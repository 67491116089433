import { all, put, take, takeLatest, select } from "redux-saga/effects";
import {
  BATCH_UPDATE_SUCCESS,
  DELETE_MULTIPLE_SUCCESS,
  DELETE_SUCCESS,
  GET_SUCCESS as GET_INVOICES_SUCCESS,
  SAVE_SUCCESS
} from "../../../api/invoices/constants";
import { GET_SUCCESS as GET_CUSTOMERS_SUCCESS } from "../../../api/customers/constants";
import { GET_SUCCESS as GET_PROJECTS_SUCCESS } from "../../../api/projects/constants";
import {
  WATCH_GET_INVOICES_TABLE,
  WATCH_REFRESH_INVOICES_TABLE
} from "./constants";

import { watchGetProjects } from "../../../api/projects/actions";
import { watchGetCustomers } from "../../../api/customers/actions";
import { watchGetInvoices } from "../../../api/invoices/actions";

import { setData, setLoading, watchRefreshInvoicesTable } from "./actions";

export const getCustomersState = state => {
  return {
    projects: state.api.projects.list,
    customers: state.api.customers.list,
    invoices: state.api.invoices.list
  };
};

export const handleRefreshTable = function*(args) {
  try {
    const { invoices } = yield select(getCustomersState);

    yield put(setData(invoices));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const fetchInvoicesTable = function*(args) {
  try {
    yield put(setLoading(true));
    yield put(watchGetInvoices());
    yield put(watchGetCustomers());
    yield put(watchGetProjects());

    const [invoices, customers, projects] = yield all([
      take(GET_INVOICES_SUCCESS),
      take(GET_CUSTOMERS_SUCCESS),
      take(GET_PROJECTS_SUCCESS)
    ]);

    yield put(watchRefreshInvoicesTable());
  } catch (e) {
    console.warn(e);
  }
};

export const handleDispatchRefresh = function*(args) {
  try {
    yield put(watchRefreshInvoicesTable());
  } catch (e) {
    console.warn(e);
  }
};

export const fetchInvoicesTableSaga = function*() {
  yield takeLatest(WATCH_GET_INVOICES_TABLE, fetchInvoicesTable);
};

export const handleDispatchRefreshSaga = function*() {
  yield takeLatest(
    [
      BATCH_UPDATE_SUCCESS,
      SAVE_SUCCESS,
      DELETE_MULTIPLE_SUCCESS,
      DELETE_SUCCESS
    ],
    handleDispatchRefresh
  );
};

export const handleRefreshSaga = function*() {
  yield takeLatest(WATCH_REFRESH_INVOICES_TABLE, handleRefreshTable);
};

export default function*() {
  yield all([
    fetchInvoicesTableSaga(),
    handleDispatchRefreshSaga(),
    handleRefreshSaga()
  ]);
}
