import styled from "styled-components";

export const PageToolbar = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > * {
    &:first-child {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;

      > * {
        margin-right: ${props => props.theme.spacing(2)}px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &:last-child {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      > * {
        margin-right: ${props => props.theme.spacing(2)}px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;
