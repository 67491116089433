import {
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  all, take,
} from "redux-saga/effects";
import {SET_ORDER_BY, SET_ORDER_DIR, SET_PAGE, SET_ROWS_PER_PAGE, WATCH_GET} from "./constants";
import {watchGetUserGroups} from "../../../api/userGroups/actions";
import {BATCH_SUCCESS, GET_FAILURE, GET_SUCCESS, SAVE_SUCCESS} from "../../../api/userGroups/constants";
import {setLoading, setUserGroups} from "./actions";
import React from "react";
import UserGroup from "../../../../../models/UserGroup";
import {UserGroupsState} from "../../../api/userGroups/types";
import {SortDirection, TableState} from "./types";

export const getUserGroups = function* (): any {
  yield put(watchGetUserGroups());
  const response = yield take([GET_SUCCESS, GET_FAILURE]);
  yield put(setLoading(true))
  if (response.type === GET_SUCCESS) {
      const {list}: UserGroupsState = yield select((state) => state.api.userGroups)
      yield put(setUserGroups(list.filter(g => !g.archived)))
      yield handleSort()
      // yield put(hideDialog())
  } else {
  //   console.error('[ui/usergroups/dialog/saga] An error occured', response)
  //   yield put(setError('Could not save UserGroup'))
  }
  yield put(setLoading(false))
};

export const handleSort = function* () {

    const {list: userGroups }: UserGroupsState = yield select((state) => state.api.userGroups)
    const {rowsPerPage, page, orderBy, orderDir}: TableState = yield select((state) => state.ui.userGroups.table)
    let groups: Array<UserGroup> = userGroups
    let groupsListSorted = groups.filter(g => !g.archived);

    groupsListSorted.sort((a, b) => {
          if ((b[orderBy] || "").toLowerCase() < (a[orderBy] || "").toLowerCase())
              return orderDir == SortDirection.ASC ? -1 : 1;
          if ((b[orderBy] || "").toLowerCase() > (a[orderBy] || "").toLowerCase())
              return orderDir == SortDirection.ASC ? 1 : -1;
          return 0;
    });
    groupsListSorted =
        rowsPerPage > 0
            ? groupsListSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            )
            : groupsListSorted;
    yield put(setUserGroups(groupsListSorted))
}

export const getUserGroupsSaga = function* () {
  yield takeEvery(WATCH_GET, getUserGroups);
};

export const handleUserGroupUpdated = function* (args: any): any {
    const userGroup = args.payload.userGroup
    const {userGroups}: TableState = yield select((state) => state.ui.userGroups.table)
    if (userGroup.archived) {
        yield put(setUserGroups(userGroups.filter(g => g.id !== userGroup.id)))

    }
    else if (userGroups.find(u => userGroup.id === u.id) || userGroup.archived) {
        yield put(setUserGroups(userGroups.slice().map(g => g.id === userGroup.id ? userGroup: g)))
    } else {
        yield put(setUserGroups(userGroups.slice().concat(userGroup)))
    }
}

export const handleUserGroupsUpdated = function* (args: any): any {
    const userGroups = args.payload.userGroups
    for (let userGroup in userGroups) {
        yield handleUserGroupUpdated({payload: { userGroup}})
    }
};
export const handleUserGroupUpdatedSaga = function* (): any {
    yield takeEvery([SAVE_SUCCESS], handleUserGroupUpdated);
};
export const handleUserGroupsUpdatedSaga = function* (): any {
    yield takeEvery([BATCH_SUCCESS], handleUserGroupUpdated);
};

export const handleSortSaga = function* () {
  yield takeEvery([SET_PAGE, SET_ROWS_PER_PAGE, SET_ORDER_BY, SET_ORDER_DIR], handleSort);
};

export default function* () {
  yield all([getUserGroupsSaga(), handleSortSaga(), handleUserGroupUpdatedSaga(), handleUserGroupsUpdatedSaga()]);
}
