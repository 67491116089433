import {
  getUserHoursByProject,
  getUserMoneyByProject,
  getUserSalaryByProject
} from "../../ProjectStats";
import {
  ReportTable,
  ReportTableCell,
  ReportTableHeader,
  ReportTableRow,
  Report
} from "../Report";
import Customer from "../../../models/Customer";
import { CompanySettingGeneric } from "../../../models/CompanySetting";
import i18next from "i18next";
import { format } from "../../../components/atoms/NumberFormatIntl";
const translate = i18next.t;

type Options = {
  projectSalarySettings?: CompanySettingGeneric;
  [key: string]: any;
};

const build = (
  ogUsers: any,
  ogProjects: any,
  entries: any,
  customers: any = [],
  options: Options = {}
) => {
  const projectsById = ogProjects.reduce(
    (prev: any, next: any) => ({ ...prev, [next.id]: next }),
    {}
  );

  let sumTotalSalary = 0;
  let sumTotalMoney = 0;
  let sumTotalHours = 0;

  const userSalaryByProject = getUserSalaryByProject(entries, projectsById);
  const userMoneyByProject = getUserMoneyByProject(entries, projectsById);
  const userHoursByProject = getUserHoursByProject(entries);
  const report = new Report();
  const tableHeader = new ReportTableHeader([
    new ReportTableCell(translate("common.user")),
    new ReportTableCell(translate("common.email")),
    new ReportTableCell(translate("common.project")),
    new ReportTableCell(translate("common.billableRate")),
    new ReportTableCell(translate("common.customer")),
    new ReportTableCell(translate("common.hours")),
    new ReportTableCell(translate("common.hourlyRate")),
    new ReportTableCell(
      translate("pages.reports.salaryBasis.otherAllowanceOrRefund")
    ),
    new ReportTableCell(translate("common.grossIncome")),
    new ReportTableCell(translate("common.grossSalary"))
  ]);

  const tableRows: any[] = [];

  const projects = ogProjects.slice();
  projects.sort((a: any, b: any) => a?.name?.localeCompare(b?.name));

  const users = ogUsers.slice();
  users.sort((a: any, b: any) => a?.fullName?.localeCompare(b?.fullName));
  const f = (number: number) => {
    return Number(number.toFixed(2));
  };
  users.forEach((user: any) => {
    let sumTotalUserSalary = 0;
    let sumTotalUserMoney = 0;
    let sumTotalUserHours = 0;
    let hasEntries = false;
    Object.entries(userHoursByProject).forEach(
      ([pid, hoursByUsers]: [string, any]) => {
        const project = projects.find((p: any) => p.id === pid);
        const hours = hoursByUsers[user.id];
        const totalMoney =
          userMoneyByProject[pid] && userMoneyByProject[pid][user.id];
        const totalSalary =
          userSalaryByProject[pid] && userSalaryByProject[pid][user.id];
        if (project && hours) {
          hasEntries = true;
          sumTotalUserHours += hours;
          sumTotalUserSalary = totalSalary
            ? sumTotalUserSalary + totalSalary
            : sumTotalUserSalary;
          sumTotalUserMoney = totalMoney
            ? sumTotalUserMoney + totalMoney
            : sumTotalUserMoney;
          tableRows.push(
            new ReportTableRow([
              new ReportTableCell(user?.displayName),
              new ReportTableCell(user?.email),
              new ReportTableCell(project?.name),
              new ReportTableCell(f(project?.getBillableRate(user?.email))),
              new ReportTableCell(
                customers?.find((c: any) => c.id === project?.customer)?.name ||
                  project?.customer
              ),
              new ReportTableCell(hours, {
                textAlign: "right"
              }),
              new ReportTableCell(
                project.salaryEnabled ? f(project.getSalaryRate(user.id)) : "",
                {
                  textAlign: "right"
                }
              ),
              new ReportTableCell(),
              new ReportTableCell(project.billable ? f(totalMoney) : "", {
                textAlign: "right"
              }),
              new ReportTableCell(project.salaryEnabled ? f(totalSalary) : "", {
                textAlign: "right"
              })
            ])
          );
        }
      }
    );
    if (hasEntries) {
      tableRows.push(
        new ReportTableRow([
          new ReportTableCell(translate("common.sum")),
          new ReportTableCell(),
          new ReportTableCell(),
          new ReportTableCell(),
          new ReportTableCell(),
          new ReportTableCell(),
          new ReportTableCell(sumTotalUserHours, {
            textAlign: "right"
          }),
          new ReportTableCell(),
          new ReportTableCell(f(sumTotalUserMoney), {
            textAlign: "right"
          }),
          new ReportTableCell(f(sumTotalUserSalary), {
            textAlign: "right"
          })
        ])
      );
      tableRows.push(
        new ReportTableRow([
          new ReportTableCell(),
          new ReportTableCell(),
          new ReportTableCell(),
          new ReportTableCell(),
          new ReportTableCell(),
          new ReportTableCell(),
          new ReportTableCell(),
          new ReportTableCell()
        ])
      );
    }
    sumTotalHours += sumTotalUserHours;
    sumTotalMoney += sumTotalUserMoney;
    sumTotalSalary += sumTotalUserSalary;
  });
  tableRows.push(
    new ReportTableRow([
      new ReportTableCell(translate("common.total")),
      new ReportTableCell(),
      new ReportTableCell(),
      new ReportTableCell(),
      new ReportTableCell(),
      new ReportTableCell(),
      new ReportTableCell()
    ])
  );
  tableRows.push(
    new ReportTableRow([
      new ReportTableCell(translate("common.sum")),
      new ReportTableCell(),
      new ReportTableCell(),
      new ReportTableCell(),
      new ReportTableCell(sumTotalHours, {
        textAlign: "right"
      }),
      new ReportTableCell(),
      new ReportTableCell(f(sumTotalMoney), {
        textAlign: "right"
      }),
      new ReportTableCell(f(sumTotalSalary), {
        textAlign: "right"
      })
    ])
  );

  report.push(new ReportTable(tableHeader, tableRows));
  return report;
};

export const createTableExport = (
  users: any,
  projects: any,
  entries: any,
  customers: Customer[] = [],
  options: Options = {}
) => {
  return build(users, projects, entries, customers, options);
};
