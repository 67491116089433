import Customer from "../models/Customer";
import { useMemo } from "react";
import useCustomers from "./useCustomers";

const useCustomersMap = (customers?: Customer[]) => {
  const { data: allCustomers } = useCustomers();
  return useMemo(() => {
    const m = new Map();
    (customers || allCustomers || []).forEach((u: Customer) => {
      m.set(u.id, u);
    });
    return m;
  }, [customers, allCustomers]);
};

export default useCustomersMap;
