import { all, delay, put, takeLatest } from "@redux-saga/core/effects";
import { SAVE, SAVE_SUCCESS } from "../../../api/userSettings/constants";

import { updateSnackbar } from "./actions";

export const handleSettingSaving = function*(args) {
  try {
    yield put(updateSnackbar({ visible: true, text: "Saving" }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleSettingSaved = function*() {
  try {
    yield put(updateSnackbar({ visible: true, text: "All changes saved" }));
    yield delay(2000);
    yield put(updateSnackbar({ visible: false }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleSettingSavingSaga = function*() {
  yield takeLatest(SAVE, handleSettingSaving);
};

export const handleSettingSavedSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleSettingSaved);
};

export default function*() {
  yield all([handleSettingSavingSaga(), handleSettingSavedSaga()]);
}
