import { takeLatest, call, put, select, all } from "redux-saga/effects";
import {
  WATCH_GET,
  WATCH_GET_CUSTOMER,
  WATCH_PATCH,
  WATCH_SAVE
} from "./constants";
import {
  get,
  getCustomer as getCustomerAction,
  getCustomerFailure,
  getCustomerSuccess,
  getFailure,
  getSuccess,
  patch,
  patchFailure,
  patchSuccess,
  save,
  saveFailure,
  saveSuccess
} from "./actions";
import Customer from "../../../../models/Customer";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;
export const getUserEmail = ({
  api: {
    auth: { user }
  }
}) => user.email;

export const getCustomers = function* getCustomers() {
  try {
    yield put(get());
    const workspace = yield select(getActiveWorkspace);
    const customers = yield call(Customer.list, workspace);

    yield put(getSuccess(customers));
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const saveCustomer = function* saveCustomer(arg) {
  const {
    payload: { customer, updates }
  } = arg;

  try {
    yield put(save());
    const workspace = yield select(getActiveWorkspace);
    const user = yield select(getUserEmail);

    const isNew = !customer || !customer.id;

    let savedCustomer;
    if (isNew) {
      savedCustomer = yield call(Customer.create, workspace, user, updates);
    } else {
      savedCustomer = yield call(
        Customer.update,
        workspace,
        user,
        customer,
        updates
      );
    }
    yield put(saveSuccess(savedCustomer, isNew));
  } catch (e) {
    console.warn(e);
    yield put(saveFailure(e));
  }
};

export const patchCustomer = function* patchCustomer(arg) {
  try {
    const {
      payload: { id, updates }
    } = arg;

    yield put(patch(id));

    const workspace = yield select(getActiveWorkspace);
    const user = yield select(getUserEmail);

    const customers = yield call(Customer.patch, workspace, user, id, updates);

    yield put(patchSuccess(customers));
  } catch (e) {
    console.warn(e);
    yield put(patchFailure(e));
  }
};

export const getCustomer = function* getCustomer(arg) {
  const {
    payload: { id }
  } = arg;
  try {
    yield put(getCustomerAction(id));

    const workspace = yield select(getActiveWorkspace);

    const customer = yield call(Customer.get, workspace, id);

    yield put(getCustomerSuccess(id, customer));
  } catch (e) {
    console.warn(e);
    yield put(getCustomerFailure(id, e));
  }
};

export const getCustomerSaga = function* getCustomerSaga() {
  yield takeLatest(WATCH_GET_CUSTOMER, getCustomer);
};

export const getCustomersSaga = function* getCustomersSaga() {
  yield takeLatest(WATCH_GET, getCustomers);
};

export const saveCustomerSaga = function* saveCustomerSaga() {
  yield takeLatest(WATCH_SAVE, saveCustomer);
};

export const patchCustomerSaga = function* saveCustomerSaga() {
  yield takeLatest(WATCH_PATCH, patchCustomer);
};

export default function*() {
  yield all([
    saveCustomerSaga(),
    getCustomersSaga(),
    patchCustomerSaga(),
    getCustomerSaga()
  ]);
}
