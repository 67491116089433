import React, { useMemo, useState } from "react";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@superprofit/core-react-components/atoms/Table";
import Card from "@superprofit/core-react-components/atoms/Card";
import UserHoursMonthItem from "./UserItem";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import WeekDistribution from "../../../../components/atoms/WeekDistribution";
import { weeks } from "@superprofit/time-util";
import TableLoader from "../../../../components/molecules/TableLoader";
import { getWeekOfMonthDates } from "../../../../utils/ProjectStats";

import ActionMenu from "../shared/ActionMenu";
import { IUserTable, OrderBy, TransformedUser } from "../shared/types";
import useWorkspace from "../../../../hooks/useWorkspace";
import useFilteredUsers from "../shared/useFilteredUsers";
import useProjectsMap from "../../../../hooks/useProjectsMap";
import useUsersList from "../shared/useUsersList";
import useSortedUsersList from "../shared/useSortedUsersList";
import useExport from "./useExport";
import { useTranslation } from "react-i18next";

export default (props: IUserTable) => {
  const {
    stats,
    projects,
    users,
    period,
    loading,
    columns,
    title = "",
    onAdd,
    filters,
    ...rest
  } = props;
  const workspace = useWorkspace();
  const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const filteredUsers = useFilteredUsers({ filters, users });
  const projectsMap = useProjectsMap(projects);
  const projectsById = Object.fromEntries(projectsMap);
  const { year, month } = period;

  const weekNumbers = useMemo(() => {
    return weeks(year, month);
  }, [year, month]);
  const usersList = useUsersList(props);

  const {
    orderDir,
    setOrderDir,
    orderBy,
    setOrderBy,
    sortedUsersList
  } = useSortedUsersList({ usersList });

  const pageList = useMemo(() => {
    return rowsPerPage > 0
      ? sortedUsersList.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : sortedUsersList;
  }, [sortedUsersList, page, rowsPerPage]) as TransformedUser[];

  const weekDistribution = useMemo(
    () =>
      weekNumbers &&
      year &&
      month &&
      weekNumbers.map((w: number) => ({
        tooltip: `${t("common.week")} ${w}`,
        value: getWeekOfMonthDates(year, month, w)
      })),
    [weekNumbers]
  );

  const count = usersList.length;

  const handleOnSort = (event: any, property: OrderBy) => {
    const isAsc = orderBy === property && orderDir === "asc";
    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { handleExport } = useExport({
    filters,
    filteredUsers,
    workspace,
    year,
    month,
    projects
  });

  return (
    <Card {...rest}>
      <CardHeader
        title={title}
        action={
          <ActionMenu
            onAdd={onAdd}
            onExportXLSX={() => handleExport("XLSX")}
            onExportPDF={() => handleExport("PDF")}
          />
        }
      />
      <Divider />
      <TableContainer>
        <Table aria-label={t("common.hoursPerUser")}>
          <TableHead>
            <TableRow>
              {columns.expand && (
                <TableCell style={{ maxWidth: 80, width: 80 }} />
              )}

              {columns.avatar && (
                <TableCell style={{ maxWidth: 80, width: 80 }} />
              )}

              <TableCell
                sortDirection={orderBy === "displayName" ? orderDir : false}
              >
                <TableSortLabel
                  active={orderBy === "displayName"}
                  direction={orderBy === "displayName" ? orderDir : "asc"}
                  onClick={e => handleOnSort(e, "displayName")}
                >
                  {t("common.name")}
                </TableSortLabel>
              </TableCell>

              {columns.weekDistribution && (
                <TableCell align="right">
                  <WeekDistribution weeks={weekDistribution || []} />
                </TableCell>
              )}

              {columns.hours && (
                <TableCell
                  align="right"
                  sortDirection={orderBy === "hours" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "hours"}
                    direction={orderBy === "hours" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "hours")}
                  >
                    {t("common.logged")}
                  </TableSortLabel>
                </TableCell>
              )}

              {columns.relative && <TableCell />}

              {columns.projectDistribution && (
                <TableCell>{t("common.byProject")}</TableCell>
              )}

              {columns.billable && (
                <TableCell
                  align="right"
                  sortDirection={orderBy === "money" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "money"}
                    direction={orderBy === "money" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "money")}
                  >
                    {t("common.billable")}
                  </TableSortLabel>
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          {!loading && (
            <TableBody>
              {pageList.map(data => (
                <UserHoursMonthItem
                  key={data.user.email}
                  data={data}
                  projectsById={projectsById}
                  weekNumbers={weekNumbers}
                  columns={columns}
                />
              ))}
            </TableBody>
          )}

          {!loading && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelRowsPerPage={t("common.rowsPerPage")}
                  rowsPerPageOptions={[
                    10,
                    25,
                    50,
                    { label: t("common.all"), value: -1 }
                  ]}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": t("common.rowsPerPage") || "" },
                    native: true
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}

          {loading && (
            <TableLoader
              cols={[
                "rect",
                "circle",
                "text",
                "text",
                "textRight",
                "text",
                "textRight"
              ]}
            />
          )}
        </Table>
      </TableContainer>
    </Card>
  );
};
