import React, { Fragment, useEffect, useRef } from "react";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import Avatar from "@superprofit/core-react-components/atoms/Avatar";
import CardContent from "@superprofit/core-react-components/atoms/CardContent";
import Grid from "@material-ui/core/Grid";
import { LeadCard, LeadStepper, LeadTags } from "./LeadList.styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import Chip from "@superprofit/core-react-components/atoms/Chip";
import Comment from "@superprofit/core-react-components/molecules/Comment";
import CommentForm from "@superprofit/core-react-components/molecules/CommentForm";
import { useDispatch, useSelector } from "react-redux";
import {
  watchSaveLead,
  watchSaveLeadComment
} from "../../../../redux/modules/api/leads/actions";

export const LeadSteps = [
  {
    label: "Prospect",
    tooltip: "Identify what the needs of the customer are."
  },
  {
    label: "Prepare",
    tooltip: "Create a proposition and prepare an offer."
  },
  {
    label: "Present",
    tooltip: "Meet the customer and discuss the proposition"
  },
  {
    label: "Follow-up",
    tooltip: "Keep the lead warm and follow-up with the customer"
  },
  {
    label: "Close",
    tooltip: "Negotiate and close the deal"
  }
];

export default ({ lead }) => {
  const dispatch = useDispatch();
  const commentInputRef = useRef();

  const user = useSelector(state => state.api.auth.user);
  const isCommentSaving = useSelector(
    state => state.ui.leads.comments.saving[lead.id]
  );
  const isCommentSaved = useSelector(
    state => state.ui.leads.comments.saved[lead.id]
  );
  const commentSaveError = useSelector(
    state => state.ui.leads.comments.saveError[lead.id]
  );

  useEffect(() => {
    if (!!isCommentSaved && !commentSaveError) {
      commentInputRef.current.value = "";
    }
  }, [isCommentSaved, commentSaveError, commentInputRef]);

  const handleOnComment = () => {
    dispatch(
      watchSaveLeadComment({ comment: commentInputRef.current.value }, lead)
    );
  };

  const handleOnWorkflowChange = (e, value) => {
    dispatch(watchSaveLead(lead, { workflow: value }));
  };

  const activeStep = lead.workflow || 0;
  const completedSteps = new Array(activeStep).fill("").reduce(
    (prev, next, idx) => ({
      ...prev,
      [idx]: true
    }),
    {}
  );

  return (
    <LeadCard>
      <CardHeader
        title={lead.name}
        subheader="September 14, 2016"
        avatar={<Avatar variant="rounded" initial={lead.name[0]} />}
      />
      <CardContent>
        <Grid container spacing={2} component="div" alignItems="flex-end">
          <Grid component="div" item xs={12}>
            <Typography
              component="p"
              variant="body2"
              color="textSecondary"
              gutterBottom
            >
              {lead.description}
            </Typography>

            <LeadTags>
              {lead.tags.map(t => (
                <Chip key={t} label={t} />
              ))}
            </LeadTags>
          </Grid>
          <Grid component="div" item xs={12}>
            <LeadStepper
              nonLinear
              steps={LeadSteps}
              activeStep={activeStep}
              completedSteps={completedSteps}
              onClick={handleOnWorkflowChange}
            />
          </Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <Grid container spacing={2} component="div" alignItems="flex-end">
          <Grid component="div" item xs={12}>
            <CommentForm
              user={user}
              isSaving={!!isCommentSaving}
              onSave={handleOnComment}
              inputRef={commentInputRef}
            />
          </Grid>
        </Grid>
      </CardContent>

      {lead.comments && lead.comments.length > 0 && (
        <Fragment>
          <Divider />
          <CardContent>
            <Grid container spacing={2} component="div" alignItems="flex-end">
              {lead.comments.map((c, idx) => (
                <Grid key={c.id} component="div" item xs={12}>
                  <Comment avatar={<Avatar initial="U" />}>{c.comment}</Comment>
                  {idx < lead.comments.length - 1 && <Divider />}
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Fragment>
      )}
    </LeadCard>
  );
};
