import {
  EXPORT,
  EXPORT_FAILURE,
  EXPORT_SUCCESS,
  WATCH_EXPORT
} from "./constants";

export const watchExportInvoice = invoice => ({
  type: WATCH_EXPORT,
  payload: { invoice }
});

export const exportInvoice = invoice => ({
  type: EXPORT,
  payload: { invoice }
});

export const exportInvoiceSuccess = invoice => ({
  type: EXPORT_SUCCESS,
  payload: { invoice }
});

export const exportInvoiceFailure = error => ({
  type: EXPORT_FAILURE,
  payload: { error }
});
