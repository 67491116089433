import { Skeleton } from "../../../../atoms";
import Typography from "../../../../atoms/Typography";
import Grid from "@material-ui/core/Grid";
import React from "react";

export default ({ state }) => {
  const {
    subscriptionInformation,
    isLoadingUpcomingInvoice,
    updatedSubscriptionStatus,
  } = state;
  const subscription = subscriptionInformation?.subscription;
  const {
    updatedSubscriptionProductId = subscription?.plan?.product?.id,
    updatedSubscriptionSeats = subscription?.quantity,
  } = state;

  const updatedProduct = state.products?.find(
    (p) => p.id === updatedSubscriptionProductId
  );
  if (isLoadingUpcomingInvoice)
    return (
      <Grid item xs={12}>
        <Skeleton />
      </Grid>
    );

  if (
    !updatedSubscriptionProductId ||
    !updatedSubscriptionSeats ||
    (updatedSubscriptionProductId === subscription?.plan?.product?.id &&
    updatedSubscriptionSeats === subscription.quantity)
  ) {
    return (
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          You have not made any changes yet.
        </Typography>
      </Grid>
    );
  }

  if (updatedSubscriptionStatus?.status === "success")
    return (
      <Grid item xs={12}>
        <Typography >
          Update success!
        </Typography>
      </Grid>
    );

  if (updatedSubscriptionSeats > subscription.quantity)
    return (
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          You added {updatedSubscriptionSeats - subscription.quantity}{" "}
          additional users, bringing you a total of {updatedSubscriptionSeats}{" "}
          seat(s) on the {updatedProduct.name} plan
        </Typography>
      </Grid>
    );
  return (
    <Grid item xs={12}>
      <Typography variant="body2" color="textSecondary">
        You removed {subscription.quantity - updatedSubscriptionSeats} users,
        bringing you a total of {updatedSubscriptionSeats} seat(s) on the{" "}
        {updatedProduct.name} plan
      </Typography>
    </Grid>
  );
};
