import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Page from "@superprofit/core-react-components/atoms/Page";
import { CreatePDFDialog } from "../../components/organisms/PDFRenderer";
import SearchField from "@superprofit/core-react-components/atoms/SearchField";
import { Button, Tooltip } from "@superprofit/core-react-components/atoms";
import UsersCard from "./users/UsersCard";
import AccessControlDialog from "./users/AccessControlDialog";
import { useAllowCreateUser } from "../../helpers";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import DeleteUsersDialog from "./users/DeleteUsersDialog";
import AssignProjectsDialog from "./users/AssignProjectsDialog";
import Snackbar from "@material-ui/core/Snackbar";
import { logEvent, ANALYTICS_EVENT } from "../../firebase";
import { useTranslation } from "react-i18next";
import UserCreateDialog from "./users/UserCreateDialog";
import useUserCreateDialog from "./users/hooks/useUserCreateDialog";
import useUsers from "../../hooks/useUsers";
import useWorkspace from "../../hooks/useWorkspace";
import useCompanySettings from "../../hooks/useCompanySettings";
import useSelection from "./users/hooks/useSelection";
import useSnackbar from "./users/hooks/useSnackbar";
import User from "../../models/User";
import UserEditDialog from "./users/UserEditDialog";
import useUserEditDialog from "./users/hooks/useUserEditDialog";

export default () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const { show: showUserCreateDialog } = useUserCreateDialog();
  const { show: showEditUserDialog } = useUserEditDialog();
  const { data: users = [], isLoading: loading } = useUsers();
  const { selection } = useSelection();
  const { state: snackbar } = useSnackbar();
  const allowCreateUser = useAllowCreateUser();
  const { data: settings } = useCompanySettings();
  const filteredUsers = React.useMemo(() => {
    return users.filter(
      (u: User) =>
        filter === "" ||
        (u.displayName || "").toLowerCase().indexOf(filter.toLowerCase()) > -1
    );
  }, [users, filter]);
  const workspaceName = useWorkspace();

  useEffect(() => {
    if (workspaceName) {
      logEvent(ANALYTICS_EVENT.USERS_VISITED, { workspaceName });
    }
  }, [workspaceName]);

  const handleOnAdd = () => {
    showUserCreateDialog();
  };

  const handleOnFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setFilter(val || "");
  };
  return (
    <Page context={t("common.management")} title={t("common.users")}>
      <Grid container spacing={3} component="div">
        <Grid container item xs={6} component="div">
          <SearchField
            placeholder={t("common.search")}
            onChange={handleOnFilter}
          />
        </Grid>

        <Grid container item xs={6} component="div" justify="flex-end">
          {allowCreateUser ? (
            <Button
              color="primary"
              variant="contained"
              onClick={handleOnAdd}
              startIcon={<PersonAddIcon />}
            >
              {t("common.invite")}
            </Button>
          ) : (
            <Tooltip
              arrow
              title={t("pages.users.subscriptionTooltip")}
              aria-label={t("pages.users.subscriptionTooltip")}
            >
              <div style={{ display: "flex" }}>
                <Button
                  disabled
                  color="primary"
                  variant="contained"
                  onClick={handleOnAdd}
                  startIcon={<PersonAddIcon />}
                >
                  {t("common.invite")}
                </Button>
              </div>
            </Tooltip>
          )}
        </Grid>

        <Grid item xs={12} component="div">
          <UsersCard
            settings={settings}
            users={filteredUsers}
            loading={loading}
            selection={selection}
          />
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={snackbar.visible}
        message={snackbar.text}
      />
      <DeleteUsersDialog />
      <AccessControlDialog />
      <AssignProjectsDialog />
      {/*<UserDialog />*/}
      <UserCreateDialog />
      <UserEditDialog />
      <CreatePDFDialog />
    </Page>
  );
};
