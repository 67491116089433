import Dialog from "../../atoms/Dialog";
import DialogTitle from "../../atoms/DialogTitle";
import Button from "../../atoms/Button";
import { projectId } from "../../../config/firebase";
import React from "react";
export function SwitchDialog(props: { open: boolean }) {
  if (projectId !== "dev-timet-eu") {
    return null;
  }

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={props.open}>
      <DialogTitle id="simple-dialog-title">Switch env</DialogTitle>
      <Button
        onClick={() => {
          localStorage.removeItem("timet-is-staging");
          window.location.href = "/app";
        }}
      >
        Development
      </Button>
      <Button
        onClick={() => {
          localStorage.setItem("timet-is-staging", "true");
          window.location.href = "/app";
        }}
      >
        Staging
      </Button>
    </Dialog>
  );
}

export function SwitchEnvsDialog() {
  const [open, setOpen] = React.useState(false);

  if (projectId !== "dev-timet-eu") {
    return null;
  }
  return (
    <>
      <Button onClick={() => setOpen(true)}>Switch Envs</Button>
      <SwitchDialog open={open} />
    </>
  );
}
