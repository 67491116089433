import React from "react";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@superprofit/core-react-components/atoms/Table";
import Card from "@superprofit/core-react-components/atoms/Card";
import TableLoader from "../../../../components/molecules/TableLoader";
import UserItem from "./UserItem";
import { getHoursByUser } from "../../../../utils/UserStats";
import { useTranslation } from "react-i18next";

export default ({
  project,
  users,
  loading,
  month,
  year,
  stats,
  onChange,
  ...rest
}) => {
  const hoursByUser = getHoursByUser(stats);
  const { t } = useTranslation();

  return (
    <Card {...rest}>
      <TableContainer>
        <Table aria-label="Planned by user">
          <TableHead>
            <TableRow>
              <TableCell style={{ maxWidth: 80, width: 80 }} />
              <TableCell>{t("common.name")}</TableCell>

              <TableCell align="right">Actual</TableCell>

              <TableCell align="right">{t("common.planned")}</TableCell>
            </TableRow>
          </TableHead>

          {!loading && (
            <TableBody>
              {users.map(user => (
                <UserItem
                  key={user.id}
                  hours={hoursByUser[user.id]}
                  month={month}
                  year={year}
                  project={project}
                  user={user}
                  onChange={onChange}
                />
              ))}
            </TableBody>
          )}

          {loading && (
            <TableLoader cols={["circle", "text", "textRight", "textRight"]} />
          )}
        </Table>
      </TableContainer>
    </Card>
  );
};
