import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { GET, GET_SUCCESS } from "../../../api/customerOverview/constants";
import { setBasis, setLoading } from "./actions";

export const getData = ({
  api: {
    customerOverview: { data: basis }
  }
}) => ({
  basis
});

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const handleUpdate = function*(args) {
  const { basis } = yield select(getData);

  try {
    yield put(setBasis(basis));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleStatisticsLoadingSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export const handleUpdateSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleUpdate);
};

export default function*() {
  yield all([handleUpdateSaga(), handleStatisticsLoadingSaga()]);
}
