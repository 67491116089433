import { UPDATE_SNACKBAR, WATCH_REFRESH_SNACKBAR } from "./constants";

export const updateSnackbar = updates => ({
  type: UPDATE_SNACKBAR,
  payload: { updates }
});

export const watchRefreshSnackbar = () => ({
  type: WATCH_REFRESH_SNACKBAR,
  payload: {}
});
