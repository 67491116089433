import { SETTING_TYPES } from "./UserSetting";
import { Timestamp } from "@superprofit/core-firestore-models";

interface IUserSettingGoal {
  type: SETTING_TYPES.PERFORMANCE_GOAL;
  id?: string;
  user: string;
  billable: boolean;
  nonBillable: boolean;
  year: number;
  month: number;
  createdBy: string;
  createdAt: typeof Timestamp;
  updatedBy: string;
  updatedAt: string;
}

export default class UserSettingGoal {
  type: SETTING_TYPES.PERFORMANCE_GOAL;
  id?: string;
  user: string;
  billable: boolean;
  nonBillable: boolean;
  year: number;
  month: number;
  createdBy: string;
  createdAt: typeof Timestamp;
  updatedBy: string;
  updatedAt: string;

  constructor({
    user,
    billable,
    nonBillable,
    year,
    month,
    createdAt,
    createdBy,
    updatedBy,
    updatedAt,
  }: IUserSettingGoal) {
    this.type = SETTING_TYPES.PERFORMANCE_GOAL;

    this.id = `goal-${user}-${year}-${month || "all"}`;

    this.user = user;
    this.year = year;
    this.month = month;
    this.billable = billable;
    this.nonBillable = nonBillable;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  isValid = () => {
    return !!this.year;
  };

  setData(updates: Partial<IUserSettingGoal>) {
    Object.assign(this, updates);
    return this;
  }

  data() {
    return {
      type: this.type,
      user: this.user,
      year: this.year,
      month: this.month,
      billable: this.billable,
      nonBillable: this.nonBillable,
      updatedAt: this.updatedAt,
      updatedBy: this.updatedBy,
    };
  }
}
