import React, { Fragment } from "react";
import {
  TableBody,
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import TableFooter from "@material-ui/core/TableFooter";
import Skeleton from "@material-ui/lab/Skeleton";

const types = {
  rect: key => (
    <TableCell key={key} style={{ maxWidth: 80, width: 80 }}>
      <Skeleton variant="rect" width={20} height={20} />
    </TableCell>
  ),
  circle: key => (
    <TableCell key={key} style={{ maxWidth: 80, width: 80 }}>
      <Skeleton variant="circle" width={40} height={40} />
    </TableCell>
  ),
  square: key => (
    <TableCell key={key} style={{ maxWidth: 80, width: 80 }}>
      <Skeleton variant="rect" width={40} height={40} />
    </TableCell>
  ),
  text: key => (
    <TableCell key={key}>
      <Skeleton variant="text" height={20} />
    </TableCell>
  ),
  textRight: key => (
    <TableCell key={key} align="right">
      <Skeleton variant="text" height={20} />
    </TableCell>
  )
};

const TableLoader = ({ cols, footer, rowCount }) => {
  const rows = new Array(rowCount).fill("");

  return (
    <Fragment>
      <TableBody>
        {rows.map((r, idx) => (
          <TableRow key={idx}>{cols.map((c, cidx) => types[c](cidx))}</TableRow>
        ))}
      </TableBody>
      {footer && (
        <TableFooter>
          <TableRow>
            <td colSpan="1000">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: 10
                }}
              >
                <Skeleton variant="text" width={100} height={20} />
              </div>
            </td>
          </TableRow>
        </TableFooter>
      )}
    </Fragment>
  );
};

TableLoader.defaultProps = {
  rowCount: 10,
  cols: ["rect", "circle", "text", "text", "text", "rect"],
  footer: true
};

export default TableLoader;
