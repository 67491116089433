import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Timet } from "@superprofit/timet-types";
import { ApprovalRecord } from "../models/ApprovalRecords";

export const useApprovalRecordsUpdateMutation = () => {
  const queryClient = useQueryClient();

  const update = (payload: Timet.Api.ApprovalRecords.UpdatePayload) => {
    return ApprovalRecord.update(payload);
  };

  return useMutation(update, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(ApprovalRecord.COLLECTION)
      });
    }
  });
};
