import { SAVE_SUCCESS, SAVE_FAILED, SAVING } from "./constants";

const initialState = {
  saving: {},
  saved: {},
  saveError: {}
};

// Use sagas for this instead
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVING:
      return {
        ...state,
        saving: {
          ...state.saving,
          [payload.leadId]: payload.comment
        },
        saved: {
          ...state.saved,
          [payload.leadId]: undefined
        },
        saveError: {
          ...state.saveError,
          [payload.leadId]: undefined
        }
      };

    case SAVE_SUCCESS:
      return {
        ...state,
        saving: {
          ...state.saving,
          [payload.leadId]: undefined
        },
        saved: {
          ...state.saved,
          [payload.leadId]: payload.comment
        }
      };

    case SAVE_FAILED:
      return {
        ...state,
        saving: {
          ...state.saving,
          [payload.leadId]: undefined
        },
        saveError: {
          ...state.saveError,
          [payload.leadId]: payload.error
        }
      };

    default:
      return state;
  }
};
