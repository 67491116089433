import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { GET, GET_SUCCESS } from "../../../api/projectOverview/constants";
import { setLoading, setProject } from "./actions";
import { SAVE_SUCCESS } from "../../../api/projects/constants";

export const getCurrentProject = ({
  ui: {
    projectOverview: {
      project: { project }
    }
  }
}) => project;

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const handleGetSuccess = function*(args) {
  try {
    const {
      payload: { project }
    } = args;
    yield put(setProject(project));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleSaveSuccess = function*(args) {
  try {
    const {
      payload: { project }
    } = args;

    const currentProject = yield select(getCurrentProject);

    if (!currentProject.id || currentProject?.id !== project?.id) return; // prevent unnecessary recalculations

    yield put(setProject(project));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleGetSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export const handleGetSuccessSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleGetSuccess);
};

export const handleSaveSuccessSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleSaveSuccess);
};

export default function*() {
  yield all([handleGetSuccessSaga(), handleGetSaga(), handleSaveSuccessSaga()]);
}
