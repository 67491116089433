import { all, delay, put, takeLatest } from "@redux-saga/core/effects";
import {
  PATCH_MULTIPLE,
  PATCH_MULTIPLE_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  DUPLICATE,
  DUPLICATE_SUCCESS
} from "../../../api/projects/constants";

import { updateSnackbar } from "./actions";

export const handleProjectsSaving = function*(args) {
  try {
    yield put(updateSnackbar({ visible: true, text: "Saving" }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleProjectsSaved = function*(args) {
  const { isNew } = args.payload;
  try {
    if (isNew) {
      yield put(updateSnackbar({ visible: true, text: "Project created." }));
    } else {
      yield put(updateSnackbar({ visible: true, text: "Project(s) saved." }));
    }
    yield delay(2000);
    yield put(updateSnackbar({ visible: false }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleProjectSavingSaga = function*() {
  yield takeLatest([SAVE, PATCH_MULTIPLE, DUPLICATE], handleProjectsSaving);
};

export const handleProjectSavedSaga = function*() {
  yield takeLatest([SAVE_SUCCESS, PATCH_MULTIPLE_SUCCESS, DUPLICATE_SUCCESS], handleProjectsSaved);
};

export default function*() {
  yield all([handleProjectSavingSaga(), handleProjectSavedSaga()]);
}
