import styled from "styled-components";

export const StyledColorPicker = styled.div`
  position: relative;
`;

export const ColorButton = styled.button`
  border-radius: 0;
  outline: 0;
  border: none;
  background: ${props => props.theme.palette.background.light};

  &:after {
    content: "";
    display: block;
    width: 2rem;
    height: 2rem;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    background: ${props => props.color};
    margin: ${props => props.theme.spacing(2)};
  }
`;
