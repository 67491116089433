import React, { forwardRef } from "react";
import { StyledChip } from "./chip/Chip.styles";

const Chip = forwardRef((props, ref) => <StyledChip {...props} ref={ref} />);

Chip.defaultProps = {
  color: "default"
};

export default Chip;
