import firebase from "firebase/compat/app";
import moment from "moment";

export default class Timestamp {
  static now = () => {
    return firebase.firestore.Timestamp.fromDate(new Date());
  };
  static fromMoment = m => firebase.firestore.Timestamp.fromDate(m.toDate());
  static toMoment = timestamp =>
    moment(
      new firebase.firestore.Timestamp(
        timestamp.seconds,
        timestamp.nanoseconds
      ).toDate()
    );
  static toMomentUtc = timestamp =>
    moment.utc(
      new firebase.firestore.Timestamp(
        timestamp.seconds,
        timestamp.nanoseconds
      ).toDate()
    );
}
