import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel
} from "@superprofit/core-react-components/atoms";
import React from "react";
import Card from "@superprofit/core-react-components/atoms/Card";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import CardContent from "@superprofit/core-react-components/atoms/CardContent";
import CompanySettingGeneric from "../../../models/CompanySettingGeneric";
import useCompanySettings from "../../../hooks/useCompanySettings";
import useCompanySettingMutation from "../../../hooks/useCompanySettingMutation";
import { useTranslation } from "react-i18next";
import Switch from "../../../components/atoms/Switch";

export default () => {
  const { data: settings } = useCompanySettings();
  const mutation = useCompanySettingMutation();
  const { t } = useTranslation();

  const features =
    settings?.features ||
    new CompanySettingGeneric({
      id: "features",
      value: {},
      updatedAt: null,
      updatedBy: null
    });

  const values = features?.value || {};

  const mergeFeatures = (newFeatures: { [key: string]: boolean }) => {
    const f = features.clone();
    f.setData({
      value: { ...f.value, ...newFeatures }
    });
    return f;
  };

  const handleTwnty = async () => {
    await mutation.mutateAsync(
      mergeFeatures({
        twentyFourSevenIntegration: !values?.twentyFourSevenIntegration
      })
    );
  };
  const handleUserIncome = async () => {
    await mutation.mutateAsync(
      mergeFeatures({ userProjectIncome: !values?.userProjectIncome })
    );
  };
  const handleTimesheetReminders = async () => {
    await mutation.mutateAsync(
      mergeFeatures({ timesheetReminders: !values?.timesheetReminders })
    );
  };
  const handleProjectSalaryRate = async () => {
    await mutation.mutateAsync(
      mergeFeatures({ projectSalaryRate: !values?.projectSalaryRate })
    );
  };

  const handleUserProjectedSalary = async () => {
    await mutation.mutateAsync(
      mergeFeatures({ userProjectedSalary: !values?.userProjectedSalary })
    );
  };

  const handleNettlonn = async () => {
    await mutation.mutateAsync(mergeFeatures({ nettlonn: !values?.nettlonn }));
  };
  const handleApprovalRecords = async () => {
    await mutation.mutateAsync(
      mergeFeatures({ approvalRecords: !values?.approvalRecords })
    );
  };

  return (
    <Card>
      <CardHeader title={t("common.features")} />
      <Divider />
      <CardContent>
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">
            {t("pages.companySettings.features.legend")}
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={!!values?.twentyFourSevenIntegration}
                  onChange={handleTwnty}
                />
              }
              label={t("pages.companySettings.features.twntyFourSevenLabel")}
            />
            <FormHelperText>
              {t("pages.companySettings.features.twntyFourSevenDesc")}
            </FormHelperText>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={!!values.userProjectIncome}
                  onChange={handleUserIncome}
                />
              }
              label={t("pages.companySettings.features.userProjectIncomeLabel")}
            />
            <FormHelperText>
              {t("pages.companySettings.features.userProjectIncomeDesc")}
            </FormHelperText>
          </FormGroup>
          {/*<FormGroup>*/}
          {/*  <FormControlLabel*/}
          {/*    control={*/}
          {/*      <Switch*/}
          {/*        checked={!!values.timesheetReminders}*/}
          {/*        onChange={handleTimesheetReminders}*/}
          {/*      />*/}
          {/*    }*/}
          {/*    label="Timesheet reminders"*/}
          {/*  />*/}
          {/*  <FormHelperText>*/}
          {/*    Get a notification in the dashboard when users is below a certain*/}
          {/*    treshold for hours filled in a month, and remind them.*/}
          {/*  </FormHelperText>*/}
          {/*</FormGroup>*/}
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={!!values.projectSalaryRate}
                  onChange={handleProjectSalaryRate}
                />
              }
              label={t("pages.companySettings.features.projectSalaryRateLabel")}
            />
            <FormHelperText>
              {t("pages.companySettings.features.projectSalaryRateDesc")}
            </FormHelperText>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={!!values.userProjectedSalary}
                  onChange={handleUserProjectedSalary}
                />
              }
              label={t(
                "pages.companySettings.features.userProjectedSalaryLabel"
              )}
            />
            <FormHelperText>
              {t("pages.companySettings.features.userProjectedSalaryDesc")}
            </FormHelperText>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={!!values.nettlonn}
                  onChange={handleNettlonn}
                />
              }
              label={t("pages.companySettings.features.nettlonnLabel")}
            />
            <FormHelperText>
              {t("pages.companySettings.features.nettlonnDesc")}
            </FormHelperText>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={!!values.approvalRecords}
                  onChange={handleApprovalRecords}
                />
              }
              label={t("pages.companySettings.features.approvalRecordsLabel")}
            />
            <FormHelperText>
              {t("pages.companySettings.features.approvalRecordsDesc")}
            </FormHelperText>
          </FormGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
};
