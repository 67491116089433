export const format = ({ options, locales, number }) => {
  const defaultOptions = { style: "currency", currency: "NOK" };
  const defaultLocale = "nb-NO";
  return new Intl.NumberFormat(
    locales || defaultLocale,
    options || defaultOptions
  ).format(number);
};

const NumberFormatIntl = (props) => {
  const formatted = format(props);
  return formatted;
};

export default NumberFormatIntl