import { useMemo } from "react";
import XstatsUserProjectHoursByWeek from "../models/XstatsUserProjectHoursByWeek";

const useActiveUsersFromStats = (
  stats: XstatsUserProjectHoursByWeek[] = []
) => {
  const data = useMemo(() => {
    const users = new Set<string>();
    for (let stat of stats) {
      users.add(stat.user);
    }
    return users;
  }, [stats]);

  return data;
};

export default useActiveUsersFromStats;
