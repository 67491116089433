import {
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  all, take,
} from "redux-saga/effects";
import {WATCH_ARCHIVE} from "./constants";
// import UserGroup from "../../../../../models/UserGroup";
// import {WATCH_GET_USERS, WATCH_SAVE} from "./constants";
// import { watchGetUsers} from "@superprofit/core-redux-saga-modules/Users/actions";
// import { watchSaveUserGroup } from "../../../api/userGroups/actions";
// import { SAVE_SUCCESS, SAVE_FAILURE } from "../../../api/userGroups/constants";
import { RootState } from "../../../../../index";
import UserGroup from "../../../../../models/UserGroup";
import {watchBatchUserGroups} from "../../../api/userGroups/actions";
import {BATCH_FAILURE, BATCH_SUCCESS} from "../../../api/userGroups/constants";
// import {hideDialog, setError, setSaving} from "./actions";
// import {delay} from "@redux-saga/core/effects";
//
const getStaged = ({ ui: { userGroups } }: RootState) =>
  userGroups.page?.stagedArchiving || [];


export const archiveUserGroups = function* (): any {

  const staged: UserGroup[] = yield select(getStaged);
  yield put(watchBatchUserGroups(staged.map(u => u.clone().setData({ archived: true}))));
  const response = yield take([BATCH_SUCCESS, BATCH_FAILURE]);
  // yield put(setSaving(true))
  // yield delay(1000); // Give an impression of loading
  if (response.type === BATCH_SUCCESS) {
    // yield put(hideDialog())
  } else {
    console.error('[ui/usergroups/page/saga] An error occured', response)
    // yield put(setError('Could not save UserGroup'))
  }
  // yield put(setSaving(false))

};

export const archiveUserGroupsSaga = function* () {
  yield takeEvery(WATCH_ARCHIVE, archiveUserGroups);
};

export default function* () {
  yield all([archiveUserGroupsSaga()]);
}
