import {
  SET_TIMESHEET_PROJECTS,
  WATCH_FILTER_TIMESHEET_PROJECTS
} from "./constants";

export const setTimesheetProjects = projects => ({
  type: SET_TIMESHEET_PROJECTS,
  payload: { projects }
});

export const watchFilterProjects = () => ({
  type: WATCH_FILTER_TIMESHEET_PROJECTS
});
