interface NumberFormatOptions {
  localeMatcher?: string;
  style?: string;
  currency?: string;
  currencyDisplay?: string;
  useGrouping?: boolean;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
}

type NumberFormatIntlProps = {
  options?: NumberFormatOptions;
  locales?: string | string[];
  number: number;
};
export const format = ({ options, locales, number }: NumberFormatIntlProps) => {
  const defaultOptions = { style: "currency", currency: "NOK" };
  const defaultLocale = "nb-NO";
  return new Intl.NumberFormat(
    locales || defaultLocale,
    options || defaultOptions
  ).format(number);
};

const NumberFormatIntl = (props: NumberFormatIntlProps) => {
  return format(props);
};

export default NumberFormatIntl;
