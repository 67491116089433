import Entry from "../../../../models/Entry";
import TimesheetComment from "../../../../models/TimesheetComment";
import { filterStats } from "../../../../utils/StatsFilter";
import { createTableExport } from "../../../../utils/report/data/UserTableExport";
import { saveAsPDF, saveAsXLSX } from "../../../../utils/report";
import { Filters, IUserTable } from "../shared/types";
import Project from "../../../../models/Project";
import User from "../../../../models/User";
import useWorkspaceDisplayName from "../../../../hooks/useWorkspaceDisplayName";
import { ANALYTICS_EVENT, logEvent } from "../../../../firebase";
import { useTranslation } from "react-i18next";
import { format } from "../../../../date-fns-wrappers";
import { createTableExport as createTableExportBasis } from "@superprofit/timet-react-client/src/utils/report/data/BasisExport";
import {
  useFeatures,
  useUserAcccess
} from "@superprofit/timet-react-client/src/helpers";
import useCustomers from "@superprofit/timet-react-client/src/hooks/useCustomers";

type RawEntry = Entry & { total?: number };
const useExport = ({
  filters,
  filteredUsers,
  workspace,
  year,
  month,
  projects
}: {
  filteredUsers: User[];
  filters: Filters;
  workspace: string;
  year: number;
  month: number;
  projects: Project[];
}) => {
  const workspaceDisplayName = useWorkspaceDisplayName();
  const features = useFeatures();
  const { data: customers } = useCustomers();
  const { isAdmin } = useUserAcccess();

  const { t } = useTranslation();
  const handleExport = async (type: "XLSX" | "PDF") => {
    logEvent(ANALYTICS_EVENT.USER_HOURS_EXPORTED, {
      workspaceName: workspace,
      type
    });

    const userIds = filteredUsers.map(u => u.id);
    const rawEntries = (await Entry.getAllByUsers(
      workspace,
      userIds,
      year,
      month
    )) as RawEntry[];
    rawEntries.forEach((e: RawEntry) => (e.total = e.hours || 0));

    const rawComments = await TimesheetComment.getAllByUsers(
      workspace,
      userIds,
      year,
      month
    );
    let mergedEntries = rawEntries.map(e => {
      const com = rawComments.filter(
        c =>
          c.year === e.year &&
          c.day === e.dayOfYear &&
          c.user === e.user &&
          c.project === e.project
      );
      return { ...e, comments: com };
    });
    const filteredEntries = filters
      ? filterStats(
          mergedEntries,
          projects,
          filters.type,
          filters.projects,
          filters.users,
          filters.customers
        )
      : mergedEntries;
    const formattedDate = format(new Date(year, month - 1, 1), "MMMM yyyy");

    let filename = t("pages.reports.filenames.userHours_multiple", {
      formattedDate
    });

    if (userIds.length === 1) {
      const user = filteredUsers.find(u => u.id === userIds[0]);
      if (user) {
        filename = t("pages.reports.filenames.userHours_one", {
          formattedDate,
          userName: user.displayName || user.id
        });
      }
    }

    if (type === "PDF") {
      const pdfData = createTableExport(
        filteredUsers,
        projects,
        filteredEntries
      );
      await saveAsPDF(pdfData, filename, {
        workspaceDisplayName
      });
    } else if (type === "XLSX") {
      const csvData = createTableExportBasis(
        filteredUsers,
        projects,
        filteredEntries,
        customers,
        features,
        isAdmin
      );
      await saveAsXLSX(csvData, filename);
    }
  };

  return { handleExport };
};
export default useExport;
