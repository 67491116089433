import styled, { css } from "styled-components";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import Stepper from "@material-ui/core/Stepper";

export const StyledQontoConnector = styled(StepConnector)`
  .MuiStepConnector-line {
    border-color: #eaeaf0;
    border-top-width: 3px;
    border-radius: 1px;
  }

  ${props =>
    props.alternativeLabel &&
    css`
      top: 10px;
      left: calc(-50% + 16px);
      right: calc(50% + 16px);
    `};

  ${props =>
    props.active &&
    css`
      .MuiStepConnector-line {
        border-color: ${props => props.theme.palette.secondary.main};
      }
    `};

  ${props =>
    props.completed &&
    css`
      .MuiStepConnector-line {
        border-color: ${props => props.theme.palette.secondary.main};
      }
    `};
`;

export const StyledQontoStepIcon = styled.div`
  color: #eaeaf0;
  display: flex;
  height: 22px;
  align-items: center;

  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.palette.secondary.main};
    `};
`;

export const StyledQontoCheckIcon = styled(Check)`
  color: ${props => props.theme.palette.secondary.main};
  z-index: 1;
  font-size: 18px;
`;

export const StyledQontoCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: currentColor;
`;

export const StyledStepper = styled(Stepper)``;
