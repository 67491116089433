
import createSagas from '../Helpers/moduleFactory/sagas';
import actions from './actions'
import constants from './constants';
import {UserAccess} from '@superprofit/core-firestore-models';



let dbRef = { current: null };
export const init = (database) => {
  dbRef.current = database;
};

export default createSagas(UserAccess, constants, actions, null, dbRef);

