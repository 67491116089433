import React from "react";
import Card from "@superprofit/core-react-components/atoms/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Logged from "./userStats/Logged";
import Planned from "./userStats/Planned";

export default ({
  month,
  year,
  currentMonthStats,
  loading,
  projects,
  user,
  ...rest
}) => {
  return (
    <Card {...rest}>
      <CardContent>
        <Grid container spacing={3} component="div" alignItems="center">
          <Grid item xs={12} md={6} component="div">
            <Logged
              loading={loading}
              projects={projects}
              stats={currentMonthStats}
              year={year}
              month={month}
            />
          </Grid>
          <Grid item xs={12} md={6} component="div">
            <Planned
              projects={projects}
              loading={loading}
              stats={currentMonthStats}
              user={user}
              month={month}
              year={year}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
