import firebase from "firebase/compat/app";

export default {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        // Forces account selection even when one account
        // is available.
        prompt: "select_account"
      }
    },
    // {
    //   provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
    // },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    }
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  tosUrl: "https://timet.io/terms-and-conditions",
  // Privacy policy url/callback.
  privacyPolicyUrl: "https://timet.io/privacy-policy",
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  }
};
