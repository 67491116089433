import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../index";
import { useEffect } from "react";

import { resetFilters } from "../../../../redux/modules/ui/report/filters/actions";
import { showFilterDialog } from "../../../../redux/modules/ui/report/filtersDialog/actions";

const useFilters = () => {
  const { hasFilters, filters } = useSelector(
    (state: RootState) => state.ui.report.filters
  );
  const dispatch = useDispatch();
  useEffect(() => {
    return () => void dispatch(resetFilters());
  }, []);

  const toggleModal = () => {
    dispatch(showFilterDialog(filters));
  };

  return {
    hasFilters,
    filters,
    toggleModal
  };
};

export default useFilters;
