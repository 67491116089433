import React, { useMemo, useState } from "react";
import { TableCell, TableRow } from "../../../components/atoms/Table";
import IconButton from "../../../components/atoms/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ProjectAvatar from "../../../components/molecules/ProjectAvatar";
import { CheckableAvatar } from "../../../components/molecules/CheckableAvatar";
import Button from "@material-ui/core/Button";
import Project from "@superprofit/timet-react-client/src/models/Project";
import DurationFormat from "../../../components/atoms/DurationFormat";
import { useTranslation } from "react-i18next";
import Label from "@superprofit/core-react-components/atoms/Label";
import Entry from "@superprofit/timet-react-client/src/models/Entry";
import entries from "@superprofit/timet-react-client/src/redux/modules/ui/timesheet/entries";
import { ApprovalRecord } from "@superprofit/timet-react-client/src/models/ApprovalRecords";
import { Status } from "@superprofit/timet-react-client/src/pages/protected/userApproval/Status";
import { eachDayOfInterval, isEqual } from "date-fns";
import { format } from "../../../date-fns-wrappers";
import { EntryRow } from "@superprofit/timet-react-client/src/pages/protected/userApproval/projectItem/EntryRow";

type ProjectItemProps = {
  project: Project;
  entries?: Entry[];
  approvalRecord?: ApprovalRecord;
  startDate: Date;
  endDate: Date;
  onSubmit: (project: Project) => void;
  isSubmitting: boolean;
};

export const ProjectItem = ({
  project,
  entries = [],
  approvalRecord,
  startDate,
  endDate,
  onSubmit,
  isSubmitting
}: ProjectItemProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const daysDates = eachDayOfInterval({
    start: startDate,
    end: endDate
  });
  const hours = useMemo(() => {
    return entries?.reduce((acc, entry) => acc + entry.hours, 0);
  }, [entries]);

  const getEntryByDate = (date: Date) => {
    return entries.find(entry =>
      isEqual(new Date(entry.year, 0, entry.dayOfYear), date)
    );
  };

  const handleOnClick = () => {
    onSubmit(project);
  };
  return (
    <>
      <TableRow>
        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <IconButton
            aria-label={t("common.expandRow")}
            size="medium"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <ProjectAvatar project={project} />
        </TableCell>
        <TableCell>
          <div>{project.name}</div>
        </TableCell>
        <TableCell>
          <Status approvalRecord={approvalRecord} />
        </TableCell>
        <TableCell>
          {format(startDate, "d.MMMM")} - {format(endDate, "d.MMMM")}
        </TableCell>
        <TableCell align="right">
          <DurationFormat value={hours} component="b" />
        </TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            color="primary"
            disabled={approvalRecord?.status || isSubmitting}
            onClick={handleOnClick}
          >
            {t("common.submit")}
          </Button>
        </TableCell>
      </TableRow>
      {open &&
        daysDates.map(date => (
          <EntryRow date={date} hours={getEntryByDate(date)?.hours} />
        ))}
    </>
  );
};
