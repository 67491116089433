import { useMemo } from "react";
import * as yup from "yup";
import { IFormInput } from "./types";
import useFeatures from "../../../../hooks/useFeatures";
import { useTranslation } from "react-i18next";
import { isSalaryEnabledFromSalaryType } from "./helpers";

export const useSchema = () => {
  const features = useFeatures();
  const { t } = useTranslation();
  return useMemo(() => {
    const defaultSchema = yup.object({
      salaryEnabled: yup.bool(),
      globalSalaryPercentageEnabled: yup.bool(),
      managerEmail: yup.string().email(t("common.invalidEmail")),
      managerName: yup.string(),
      salaryRate: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .nullable()
        .when("salaryType", {
          is: (salaryType: IFormInput["salaryType"]) => salaryType === "fixed",
          then: () =>
            yup
              .number()
              .required()
              .typeError(t("common.invalidNumber"))
        }),
      name: yup.string().required(),
      billableRate: yup
        .number()
        .positive()
        .transform(value => (isNaN(value) ? undefined : value))
        .nullable()
        .when("billable", {
          is: true,
          then: () =>
            yup
              .number()
              .required()
              .typeError(t("common.invalidNumber"))
              .test(
                "is-decimal",
                t("common.invalidNumber"),
                value =>
                  value !== undefined &&
                  /^-?\d+(\.\d{1,2})?$/.test(value.toString())
              )
        })
        .when("salaryType", {
          is: (salaryType: IFormInput["salaryType"]) =>
            salaryType === "provision",
          then: () =>
            yup
              .number()
              .positive()
              .required()
              .typeError(t("common.invalidNumber"))
              .test(
                "is-decimal",
                t("common.invalidNumber"),
                value =>
                  value !== undefined &&
                  /^-?\d+(\.\d{1,2})?$/.test(value.toString())
              )
        })
    });
    if (features?.nettlonn) {
      const nettlonnSchema = yup.object({
        nettlonnSalaryArt: yup
          .number()
          .transform(value => (isNaN(value) ? undefined : value))
          .nullable()
          .when("salaryType", {
            is: isSalaryEnabledFromSalaryType,
            then: () =>
              yup
                .number()
                .required()
                .typeError(t("common.invalidNumber"))
          }),
        nettlonnDimensionOne: yup
          .number()
          .transform(value => (isNaN(value) ? undefined : value))
          .nullable()
          .when("salaryType", {
            is: isSalaryEnabledFromSalaryType,
            then: () =>
              yup
                .number()
                .required()
                .typeError(t("common.invalidNumber"))
          })
      });
      return defaultSchema.concat(nettlonnSchema);
    }
    return defaultSchema;
  }, [t]);
};
