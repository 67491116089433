import useUserProfile from "../../../../hooks/useUserProfile";
import useUserProfileMutation from "../../../../hooks/useUserProfileMutation";

const useHiddenProjectTimesheets = ({ userId }: { userId?: string } = {}) => {
  const { data: userProfile } = useUserProfile({ userId });
  const mutation = useUserProfileMutation();
  const hidden = new Set<string>(userProfile?.hiddenProjectTimesheets || []);
  const toggleHidden = (id: string | null) => {
    if (id === null) return;
    hidden.has(id) ? hidden.delete(id) : hidden.add(id);

    mutation.mutate({
      ...userProfile,
      hiddenProjectTimesheets: Array.from(hidden)
    });
  };

  return { toggleHidden, hidden };
};

export default useHiddenProjectTimesheets;
