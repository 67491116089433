import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import useUnarchivedProjects from "@superprofit/timet-react-client/src/hooks/useUnarchivedProjects";
import useUsers from "@superprofit/timet-react-client/src/hooks/useUsers";
import useCustomers from "@superprofit/timet-react-client/src/hooks/useCustomers";
import useCustomersMap from "@superprofit/timet-react-client/src/hooks/useCustomersMap";
import useProjectsMap from "@superprofit/timet-react-client/src/hooks/useProjectsMap";
import useUsersMap from "@superprofit/timet-react-client/src/hooks/useUsersMap";
import FormDialog from "@superprofit/timet-react-client/src/components/molecules/FormDialog";
import AutocompleteUsers from "@superprofit/timet-react-client/src/components/molecules/AutocompleteUsers";
import Project from "@superprofit/timet-react-client/src/models/Project";
import Customer from "@superprofit/timet-react-client/src/models/Customer";
import MultiSelectProjects from "@superprofit/timet-react-client/src/components/molecules/MultiSelectProjects";
import MultiSelectCustomers from "@superprofit/timet-react-client/src/components/molecules/MultiSelectCustomers";
import Select from "@superprofit/timet-react-client/src/components/atoms/Select";
import MenuItem from "@superprofit/timet-react-client/src/components/atoms/MenuItem";
import InputLabel from "@superprofit/timet-react-client/src/components/atoms/InputLabel";
import TimetUser from "@superprofit/timet-react-client/src/models/TimetUser";
import useUserGroups from "@superprofit/timet-react-client/src/hooks/useUserGroups";

type FilterState = {
  filters: {
    types: ["all" | "billable" | "nonBillable"];
    projects: string[];
    users: string[];
    customers: string[];
  };
  isOpen: boolean;
  hasFilters: boolean;
};

const initialState: FilterState = {
  filters: {
    types: ["all"],
    projects: [],
    users: [],
    customers: []
  },
  isOpen: false,
  hasFilters: false
};

type FilterAction =
  | { type: "SET_OPEN"; isOpen: boolean }
  | {
      type: "SET_FILTER";
      filterName: keyof FilterState["filters"];
      value: string[];
    }
  | { type: "SET_ALL_FILTERS"; filters: FilterState["filters"] }
  | { type: "RESET_FILTERS" };

interface FilterContextValue {
  state: FilterState;
  dispatch: React.Dispatch<FilterAction>;
}

const FilterContext = createContext<FilterContextValue | undefined>(undefined);

const filterReducer = (
  state: FilterState,
  action: FilterAction
): FilterState => {
  switch (action.type) {
    case "SET_OPEN":
      return {
        ...state,
        isOpen: action.isOpen
      };

    case "SET_ALL_FILTERS":
      return {
        ...state,
        filters: action.filters,
        hasFilters:
          JSON.stringify(action.filters) !==
          JSON.stringify(initialState.filters)
      };

    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filterName]: action.value
        },
        hasFilters:
          JSON.stringify({
            ...state.filters,
            [action.filterName]: action.value
          }) !== JSON.stringify(initialState.filters)
      };
    case "RESET_FILTERS":
      return initialState;
    default:
      throw new Error(`Unknown action type: ${(action as any)?.type}`);
  }
};

export const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(filterReducer, initialState);

  return (
    <FilterContext.Provider value={{ state, dispatch }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterDialog = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilterDialog must be used within a FilterProvider");
  }

  const setIsOpen = (isOpen: boolean) => {
    context.dispatch({ type: "SET_OPEN", isOpen });
  };

  const setFilter = (
    filterName: keyof FilterState["filters"],
    value: string[]
  ) => {
    context.dispatch({ type: "SET_FILTER", filterName, value });
  };

  const setFilters = (filters: FilterState["filters"]) => {
    context.dispatch({ type: "SET_ALL_FILTERS", filters });
  };

  const resetFilters = () => {
    context.dispatch({ type: "RESET_FILTERS" });
  };

  return {
    state: context.state,
    setIsOpen,
    setFilter,
    setFilters,
    resetFilters
  };
};

type FiltersBeforeApplyState = FilterState["filters"];

export const FilterDialog = () => {
  const { state, setIsOpen, setFilters, resetFilters } = useFilterDialog();
  const { filters, isOpen } = state;
  const { t } = useTranslation();
  const [isClosable, setIsClosable] = useState(true);
  const [filtersBeforeApply, setFiltersBeforeApply] = useState<
    FiltersBeforeApplyState
  >(filters);

  const { data: allUserGroups = [] } = useUserGroups();

  const { data: projects = [] } = useUnarchivedProjects();
  const { data: users = [] } = useUsers();
  const { data: customers = [] } = useCustomers();

  const customersMap = useCustomersMap();
  const projectsMap = useProjectsMap();
  const usersMap = useUsersMap();

  const filteredProjects = useMemo(() => {
    if (filtersBeforeApply.customers.length === 0) return projects;

    return projects.filter(p => {
      return filtersBeforeApply.customers.indexOf(p.customer) > -1;
    });
  }, [filtersBeforeApply.customers, projects]);

  const filteredUsers = useMemo(() => {
    return users.filter(u => {
      const ps =
        filtersBeforeApply.projects.length > 0
          ? filtersBeforeApply.projects
              .map(p => projectsMap.get(p))
              .filter((p): p is Project => !!p)
          : filteredProjects;
      return ps.some(p => Project.isUserInProject(u.email, p, allUserGroups));
    });
  }, [filtersBeforeApply.projects, users, allUserGroups, projectsMap]);

  const handleConfirmClose = () => {
    if (isClosable) {
      setIsOpen(false);
      setFiltersBeforeApply(filters);
    }
  };

  const setFilterBeforeApply = (
    key: keyof FiltersBeforeApplyState,
    value: string[]
  ) => {
    setFiltersBeforeApply(current => {
      return {
        ...(current || initialState.filters),
        [key]: value
      };
    });
  };

  const handleOnChangeProjects = (
    e: React.ChangeEvent<{}>,
    selected: Project[]
  ) => {
    setFilterBeforeApply(
      "projects",
      selected.map((p: Project) => p.id as string)
    );
  };

  const handleOnChangeCustomers = (
    e: React.ChangeEvent<{}>,
    selected: Customer[]
  ) => {
    const selectedCustomers = selected.map((c: Customer) => c.id);
    const selectedProjects = filtersBeforeApply.projects
      .map(id => projectsMap.get(id))
      .filter(p => {
        return p && selectedCustomers.indexOf(p.customer) > -1;
      })
      .filter((p): p is Project => !!p);
    setFilterBeforeApply("customers", selectedCustomers);
    setFilterBeforeApply(
      "projects",
      selectedProjects.map((p: Project) => p.id as string)
    );
  };

  const handleOnChangeUsers = (e: unknown, selected: TimetUser[]) => {
    setFilterBeforeApply(
      "users",
      selected.map((u: TimetUser) => u.id)
    );
  };

  const handleOnChangeType = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (typeof e.target.value !== "string") {
      return;
    }
    setFilterBeforeApply("types", [e.target.value]);
  };

  const handleOnApply = () => {
    if (filtersBeforeApply) {
      setFilters(filtersBeforeApply);
    }
    setIsOpen(false);
  };

  const hasProjectFilter = filtersBeforeApply.projects.length > 0;
  const hasUserFilter = filtersBeforeApply.users.length > 0;
  const hasCustomerFilter = filtersBeforeApply.customers.length > 0;

  return (
    <FormDialog
      inProgress={false}
      key="form"
      saveActionTitle={t("common.save")}
      onSave={handleOnApply}
      open={isOpen}
      onClose={handleConfirmClose}
      title="Filter"
    >
      <div>
        <InputLabel variant="standard" shrink={false} id="project-type-label">
          {`${t("common.project")} ${t("common.type")}`}
        </InputLabel>
        <Select
          fullWidth
          variant="filled"
          labelId="project-type-label"
          id="project-budget-type"
          value={filtersBeforeApply.types?.[0] || "all"}
          name="project-type"
          onChange={handleOnChangeType}
        >
          {["all", "billable", "nonBillable"].map(type => (
            <MenuItem key={type} value={type}>
              {t(`common.${type}`)}
            </MenuItem>
          ))}
        </Select>
      </div>

      <MultiSelectCustomers
        onClose={() => {
          setIsClosable(true);
        }}
        value={filtersBeforeApply.customers.map(c => customersMap.get(c))}
        customers={customers}
        onChange={handleOnChangeCustomers}
        onOpen={() => setIsClosable(false)}
        variant="filled"
        fullWidth
        label={t("common.customers")}
        placeholder={hasCustomerFilter ? "" : t("common.all")}
      />

      <MultiSelectProjects
        onClose={() => {
          setIsClosable(true);
        }}
        value={filtersBeforeApply.projects.map(p => projectsMap.get(p))}
        projects={filteredProjects}
        onChange={handleOnChangeProjects}
        onOpen={() => setIsClosable(false)}
        variant="filled"
        fullWidth
        label={t("common.projects")}
        placeholder={hasProjectFilter ? "" : t("common.all")}
      />

      <AutocompleteUsers
        onClose={() => {
          setIsClosable(true);
        }}
        value={
          filtersBeforeApply.users.map(u => usersMap.get(u)) as TimetUser[]
        }
        users={filteredUsers}
        onChange={handleOnChangeUsers}
        onOpen={() => setIsClosable(false)}
        variant="filled"
        fullWidth
        label={t("common.users")}
        placeholder={hasUserFilter ? "" : t("common.all")}
      />
    </FormDialog>
  );
};
