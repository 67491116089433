import {
  takeLatest,
  call,
  put,
  select,
  all,
  takeEvery
} from "redux-saga/effects";
import { WATCH_GET } from "./constants";
import {
  get,
  getFailure,
  getSuccess,
  save,
  saveFailure,
  saveSuccess,
  deleteUser as deleteUserAction,
  deleteFailure,
  deleteSuccess
} from "./actions";
import { WATCH_SAVE, WATCH_DELETE } from "./constants";
import { User } from "@superprofit/core-firestore-models";
import Isemail from 'isemail';

let db;
export const init = (database) => {
  db = database;
};

export const getUsers = function* getUsers(args) {
  const { payload: { UserClass = User } } = args;
  try {
    yield put(get());
    const workspace = yield select(
      ({ api: { auth } }) => auth.activeWorkspaceId
    );
    const users = yield call(UserClass.list, db, workspace);
    yield put(getSuccess(users));
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const saveUser = function*(args) {
  const { payload: { UserClass = User, user, updates = {} } } = args;

  try {
    const workspace = yield select(
      ({ api: { auth } }) => auth.activeWorkspaceId
    );
    const creator = yield select(({ api: { auth } }) => auth.user);
    yield put(save());

    let saved;
    if (!user.id) {
      saved = yield call(UserClass.create, db, workspace, creator.email, {...user, ...updates});
    } else {
      saved = yield call(UserClass.update, db, workspace, creator.email, user, updates);
    }
    yield put(saveSuccess(saved));
    // yield call(getUsers); // hmm, hvorfor gjør vi denne?
    // Test user email/pw
    /*firebase.auth().createUserWithEmailAndPassword(user.email, '1234test').catch(function(error) {
        console.error('ERROR creating user', error)
      });*/
  } catch (e) {
    console.error("Error", e);
    yield put(saveFailure(e.toString()));
  }
};

export const deleteUser = function*(args) {
  const { payload } = args;
  const { id } = payload;

  try {
    const workspace = yield select(
      ({ api: { auth } }) => auth.activeWorkspaceId
    );
    yield put(deleteUserAction());
    yield db
      .collection("workspaces")
      .doc(workspace)
      .collection("users")
      .doc(id)
      .delete();
    yield put(deleteSuccess());
    yield call(getUsers);
  } catch (e) {
    yield put(deleteFailure());
  }
};

export const getUsersSaga = function* getUsersSaga() {
  yield takeLatest(WATCH_GET, getUsers);
};

export const saveUserSaga = function*() {
  yield takeLatest(WATCH_SAVE, saveUser);
};

export const deleteUserSaga = function*() {
  yield takeEvery(WATCH_DELETE, deleteUser);
};

export default function*() {
  yield all([getUsersSaga(), saveUserSaga(), deleteUserSaga()]);
}
