import { all, delay, put, takeLatest } from "@redux-saga/core/effects";
import { SAVE, SAVE_SUCCESS } from "../../../api/customers/constants";

import { updateSnackbar } from "./actions";

export const handleCustomerSaving = function*(args) {
  try {
    yield put(updateSnackbar({ visible: true, text: "Saving" }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleCustomerSaved = function*(args) {
  const { isNew } = args.payload;
  try {
    if (isNew) {
      yield put(updateSnackbar({ visible: true, text: "Customer saved." }));
    } else {
      yield put(updateSnackbar({ visible: true, text: "Customer updated." }));
    }
    yield delay(2000);
    yield put(updateSnackbar({ visible: false }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleCustomerSavingSaga = function*() {
  yield takeLatest(SAVE, handleCustomerSaving);
};

export const handleCustomerSavedSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleCustomerSaved);
};

export default function*() {
  yield all([handleCustomerSavingSaga(), handleCustomerSavedSaga()]);
}
