import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useWorkspace, useWorkspaceHistory } from "./WorkspaceContext";
import TimetUser from "../models/TimetUser";
import InfoBox from "../components/atoms/infoBox/InfoBox";
import Button from "@material-ui/core/Button";

const TutorialContext = React.createContext({
  skip: noop => noop,
  next: noop => noop,
  data: {},
  currentStep: -1
});

export const useTutorial = stepIndex => {
  const { activeIndex, completed, complete, data, next, skipAll } = useContext(
    TutorialContext
  );
  return {
    next,
    data,
    complete,
    skipAll,
    isCompleted:
      stepIndex < activeIndex || (stepIndex === activeIndex && completed),
    isActive: stepIndex === activeIndex,
    isHighlighted: stepIndex === activeIndex && !completed
  };
};

const TutorialContextProvider = ({ steps, type, children }) => {
  const { activeWorkspaceId, user } = useWorkspace();
  const history = useWorkspaceHistory();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [completed, setCompleted] = useState(false);
  const [timetUser, setTimetUser] = useState(null);

  const updateUserOnboarding = async (index, isCompleted = false) => {
    await timetUser.updateOnboarding(activeWorkspaceId, type, {
      activeIndex: index,
      isCompleted
    });
    setCompleted(isCompleted);
    setActiveIndex(index);
  };

  const complete = async () => {
    await updateUserOnboarding(activeIndex, true);
  };

  const skipAll = async () => {
    await updateUserOnboarding(steps.length, false);
  };

  const next = async () => {
    return updateUserOnboarding(activeIndex + 1, false);
  };

  useEffect(() => {
    if (!user || !activeWorkspaceId) return;
    async function getTimetUser() {
      const res = await TimetUser.get(activeWorkspaceId, user.email);
      setTimetUser(res);
    }
    getTimetUser();
  }, [user, activeWorkspaceId]);

  useEffect(() => {
    if (!timetUser) return;
    async function getTutorialState() {
      const pathOnboarding = timetUser.getOnboarding(type);

      if (!pathOnboarding) {
        await updateUserOnboarding(0, false);
      } else {
        setActiveIndex(pathOnboarding.activeIndex || 0);
        setCompleted(pathOnboarding.isCompleted);
      }
    }
    getTutorialState();
  }, [timetUser]);

  const data = activeIndex >= 0 ? steps[activeIndex] : null;

  const actions = [
    <Button color="inherit" aria-label="Show more tutorials" onClick={next}>
      {activeIndex < steps.length - 1 ? "Next" : "Hide"}
    </Button>
  ];

  if (data && data.link) {
    actions.push(
      <Button
        color="inherit"
        aria-label={data.linkText}
        onClick={() => history.push(data.link)}
      >
        {data.linkText}
      </Button>
    );
  }

  return (
    <TutorialContext.Provider
      value={{
        activeIndex,
        completed,
        complete,
        skipAll,
        next,
        data
      }}
    >
      <>
        {children}
        {data && (
          <InfoBox
            open={!completed && activeIndex < steps.length}
            title={data.title}
            message={data.message}
            actions={actions}
          />
        )}
      </>
    </TutorialContext.Provider>
  );
};

TutorialContextProvider.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
      link: PropTypes.string,
      linkText: PropTypes.string
    })
  ).isRequired,
  type: PropTypes.oneOf(["projects", "users", "customers", "timesheet"])
};

export default TutorialContextProvider;
