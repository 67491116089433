import {
  HIDE_DIALOG, SET_IS_CLOSABLE, SET_SAVING, SET_SHOW_CONFIRMATION,

  SHOW_DIALOG,

  UPDATE_DIALOG_DATA,
} from "./constants";
import { DialogAction, DialogState } from "./types";
import { createReducer } from "typesafe-actions";

import UserGroup from "../../../../../models/UserGroup";

const initialState: DialogState = {
  open: false,
  userGroup: new UserGroup(),
  original: null,
  saving: false,
  hasUnsaved: false,
  error: null,
  isClosable: true,
  showConfirmation: false
};

const reducer = createReducer<DialogState, DialogAction>(initialState)
  .handleType(HIDE_DIALOG, (state) => ({ ...state, open: false }))
  .handleType(SET_IS_CLOSABLE, (state, action) => ({ ...state, isClosable: action.payload.isClosable }))
  .handleType(SET_SAVING, (state, action) => ({ ...state, saving: action.payload.saving }))
  .handleType(SET_SHOW_CONFIRMATION, (state, action) => ({ ...state, showConfirmation: action.payload.show }))
  .handleType(SHOW_DIALOG, (state, { payload }) => ({
    ...state,
    open: true,
    userGroup: payload.userGroup || new UserGroup(),
    original: payload.userGroup || null
  }))
  .handleType(UPDATE_DIALOG_DATA, (state, {payload}) => ({
    ...state,
    hasUnsaved: JSON.stringify(state.original || new UserGroup().data()) == JSON.stringify(state.userGroup?.clone().setData(payload.updates).data()),
    userGroup: state.userGroup?.clone().setData(payload.updates),
  }))

//
// export default (state = initialState, action) => {
//   switch (action.type) {
//     case SHOW_USER_DIALOG:
//       return {
//         ...initialState,
//         open: true,
//         user: action.payload.user,
//       };
//
//     case SAVE:
//       return {
//         ...state,
//         saving: true,
//       };
//
//     case SAVE_SUCCESS: {
//       if (state.addMultipleUsers) {
//         return {
//           ...initialState,
//           addMultipleUsers: true,
//           open: true,
//           hasUnsaved: false,
//         };
//       } else {
//         return {
//           ...state,
//           open: false,
//           hasUnsaved: false,
//         };
//       }
//     } // Hide dialog when saved, use sagas?
//     case SAVE_FAILURE: {
//       return {
//         ...state,
//         open: true,
//         saving: false,
//         error: {
//           message:
//             "Something went wrong. Please try again. If the problem persist, please give us a message.",
//         },
//       };
//     }
//     case HIDE_USER_DIALOG:
//       return {
//         ...state,
//         open: false,
//         hasUnsaved: false,
//       };
//
//     case UPDATE_USER_DIALOG_DATA:
//       return {
//         ...state,
//         updates: {
//           ...state.updates,
//           ...action.payload.updates,
//         },
//         hasUnsaved: true,
//       };
//
//     case TOGGLE_ADD_MULTIPLE_USERS:
//       return {
//         ...state,
//         addMultipleUsers: !state.addMultipleUsers,
//       };
//     case SET_INVALID_EMAIL_MESSAGE:
//       return {
//         ...state,
//         invalidEmailMessage: action.payload.message,
//       };
//
//     default:
//       return state;
//   }
// };

export default reducer;
