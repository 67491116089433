import React, { forwardRef } from "react";
import Avatar from "../atoms/Avatar";
import { ElevatedTextField } from "../atoms/TextField";
import IconButton from "../atoms/IconButton";
import CircularProgress from "../atoms/CircularProgress";
import { Send } from "@material-ui/icons";
import { CommentForm } from "./commentForm/CommentForm.styles";

export default forwardRef(
  ({ isSaving, user, inputRef, onSave, ...rest }, ref) => (
    <CommentForm ref={ref}>
      <Avatar initial={user.displayName.slice(0, 1)} />
      <ElevatedTextField
        disabled={isSaving}
        inputRef={inputRef}
        className="comment-input"
        fullWidth
        placeholder="Add a comment"
      />
      <IconButton onClick={onSave} disabled={isSaving}>
        {isSaving ? <CircularProgress size={24} /> : <Send />}
      </IconButton>
    </CommentForm>
  )
);


/*Updated form maybe
*
*
 export default forwardRef(
 ({isSaving, user, inputRef, date, handle, onSave, selectOptions, ...rest}, ref) => {
 return <Grid container ref={ref} spacing={2} alignItems="center">
 <Grid item xs={12} sm={8}><ElevatedTextField
 disabled={isSaving}
 inputRef={inputRef}
 className="comment-input"
 fullWidth
 placeholder="Add a comment"
 /> </Grid>
 {selectOptions && (
 <Grid item>
 <FormControl>
 <InputLabel id="demo-simple-select-helper-label">Age</InputLabel>
 {!isMobile() ? <Select
 style={{width: '7rem'}}
 defaultValue={0}
 variant="filled"
 labelId="demo-simple-select-helper-label"
 id="demo-simple-select-helper"
 input={(props) => <div {...props}/>}
 // value={age}
 // onChange={handleChange}
 >
 {selectOptions.map(o => <MenuItem value={o.value}>{o.label}</MenuItem>)}
 </Select>
 :
 <Select
 style={{width: '7rem'}}
 native
 variant="filled"
 defaultValue={0}
 >
 {selectOptions.map(o => <option value={o.value}>{o.label}</option>)}
 </Select>}
 </FormControl> </Grid>
 )}

 <Grid item>
 <IconButton onClick={onSave} disabled={isSaving}>
 {isSaving ? <CircularProgress size={24}/> : <Send/>}
 </IconButton>
 </Grid>


 </Grid>;
 }
 );

 * */
