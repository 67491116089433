import { takeLatest, put, select, all } from "redux-saga/effects";
import { get, getFailure, getSuccess } from "./actions";
import {
  SIGNED_IN,
  SET_WORKSPACE,
  SET_ID_TOKEN
} from "@superprofit/core-redux-saga-modules/Auth/constants";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;

export const getUserState = workspace => ({
  api: {
    auth: { user, idTokenResult }
  }
}) => ({
  user,
  role:
    (idTokenResult?.claims?.workspaces &&
      idTokenResult?.claims?.workspaces[workspace]?.role) ||
    null
});

export const getCurrentUser = function* getCurrentUser(arg) {
  try {
    yield put(get());
    const workspace = yield select(getActiveWorkspace);
    const { user, role } = yield select(getUserState(workspace));
    yield put(getSuccess(workspace, user, role));
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const getCurrentUserSaga = function* getCurrentUserSaga() {
  yield takeLatest([SIGNED_IN, SET_ID_TOKEN, SET_WORKSPACE], getCurrentUser);
};

export default function*() {
  yield all([getCurrentUserSaga()]);
}
