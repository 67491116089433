import React, { useEffect } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import { watchGetUsers } from "@superprofit/core-redux-saga-modules/Users/actions";
import { watchGetProjects } from "../../redux/modules/api/projects/actions";
import ByUserAndProject from './pdfRenderer/templates/ByUserAndProject';

export { default as CreatePDFDialog } from "./pdfRenderer/CreatePDFDialog";

export const TEMPLATES = {
  BY_PROJECT: 'byProject',
  BY_USER: 'byUser',
  BY_USER_AND_PROJECT: 'byUserAndProject'
};

export default () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.api.users.list);
  const projects = useSelector(state => state.api.projects.list);
  useEffect(() => {
    if (users.length === 0) dispatch(watchGetUsers());
    if (projects.length === 0) dispatch(watchGetProjects());
  }, [dispatch]);
  if (projects.length === 0 || users.length === 0) return "Loading";
  return (
    <PDFViewer style={{ height: "100rem" }}>
      <ByUserAndProject projects={projects} users={users} />
    </PDFViewer>
  );
};
