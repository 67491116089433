import React, { Fragment, useRef } from "react";
// hooks
import { useDispatch, useSelector } from "react-redux";

// ui
import Grid from "@material-ui/core/Grid";
import {
  HeaderContainer,
  ProjectTimesheetCardHeader,
  ProjectTitleContainer,
  StyledEntries,
  StyledWeekSummary,
} from "./Timesheet.styles";
import { NumberFormatIntl } from "@superprofit/core-react-components/atoms";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import Avatar from "@superprofit/core-react-components/atoms/Avatar";
import Card from "@superprofit/core-react-components/atoms/Card";
import CardContent from "@superprofit/core-react-components/atoms/CardContent";
import Comment from "@superprofit/core-react-components/molecules/Comment";
import CommentForm from "@superprofit/core-react-components/molecules/CommentForm";
import Entry from "./Entry";
import IconButton from "@superprofit/core-react-components/atoms/IconButton";
import UndoIcon from "@material-ui/icons/Undo";
import DragIcon from "@material-ui/icons/DragIndicator";

// other
import { dayNamesAbbrWithDate, moment } from "@superprofit/time-util";
import TimesheetEntry from "../../../models/Entry";

// actions
import {
  stageEntries,
  stageEntry,
} from "../../../redux/modules/ui/timesheet/entries/actions";
import { watchSaveTimesheetComment } from "../../../redux/modules/api/timesheet/comments/actions";
import { CircularProgress } from "@superprofit/core-react-components/atoms";
import ProjectAvatar from "../../../components/molecules/ProjectAvatar";
import DurationFormat from "../../../components/atoms/DurationFormat";
import Tooltip from "@material-ui/core/Tooltip";
import { addGlobalAlert } from "../../../redux/modules/ui/app/actions";
import { useDebouncedCallback } from "use-debounce";
import { FormHelperText } from "@superprofit/core-react-components/atoms";
import { useFeatures } from "../../../helpers";
import CommentsAccordion from "./projectTimesheet/CommentsAccordion";
import {useWindowWidth} from '@react-hook/window-size';

const MAX_HOURS_WORKING_DAYS = 5 * 24;
export const WINDOW_MAX_CARD_BREAKPOINT = 1800;
const commentsEnabled = false;

export default ({
  dragHandleProps,
  period: { year, week },
  project,
  ...rest
}) => {
  const commentInputRef = useRef(null);
  const weekRef = useRef(null);
  const windowWidth = useWindowWidth()
  const inputRefs = [];

  const dispatch = useDispatch();
  const { userProjectIncome } = useFeatures();
  const currency = useSelector((state) => {
    const curr = state.api.companySettings.settings?.currency?.value;
    return curr && curr !== "none" ? curr : undefined;
  });
  const { user } = useSelector((state) => state.api.auth);
  const { list: customers } = useSelector((state) => state.api.customers);
  const userEmail = user && user.email;

  const entries =
    useSelector(
      (state) => state.ui.timesheet.entries.entriesByProject[project.id]
    ) || [];
  const { savingIdMap } = useSelector((state) => state.api.timesheet.entries);
  const comments =
    useSelector(
      (state) => state.ui.timesheet.comments.commentsByProject[project.id]
    ) || [];
  const savingComment = useSelector(
    (state) => state.ui.timesheet.comments.commentsSavingByProject[project.id]
  );
  const savedComment = useSelector(
    (state) => state.ui.timesheet.comments.commentsSavedByProject
  );
  const { timesheetEntryComments } = useFeatures();

  // useEffect(() => {
  //   if (savedComment) {
  //     commentInputRef.current.value = "";
  //   }
  // }, [savedComment, commentInputRef]);

  const startOfWeekDate = moment()
    .year(year)
    .week(week)
    .year(year)
    .startOf("week");

  const startOfWeek = startOfWeekDate.dayOfYear();

  const days = dayNamesAbbrWithDate(year, week);

  const entriesByDay = entries.reduce(
    (prev, next) => ({
      ...prev,
      [next.dayOfYear]: next,
    }),
    {}
  );

  const oneEntryIsSaving = entries.find((e) => savingIdMap[e.id]);

  const handleOnSaveWeek = async (e) => {
    await new Promise((res) => setTimeout(() => res(), 100));
    const entries = [];
    if (
      weekRef?.current?.value !== null &&
      weekRef?.current?.value !== undefined &&
      weekRef?.current?.value !== ""
    ) {
      inputRefs.forEach((r, idx) => {
        if (r.value !== null && r.value !== undefined && r.value !== "") {
          const next = new TimesheetEntry(
            userEmail,
            project.id,
            year,
            startOfWeek + idx,
            r.value
          );
          entries.push(next);
        }
      });
      dispatch(stageEntries(entries));
    }
  };

  const handleOnFillWeekChange = (value) => {
    // const { value } = e.target;
    let number = value && value !== "" ? parseFloat(value) : 0;
    if (isNaN(number)) return;
    if (number > MAX_HOURS_WORKING_DAYS) number = MAX_HOURS_WORKING_DAYS;
    else if (number < 0) number = 0;

    const undoValues = [];
    const slicedRefs = inputRefs.slice(0, 5);
    slicedRefs.forEach((el) => {
      undoValues.push(el.value);
      el.value = number / 5;
    });

    dispatch(
      addGlobalAlert({
        // severity: "warning",
        title: "Filled week",
        message: `Undo filled week of ${value} hours ?`,
        action: (
          <IconButton
            onClick={() => {
              slicedRefs.forEach((el, idx) => {
                el.value = undoValues[idx];
              });
              if (weekRef?.current) weekRef.current.value = null;

              slicedRefs.forEach((r, idx) => {
                if (
                  r &&
                  r.value !== null &&
                  r.value !== undefined &&
                  r.value !== ""
                ) {
                  const next = new TimesheetEntry(
                    userEmail,
                    project.id,
                    year,
                    startOfWeek + idx,
                    r.value
                  );
                  entries.push(next);
                }
              });
              dispatch(stageEntries(entries));
            }}
          >
            <UndoIcon style={{ color: "white" }} />
          </IconButton>
        ),
        duration: 3000,
        // horizontal: "center",
      })
    );
  };

  const [debouncedHandleOnFillWeekChange] = useDebouncedCallback(
    handleOnFillWeekChange,
    250
  );

  const handleOnChange = (e, dayOfYear) => {
    const { value } = e.target;
    const entry = new TimesheetEntry(
      userEmail,
      project.id,
      year,
      dayOfYear,
      value
    );
    dispatch(stageEntry(entry));
  };

  const handleOnCommentSave = (comment, dayOfYear) => {
    dispatch(
      watchSaveTimesheetComment({
        user: userEmail,
        project: project.id,
        year,
        day: dayOfYear,
        comment,
      })
    );
  };

  const sumProject = entries.reduce((prev, next) => {
    return prev + (next.hours || 0);
  }, 0);

  const weekendDays = new Array(7).fill(false).map((d, idx) => {
    const next = startOfWeekDate.clone().add(idx, "days").format("dd");
    return next === "Su" || next === "Sa";
  });

  const getTitle = () => {
    if (project.expandedCustomer) {
      return (
        <ProjectTitleContainer>
          <span>{project.name}</span>
          <FormHelperText>
            <i>{project.expandedCustomer.name}</i>
          </FormHelperText>
        </ProjectTitleContainer>
      );
    }
    return project.name;
  };

  let billableRate;
  if (project.billable) {
    billableRate = project.billableRate && parseInt(project.billableRate, 10);

    if (typeof project?.userBillableRate[userEmail] === "number") {
      billableRate =
        project?.userBillableRate[userEmail] &&
        parseInt(project?.userBillableRate[userEmail], 10);
    }
    if (Number.isNaN(billableRate)) billableRate = undefined;
  }

  const dateRange = `${startOfWeekDate.format(
    "MMM Do"
  )} - ${startOfWeekDate.endOf("week").format("MMM Do")}`;

  return (
    <Card
      {...rest}
      style={{
        transform: "translate3d(0)",
        width: windowWidth >= WINDOW_MAX_CARD_BREAKPOINT ? "44rem" : undefined,
      }}
    >
      <HeaderContainer>
        <ProjectTimesheetCardHeader
          avatar={<ProjectAvatar project={project} />}
          title={getTitle()}
          subheader={dateRange}
        />
        <ProjectTimesheetCardHeader
          title={
            oneEntryIsSaving ? (
              <CircularProgress size={23} />
            ) : (
              <DurationFormat value={sumProject} />
            )
          }
          subheader={
            userProjectIncome &&
            !oneEntryIsSaving &&
            project.billable && (
              <NumberFormatIntl
                locales="en"
                number={sumProject * billableRate}
                options={{
                  style: currency && "currency",
                  currency: currency && currency.toUpperCase(),
                }}
              />
            )
          }
          action={
            <StyledWeekSummary>
              <Tooltip title="Drag & drop sort">
                <span {...dragHandleProps}>
                  <DragIcon fontSize="small" />
                </span>
              </Tooltip>
            </StyledWeekSummary>
          }
        />
      </HeaderContainer>
      <Divider />
      <CardContent style={{overflow: "auto"}}>
        <Grid
          container
          spacing={1}
          style={{ marginTop: 20 }}
          component="div"
          flexDirection="column"
        >
          <Grid
            item
            xs={12}
            md
            component="div"
            style={{ minWidth: "20rem" }}
          >
            <Grid container spacing={1}>
              {days.slice(0, 4).map((d, idx) => (
                <Grid item xs style={{ minWidth: "4rem" }}>
                  <Entry
                    abnormalDay={weekendDays[idx]}
                    ref={(c) => (inputRefs[idx] = c)}
                    key={
                      (entriesByDay[startOfWeek + idx] &&
                        entriesByDay[startOfWeek + idx].id) ||
                      d
                    }
                    title={d}
                    entry={entriesByDay[startOfWeek + idx]}
                    onBlur={(e) => handleOnChange(e, startOfWeek + idx)}
                    onCommentSave={timesheetEntryComments ?(e) =>
                      handleOnCommentSave(e, startOfWeek + idx) : undefined
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md
            spacing={1}
            component="div"
            style={{ minWidth: "20rem" }}
          >
            <Grid container spacing={1}>
              {days.slice(4).map((d, idx) => (
                <Grid item xs style={{ minWidth: "4rem" }}>
                  <Entry
                    abnormalDay={weekendDays[4 + idx]}
                    ref={(c) => (inputRefs[idx + 4] = c)}
                    key={
                      (entriesByDay[startOfWeek + idx + 4] &&
                        entriesByDay[startOfWeek + idx + 4].id) ||
                      d
                    }
                    title={d}
                    entry={entriesByDay[startOfWeek + idx + 4]}
                    onBlur={(e) => handleOnChange(e, startOfWeek + idx + 4)}
                    onCommentSave={timesheetEntryComments ?(e) =>
                      handleOnCommentSave(e, startOfWeek + idx + 4) : undefined}
                  />
                </Grid>
              ))}
              <Grid item xs style={{ minWidth: "4rem" }}>
                <Entry
                  key="week"
                  title="Week"
                  ref={weekRef}
                  inputProps={{ max: MAX_HOURS_WORKING_DAYS, min: 0 }}
                  onChange={(e) =>
                    debouncedHandleOnFillWeekChange(e.target.value)
                  }
                  onBlur={handleOnSaveWeek}
                />
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </CardContent>
      { timesheetEntryComments && <CommentsAccordion projectId={project.id} />}
    </Card>
  );
};
