import { useCallback, useEffect, useMemo, useState } from "react";
import useGlobalSnackbar from "../../../hooks/useGlobalSnackbar";
import useUserTimesheetMutation, {
  EntryPayload,
  isApiEnabled
} from "../../../hooks/useUserTimesheetMutation";
import useWorkspace from "../../../hooks/useWorkspace";
import useUser from "../../../hooks/useUser";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import { ANALYTICS_EVENT, logEvent } from "../../../firebase";
import { promiseDelay } from "../../../helpers";
import AsyncQueue, { AsyncTask } from "../../../utils/AsyncQueue";

const useSaveEntry = () => {
  const q = useMemo(() => new AsyncQueue(), []);
  const [entryQueue, setEntryQueue] = useState<EntryPayload[]>([]);
  const { update: updateSnackbar } = useGlobalSnackbar();
  const mutation = useUserTimesheetMutation();
  const workspaceName = useWorkspace();
  const user = useUser();
  const { t } = useTranslation();

  const saveEntry: (payload: EntryPayload) => void = useCallback(
    (payload: EntryPayload) => {
      updateSnackbar({
        open: true,
        message: `${t("common.saving")}...   😎`,
        alert: { severity: "info" }
      });
      setEntryQueue(prev => [...prev, payload]);
    },
    [t, updateSnackbar]
  );

  const [debouncedEntryQueue] = useDebounce(entryQueue, 1000);

  useEffect(() => {
    if (debouncedEntryQueue.length === 0) return;
    const entriesToProcess = [...debouncedEntryQueue];
    setEntryQueue([]);
    const task: AsyncTask = async () => {
      updateSnackbar({
        open: true,
        message: `${t("common.saving")}...   😎`,
        alert: { severity: "info" }
      });
      entriesToProcess.forEach((e: EntryPayload) => {
        logEvent(ANALYTICS_EVENT.TIMESHEET_ENTRY_TABLE_VIEW_SAVE, {
          workspaceName,
          id: e.entry?.id,
          uid: user?.uid
        });
      });

      try {
        await mutation.mutateAsync(entriesToProcess);
        entriesToProcess.forEach((e: EntryPayload) => {
          logEvent(ANALYTICS_EVENT.TIMESHEET_ENTRY_TABLE_VIEW_SAVE_SUCCESS, {
            workspaceName,
            id: e.entry?.id,
            uid: user?.uid
          });
        });
        if (!isApiEnabled(entriesToProcess)) {
          await promiseDelay(1000);
        }
        updateSnackbar({ open: false });
        updateSnackbar({
          open: true,
          message: `${t("pages.timesheet.hoursSaved")}!   🎉`,
          alert: { severity: "success" }
        });
        await promiseDelay(500);
      } catch (error) {
        console.error(error);
        entriesToProcess.forEach((e: EntryPayload) => {
          logEvent(ANALYTICS_EVENT.TIMESHEET_ENTRY_TABLE_VIEW_SAVE_ERROR, {
            workspaceName,
            id: e.entry?.id,
            uid: user?.uid
          });
        });
        updateSnackbar({ open: false });
        updateSnackbar({
          open: true,
          message: `${t("common.error")}: ${error}   🚨🚨 `,
          alert: {
            color: "error",
            severity: "error"
          }
        });
        await new Promise<void>(res => setTimeout(() => res(), 3000));
      } finally {
        if (q.taskCount === 0) {
          await promiseDelay(3000);
          updateSnackbar({ open: false });
        }
      }
    };
    q.enqueue(task);
  }, [debouncedEntryQueue]);

  return saveEntry;
};

export default useSaveEntry;
