import styled, { css } from "styled-components";
import { darken } from "@superprofit/core-react-components/themes/shared/colorUtils";
import List, {
  ListItemText,
  ListItemIcon,
  ListSubheader,
  NavListItem
} from "@superprofit/core-react-components/atoms/List";
import theme from "styled-theming";

export const SidebarContent = styled.div`
  padding-top: 80px;
  width: 20rem;
  min-width: 20rem;
  display: block;
`;

export const StyledSubHeader = styled(ListSubheader)`
  font-weight: ${props => props.theme.typography.h2.fontWeight};
  font-size: ${props => props.theme.typography.h2.fontSize};
  font-family: ${props => props.theme.typography.h2.fontFamily};
  letter-spacing: ${props => props.theme.typography.h2.letterSpacing}px;
`;

export const StickySidebar = styled.div`
  width: 20rem;
  min-width: 20rem;
  display: block;
  background: ${props => props.theme.palette.background.paper};
  border-right: 1px solid
    ${props => darken(props.theme.palette.background.default, 0.05)};
`;

export const SidebarListItemText = styled(ListItemText)`
  margin-left: ${props => props.theme.spacing(2.5)}px;

  > * {
    font-size: 0.875rem;
    font-weight: ${props => props.theme.typography.fontWeightBold};
  }
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 32px;
`;

const selected = theme("mode", {
  light: css`
    background: ${props => props.theme.palette.action.selected};
    ${SidebarListItemText} {
      color: ${props => props.theme.palette.primary.main};
    }
    ${StyledListItemIcon} {
      color: ${props => props.theme.palette.primary.main};
    }
  `,
  dark: css``
});

export const SidebarListItem = styled(NavListItem)`
  margin-left: ${props => props.theme.spacing(2)}px;
  margin-right: ${props => props.theme.spacing(2)}px;
  margin-bottom: ${props => props.theme.spacing(0.5)}px;

  box-sizing: border-box;
  width: auto;
  border-radius: ${props => props.theme.shape.borderRadius}px;

  ${props => props.selected && selected};
`;

export const NestedList = styled(List)`
  .MuiListItemText-root {
    text-indent: ${props => props.theme.spacing(2.5)}px;
    > * {
      font-weight: ${props =>
        props.theme.typography.fontWeightRegular} !important;
    }
  }
`;
