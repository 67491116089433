import React, { forwardRef } from "react";
import { Avatar } from "@superprofit/core-react-components/atoms";

export interface IUserAvatar {
  user: { email: string; displayName?: string | null; picture?: string | null };
}

export default forwardRef(({ user, ...rest }: IUserAvatar, ref) => (
  <Avatar
    ref={ref}
    variant="circle"
    s
    initial={(user?.displayName || user.email || "-").toUpperCase()[0]}
    alt={`Avatar ${user?.displayName || user.email}`}
    src={user.picture}
    {...rest}
  />
));
