import { TableCell } from "@superprofit/timet-react-client/src/components/atoms/Table";
import DurationFormat from "@superprofit/timet-react-client/src/components/atoms/DurationFormat";
import React, { useMemo } from "react";
import { StyledRow } from "./userRow/UserRow.styles";
import { ApprovalRecord } from "@superprofit/timet-react-client/src/models/ApprovalRecords";
import Entry from "@superprofit/timet-react-client/src/models/Entry";
import UserAvatar from "@superprofit/timet-react-client/src/components/molecules/UserAvatar";
import Button from "@superprofit/timet-react-client/src/components/atoms/Button";
import User from "@superprofit/timet-react-client/src/models/User";
import { useTranslation } from "react-i18next";
import Project from "@superprofit/timet-react-client/src/models/Project";
import { Status } from "@superprofit/timet-react-client/src/pages/protected/approvals/Status";
import ConnectedCurrencyFormat from "@superprofit/timet-react-client/src/components/organisms/ConnectedCurrencyFormat";
import UserLink from "@superprofit/timet-react-client/src/components/molecules/UserLink";

type UserRow = {
  approvalRecord?: ApprovalRecord;
  entries?: Entry[];
  user: User;
  onApprove: (approvalRecord: ApprovalRecord) => void;
  onReject: (approvalRecord: ApprovalRecord) => void;
  onCreate: (user: string) => void;
  project: Project;
  isLoading: boolean;
};
export const UserRow = ({
  approvalRecord,
  entries,
  user,
  onApprove,
  onCreate,
  project,
  onReject,
  isLoading
}: UserRow) => {
  const { t } = useTranslation();
  const totalHours = useMemo(() => {
    return entries?.reduce((acc, entry) => acc + entry.hours, 0) || 0;
  }, [entries]);

  const totalMoney = useMemo(() => {
    return user?.id ? totalHours * project.getBillableRate(user.id) : 0;
  }, [project, totalHours, user]);

  const handleOnPositiveAction = () => {
    if (approvalRecord) {
      onApprove(approvalRecord);
    } else {
      onCreate(user.id);
    }
  };
  const handleOnReject = () => {
    if (approvalRecord) {
      onReject(approvalRecord);
    }
  };

  const billableRate = project.getBillableRate(user?.id) || 0;

  const actionDisabled =
    approvalRecord?.status === "approved" ||
    approvalRecord?.status === "rejected";

  return (
    <StyledRow>
      <TableCell style={{ maxWidth: 80, width: 80 }}></TableCell>
      <TableCell style={{ maxWidth: 80, width: 80 }}>
        {user && <UserAvatar user={user} />}
      </TableCell>
      <TableCell>
        <UserLink
          name={user.displayName || undefined}
          email={user.email}
          hideEmail
        />
      </TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Status approvalRecord={approvalRecord} />
      </TableCell>
      <TableCell>
        {project.billable ? (
          <ConnectedCurrencyFormat number={billableRate} />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell align="right">
        {project.billable ? (
          <ConnectedCurrencyFormat number={totalMoney} />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell align="right">
        <DurationFormat value={totalHours} component="b" />
      </TableCell>
      <TableCell align="right">
        <Button
          disabled={isLoading || actionDisabled}
          variant={approvalRecord ? "contained" : "outlined"}
          color="primary"
          onClick={handleOnPositiveAction}
        >
          {approvalRecord ? t("common.approve") : t("common.create")}
        </Button>
      </TableCell>
      <TableCell align="right">
        {approvalRecord && (
          <Button
            disabled={isLoading || actionDisabled}
            variant="contained"
            color="alert"
            onClick={handleOnReject}
          >
            {t("common.reject")}
          </Button>
        )}
      </TableCell>
    </StyledRow>
  );
};
