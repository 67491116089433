import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../index";
import {
  hideDialog,
  showDialog
} from "../../../../redux/modules/ui/users/editDialog/actions";

const useUserEditDialog = () => {
  const dispatch = useDispatch();

  const { open, id } = useSelector(
    (state: RootState) => state.ui.users.editDialog
  );

  const show = (id: string) => {
    dispatch(showDialog(id));
  };

  const close = () => {
    dispatch(hideDialog());
  };

  return {
    close,
    open,
    show,
    id
  };
};

export default useUserEditDialog;
