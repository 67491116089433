import { Workspace } from "@superprofit/core-firestore-models";
import createSagas from "../Helpers/moduleFactory/sagas";
import constants from "./constants";
import actions from "./actions";
import { fork, call, take, cancel, put } from "@redux-saga/core/effects";
import { select } from "redux-saga/effects";

let dbRef = { current: null };
let firebase;

let workspaceCreateUrl;
export const init = (database, fb, createUrl) => {
  firebase = fb;
  dbRef.current = database;
  workspaceCreateUrl = createUrl;
};

const factorySagas = createSagas(Workspace, constants, actions, null, dbRef);

const createWorkspace = async (workspaceId, workspaceDisplayName, token) =>
  fetch(workspaceCreateUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    body: JSON.stringify({
      workspaceId,
      workspaceDisplayName,
    }),
  });

// export const getIdToken = function*(args) {
//   const { payload } = args;
//   const { forceRefresh } = payload;
//   try {
//     const user = firebase.auth().currentUser;
//     yield call([user, user.getIdToken], forceRefresh);
//   } catch (e) {
//     console.warn(e);
//   }
// };

const create = function* (args) {
  const { payload } = args;
  const { data } = payload;
  console.log("Waiting for implementation");

  try {
    yield put(actions.create());
    // const idTokenResult = yield call([token, token.getIdTokenResult]);
    const token = yield select(({ api: { auth } }) => auth.idTokenResult.token);
    const response = yield call(
      createWorkspace,
      data.workspaceId,
      data.workspaceDisplayName,
      token
    );
    yield put(actions.createSuccess());
  } catch (e) {
    console.error(`Model create saga error ${Workspace.collectionName}`, e);
    yield put(actions.createFailure(e));
  }
};


export default function* () {
  let all;
  while (true) {
    const task = yield take("*");
    if (task.type === constants.WATCH_CREATE) {
      if (all) yield cancel(all);
      yield call(create, task);
    } else {
      if (!all) all = yield fork(factorySagas, task);
    }
  }
}
//
// export const getWorkspaces = function* getWorkspaces() {
//   // try {
//   //   yield put(get());
//   //   const workspace = yield select(
//   //     ({ api: { auth } }) => auth.activeWorkspaceId
//   //   );
//   //   const workspaces = yield call(Workspace.list, workspace);
//   //   yield put(getSuccess(workspaces));
//   // } catch (e) {
//   //   console.warn(e);
//   //   yield put(getFailure(e));
//   // }
// };
//
// export const saveWorkspace = function*(args) {
//   const { payload } = args;
//
//   try {
//     const user = yield select(
//       ({
//         api: {
//           auth: { user }
//         }
//       }) => user.email
//     );
//     yield put(save());
//     yield call(Workspace.create, db, payload, user);
//     yield put(saveSuccess());
//   } catch (e) {
//     console.error(e);
//
//     yield put(saveFailure());
//   }
// };
//
// export const existWorkspace = function*(args) {
//   const { payload } = args;
//   const { workspace } = payload;
//
//   try {
//     yield put(exist());
//     yield delay(1000);
//     const doesExist = yield call(Workspace.exists, db, workspace);
//     yield put(existSuccess(workspace, doesExist));
//   } catch (e) {
//     console.error(e);
//     yield put(existFailure());
//   }
// };
//
// export const deleteWorkspace = function*(args) {
//   // const { payload } = args;
//   // const { id } = payload;
//   //
//   // try {
//   //   const workspace = yield select(
//   //     ({ api: { auth } }) => auth.activeWorkspaceId
//   //   );
//   //   yield put(deleteWorkspaceAction());
//   //   yield db
//   //     .collection("workspaces")
//   //     .doc(workspace)
//   //     .collection("workspaces")
//   //     .doc(id)
//   //     .delete();
//   //   yield put(deleteSuccess());
//   //   yield call(getWorkspaces);
//   // } catch (e) {
//   //   yield put(deleteFailure());
//   // }
// };
//
// export const getWorkspacesSaga = function* getWorkspacesSaga() {
//   yield takeLatest(WATCH_GET, getWorkspaces);
// };
// export const saveWorkspaceSaga = function*() {
//   yield takeLatest(WATCH_SAVE, saveWorkspace);
// };
// export const deleteWorkspaceSaga = function*() {
//   yield takeEvery(WATCH_DELETE, deleteWorkspace);
// };
// export const existWorkspaceSaga = function*() {
//   yield takeLatest(WATCH_EXIST, existWorkspace);
// };
//
// export default function*() {
//   yield all([
//     getWorkspacesSaga(),
//     saveWorkspaceSaga(),
//     deleteWorkspaceSaga(),
//     existWorkspaceSaga()
//   ]);
// }
