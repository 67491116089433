import useCompanySettings from "./useCompanySettings";
import CompanySettingGeneric from "../models/CompanySettingGeneric";
import UserSettingGeneric from "../models/UserSettingGeneric";
import useUserSettings from "./useUserSettings";

type Features = { [key: string]: boolean };

export const useCompanyFeatures = () => {
  const { data: settings, ...rest } = useCompanySettings();
  let features = {} as Features;
  if (settings?.features instanceof CompanySettingGeneric) {
    features = settings.features.value;
  }
  return { ...rest, features };
};

export const useUserFeatures = () => {
  const { data: settings, ...rest } = useUserSettings();
  let features = {} as Features;
  if (settings?.features instanceof UserSettingGeneric) {
    features = settings.features.value;
  }
  return { ...rest, features };
};

const useFeatures = () => {
  const company = useCompanyFeatures();
  const user = useUserFeatures();
  return { ...company.features, ...user.features };
};

export default useFeatures;
