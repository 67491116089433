import { useMemo } from "react";
import Project from "../models/Project";
import useProjects from "./useProjects";

const useProjectsMap = (projects?: Project[]) => {
  const { data: allProjects } = useProjects();
  return useMemo(() => {
    const m = new Map<string, Project>();
    (projects || allProjects || []).forEach((u: Project) => {
      // id can be null if its a newly instantiated project..
      if (u.id) m.set(u.id, u);
    });
    return m;
  }, [projects, allProjects]);
};

export default useProjectsMap;
