import { useMemo } from "react";
import { filterStats } from "../../../../utils/StatsFilter";
import Project from "../../../../models/Project";

export type Filters = {
  type: "All" | "Billable";
  projects: string[];
  users: string[];
  customers: string[];
};
const useFilteredStats = ({
  filters,
  stats,
  projects
}: {
  filters: any;
  stats: any;
  projects: Project[];
}) => {
  return useMemo(() => {
    if (!filters) return stats;
    return filterStats(
      stats,
      projects,
      filters.type,
      filters.projects,
      filters.users,
      filters.customers
    );
  }, [filters, stats, projects]);
};

export default useFilteredStats;
