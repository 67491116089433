import styled from "styled-components";
import { LandingSection, TitleContainer } from "../landing/Landing.styles";
import { motion } from "framer-motion";
import { DialogContent } from "@superprofit/core-react-components/atoms";

export const StyledLoginSection = styled(DialogContent)`
  padding-bottom: ${props => props.theme.spacing(3)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .login-alt {
    color: ${props => props.theme.palette.text.hint};
    margin: ${props => props.theme.spacing(3)}px;
  }
`;

export const StyledTitleContainer = styled(TitleContainer)``;

export const AnimatedContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;
