import { SET_TIMESHEET_PROJECTS } from "./constants";

const initialState = {
  list: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TIMESHEET_PROJECTS:
      return {
        ...state,
        list: payload.projects
      };
    default:
      return state;
  }
};
