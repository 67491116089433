import NumberFormat from "react-number-format";
import React, { forwardRef } from "react";

export default forwardRef((props, ref) => {
  const {
    inputRef,
    onChange = n => n,
    prefix = "NOK ",
    suffix = " ,-",
    type,
    ...other
  } = props;
  const dataset = Object.keys(props).reduce(
    (set, key) =>
      key.startsWith("data-")
        ? { ...set, [key.substring(5)]: props[key] }
        : set,
    {}
  );
  const handleValueChange = values => {
    onChange({
      target: {
        value: type === "number" ? values.floatValue || 0 : values.value,
        dataset
      },
      currentTarget: {
        value: type === "number" ? values.floatValue || 0 : values.value,
        dataset
      }
    });
  };
  return (
    <NumberFormat
      ref={ref}
      getInputRef={inputRef}
      onValueChange={handleValueChange}
      thousandSeparator
      isNumericString
      suffix={suffix}
      prefix={prefix}
      {...other}
    />
  );
});
