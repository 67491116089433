import React from "react";
import styled, { css } from "styled-components";
import Typography from "./Typography";
import PageContextHeader from "@superprofit/timet-react-client/src/components/atoms/PageContextHeader";

const StyledPage = styled.div<{
  paddingBottom: boolean;
  padding: boolean;
}>`
  padding: ${props => props.padding && props.theme.spacing(2)}px;
  padding-bottom: ${props => props.paddingBottom && props.theme.spacing(12)}px;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  color: ${props => props.color || props.theme.palette.common.white};
`;

export const PageHeader = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme.palette.primary.main};
  transition: all ease-out;
  transition-duration: 2150ms;

  ${props =>
    props.color &&
    css<{ color?: string }>`
      background-color: ${props => props.color};
    `};
`;

export const PageActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > * {
    margin-left: 1rem;
  }
`;

export const PageContent = styled.div`
  margin: 2rem 1rem;
`;

interface PageTitleProps {
  children: React.ReactNode;
}

export const PageTitle = ({ children }: PageTitleProps) => (
  <Typography variant="h1" component="h1">
    {children}
  </Typography>
);

interface PageProps {
  context?: any;
  title?: string;
  children: React.ReactNode;
  padding?: boolean;
  paddingBottom?: boolean;
}

export default ({
  context,
  title,
  children,
  paddingBottom = true,
  padding = true,
  ...rest
}: PageProps) => (
  <StyledPage padding={padding} paddingBottom={paddingBottom} {...rest}>
    {(title || context) && (
      <PageContextHeader title={title} context={context} />
    )}

    {children}
  </StyledPage>
);
