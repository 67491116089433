import {
  getHoursByUser,
  getMoneyByUser,
  getProjectHoursByUser,
  getProjectMoneyByUser,
  getUserProjectEntries
} from "../../UserStats";
import moment from "moment";
import {
  Report,
  ReportSection,
  ReportSubtitle,
  ReportTable,
  ReportTableCell,
  ReportTableFooter,
  ReportTableHeader,
  ReportTableRow,
  ReportTitle
} from "../Report";
import { colors } from "@superprofit/core-react-components/themes/light/colors";
import i18next from "i18next";
const translate = i18next.t;

const build = (users, projects, entries) => {
  const projectsById = projects.reduce(
    (prev, next) => ({ ...prev, [next.id]: next }),
    {}
  );

  const filteredEntries = entries.filter(e => {
    return !!projectsById[e.project];
  });

  const f = number => {
    return new Intl.NumberFormat(i18next.language, {
      minimumFractionDigits: 2
    }).format(number);
  };

  const projectHoursByUser = getProjectHoursByUser(filteredEntries);
  const entriesByUserProject = getUserProjectEntries(
    filteredEntries,
    projectsById
  );

  const report = new Report();
  users.forEach(user => {
    const reportSection = new ReportSection([
      new ReportTitle(
        `${translate("common.consultant")}: ${user.displayName || user.email}`
      )
    ]);

    let hasEntries = false;

    projects.forEach(project => {
      const userProjectEntries =
        entriesByUserProject[user.id] &&
        entriesByUserProject[user.id][project.id];

      if (userProjectEntries && userProjectEntries.length > 0) {
        hasEntries = true;

        // Sort entries
        userProjectEntries.sort((a, b) => {
          if (a.year > b.year) {
            return -1;
          } else if (a.dayOfYear > b.dayOfYear) {
            return -1;
          }
          return 1;
        });

        const tableRows = [];
        userProjectEntries.forEach(entry => {
          // Create rows for entries
          tableRows.push(
            new ReportTableRow([
              new ReportTableCell(
                moment()
                  .year(entry.year)
                  .dayOfYear(entry.dayOfYear)
                  .format("LL")
              ),
              new ReportTableCell(entry.hours || entry.total, {
                textAlign: "right"
              }),
              new ReportTableCell(f(project.getBillableRate(user.id)), {
                textAlign: "right"
              }),
              new ReportTableCell(f(entry.money || 0), { textAlign: "right" })
            ]),
            {
              borderBottomColor:
                entry?.comments.length <= 0 ? colors.background : undefined,
              borderBottomWidth: entry?.comments.length <= 0 ? 1 : undefined,
              borderBottomStyle:
                entry?.comments.length <= 0 ? "solid" : undefined,
              padding: 3
            }
          );

          if (entry?.comments.length > 0) {
            entry.comments.forEach((c, idx) => {
              tableRows.push(
                new ReportTableRow(
                  [
                    new ReportTableCell(
                      `${translate("common.comment")}: ${c.comment}`,
                      {
                        paddingLeft: 8,
                        fontStyle: "italic",
                        fontSize: 8,
                        opacity: 0.8
                      }
                    )
                  ],
                  {
                    borderBottomColor:
                      idx === entry?.comments.length - 1
                        ? colors.background
                        : undefined,
                    borderBottomWidth:
                      idx === entry?.comments.length - 1 ? 1 : undefined,
                    borderBottomStyle:
                      idx === entry?.comments.length - 1 ? "solid" : undefined,
                    paddingBottom:
                      idx === entry?.comments.length - 1 ? 3 : undefined
                  }
                )
              );
            });
          }
        });
        const tableHeader = new ReportTableHeader([
          new ReportTableCell(translate("common.date")),
          new ReportTableCell(translate("common.hours"), {
            textAlign: "right"
          }),
          new ReportTableCell(translate("common.rate"), { textAlign: "right" }),
          new ReportTableCell(translate("common.billable"), {
            textAlign: "right"
          })
        ]);
        const tableFooter = new ReportTableFooter([
          new ReportTableCell(translate("common.total")),
          new ReportTableCell(
            projectHoursByUser[user.id]
              ? projectHoursByUser[user.id][project.id]
              : "",
            { textAlign: "right" }
          ),
          new ReportTableCell(f(project.getBillableRate(user.id)), {
            textAlign: "right"
          }),
          new ReportTableCell(
            projectHoursByUser[user.id]
              ? f(
                  projectHoursByUser[user.id][project.id] *
                    project.getBillableRate(user.id)
                )
              : "",
            { textAlign: "right" }
          )
        ]);

        reportSection.push([
          new ReportSubtitle(project.name),
          new ReportTable(tableHeader, tableRows, tableFooter)
        ]);
      }
    });
    if (hasEntries) {
      report.push(reportSection);
    }
  });

  return report;
};

export const createTableExport = (users, projects, entries) => {
  return build(users, projects, entries);
};
