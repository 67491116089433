import {
  UPDATE,
  UPDATE_FAILURE,
  UPDATE_SUCCESS,
  GET,
  GET_SUCCESS,
  GET_FAILURE,
  WATCH_UPDATE
} from "./constants";

export const get = () => ({
  type: GET,
  payload: {}
});

export const getSuccess = userProfile => ({
  type: GET_SUCCESS,
  payload: { userProfile }
});

export const getFailure = error => ({
  type: GET_FAILURE,
  payload: { error }
});

export const update = () => ({
  type: UPDATE,
  payload: {}
});

export const updateSuccess = userProfile => ({
  type: UPDATE_SUCCESS,
  payload: { userProfile }
});

export const updateFailure = error => ({
  type: UPDATE_FAILURE,
  payload: { error }
});

export const watchUpdateUserProfile = (profile, updates) => {
  return {
    type: WATCH_UPDATE,
    payload: { profile, updates }
  };
};
