import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { SET_ENTRIES_BY_PROJECT } from "../entries/constants";
import { GET_SUCCESS } from "../../../api/projects/constants";
import { GET_SUCCESS as GET_USER_GROUPS_SUCCESS} from "../../../api/userGroups/constants";
import { setTimesheetProjects, watchFilterProjects } from "./actions";
import { WATCH_FILTER_TIMESHEET_PROJECTS } from "./constants";
import { getCustomer, watchGetCustomer } from "../../../api/customers/actions";
import { GET_CUSTOMER_SUCCESS } from "../../../api/customers/constants";

export const getState = (state) => ({
  userEmail: state.api.auth.user.email,
  projects: state.api.projects.list,
  entriesByProject: state.ui.timesheet.entries.entriesByProject,
  customers: state.api.customers.list,
  userGroups: state.api.userGroups.list,
});

const filterProjects = (
  userEmail,
  projects,
  entriesByProject = {},
  userGroups
) => {
  return (projects || [])
    .reduce((prev, next) => {
      const isParticipant =
        (next.team || []).some((u) => u === userEmail) ||
        userGroups
          .filter((g) => next.userGroups.some((id) => id === g.id))
          .some((u) => {
            return (
              u &&
              u.users &&
              u.users.length > 0 &&
              u.users.some((usr) => usr === 'all' || userEmail === usr)
            );
          });
      const isArchived = next.archived;
      const hasHours = !!entriesByProject[next.id];
      if ((!isArchived && isParticipant) || hasHours) {
        return [...prev, next];
      }
      return prev;
    }, [])
    .sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const refreshFilteredProjects = function* () {
  try {
    const {
      userEmail,
      projects,
      entriesByProject,
      customers,
      userGroups,
    } = yield select(getState);

    const result = filterProjects(
      userEmail,
      projects,
      entriesByProject,
      userGroups
    );
    const expanded = [];
    for (let p of result) {
      if (p.customer) {
        const cust = customers.find((c) => c.id === p.customer);
        if (!cust) {
          yield put(watchGetCustomer(p.customer));
        } else {
          p.expandedCustomer = cust;
        }
      }
      expanded.push(p);
    }

    yield put(setTimesheetProjects(expanded));
  } catch (e) {
    console.warn(e);
  }
};

export const handleProjectsChanged = function* () {
  yield put(watchFilterProjects());
};

export const handleEntriesChangedSaga = function* () {
  yield takeLatest(SET_ENTRIES_BY_PROJECT, handleProjectsChanged);
};

export const handleProjectsChangedSaga = function* () {
  yield takeLatest(GET_SUCCESS, handleProjectsChanged);
};

export const refreshFilteredProjectsSaga = function* () {
  yield takeLatest(
    [WATCH_FILTER_TIMESHEET_PROJECTS, GET_CUSTOMER_SUCCESS, GET_USER_GROUPS_SUCCESS],
    refreshFilteredProjects
  );
};

export default function* () {
  yield all([
    handleEntriesChangedSaga(),
    handleProjectsChangedSaga(),
    refreshFilteredProjectsSaga(),
  ]);
}
