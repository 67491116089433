import { combineReducers } from "redux";

import dialog from "./dialog/reducer";
import snackbar from "./snackbar/reducer";
import view from "./view/reducer";
import table from "./table/reducer";
import selection from "./selection/reducer";

export default combineReducers({
  dialog,
  snackbar,
  view,
  table,
  selection
});
