import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import UserGroup from "../models/UserGroup";

type Options = { ids: string[] };
const useUserGroups = (options?: Options) => {
  const { ids = [] } = options || {};
  const workspace = useWorkspace();
  const get = () => UserGroup.list(workspace, ids);
  const key = [workspace, UserGroup.collectionName].concat(ids);
  const query = useQuery(key, get, {
    staleTime: 1000 * 60 * 5 //five minutes stale time. rarely changes
  });
  return query;
};

export default useUserGroups;
