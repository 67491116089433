import React, { useEffect } from "react";
import UserContext from "./contexts/UserContext";
import WorkspaceContext from "./contexts/WorkspaceContext";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { useSelector } from "react-redux";
import { FilterProvider } from "@superprofit/timet-react-client/src/components/organisms/FilterDialog";

export default ({ children }) => {
  const { settings } = useSelector(state => state.api.userSettings);

  useEffect(() => {
    if (settings?.language?.value) {
      console.log("Overriding language to", settings.language.value);
      i18n.changeLanguage(settings.language.value).catch(console.error);
    }
  }, [settings?.language?.value]);
  return (
    <UserContext>
      <WorkspaceContext>
        <FilterProvider>
          <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
        </FilterProvider>
      </WorkspaceContext>
    </UserContext>
  );
};
