import { all, put, take, select, takeLatest } from "redux-saga/effects";
import { watchGetProjects } from "../../../api/projects/actions";
import { watchGetUsers } from "@superprofit/core-redux-saga-modules/Users/actions";
import { watchGetCustomers } from "../../../api/customers/actions";
import {
  GET_SUCCESS as GET_PROJECTS_SUCCESS,
  PATCH_MULTIPLE_SUCCESS,
  SAVE_SUCCESS,
  DUPLICATE_SUCCESS,
  PATCH_SUCCESS
} from "../../../api/projects/constants";
import { GET_SUCCESS as GET_USERS_SUCCESS } from "@superprofit/core-redux-saga-modules/Users/constants";
import { GET_SUCCESS as GET_CUSTOMERS_SUCCESS } from "../../../api/customers/constants";
import {
  setProjectsTable,
  watchRefreshProjectsTable,
  setLoading
} from "./actions";

import {
  WATCH_GET_PROJECTS,
  WATCH_REFRESH_PROJECTS_TABLE,
  UPDATE_PROJECTS_SEARCH
} from "./constants";

export const getProjectsState = state => {
  return {
    searchTerm: state.ui.projects.table.searchTerm,
    projects: state.api.projects.list,
    users: state.api.users.list,
    customers: state.api.customers.list
  };
};

export const filterProjects = (list, searchTerm) =>
  list.filter(
    p =>
      !searchTerm ||
      searchTerm === "" ||
      p.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

export const handleRefreshTable = function*(args) {
  try {
    const { searchTerm, projects, users, customers } = yield select(
      getProjectsState
    );

    const customersById = customers.reduce(
      (prev, next) => ({
        ...prev,
        [next.id]: next
      }),
      {}
    );

    const usersById = users.reduce(
      (prev, next) => ({
        ...prev,
        [next.id]: next
      }),
      {}
    );

    const filteredProjects = filterProjects(projects, searchTerm);

    const enrichedFilteredProjects = filteredProjects.map(project => {
      let enrichedProject = project.clone();
      if (project.customer) {
        enrichedProject.setData({
          customerDetails: customersById[project.customer]
        });
      }
      if (project.team) {
        const teamDetails = project.team.map(userId => usersById[userId]);
        enrichedProject.setData({ teamDetails: teamDetails });
      }
      if (project.createdBy) {
        enrichedProject.setData({
          createdByDetails: usersById[project.createdBy]
        });
      }
      return enrichedProject;
    });

    yield put(setProjectsTable(enrichedFilteredProjects));
    yield put(setLoading(false));
  } catch (e) {}
};

export const handleGetProjects = function*(args) {
  try {
    yield put(setLoading(true));
    yield put(watchGetProjects());
    yield put(watchGetUsers());
    yield put(watchGetCustomers());

    const [projects, users, customers] = yield all([
      take(GET_PROJECTS_SUCCESS),
      take(GET_USERS_SUCCESS),
      take(GET_CUSTOMERS_SUCCESS)
    ]);

    // Dont care about the data, lets just get the values from store.

    yield put(watchRefreshProjectsTable());
  } catch (e) {}
};

export const handleDispatchRefresh = function*(args) {
  try {
    yield put(watchRefreshProjectsTable());
  } catch (e) {}
};

export const handleDispatchRefreshSaga = function*() {
  yield takeLatest(
    [
      UPDATE_PROJECTS_SEARCH,
      SAVE_SUCCESS,
      PATCH_MULTIPLE_SUCCESS,
      PATCH_SUCCESS,
      DUPLICATE_SUCCESS
    ],
    handleDispatchRefresh
  );
};

export const handleSearchChangeSaga = function*() {
  yield takeLatest(WATCH_REFRESH_PROJECTS_TABLE, handleRefreshTable);
};

export const getProjectsSaga = function*() {
  yield takeLatest(WATCH_GET_PROJECTS, handleGetProjects);
};

export default function*() {
  yield all([
    handleDispatchRefreshSaga(),
    getProjectsSaga(),
    handleSearchChangeSaga()
  ]);
}
