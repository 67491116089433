import { takeLatest, call, put, all, select,delay, takeEvery } from "redux-saga/effects";
import { Workspace } from "@superprofit/core-firestore-models";
import {
  WATCH_AUTH_SIGN_OUT,
  WATCH_AUTH_STATE_CHANGED,
  WATCH_AUTH_ON_ID_TOKEN_CHANGED,
  WATCH_GET_ID_TOKEN,
} from "./constants";
import {
  signedIn,
  signedOut,
  setIdTokenResult,
  setWorkspace,
  workspaceNoAccess,
  workspaceJoined,
  workspaceJoining,
} from "./actions";

let firebase;
let db;
let workspaceJoinUrl;
export const init = (database, fb, workspaceJoin) => {
  firebase = fb;
  db = database;
  workspaceJoinUrl = workspaceJoin;
};

const joinWorkspace = async (user, workspace, token) =>
  fetch(workspaceJoinUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    body: JSON.stringify({
      workspace,
    }),
  });

export const authStateChanged = function* (args) {
  const { payload } = args;
  const { user } = payload;
  try {
    if (!!user) {
      yield put(signedIn(user));
    } else {
      yield put(signedOut());
    }
  } catch (e) {
    console.warn(e);
  }
};

export const getIdToken = function* (args) {
  const { payload } = args;
  const { forceRefresh } = payload;
  try {
    const user = firebase.auth().currentUser;
    yield call([user, user.getIdToken], forceRefresh);
  } catch (e) {
    console.warn(e);
  }
};

export const signOut = function* () {
  try {
    const auth = firebase.auth();
    yield call([auth, auth.signOut]);
  } catch (e) {
    console.warn(e);
  }
};

export const onIdTokenChanged = function* (args) {
  const { payload } = args;
  const { token } = payload;
  try {
    if (!token) {
      yield put(signedOut());
    } else {
      const idTokenResult = yield call([token, token.getIdTokenResult], true);
      yield put(setIdTokenResult(idTokenResult));
      const workspace = yield select(
        ({ api: { auth } }) => auth.activeWorkspaceId
      );
      const isCurrentlyJoining = yield select(({ api: { auth } }) => auth.workspaceJoining);

      if (workspace) {
        if (
          idTokenResult.claims.workspaces &&
          idTokenResult.claims.workspaces[workspace] &&
          idTokenResult.claims.workspaces[workspace].isActive
        ) {
          yield put(setWorkspace(yield call(Workspace.get, db, workspace)));
        } else if (!isCurrentlyJoining && idTokenResult.token) {
          console.warn('[AUTH SAGA]: Not currently joining workspace. Trying to join workspace.')
          const user = yield select(({ api: { auth } }) => auth.user);
          yield put(workspaceJoining(workspace));
          const result = yield call(
            joinWorkspace,
            user,
            workspace,
            idTokenResult.token
          );
          if (result.ok) {
            yield delay(3000);
            yield call(getIdToken, { payload: { forceRefresh: true } }); // force update token
            yield put(workspaceJoined(workspace));
            // yield put(setWorkspace(yield call(getWorkspace, workspace)));
          } else {
            console.warn('[AUTH SAGA]: Join workspace result not ok.')

            yield put(workspaceNoAccess({workspace}));
            // yield signOut();
          }
        } else {
          console.warn('[AUTH SAGA]: Not doing anything onIdTokenChanged')
        }
      }
    }
  } catch (e) {
    console.warn('[AUTH SAGA]: ON ID TOKEN CHANGED FAILED');
    console.warn(e);
    yield put(workspaceNoAccess());
    // yield signOut();
  }
};

export const authStateChangedSaga = function* getUsersSaga() {
  yield takeLatest(WATCH_AUTH_STATE_CHANGED, authStateChanged);
};
export const onIdTokenChangedSaga = function* () {
  yield takeEvery(WATCH_AUTH_ON_ID_TOKEN_CHANGED, onIdTokenChanged);
};
export const signOutSaga = function* () {
  yield takeLatest(WATCH_AUTH_SIGN_OUT, signOut);
};
export const getIdTokenSaga = function* () {
  yield takeLatest(WATCH_GET_ID_TOKEN, getIdToken);
};

export default function* () {
  yield all([
    authStateChangedSaga(),
    signOutSaga(),
    onIdTokenChangedSaga(),
    getIdTokenSaga(),
  ]);
}
