import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Page from "@superprofit/core-react-components/atoms/Page";
import GeneralSettings from "./companySettings/GeneralSettings";
import GoalsSetting from "./companySettings/GoalsSetting";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import Snackbar from "@superprofit/core-react-components/atoms/Snackbar";
import GoalSettingsDialog from "./companySettings/goalSettings/GoalSettingsDialog";
import Features from "./companySettings/Features";
import { useTranslation } from "react-i18next";
import ProjectSalaryRate from "./companySettings/ProjectSalaryRate";
import NettlonnConfig from "./companySettings/NettlonnConfig";

export default () => {
  const snackbar = useSelector(state => state.ui.companySettings.snackbar);
  const { t } = useTranslation();
  return (
    <Page context={t("common.management")} title={t("common.settings")}>
      <Grid container spacing={3} component="div">
        <Grid item xs={12} sm={6} md={12} lg={6} component="div">
          <GeneralSettings />
        </Grid>
        {/*<Grid item xs={12} sm={6} md={12} lg={6} component="div">*/}
        {/*  <GoalsSetting />*/}
        {/*</Grid>*/}
        <Grid item xs={12} sm={6} md={12} lg={6} component="div">
          <Features />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6} component="div">
          <ProjectSalaryRate />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6} component="div">
          <NettlonnConfig />
        </Grid>
      </Grid>

      <GoalSettingsDialog />

      <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={snackbar.visible}
          message={snackbar.text}
        />
      </AppBar>
    </Page>
  );
};
