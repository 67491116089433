import React, { useEffect, useState } from "react";
import Page from "@superprofit/core-react-components/atoms/Page";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import SearchField from "@superprofit/core-react-components/atoms/SearchField";
import { Button } from "@superprofit/core-react-components/atoms";
import ViewInvoiceDialog from "./invoices/ViewInvoiceDialog";
import AddInvoiceDialog from "./invoices/InvoiceDialog";
import AuthDialog from "./integrations/twentyFourSeven/AuthDialog";
import ExportDialog from "./integrations/twentyFourSeven/ExportDialog";
import { watchGetInvoicesTable } from "../../redux/modules/ui/invoices/table/actions";
import { showInvoiceDialog } from "../../redux/modules/ui/invoices/dialog/actions";
import InvoicesCard from "./invoices/InvoicesCard";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";

export default () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");

  useEffect(() => {
    dispatch(watchGetInvoicesTable());
  }, [dispatch]);
  const { t } = useTranslation();
  const { list: invoices, loading } = useSelector(
    state => state.ui.invoices.table
  );

  const selection = useSelector(state => state.ui.invoices.selection.selected);

  const filteredInvoices = React.useMemo(() => {
    return invoices.filter(
      i =>
        filter === "" ||
        `${i.invoiceNumber}`.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        i.customer.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
    );
  }, [invoices, filter]);

  const handleOnAdd = () => {
    dispatch(showInvoiceDialog({}));
  };

  const handleOnFilter = e => {
    const val = e.target.value;
    setFilter(val || "");
  };

  return (
    <Page context={t("common.management")} title={t("common.invoices")}>
      <Grid container spacing={3} component="div">
        <Grid container item xs={6} component="div">
          <SearchField
            placeholder={t("common.search")}
            onChange={handleOnFilter}
          />
        </Grid>

        <Grid container item xs={6} component="div" justify="flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={handleOnAdd}
            startIcon={<AddIcon />}
          >
            {t("common.create")}
          </Button>
        </Grid>

        <Grid item xs={12} component="div">
          <InvoicesCard
            invoices={filteredInvoices}
            loading={loading}
            selection={selection}
          />
        </Grid>
      </Grid>

      <ViewInvoiceDialog />
      <AddInvoiceDialog />
      <AuthDialog />
      <ExportDialog />
    </Page>
  );
};
