import {
  takeLatest,
  call,
  put,
  cancelled,
  cancel,
  select,
  all,
  takeEvery,
  take,
  fork,
  delay,
} from "redux-saga/effects";
import { eventChannel } from "redux-saga";

export default (Model, constants, actions, db, dbRef) => {
  const get = function* get() {
    try {
      yield put(actions.get());
      const workspace = yield select(
        ({ api: { auth } }) => auth.activeWorkspaceId
      );
      const list = yield call(Model.list, db || dbRef.current, workspace);
      yield put(actions.getSuccess(list));
    } catch (e) {
      console.warn(e);
      yield put(actions.getFailure(e));
    }
  };

  const getEntry = function* getEntry(args) {
    try {
      yield put(actions.getEntry());
      const workspace = yield select(
        ({ api: { auth } }) => auth.activeWorkspaceId
      );
      const entry = yield call(
        Model.get,
        db || dbRef.current,
        workspace,
        args.payload && args.payload.id
      );
      yield put(actions.getEntrySuccess(entry));
    } catch (e) {
      console.warn(e);
      yield put(actions.getEntryFailure(e));
    }
  };

  const create = function* (args) {
    const { payload } = args;
    const { data } = payload;

    try {
      const workspace = yield select(
        ({ api: { auth } }) => auth.activeWorkspaceId
      );
      yield put(actions.create());
      const user = yield select(({ api: { auth } }) => auth.user.email);

      const response = yield call(
        Model.create,
        db || dbRef.current,
        workspace,
        user,
        data
      );
      yield put(actions.createSuccess(response));
      yield call(get);
    } catch (e) {
      console.error(`Model create saga error ${Model.collectionName}`, e);
      yield put(actions.createFailure(e));
    }
  };

  const del = function* (args) {
    const { payload } = args;
    const { id } = payload;

    try {
      const workspace = yield select(
        ({ api: { auth } }) => auth.activeWorkspaceId
      );
      yield put(actions.deleteAction());
      const response = yield call(
        Model.delete,
        db || dbRef.current,
        workspace,
        id
      );

      yield put(actions.deleteSuccess(response));
      yield call(get);
    } catch (e) {
      console.error(`Model delete saga error ${Model.collectionName}`, e);
      yield put(actions.deleteFailure(e));
    }
  };

  const update = function* (args) {
    const { payload } = args;
    const { original, updates } = payload;

    try {
      const workspace = yield select(
        ({ api: { auth } }) => auth.activeWorkspaceId
      );
      yield put(actions.update());
      const user = yield select(({ api: { auth } }) => auth.user.email);

      const response = yield call(
        Model.update,
        db || dbRef.current,
        workspace,
        user,
        original,
        updates
      );

      yield put(actions.updateSuccess(response));
      yield call(get);
    } catch (e) {
      console.error(`Model update saga error ${Model.collectionName}`, e);
      yield put(actions.updateFailure(e));
    }
  };

  const listenAll = function* () {
    let channel;
    try {
      const workspace = yield select(
        ({ api: { auth } }) => auth.activeWorkspaceId
      );
      channel = eventChannel((emitter) => {
        const detach = Model.listenAll(
          db || dbRef.current,
          workspace,
          (list) => {
            if (list) emitter(list);
          }
        );
        return () => {
          detach();
        };
      });
      while (true) {
        const list = yield take(channel);
        yield put(actions.listenAllSuccess(list));
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (yield cancelled()) {
        console.log(`STOP LISTEN ALL ON ${Model.collectionName}`);
        channel.close();
      }
    }
  };

  const listen = function* () {
    let channel;
    try {
      const workspace = yield select(
        ({ api: { auth } }) => auth.activeWorkspaceId
      );

      channel = eventChannel((emitter) => {
        const detach = Model.listen(db || dbRef.current, workspace, (data) => {
          if (data) emitter(data);
        });
        return () => {
          detach();
        };
      });
      while (true) {
        const entry = yield take(channel);
        yield put(actions.listenSuccess(entry));
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (yield cancelled()) {
        console.log(`STOP LISTEN ON ${Model.collectionName}`);
        channel.close();
      }
    }
  };

  const docExists = function* (args) {
    const { payload } = args;
    const { docId } = payload;
    try {
      yield put(actions.exist());
      yield delay(1000);
      const doesExist = yield call(Model.exists, db || dbRef.current, docId);
      yield put(actions.existSuccess(docId, doesExist));
    } catch (e) {
      console.error(e);
      yield put(actions.existFailure());
    }
  };

  // SAGAS

  const existDocSaga = function* () {
    yield takeLatest(constants.WATCH_EXISTS, docExists);
  };

  const listenAllSaga = function* listenAllSaga() {
    // yield takeLatest(WATCH_LISTEN_ALL, listenAllRequests);

    while (yield take(constants.WATCH_LISTEN_ALL)) {
      // starts the task in the background
      const listenerTask = yield fork(listenAll);

      // wait for the user stop action
      yield take(constants.WATCH_STOP_LISTEN_ALL);
      // user clicked stop. cancel the background task
      // this will cause the forked bgSync task to jump into its finally block
      yield cancel(listenerTask);
    }
  };

  const listenSaga = function* listenSaga() {
    // yield takeLatest(WATCH_LISTEN_ALL, listenAllRequests);

    while (yield take(constants.WATCH_LISTEN)) {
      // starts the task in the background
      const listenerTask = yield fork(listen);

      // wait for the user stop action
      yield take(constants.WATCH_STOP_LISTEN);
      // user clicked stop. cancel the background task
      // this will cause the forked bgSync task to jump into its finally block
      yield cancel(listenerTask);
    }
  };

  const getSaga = function* getSaga() {
    yield takeLatest(constants.WATCH_GET, get);
  };

  const getEntrySaga = function* getEntrySaga() {
    yield takeLatest(constants.WATCH_GET_ENTRY, getEntry);
  };

  const createSaga = function* () {
    yield takeLatest(constants.WATCH_CREATE, create);
  };

  const deleteSaga = function* () {
    yield takeEvery(constants.WATCH_DELETE, del);
  };
  const updateSaga = function* () {
    yield takeEvery(constants.WATCH_UPDATE, update);
  };

  return function* () {
    yield all([
      getSaga(),
      getEntrySaga(),
      createSaga(),
      deleteSaga(),
      updateSaga(),
      listenAllSaga(),
      listenSaga(),
      existDocSaga(),
    ]);
  };
};
