import { all, put, select, takeLatest } from "@redux-saga/core/effects";
import { GET } from "../../../api/projectOverview/constants";
import { setLoading, setBurndown } from "./actions";
import { moment, weeksOfYear } from "@superprofit/time-util";
import { SET_PROJECT } from "../project/constants";
import { BUDGET_TYPE_ENUM } from "../../../../../models/BudgetTypes";
import { getLabels } from "../../../../../utils/ChartUtils";

export const getData = ({
  api: {
    projectStats: { stats }
  },
  ui: {
    projectOverview: { period }
  }
}) => ({
  stats,
  period
});

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

const getBurndownCurrentYear = (project, byWeek) => {
  const labels = getLabels(byWeek);

  const burndownByWeek = byWeek.reduce((prev, next) => {
    const nextKey = `${next.year}-${next.week}`;

    return {
      ...prev,
      [nextKey]:
        (prev[nextKey] ? prev[nextKey] : 0) +
        (project?.budget?.type === BUDGET_TYPE_ENUM.MONEY_BUDGET
          ? next.total * project.getBillableRate(next.user)
          : next.total)
    };
  }, {});

  const values = labels.map(l => burndownByWeek[l] || 0);

  return { values, labels };
};

export const handleUpdate = function*(args) {
  try {
    const {
      payload: { project }
    } = args;

    const {
      stats: { allTime: allTimeStats }
    } = yield select(getData);

    const { labels, values } = getBurndownCurrentYear(project, allTimeStats);

    // const dataset = burndown.sort((a, b) => (a.label > b.label ? 1 : -1));
    //
    // const values = dataset.map(d => d.value);
    // const labels = dataset.map(d => d.label);

    // let indexOfFirstEntry = 0;

    // dataset.forEach((d, idx) => {
    //   if (d.value > 0 && !indexOfFirstEntry) indexOfFirstEntry = idx;
    // });
    //
    // indexOfFirstEntry = indexOfFirstEntry === 0 ? 0 : indexOfFirstEntry - 1;
    //
    // const resDataset = dataset.slice(indexOfFirstEntry);
    // const resValues = values.slice(indexOfFirstEntry);
    // const resLabels = labels.slice(indexOfFirstEntry);
    //
    // yield put(setBurndown(resDataset, resLabels, resValues));

    yield put(setBurndown({}, labels, values));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleGetSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export const handleUpdateSaga = function*() {
  yield takeLatest(SET_PROJECT, handleUpdate);
};

export default function*() {
  yield all([handleUpdateSaga(), handleGetSaga()]);
}
