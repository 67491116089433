import {
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  all,
} from "redux-saga/effects";
import { WATCH_GET, WATCH_REMOVE, WATCH_SAVE } from "./constants";
import {
  get,
  getFailure,
  getSuccess,
  saveSuccess,
  saveFailure,
  save,
  watchGetTimesheetComments,
  watchSaveTimesheetComment,
  remove,
  watchRemoveTimesheetComment,
  removeSuccess,
  removeFailure,
} from "./actions";
import TimesheetComment from "../../../../../models/TimesheetComment";
import { Condition, Query } from "@superprofit/core-firestore-models";
import { Comment } from "./types";

export const getActiveWorkspace = ({
  api: { auth },
}: {
  api: { auth: { activeWorkspaceId: string } };
}) => auth.activeWorkspaceId;
export const getUserEmail = ({
  api: {
    auth: { user },
  },
}: {
  api: { auth: { user: { email: string } } };
}) => user.email;

// export const getComments = function*(args: {payload: {year: number, week: number}}) {
export const getComments = function* (
  args: ReturnType<typeof watchGetTimesheetComments>
) {
  const { payload } = args;
  const { year, week } = payload;
  try {
    yield put(get());
    const workspace: string = yield select(getActiveWorkspace);
    const user: string = yield select(getUserEmail);

    const query = new Query();
    query.addCondition(new Condition("user", "==", user));
    query.addCondition(new Condition("year", "==", year));
    query.addCondition(new Condition("week", "==", week));

    const comments: Comment[] = yield call(
      TimesheetComment.list,
      workspace,
      query
    );

    yield put(getSuccess(comments));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(getFailure(e.toString()));
    }
  }
};
export const removeComment = function* (
  args: ReturnType<typeof watchRemoveTimesheetComment>
) {
  const { payload } = args;
  const { comment } = payload;
  try {
    yield put(remove(comment));
    const workspace: string = yield select(getActiveWorkspace);
    yield call(TimesheetComment.delete, workspace, comment);

    yield put(removeSuccess(comment));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(removeFailure(comment, e.toString()));
    }
  }
};

// export const saveComment = function*(args: {payload: { comment: Comment}}) {
export const saveComment = function* (
  args: ReturnType<typeof watchSaveTimesheetComment>
) {
  const { payload } = args;
  const { comment /*updates*/ } = payload;

  try {
    const workspace: string = yield select(getActiveWorkspace);
    const user: string = yield select(getUserEmail);
    // const isNew = !comment || !comment.id;
    // const isNew = !comment || !comment.id;
    yield put(save(comment /*isNew*/));

    // let savedComment;
    const savedComment: Comment = yield call(
      TimesheetComment.create,
      workspace,
      user,
      comment
    );

    // if (isNew) {
    //   savedComment = yield call(
    //     TimesheetComment.create,
    //     workspace,
    //     user,
    //     updates
    //   );
    // } else {
    //   savedComment = yield call(
    //     TimesheetComment.update,
    //     workspace,
    //     user,
    //     comment,
    //     updates
    //   );
    // }

    yield put(saveSuccess(savedComment /*isNew*/));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(saveFailure(comment, e.toString()));
    }
  }
};

export const getCommentsSaga = function* () {
  yield takeLatest(WATCH_GET, getComments);
};

export const saveCommentSaga = function* () {
  yield takeEvery(WATCH_SAVE, saveComment);
};
export const removeCommentSaga = function* () {
  yield takeEvery(WATCH_REMOVE, removeComment);
};

export default function* () {
  yield all([getCommentsSaga(), saveCommentSaga(), removeCommentSaga()]);
}
