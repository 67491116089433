import { useSelector } from "react-redux";
import { RootState } from "../index";
import useCompanySettings from "./useCompanySettings";

const useCompanySettingsCurrency = () => {
  const { data: settings } = useCompanySettings();
  const curr = settings?.currency?.value;
  return curr && curr !== "none" ? curr : undefined;
};

export default useCompanySettingsCurrency;
