import React from "react";
import Card from "@superprofit/core-react-components/atoms/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Logged from "./workspaceStats/Logged";
import ProjectDistribution from "./workspaceStats/ProjectDistribution";
import TypeDistribution from "./workspaceStats/TypeDistribution";
import Planned from "./workspaceStats/Planned";
import usePeriod from "./hooks/usePeriod";
import useUserStats from "../../../../hooks/useUserStats";
import useProjects from "../../../../hooks/useProjects";
import useUsers from "../../../../hooks/useUsers";
import useWorkspace from "../../../../hooks/useWorkspace";
import usePrevMonthPeriodPeriod from "./hooks/usePrevMonthPeriod";
import useWorkspaceStats from "./hooks/useWorkspaceStats";

export default () => {
  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={3}
          component="div"
          alignItems="center"
          justify="flex-start"
        >
          <Grid item xs={12} md={6} component="div">
            <Logged />
          </Grid>
          <Grid item xs={12} md={6} component="div">
            <Planned />
          </Grid>
          <Grid item xs={12} md={6} component="div">
            <TypeDistribution />
          </Grid>
          <Grid item xs={12} md={6} component="div">
            <ProjectDistribution />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
