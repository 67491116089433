export const GET = "api/projects/GET";
export const GET_SUCCESS = "api/projects/GET_SUCCESS";
export const GET_FAILURE = "api/projects/GET_FAILED";

export const GET_PROJECT = "api/projects/GET_PROJECT";
export const GET_PROJECT_SUCCESS = "api/projects/GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILURE = "api/projects/GET_PROJECT_FAILURE";

export const SAVE = "api/projects/SAVE";
export const SAVE_SUCCESS = "api/projects/SAVE_SUCCESS";
export const SAVE_FAILURE = "api/projects/SAVE_FAILED";

export const DELETE = "api/projects/DELETE";
export const DELETE_SUCCESS = "api/projects/DELETE_SUCCESS";
export const DELETE_FAILURE = "api/projects/DELETE_FAILED";

export const PATCH = "api/projects/PATCH";
export const PATCH_SUCCESS = "api/projects/PATCH_SUCCESS";
export const PATCH_FAILURE = "api/projects/PATCH_FAILURE";

export const PATCH_MULTIPLE = "api/projects/PATCH_MULTIPLE";
export const PATCH_MULTIPLE_SUCCESS = "api/projects/PATCH_MULTIPLE_SUCCESS";
export const PATCH_MULTIPLE_FAILURE = "api/projects/PATCH_MULTIPLE_FAILURE";

export const DUPLICATE = "api/projects/DUPLICATE";
export const DUPLICATE_SUCCESS = "api/projects/DUPLICATE_SUCCESS";
export const DUPLICATE_FAILURE = "api/projects/DUPLICATE_FAILURE";

export const WATCH_DUPLICATE = "api/projects/WATCH_DUPLICATE";
export const WATCH_GET_PROJECT = "api/projects/WATCH_GET_PROJECT";
export const WATCH_GET = "api/projects/WATCH_GET";
export const WATCH_SAVE = "api/projects/WATCH_SAVE";
export const WATCH_DELETE = "api/projects/WATCH_DELETE";
export const WATCH_PATCH = "api/projects/WATCH_PATCH";
export const WATCH_PATCH_MULTIPLE = "api/projects/WATCH_PATCH_MULTIPLE";
