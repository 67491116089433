import { FilterAction, OnlyShowProjectsWith, State } from "./types";
import { createReducer } from "typesafe-actions";
import { SET } from "./constants";

const updateLocalStorage = (filter: Partial<State>) => {
  const { onlyShowProjectsWith, showHiddenProjects } = filter;
  if (onlyShowProjectsWith) {
    localStorage.setItem(`${SET}.onlyShowProjectsWith`, onlyShowProjectsWith);
  } else {
    localStorage.removeItem(`${SET}.onlyShowProjectsWith`);
  }

  if (showHiddenProjects) {
    localStorage.setItem(`${SET}.showHiddenProjects`, "true");
  } else {
    localStorage.removeItem(`${SET}.showHiddenProjects`);
  }
};

const getLocalStorage = () => {
  const onlyShowProjectsWith = localStorage.getItem(
    `${SET}.onlyShowProjectsWith`
  );
  const showHiddenProjects = localStorage.getItem(`${SET}.showHiddenProjects`);

  return {
    onlyShowProjectsWith:
      (onlyShowProjectsWith as OnlyShowProjectsWith) || null,
    showHiddenProjects: showHiddenProjects === "true"
  };
};

const initialState: State = {
  search: "",
  showModal: false,
  ...getLocalStorage()
};

const reducer = createReducer<State, FilterAction>(initialState).handleType(
  SET,
  (state, action) => {
    updateLocalStorage(action.payload.filter);
    return { ...state, ...action.payload.filter };
  }
);

export default reducer;
