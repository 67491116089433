import {
  HIDE_ACCESS_CONTROL_DIALOG,
  SHOW_ACCESS_CONTROL_DIALOG,
  UPDATE_ACCESS_CONTROL_DIALOG_DATA,
  WATCH_SAVE_USER_ACCESS,
  SET_SAVING
} from "./constants";
import { action } from "typesafe-actions";
import User from "../../../../../models/User";

export const hideAccessControlDialog = () => action(HIDE_ACCESS_CONTROL_DIALOG);

export const showAccessControlDialog = (users: User[]) =>
  action(SHOW_ACCESS_CONTROL_DIALOG, { users });

export const updateAccessControlDialogData = (updates: any) =>
  action(UPDATE_ACCESS_CONTROL_DIALOG_DATA, { updates });

export const watchSaveUserAccess = () => action(WATCH_SAVE_USER_ACCESS);

export const setSaving = (saving: boolean) => action(SET_SAVING, { saving });
