import {
  HIDE_DELETE_USERS_DIALOG,
  SHOW_DELETE_USERS_DIALOG,
  RESET_DELETE_USERS_DIALOG
} from "./constants";
import { action } from "typesafe-actions";
import User from "../../../../../models/User";

export const showDeleteUsersDialog = (users: User[]) =>
  action(SHOW_DELETE_USERS_DIALOG, { users });

export const hideDeleteUsersDialog = () => action(HIDE_DELETE_USERS_DIALOG);

export const resetDeleteUsersDialog = () => action(RESET_DELETE_USERS_DIALOG);
