import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../index";
import {
  hideDialog,
  showDialog
} from "../../../../redux/modules/ui/companySettings/nettlonnFixProjectsDialog/actions";

const useFixProjectsDialog = () => {
  const dispatch = useDispatch();

  const { open } = useSelector(
    (state: RootState) => state.ui.companySettings.nettlonnFixProjectsDialog
  );

  const show = () => {
    dispatch(showDialog());
  };

  const close = () => {
    dispatch(hideDialog());
  };

  return {
    close,
    open,
    show
  };
};

export default useFixProjectsDialog;
