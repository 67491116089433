import React, { useMemo, useState } from "react";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import HoursHistoryLoader from "./weeklyHours/HoursHistoryLoader";
import LineChart from "../../../../components/atoms/LineChart";
import useTheme from "@material-ui/core/styles/useTheme";
import styled from "styled-components";
import Card from "@superprofit/core-react-components/atoms/Card";
import { getLabel, getLabels } from "../../../../utils/ChartUtils";
import { useTranslation } from "react-i18next";

const DashboardCard = styled(Card)`
  position: relative;
  pointer-events: all;
`;

export default ({ stats, projects, loading, ...rest }) => {
  const [showPoints, setShowPoints] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const projectsById = useMemo(() => {
    if (loading || !projects) return {};
    return projects.reduce(
      (prev, next) => ({
        ...prev,
        [next.id]: next
      }),
      {}
    );
  }, [projects, loading]);

  const { labels, datasets } = useMemo(() => {
    if (loading || !stats) return { datasets: [], labels: [] };
    const billableStats = stats.filter(s => projectsById[s.project].billable);
    const nonBillableStats = stats.filter(
      s => !projectsById[s.project].billable
    );

    const labels = getLabels(stats);

    const billableHoursByWeek = billableStats.reduce(
      (prev, next) => ({
        ...prev,
        [getLabel(next)]: (prev[getLabel(next)] || 0) + next.total
      }),
      {}
    );

    const nonBillableHoursByWeek = nonBillableStats.reduce(
      (prev, next) => ({
        ...prev,
        [getLabel(next)]: (prev[getLabel(next)] || 0) + next.total
      }),
      {}
    );

    const datasets = [
      {
        color: theme.palette.tertiary.main,
        values: labels.map(l => nonBillableHoursByWeek[l] || 0),
        label: "Non-billable"
      },
      {
        color: theme.palette.primary.main,
        values: labels.map(l => billableHoursByWeek[l] || 0),
        label: "Billable"
      }
    ];
    return {
      labels,
      datasets
    };
  }, [stats, loading, projectsById]);

  const handleMouseOver = () => {
    if (showPoints) return;
    setShowPoints(true);
  };

  const handleMouseLeave = () => {
    if (!showPoints) return;
    setShowPoints(false);
  };

  if (loading) {
    return <HoursHistoryLoader />;
  }

  return (
    <DashboardCard
      {...rest}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <CardHeader
        title={t("common.activityOverTime")}
        subheader={
          labels &&
          labels.length > 0 &&
          t("common.templates.showingWeeks", {
            firstLabel: labels[0],
            secondLabel: labels[labels.length - 1]
          })
        }
      />

      {labels && labels.length > 0 && (
        <LineChart
          id="billable-nonbillable-customer"
          showPoints={showPoints}
          datasets={datasets}
          labels={labels}
        />
      )}
    </DashboardCard>
  );
};
