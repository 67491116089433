import { useMemo } from "react";

const useConvertToMap = <T extends Record<string, any>>(
  key: Extract<keyof T, string>,
  list: T[]
) => {
  return useMemo(() => {
    const m = new Map<string, T>();
    list.forEach((t: T) => {
      m.set(t[key], t);
    });
    return m;
  }, [key, list]);
};

export default useConvertToMap;
