import {
  HIDE_ACCESS_CONTROL_DIALOG,
  SHOW_ACCESS_CONTROL_DIALOG,
  UPDATE_ACCESS_CONTROL_DIALOG_DATA
} from "./constants";

import { createReducer } from "typesafe-actions";
import { AccessControlDialogAction, AccessControlDialogState } from "./types";

const initialState: AccessControlDialogState = {
  open: false,
  updates: {},
  users: [],
  saving: false,
  hasUnsaved: false
};

const reducer = createReducer<
  AccessControlDialogState,
  AccessControlDialogAction
>(initialState)
  .handleType(HIDE_ACCESS_CONTROL_DIALOG, state => ({
    ...state,
    open: false,
    hasUnsaved: false
  }))
  .handleType(SHOW_ACCESS_CONTROL_DIALOG, (state, action) => ({
    ...initialState,
    open: true,
    users: action.payload.users
  }))
  .handleType(UPDATE_ACCESS_CONTROL_DIALOG_DATA, (state, action) => ({
    ...state,
    updates: { ...state.updates, ...action.payload.updates },
    hasUnsaved: true
  }));

export default reducer;
