import workspaceConstants from "../Workspaces/constants";

import {
  SET_ACTIVE_WORKSPACE,
  SET_ID_TOKEN,
  SET_WORKSPACE,
  SIGNED_IN,
  SIGNED_OUT, WORKSPACE_JOINED, WORKSPACE_JOINING,
} from './constants';
import { getCurrentWorkspace } from "../Helpers";

const initialState = {
  activeWorkspaceId: getCurrentWorkspace(),
};

export default (state = initialState, { type, payload = {} }) => {
  const { user, idTokenResult, activeWorkspaceId, workspace } = payload;
  switch (type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        activeWorkspaceId: getCurrentWorkspace(),
      };
    case SIGNED_IN:
      return {
        ...state,
        user,
      };
    case SET_ID_TOKEN:
      return {
        ...state,
        idTokenResult,
      };
    case SET_ACTIVE_WORKSPACE:
      return {
        ...state,
        activeWorkspaceId,
      };
    case WORKSPACE_JOINING:
      return {
        ...state,
        workspaceJoining: payload.workspace,
      };
    case WORKSPACE_JOINED:
      return {
        ...state,
        workspaceJoining: undefined,
      };
    case workspaceConstants.LISTEN_SUCCESS:
      return {
        ...state,
        workspace: payload.data,
      };
    case SET_WORKSPACE:
      return {
        ...state,
        workspace,
      };
    case SIGNED_OUT:
      return initialState;

    default:
      return state;
  }
};
