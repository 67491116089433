import styled , { css } from 'styled-components';
import { Badge } from "@material-ui/core";

export const BadgeColors = {
  tertiary: css`
    background: ${props => props.theme.palette.tertiary.main};
  `,
  alert: css`
    background: ${props => props.theme.palette.alert.main};
  `,
  warning: css`
    background: ${props => props.theme.palette.warning.main};
  `,
  success: css`
    background: ${props => props.theme.palette.success.main};
  `
};

export const StyledBadge = styled(Badge)`

  .MuiBadge-badge {
    ${props => BadgeColors[props.color]};
  }
`;
