import {
  GET,
  GET_FAILURE,
  GET_CUSTOMER,
  GET_CUSTOMER_FAILURE,
  GET_CUSTOMER_SUCCESS,
  GET_SUCCESS,
  PATCH,
  PATCH_FAILURE,
  PATCH_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
  WATCH_GET,
  WATCH_GET_CUSTOMER,
  WATCH_PATCH,
  WATCH_SAVE
} from "./constants";

export const get = () => ({
  type: GET,
  payload: {}
});

export const getSuccess = (list, pagination) => ({
  type: GET_SUCCESS,
  payload: { list, pagination }
});

export const getFailure = error => ({
  type: GET_FAILURE,
  payload: { error }
});

export const getCustomer = id => ({
  type: GET_CUSTOMER,
  payload: { id }
});

export const getCustomerSuccess = (id, customer) => ({
  type: GET_CUSTOMER_SUCCESS,
  payload: { id, customer }
});

export const getCustomerFailure = (id, error) => ({
  type: GET_CUSTOMER_FAILURE,
  payload: { id, error }
});

export const save = () => ({
  type: SAVE,
  payload: {}
});

export const saveSuccess = (customer, isNew) => ({
  type: SAVE_SUCCESS,
  payload: { customer, isNew }
});

export const saveFailure = error => ({
  type: SAVE_FAILURE,
  payload: { error }
});

export const patch = id => ({
  type: PATCH,
  payload: { id }
});

export const patchSuccess = (id, updates) => ({
  type: PATCH_SUCCESS,
  payload: { id, updates }
});

export const patchFailure = error => ({
  type: PATCH_FAILURE,
  payload: { error }
});

export const watchGetCustomers = () => ({
  type: WATCH_GET,
  payload: {}
});

export const watchGetCustomer = id => ({
  type: WATCH_GET_CUSTOMER,
  payload: { id }
});

export const watchSaveCustomer = (customer, updates) => ({
  type: WATCH_SAVE,
  payload: { customer, updates }
});

export const watchPatchCustomer = (id, updates) => ({
  type: WATCH_PATCH,
  payload: { id, updates }
});
