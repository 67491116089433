import React from "react";
import * as FileSaver from "file-saver";
import { pdf, Document, Page, Text, View } from "@react-pdf/renderer";
import styles from "./PDFReport.styles";

export const createPDFBlob = async (report, { workspaceDisplayName = 'Display name not set'}) => {
  const blob = await pdf(
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <View style={styles.pageHeader} fixed>
          <Text style={styles.pageHeaderCompany}>{workspaceDisplayName}</Text>
          <Text style={styles.pageHeaderTimet}>timet.io</Text>
        </View>
        {report.renderAsPDF()}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  ).toBlob();

  return blob;
};

export const saveAsPDF = async (report, fileName, options = {}) => {
  const blob = await createPDFBlob(report, options);
  const fileExtension = ".pdf";
  FileSaver.saveAs(blob, fileName + fileExtension);
};
