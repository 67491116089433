export const iframeStyles = {
  base: {
    // color: "#fff",
    fontSize: "1rem",
    // iconColor: "#fff",
    "::placeholder": {
      // color: "#87bbfd",
    },
  },
  invalid: {
    // iconColor: "#FFC7EE",
    // color: "#FFC7EE",
  },
  complete: {
    // iconColor: "#cbf4c9",
  },
};

export const options = {
  iconStyle: "solid",
  style: iframeStyles,
  hidePostalCode: true,
};