import { db, firebase } from "../firebase";
import { Query, QueryBuilder } from "./QueryBuilder";
import Timestamp from "./Timestamp";
type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;

// This is a bit of a mess. Struggled with circular dependencies so just added everything here.

export enum SETTING_TYPES {
  GENERIC_KEY_VALUE = "GENERIC_KEY_VALUE",
  PERFORMANCE_GOAL = "PERFORMANCE_GOAL"
}

type AllowedInstanceTypes = InstanceType<
  typeof SETTING_TYPES_CLASSES[keyof typeof SETTING_TYPES_CLASSES]
>;

interface ICompanySetting {
  type: SETTING_TYPES;
}

interface KnownCompanySettingTypes {
  features?: CompanySettingGeneric;
  currency?: CompanySettingGeneric;
  holidays?: CompanySettingGeneric;
  workdays?: CompanySettingGeneric;
  defaultCapacity?: CompanySettingGeneric;
  [key: string]: AllowedInstanceTypes | undefined;
}

export default class CompanySetting implements ICompanySetting {
  type: SETTING_TYPES;
  static collectionName = "companySettings";

  setData(updates: Partial<ICompanySetting>) {
    Object.assign(this, updates);
    return this;
  }

  constructor({ type }: { type: SETTING_TYPES }) {
    this.type = type;
  }

  static converter = {
    toFirestore(setting: AllowedInstanceTypes) {
      return setting.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      let data = snapshot.data(options) as AllowedInstanceTypes;
      const { type } = data;

      if (type === SETTING_TYPES.PERFORMANCE_GOAL) {
        return new CompanySettingGoal({
          ...(data as CompanySettingGoal)
        });
      }

      if (type === SETTING_TYPES.GENERIC_KEY_VALUE) {
        return new CompanySettingGeneric({
          ...(data as CompanySettingGeneric),
          id: snapshot.id
        });
      }
      throw new Error("Invalid setting type");
    }
  };

  static map = async (workspace: string, query?: Query) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(CompanySetting.collectionName),
      query || new Query()
    )
      .withConverter(CompanySetting.converter)
      .get();

    const result = {} as {
      [key: string]: AllowedInstanceTypes;
    };

    snapshot.docs.forEach((doc: QueryDocumentSnapshot) => {
      result[doc.id] = doc.data() as AllowedInstanceTypes;
    });

    return result as KnownCompanySettingTypes;
  };

  static save = async (
    workspace: string,
    user: string,
    setting: AllowedInstanceTypes
  ) => {
    const updatedSetting = setting.clone();

    updatedSetting.setData({
      updatedAt: Timestamp.now(),
      updatedBy: user
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(CompanySetting.collectionName)
      .doc(updatedSetting.id)
      .withConverter(CompanySetting.converter)
      .set(updatedSetting, { merge: true });

    return updatedSetting;
  };
}

export interface ICompanySettingGeneric {
  type: SETTING_TYPES.GENERIC_KEY_VALUE;
  id: string;
  value: any;
  updatedAt: Timestamp | null;
  updatedBy: string | null;
}

export class CompanySettingGeneric extends CompanySetting {
  type: SETTING_TYPES.GENERIC_KEY_VALUE;
  id: string;
  value: any;
  updatedAt: Timestamp | null;
  updatedBy: string | null;

  constructor({
    id,
    value,
    updatedBy,
    updatedAt
  }: Omit<ICompanySettingGeneric, "type">) {
    super({ type: SETTING_TYPES.GENERIC_KEY_VALUE });
    this.type = SETTING_TYPES.GENERIC_KEY_VALUE;
    this.id = id;
    this.value = value;
    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  isValid = () => {
    return !!this.id;
  };

  setData(updates: Partial<ICompanySettingGeneric>) {
    Object.assign(this, updates);
    return this;
  }

  data() {
    return {
      type: this.type,
      value: this.value,
      updatedAt: this.updatedAt,
      updatedBy: this.updatedBy
    };
  }
}

export interface ICompanySettingGoal {
  type: SETTING_TYPES.PERFORMANCE_GOAL;
  id: string;
  year: number;
  month: number;
  billable: number;
  avgBillableRate: number;
  nonBillable: number;
  updatedAt: Timestamp | null;
  updatedBy: string;
}

export class CompanySettingGoal extends CompanySetting
  implements ICompanySettingGoal {
  type: SETTING_TYPES.PERFORMANCE_GOAL;
  id: string;
  year: number;
  month: number;
  billable: number;
  avgBillableRate: number;
  nonBillable: number;
  updatedAt: Timestamp;
  updatedBy: string;
  constructor({
    billable,
    avgBillableRate,
    nonBillable,
    year,
    month,
    updatedBy,
    updatedAt
  }: Omit<ICompanySettingGoal, "id" | "type">) {
    super({ type: SETTING_TYPES.PERFORMANCE_GOAL });
    this.type = SETTING_TYPES.PERFORMANCE_GOAL;
    this.id = `goal-${year}-${month || "all"}`;
    this.year = year;
    this.month = month;
    this.billable = billable;
    this.avgBillableRate = avgBillableRate;
    this.nonBillable = nonBillable;

    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  isValid = () => {
    return !!this.year;
  };

  setData(updates: Partial<ICompanySettingGoal>) {
    Object.assign(this, updates);
    return this;
  }

  data() {
    return {
      type: this.type,
      year: this.year,
      month: this.month,
      billable: this.billable,
      avgBillableRate: this.avgBillableRate,
      nonBillable: this.nonBillable,
      updatedAt: this.updatedAt,
      updatedBy: this.updatedBy
    };
  }
}

const SETTING_TYPES_CLASSES = {
  GENERIC_KEY_VALUE: CompanySettingGeneric,
  PERFORMANCE_GOAL: CompanySettingGoal
} as const;
