import React, { forwardRef, ForwardRefExoticComponent } from "react";
import { StyledAvatar } from "./avatar/Avatar.styles";
import { AvatarProps } from "@material-ui/core";

interface IAvatarProps extends AvatarProps {
  initial?: string;
  colorKey?: string;
}

const Avatar: ForwardRefExoticComponent<IAvatarProps> = forwardRef(
  ({ initial, colorKey, color, ...rest }, ref) => {
    if (initial) {
      return (
        <StyledAvatar ref={ref} {...rest} initial={initial} color={color}>
          {initial}
        </StyledAvatar>
      );
    }

    if (colorKey) {
      return <StyledAvatar ref={ref} {...rest} initial={colorKey} />;
    }

    return <StyledAvatar ref={ref} {...rest} />;
  }
);

export default Avatar;
