import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Paper } from "@material-ui/core";
import { Button } from "@superprofit/core-react-components/atoms";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import Snackbar from "@superprofit/core-react-components/atoms/Snackbar";
import { watchGetProjectOverview } from "../../redux/modules/api/projectOverview/actions";
import ProjectDialog from "./projects/ProjectDialog";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Page, {
  PageActions,
  PageHeader,
  PageTitle
} from "../../components/atoms/Page";
import CustomerLink from "../../components/molecules/CustomerLink";
import Skeleton from "@material-ui/lab/Skeleton";
import Overview from "./projectOverview/Overview";
import Plan from "./projectOverview/Plan";
import Settings from "./projectOverview/Settings";
import LinkButton from "../../components/atoms/LinkButton";
import useDialog from "./projects/hooks/useDialog";
import useProject from "../../hooks/useProject";
import { useTranslation } from "react-i18next";
import { getPathWithKey } from "../../router/routes";

export default ({
  match: {
    params: { id }
  }
}) => {
  const { t } = useTranslation();

  const [tab, setTab] = useState("overview");
  const { show } = useDialog();

  const dispatch = useDispatch();

  const period = useSelector(state => state.ui.projectOverview.period);

  const {
    data: { customer },
    loading
  } = useSelector(state => state.ui.projectOverview.basis);

  // const { project } = useSelector(state => state.ui.projectOverview.project);
  const { data: project, isLoading: projectLoading } = useProject(id);
  const snackbar = useSelector(state => state.ui.projectOverview.snackbar);

  useEffect(() => {
    const { year, month } = period;
    dispatch(watchGetProjectOverview(id, year, month));
  }, [period, dispatch]);

  const handleOnEdit = () => {
    show(project.id);
  };

  const handleOnChangeTab = (e, val) => {
    setTab(val);
  };

  return (
    <Page>
      <PageHeader color={project && project.getColor && project.getColor()}>
        <Grid
          container
          spacing={3}
          component="div"
          style={{ marginBottom: 4 }}
          justify="space-between"
        >
          <Grid item xs={6} component="div">
            <LinkButton
              color="inherit"
              startIcon={<ArrowBackIcon />}
              to={getPathWithKey("projects")}
            >
              {t("common.projects")}
            </LinkButton>
          </Grid>
          <Grid item xs={6} component="div">
            <PageActions>
              <Button
                disabled={!project || loading}
                color="inherit"
                onClick={handleOnEdit}
              >
                {t("common.edit")}
              </Button>
            </PageActions>
          </Grid>
        </Grid>

        <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
          <Grid container item xs={12} component="div" justify="center">
            <PageTitle>
              {!project || loading ? (
                <Skeleton
                  height={25}
                  width={200}
                  style={{ backgroundColor: "rgba(255,255,255,.5)" }}
                />
              ) : (
                project.name
              )}
            </PageTitle>
          </Grid>
          <Grid container item xs={12} component="div" justify="center">
            {loading ? (
              <Skeleton
                height={15}
                width={150}
                style={{ backgroundColor: "rgba(255,255,255,.5)" }}
              />
            ) : (
              <CustomerLink
                id={customer && customer.id}
                name={customer && customer.name}
              />
            )}
          </Grid>
        </Grid>
      </PageHeader>

      <Paper square>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleOnChangeTab}
          aria-label="disabled tabs example"
        >
          <Tab label={t("common.overview")} value="overview" />
          <Tab label={t("common.settings")} value="settings" />
          <Tab label={t("common.plan")} value="plan" />
        </Tabs>
      </Paper>

      {tab === "overview" && <Overview />}
      {tab === "settings" && <Settings />}
      {tab === "plan" && <Plan />}

      <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={snackbar.visible}
          message={snackbar.text}
        />
      </AppBar>

      <ProjectDialog />
    </Page>
  );
};
