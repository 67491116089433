export default {
  logo: {
    margin: "0 auto",
    height: 100,
    width: 200,
    objectFit: "contain",
    marginBottom: 20
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    flexDirection: "row"
  },
  container: {
    margin: 10,
    padding: 10
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  },
  pageHeader: {
    width: "100%",
    position: "absolute",
    fontSize: 12,
    top: 30,
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  pageHeaderCompany: {
    fontSize: 10,
    marginLeft: 50,
    flex: 1,
    color: "#3f51b5"
  },
  pageHeaderTimet: {
    fontSize: 10,
    flex: 1,
    textAlign: "right",
    color: "#aaaaaa"
  },
  body: {
    paddingTop: 65,
    paddingBottom: 65,
    paddingHorizontal: 35
  }
};
