import React, { useMemo } from "react";
import DurationFormat from "../../../../../components/atoms/DurationFormat";
import { getProjectHoursByCustomer } from "../../../../../utils/CustomerStats";
import DistributionBar from "../../../../../components/atoms/DistributionBar";
import Hint from "../../../../../components/atoms/Hint";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  StyledProjectDistribution,
  StyledProjectDistributionStats
} from "./ProjectDistribution.styles";
import Important from "../../../../../components/atoms/Important";

export default ({ stats, customer, projects, loading }) => {
  const projectsById = useMemo(() => {
    if (loading || !projects) return {};
    return projects.reduce(
      (prev, next) => ({
        ...prev,
        [next.id]: next
      }),
      {}
    );
  }, [projects, loading]);

  const filteredStats = useMemo(() => {
    if (loading || !stats) return [];
    return stats;
  }, [stats, loading]);

  const [activeUsers, totalUsers] = useMemo(() => {
    if (!projects || loading) return [0, 0];
    const uniqueUsers = projects.reduce(
      (prev, next) => ({
        ...prev,
        ...(next.team || []).reduce(
          (uPrev, uNext) => ({
            ...uPrev,
            [uNext]: uNext
          }),
          prev
        )
      }),
      {}
    );

    const activeUsers = filteredStats.reduce(
      (prev, next) => ({
        ...prev,
        [next.user]: next
      }),
      {}
    );

    return [Object.keys(activeUsers).length, Object.keys(uniqueUsers).length];
  }, [projects, filteredStats, loading]);

  const projectHours = useMemo(() => {
    if (loading || !customer || !filteredStats) return {};
    const hrs = getProjectHoursByCustomer(filteredStats, projectsById);
    return hrs[customer.id] || {};
  }, [filteredStats, projectsById, customer, loading]);

  const distributionDataset = useMemo(() => {
    if (loading || !projectsById) return [];
    return Object.keys(projectHours)
      .map(p => ({
        value: projectHours[p],
        color: projectsById[p].color,
        tooltip: (
          <DurationFormat
            value={projectHours[p]}
            prefix={`${projectsById[p].name}: `}
          />
        )
      }))
      .filter(v => !!v.value);
  }, [loading, projectsById, projectHours]);

  return (
    <StyledProjectDistribution>
      <div>
        <Hint>Project distribution</Hint>
        {loading ? (
          <Skeleton height={20} width="100%" />
        ) : (
          <DistributionBar dataset={distributionDataset} />
        )}
      </div>
      <StyledProjectDistributionStats>
        <li>
          <Hint>Active projects</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              {distributionDataset.length}/{projects.length}
            </Important>
          )}
        </li>
        <li>
          <Hint>Active users</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              {activeUsers}/{totalUsers}
            </Important>
          )}
        </li>
      </StyledProjectDistributionStats>
    </StyledProjectDistribution>
  );
};
