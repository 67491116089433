import React from "react";
import Card from "@superprofit/core-react-components/atoms/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Logged from "./projectStats/Logged";
import BudgetProgress from "../budget/BudgetProgress";

export default ({
  month,
  year,
  stats,
  statsBefore,
  allTimeStats,
  loading,
  project,
  ...rest
}) => {
  return (
    <Card {...rest}>
      <CardContent>
        <Grid container spacing={3} component="div" alignItems="center">
          <Grid container item xs={12} md={6} lg={7} component="div">
            <Grid item xs={12} component="div">
              <Logged
                year={year}
                month={month}
                loading={loading}
                project={project}
                stats={stats}
                statsBefore={statsBefore}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={5} component="div">
            <BudgetProgress
              loading={loading}
              stats={allTimeStats}
              project={project}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
