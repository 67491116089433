import React, { forwardRef, ForwardRefExoticComponent, Ref } from "react";
import {
  StyledElevatedTextField,
  StyledTextField
} from "./textField/TextField.styles";
import { StandardTextFieldProps } from "@material-ui/core";

export type ITextField = Omit<StandardTextFieldProps, "variant"> & {
  variant?: "filled" | "outlined" | "standard"; // variant TS bug https://github.com/mui/material-ui/issues/15697#issuecomment-493419773
} & {
  align?: "right";
  alignlabel?: "center";

  ref?: React.Ref<HTMLInputElement> | any; // hack to make types work. dont have time
};

const TextField: ForwardRefExoticComponent<ITextField> = forwardRef<
  HTMLInputElement,
  ITextField
>((props, ref) => (
  <StyledTextField
    {...props}
    ref={ref as React.MutableRefObject<HTMLInputElement>}
  />
));

export const ElevatedTextField: ForwardRefExoticComponent<ITextField> = forwardRef(
  (props, ref) => <StyledElevatedTextField {...props} ref={ref} />
);

export default TextField;
