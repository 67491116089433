import styled, {css} from 'styled-components';
import theme from "styled-theming";
import Card from "@superprofit/core-react-components/atoms/Card";

export const PlanCard = styled(Card)`
  overflow: visible;
  padding-bottom: ${props => props.theme.spacing(2)}px;
  transform: ${props => props.active ? 'scale(1)' : 'scale(0.9)'};
`;

const colors = theme.variants('mode', 'plan', {
  small: {
    light: css`
      background: ${props => props.theme.palette.primary.main};
    `,
    dark: css``
  },
  medium: {
    light: css`
      background: ${props => props.theme.palette.secondary.main};
    `,
    dark: css``
  },
  large: {
    light: css`
      background: ${props => props.theme.palette.tertiary.main};
    `,
    dark: css``
  },
});

export const Plan = styled.div`
  padding: ${props => props.theme.spacing(5)}px;
  ${colors};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  margin-left: ${props => props.theme.spacing(4)}px;
  margin-right: ${props => props.theme.spacing(4)}px;
  transform: translate3d(0, -2rem, 0);
`;

export const PlanName = styled.div`
  margin-top: ${props => props.theme.spacing(3)}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
`;


const icons = theme.variants('mode', 'plan', {
  small: {
    light: css`
      transform: rotate(-135deg);
      &:after {
        border-bottom-right-radius: ${props => props.theme.shape.borderRadius * 16}px;
      }
    `,
    dark: css``
  },
  medium: {
    light: css`
      transform: rotate(-135deg);
      &:after {
        border-bottom-right-radius: ${props => props.theme.shape.borderRadius * 12}px;
      }
    `,
    dark: css``
  },
  large: {
    light: css`
      transform: rotate(-135deg);
      &:after {
        border-bottom-right-radius: ${props => props.theme.shape.borderRadius * 8}px;
      }
    `,
    dark: css``
  },
});

export const PlanIcon = styled.div`
  height: 3rem;
  width: 3rem;
  display: block;
  position: relative;
  background: rgba(255,255,255, .5);
  border-radius: ${props => props.theme.shape.borderRadius}px;
  ${icons};
  
  &:after {
    height: 3rem;
    width: 3rem;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255,255,255, .9);
    border-top-left-radius: ${props => props.theme.shape.borderRadius}px;
    border-top-right-radius: ${props => props.theme.shape.borderRadius}px;
    border-bottom-left-radius: ${props => props.theme.shape.borderRadius}px;
  }
`;

export const PlanContent = styled.div`
  margin-left: ${props => props.theme.spacing(2)}px;
  margin-right: ${props => props.theme.spacing(2)}px;
`;

export const PlanCost = styled.div`
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
  letter-spacing: -1px;
`;

export const PlanUsers = styled.div`
  margin-top: ${props => props.theme.spacing(1)}px;
  margin-bottom: ${props => props.theme.spacing(2)}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.palette.text.hint};
`;

export const PlanDetails = styled.div`
  margin-top: ${props => props.theme.spacing(2)}px;
  margin-bottom: ${props => props.theme.spacing(2)}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const PlanActions = styled.div`
  margin-top: ${props => props.theme.spacing(2)}px;
  margin-bottom: ${props => props.theme.spacing(2)}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
