import { all, delay, put, takeLatest } from "@redux-saga/core/effects";
import { SAVE, SAVE_SUCCESS } from "../../../api/companySettings/constants";

import { updateSnackbar } from "./actions";

export const handleLeadSaving = function*(args) {
  try {
    yield put(updateSnackbar({ visible: true, text: "Saving" }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleLeadSaved = function*() {
  try {
    yield put(updateSnackbar({ visible: true, text: "All changes saved" }));
    yield delay(2000);
    yield put(updateSnackbar({ visible: false }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleLeadSavingSaga = function*() {
  yield takeLatest(SAVE, handleLeadSaving);
};

export const handleLeadSavedSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleLeadSaved);
};

export default function*() {
  yield all([handleLeadSavingSaga(), handleLeadSavedSaga()]);
}
