import { View } from "@react-pdf/renderer";
import React from "react";
import {Container, Header, Line, Subtitle, Timet} from './pageHeader/pageHeader.styles';

export default ({ children = "Default Project", gutterBottom, subtitle, title }) => (
  <Container gutterBottom={gutterBottom}>
    <Line />
    {(title || children) && <Header>{title || children}</Header>}
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </Container>
);
