import { db } from "../firebase";
import { QueryBuilder, Timestamp } from "@superprofit/core-firestore-models";

const colors = [
  "#4422ff",
  "#2255cc",
  "#aa3388",
  "#FA5A5D",
  "#00ffff",
  "#ffff00",
  "#ff9900",
  "#88ff88"
];

const getIndex = initial => {
  return initial.toLowerCase().charCodeAt(0) % colors.length;
};

const getColor = initial => {
  return colors[getIndex(initial)];
};

export default class Customer {
  static collectionName = "customers";

  static converter = {
    toFirestore(customer) {
      return customer.data();
    },
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      return new Customer({ ...data, id: snapshot.id });
    }
  };

  static createId(workspace) {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(Customer.collectionName)
      .doc().id;
  }

  constructor({
    id,
    archived,
    name,
    contactName,
    email,
    color,
    billingAddress,
    billingAddressZip,
    billingAddressCity,
    billingAddressState,
    billingAddressCountry,
    twntyFourSevenId,
    externalReference,
    tags,
    createdBy,
    updatedBy,
    createdAt,
    updatedAt
  }) {
    this.archived = archived || null;
    this.id = id;
    this.name = name;
    this.contactName = contactName || "";
    this.email = email || "";
    this.tags = tags || "";
    this.color = color || "#3f51b5";
    this.externalReference = externalReference || null;
    this.billingAddress = billingAddress || "";
    this.billingAddressZip = billingAddressZip || "";
    this.billingAddressCity = billingAddressCity || "";
    this.billingAddressState = billingAddressState || "";
    this.billingAddressCountry = billingAddressCountry || "";
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
    this.twntyFourSevenId = twntyFourSevenId;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  getColor() {
    return (
      this.color ||
      (this.name && this.name.length && getColor(this.name[0])) ||
      "#3f51b5"
    );
  }

  setData(updates) {
    Object.assign(this, updates);
    return this;
  }

  isValid = () => {
    return !!this.name;
  };

  data() {
    return {
      archived: this.archived || null,
      name: this.name || null,
      contactName: this.contactName || null,
      email: this.email || null,
      tags: this.tags || null,
      color:
        this.color ||
        (this.name && this.name.length && getColor(this.name[0])) ||
        "#3f51b5",
      billingAddress: this.billingAddress || null,
      billingAddressZip: this.billingAddressZip || null,
      billingAddressCity: this.billingAddressCity || null,
      billingAddressState: this.billingAddressState || null,
      billingAddressCountry: this.billingAddressCountry || null,
      externalReference: this.externalReference || null,
      createdBy: this.createdBy || null,
      createdAt: this.createdAt || null,
      updatedAt: this.updatedAt || null,
      updatedBy: this.updatedBy || null,
      twntyFourSevenId: this.twntyFourSevenId || null
    };
  }

  static async get(workspace, id) {
    const doc = await db
      .collection("workspaces")
      .doc(workspace)
      .collection("customers")
      .doc(id)
      .withConverter(Customer.converter)
      .get();
    return doc.data();
  }

  static list = async (workspace, query) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(Customer.collectionName),
      query
    )
      .withConverter(Customer.converter)
      .get();

    return snapshot.docs.map(doc => doc.data());
  };

  static create = async (workspace, user, data) => {
    const customer = new Customer({
      ...data,
      id: Customer.createId(workspace),
      updatedAt: Timestamp.now(),
      createdAt: Timestamp.now(),
      updatedBy: user,
      createdBy: user
    });

    const result = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Customer.collectionName)
      .doc(customer.id)
      .withConverter(Customer.converter)
      .set(customer, { merge: true });

    return customer;
  };

  static patch = async (workspace, user, id, updates) => {
    const patchData = {
      updatedAt: Timestamp.now(),
      updatedBy: user,
      ...updates
    };

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Customer.collectionName)
      .doc(id)
      .set(patchData, { merge: true });

    return { patchData };
  };

  static update = async (workspace, user, customer, updates) => {
    const updatedCustomer = customer.clone();

    updatedCustomer.setData({
      ...customer,
      ...updates,
      updatedAt: Timestamp.now(),
      updatedBy: user
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Customer.collectionName)
      .doc(updatedCustomer.id)
      .withConverter(Customer.converter)
      .set(updatedCustomer, { merge: true });

    return updatedCustomer;
  };
}
