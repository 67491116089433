export const BUDGET_TYPE_ENUM = {
  TIME_BUDGET: "TIME_BUDGET",
  MONEY_BUDGET: "MONEY_BUDGET"
};

export class TimeBudget {
  type = BUDGET_TYPE_ENUM.TIME_BUDGET;

  constructor({ hours, recurring }) {}
}

export class MoneyBudget {
  type = BUDGET_TYPE_ENUM.MONEY_BUDGET;

  constructor({ amount, recurring }) {}
}

export const BUDGET_TYPES = [
  {
    name: "Fixed price",
    type: BUDGET_TYPE_ENUM.MONEY_BUDGET,
    cls: MoneyBudget
  },
  {
    name: "Fixed hours",
    type: BUDGET_TYPE_ENUM.TIME_BUDGET,
    cls: TimeBudget
  },
  {
    name: "None",
    type: "None"
  }
];
