import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import XstatsUserProjectHoursByWeek from "../models/XstatsUserProjectHoursByWeek";

export const getKey = (
  workspace: string,
  year: number,
  month: number | undefined,
  week: number | undefined
) => [
  workspace,
  year,
  month,
  week,
  XstatsUserProjectHoursByWeek.collectionName
];

const useUserStatsByWeek = ({
  year,
  month,
  week
}: {
  month?: number;
  year: number;
  week?: number;
}) => {
  const workspace = useWorkspace();
  const get = () => {
    if (month && !week)
      return XstatsUserProjectHoursByWeek.listMonth(workspace, month, year);
    if (week && !month)
      return XstatsUserProjectHoursByWeek.listWeek(workspace, week, year);
  };

  const query = useQuery(getKey(workspace, year, month, week), get, {
    enabled: !!year && (!!month || !!week),
    staleTime: 1000 * 20 //20 seconds stale time. May need to adjust
  });
  return query;
};

export default useUserStatsByWeek;
