import React, { forwardRef, ForwardRefExoticComponent } from "react";
import MUITable from "@material-ui/core/Table";
import {
  TableBody as MuiTableBody,
  TableBodyProps,
  TableCell as MuiTableCell,
  TableCellProps,
  TableContainer as MuiTableContainer,
  TableContainerProps,
  TableHeadProps,
  TableRowProps,
  TableSortLabelProps
} from "@material-ui/core";
import { StyledTableHead, StyledTableRow } from "./table/Table.styles";
import { TableProps } from "@material-ui/core/Table/Table";

export const Table: ForwardRefExoticComponent<TableProps> = forwardRef(
  (props, ref) => <MUITable {...props} ref={ref} />
);

export const TableContainer: ForwardRefExoticComponent<TableContainerProps> = forwardRef(
  (props, ref) => <MuiTableContainer {...props} ref={ref} />
);

export const TableHead: ForwardRefExoticComponent<TableHeadProps> = forwardRef(
  (props, ref) => <StyledTableHead {...props} ref={ref} />
);

export const TableBody: ForwardRefExoticComponent<TableBodyProps> = forwardRef(
  (props, ref) => <MuiTableBody {...props} ref={ref} />
);

export const TableRow: ForwardRefExoticComponent<TableRowProps> = forwardRef(
  (props, ref) => <StyledTableRow {...props} ref={ref} />
);

export const TableCell: ForwardRefExoticComponent<TableCellProps> = forwardRef(
  (props, ref) => <MuiTableCell {...props} ref={ref} />
);

export const TableSortLabel: ForwardRefExoticComponent<TableSortLabelProps> = forwardRef(
  (props, ref) => <TableSortLabel {...props} ref={ref} />
);

export default Table;
