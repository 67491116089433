const initialState = {
  entry: null,
  list: [],
  lastUpdated: Date.now(),
  loading: false,
  pagination: {},
  error: null,
};

export default (constants) => (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET:
      return {
        ...state,
        list: [],
        loading: true,
      };
    case constants.GET_ENTRY:
      return {
        ...state,
        list: [],
        loading: true,
      };
    case constants.LISTEN_ALL_SUCCESS:
    case constants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.list,
      };
    case constants.GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case constants.LISTEN_SUCCESS:
    case constants.GET_ENTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        entry: payload.entry,
      };
    case constants.GET_ENTRY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case constants.CREATE:
      return {
        ...state,
        loading: true,
      };
    case constants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case constants.CREATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case constants.UPDATE:
      return {
        ...state,
        loading: true,
      };
    case constants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case constants.UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case constants.DELETE:
      return {
        ...state,
        loading: true,
      };
    case constants.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case constants.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case constants.EXISTS:
      return {
        ...state,
        loading: true,
      };
    case constants.EXISTS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case constants.EXISTS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
