import TimetUser from "../models/TimetUser";
import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";

export const getKey = (workspace: string, id?: string | null) => [
  workspace,
  id,
  TimetUser.collectionName
];
const useTimetUser = ({ id }: { id?: string | null } = { id: null }) => {
  const workspace = useWorkspace();
  const get = () => TimetUser.get(workspace, id);
  const key = getKey(workspace, id);
  const query = useQuery(key, get, {
    enabled: !!id
  });
  return query;
};

export default useTimetUser;
