import { QueryBuilder, Timestamp } from ".";
import { db } from "@superprofit/timet-react-client/src/firebase";

const booleanOrNull = t => typeof t === "boolean" ? t : null;

export default class UserAccess {
  static converter = {
    toFirestore(user) {
      return user.data();
    },
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      return new UserAccess({ ...data, id: snapshot.id });
    },
  };

  static collectionName = "userAccess";

  static createId(db, workspace) {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserAccess.collectionName)
      .doc().id;
  }

  constructor({ id, isActive, isAdmin, isPendingInvite, createdBy, createdAt, updatedBy, updatedAt }) {
    this.id = id;
    this.isActive = booleanOrNull(isActive);
    this.isPendingInvite = booleanOrNull(isPendingInvite);
    this.isAdmin = booleanOrNull(isAdmin);
    this.createdBy = createdBy || null;
    this.createdAt = createdAt || null;
    this.updatedBy = updatedBy || null;
    this.updatedAt = updatedAt || null;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      id: this.id,
      isActive: booleanOrNull(this.isActive),
      isPendingInvite: booleanOrNull(this.isPendingInvite),
      isAdmin: booleanOrNull(this.isAdmin),
      createdBy: this.createdBy || null,
      createdAt: this.createdAt || null,
      updatedBy: this.updatedBy || null,
      updatedAt: this.updatedAt || null,
    };
  }

  static get = async (workspace, user) => {
    const doc = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserAccess.collectionName)
      .doc(user)
      .get();
    return { ...doc.data(), id: doc.id };
  };

  static getAll = (workspace, usersIds) => {
    return Promise.all(usersIds.map((user) => UserAccess.get(workspace, user)));
  };

  static listenAll = (db, workspace, callback) => {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserAccess.collectionName)
      .withConverter(UserAccess.converter)
      .onSnapshot((qs) => {
        const data = [];
        qs.forEach((doc) => {
          data.push(doc.data());
        });
        callback(data);
      });
  };

  static list = async (db, workspace, query) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(UserAccess.collectionName),
      query
    )
      .withConverter(UserAccess.converter)
      .get();

    return snapshot.docs.map((doc) => doc.data());
  };

  static create = async (db, workspace, user, data) => {

    const newUserAccess = new UserAccess({
      ...data,
      id: data.email,
      updatedAt: Timestamp.now(),
      createdAt: Timestamp.now(),
      updatedBy: user,
      createdBy: user,
    });


    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserAccess.collectionName)
      .doc(newUserAccess.id)
      .withConverter(UserAccess.converter)
      .set(newUserAccess, { merge: true });

    return newUserAccess;
  };


  static update = async (db, workspace, byUser, original, updates) => {
    const updatedUserAccess = original.clone();



    updatedUserAccess.setData({
      ...updates,
      updatedAt: Timestamp.now(),
      updatedBy: byUser,
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserAccess.collectionName)
      .doc(updatedUserAccess.id)
      .withConverter(UserAccess.converter)
      .set(updatedUserAccess, { merge: true });

    return updatedUserAccess;
  };
}
