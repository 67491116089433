import {
  SET_SELECTION,
  SET_ORDER_BY, SET_ORDER_DIR, SET_ROWS_PER_PAGE, SET_PAGE, SET_LOADING, SET_USER_GROUPS, WATCH_GET
} from "./constants";
import { action } from "typesafe-actions";
import UserGroup, {IUserGroup} from "../../../../../models/UserGroup";
import {SortDirection} from "./types";

export const setSelection = (list: Array<UserGroup>) => action(SET_SELECTION, { list });
export const setUserGroups = (list: Array<UserGroup>) => action(SET_USER_GROUPS, { list });
export const setLoading = (loading: boolean) => action(SET_LOADING, { loading });
export const setOrderBy = (property: keyof IUserGroup) => action(SET_ORDER_BY, { property})
export const setOrderDir = (direction: SortDirection) => action(SET_ORDER_DIR, { direction })
export const setRowsPerPage = (rows: number) => action(SET_ROWS_PER_PAGE, { rows })
export const setPage = (page: number) => action(SET_PAGE, { page })
export const watchGetUserGroups = () => action(WATCH_GET)



