import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { GET, GET_SUCCESS } from "../../../api/report/constants";
import { setBasis, setLoading } from "./actions";

export const getData = ({
  api: {
    report: { stats, users, projects, customers }
  }
}) => ({
  stats,
  users,
  projects,
  customers
});

export const handleUpdate = function*(args) {
  try {
    const { stats, users, projects, customers } = yield select(getData);
    yield put(setBasis(stats, customers, projects, users));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const handleUpdateSaga = function*() {
  yield takeLatest([GET_SUCCESS], handleUpdate);
};

export const handleGetSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export default function*() {
  yield all([handleUpdateSaga(), handleGetSaga()]);
}
