import { combineReducers } from "redux";

import deleteUsersDialog from "./deleteUsersDialog/reducer";
import accessControlDialog from "./accessControlDialog/reducer";
import assignProjectsDialog from "./assignProjectsDialog/reducer";
import table from "./table/reducer";
import selection from "./selection/reducer";
import snackbar from "./snackbar/reducer";
import createDialog from "./createDialog/reducer";
import editDialog from "./editDialog/reducer";

export default combineReducers({
  accessControlDialog,
  assignProjectsDialog,
  selection,
  table,
  deleteUsersDialog,
  snackbar,
  createDialog,
  editDialog
});
