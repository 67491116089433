import React, { Fragment } from "react";
import {
  NumberFormatTextField,
  Select
} from "@superprofit/core-react-components/atoms";
import { useDispatch, useSelector } from "react-redux";
import { updateBudgetDialogData } from "../../../../redux/modules/ui/projectOverview/budgetDialog/actions";
import { watchSaveProject } from "../../../../redux/modules/api/projects/actions";
import MenuItem from "@superprofit/core-react-components/atoms/MenuItem";
import FormControl from "@superprofit/core-react-components/atoms/FormControl";
import InputLabel from "@superprofit/core-react-components/atoms/InputLabel";
import FormHelperText from "@superprofit/core-react-components/atoms/FormHelperText";
import { CardContent } from "@superprofit/core-react-components/atoms";
import { BUDGET_TYPE_ENUM, BUDGET_TYPES } from "../../../../models/BudgetTypes";
import { BudgetCard } from "./Budget.styles";
import Divider from "@material-ui/core/Divider";
import Button from "@superprofit/core-react-components/atoms/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@superprofit/core-react-components/atoms/Grid";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import { useTranslation } from "react-i18next";

export default ({ ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isSaving = useSelector(
    state => state.ui.projectOverview.budgetDialog.saving
  );
  const updates = useSelector(
    state => state.ui.projectOverview.budgetDialog.updates
  );

  const {
    loading,
    data: { project }
  } = useSelector(state => state.ui.projectOverview.basis);

  const isLoading = !project || loading;

  const budget = { ...project?.budget, ...updates };

  const handleOnSave = () => {
    dispatch(
      watchSaveProject(project, { budget: { ...project.budget, ...updates } })
    );
  };

  const onChange = (e, key) => {
    dispatch(updateBudgetDialogData({ [key]: e.target.value }));
  };

  const handleOnChangeBudgetType = e => {
    dispatch(updateBudgetDialogData({ type: e.target.value }));
  };

  const valueHelper = () => {
    if (budget && budget.type === BUDGET_TYPE_ENUM.TIME_BUDGET) {
      return t("pages.projects.timeBudgetDesc");
    }
    if (budget && budget.type === BUDGET_TYPE_ENUM.MONEY_BUDGET) {
      return t("pages.projects.moneyBudgetDesc");
    }
    return "";
  };

  return (
    <BudgetCard>
      <CardHeader title={t("common.budget")} />
      <Divider component="div" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <FormControl
              component="fieldset"
              margin="normal"
              style={{ marginBottom: 20 }}
              fullWidth
            >
              <InputLabel
                variant="standard"
                shrink={false}
                id="project-budget-type-label"
              >
                {t("pages.projects.budgetType")}
              </InputLabel>

              {isLoading ? (
                <Skeleton height={40} width={200} component="div" />
              ) : (
                <Fragment>
                  <Select
                    fullWidth
                    variant="filled"
                    labelId="project-budget-type-label"
                    id="project-budget-type"
                    value={budget.type || "None"}
                    defaultValue={(budget && budget.type) || "None"}
                    name="budget-type"
                    onChange={handleOnChangeBudgetType}
                  >
                    {BUDGET_TYPES.map(budgetType => (
                      <MenuItem key={budgetType.type} value={budgetType.type}>
                        {budgetType.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {t("pages.projects.budgetTypeHelperText")}
                  </FormHelperText>
                </Fragment>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormControl
              component="fieldset"
              margin="normal"
              style={{ marginBottom: 20 }}
              fullWidth
            >
              <InputLabel
                variant="standard"
                shrink={false}
                id="project-budget-value-label"
              >
                {t("pages.projects.budgetValue")}
              </InputLabel>
              {isLoading ? (
                <Skeleton height={40} width={200} component="div" />
              ) : (
                <Fragment>
                  <NumberFormatTextField
                    fullWidth
                    labelId="project-budget-value-label"
                    disabled={
                      isSaving || !budget.type || budget.type === "None"
                    }
                    variant="filled"
                    defaultValue={budget.value}
                    placeholder="Budget"
                    inputProps={{
                      "data-prop": "value",
                      type: "number",
                      prefix: "",
                      suffix: ""
                    }}
                    onChange={e => onChange(e, "value")}
                  />
                  <FormHelperText>{valueHelper()}</FormHelperText>
                </Fragment>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Divider style={{ marginBottom: 20 }} />

        {isLoading ? (
          <Skeleton height={40} width={100} component="div" />
        ) : (
          <Button
            onClick={handleOnSave}
            variant="contained"
            color="primary"
            disabled={!Object.keys(updates).length}
          >
            {updates.type && updates.type === "None"
              ? `${t("common.save")}  `
              : t("common.save")}
          </Button>
        )}
      </CardContent>
    </BudgetCard>
  );
};
