import {
  WATCH_AUTHENTICATE,
  AUTHENTICATE,
  AUTHENTICATE_FAILURE,
  AUTHENTICATE_SUCCESS,
  CHECK_HAS_SESSION,
  CHECK_HAS_SESSION_SUCCESS,
  CHECK_HAS_SESSION_FAILURE,
  WATCH_CHECK_HAS_SESSION
} from "./constants";

export const watchAuthenticate = (username, pw) => ({
  type: WATCH_AUTHENTICATE,
  payload: { username, pw }
});

export const authenticate = () => ({
  type: AUTHENTICATE,
  payload: {}
});

export const authenticateSuccess = () => ({
  type: AUTHENTICATE_SUCCESS,
  payload: {}
});

export const authenticateFailure = error => ({
  type: AUTHENTICATE_FAILURE,
  payload: { error }
});

export const watchCheckHasSession = () => ({
  type: WATCH_CHECK_HAS_SESSION,
  payload: {}
});

export const checkHasSession = () => ({
  type: CHECK_HAS_SESSION,
  payload: {}
});

export const checkHasSessionSuccess = () => ({
  type: CHECK_HAS_SESSION_SUCCESS,
  payload: {}
});

export const checkHasSessionFailure = error => ({
  type: CHECK_HAS_SESSION_FAILURE,
  payload: { error }
});
