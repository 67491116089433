import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select
} from "@superprofit/core-react-components/atoms";
import { supportedCountries } from "../../../utils/Countries";
import MenuItem from "@superprofit/core-react-components/atoms/MenuItem";
import React, { useState } from "react";

export default ({ name, defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = e => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onChange) onChange(name, newValue);
  };

  return (
    <FormControl component="fieldset" margin="normal">
      <InputLabel variant="standard" shrink={false} id="holidays-label">
        Holiday calendar
      </InputLabel>

      <Select
        variant="filled"
        labelId="holidays-label"
        id="holidays-input"
        value={value}
        onChange={handleChange}
      >
        {supportedCountries.map(c => (
          <MenuItem key={c.abbr} value={c.abbr}>
            {c.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        The appropriate holiday calendar for your establishment (used for better
        income prognosis calculation).
      </FormHelperText>
    </FormControl>
  );
};
