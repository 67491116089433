import {
  HIDE_CUSTOMER_DIALOG,
  SHOW_CUSTOMER_DIALOG,
  UPDATE_CUSTOMER_DIALOG_DATA
} from "./constants";

import { SAVE_SUCCESS, SAVE } from "../../../api/customers/constants";

const initialState = {
  open: false,
  updates: {},
  customer: {},
  saving: false,
  hasUnsaved: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CUSTOMER_DIALOG:
      return {
        ...initialState,
        open: true,
        customer: action.payload.customer
      };

    case SAVE:
      return {
        ...state,
        saving: true
      };

    case SAVE_SUCCESS: // Hide dialog when saved, use sagas?
    case HIDE_CUSTOMER_DIALOG:
      return {
        ...state,
        open: false,
        hasUnsaved: false
      };

    case UPDATE_CUSTOMER_DIALOG_DATA:
      return {
        ...state,
        updates: {
          ...state.updates,
          ...action.payload.updates
        },
        hasUnsaved: true
      };

    default:
      return state;
  }
};
