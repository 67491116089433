export const HIDE_GOAL_DIALOG =
  "ui/companySettings/targetDialog/HIDE_TARGET_DIALOG";

export const SHOW_GOAL_DIALOG =
  "ui/companySettings/targetDialog/SHOW_TARGET_DIALOG";

export const UPDATE_GOAL_DIALOG_DATA =
  "ui/dashboard/settingsDialog/UPDATE_TARGET_DIALOG_DATA";

export const SET_SAVE_STATE = "ui/dashboard/settingsDialog/SET_SAVE_STATE";
