import styled from "styled-components";
import DialogContent from "../../atoms/DialogContent";
import Typography from "../../atoms/Typography";
import Drawer from "../../atoms/Drawer";

export const StyledFormDialog = styled(Drawer)`
  .MuiDrawer-paper {
    ${props => props.theme.breakpoints.down("sm")} {
      width: 90%;
      max-width: 550px;
    }

    ${props => props.theme.breakpoints.up("sm")} {
      max-width: 550px;
      width: 550px;
    }
  }
`;

export const FormDialogTitle = styled(Typography)`
  flex-grow: 1;
`;

export const FormDialogContent = styled(DialogContent)`
  padding: ${props => props.theme.spacing(3)}px;

  > * {
    display: block;
    margin-bottom: ${props => props.theme.spacing(4)}px;
    &:last-child {
      margin-bottom: initial;
    }
  }
`;

export const FormDialogGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-right: ${props => props.theme.spacing(2)}px;

    &:last-child {
      margin-right: 0;
      flex: 1;
    }
  }
`;
