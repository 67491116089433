import { SET_ACTIVE_GOAL } from "./constants";

const initialState = {
  goal: null,
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_GOAL:
      return {
        ...state,
        goal: payload.goal
      };
    default:
      return state;
  }
};
