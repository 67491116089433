import React, { forwardRef } from "react";
import Table from "@material-ui/core/Table";
import {
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer
} from "@material-ui/core";
import { StyledTableHead, StyledTableRow } from "./table/Table.styles";

export default forwardRef((props, ref) => <Table {...props} ref={ref} />);

export const TableContainer = forwardRef((props, ref) => (
  <MuiTableContainer {...props} ref={ref} />
));

export const TableHead = forwardRef((props, ref) => (
  <StyledTableHead {...props} ref={ref} />
));

export const TableBody = forwardRef((props, ref) => (
  <MuiTableBody {...props} ref={ref} />
));

export const TableRow = forwardRef((props, ref) => (
  <StyledTableRow {...props} ref={ref} />
));

export const TableCell = forwardRef((props, ref) => (
  <MuiTableCell {...props} ref={ref} />
));
