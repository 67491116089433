import React, { forwardRef } from "react";
import { Checkbox } from "../atoms";
import { Container } from "./checkableAvatar/CheckableAvatar.styles";

export default forwardRef(({ children, checked, ...rest }, ref) => (
  <Container checked={checked}>
    <Checkbox checked={checked} {...rest} />
    {children}
  </Container>
));
