import { motionValue } from "framer-motion";
import { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./index";
import i18next from "i18next";
const translate = i18next.t;

export const getCurrentWorkspace = (pathname: string) => {
  const path = pathname || window.location.pathname;
  const [empty, app, workspaceId] = path.split("/");
  if (app === "app" && workspaceId) {
    return workspaceId;
  }
  return "";
  // We dont support subdomains under ssl right now.
  //   const { subdomain } = parseDomain(window.location.href, {
  //     customTlds: /localhost|\.local/
  //   });
  //   return subdomain;
};

export function useUpdateRef() {
  const [node, setRect] = useState(null);
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node);
    }
  }, []);
  return [node, ref];
}

export const promiseDelay = (ms = 0) =>
  new Promise<void>(res => {
    setTimeout(() => res(), ms);
  });

export const isEmptyObject = (o: any) =>
  Object.keys(o).length === 0 && o.constructor === Object;

export function useViewportScroll(element: any) {
  const viewportMotionValues = {
    scrollX: motionValue(0),
    scrollY: motionValue(0),
    scrollXProgress: motionValue(0),
    scrollYProgress: motionValue(0)
  };
  const setProgress = (offset: any, maxOffset: any, value: any) => {
    value.set(!maxOffset || !offset ? 0 : offset / maxOffset);
  };
  const hasEventListenerRef = useRef(false);
  useEffect(() => {
    if (!element) return;
    const updateScrollValues = () => {
      window.requestAnimationFrame(() => {
        const xOffset = element.scrollLeft;
        const yOffset = element.scrollTop;
        // Set absolute positions
        viewportMotionValues.scrollX.set(xOffset);
        viewportMotionValues.scrollY.set(yOffset);

        // Set 0-1 progress
        setProgress(
          xOffset,
          element.clientWidth - element.clientHeight,
          viewportMotionValues.scrollXProgress
        );
        setProgress(
          yOffset,
          element.scrollHeight - element.clientHeight,
          viewportMotionValues.scrollYProgress
        );
      });
    };
    if (!hasEventListenerRef.current) {
      hasEventListenerRef.current = true;
      if (typeof window === "undefined") return;
      updateScrollValues();
      window.addEventListener("resize", updateScrollValues);
      window.addEventListener("scroll", updateScrollValues, true);
    }
    return () => {
      hasEventListenerRef.current = false;
      window.removeEventListener("resize", updateScrollValues);
      window.removeEventListener("scroll", updateScrollValues, true);
    };
  }, [element, viewportMotionValues]);

  return viewportMotionValues;
}

export const useAllowCreateUser = () => {
  const applicationAccess = useSelector(
    (state: RootState) => state.api.applicationAccess.entry
  );
  const xstatsActiveAndPendingUsers = useSelector(
    (state: RootState) =>
      (state.api.xstats.usersInWorkspace.entry?.numberOfActiveUsers || 0) +
      (state.api.xstats.usersInWorkspace.entry?.numberOfPendingInvites || 0)
  );

  const maxUsers = applicationAccess?.maxUsers || 3;
  return maxUsers > xstatsActiveAndPendingUsers;
};

export const useFeatures = () => {
  const { settings } = useSelector(
    (state: RootState) => state.api.companySettings
  );
  const { settings: userSettings } = useSelector(
    (state: RootState) => state.api.userSettings
  );
  const timetFeatures = {};
  const userFeatures = userSettings?.features?.value || {};
  const companyFeatures = settings?.features?.value || {};
  return { ...timetFeatures, ...companyFeatures, ...userFeatures };
};

export const useUserAcccess = () => {
  const access = useSelector((state: RootState) => {
    const activeWorkspaceId = state.api.auth.activeWorkspaceId;
    if (state.api.auth.idTokenResult?.claims?.workspaces) {
      return (
        state.api.auth.idTokenResult?.claims?.workspaces[activeWorkspaceId] ||
        {}
      );
    }
    return {};
  });
  return access;
};

export const dayNames = () => [
  //@ts-ignore
  translate("date.dayNames.sunday"),
  //@ts-ignore
  translate("date.dayNames.monday"),
  //@ts-ignore
  translate("date.dayNames.tuesday"),
  //@ts-ignore
  translate("date.dayNames.wednesday"),
  //@ts-ignore
  translate("date.dayNames.thursday"),
  //@ts-ignore
  translate("date.dayNames.friday"),
  //@ts-ignore
  translate("date.dayNames.saturday")
];

export const monthNames = () => [
  translate("date.monthNames.january"),
  translate("date.monthNames.february"),
  translate("date.monthNames.march"),
  translate("date.monthNames.april"),
  translate("date.monthNames.may"),
  translate("date.monthNames.june"),
  translate("date.monthNames.july"),
  translate("date.monthNames.august"),
  translate("date.monthNames.september"),
  translate("date.monthNames.october"),
  translate("date.monthNames.november"),
  translate("date.monthNames.december")
];

export const isStaging = () => {
  return localStorage.getItem("timet-is-staging") === "true";
};
