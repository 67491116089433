import { AuthActions, AuthState } from "./types";
import * as constants from "./constants";

import { createReducer } from "typesafe-actions";

function getWorkspaceIdFromPath(): string {
  const path = window.location.pathname;
  const [empty, app, workspaceId] = path.split("/");
  if (app === "app" && workspaceId) {
    return workspaceId;
  }
  return "";
}
const initialState: AuthState = {
  activeWorkspaceId: getWorkspaceIdFromPath(),
  user: undefined,
  idTokenResult: undefined,
  workspace: undefined,
  workspaceJoining: undefined,
  workspaceNoAccess: false
};

const reducer = createReducer<AuthState, AuthActions>(initialState)
  .handleType("@@router/LOCATION_CHANGE", state => {
    return {
      ...state,
      activeWorkspaceId: getWorkspaceIdFromPath()
    };
  })
  .handleType(constants.SIGNED_IN, (state, action) => {
    return {
      ...state,
      user: action.payload.user
    };
  })
  .handleType(constants.SIGNED_OUT, (state, action) => {
    return initialState;
  })
  .handleType(constants.SET_ID_TOKEN, (state, action) => {
    return {
      ...state,
      idTokenResult: action.payload.idTokenResult
    };
  })
  .handleType(constants.SET_ACTIVE_WORKSPACE, (state, action) => {
    return {
      ...state,
      activeWorkspaceId: action.payload.activeWorkspaceId
    };
  })
  .handleType(constants.SET_WORKSPACE, (state, action) => {
    return {
      ...state,
      workspace: action.payload.workspace
    };
  })
  .handleType(constants.WORKSPACE_NO_ACCESS, (state, action) => {
    return {
      ...state,
      workspace: undefined,
      workspaceNoAccess: true
    };
  })
  .handleType(constants.WORKSPACE_JOINED, (state, action) => {
    return {
      ...state,
      workspaceJoining: undefined
    };
  })
  .handleType(constants.WORKSPACE_JOINING, (state, action) => {
    return {
      ...state,
      workspaceJoining: action.payload.workspace
    };
  });

export default reducer;
