import React from 'react';
import Grid from "@material-ui/core/Grid";
import Plan from "./Plan";


export default () => (
  <Grid container spacing={3} style={{ marginTop: 20 }} component="div">
    <Grid item xs={12} sm={4} component="div">
      <Plan plan="small" />
    </Grid>
    <Grid item xs={12} sm={4} component="div">
      <Plan plan="medium" active/>
    </Grid>
    <Grid item xs={12} sm={4} component="div">
      <Plan plan="large"/>
    </Grid>

  </Grid>
);
