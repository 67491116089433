import React, { forwardRef, ForwardRefExoticComponent } from "react";
import { StyledFormLabel } from "./formLabel/FormLabel.styles";
import { FormLabelProps } from "@material-ui/core";

interface IProps extends FormLabelProps {
  shrink?: boolean;
  component?: string; //idk if used
}

const Component: ForwardRefExoticComponent<IProps> = forwardRef(
  (props, ref) => (
    <StyledFormLabel shrink={false} variant="default" {...props} ref={ref} />
  )
);

export default Component;
