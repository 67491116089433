import { SET_LOADING, SET_BASIS } from "./constants";

const initialState = {
  data: {
    customers: [],
    projects: [],
    users: [],
    stats: {
      currentYear: [],
      monthBefore: []
    },
    year: null,
    month: null
  },
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload.isLoading
      };
    case SET_BASIS:
      return {
        ...state,
        data: payload.basis
      };
    default:
      return state;
  }
};
