import { takeLatest, put, call, all, select } from "redux-saga/effects";
import { WATCH_GET, WATCH_SAVE } from "./constants";
import {
  get,
  getFailure,
  getSuccess,
  save,
  saveSuccess,
  saveFailure
} from "./actions";
import CompanySetting from "../../../../models/CompanySetting";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;

export const getUserEmail = ({
  api: {
    auth: { user }
  }
}) => user.email;

export const getCompanySettings = function* getCompanySettings(args) {
  try {
    yield put(get());
    const workspace = yield select(getActiveWorkspace);
    const result = yield call(CompanySetting.map, workspace);
    yield put(getSuccess(result));
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const saveCompanySettings = function* saveCompanySettings(args) {
  const {
    payload: { settings }
  } = args;
  try {
    yield put(save());
    const workspace = yield select(getActiveWorkspace);
    const user = yield select(getUserEmail);
    const result = yield all(
      settings.map(setting => CompanySetting.save(workspace, user, setting))
    );
    yield put(saveSuccess(result));
  } catch (e) {
    console.warn(e);
    yield put(saveFailure(e));
  }
};

export const getCompanySettingsSaga = function* getCompanySettingsSaga() {
  yield takeLatest(WATCH_GET, getCompanySettings);
};

export const saveCompanySettingsSaga = function* saveCompanySettingsSaga() {
  yield takeLatest(WATCH_SAVE, saveCompanySettings);
};

export default function*() {
  yield all([getCompanySettingsSaga(), saveCompanySettingsSaga()]);
}
