import React, { forwardRef } from "react";
import {
  TableCell,
  TableRow,
} from "@superprofit/core-react-components/atoms/Table";
import {
  Checkbox,
  Tooltip,
  IconButton,
  Label,
  AvatarGroup,
} from "@superprofit/core-react-components/atoms";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tags from "@superprofit/core-react-components/molecules/Tags";
import { useWorkspaceHistory } from "../../../../../contexts/WorkspaceContext";
import { IGroupRow } from "./groupRow/types";
import { Avatar } from "@material-ui/core";
import UserAvatar from "../../../../../components/molecules/UserAvatar";
import {useSelector} from "react-redux";

export default forwardRef(
  ({ group, onMore, onSelect, selected = false, ...rest }: IGroupRow, ref) => {
    const {list } = useSelector((state: { api: { users : { list: any[]}}}) => state.api.users);

    const users = group.users.map((email: string) => {
        const usr = list.find(u => u.email === email);
        return usr ?  usr :  { email }
    })
    return (
      <TableRow key={group.id} {...rest} ref={ref}>
        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <Checkbox
            checked={selected}
            value={group.id}
            onChange={onSelect}
            onClick={(e: Event) => e.stopPropagation()}
          />
        </TableCell>

        <TableCell>{group.name}</TableCell>
        <TableCell>
          <AvatarGroup max={4}>
            {users.map((u: any) => (
              <UserAvatar user={u} />
            ))}
          </AvatarGroup>
        </TableCell>

        <TableCell align="right">
          <Tooltip title="More" aria-label="More">
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e: React.MouseEvent) => {
                onMore(e, group);
                e.stopPropagation();
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }
);
