import {
  FeatureCard,
  FeaturesGrid,
  FeaturesSection,
  LandingSection
} from "./landing/Landing.styles";
import { Typography } from "@material-ui/core";
import { useInView } from "react-intersection-observer";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { motion } from "framer-motion";

export default () => {
  const [ref, inView] = useInView({
    rootMargin: "0px 0px"
  });

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.3,
        delay: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <LandingSection color="white">
      <FeaturesSection>
        <br />
        <br />
        <Typography
          className="features-heading"
          component="h3"
          variant="h1"
          gutterBottom
        >
          Why timet?
        </Typography>

        <Typography className="features-sub" component="p" variant="p">
          {`We worry about timesheets so you don't have to`}
          <br />
          {`Our goal is to eliminate needless complexity and`}
          <br />
          {`let your employees focus on their work`}
          <br />
        </Typography>

        <br />
        <br />

        <FeaturesGrid
          variants={container}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          ref={ref}
          className="features-grid"
          as={motion.div}
          container
          spacing={8}
        >
          <FeaturesGrid
            key={1}
            item
            component="div"
            xs={6}
            as={motion.div}
            variants={item}
          >
            <FeatureCard>
              {`
                Easy-to-use trackers assists your employees
                keeping track of time spent on different 
                projects.
                `}
            </FeatureCard>
          </FeaturesGrid>
          <FeaturesGrid
            key={2}
            item
            component="div"
            xs={6}
            as={motion.div}
            variants={item}
          >
            <FeatureCard>
              {`
                Easy-to-use trackers assists your employees
                keeping track of time spent on different 
                projects.
                `}
            </FeatureCard>
          </FeaturesGrid>
          <FeaturesGrid
            key={3}
            item
            component="div"
            xs={6}
            as={motion.div}
            variants={item}
          >
            <FeatureCard>
              {`
                Easy-to-use trackers assists your employees
                keeping track of time spent on different 
                projects.
                `}
            </FeatureCard>
          </FeaturesGrid>
          <FeaturesGrid
            key={4}
            item
            component="div"
            xs={6}
            as={motion.div}
            variants={item}
          >
            <FeatureCard>
              {`
                Easy-to-use trackers assists your employees
                keeping track of time spent on different 
                projects.
                `}
            </FeatureCard>
          </FeaturesGrid>
        </FeaturesGrid>
      </FeaturesSection>
    </LandingSection>
  );
};
