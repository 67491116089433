import { SET_SELECTED_INVOICES } from "./constants";

const initialState = {
  selected: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_INVOICES:
      return {
        ...state,
        selected: [...payload.invoices]
      };

    default:
      return state;
  }
};
