import { takeLatest, call, put, select, all } from "redux-saga/effects";
import { WATCH_GET } from "./constants";
import { get, getFailure, getSuccess } from "./actions";
import XstatsProjectHoursByWeek from "../../../../../../models/XstatsProjectHoursByWeek";
import { Condition, Query } from "@superprofit/core-firestore-models";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;

export const getStats = function*(args = {}) {
  const { payload } = args;
  const { query: qs } = payload;

  try {
    const workspace = yield select(getActiveWorkspace);
    const query = new Query();
    if (qs) {
      query.addCondition(new Condition("year", "==", qs.year));
      query.addCondition(new Condition("month", "==", qs.month));
    }

    yield put(get());
    const list = yield call(
      XstatsProjectHoursByWeek.list,
      workspace,
      qs && query
    );
    yield put(getSuccess(list));
  } catch (e) {
    yield put(getFailure());
  }
};

export const getSaga = function*() {
  yield takeLatest(WATCH_GET, getStats);
};

export default function*() {
  yield all([getSaga()]);
}
