import styled, { css } from "styled-components";

const colors = {
  default: css`
    &:before {
      background-color: ${props =>
        props.color || props.theme.palette.primary.main};
    }
  `,
  primary: css`
    &:before {
      background-color: ${props => props.theme.palette.primary.main};
    }
  `,
  secondary: css`
    &:before {
      background-color: ${props => props.theme.palette.secondary.main};
    }
  `,
  tertiary: css`
    &:before {
      background-color: ${props => props.theme.palette.tertiary.main};
    }
  `
};

export const ChartLegend = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem;
  text-transform: uppercase;
  font-weight: ${props => props.theme.typography.h2.fontWeight};
  font-size: ${props => props.theme.typography.h2.fontSize};
  color: ${props => props.theme.palette.text.hint};
  ${props => (colors[props.color] && colors[props.color]) || colors["default"]};

  &:before {
    content: "";
    display: block;
    width: 1rem;
    height: 3px;
    border-radius: 1.5px;
    margin-right: 0.25rem;
  }
`;
