import React, { useEffect, useState } from "react";
import Page from "@superprofit/core-react-components/atoms/Page";
import Grid from "@material-ui/core/Grid";
import CustomerDialog from "./customers/CustomerDialog";
import { useDispatch, useSelector } from "react-redux";
import { watchGetCustomerTable } from "../../redux/modules/ui/customers/table/actions";
import SearchField from "@superprofit/core-react-components/atoms/SearchField";
import { Button } from "@superprofit/core-react-components/atoms";
import CustomersCard from "./customers/CustomersCard";
import { showCustomerDialog } from "../../redux/modules/ui/customers/dialog/actions";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";

export default () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");

  useEffect(() => {
    dispatch(watchGetCustomerTable());
  }, [dispatch]);

  const { list: customers, loading } = useSelector(
    state => state.ui.customers.table
  );
  const { t } = useTranslation();

  const selection = useSelector(state => state.ui.customers.selection.selected);

  const filteredCustomers = React.useMemo(() => {
    return customers.filter(
      c =>
        filter === "" || c.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
    );
  }, [customers, filter]);

  const handleOnAdd = () => {
    dispatch(showCustomerDialog({}));
  };

  const handleOnFilter = e => {
    const val = e.target.value;
    setFilter(val || "");
  };

  return (
    <Page context={t("common.management")} title={t("common.customers")}>
      <Grid container spacing={3} component="div">
        <Grid container item xs={6} component="div">
          <SearchField
            placeholder={t("common.search")}
            onChange={handleOnFilter}
          />
        </Grid>

        <Grid container item xs={6} component="div" justify="flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={handleOnAdd}
            startIcon={<AddIcon />}
          >
            {t("common.create")}
          </Button>
        </Grid>

        <Grid item xs={12} component="div">
          <CustomersCard
            customers={filteredCustomers}
            loading={loading}
            selection={selection}
          />
        </Grid>
      </Grid>

      <CustomerDialog />
    </Page>
  );
};
