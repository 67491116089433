import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { GET, GET_SUCCESS } from "../../../api/companyDashboard/constants";
import { setHoursByUser, setLoading } from "./actions";

export const getUsers = ({
  api: {
    users: { list }
  }
}) => list;

export const getStats = ({
  api: {
    companyStats: {
      stats: { raw }
    }
  }
}) => raw;

export const handleGetSuccess = function*(args) {
  try {
    const users = yield select(getUsers);
    const stats = yield select(getStats);

    if (!stats || stats.length === 0 || !users || users.length === 0) return;

    let hoursByUserWeekly = stats.reduce(
      (prev, next) => ({
        ...prev,
        [next.user]: {
          billableWeeks: {
            ...prev[next.user]?.billableWeeks,
            [next.week]:
              (prev[next.user]?.billableWeeks[next.week] || 0) +
              (next.billable ? next.total : 0)
          },
          nonBillableWeeks: {
            ...prev[next.user]?.nonBillableWeeks,
            [next.week]:
              (prev[next.user]?.nonBillableWeeks[next.week] || 0) +
              (!next.billable ? next.total : 0)
          }
        }
      }),
      {}
    );

    const hoursByUserMonthly = stats.reduce(
      (prev, next) => ({
        ...prev,
        [next.user]: {
          billableMonths: {
            ...prev[next.user]?.billableMonths,
            [next.month]:
              (prev[next.user]?.billableMonths[next.month] || 0) +
              (next.billable ? next.total : 0)
          },
          nonBillable: {
            ...prev[next.user]?.nonBillable,
            [next.month]:
              (prev[next.user]?.nonBillable[next.month] || 0) +
              (!next.billable ? next.total : 0)
          }
        }
      }),
      {}
    );

    const hoursByUser = users.map(u => ({
      id: u.id,
      ...u.data(),
      weeklyHours: hoursByUserWeekly[u.id]?.weeks || {},
      billableMonthlyHours: hoursByUserMonthly[u.id]?.billableMonths || {},
      nonBillableMonthlyHours: hoursByUserMonthly[u.id]?.nonBillable || {}
    }));

    yield put(setHoursByUser(hoursByUser));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const handleGetSuccessSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleGetSuccess);
};

export const handleGetSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export default function*() {
  yield all([handleGetSuccessSaga(), handleGetSaga()]);
}
