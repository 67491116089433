import { call, put, takeLatest, delay, all } from "redux-saga/effects";
import { WATCH_EXPORT } from "./constants";
import {
  exportInvoice,
  exportInvoiceFailure,
  exportInvoiceSuccess,
} from "./actions";
import * as TwentyFourSeven from "@superprofit/24-seven-integration-client";
import {Timestamp} from '@superprofit/core-firestore-models';
import {addGlobalAlert} from '../../../../ui/app/actions';

export function* handleExport(args) {
  const { invoice } = args.payload;
  try {
    const body = {
      id: invoice.id,
      twntyFourSevenId: invoice.twntyFourSevenId,
      customer: invoice.customer,
      projects: invoice.projects,
      // dueDate:Timestamp.toMomentUtc(invoice.dueDate).format(),
      issueDate:Timestamp.toMomentUtc(invoice.issueDate).format(),
    };
    yield put(exportInvoice(invoice));
    const result = yield call(TwentyFourSeven.saveInvoice, body);
    if (result?.response?.ok) {
      yield put(exportInvoiceSuccess(result.responseData));
    } else {
      yield put(
        addGlobalAlert({
          severity: "error",
          title: "Invoice export failed",
          message: result.responseData,
        })
      );
      yield put(exportInvoiceFailure(new Error('Invoice export failed')));

    }
  } catch (error) {
    yield put(
      addGlobalAlert({
        severity: "error",
        title: "Invoice export failed",
        message: error.toString(),
      })
    );
    yield put(exportInvoiceFailure(error));
  }
}

const exportSaga = function* authSaga() {
  yield takeLatest(WATCH_EXPORT, handleExport);
};

export default function* () {
  yield all([exportSaga()]);
}
