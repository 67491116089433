export class Condition {
  constructor(fieldPath, opStr, value) {
    this.fieldPath = fieldPath;
    this.opStr = opStr;
    this.value = value;
  }
}

export class Query {
  constructor(props) {
    this._conditions = props?._conditions || [];
  }

  clone() {
    return new Query(this);
  }

  addCondition(condition) {
    this._conditions = [...this._conditions, condition];
  }
}

export class QueryBuilder {
  static build(ref, query = new Query()) {
    return query._conditions.reduce(
      (prev, next) => prev.where(next.fieldPath, next.opStr, next.value),
      ref
    );
  }
}
