import { combineReducers } from "redux";
import weeklyHours from "./weeklyHours/reducer";
import hoursByProject from "./hoursByProject/reducer";
import hoursByUser from "./hoursByUser/reducer";
import settingsDialog from "./settingsDialog/reducer";
import period from "./period/reducer";
import billableMonth from "./billableMonth/reducer";
import nonBillableMonth from "./nonBillableMonth/reducer";
import prognosis from "./prognosis/reducer";
import activeGoal from "./activeGoal/reducer";

export default combineReducers({
  weeklyHours,
  hoursByProject,
  hoursByUser,
  settingsDialog,
  period,
  billableMonth,
  nonBillableMonth,
  prognosis,
  activeGoal
});
