import { SELECT_USER } from "./constants";

const initialState = {
  selected: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECT_USER:
      const exists =
        state.selected.filter((u) => payload.user.id === u.id)?.length >
        0;
      if (exists) {
        return {
          ...state,
          selected: state.selected.filter(
            (u) => payload.user.id !== u.id
          ),
        };
      }
      return {
        ...state,
        selected: state.selected.concat([payload.user]),
      };
    default:
      return state;
  }
};
