import React, { forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Distribution,
  DistributionSection
} from "./distributionBar/DistributionBar.styles";
import Tooltip from "@superprofit/core-react-components/atoms/Tooltip";

type Bucket = {
  color: string;
  value: number;
  tooltip: React.ReactNode;
};
interface IDistributionBar {
  dataset: Bucket[];
  max?: number;
}

const DistributionBar = forwardRef<HTMLDivElement, IDistributionBar>(
  ({ dataset, max, ...rest }, ref) => {
    const total = useMemo(() => {
      if (!max) {
        return dataset.reduce((prev, next) => prev + next.value, 0);
      } else {
        let tmp = dataset.reduce((prev, next) => prev + next.value, 0);
        return Math.max(max, tmp);
      }
    }, [dataset, max]);

    const sortedBuckets = dataset.slice(0);
    sortedBuckets.sort((a, b) => b.value - a.value);

    return (
      <Distribution {...rest} ref={ref}>
        {sortedBuckets.map((b, idx) => (
          <Tooltip arrow key={idx} title={b.tooltip} aria-label={b.tooltip}>
            <DistributionSection
              percentage={(b.value / total) * 100}
              color={b.color}
            />
          </Tooltip>
        ))}
      </Distribution>
    );
  }
);

export default DistributionBar;
