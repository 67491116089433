import { takeLatest, call, put, select, all, take } from "redux-saga/effects";
import { WATCH_UPDATE } from "./constants";
import {
  get,
  getFailure,
  getSuccess,
  update,
  updateFailure,
  updateSuccess
} from "./actions";
import {
  SIGNED_IN,
  SET_WORKSPACE
} from "@superprofit/core-redux-saga-modules/Auth/constants";
import UserProfile from "../../../../models/UserProfile";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;

export const getSignedInEmail = ({
  api: {
    auth: { user }
  }
}) => user?.email;

export const updateUserProfile = function* updateUserProfile(arg) {
  try {
    const {
      payload: { profile, updates }
    } = arg;

    yield put(update(updates));

    const workspace = yield select(getActiveWorkspace);
    const userId = yield select(getSignedInEmail);

    const updatedUser = yield call(
      UserProfile.save,
      workspace,
      userId,
      profile,
      updates
    );

    yield put(updateSuccess(updatedUser));
  } catch (e) {
    console.warn(e);
    yield put(updateFailure(e));
  }
};

export const getUserProfile = function* getUserProfile(arg) {
  try {
    yield put(get());
    const userId = yield select(getSignedInEmail);
    const {
      payload: { workspace }
    } = yield take(SET_WORKSPACE);
    const profile = yield call(UserProfile.get, workspace.id, userId);

    yield put(getSuccess(profile));
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const updateUserProfileSaga = function* updateUserProfileSaga() {
  yield takeLatest(WATCH_UPDATE, updateUserProfile);
};

export const getUserProfileSaga = function* getUserProfileSaga() {
  yield takeLatest(SIGNED_IN, getUserProfile);
};

export default function*() {
  yield all([getUserProfileSaga(), updateUserProfileSaga()]);
}
