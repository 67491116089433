import { all, put, takeLatest } from "@redux-saga/core/effects";
import {
  SAVE_COMMENT,
  SAVE_COMMENT_SUCCESS,
  SAVE_FAILURE
} from "../../../api/leads/constants";
import { saved, saveError, saving } from "./actions";

export const handleLeadCommentSaveError = function*(args) {
  const { lead, comment } = args.payload;
  try {
    yield put(saveError(lead.id, comment));
  } catch (e) {
    console.warn(e);
  }
};

export const handleLeadCommentSaving = function*(args) {
  const { lead, comment } = args.payload;
  try {
    yield put(saving(lead.id, comment));
  } catch (e) {
    console.warn(e);
  }
};

export const handleLeadCommentSaved = function*(args) {
  const { lead, comment } = args.payload;
  try {
    yield put(saved(lead.id, comment));
  } catch (e) {
    console.warn(e);
  }
};

export const handleLeadCommentSavingSaga = function*() {
  yield takeLatest(SAVE_COMMENT, handleLeadCommentSaving);
};

export const handleLeadCommentSavedSaga = function*() {
  yield takeLatest(SAVE_COMMENT_SUCCESS, handleLeadCommentSaved);
};

export const handleLeadCommentSaveErrorSaga = function*() {
  yield takeLatest(SAVE_FAILURE, handleLeadCommentSaveError);
};

export default function*() {
  yield all([
    handleLeadCommentSavingSaga(),
    handleLeadCommentSavedSaga(),
    handleLeadCommentSaveErrorSaga()
  ]);
}
