import * as yup from "yup";
import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect, useMemo, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ConfirmationDialog from "../../../../components/molecules/ConfirmationDialog";
import FormDialog from "../../../../components/molecules/FormDialog";
import Grid from "../../../../components/atoms/Grid";

import useGlobalSnackbar from "../../../../hooks/useGlobalSnackbar";
import { promiseDelay } from "../../../../helpers";
import useFixProjectsDialog from "./fixProjectsDialog/useFixProjectsDialog";
import useUnarchivedProjects from "../../../../hooks/useUnarchivedProjects";
import Project, { IProject } from "../../../../models/Project";
import FormLabel from "../../../../components/atoms/FormLabel";
import FormControl from "../../../../components/atoms/FormControl";
import Typography from "../../../../components/atoms/Typography";
import useProjectUpdateMutation from "../../../../hooks/useProjectUpdateMutation";
import FormControlLabel from "../../../../components/atoms/FormControlLabel";
import Radio from "../../../../components/atoms/Radio";
import { withFormController as withFormControllerRadioGroup } from "../../../../components/atoms/RadioGroup";
import useProjectSalaryRateSettings from "../../../../hooks/useProjectSalarySettings";
import FixProjectsForm, { IFixProjectsForm } from "./fixProjectsDialog/Form";

interface IFormInput {
  [key: string]: { salaryType: IProject["salaryType"] };
}

const RadioGroupFromController = withFormControllerRadioGroup<IFormInput>();

export default ({ ...rest }) => {
  const { t } = useTranslation();
  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);
  const {
    mutateAsync: updateProject,
    isLoading: isMutationLoading
  } = useProjectUpdateMutation();
  const { open, close } = useFixProjectsDialog();
  const { data: projectsData = [] } = useUnarchivedProjects();

  const projects = useMemo(
    () => projectsData?.filter((project: Project) => project.billable),
    [projectsData]
  );
  const handleConfirmClose = () => {
    close();
  };

  const handleCancelClose = () => {
    setShowUnsavedConfirmation(false);
  };

  const handleOnClose = () => {
    if (isMutationLoading) return;
    else {
      handleConfirmClose();
    }
  };

  const [showForm, setShowForm] = useState(open);

  return (
    <Fragment>
      <ConfirmationDialog
        key="unsaved"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showUnsavedConfirmation}
      />

      <FormDialog
        {...rest}
        SlideProps={{
          onExited: () => {
            setShowForm(false);
          },
          onEnter: () => {
            setShowForm(true);
          }
        }}
        SubmitButtonProps={{ type: "submit", form: "test_form" }}
        key="form"
        saveActionTitle={t("common.save")}
        open={open}
        onClose={handleOnClose}
        title={t(
          "pages.companySettings.projectSalaryRate.fixProjectsDialog.title"
        )}
        inProgress={isMutationLoading}
      >
        <Typography variant="h4">
          {t("pages.companySettings.projectSalaryRate.fixProjectsDialog.desc")}
        </Typography>
        {showForm && projects.length > 0 && (
          <FixProjectsForm
            id="test_form"
            updateProject={updateProject}
            projects={projects}
            handleConfirmClose={handleConfirmClose}
          />
        )}
      </FormDialog>
    </Fragment>
  );
};
