import { combineReducers } from "redux";

import dialog from "./dialog/reducer";
import snackbar from "./snackbar/reducer";
import selection from "./selection/reducer";
import table from "./table/reducer";

export default combineReducers({
  dialog,
  snackbar,
  selection,
  table
});
