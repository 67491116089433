import {
  ADD_GLOBAL_ALERT,
  REMOVE_GLOBAL_ALERT,
  TOGGLE_SIDEBAR
} from "./constants";
import { Actions, State } from "./types";
import { createReducer } from "typesafe-actions";

export const initialState: State = {
  sidebar: {
    open: false
  },
  alerts: []
};

const reducer = createReducer<State, Actions>(initialState)
  .handleType(TOGGLE_SIDEBAR, (state, action) => {
    state.sidebar = { ...state.sidebar, ...action.payload };
    return state;
  })
  .handleType(ADD_GLOBAL_ALERT, (state, action) => {
    state.alerts = state.alerts.concat({
      show: true,
      time: new Date().getTime(),
      id: btoa(`${new Date().getTime}${JSON.stringify({ ...action.payload })}`),
      ...action.payload
    });
    return state;
  })
  .handleType(REMOVE_GLOBAL_ALERT, (state, action) => {
    state.alerts = state.alerts.filter(a => a.id !== action.payload.id);
    return state;
  });

export default reducer;
