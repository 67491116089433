import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
  BATCH,
  BATCH_FAILURE,
  BATCH_SUCCESS,
  REMOVE,
  REMOVE_FAILURE,
  REMOVE_SUCCESS,
  WATCH_GET,
  WATCH_SAVE,
  WATCH_REMOVE,
  WATCH_BATCH
} from "./constants";
import UserGroup from "../../../../models/UserGroup";
import { action } from "typesafe-actions";

export const get = () => action(GET);
export const getSuccess = (userGroups: UserGroup[]) =>
  action(GET_SUCCESS, { userGroups });
export const getFailure = (error: string) => action(GET_FAILURE, { error });
export const watchGetUserGroups = (users: string[] | [] = []) => action(WATCH_GET, { users});

export const save = (userGroup: UserGroup) => action(SAVE, { userGroup });
export const saveSuccess = (userGroup: UserGroup) =>
  action(SAVE_SUCCESS, { userGroup });
export const saveFailure = (userGroup: UserGroup, error: string) =>
  action(SAVE_FAILURE, { error, userGroup });
export const watchSaveUserGroup = (userGroup: UserGroup) =>
  action(WATCH_SAVE, { userGroup });

export const batch = (userGroups: UserGroup[]) => action(BATCH, { userGroups });
export const batchSuccess = (userGroups: UserGroup[]) =>
  action(BATCH_SUCCESS, { userGroups });
export const batchFailure = (userGroups: UserGroup[], error: string) =>
  action(BATCH_FAILURE, { error, userGroups });
export const watchBatchUserGroups = (userGroups: UserGroup[]) =>
  action(WATCH_BATCH, { userGroups });

export const remove = (userGroup: UserGroup) => action(REMOVE, { userGroup });
export const removeSuccess = (userGroup: UserGroup) =>
  action(REMOVE_SUCCESS, { userGroup });
export const removeFailure = (userGroup: UserGroup, error: string) =>
  action(REMOVE_FAILURE, { error, userGroup });
export const watchRemoveUserGroup = (userGroup: UserGroup) =>
  action(WATCH_REMOVE, { userGroup });
