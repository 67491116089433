import React, { forwardRef, ForwardRefExoticComponent } from "react";
import { ButtonProps } from "@material-ui/core/Button";
import { StyledButton } from "./button/button.styles";
import CircularProgress from "./CircularProgress";
import { CircularProgressProps } from "@material-ui/core/CircularProgress";

interface Props extends ButtonProps {
  loading?: boolean;
  progressProps?: CircularProgressProps;
}

const Button: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => {
  if (props.loading) {
    const { children, progressProps, ...rest } = props;
    return (
      <StyledButton {...rest} ref={ref}>
        <CircularProgress className="absolute" size={30} {...progressProps} />
        {children}
      </StyledButton>
    );
  }
  return <StyledButton {...props} ref={ref} />;
});

export default Button;
