import { all, delay, put, takeLatest } from "@redux-saga/core/effects";
import { SAVE, SAVE_SUCCESS } from "@superprofit/core-redux-saga-modules/Users/constants";

import { updateSnackbar } from "./actions";

export const handleUserSaving = function*(args) {
  try {
    yield put(updateSnackbar({ visible: true, text: "Saving" }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleUserSaved = function*(args) {
  const { isNew } = args.payload;
  try {
    if (isNew) {
      yield put(
        updateSnackbar({ visible: true, text: "Invitation has been sent" })
      );
    } else {
      yield put(updateSnackbar({ visible: true, text: "User updated" }));
    }
    yield delay(2000);
    yield put(updateSnackbar({ visible: false }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleUserSavingSaga = function*() {
  yield takeLatest(SAVE, handleUserSaving);
};

export const handleUserSavedSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleUserSaved);
};

export default function*() {
  yield all([handleUserSavedSaga(), handleUserSavedSaga()]);
}
