import { SETTING_TYPES } from "./UserSetting";
import {Timestamp} from "@superprofit/core-firestore-models";

interface IUserSettingsGeneric {
  type: SETTING_TYPES.GENERIC_KEY_VALUE;
  id?: string;
  user: string | null;
  value: any
  createdBy: string| null;
  createdAt: typeof Timestamp| null;
  updatedBy: string| null;
  updatedAt: string| null;
}


export default class UserSettingGeneric {
  type: SETTING_TYPES.GENERIC_KEY_VALUE;
  id?: string;
  user: string | null;
  value: any
  createdBy: string | null;
  createdAt: typeof Timestamp| null;
  updatedBy: string| null;
  updatedAt: string| null;

  constructor({ id, value, user, updatedBy, updatedAt, createdAt, createdBy }: Omit<IUserSettingsGeneric, "type">) {
    this.type = SETTING_TYPES.GENERIC_KEY_VALUE;
    this.id = id;
    this.user = user;
    this.value = value;
    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  isValid = () => {
    return !!this.id;
  };

  setData(updates: Partial<IUserSettingsGeneric>) {
    Object.assign(this, updates);
    return this;
  }

  data() {
    return {
      type: this.type,
      value: this.value,
      user: this.user,
      updatedAt: this.updatedAt,
      updatedBy: this.updatedBy,
      createdAt: this.createdAt,
      createdBy: this.createdBy
    };
  }
}
