import * as constants from "./constants";
import { Action, State } from "./types";
import { createReducer } from "typesafe-actions";

const initialState: State = {
  isOpen: false,
  isClosable: true,
  filters: {
    projects: []
  }
};

const reducer = createReducer<State, Action>(initialState)
  .handleType(constants.CLOSE, state => ({
    ...state,
    isOpen: false
  }))
  .handleType(constants.OPEN, state => ({
    ...state,
    isOpen: true
  }))
  .handleType(constants.SET_IS_CLOSEABLE, (state, action) => ({
    ...state,
    isClosable: action.payload
  }))
  .handleType(constants.UPDATE, (state, action) => ({
    ...state,
    filters: { ...state.filters, ...action.payload }
  }));

export default reducer;
