import { SET_HOURS_BY_PROJECT, SET_LOADING } from "./constants";

const initialState = {
  list: [],
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_HOURS_BY_PROJECT:
      return {
        ...state,
        list: payload.hoursByProject
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload.isLoading
      };
    default:
      return state;
  }
};
