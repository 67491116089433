import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../index";
import * as actions from "../../../../redux/modules/ui/users/selection/actions";
import User from "../../../../models/User";

const useSelection = () => {
  const dispatch = useDispatch();

  const selection = useSelector(
    (state: RootState) => state.ui.users.selection.selected
  );

  const select = (id: User) => {
    dispatch(actions.selectUser(id));
  };
  const deselect = (id: User) => {
    dispatch(actions.deselectUser(id));
  };
  const selectAll = (users: User[]) => {
    dispatch(actions.selectAllUsers(users));
  };
  const deselectAll = () => {
    dispatch(actions.deselectAllUsers());
  };
  return {
    selection,
    select,
    deselect,
    selectAll,
    deselectAll
  };
};

export default useSelection;
