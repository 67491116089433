import MuiDialogContentText, {
  DialogContentTextProps
} from "@material-ui/core/DialogContentText";
import React, { forwardRef, ForwardRefExoticComponent } from "react";

const DialogContentText: ForwardRefExoticComponent<DialogContentTextProps> = forwardRef(
  (props, ref) => <MuiDialogContentText {...props} ref={ref} />
);

export default DialogContentText;
