import User from "../models/User";
import useUsers from "./useUsers";
import useConvertToMap from "./useConvertToMap";

const useUsersMap = (users?: User[]) => {
  const { data: allUsers = [] } = useUsers();
  return useConvertToMap<User>("id", users || allUsers);
};

export default useUsersMap;
