import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import Page from "@superprofit/core-react-components/atoms/Page";
import PeriodSelect from "../../components/molecules/PeriodSelect";
import {
  Button,
  FormHelperText,
  Grid,
  Typography
} from "@superprofit/core-react-components/atoms";
import useUserGroups from "../../hooks/useUserGroups";
import useUser from "../../hooks/useUser";
import useUserTimesheet from "../../hooks/useUserTimesheet";
import usePeriod from "./timesheet/hooks/usePeriod";
import useUserProfile from "../../hooks/useUserProfile";
import useFilterProjects from "./timesheet/hooks/useFilterProjects";
import FilterIcon from "@material-ui/icons/FilterList";
import Badge from "@superprofit/core-react-components/atoms/Badge";
import FilterDialog from "./timesheet/FilterDialog";

import { format } from "../../date-fns-wrappers";
import useISOWeekBookendsAsDate from "./timesheet/hooks/useISOWeekBookendsAsDate";
import { eachDayOfInterval, getDayOfYear, isSameDay } from "date-fns";
import { getYear } from "date-fns/fp";
import { useTranslation } from "react-i18next";
import Entry from "../../models/Entry";
import {
  TimesheetV2Container,
  TimesheetV2Wrapper,
  GridContainer,
  ProjectsHeader,
  Day,
  HiddenProjectsMessage,
  ProjectsHeaderDivider,
  ProjectsHeaderContainer
} from "./timesheetV2/TimesheetV2.styles";
import Project from "../../models/Project";

import TextField from "../../components/atoms/TextField";
import DayHeader from "./timesheetV2/DayHeader";
import ProjectItem from "./timesheetV2/ProjectItem";
import { useForm } from "react-hook-form";

import useEntriesMap, { getKey } from "./timesheetV2/useEntriesMap";
import Skeleton from "@material-ui/lab/Skeleton";
import useSaveEntry from "./timesheetV2/useSaveEntry";
import useHiddenProjectTimesheets from "./timesheet/hooks/useHiddenProjectTimesheets";

import ViewDayIcon from "@material-ui/icons/ViewDay";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import IconButton from "../../components/atoms/IconButton";
import Tooltip from "../../components/atoms/Tooltip";
import { useHistory, useLocation } from "react-router-dom";
import Routes, { getPathWithKey } from "../../router/routes";
import DurationFormat from "../../components/atoms/DurationFormat";
import { EntryPayload } from "../../hooks/useUserTimesheetMutation";
import { useOverrideTimesheetUser } from "./timesheet/hooks/useOverrideTimesheetUser";
import { ProjectTitleContainer } from "./timesheet/Timesheet.styles";
import useCustomersMap from "../../hooks/useCustomersMap";
import { ProjectView } from "./timesheetV2/projectView/ProjectView";

export default () => {
  const { t } = useTranslation();
  const { update: setPeriod, ...period } = usePeriod();
  const { isLoading: profileLoading } = useUserProfile();
  const history = useHistory();
  const location = useLocation();
  const saveEntry = useSaveEntry();
  const customersMap = useCustomersMap();
  const user = useUser();
  const overridenUserEmail = useOverrideTimesheetUser();
  const userEmail = overridenUserEmail || (user && user.email);
  const {
    isLoading: timesheetEntriesLoading,
    data: entries = []
  } = useUserTimesheet({
    id: userEmail,
    ...period
  });
  const { hidden, toggleHidden } = useHiddenProjectTimesheets();
  const {
    data: filteredProjects,
    isLoading: projectsLoading,
    update: updateFilter,
    toggleModal,
    onlyShowProjectsWith,
    showHiddenProjects
  } = useFilterProjects();
  const projects = filteredProjects;
  const entriesMap = useEntriesMap(entries);

  const { isLoading: userGroupsLoading } = useUserGroups({
    ids: [userEmail]
  });

  const getInputName = getKey;

  const [startOfWeekDate, endOfWeekDate] = useISOWeekBookendsAsDate(period);

  const values = entries.reduce(
    (acc, entry) => ({
      ...acc,
      [getInputName(entry.project, entry.year, entry.dayOfYear)]: entry.hours
    }),
    {}
  );
  const { handleSubmit, register } = useForm({
    values,
    resetOptions: {
      keepDirtyValues: true // user-interacted input will be retained
    },
    mode: "onBlur"
  });

  const datesBetween = eachDayOfInterval({
    start: startOfWeekDate,
    end: endOfWeekDate
  });

  const isLoading =
    timesheetEntriesLoading ||
    projectsLoading ||
    profileLoading ||
    userGroupsLoading;

  const handleOnChangePeriod = ({
    year,
    week
  }: {
    year?: number;
    week?: number;
  }) => {
    setPeriod({ year, week });
  };

  const handleOnFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFilter({ search: e.target.value });
  };
  const dateRange = `${format(startOfWeekDate, "MMM dd")} - ${format(
    endOfWeekDate,
    "MMM dd"
  )}`;

  const getChangedEntry = (
    name: string,
    value: string | number
  ): EntryPayload | null => {
    const [projectId, yearString, dayOfYearString] = name.split("-");
    const year = parseInt(yearString);
    const dayOfYear = parseInt(dayOfYearString);
    const existingEntry = entriesMap.get(name);
    const valueParsed =
      typeof value === "string" ? value.replace(",", ".") : value;
    const hours =
      typeof valueParsed === "string" ? parseFloat(valueParsed) : valueParsed;
    if (
      !Number.isNaN(hours) &&
      (!existingEntry || existingEntry.hours !== hours) &&
      hours > -1
    ) {
      const entry = existingEntry?.clone();
      return {
        user: userEmail,
        project: projectId,
        year,
        dayOfYear,
        hours,
        entry
      };
    }
    return null;
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const entry = getChangedEntry(name, value);
    if (entry) {
      saveEntry(entry);
    }
  };

  const handleFormSubmit = async (data: [string, string | number]) => {
    const filteredData = Object.entries(data).filter(([, value]) => value);
    for (const [name, value] of filteredData) {
      const entry = getChangedEntry(name, value);
      if (entry) {
        saveEntry(entry);
      }
    }
  };

  const sumPerDay = useMemo(
    () =>
      datesBetween.map((d, index) =>
        entries.reduce(
          (total, entry) =>
            ((entry.dayOfYear === getDayOfYear(d) &&
              entry.year === getYear(d) &&
              entry.hours) ||
              0) + total,
          0
        )
      ),
    [entries, datesBetween]
  );

  const todaysDate = new Date();

  const sortedProjects = useMemo(() => {
    return [...projects]
      .filter(p => !hidden.has(p.id))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [projects, hidden]);

  const hiddenProjects = useMemo(() => {
    return [...projects]
      .filter(p => hidden.has(p.id))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [hidden]);

  const handleOnHideProject = (project: Project) => {
    if (project.id !== null) toggleHidden(project.id);
  };

  const totalHours = useMemo(
    () => entries.reduce((total, entry) => entry.hours + total, 0),
    [entries]
  );

  const getTitle = (project: Project) => {
    const customer = customersMap.get(project.customer);
    if (customer) {
      return (
        <ProjectTitleContainer>
          <span>{project.name}</span>
          {/*<FormHelperText>*/}
          {/*  <i>{customer.name}</i>*/}
          {/*</FormHelperText>*/}
        </ProjectTitleContainer>
      );
    }
    return project.name;
  };

  const v2Users = [
    "sondre.t.johannessen@gmail.com",
    "sondre.johannessen@sonat.no"
  ];
  const v2Enabled = v2Users.includes(user.email);

  const handleOnGridViewClick = () => {
    history.push(getPathWithKey("timesheetV2") + location.search);
  };

  const handleOnCardViewClick = () => {
    if (v2Enabled) {
      history.push(getPathWithKey("timesheetV2Card") + location.search);
    } else {
      history.push(getPathWithKey("timesheet") + location.search);
    }
  };

  return (
    <Page
      style={{ paddingBottom: 0, display: "flex", flexDirection: "column" }}
      context={userEmail}
      title={
        <Grid container alignItems="baseline" spacing={1}>
          <Grid item xs={12} md>
            <Typography variant="h1">{`${t(
              "common.timesheet"
            )} (Beta)`}</Typography>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={3}
        justify="space-between"
        alignItems="center"
        style={{ maxWidth: 1680 }}
      >
        <Grid item>
          <div>
            <PeriodSelect
              showNavigation
              showMonth={false}
              period={period}
              onChange={handleOnChangePeriod}
            />
          </div>
        </Grid>
        <Grid item>
          <Grid container justify="center" spacing={1} alignItems="center">
            <Grid item>
              <Tooltip
                arrow
                title="Beta"
                aria-label={t("pages.users.subscriptionTooltip") && ""}
              >
                <IconButton onClick={handleOnGridViewClick}>
                  <ViewComfyIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton onClick={handleOnCardViewClick}>
                <ViewDayIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Badge
                color="error"
                variant="dot"
                invisible={onlyShowProjectsWith === null && !showHiddenProjects}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={toggleModal}
                  disabled={isLoading}
                  startIcon={<FilterIcon />}
                >
                  {t("common.filter")}
                </Button>
              </Badge>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {location.pathname === getPathWithKey("timesheetV2Card") ? (
        <ProjectView
          userEmail={userEmail}
          projects={sortedProjects}
          hiddenProjects={hiddenProjects}
          entries={entries}
          isLoading={isLoading}
          showHiddenProjects={showHiddenProjects}
          datesBetween={datesBetween}
        />
      ) : (
        <TimesheetV2Container>
          <TimesheetV2Wrapper>
            <GridContainer onSubmit={handleSubmit(handleFormSubmit)}>
              <ProjectsHeaderContainer>
                <Grid item>
                  <ProjectsHeader>{t("common.projects")}</ProjectsHeader>
                </Grid>
                <Grid item>
                  <ProjectsHeaderDivider orientation="vertical" />
                </Grid>
                <Grid item>
                  <DurationFormat
                    component={({ children }: { children: JSX.Element }) => (
                      <Typography variant="caption" children={children} />
                    )}
                    value={totalHours}
                  />
                </Grid>
              </ProjectsHeaderContainer>

              {datesBetween.map((date, index) => (
                <DayHeader
                  date={date}
                  hours={sumPerDay[index]}
                  isToday={isSameDay(todaysDate, date)}
                />
              ))}
              {isLoading &&
                new Array(710).fill(0).map((_, index) =>
                  index % 8 !== 0 ? (
                    <Skeleton
                      style={{ margin: "0.5rem" }}
                      component="div"
                      variant="rect"
                      height={50}
                    />
                  ) : (
                    <div>
                      <Skeleton component="div" variant="text" width={150} />
                      <Skeleton component="div" variant="text" width={100} />
                    </div>
                  )
                )}
              {!isLoading &&
                sortedProjects?.map((project: Project) =>
                  [
                    <ProjectItem
                      isHidden={project.id !== null && hidden.has(project.id)}
                      project={project}
                      onHide={handleOnHideProject}
                    />
                  ].concat(
                    datesBetween.map((date, index) => (
                      <Day>
                        <TextField
                          {...register(
                            getInputName(
                              project.id,
                              getYear(date),
                              getDayOfYear(date)
                            )
                          )}
                          onBlur={handleOnBlur}
                          variant={"filled" as any}
                        />
                      </Day>
                    ))
                  )
                )}
              {hidden.size > 0 && (
                <>
                  <HiddenProjectsMessage variant="caption">
                    <i>
                      {t(
                        showHiddenProjects
                          ? "pages.timesheet.templates.showingHiddenProjects"
                          : "pages.timesheet.templates.hidingHiddenProjects",
                        {
                          count: hidden.size
                        }
                      )}
                    </i>
                  </HiddenProjectsMessage>
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </>
              )}
              {!isLoading &&
                showHiddenProjects &&
                hiddenProjects?.map((project: Project) =>
                  [
                    <ProjectItem
                      isHidden={project.id !== null && hidden.has(project.id)}
                      project={project}
                      onHide={handleOnHideProject}
                    />
                  ].concat(
                    datesBetween.map((date, index) => (
                      <Day>
                        <TextField
                          {...register(
                            getInputName(
                              project.id,
                              getYear(date),
                              getDayOfYear(date)
                            )
                          )}
                          onBlur={handleOnBlur}
                          variant={"filled" as any}
                        />
                      </Day>
                    ))
                  )
                )}
              <button style={{ display: "none" }} />
            </GridContainer>
          </TimesheetV2Wrapper>
        </TimesheetV2Container>
      )}

      <FilterDialog />
    </Page>
  );
};
