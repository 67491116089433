import { all, put, takeLatest, select, call } from "@redux-saga/core/effects";
import { INIT, UPDATE } from "./constants";
import { moment } from "@superprofit/time-util";
import { setUpdates } from "./actions";
import Invoice2 from "../../../../../models/Invoice";
import { Timestamp } from "@superprofit/core-firestore-models";
import { isEmptyObject } from "../../../../../helpers";

export const handleInvoiceUpdate = function*(args) {
  const { updates: incomingUpdates } = args.payload;
  const workspace = yield select(({ api: { auth } }) => auth.activeWorkspaceId);

  const currentUpdates = yield select(
    state => state.ui.invoices.dialog.updates
  );
  const existingInvoice = yield select(
    state => state.ui.invoices.dialog.invoice
  );

  const nextState = {
    ...existingInvoice,
    ...currentUpdates,
    ...incomingUpdates
  };

  if (!existingInvoice || !existingInvoice.id) {
    // do not recalculate existing invoices
    if (
      nextState.month &&
      nextState.year &&
      nextState.customer &&
      nextState.projects &&
      nextState.dueDate &&
      nextState.issueDate
    ) {
      const basis = yield call(
        Invoice2.createDataFromXstats,
        workspace,
        nextState.year,
        nextState.month,
        nextState.customer,
        nextState.projects,
        nextState.dueDate,
        nextState.issueDate
      );
      incomingUpdates.basis = basis;
    }
  }

  yield put(
    setUpdates({
      ...currentUpdates,
      ...incomingUpdates
    })
  );
};

export const handleInit = function*() {
  const invoice = yield select(state => state.ui.invoices.dialog.invoice);
  if (!isEmptyObject(invoice)) {
    yield put(setUpdates({}, false));
  } else {
    const monthAgo = moment().subtract(1, "month"); // Defaults to last month;
    const initializedNewInvoice = {
      month: monthAgo.month() + 1, // Invoice is currently just a  month at a time. moment month is 0 - 11
      year: monthAgo.year(),
      dueDate: Timestamp.fromMoment(moment().endOf("month")), //moment().month(), // Invoice is currently just a  month at a time
      issueDate: Timestamp.fromMoment(monthAgo.endOf("month")) //moment().month(), // Invoice is currently just a  month at a time
    };
    yield put(setUpdates(initializedNewInvoice, false));
  }
};

export const handleInvoiceUpdateSaga = function*() {
  yield takeLatest(UPDATE, handleInvoiceUpdate);
};

export const handleInitSaga = function*() {
  yield takeLatest(INIT, handleInit);
};

export default function*() {
  yield all([handleInvoiceUpdateSaga(), handleInitSaga()]);
}
