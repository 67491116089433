import React, { forwardRef } from "react";
import { Avatar } from "@superprofit/core-react-components/atoms";

export default forwardRef(({ customer, ...rest }, ref) => (
  <Avatar
    initial={customer.name && customer.name[0]}
    alt={`Avatar ${customer.name}`}
    color={customer.getColor()}
    variant="rounded"
    {...rest}
    ref={ref}
  />
));
