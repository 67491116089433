import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
  DELETE,
  DELETE_FAILURE,
  DELETE_SUCCESS,
  WATCH_GET,
  WATCH_SAVE,
  WATCH_DELETE
} from "./constants";
import { User } from "@superprofit/core-firestore-models";

export const get = () => ({
  type: GET,
  payload: { }
});

export const getSuccess = (list, pagination) => ({
  type: GET_SUCCESS,
  payload: { list, pagination }
});

export const getFailure = error => ({
  type: GET_FAILURE,
  payload: { error }
});

export const save = () => ({
  type: SAVE,
  payload: {}
});

export const saveSuccess = (user) => ({
  type: SAVE_SUCCESS,
  payload: { user }
});

export const saveFailure = error => ({
  type: SAVE_FAILURE,
  payload: { error }
});

export const deleteUser = () => ({
  type: DELETE,
  payload: {}
});

export const deleteSuccess = (list, pagination) => ({
  type: DELETE_SUCCESS,
  payload: { list, pagination }
});

export const deleteFailure = error => ({
  type: DELETE_FAILURE,
  payload: { error }
});

export const watchGetUsers = (UserClass = User) => ({
  type: WATCH_GET,
  payload: { UserClass }
});

export const watchDeleteUser = ({ id }, UserClass = User) => ({
  type: WATCH_DELETE,
  payload: { id, UserClass }
});

export const watchSaveUser = (user, updates, UserClass = User) => ({
  type: WATCH_SAVE,
  payload: { user, updates, UserClass }
});
