import React from "react";
import i18next from "i18next";
const t = i18next.t;

export const asHoursAndMinutes = value => {
  const hours = Math.floor(value);
  const rest = value - hours;
  const minutes = Math.round(rest * 60);

  const minPart = minutes > 0 ? ` ${minutes}m` : "";

  return `${hours}${t("common.h")}${minPart}`;
};

export default props => {
  const { value, prefix, component, ...rest } = props;
  const ComponentName = component || "span";
  return (
    <ComponentName {...rest}>
      {prefix && prefix}
      {asHoursAndMinutes(value || 0)}
    </ComponentName>
  );
};
