import React, { useState, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DownloadIcon from "@material-ui/icons/GetApp";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Badge, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FilterIcon from "@material-ui/icons/FilterList";
interface Props {
  onAdd?: (e: React.MouseEvent) => void;
  onExportPDF?: (e: React.MouseEvent) => void;
  onExportXLSX?: (e: React.MouseEvent) => void;
  onExportPDFProjectsOnly?: (e: React.MouseEvent) => void;
  onExportXLSXProjectsOnly?: (e: React.MouseEvent) => void;
  onExportNettlonn?: (e: React.MouseEvent) => void;
  onFilter?: (e: React.MouseEvent) => void;
  hasFilters?: boolean;
}
const ActionMenu: React.FC<Props> = props => {
  const {
    onAdd,
    onExportPDF,
    onExportXLSX,
    onExportPDFProjectsOnly,
    onExportXLSXProjectsOnly,
    onExportNettlonn,
    onFilter,
    hasFilters
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { t } = useTranslation();
  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleOnOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleOnClick = (
    e: React.MouseEvent,
    action: (e: React.MouseEvent) => void
  ) => {
    action(e);
    handleOnClose();
  };

  if (!onAdd && !onExportPDF && !onExportXLSX) return null;

  return (
    <Fragment>
      {onAdd && (
        <Tooltip title="Add" arrow>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={e => handleOnClick(e, onAdd)}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={t("common.download")!} arrow>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOnOpen}
        >
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      {onFilter && (
        <Tooltip title={t("common.filter")!} arrow>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={onFilter}
          >
            <Badge color="error" variant="dot" invisible={!hasFilters}>
              <FilterIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      )}

      <Menu
        id="actions"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleOnClose}
      >
        {onExportPDF && (
          <MenuItem onClick={e => handleOnClick(e, onExportPDF)}>
            {`${t("common.download")} PDF`}
          </MenuItem>
        )}
        {onExportPDFProjectsOnly && (
          <MenuItem onClick={e => handleOnClick(e, onExportPDFProjectsOnly)}>
            <span style={{ marginLeft: "auto" }}>
              <i>{t("pages.reports.onlyProjects")}</i>
            </span>
          </MenuItem>
        )}
        {onExportXLSX && (
          <MenuItem onClick={e => handleOnClick(e, onExportXLSX)}>
            {`${t("common.download")} excel`}
          </MenuItem>
        )}
        {onExportNettlonn && (
          <MenuItem onClick={e => handleOnClick(e, onExportNettlonn)}>
            {`${t("common.download")} Nettlonn excel`}
          </MenuItem>
        )}

        {onExportXLSXProjectsOnly && (
          <MenuItem onClick={e => handleOnClick(e, onExportXLSXProjectsOnly)}>
            <span style={{ marginLeft: "auto" }}>
              <i>Only projects</i>
            </span>
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};

export default ActionMenu;
