import React from "react";
import Grid from "@material-ui/core/Grid";
import Page from "@superprofit/core-react-components/atoms/Page";
import { useSelector } from "react-redux";
import AutofillSettings from "./profileSettings/AutofillSettings";
import Notifcations from "./profileSettings/Notifications";
import Features from "./profileSettings/Features";
import { useTranslation } from "react-i18next";
import GeneralSettings from "./profileSettings/GeneralSettings";
import ApiKeys from "./profileSettings/ApiKeys";

export default () => {
  const { user } = useSelector(state => state.api.auth);
  const userEmail = user && user.email;
  const { t } = useTranslation();

  return (
    <Page context={userEmail} title={t("common.settings")}>
      <Grid container spacing={3} component="div">
        <Grid item xs={12} sm={6} md={12} lg={6} component="div">
          <GeneralSettings />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6} component="div">
          <Features />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6} component="div">
          <ApiKeys />
        </Grid>

        {/*<Grid item xs={12} sm={6} md={12} lg={6} component="div">*/}
        {/*  <AutofillSettings />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} sm={6} md={12} lg={6} component="div">*/}
        {/*  <Notifcations />*/}
        {/*</Grid>*/}
      </Grid>
    </Page>
  );
};
