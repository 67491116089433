import React from "react";
import { MenuItem, Menu } from "@superprofit/core-react-components/atoms";
import { useTranslation } from "react-i18next";

interface IActionMenu {
  data: any;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onOverrideUserTimesheet?: (e: React.MouseEvent, data: any) => void;
  onDelete?: (e: React.MouseEvent, data: any) => void;
  onEdit?: (e: React.MouseEvent, data: any) => void;
  onView?: (e: React.MouseEvent, data: any) => void;
  onAssignRoles?: (e: React.MouseEvent, data: any) => void;
  onAssignProjects?: (e: React.MouseEvent, data: any) => void;
  onArchive?: (e: React.MouseEvent, data: any) => void;
  onExport247?: (e: React.MouseEvent, data: any) => void;
  onChangePaid?: (e: React.MouseEvent, data: any) => void;
  onDuplicate?: (e: React.MouseEvent, data: any) => void;
}

function ActionMenu({
  data,
  open,
  anchorEl,
  onClose,
  onDelete,
  onEdit,
  onView,
  onOverrideUserTimesheet,
  onAssignRoles,
  onAssignProjects,
  onArchive,
  onExport247,
  onChangePaid,
  onDuplicate
}: IActionMenu) {
  const { t } = useTranslation();

  return (
    <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
      {onView && (
        <MenuItem
          key="View"
          color="inherit"
          onClick={(e: React.MouseEvent) => {
            onClose();
            onView(e, data);
          }}
        >
          {t("common.view")}
        </MenuItem>
      )}

      {onEdit && (
        <MenuItem
          key="Edit"
          color="inherit"
          onClick={(e: React.MouseEvent) => {
            onClose();
            onEdit(e, data);
          }}
        >
          {t("common.edit")}
        </MenuItem>
      )}

      {onOverrideUserTimesheet && (
        <MenuItem
          key="duplicateResource"
          color="inherit"
          onClick={(e: React.MouseEvent) => {
            onClose();
            onOverrideUserTimesheet(e, data);
          }}
        >
          {t("pages.users.overrideHours")}
        </MenuItem>
      )}

      {onAssignProjects && (
        <MenuItem
          key="Add projects"
          color="inherit"
          onClick={(e: React.MouseEvent) => {
            onClose();
            onAssignProjects(e, data);
          }}
        >
          {t("pages.users.assignProjects")}
        </MenuItem>
      )}

      {onAssignRoles && (
        <MenuItem
          key="Add roles"
          color="inherit"
          disabled={
            (typeof data === "object" && (data as any)?.userAccess)
              ?.isPendingInvite
          }
          onClick={(e: React.MouseEvent) => {
            onClose();
            onAssignRoles(e, data);
          }}
        >
          {t("pages.users.assignRoles")}
        </MenuItem>
      )}

      {onChangePaid && (
        <MenuItem
          key="changePaid"
          color="inherit"
          onClick={(e: React.MouseEvent) => {
            onClose();
            onChangePaid(e, data);
          }}
        >
          {typeof data === "object" && (data as any)?.paid
            ? t("pages.invoices.markAsUnpaid")
            : t("pages.invoices.markAsPaid")}
        </MenuItem>
      )}
      {onDuplicate && (
        <MenuItem
          key="duplicateResource"
          color="inherit"
          onClick={(e: React.MouseEvent) => {
            onClose();
            onDuplicate(e, data);
          }}
        >
          {t("common.duplicate")}
        </MenuItem>
      )}

      {onExport247 && (
        <MenuItem
          key="export247"
          color="inherit"
          onClick={(e: React.MouseEvent) => {
            onClose();
            onExport247(e, data);
          }}
        >
          {t("common.sendTo")} 24SO
        </MenuItem>
      )}

      {onArchive && (
        <MenuItem
          key="Archive"
          color="inherit"
          onClick={(e: React.MouseEvent) => {
            onClose();
            onArchive(e, data);
          }}
        >
          {t("common.archive_command")}
        </MenuItem>
      )}

      {onDelete && (
        <MenuItem
          key="Delete"
          color="alert"
          onClick={(e: React.MouseEvent) => {
            onClose();
            onDelete(e, data);
          }}
        >
          {t("common.delete")}
        </MenuItem>
      )}
    </Menu>
  );
}

export default ActionMenu;
