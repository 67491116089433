import { all, put, takeLatest } from "@redux-saga/core/effects";
import { SAVE, SAVE_SUCCESS } from "../../../api/projects/constants";

import { hideAddUserDialog, setSaving } from "./actions";

export const handleProjectSaving = function*(args) {
  try {
    yield put(setSaving(true));
  } catch (e) {
    console.warn(e);
  }
};

export const handleProjectSaved = function*(args) {
  try {
    yield put(hideAddUserDialog());
  } catch (e) {
    console.warn(e);
  }
};

export const handleProjectSavingSaga = function*() {
  yield takeLatest(SAVE, handleProjectSaving);
};

export const handleProjectSavedSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleProjectSaved);
};

export default function*() {
  yield all([handleProjectSavingSaga(), handleProjectSavedSaga()]);
}
