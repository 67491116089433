import { combineReducers } from "redux";
import period from "./period/reducer";
import plan from "./plan/reducer";
import snackbar from "./snackbar/reducer";
import projectsTable from "./projectsTable/reducer";

export default combineReducers({
  period,
  plan,
  snackbar,
  projectsTable
});
