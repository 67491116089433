import React, { useMemo } from "react";
import Hint from "../../../../../components/atoms/Hint";
import Important from "../../../../../components/atoms/Important";
import Grid from "@material-ui/core/Grid";
import { LoggedItem, StyledLogged } from "./Logged.styles";
import Skeleton from "@material-ui/lab/Skeleton";
import DurationFormat from "../../../../../components/atoms/DurationFormat";
import NumberFormat from "react-number-format";
import {
  getTotalPlannedHours,
  getTotalPlannedMoney
} from "../../../../../utils/WorkspaceStats";
import useWorkspaceStats from "../hooks/useWorkspaceStats";
import { useTranslation } from "react-i18next";

export default () => {
  const {
    isLoading: loading,
    projects,
    period: { year, month }
  } = useWorkspaceStats();

  const { t } = useTranslation();

  const { money, hours } = useMemo(() => {
    if (loading || !projects) return {};

    const monthHours = getTotalPlannedHours(year, month, projects);

    const monthMoney = getTotalPlannedMoney(year, month, projects);

    return {
      money: monthMoney,
      hours: monthHours
    };
  }, [year, month, projects, loading]);

  return (
    <StyledLogged>
      <Grid
        container
        spacing={6}
        component="div"
        alignItems="center"
        justify="flex-start"
      >
        <Grid item component="div">
          <Hint>{t("common.planned")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <LoggedItem>
                <DurationFormat value={hours} />
              </LoggedItem>
              <em>|</em>
              <LoggedItem>
                <NumberFormat
                  displayType="text"
                  thousandSeparator
                  isNumericString
                  decimalScale={2}
                  value={money || 0}
                />
              </LoggedItem>
            </Important>
          )}
        </Grid>
      </Grid>
    </StyledLogged>
  );
};
