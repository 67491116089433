import React from "react";
import Card from "@superprofit/core-react-components/atoms/Card";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import CardContent from "@superprofit/core-react-components/atoms/CardContent";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select
} from "@superprofit/core-react-components/atoms";
import MenuItem from "@superprofit/core-react-components/atoms/MenuItem";
import { watchSaveUserSettings } from "../../../redux/modules/api/userSettings/actions";
import UserSettingGeneric from "../../../models/UserSettingGeneric";
import { useTranslation } from "react-i18next";

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { settings } = useSelector(
    (state: {
      api: { userSettings: { settings: { language: { value: any } } } };
    }) => state.api.userSettings
  );

  const handleChangeLanguage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    dispatch(
      watchSaveUserSettings([
        new UserSettingGeneric({
          id: "language",
          value,
          user: null,
          updatedAt: null,
          updatedBy: null,
          createdAt: null,
          createdBy: null
        })
      ])
    );
  };

  return (
    <div>
      <section>
        <Card>
          <CardHeader title={t("common.general")} />
          <Divider />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <FormControl component="fieldset" margin="normal">
                  <InputLabel
                    variant="standard"
                    shrink={false}
                    id="application-lang-label"
                  >
                    {t("pages.profileSettings.applicationLanguage")}
                  </InputLabel>
                  <Select
                    variant="filled"
                    labelId="application-lang-label"
                    id="application-lang-select"
                    value={settings?.language?.value || "en"}
                    onChange={handleChangeLanguage}
                  >
                    <MenuItem key="en" value="en">
                      {t("common.english")}
                    </MenuItem>
                    <MenuItem key="nb-NO" value="nb-NO">
                      {t("common.norwegian")}
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {t("pages.profileSettings.applicationLanguageHelperText")}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </section>
    </div>
  );
};
