import styled from "styled-components";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import { darken } from "@material-ui/core";

export const DayItem = styled.div<{ isWeekend: boolean }>`
  display: flex;
  flex-direction: column;
  opacity: 0.85;
  ${({ isWeekend }) => isWeekend && "opacity: 0.5"};
  &:not(:first-child) {
    padding-left: 0.25rem;
  }
`;
export const Days = styled.div`
  display: flex;
`;
export const ProjectTimesheetCardHeader = styled(CardHeader)`
  .MuiCardHeader-action {
    align-self: center;
  }
`;

export const ProjectTitleContainer = styled.div`
  display: flex;

  > span:first-child {
    padding-right: 0.5rem;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledWeekSummary = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: ${props => props.theme.spacing(1)}px;

  > * {
    margin-left: ${props => props.theme.spacing(2)}px;
  }
`;

interface IStyledEntry {
  abnormalDay: boolean;
}
export const StyledEntry = styled.div<IStyledEntry>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ abnormalDay, theme }) =>
    abnormalDay &&
    `
      .MuiFilledInput-root {
        background: ${darken(theme.palette.background.light, 0.01)}
      }
  `};
`;

export const StyledEntries = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-left: ${props => props.theme.spacing(1)}px;
    margin-right: ${props => props.theme.spacing(1)}px;
  }

  margin-bottom: ${props => props.theme.spacing(2)}px;
`;
