import { db } from "../firebase";
import { QueryBuilder, Timestamp } from "@superprofit/core-firestore-models";

export default class Lead {
  static collectionName = "leads";

  static converter = {
    toFirestore(lead) {
      return lead.data();
    },
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      return new Lead({ ...data, id: snapshot.id });
    }
  };

  static createId(workspace) {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(Lead.collectionName)
      .doc().id;
  }

  constructor({
    id,
    name,
    email,
    contactName,
    tags,
    description,
    comments = [],
    workflow = 0,
    createdBy,
    updatedBy,
    createdAt,
    updatedAt
  }) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.contactName = contactName;
    this.tags = tags;
    this.description = description;
    this.comments = comments;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.workflow = workflow;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates) {
    Object.assign(this, updates);
    return this;
  }

  isValid = () => {
    return !!this.name && !!this.description;
  };

  data() {
    return {
      name: this.name,
      email: this.email,
      contactName: this.contactName,
      tags: this.tags,
      description: this.description,
      comments: this.comments,
      workflow: this.workflow,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      updatedBy: this.updatedBy
    };
  }

  static list = async (workspace, query) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(Lead.collectionName),
      query
    )
      .withConverter(Lead.converter)
      .get();

    return snapshot.docs.map(doc => doc.data());
  };

  static create = async (workspace, user, data) => {
    const lead = new Lead({
      ...data,
      id: Lead.createId(workspace),
      updatedAt: Timestamp.now(),
      createdAt: Timestamp.now(),
      updatedBy: user,
      createdBy: user
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Lead.collectionName)
      .doc(lead.id)
      .withConverter(Lead.converter)
      .set(lead, { merge: true });

    return lead;
  };

  static update = async (workspace, user, lead, updates) => {
    const updatedLead = lead.clone();

    updatedLead.setData({
      ...updates,
      updatedAt: Timestamp.now(),
      updatedBy: user
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Lead.collectionName)
      .doc(updatedLead.id)
      .withConverter(Lead.converter)
      .set(updatedLead, { merge: true });

    return updatedLead;
  };
}
