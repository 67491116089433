import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import UserProfile from "../models/UserProfile";
import useUser from "./useUser";
import Entry from "../models/Entry";

export const getKey = (workspace: string, id: string) => [
  workspace,
  id,
  UserProfile.collectionName
];
const useUserProfile = ({ userId }: { userId?: string } = {}) => {
  const { email: userEmail } = useUser();
  const workspace = useWorkspace();
  const email = userId || userEmail;

  const get = () => UserProfile.get(workspace, email);
  const key = getKey(workspace, email);

  const query = useQuery(key, get, {
    enabled: !!email && !!workspace
  });
  return query;
};

export default useUserProfile;
