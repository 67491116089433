import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { GET, GET_SUCCESS } from "../../../api/companyDashboard/constants";
import { setLoading, setWeeklyHours } from "./actions";

export const getStats = ({
  api: {
    companyStats: { stats }
  }
}) => stats.raw;

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const handleGetSuccess = function*(args) {
  try {
    const raw = yield select(getStats);

    const weeklyHoursBillable = raw
      .filter(stats => stats.billable)
      .reduce((prev, next) => {
        return {
          ...prev,
          [next.week]: (prev[next.week] || 0) + next.total
        };
      }, {});

    const weeklyHoursNonBillable = raw
      .filter(stats => !stats.billable)
      .reduce((prev, next) => {
        return {
          ...prev,
          [next.week]: (prev[next.week] || 0) + next.total
        };
      }, {});

    const weeks = Object.keys({
      ...weeklyHoursBillable,
      ...weeklyHoursNonBillable
    });
    const weekMin = parseInt(weeks[0]);
    const weekMax = parseInt(weeks[weeks.length - 1]);
    const arrayLength = weekMax - weekMin + 1;
    let weekNumbers = [0];
    if (arrayLength > 0) {
      new Array(arrayLength)
        .fill(0)
        .map((w, idx) => weekMin + idx);
    }


    const weeklyHoursBillableNormalized = weekNumbers.map(
      w => weeklyHoursBillable[w] || 0
    );
    const weeklyHoursNonBillableNormalized = weekNumbers.map(
      w => weeklyHoursNonBillable[w] || 0
    );

    yield put(
      setWeeklyHours(
        weeklyHoursBillableNormalized,
        weeklyHoursNonBillableNormalized,
        weekNumbers
      )
    );
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleStatisticsLoadingSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export const handleStatisticsSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleGetSuccess);
};

export default function*() {
  yield all([handleStatisticsSaga(), handleStatisticsLoadingSaga()]);
}
