import React from "react";
import Card from "@superprofit/core-react-components/atoms/Card";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import CardContent from "@superprofit/core-react-components/atoms/CardContent";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import WorkdaysCheckboxes from "./WorkdaysCheckboxes";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import HolidayCalendar from "./HolidayCalendar";
import { watchSaveCompanySettings } from "../../../redux/modules/api/companySettings/actions";
import CompanySettingGoal from "../../../models/CompanySettingGoal";
import CompanySettingGeneric from "../../../models/CompanySettingGeneric";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import { QuickLogForm } from "../../../components/organisms/appBar/AppBar.styles";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import workspaceActions from "@superprofit/core-redux-saga-modules/Workspaces/actions";
import Internationalization from "./Internationalization";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select
} from "@superprofit/core-react-components/atoms";
import { supportedCountries } from "../../../utils/Countries";
import MenuItem from "@superprofit/core-react-components/atoms/MenuItem";

const LoadingSkeleton = () => (
  <div>
    <section>
      <Card>
        <CardHeader title="General" />
        <Divider />
        <CardContent>
          <Skeleton variant="text" width={200} height={40} />
          <br />
          <br />
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="text" width={200} height={40} />
        </CardContent>
      </Card>
    </section>
  </div>
);

export default () => {
  const dispatch = useDispatch();

  const { settings, loading } = useSelector(state => state.api.companySettings);

  const { workspace: originalWorkspace } = useSelector(state => state.api.auth);

  const handleOnSettingChanged = (name, value) => {
    dispatch(
      watchSaveCompanySettings([new CompanySettingGeneric({ id: name, value })])
    );
  };

  const [debouncedHandleWorkspaceDisplayNameChanged] = useDebouncedCallback(
    value => {
      dispatch(
        workspaceActions.watchUpdate(originalWorkspace, { displayName: value })
      );
    },
    300
  );
  const handleWorkspaceDisplayNameChanged = e => {
    debouncedHandleWorkspaceDisplayNameChanged(e.currentTarget.value);
  };

  const [
    debouncedHandleWorkspaceOrganizationNumberChanged
  ] = useDebouncedCallback(value => {
    dispatch(
      watchSaveCompanySettings([
        new CompanySettingGeneric({
          id: "organization",
          value: {
            organizationNumber: value
          }
        })
      ])
    );
  }, 300);
  const handleWorkspaceOrganizationNumberChanged = e => {
    debouncedHandleWorkspaceOrganizationNumberChanged(e.currentTarget.value);
  };
  if (loading) return <LoadingSkeleton />;
  return (
    <div>
      <section>
        <Card>
          <CardHeader title="General" />
          <Divider />
          <CardContent>
            <TextField
              variant="filled"
              defaultValue={originalWorkspace?.displayName || ""}
              label="Workspace display name"
              placeholder="My Corp Inc"
              onChange={handleWorkspaceDisplayNameChanged}
            />
            <FormHelperText>
              The name that should appear in document and emails when referring
              to this workspace.
            </FormHelperText>
            <br />

            <TextField
              variant="filled"
              defaultValue={
                settings?.organization?.value?.organizationNumber || ""
              }
              label="Organization number"
              placeholder="123456789"
              onChange={handleWorkspaceOrganizationNumberChanged}
            />
            <FormHelperText>
              An organization number if applicable.
            </FormHelperText>
            <Internationalization
              onChange={handleOnSettingChanged}
              currency={settings?.currency?.value}
              language={settings?.language?.value}
            />
            <HolidayCalendar
              name="holidaysCalendar"
              defaultValue={settings.holidaysCalendar.value}
              onChange={handleOnSettingChanged}
            />
            <br />
            <br />
            <WorkdaysCheckboxes
              name="workdays"
              defaultValue={settings.workdays.value}
              onChange={handleOnSettingChanged}
            />
          </CardContent>
        </Card>
      </section>
    </div>
  );
};
