import { all, put, takeLatest } from "@redux-saga/core/effects";
import {
  SAVE,
  SAVE_SUCCESS,
  SAVE_FAILURE
} from "../../../api/userSettings/constants";

import { save, saveSuccess, saveFailed } from "./actions";

export const handleSave = function*(args) {
  try {
    yield put(save());
  } catch (e) {
    console.warn(e);
  }
};

export const handleSaveSuccess = function*(args) {
  const { savedSettings } = args.payload;
  try {
    yield put(saveSuccess(savedSettings));
  } catch (e) {
    console.warn(e);
  }
};

export const handleSaveFailure = function*(args) {
  const { error } = args.payload;
  try {
    yield put(saveFailed(error));
  } catch (e) {
    console.warn(e);
  }
};

export const handleSaveSettingsSaga = function*() {
  yield takeLatest(SAVE, handleSave);
};

export const handleSaveSettingsSuccessSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleSaveSuccess);
};

export const handleSaveSettingsFailedSaga = function*() {
  yield takeLatest(SAVE_FAILURE, handleSaveFailure);
};

export default function*() {
  yield all([
    handleSaveSettingsSaga(),
    handleSaveSettingsSuccessSaga(),
    handleSaveSettingsFailedSaga()
  ]);
}
