import styled, { css } from "styled-components";
import { lighten } from "@material-ui/core";

export default styled.div`
  font-size: 20px;
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: ${props => props.theme.spacing(1)}px;

  em {
    text-decoration: none;
    font-style: normal;
    color: ${props => lighten(props.theme.palette.text.hint, 0.4)};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
  ${props =>
    props.align === "right"
      ? css`
          text-align: right;
        `
      : ""};
`;
