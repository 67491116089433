import styled, { css } from "styled-components";

export default styled.div`
  font-size: 14px;
  color: ${props => props.theme.palette.text.hint};
  margin-bottom: ${props => props.theme.spacing(1)}px;
  ${props =>
    props.align === "right"
      ? css`
          text-align: right;
        `
      : ""};
`;
