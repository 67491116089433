import {
  HIDE_EXPORT_DIALOG,
  SHOW_EXPORT_DIALOG,
  WATCH_EXPORT_INVOICE,
  SET_EXPORT_LOADING
} from "./constants";

export const watchExportInvoice = invoice => ({
  type: WATCH_EXPORT_INVOICE,
  payload: { invoice }
});

export const showExportDialog = invoice => ({
  type: SHOW_EXPORT_DIALOG,
  payload: { invoice }
});

export const hideExportDialog = () => ({
  type: HIDE_EXPORT_DIALOG,
  payload: {}
});

export const setExportLoading = isLoading => ({
  type: SET_EXPORT_LOADING,
  payload: { isLoading }
});
