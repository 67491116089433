import { SET_LOADING, SET_STATS_BILLABLE_MONTH } from "./constants";

const initialState = {
  billableHours: 0,
  billableHoursPrev: 0,
  hoursChange: 0,
  billableIncome: 0,
  billableIncomePrev: 0,
  incomeChange: 0,
  goal: null,
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload.isLoading
      };
    case SET_STATS_BILLABLE_MONTH:
      return {
        ...state,
        billableHours: payload.billableHours,
        billableHoursPrev: payload.billableHoursPrev,
        hoursChange: payload.hoursChange,
        billableIncome: payload.billableIncome,
        billableIncomePrev: payload.billableIncomePrev,
        incomeChange: payload.incomeChange,
        goal: payload.goal
      };
    default:
      return state;
  }
};
