export const SIGNED_IN = "@superprofit/core-redux-saga-modules/Auth/SIGNED_IN";
export const SIGNED_OUT = "@superprofit/core-redux-saga-modules/Auth/SIGNED_OUT";
export const SET_ID_TOKEN = "@superprofit/core-redux-saga-modules/Auth/SET_ID_TOKEN";
export const SET_ACTIVE_WORKSPACE = "@superprofit/core-redux-saga-modules/Auth/SET_ACTIVE_WORKSPACE";
export const SET_WORKSPACE = "@superprofit/core-redux-saga-modules/Auth/SET_WORKSPACE";
export const WORKSPACE_NO_ACCESS = "@superprofit/core-redux-saga-modules/Auth/WORKSPACE_NO_ACCESS";
export const WORKSPACE_JOINED = "@superprofit/core-redux-saga-modules/Auth/WORKSPACE_JOINED";
export const WORKSPACE_JOINING = "@superprofit/core-redux-saga-modules/Auth/WORKSPACE_JOINING";
export const WATCH_AUTH_STATE_CHANGED = "@superprofit/core-redux-saga-modules/Auth/WATCH_AUTH_STATE_CHANGED";
export const WATCH_GET_ID_TOKEN = "@superprofit/core-redux-saga-modules/Auth/WATCH_GET_ID_TOKEN";
export const WATCH_AUTH_ON_ID_TOKEN_CHANGED =
  "@superprofit/core-redux-saga-modules/Auth/WATCH_AUTH_ON_ID_TOKEN_CHANGED";
export const WATCH_AUTH_SIGN_OUT = "@superprofit/core-redux-saga-modules/Auth/WATCH_AUTH_SIGN_OUT";
