import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Paper } from "@material-ui/core";
import { Button } from "@superprofit/core-react-components/atoms";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import Snackbar from "@superprofit/core-react-components/atoms/Snackbar";
import { watchGetCustomerOverview } from "../../redux/modules/api/customerOverview/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Page, {
  PageActions,
  PageHeader,
  PageTitle
} from "../../components/atoms/Page";
import Skeleton from "@material-ui/lab/Skeleton";
import LinkButton from "../../components/atoms/LinkButton";
import { showCustomerDialog } from "../../redux/modules/ui/customers/dialog/actions";
import CustomerDialog from "./customers/CustomerDialog";
import Overview from "./customerOverview/Overview";
import AuthDialog from "./integrations/twentyFourSeven/AuthDialog";
import { useTranslation } from "react-i18next";
import { getPathWithKey } from "../../router/routes";

export default ({
  match: {
    params: { id }
  }
}) => {
  const [tab, setTab] = useState("overview");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const period = useSelector(state => state.ui.customerOverview2.period);

  const {
    data: { projects, customer },
    loading
  } = useSelector(state => state.ui.customerOverview2.basis);

  const snackbar = useSelector(state => state.ui.customerOverview2.snackbar);

  useEffect(() => {
    const { year, month } = period;
    dispatch(watchGetCustomerOverview(id, year, month));
  }, [period, dispatch]);

  const handleOnEdit = () => {
    dispatch(showCustomerDialog(customer));
  };

  const handleOnChangeTab = (e, val) => {
    setTab(val);
  };

  return (
    <Page>
      <PageHeader color={customer && customer.getColor && customer.getColor()}>
        <Grid
          container
          spacing={3}
          component="div"
          style={{ marginBottom: 4 }}
          justify="space-between"
        >
          <Grid item xs={6} component="div">
            <LinkButton
              color="inherit"
              startIcon={<ArrowBackIcon />}
              to={getPathWithKey("customers")}
            >
              {t("common.customers")}
            </LinkButton>
          </Grid>
          <Grid item xs={6} component="div">
            <PageActions>
              <Button
                disabled={!customer || loading}
                color="inherit"
                onClick={handleOnEdit}
              >
                {t("common.edit")}
              </Button>
            </PageActions>
          </Grid>
        </Grid>

        <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
          <Grid container item xs={12} component="div" justify="center">
            <PageTitle>
              {!customer || loading ? (
                <Skeleton
                  height={25}
                  width={200}
                  style={{ backgroundColor: "rgba(255,255,255,.5)" }}
                />
              ) : (
                customer.name
              )}
            </PageTitle>
          </Grid>
        </Grid>
      </PageHeader>
      <Paper square>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleOnChangeTab}
          aria-label="disabled tabs example"
        >
          <Tab label={t("common.overview")} value="overview" />
        </Tabs>
      </Paper>

      <Overview />

      <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={snackbar.visible}
          message={snackbar.text}
        />
      </AppBar>
      <AuthDialog />
      <CustomerDialog />
    </Page>
  );
};
