import { GET, GET_FAILURE, GET_SUCCESS, WATCH_GET } from "./constants";

export const get = () => ({
  type: GET,
  payload: {}
});

export const getSuccess = result => ({
  type: GET_SUCCESS,
  payload: { ...result }
});

export const getFailure = error => ({
  type: GET_FAILURE,
  payload: { error }
});

export const watchGetCustomerOverview = (id, year, month) => ({
  type: WATCH_GET,
  payload: { id, year, month }
});
