// import firebase from "./firebase";
//
// const db = firebase.firestore();
//
// db.settings({
//   /*
//   * [2020-03-01T14:25:57.665Z]  @firebase/firestore: Firestore (7.9.2):
//    The timestampsInSnapshots setting now defaults to true and you no
//    longer need to explicitly set it. In a future release, the setting
//    will be removed entirely and so it is recommended that you remove it
//    from your firestore.settings() call now.*/
//   // timestampsInSnapshots: true // not needed anymore
// });
//
// export default db;

import { db } from "./firebase";
export default db;
