import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@superprofit/core-react-components/atoms/Button";
import Card from "@superprofit/core-react-components/atoms/Card";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import FeatureNotImplementedDialog from "@superprofit/core-react-components/organisms/FeatureNotImplementedDialog";
import InvoicesTable from "./invoicesTable/InvoiceTable";
import { showInvoiceDialog } from "../../../redux/modules/ui/invoices/dialog/actions";
import { setSelectedInvoices } from "../../../redux/modules/ui/invoices/selection/actions";
import {
  watchDeleteInvoice,
  watchDeleteMultipleInvoices,
  watchUpdateInvoices
} from "../../../redux/modules/api/invoices/actions";
import { toggleView } from "../../../redux/modules/ui/invoices/view/actions";
import { watchExportInvoice } from "../../../redux/modules/ui/integrations/twentyfourseven/invoices/actions";
import { useTranslation } from "react-i18next";

const InvoicesCard = ({ invoices, loading, selection: selected, ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const snackbar = useSelector(state => state.ui.invoices.snackbar);
  const [notYetDialog, setNotYetDialog] = useState(false);

  const handleDeleteSelected = () => {
    dispatch(watchDeleteMultipleInvoices(selected.map(s => s.id)));
  };

  const handleOnExport = (e, data) => {
    dispatch(watchExportInvoice(data));
  };

  const handleChangePaid = (e, invoice) => {
    dispatch(watchUpdateInvoices([invoice], [{ id: invoice.id, paid: true }]));
  };

  const handleOnSetSelectedPaid = () => {
    const updates = selected.map(s => ({
      paid: true,
      id: s.id
    }));
    dispatch(watchUpdateInvoices(selected, updates));
  };

  const handleOnEdit = (e, data) => {
    const invoice = invoices.find(i => i.id === data.id);
    dispatch(showInvoiceDialog(invoice));
  };

  const handleOnDelete = (e, data) => {
    dispatch(watchDeleteInvoice(data.id));
  };

  const handleOnView = (e, data) => {
    dispatch(toggleView(data));
  };

  const handleOnSelectionChange = selection => {
    dispatch(setSelectedInvoices(selection));
  };

  const handleOnClearSelection = () => {
    dispatch(setSelectedInvoices([]));
  };

  return (
    <div>
      <Card {...rest}>
        <CardHeader title={t("common.invoices")} />

        <Divider />

        <InvoicesTable
          onEdit={handleOnEdit}
          onView={handleOnView}
          onDelete={handleOnDelete}
          onSelectionChange={handleOnSelectionChange}
          onChangePaid={handleChangePaid}
          onExport={handleOnExport}
          invoices={invoices}
          loading={loading}
          selection={selected}
        />
      </Card>

      <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={snackbar.visible}
          message={snackbar.text}
          action={
            snackbar.showActions &&
            selected.length > 0 && (
              <React.Fragment>
                <Button
                  color="inherit"
                  size="small"
                  onClick={handleOnClearSelection}
                >
                  {t("common.clearSelection")}
                </Button>
                <Button
                  color="alert"
                  size="small"
                  onClick={handleDeleteSelected}
                >
                  {t("common.delete")}
                </Button>
              </React.Fragment>
            )
          }
        />
      </AppBar>

      <FeatureNotImplementedDialog
        open={notYetDialog}
        onClose={e => setNotYetDialog(false)}
      />
    </div>
  );
};

export default InvoicesCard;
