import theme from "styled-theming";
import styled, { css } from "styled-components";
import { darken } from "../../themes/shared/colorUtils";
import { Chip, ChipProps } from "@material-ui/core";

const variants = theme.variants("mode", "color", {
  default: {
    light: css`
      color: ${props => props.theme.palette.text.primary};
      background: ${props =>
        darken(props.theme.palette.background.default, 0.05)};
    `,
    dark: css``
  }
});

// any here because of too much trouble to get the types for variant and theme.variants right
export const StyledChip = styled(Chip)<any>`
  ${variants}
`;
