import {
  HIDE_GOAL_DIALOG,
  SET_SAVE_STATE,
  SHOW_GOAL_DIALOG,
  UPDATE_GOAL_DIALOG_DATA
} from "./constants";

export const showGoalDialog = goal => ({
  type: SHOW_GOAL_DIALOG,
  payload: { goal }
});

export const hideGoalDialog = () => ({
  type: HIDE_GOAL_DIALOG,
  payload: {}
});

export const updateGoalDialogData = updates => ({
  type: UPDATE_GOAL_DIALOG_DATA,
  payload: { updates }
});

export const setSaveState = isSaving => ({
  type: SET_SAVE_STATE,
  payload: { isSaving }
});
