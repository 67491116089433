import React from "react";
import FormDialog from "@superprofit/core-react-components/molecules/FormDialog";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLeadDialog,
  updateLeadDialogData
} from "../../../redux/modules/ui/leads/dialog/actions";
import { watchSaveLead } from "../../../redux/modules/api/leads/actions";
import { useTranslation } from "react-i18next";

export default ({ ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const open = useSelector(state => state.ui.leads.dialog.open);
  const isSaving = useSelector(state => state.ui.leads.saving);
  const lead = useSelector(state => state.ui.leads.dialog.lead);
  const updates = useSelector(state => state.ui.leads.dialog.updates);

  const handleOnClose = e => {
    if (isSaving) return;
    dispatch(hideLeadDialog());
  };

  const handleOnBlur = (e, key) => {
    dispatch(updateLeadDialogData({ [key]: e.target.value }));
  };

  const handleOnBlurTags = (e, key) => {
    let val = e.target.value;
    val = val
      .split(" ")
      .join(",")
      .split(",");
    dispatch(updateLeadDialogData({ tags: val }));
  };

  const handleOnSave = () => {
    dispatch(watchSaveLead(lead, updates));
  };

  return (
    <FormDialog
      {...rest}
      saveActionTitle={lead.id ? "Update" : "Save"}
      onSave={handleOnSave}
      open={open}
      onClose={handleOnClose}
      title="Add lead"
      inProgress={isSaving}
    >
      <TextField
        defaultValue={lead.name}
        fullWidth
        variant="filled"
        label="Company name"
        placeholder="Umbrella corp"
        onBlur={e => handleOnBlur(e, "name")}
      />
      <TextField
        defaultValue={lead.email}
        fullWidth
        variant="filled"
        label="Email"
        placeholder="john.doe@timet.io"
        onBlur={e => handleOnBlur(e, "email")}
      />
      <TextField
        defaultValue={lead.contactName}
        fullWidth
        variant="filled"
        label="Contact name"
        placeholder="John Doe"
        onBlur={e => handleOnBlur(e, "contactName")}
      />
      <TextField
        defaultValue={lead.description}
        fullWidth
        variant="filled"
        label="Description"
        placeholder=""
        onBlur={e => handleOnBlur(e, "description")}
        multiline
        rows={4}
      />
      <TextField
        defaultValue={(lead.tags || []).join(" ")}
        fullWidth
        variant="filled"
        label={t("common.tags")}
        placeholder="List of tags"
        onBlur={handleOnBlurTags}
      />
    </FormDialog>
  );
};
