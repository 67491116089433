import React, { Fragment, useEffect, useState } from "react";
import FormDialog from "@superprofit/core-react-components/molecules/FormDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  Typography,
  FormControlLabel,
  FormHelperText,
  Avatar
} from "@superprofit/core-react-components/atoms";
import ConfirmationDialog from "../../../components/molecules/ConfirmationDialog";
import InputLabel from "@superprofit/core-react-components/atoms/InputLabel";
import Grid from "@superprofit/core-react-components/atoms/Grid";
import {
  hideAccessControlDialog,
  updateAccessControlDialogData,
  watchSaveUserAccess
} from "../../../redux/modules/ui/users/accessControlDialog/actions";
import Tooltip from "@superprofit/core-react-components/atoms/Tooltip";
import { AvatarGroup } from "./assignProjectsDialog/AssignProjectsDialog.styles";
import { useTranslation } from "react-i18next";

export default ({ ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);
  const { open, saving: isSaving, users, updates, hasUnsaved } = useSelector(
    state => state.ui.users.accessControlDialog
  );
  const userAccessList = useSelector(state => state.api.userAccess.list);

  const access = userAccessList
    .filter(ua => users.findIndex(us => us.email === ua.id) > -1)
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  const handleConfirmClose = () => {
    dispatch(hideAccessControlDialog());
  };

  const handleCancelClose = () => {
    setShowUnsavedConfirmation(false);
  };

  const handleOnClose = e => {
    if (isSaving) return;
    if (hasUnsaved) {
      setShowUnsavedConfirmation(true);
    } else {
      handleConfirmClose();
    }
  };

  const handleOnSave = () => {
    dispatch(watchSaveUserAccess());
  };

  const handleChangeIsActive = e => {
    dispatch(updateAccessControlDialogData({ isActive: e.target.checked }));
  };
  const handleChangeIsAdmin = e => {
    dispatch(updateAccessControlDialogData({ isAdmin: e.target.checked }));
  };

  const hasChanges =
    (updates.isAdmin !== undefined && updates.isAdmin !== access?.isAdmin) ||
    (updates.isActive !== undefined && updates.isActive !== access?.isActive);

  return (
    <Fragment>
      <ConfirmationDialog
        key="unsaved"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showUnsavedConfirmation}
      />

      <FormDialog
        {...rest}
        key="form"
        saveActionTitle="Update"
        onSave={handleOnSave}
        open={open}
        onClose={handleOnClose}
        title="Assign roles"
        inProgress={isSaving}
        disabled={!hasChanges}
      >
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <InputLabel variant="standard" shrink={false}>
                    User{users.length > 1 ? "s" : ""}
                  </InputLabel>{" "}
                </Grid>
                <Grid item xs={12}>
                  {users.length > 1 ? (
                    <Tooltip
                      title={users.reduce(
                        (acc, curr) => `${acc}${curr?.email || ""},`,
                        ""
                      )}
                      aria-label="More"
                    >
                      <AvatarGroup max={4}>
                        {users.map(u => (
                          <Avatar alt={u?.displayName} src={u?.picture}>
                            {(u?.displayName || u?.email || "")[0]}
                          </Avatar>
                        ))}
                      </AvatarGroup>
                    </Tooltip>
                  ) : (
                    <Typography>{users[0]?.email}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      updates.isAdmin !== undefined
                        ? updates.isAdmin
                        : !!access?.isAdmin
                    }
                    onChange={handleChangeIsAdmin}
                    name="isAdmin"
                  />
                }
                label="Administrator"
              />
              <FormHelperText>
                Administrators have extended access.
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      updates.isActive !== undefined
                        ? updates.isActive
                        : !!access?.isActive
                    }
                    onChange={handleChangeIsActive}
                    name="isActive"
                  />
                }
                label={t("common.active")}
              />
              <FormHelperText>
                Deactivated users will not be able to log in and does not count
                as part of your subscription.
              </FormHelperText>
            </Grid>
          </Grid>
        </form>
      </FormDialog>
    </Fragment>
  );
};
