import handlePaymentThatRequiresCustomerAction from "./handlePaymentThatRequiresCustomerAction";
import onSubscriptionComplete from "./onSubscriptionComplete";
import { actionTypes } from "../../reducer";

const retry = async ({
  apiBase,
  customerId,
  paymentMethodId,
  invoiceId,
  firebaseIdToken,
}) => {
  const response = await fetch(`${apiBase}/retryInvoice`, {
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: firebaseIdToken,
    },
    body: JSON.stringify({
      customerId,
      paymentMethodId,
      invoiceId,
    }),
  });
  if (!response.ok) {
    return {
      error: `[retry-invoice] Status ${response.status} - ${response.statusText}`,
    };
  }
  return response.json();
};

const retryInvoiceWithNewPaymentMethod = async (args) => {
  const { paymentMethodId, priceId } = args;
  const response = await retry(args);

  if (response.error) {
    dispatch({
      type: actionTypes.setCreatedSubscriptionStatus,
      payload: {
        message:
          typeof response.error === "string"
            ? response.error
            : response.error.toString(),
        status: "error",
      },
    });
  }

  const normalizedResponse = {
    // Use the Stripe 'object' property on the
    // returned result to understand what object is returned.
    invoice: response,
    paymentMethodId,
    priceId,
    isRetry: true,
  };

  const customerActionResponse = await handlePaymentThatRequiresCustomerAction({
    ...args,
    ...normalizedResponse,
  });
  if (customerActionResponse) {
    await onSubscriptionComplete(customerActionResponse);
  }
};

export default retryInvoiceWithNewPaymentMethod;
