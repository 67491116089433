import useWorkspace from "./useWorkspace";
import { useSelector } from "react-redux";
import { RootState } from "../index";

export const useIsUserAdmin = () => {
  const workspace = useWorkspace();
  const workspaces = useSelector(
    (state: RootState) => state.api.auth.idTokenResult?.claims?.workspaces
  );

  if (!workspaces) {
    return false;
  }
  const workspaceClaims = workspaces[workspace];
  return workspaceClaims?.isAdmin;
};
