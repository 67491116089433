import Grid from "@material-ui/core/Grid";
import { Button, Card, NumberFormatIntl } from "../../../atoms";
import CardContent from "../../../atoms/CardContent";
import Typography from "../../../atoms/Typography";
import React, { useEffect, useReducer, createContext } from "react";
import UpdatePaymentMethod from "./UpdatePaymentMethod";
import UpdateSubscription from "./UpdateSubscription";
import CreateSubscription from "./CreateSubscription";
import retrieveUpcomingInvoice from "./updateSubscription/retrieveUpcomingInvoice";
import moment from "moment";
import retrieveSubscriptionInformation from "./summary/retrieveSubscriptionInformation";
import Skeleton from "@material-ui/lab/Skeleton";
import reducer, { actionTypes, init } from "./reducer";
import Context from "./context";
import cancelSubscription from "./cancelSubscription";
import CancelSubscriptionButton from "./CancelSubscriptionButton";

export default ({ filterProductsFunc = (f) => f, ...initials }) => {
  const [state, dispatch] = useReducer(reducer, initials, init);
  const {
    isLoadingProducts,
    showCreateSubscription,
    showUpdatePaymentMethod,
    showUpdateSubscription,
    hasSubscription,
    baseUrl,
    firebaseIdToken,
    customerId,
    products,
    subscriptions,
    subscriptionInformation,
    upcomingInvoice,
    isLoadingSubscriptions,
  } = state;

  const initialize = () => {
    let isCancelled = false;
    const d = (args) => !isCancelled && dispatch(args);
    if (baseUrl && customerId && firebaseIdToken) {
      const getData = async () => {
        // dispatch({ type: actionTypes.toggleLoadingProducts });
        const productsResponse = await fetch(
          `${baseUrl}/getProducts?expand=data.prices`
        );
        if (!productsResponse.ok) {
          d(
            {
              type: actionTypes.setFetchProductsError,
            }`Status ${productsResponse.status} - ${productsResponse.statusText}`
          );
          d({ type: actionTypes.toggleLoadingProducts });
          return;
        }
        const productsJson = await productsResponse.json();
        const products = productsJson.filter(filterProductsFunc);
        for (let product of products) {
          const priceResponse = await fetch(
            `${baseUrl}/getPrices?expand=data.tiers&product=${product.id}`
          );
          if (!priceResponse.ok) {
            d(
              {
                type: actionTypes.setFetchProductsError,
              }`Status ${productsResponse.status} - ${productsResponse.statusText}`
            );
            d({ type: actionTypes.toggleLoadingProducts });
            return;
          }
          product.prices = await priceResponse.json();
        }
        const subscriptionsResponse = await fetch(
          `${baseUrl}/getCustomerSubscriptions?customerId=${customerId}`,
          {
            headers: {
              authorization: firebaseIdToken,
            },
          }
        );
        if (subscriptionsResponse.ok) {
          const subs = await subscriptionsResponse.json();
          if (subs?.data?.length > 0) {
            const subscription = subs.data[0];
            const getInformation = () =>
              retrieveSubscriptionInformation({
                apiBase: baseUrl,
                token: firebaseIdToken,
                subscriptionId: subscription.id,
                customerId: subscription.customer,
              });
            const subscriptionInformation = await getInformation();
            d({
              type: actionTypes.setSubscriptions,
              payload: { subscriptions: subs.data },
            });
            d({
              type: actionTypes.setSubscriptionInformation,
              payload: { subscriptionInformation },
            });
            d({
              type: actionTypes.toggleHasSubscription,
            });
          } else {
            d({
              type: actionTypes.toggleShowCreateSubscription,
            });
          }
          d({
            type: actionTypes.toggleLoadingSubscriptions,
          });
        }
        d({ type: actionTypes.setProducts, payload: { products } });
        d({ type: actionTypes.toggleLoadingProducts });
      };
      getData().catch((e) => console.error(e));
    }
    return () => (isCancelled = true);
  };

  useEffect(initialize, [customerId, firebaseIdToken, baseUrl]);

  const subscription = subscriptionInformation?.subscription;
  const product = subscription?.plan.product;
  const brand = subscriptionInformation?.subscription?.customer?.invoice_settings?.default_payment_method?.card?.brand?.toUpperCase();
  const last4 =
    subscriptionInformation?.subscription?.customer?.invoice_settings
      ?.default_payment_method?.card.last4;
  const contactName =
    subscription?.customer?.invoice_settings?.default_payment_method
      ?.billing_details?.name || subscription?.customer.name;
  const contactEmail =
    subscription?.customer?.invoice_settings?.default_payment_method
      ?.billing_details?.email || subscription?.customer.email;
  useEffect(() => {
    dispatch({ type: actionTypes.init, payload: initials });
  }, [initials?.customerId, initials?.baseUrl, initials?.firebaseIdToken]);

  const loading = isLoadingProducts || isLoadingSubscriptions;
  return (
    <Context.Provider
      value={{
        initialize,
        reset: () => dispatch({ type: actionTypes.init, payload: initials }),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {hasSubscription || loading ? (
                <>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        variant="fontWeightMedium"
                        color="textSecondary"
                      >
                        {!loading ? "Summary" : <Skeleton width={100} />}
                      </Typography>
                    </Grid>
                    {!subscription?.cancel_at_period_end && (
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          {!loading ? (
                            <>
                              {" "}
                              You are subscribed to{" "}
                              <b>{subscription?.quantity}</b> user(s) on the{" "}
                              <b>{product?.name}</b> plan
                            </>
                          ) : (
                            <Skeleton width={200} />
                          )}
                        </Typography>
                      </Grid>
                    )}
                    {subscription?.cancel_at_period_end && (
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          You have cancelled your subscription.
                        </Typography>
                      </Grid>
                    )}
                    {subscription?.cancel_at_period_end && (
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          It will stop running at{" "}
                          <b>
                            {moment
                              .unix(subscription?.current_period_end)
                              .format("DD/MM/YYYY")}
                          </b>
                          .{" "}
                        </Typography>
                      </Grid>
                    )}
                    {subscription?.cancel_at_period_end && (
                      <Grid item xs={12}>
                        <Typography
                          gutterBottom
                          variant="body2"
                          color="textSecondary"
                        >
                          You may reactivate at any time before given date.
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {!loading ? (
                          <>
                            Your last payment was{" "}
                            <b>
                              <NumberFormatIntl
                                locales="en"
                                number={
                                  subscriptionInformation?.subscription
                                    ?.latest_invoice?.amount_paid / 100
                                }
                                options={{
                                  style: "currency",
                                  currency: subscriptionInformation?.subscription?.latest_invoice?.currency?.toUpperCase(),
                                }}
                              />
                            </b>
                          </>
                        ) : (
                          <Skeleton width={100} />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {typeof subscriptionInformation?.upcoming_invoice
                        ?.amount_due === "number" &&
                        !subscription?.cancel_at_period_end &&
                        !loading && (
                          <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {"Your next payment of "}
                              <b>
                                <NumberFormatIntl
                                  locales="en"
                                  number={
                                    subscriptionInformation?.upcoming_invoice
                                      ?.amount_due / 100
                                  }
                                  options={{
                                    style: "currency",
                                    currency: subscriptionInformation?.upcoming_invoice?.currency?.toUpperCase(),
                                  }}
                                />
                              </b>
                              {" will be due "}
                              <b>
                                {moment
                                  .unix(
                                    subscriptionInformation?.upcoming_invoice
                                      ?.next_payment_attempt
                                  )
                                  .format("DD/MM/YYYY")}
                              </b>
                            </Typography>
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                  { (loading || last4) && (
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography variant="body2" color="textSecondary">
                          {!loading ? "Credit card" : <Skeleton width={100} />}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" color="textSecondary">
                          {!loading ? (
                            <>
                              {brand?.toUpperCase()} **** {last4}
                            </>
                          ) : (
                            <Skeleton width={100} />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography variant="body2" color="textSecondary">
                        {!loading ? "Contact name" : <Skeleton width={100} />}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="textSecondary">
                        {!loading ? contactName : <Skeleton width={100} />}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography variant="body2" color="textSecondary">
                        {!loading ? "Contact email" : <Skeleton width={100} />}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="textSecondary">
                        {!loading ? contactEmail : <Skeleton width={100} />}
                      </Typography>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container justify="space-between">
                    {!subscription?.cancel_at_period_end && (
                      <Grid item xs={12} sm={6}>
                        {" "}
                        <Grid container spacing={2}>
                          <Grid item>
                            {!loading ? (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  dispatch({
                                    type:
                                      actionTypes.toggleShowUpdateSubscription,
                                  })
                                }
                              >
                                Update subscription
                              </Button>
                            ) : (
                              <Skeleton width={150} />
                            )}
                          </Grid>
                          <Grid item>
                            {!loading ? (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  dispatch({
                                    type:
                                      actionTypes.toggleShowUpdatePaymentMethod,
                                  })
                                }
                              >
                                Update payment method
                              </Button>
                            ) : (
                              <Skeleton width={150} />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    <Grid item>
                      {!loading ? (
                        <CancelSubscriptionButton
                          dispatch={dispatch}
                          state={state}
                        />
                      ) : (
                        <Skeleton width={150} />
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                      You have no active subscription.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                      Purchase a subscription below to give more users access to
                      your workspace.
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
        <UpdateSubscription state={state} dispatch={dispatch} />
        <UpdatePaymentMethod state={state} dispatch={dispatch} />
        <CreateSubscription state={state} dispatch={dispatch} />
      </Grid>
    </Context.Provider>
  );
};
