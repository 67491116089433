import { SHOW_AUTH_DIALOG, HIDE_AUTH_DIALOG } from "./constants";

export const initialState = {
  dialog: {
    open: false
  },
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_AUTH_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          open: true
        }
      };
    case HIDE_AUTH_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          open: false
        }
      };
    default:
      return state;
  }
};
