import { db } from "../firebase";
import { QueryBuilder, Timestamp } from "@superprofit/core-firestore-models";
import { firestore } from "firebase";

interface IXstatsUserProjectHoursByMonth {
  id: string;
  month: number;
  project: string;
  total: number;
  user: string;
  year: number;
}

export default class XstatsUserProjectHoursByMonth
  implements IXstatsUserProjectHoursByMonth {
  id: string;
  month: number;
  project: string;
  total: number;
  user: string;
  year: number;

  static collectionName = "xstats_user_project_hours_by_month";

  static converter = {
    toFirestore(customer: XstatsUserProjectHoursByMonth) {
      return customer.data();
    },
    fromFirestore(
      snapshot: firestore.QueryDocumentSnapshot,
      options: firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options) as IXstatsUserProjectHoursByMonth;
      return new XstatsUserProjectHoursByMonth({ ...data, id: snapshot.id });
    }
  };

  constructor({
    id,
    month,
    project,
    total,
    year,
    user
  }: IXstatsUserProjectHoursByMonth) {
    this.id = id;
    this.month = month;
    this.project = project;
    this.total = total;
    this.user = user;
    this.year = year;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  data() {
    return {
      month: this.month,
      project: this.project,
      total: this.total,
      user: this.user,
      year: this.year
    };
  }

  static listMonth = async (workspace: string, month: number, year: number) => {
    const snapshot = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(XstatsUserProjectHoursByMonth.collectionName)
      .withConverter(XstatsUserProjectHoursByMonth.converter)
      .where("month", "==", month)
      .where("year", "==", year)
      .get();

    return snapshot.docs.map(doc => doc.data());
  };

  static listMonthUser = async (
    workspace: string,
    month: number,
    year: number,
    user: string
  ) => {
    const snapshot = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(XstatsUserProjectHoursByMonth.collectionName)
      .where("month", "==", month)
      .where("year", "==", year)
      .where("user", "==", user)
      .withConverter(XstatsUserProjectHoursByMonth.converter)
      .get();

    return snapshot.docs.map(doc => doc.data());
  };
}
