import React, { Fragment, useEffect, useState } from "react";
import FormDialog from "@superprofit/core-react-components/molecules/FormDialog";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  init,
  hideInvoiceDialog,
  updateInvoiceDialogData
} from "../../../redux/modules/ui/invoices/dialog/actions";
import { Autocomplete } from "@superprofit/core-react-components/atoms";
import { KeyboardDatePicker } from "@superprofit/core-react-components/atoms/pickers";
import InputLabel from "@superprofit/core-react-components/atoms/InputLabel";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import InvoiceCard from "./InvoiceCard";
import PeriodSelect from "../../../components/molecules/PeriodSelect";
import { Timestamp } from "@superprofit/core-firestore-models";
import { isEmptyObject } from "../../../helpers";
import AutocompleteProjects from "./AutocompleteProjects";
import { watchSaveInvoice } from "../../../redux/modules/api/invoices/actions";
import ConfirmationDialog from "../../../components/molecules/ConfirmationDialog";
import { useTranslation } from "react-i18next";

export default ({ ...rest }) => {
  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { open, saving: isSaving, hasUnsaved } = useSelector(
    state => state.ui.invoices.dialog
  );
  const customers = useSelector(state => state.api.customers.list);
  const invoice = useSelector(
    ({
      ui: {
        invoices: { dialog }
      }
    }) => !isEmptyObject(dialog.invoice) && dialog.invoice
  );
  const updates = useSelector(
    ({
      ui: {
        invoices: { dialog }
      }
    }) => !isEmptyObject(dialog.updates) && dialog.updates
  );

  useEffect(() => {
    dispatch(init());
  }, [dispatch, open]);

  const projects = useSelector(state =>
    state.api.projects.list.filter(p => p.billable)
  );

  if ((!invoice && !updates) || !open) return null; //loader? // not initialized

  const handleConfirmClose = () => {
    dispatch(hideInvoiceDialog());
  };

  const handleCancelClose = () => {
    setShowUnsavedConfirmation(false);
  };

  const handleOnClose = e => {
    if (isSaving) return;
    if (hasUnsaved) {
      setShowUnsavedConfirmation(true);
    } else {
      handleConfirmClose();
    }
  };

  const customerOptions = customers.sort(
    (a, b) => -b.name?.localeCompare(a.name)
  );

  const handleOnSave = () => {
    dispatch(watchSaveInvoice(invoice, updates));
  };

  const handleOnChangeCustomer = (e, customer) => {
    dispatch(updateInvoiceDialogData({ customer }));
  };

  const handleOnChangeProjects = (e, projects) => {
    dispatch(updateInvoiceDialogData({ projects }));
  };

  const handleDateChange = (date, key) => {
    dispatch(updateInvoiceDialogData({ [key]: Timestamp.fromMoment(date) }));
  };

  const handlePeriodChange = ({ year, month }) => {
    dispatch(updateInvoiceDialogData({ year, month }));
  };

  const issueDateValue = Timestamp.toMoment(
    updates.issueDate || invoice.issueDate
  )?.format();

  const dueDateValue = Timestamp.toMoment(
    updates.dueDate || invoice.dueDate
  )?.format();

  const month = updates.month || invoice.month;
  const year = updates.year || invoice.year;

  const selectedCustomer = updates.customer || invoice.customer;

  return (
    <Fragment>
      <ConfirmationDialog
        key="unsaved"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showUnsavedConfirmation}
      />

      <FormDialog
        {...rest}
        key="form"
        saveActionTitle={invoice?.id ? "Update" : "Save"}
        onSave={handleOnSave}
        open={open}
        onClose={handleOnClose}
        title={`${t("common.add")} ${t("common.invoice")}`}
        inProgress={isSaving}
      >
        <PeriodSelect
          period={{ year, month }}
          showWeek={false}
          onChange={handlePeriodChange}
          disabled={isSaving || !!invoice}
        />

        <Autocomplete
          getOptionSelected={(option, val) => {
            return option.id === val.id;
          }}
          options={customerOptions}
          renderOption={option => option.name}
          getOptionLabel={option => option.name}
          inputValue={(updates.customer || invoice.customer || {}).name || ""}
          value={updates.customer || invoice.customer || null}
          onChange={handleOnChangeCustomer}
          style={{ width: 300 }}
          disabled={isSaving || !!invoice}
          renderInput={params => (
            <TextField
              {...params}
              label={t("common.customer")}
              variant="filled"
            />
          )}
        />

        <AutocompleteProjects
          getOptionSelected={(option, val) => {
            return option.id === val.id;
          }}
          defaultValue={invoice ? invoice.projects : undefined}
          disabled={isSaving || !!invoice || !selectedCustomer}
          projects={projects.filter(
            p => selectedCustomer && p.customer === selectedCustomer.id
          )}
          label={t("common.projects")}
          variant="filled"
          fullWidth
          onChange={handleOnChangeProjects}
        />

        <InputLabel
          variant="standard"
          shrink={false}
          id="invoice-label-issue-date"
        >
          {t("common.issueDate")}
        </InputLabel>
        <KeyboardDatePicker
          margin="normal"
          inputVariant="filled"
          format="DD/MM/YYYY"
          value={issueDateValue}
          onChange={date => handleDateChange(date, "issueDate")}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
        />
        <InputLabel
          variant="standard"
          shrink={false}
          id="invoice-label-due-date"
        >
          {t("common.dueDate")}
        </InputLabel>
        <KeyboardDatePicker
          margin="normal"
          inputVariant="filled"
          format="DD/MM/YYYY"
          value={dueDateValue}
          onChange={date => handleDateChange(date, "dueDate")}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
        />
        <Divider />
        <InvoiceCard invoice={{ ...invoice, ...updates }} />
      </FormDialog>
    </Fragment>
  );
};
