import {
  DESELECT_ALL,
  DESELECT_USER,
  SELECT_ALL,
  SELECT_USER
} from "./constants";
import { action } from "typesafe-actions";
import User from "../../../../../models/User";

export const selectUser = (user: User) => action(SELECT_USER, { user });

export const deselectUser = (user: User) => action(DESELECT_USER, { user });

export const selectAllUsers = (users: User[]) => action(SELECT_ALL, { users });

export const deselectAllUsers = () => action(DESELECT_ALL);
