import { HIDE_DIALOG, SHOW_DIALOG } from "./constants";
import { DialogAction, DialogState } from "./types";
import { createReducer } from "typesafe-actions";

const initialState: DialogState = {
  open: false
};

const reducer = createReducer<DialogState, DialogAction>(initialState)
  .handleType(HIDE_DIALOG, state => ({ ...state, open: false }))
  .handleType(SHOW_DIALOG, state => ({
    ...state,
    open: true
  }));

export default reducer;
