import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { GET_SUCCESS } from "../../../api/companyDashboard/constants";
import { SETTING_TYPES } from "../../../../../models/CompanySetting";
import { setActiveGoal } from "./actions";

export const getStats = ({
  api: {
    companyStats: { stats }
  }
}) => stats;

export const getPeriod = ({
  ui: {
    dashboard: { period }
  }
}) => period;

export const getCompanySettings = ({
  api: {
    companySettings: { settings }
  }
}) => settings;

export const getGoal = (year, month, settings) => {
  let goal = null;
  Object.keys(settings).forEach(key => {
    const setting = settings[key];
    if (setting.type === SETTING_TYPES.PERFORMANCE_GOAL) {
      if (setting.year === year && setting.month === month) {
        goal = setting;
      }
      if (!goal && setting.year === year && setting.month === "*") {
        goal = setting;
      }
    }
  });
  return goal;
};

export const handleGetSuccess = function*(args) {
  try {
    const { year, month } = yield select(getPeriod);
    const companySettings = yield select(getCompanySettings);
    const goal = getGoal(year, month, companySettings);
    yield put(setActiveGoal(goal));
  } catch (e) {
    console.warn(e);
  }
};

export const handleGetSuccessSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleGetSuccess);
};

export default function*() {
  yield all([handleGetSuccessSaga()]);
}
