import { all, delay, put, takeLatest, select } from "@redux-saga/core/effects";
import {
  BATCH_UPDATE,
  BATCH_UPDATE_SUCCESS,
  SAVE,
  SAVE_SUCCESS
} from "../../../api/invoices/constants";
import { updateSnackbar, watchRefreshSnackbar } from "./actions";
import { SET_SELECTED_INVOICES } from "../selection/constants";
import { WATCH_REFRESH_SNACKBAR } from "./constants";

export const getSelection = state => state.ui.invoices.selection.selected;

export const handleInvoiceSaving = function*(args) {
  try {
    yield put(
      updateSnackbar({ visible: true, text: "Saving", showActions: false })
    );
  } catch (e) {
    console.warn(e);
  }
};

export const handleInvoiceSaved = function*() {
  try {
    yield put(
      updateSnackbar({
        visible: true,
        text: "All changes saved",
        showActions: false
      })
    );
    yield delay(2000);
    const selected = yield select(getSelection);
    if (selected && selected.length > 0) {
      yield put(watchRefreshSnackbar());
    } else {
      yield put(updateSnackbar({ visible: false }));
    }
  } catch (e) {
    console.warn(e);
  }
};

export const handleRefresh = function*(args) {
  try {
    const selected = yield select(getSelection);
    yield put(
      updateSnackbar({
        showActions: true,
        visible: selected.length > 0,
        text: `${selected.length} invoice(s) selected`
      })
    );
  } catch (e) {
    console.warn(e);
  }
};

export const dispatchRefresh = function*(args) {
  try {
    yield put(watchRefreshSnackbar());
  } catch (e) {
    console.warn(e);
  }
};

export const handleInvoiceSavingSaga = function*() {
  yield takeLatest([BATCH_UPDATE, SAVE], handleInvoiceSaving);
};

export const handleInvoiceSavedSaga = function*() {
  yield takeLatest([BATCH_UPDATE_SUCCESS, SAVE_SUCCESS], handleInvoiceSaved);
};

export const handleTableSelectionChangeSaga = function*() {
  yield takeLatest([SET_SELECTED_INVOICES], dispatchRefresh);
};

export const handleRefreshSnackbar = function*() {
  yield takeLatest(WATCH_REFRESH_SNACKBAR, handleRefresh);
};

export default function*() {
  yield all([
    handleInvoiceSavingSaga(),
    handleInvoiceSavedSaga(),
    handleTableSelectionChangeSaga(),
    handleRefreshSnackbar()
  ]);
}
