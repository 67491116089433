import { all, put, select, takeLatest } from "@redux-saga/core/effects";
import { GET_SUCCESS, GET } from "../../../api/companyDashboard/constants";
import {
  getWorkingDays,
  getWorkingDaysTD
} from "../../../../../utils/WorkingDays";
import { moment } from "@superprofit/time-util";
import { setPrognosis, setLoading } from "./actions";
import { mapRatesWithProjects } from "../utils";

export const getPeriod = ({
  ui: {
    dashboard: { period }
  }
}) => period;

export const getStats = ({
  api: {
    companyStats: { stats }
  }
}) => stats;

export const getCompanySettings = ({
  api: {
    companySettings: { settings }
  }
}) => settings;

export const getProjects = ({
  api: {
    projects: { list }
  }
}) => list;

export const getMonthBefore = (year, month) => {
  return {
    year: month === 1 ? year - 1 : year,
    month: month === 1 ? 12 : month - 1
  };
};

const getBasis = (data, year, month, projectSet) => {
  let workingHours = 0;
  let income = 0;

  data
    .filter(
      stats => projectSet[stats.project] && projectSet[stats.project].billable
    )
    .filter(stats => stats.month === month)
    .filter(stats => stats.year === year)
    .forEach(stats => {
      workingHours += stats.total;
      income +=
        stats.total *
        ((projectSet[stats.project] &&
          projectSet[stats.project].getBillableRate(stats.user)) ||
          0);
    });

  const avgRate = income / workingHours;

  return { income, workingHours, avgRate };
};

const getActualTD = (year, month, raw, projectSet) => {
  const { income } = getBasis(raw, year, month, projectSet);
  return income;
};

const getPrognosis = (
  year,
  month,
  raw,
  monthBeforeData,
  holidaysCalendar,
  workdays,
  projectSet
) => {
  const monthBefore = getMonthBefore(year, month);

  const { workingHours, avgRate } = getBasis(
    monthBeforeData,
    monthBefore.year,
    monthBefore.month,
    projectSet
  );

  const workingDaysPrevMonth = getWorkingDays(
    monthBefore.year,
    monthBefore.month,
    holidaysCalendar,
    workdays
  );

  const billableHoursPerDay = workingHours / workingDaysPrevMonth;
  const billableHoursTotalMonth =
    billableHoursPerDay *
    getWorkingDays(year, month, holidaysCalendar, workdays);

  return avgRate * billableHoursTotalMonth;
};

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const handleGetSuccess = function*(args) {
  try {
    const projects = yield select(getProjects);

    const projectSet = projects.reduce(
      (prev, next) => ({
        ...prev,
        [next.id]: next
      }),
      {}
    );
    const { raw, monthBefore } = yield select(getStats);
    const companySettings = yield select(getCompanySettings);
    const { year, month } = yield select(getPeriod);

    const holidaysCalendar = companySettings.holidaysCalendar.value;
    const workdays = companySettings.workdays.value;

    const mappedRaw = mapRatesWithProjects(raw, projects);
    const mappedMonthBefore = mapRatesWithProjects(monthBefore, projects);

    const prognosis = getPrognosis(
      year,
      month,
      mappedRaw,
      mappedMonthBefore,
      holidaysCalendar,
      workdays,
      projectSet
    );
    const actualTD = getActualTD(year, month, mappedRaw, projectSet);

    const workingDaysTD = getWorkingDaysTD(
      year,
      month,
      moment(),
      holidaysCalendar,
      workdays
    );

    const workingDaysMonth = getWorkingDays(
      year,
      month,
      holidaysCalendar,
      workdays
    );

    const adjustedPrognosis =
      actualTD + (1 - workingDaysTD / workingDaysMonth) * prognosis;

    yield put(setPrognosis(prognosis, adjustedPrognosis, actualTD));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleGetSuccessSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleGetSuccess);
};

export const handleGetSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export default function*() {
  yield all([handleGetSuccessSaga(), handleGetSaga()]);
}
