import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../index";
import { setPeriod } from "../../../../../redux/modules/ui/workspaceOverview/period/actions";
import usePeriod from "./usePeriod";

const usePrevMonthPeriodPeriod = () => {
  const period = usePeriod();
  return period.month === 1
    ? { month: 12, year: period.year - 1 }
    : { ...period, month: period.month - 1 };
};

export default usePrevMonthPeriodPeriod;
