import { SET_LOADING, SET_STATS_BILLABLE_MONTH } from "./constants";

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const setBillableMonth = (
  billableHours,
  billableHoursPrev,
  hoursChange,
  billableIncome,
  billableIncomePrev,
  incomeChange,
  goal
) => ({
  type: SET_STATS_BILLABLE_MONTH,
  payload: {
    billableHours,
    billableHoursPrev,
    hoursChange,
    billableIncome,
    billableIncomePrev,
    incomeChange,
    goal
  }
});
