import React from "react";
import { StyledInfoBox, StyledInfoBoxActions } from "./InfoBox.styles";

const InfoBox = ({ title, message, actions, ...rest }) => {
  return (
    <StyledInfoBox
      {...rest}
      anchor={{
        vertical: "bottom",
        horizontal: "right"
      }}
    >
      <div>
        <div className="title">
          <b>{title}</b>
        </div>
        <div className="message">{message}</div>
        <StyledInfoBoxActions>{actions}</StyledInfoBoxActions>
      </div>
    </StyledInfoBox>
  );
};

export default InfoBox;
