import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Card from "../../../components/atoms/Card";
import CardHeader from "../../../components/atoms/CardHeader";
import CardContent from "../../../components/atoms/CardContent";
import Divider from "../../../components/atoms/Divider";
import Button from "../../../components/atoms/Button";
import { functions } from "../../../firebase";
import useWorkspace from "../../../hooks/useWorkspace";
import FormDialog from "../../../components/molecules/FormDialog";
import Typography from "../../../components/atoms/Typography";
import { Code } from "./apiKeys/ApiKeys.styles";
import { projectId } from "../../../config/firebase";
import Grid from "../../../components/atoms/Grid";

export default () => {
  const { t } = useTranslation();
  const workspaceId = useWorkspace();
  const [isOpen, setIsOpen] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const functionUrl = `https://europe-west1-${projectId}.cloudfunctions.net/entries-bymonth`;

  const create = async () => {
    try {
      const callable = functions.httpsCallable("apikey-generate");
      setIsLoading(true);
      const result = await callable({ workspaceId });
      setApiKey(result.data.apiKey);

      setIsOpen(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("ERROR", error);
    }
  };

  const handleOnClose = () => {
    setIsOpen(false);
  };

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  return (
    <>
      <Card>
        <CardHeader title={t("pages.profileSettings.apiKeysHeader")} />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                {t("pages.profileSettings.apiKeysDescription")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={create}
                loading={isLoading}
                disabled={isLoading}
              >
                {t("common.create")}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <FormDialog
        key="form"
        open={isOpen}
        onClose={handleOnClose}
        title={t("pages.profileSettings.apiKeysDialogHeader")}
        inProgress={false}
        saveActionTitle={t("common.close")}
        onSave={handleOnClose}
      >
        <Typography gutterBottom>
          {t("pages.profileSettings.apiKeysDialogDesc")}
        </Typography>
        <Code>{apiKey}</Code>
        <br />

        <Divider />

        <Typography variant="h4">
          {t("pages.profileSettings.apiKeysDialogExampleText")}
        </Typography>

        <Code>
          curl -H "X-API-Key: {apiKey}" "{functionUrl}?year={currentYear}
          &amp;month={currentMonth}"
        </Code>
      </FormDialog>
    </>
  );
};
