import {
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  all,
  delay
} from "redux-saga/effects";
import { WATCH_BATCH_SAVE, WATCH_GET, WATCH_SAVE } from "./constants";
import {
  get,
  getFailure,
  getSuccess,
  saveSuccess,
  saveFailure,
  save,
  batchSave,
  batchSaveSuccess,
  batchSaveFailure
} from "./actions";

import Entry from "../../../../../models/Entry";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;
export const getUserEmail = ({
  api: {
    auth: { user }
  }
}) => user.email;

export const doGet = async (workspace, user, year, week) =>
  Entry.getUserTimesheet(workspace, user, year, week);

export const getEntries = function*(args) {
  const { payload } = args;
  const { year, week } = payload;
  try {
    yield put(get());
    const workspace = yield select(getActiveWorkspace);
    const user = yield select(getUserEmail);

    const entries = yield call(doGet, workspace, user, year, week);

    yield put(getSuccess(entries));
  } catch (e) {
    console.error(e);
    yield put(getFailure(e));
  }
};

export const saveEntry = function*(args) {
  const { payload } = args;
  const { entry } = payload;

  try {
    const workspace = yield select(getActiveWorkspace);
    yield put(save(entry));

    yield call(Entry.save, workspace, entry);

    yield put(saveSuccess(entry));
  } catch (e) {
    yield put(saveFailure(entry, e));
    console.warn(e);
  }
};

export const batchSaveEntries = function*(args) {
  const { payload } = args;
  const { entries } = payload;

  try {
    const workspace = yield select(getActiveWorkspace);

    yield put(batchSave(entries));

    yield call(Entry.batchSave, workspace, entries);

    yield delay(3000);
    yield put(batchSaveSuccess(entries));
  } catch (e) {
    yield put(batchSaveFailure(entries, e));
    console.warn(e);
  }
};

export const getEntriesSaga = function*() {
  yield takeLatest(WATCH_GET, getEntries);
};

export const saveEntrySaga = function*() {
  yield takeEvery(WATCH_SAVE, saveEntry);
};

export const batchSaveEntriesSaga = function*() {
  yield takeEvery(WATCH_BATCH_SAVE, batchSaveEntries);
};

export default function*() {
  yield all([getEntriesSaga(), saveEntrySaga(), batchSaveEntriesSaga()]);
}
