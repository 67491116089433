import {
    SET_LOADING,
    SET_ORDER_BY,
    SET_ORDER_DIR,
    SET_PAGE,
    SET_ROWS_PER_PAGE,
    SET_SELECTION,
    SET_USER_GROUPS,
} from "./constants";
import {SortDirection, TableAction, TableState} from "./types";
import {createReducer} from "typesafe-actions";


const initialState: TableState = {
    userGroups: [],
  loading: false,
    selection: [],
    rowsPerPage: 10,
    page: 0,
    orderDir: SortDirection.DESC,
    orderBy: "name"
};

const reducer = createReducer<TableState, TableAction>(initialState)
  .handleType(SET_SELECTION, (state, action) => ({ ...state, selection:  action.payload.list}))
  .handleType(SET_ORDER_BY, (state, action) => ({ ...state, orderBy:  action.payload.property}))
  .handleType(SET_USER_GROUPS, (state, action) => ({ ...state, userGroups:  action.payload.list}))
  .handleType(SET_ORDER_DIR, (state, action) => ({ ...state, orderDir:  action.payload.direction}))
  .handleType(SET_ROWS_PER_PAGE, (state, action) => ({ ...state, rowsPerPage:  action.payload.rows}))
  .handleType(SET_LOADING, (state, action) => ({ ...state, loading:  action.payload.loading}))
  .handleType(SET_PAGE, (state, action) => ({ ...state, page:  action.payload.page}))

export default reducer;
