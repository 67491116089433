import React, { useMemo } from "react";
import { PageContent } from "../../../components/atoms/Page";
import ProjectsTable from "../reports/projects/ProjectsTable";
import { useDispatch, useSelector } from "react-redux";
import CustomerStats from "./overview/CustomerStats";
import PeriodSelect from "../../../components/molecules/PeriodSelect";
import {
  Grid,
  Button,
  Tooltip
} from "@superprofit/core-react-components/atoms";
import { setPeriod } from "../../../redux/modules/ui/customerOverview2/period/actions";
import ActivityCard from "./overview/ActivityCard";
import { useFeatures } from "../../../helpers";
import { createInvoice } from "../../../redux/modules/ui/customerOverview2/invoice/actions";
import { filterArchivedProjects } from "../../../utils/ProjectStats";
import useCustomerEntries from "../../../hooks/useCustomerEntries";
import {
  addMonths,
  endOfISOWeekYear,
  endOfMonth,
  endOfYear,
  format,
  startOfISOWeekYear,
  startOfYear
} from "date-fns";
import { useParams } from "react-router-dom";
import useCustomersMap from "../../../hooks/useCustomersMap";
import useStatsFromEntries, {
  StatsType
} from "../../../hooks/useStatsFromEntries";
import useProjects from "../../../hooks/useProjects";
import useCustomerProjects from "../../../hooks/useCustomerProjects";
import { use24SOIntegrationInvoice } from "../integrations/twentyFourSeven/use24SOIntegrationInvoice";
import card from "../../../components/atoms/Card";
import useUser from "../../../hooks/useUser";
import { useTranslation } from "react-i18next";

export default () => {
  const dispatch = useDispatch();
  const user = useUser();
  const { twentyFourSevenIntegration } = useFeatures();
  const { id: customerId } = useParams();
  const customersMap = useCustomersMap();
  const customer = customersMap.get(customerId);
  const { data: projects, isLoading: loading } = useCustomerProjects(
    customerId
  );

  const period = useSelector(state => state.ui.customerOverview2.period);
  const currentYearDate = new Date(period.year, 1, 1);
  // We dont use ISO week since we are fetching all entries for the year
  const startOfYearDate = startOfYear(currentYearDate);
  const endOfYearDate = endOfYear(currentYearDate);
  const { t } = useTranslation();

  const { data: entries, isLoading } = useCustomerEntries({
    id: customerId,
    fromDate: startOfYearDate,
    toDate: endOfYearDate
  });

  const byWeekAllYear = useStatsFromEntries(entries, {
    fromDate: startOfYearDate,
    toDate: endOfYearDate,
    type: StatsType.XStatsUserProjectHoursByWeek
  });

  const { saveInvoice } = use24SOIntegrationInvoice();

  const invoiceLoading = useSelector(
    state => state.ui.customerOverview2.invoice.loading
  );

  const activeProjects = useMemo(() => {
    if (!projects || loading) return [];
    return filterArchivedProjects(projects, byWeekAllYear);
  }, [projects, byWeekAllYear, loading]);

  const filteredStatsMonth = useMemo(() => {
    if (!byWeekAllYear || !period.year) return [];
    return byWeekAllYear.filter(
      s => s.year === period.year && s.month === period.month
    );
  }, [byWeekAllYear]);

  const monthBefore = useMemo(() => {
    if (!byWeekAllYear || !period.year) return [];
    const year = period.month === 1 ? period.year - 1 : period.year;
    const month = period.month === 1 ? 12 : period.month - 1;
    return byWeekAllYear.filter(s => s.year === year && s.month === month);
  }, [byWeekAllYear]);

  const handleOnChangePeriod = ({ year, month }) => {
    dispatch(setPeriod(year, month));
  };

  const handleCreateInvoice = async () => {
    dispatch(createInvoice());
  };

  const handle24SOInvoice = async () => {
    try {
      const endOfMonthDate = endOfMonth(
        new Date(period.year, period.month - 1, 1)
      );
      const endOfNextMonthDate = endOfMonth(addMonths(endOfMonthDate, 1));
      await saveInvoice({
        year: period.year,
        month: period.month,
        customer,
        projects: activeProjects,
        dueDate: endOfNextMonthDate,
        issueDate: endOfMonthDate
      });
    } catch (e) {
      console.error(e);
    }
  };
  const formattedMonth = format(
    new Date(period.year, period.month - 1),
    "MMMM"
  );

  return (
    <PageContent>
      <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
        <Grid item xs={12} component="div">
          <Grid container justify="space-between">
            <Grid item>
              <PeriodSelect
                period={period}
                showNavigation
                onChange={handleOnChangePeriod}
                showWeek={false}
              />
            </Grid>
            <Grid item>
              <Tooltip
                arrow
                title={
                  twentyFourSevenIntegration
                    ? t("pages.customers.sendInvoiceTo24SOHelperText", {
                        customerName: customer?.name,
                        // projectName: project?.name,
                        formattedMonth
                      })
                    : t("pages.customers.createInvoiceHelperText", {
                        customerName: customer?.name,
                        // projectName: project?.name,
                        formattedMonth
                      })
                }
              >
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={
                    twentyFourSevenIntegration
                      ? handle24SOInvoice
                      : handleCreateInvoice
                  }
                  disabled={invoiceLoading}
                >
                  {twentyFourSevenIntegration
                    ? t("pages.customers.sendInvoiceTo24SO")
                    : t("pages.customers.createInvoice")}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} component="div" justify="flex-start">
          <CustomerStats
            customer={customer}
            projects={activeProjects}
            loading={isLoading}
            stats={filteredStatsMonth}
            statsBefore={monthBefore}
          />
        </Grid>
        <Grid item xs={12} component="div" justify="flex-start">
          <ProjectsTable
            stats={filteredStatsMonth}
            users={[]}
            customer={customer}
            projects={activeProjects}
            loading={loading}
            period={period}
            columns={{
              avatar: true,
              hours: true,
              billable: true,
              weekDistribution: true,
              relativeShare: true,
              label: true
            }}
          />
        </Grid>
        <Grid item xs={12} component="div" justify="flex-start">
          <ActivityCard
            stats={byWeekAllYear}
            projects={projects}
            loading={loading}
          />
        </Grid>
      </Grid>
    </PageContent>
  );
};
