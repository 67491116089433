import React, { useMemo } from "react";
import { PageContent } from "../../../components/atoms/Page";
import Grid from "@material-ui/core/Grid";
import PeriodSelect from "../../../components/molecules/PeriodSelect";
import ProjectStats from "./overview/ProjectStats";
import ActivityCard from "./overview/ActivityCard";
import { setPeriod } from "../../../redux/modules/ui/projectOverview/period/actions";
import { useDispatch, useSelector } from "react-redux";
import UserTable from "../reports/users/UserTable";
import BurndownCard from "./budget/BurndownCard";
import { Button, Tooltip } from "@superprofit/core-react-components/atoms";
import { useFeatures } from "../../../helpers";
import { createInvoice } from "../../../redux/modules/ui/projectOverview/invoice/actions";
import {
  hideAddUserDialog,
  showAddUserDialog
} from "../../../redux/modules/ui/projectOverview/addUserDialog/actions";
import AddUserDialog from "./overview/AddUserDialog";
import useProject from "../../../hooks/useProject";
import { useParams } from "react-router-dom";
import useCustomers from "../../../hooks/useCustomers";
import { useTranslation } from "react-i18next";
import { format } from "../../../date-fns-wrappers";
import { addMonths, endOfMonth, endOfYear, startOfYear } from "date-fns";
import useProjectEntries from "../../../hooks/useProjectEntries";
import useStatsFromEntries, {
  StatsType
} from "../../../hooks/useStatsFromEntries";
import { use24SOIntegrationInvoice } from "../integrations/twentyFourSeven/use24SOIntegrationInvoice";

export default () => {
  const dispatch = useDispatch();
  const { twentyFourSevenIntegration } = useFeatures();
  const { t } = useTranslation();
  const { id: projectId } = useParams();
  const { saveInvoice } = use24SOIntegrationInvoice();

  const period = useSelector(state => state.ui.projectOverview.period);
  const {
    loading: statsLoading,
    data: { users, userGroups = [] }
  } = useSelector(state => state.ui.projectOverview.basis);
  const currentYearDate = new Date(period.year, 1, 1);
  const startOfYearDate = startOfYear(currentYearDate);
  const endOfYearDate = endOfYear(currentYearDate);

  const { data: entries, isLoading: entriesLoading } = useProjectEntries({
    id: projectId,
    fromDate: startOfYearDate,
    toDate: endOfYearDate
  });

  const byWeekAllYear = useStatsFromEntries(entries, {
    fromDate: startOfYearDate,
    toDate: endOfYearDate,
    type: StatsType.XStatsUserProjectHoursByWeek
  });

  const monthBefore = useMemo(() => {
    if (!byWeekAllYear || !period.year) return [];
    const year = period.month === 1 ? period.year - 1 : period.year;
    const month = period.month === 1 ? 12 : period.month - 1;
    return byWeekAllYear.filter(s => s.year === year && s.month === month);
  }, [byWeekAllYear, period]);

  const { data: project, isLoading: projectLoading } = useProject(projectId);

  const {
    loading: burndownLoading,
    labels: burndownLabels,
    values: burndownValues
  } = useSelector(state => state.ui.projectOverview.burndown);

  const isLoading =
    projectLoading || statsLoading || burndownLoading || entriesLoading;
  const invoiceLoading = useSelector(
    state => state.ui.customerOverview2.invoice.loading
  );

  const handleOnChangePeriod = ({ year, month }) => {
    dispatch(setPeriod(year, month));
  };
  const handleCreateInvoice = () => {
    dispatch(createInvoice());
  };

  const projectUsers = useMemo(() => {
    if (isLoading || !project) return [];
    return users.filter(
      u =>
        project.team.indexOf(u.id) > -1 ||
        byWeekAllYear.find(s => s.user === u.id) ||
        (project?.userGroups || [])
          .map(id => userGroups.find(ug => ug.id === id))
          .some(g => g?.users && g.users.indexOf(u.email) > -1)
    );
  }, [users, project, isLoading, byWeekAllYear]);

  const monthStats = useMemo(() => {
    return byWeekAllYear.filter(
      s => s.month === period.month && s.year === period.year
    );
  }, [byWeekAllYear, period]);

  const { data: customers } = useCustomers();
  const customer = customers?.find(c => project?.customer === c.id) || {};
  // const customer = useSelector(state =>
  //   state.api.customers.list.find(c => project.customer)
  // );

  const showBurndown =
    !isLoading &&
    project &&
    project.budget &&
    project.budget.type &&
    project.budget.type !== "None";

  const formattedMonth = format(
    new Date(period.year, period.month - 1, 1),
    "MMMM"
  );

  const handleOnAddUser = () => {
    dispatch(showAddUserDialog());
  };
  const handle24SOInvoice = async () => {
    try {
      const endOfMonthDate = endOfMonth(
        new Date(period.year, period.month - 1, 1)
      );
      const endOfNextMonthDate = endOfMonth(addMonths(endOfMonthDate, 1));
      await saveInvoice({
        year: period.year,
        month: period.month,
        customer,
        projects: [project],
        dueDate: endOfNextMonthDate,
        issueDate: endOfMonthDate
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <PageContent>
      <AddUserDialog />
      <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
        <Grid item xs={12} component="div">
          <Grid container justify="space-between">
            <Grid item>
              <PeriodSelect
                period={period}
                showNavigation
                onChange={handleOnChangePeriod}
                showWeek={false}
              />
            </Grid>

            <Grid item>
              <Tooltip
                arrow
                title={
                  twentyFourSevenIntegration
                    ? t("pages.projects.sendInvoiceTo24SOHelperText", {
                        customerName: customer?.name,
                        projectName: project?.name,
                        formattedMonth
                      })
                    : t("pages.projects.createInvoiceHelperText", {
                        customerName: customer?.name,
                        projectName: project?.name,
                        formattedMonth
                      })
                }
              >
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={
                    twentyFourSevenIntegration
                      ? handle24SOInvoice
                      : handleCreateInvoice
                  }
                  disabled={invoiceLoading}
                >
                  {twentyFourSevenIntegration
                    ? t("pages.projects.sendInvoiceTo24SO")
                    : t("pages.projects.createInvoice")}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} component="div">
          <ProjectStats
            project={project}
            month={period.month}
            year={period.year}
            loading={isLoading}
            stats={monthStats}
            statsBefore={monthBefore}
            allTimeStats={byWeekAllYear}
          />
        </Grid>

        <Grid item xs={12} lg={showBurndown ? 6 : 12} component="div">
          <ActivityCard
            loading={isLoading}
            project={project}
            year={period.year}
            stats={byWeekAllYear}
          />
        </Grid>

        {showBurndown && (
          <Grid item xs={12} lg={6} component="div">
            <BurndownCard
              project={project}
              loading={isLoading}
              stats={byWeekAllYear}
            />
          </Grid>
        )}

        <Grid item xs={12} component="div">
          <UserTable
            year={period.year}
            month={period.month}
            stats={monthStats}
            projects={project ? [project] : []}
            users={projectUsers}
            period={period}
            loading={isLoading}
            onAdd={handleOnAddUser}
            columns={{
              relative: true,
              avatar: true,
              money: true,
              hours: true,
              weekDistribution: true,
              billable: true
            }}
          />
        </Grid>
      </Grid>
    </PageContent>
  );
};
