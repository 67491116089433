export default ({
  customerId,
  subscriptionId,
  priceId,
  apiBase,
  quantity,
  token,
}) => {
  return fetch(
    `${apiBase}/getSubscriptionInformation?subscriptionId=${subscriptionId}&customerId=${customerId}`,
    {
      method: "get",
      headers: {
        "Content-type": "application/json",
        authorization: token,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    });
};
