import React, { forwardRef, Fragment, useState } from "react";
import Table, {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";

import { Checkbox } from "@superprofit/core-react-components/atoms";
import ProjectRow from "./ProjectRow";
import ActionMenu from "../../../../components/molecules/ActionMenu";
import TableLoader from "../../../../components/molecules/TableLoader";
import {
  ActionMenuState,
  IProjectsTable,
  OrderBy,
  OrderDirection
} from "./types";
import { Project } from "../projectsCard/types";
import { useTranslation } from "react-i18next";

const descendingComparator = (a: Project, b: Project, orderBy: OrderBy) => {
  const compB = b[orderBy] || "";
  const compA = a[orderBy] || "";
  if (compB < compA) return -1;
  if (compB > compA) return 1;
  return 0;
};

const getComparator = (orderDir: OrderDirection, orderBy: OrderBy) => {
  return orderDir === "desc"
    ? (a: Project, b: Project) => descendingComparator(a, b, orderBy)
    : (a: Project, b: Project) => -descendingComparator(a, b, orderBy);
};

export default forwardRef(
  (
    {
      onSelectionChange,
      onEdit,
      onArchive,
      onDelete,
      onView,
      onDuplicate,
      selection = [],
      projects,
      loading
    }: IProjectsTable,
    ref
  ) => {
    const [actionMenu, setActionMenu] = useState<ActionMenuState>({
      project: null,
      anchor: null
    });
    const { t } = useTranslation();

    const [orderBy, setOrderBy] = useState<OrderBy>("name");
    const [orderDir, setOrderDir] = useState(OrderDirection.ASC);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const selectedIds = React.useMemo(
      () =>
        (selection || []).reduce(
          (prev, next) => ({
            ...prev,
            [next.id || "noid"]: true
          }),
          {} as Record<string, boolean>
        ),
      [selection]
    );

    const projectsList = React.useMemo(() => {
      let projectsListSorted = projects.slice();

      projectsListSorted.sort(getComparator(orderDir, orderBy));
      projectsListSorted =
        rowsPerPage > 0
          ? projectsListSorted.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : projectsListSorted;

      return projectsListSorted;
    }, [orderBy, orderDir, page, rowsPerPage, projects]);

    const handleOnSort = (event: React.MouseEvent, property: OrderBy) => {
      const isAsc = orderBy === property && orderDir === OrderDirection.ASC;
      setOrderDir(isAsc ? OrderDirection.DESC : OrderDirection.ASC);
      setOrderBy(property);
    };

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target?: { value: string } }) => {
      // setRowsPerPage(parseInt(event.currentTarget.value, 10));
      if (event.target?.value) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      }
    };

    const handleOnMore = (
      e: React.MouseEvent<HTMLButtonElement>,
      project: Project
    ) => {
      setActionMenu({ anchor: e.currentTarget, project });
    };

    const handleOnCloseMore = () => {
      setActionMenu({ anchor: null, project: null });
    };

    const handleOnSelectAll = () => {
      if (selection && selection.length === projects.length) {
        onSelectionChange([]);
      } else {
        onSelectionChange(projects);
      }
    };

    const handleOnSelect = (project: Project) => {
      if (project.id && selectedIds[project.id]) {
        onSelectionChange(selection.filter(p => p.id !== project.id));
      } else {
        onSelectionChange(
          selection.filter(p => p.id !== project.id).concat(project)
        );
      }
    };

    return (
      <Fragment>
        <ActionMenu
          onEdit={onEdit}
          onArchive={onArchive}
          // onDelete={onDelete}
          onDuplicate={onDuplicate}
          onView={onView}
          data={actionMenu.project}
          open={!!actionMenu.anchor}
          anchorEl={actionMenu.anchor}
          onClose={handleOnCloseMore}
        />

        <TableContainer ref={ref}>
          <Table aria-label={t("common.projects")}>
            <TableHead>
              <TableRow>
                <TableCell style={{ maxWidth: 80, width: 80 }}>
                  <Checkbox
                    defaultChecked={false}
                    checked={
                      (selection.length &&
                        selection.length === projects.length) ||
                      false
                    }
                    indeterminate={
                      (selection.length &&
                        selection.length < projects.length) ||
                      false
                    }
                    inputProps={{ "aria-label": "select all projects" }}
                    onChange={handleOnSelectAll}
                  />
                </TableCell>

                <TableCell style={{ maxWidth: 80, width: 80 }} />

                <TableCell
                  sortDirection={orderBy === "name" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "name")}
                  >
                    {t("common.name")}
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sortDirection={orderBy === "billable" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "billable"}
                    direction={orderBy === "billable" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "billable")}
                  >
                    {t("common.type")}
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sortDirection={orderBy === "managerName" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "managerName"}
                    direction={orderBy === "managerName" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "managerName")}
                  >
                    {t("common.manager")}{" "}
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sortDirection={orderBy === "tags" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "tags"}
                    direction={orderBy === "tags" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "tags")}
                  >
                    {t("common.tags")}
                  </TableSortLabel>
                </TableCell>

                <TableCell style={{ maxWidth: 80, width: 80 }}></TableCell>
              </TableRow>
            </TableHead>

            {!loading && (
              <Fragment>
                <TableBody>
                  {projectsList.map(p => (
                    <ProjectRow
                      key={p.id}
                      project={p}
                      selected={!!(p.id && selectedIds[p.id])}
                      onMore={handleOnMore}
                      onSelect={() => handleOnSelect(p)}
                    />
                  ))}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TablePagination
                      labelRowsPerPage={t("common.rowsPerPage")}
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        { label: t("common.all"), value: -1 }
                      ]}
                      count={projects.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Fragment>
            )}

            {loading && (
              <TableLoader
                cols={[
                  "rect",
                  "circle",
                  "text",
                  "text",
                  "text",
                  "text",
                  "rect"
                ]}
              />
            )}
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
);
