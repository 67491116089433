import {init as auth} from '@superprofit/core-redux-saga-modules/Auth/sagas';
import {init as users} from '@superprofit/core-redux-saga-modules/Users/sagas';
import {init as userRole} from '@superprofit/core-redux-saga-modules/UserRole/sagas';
import {init as userAccess} from '@superprofit/core-redux-saga-modules/UserAccess/sagas';
import {init as workspaces} from '@superprofit/core-redux-saga-modules/Workspaces/sagas';
import { firebase, db } from '../firebase';
import {userRoleUrl, workspaceJoinUrl, createWorkspaceUrl} from '../config/api';

auth(db, firebase, workspaceJoinUrl);
users(db);
userRole(userRoleUrl);
userAccess(db);
workspaces(db, firebase, createWorkspaceUrl);