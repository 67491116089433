import {
  SET_LOADING,
  WATCH_REFRESH_USERS_TABLE,
  SET_USERS_TABLE,
  WATCH_GET_USERS, WATCH_DELETE_USERS,
} from './constants';

export const setUsersTable = (list) => ({
  type: SET_USERS_TABLE,
  payload: { list },
});

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  payload: { isLoading },
});

export const watchGetUsers = () => ({
  type: WATCH_GET_USERS,
});

export const watchRefreshUsersTable = () => ({
  type: WATCH_REFRESH_USERS_TABLE,
});

export const watchDeleteUsers = (users) => ({
  type: WATCH_DELETE_USERS,
  payload: { users },
});
