import {
  SHOW_EXPORT_DIALOG,
  HIDE_EXPORT_DIALOG,
  SET_EXPORT_LOADING
} from "./constants";

export const initialState = {
  isSaving: false,
  dialog: {
    open: false,
    loading: false,
    invoice: null,
    exportSettings: {
      customerId: null,
      productId: null
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_EXPORT_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          open: true,
          invoice: action.payload.invoice
        }
      };
    case HIDE_EXPORT_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          open: false
        }
      };
    case SET_EXPORT_LOADING:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          loading: action.payload.isLoading
        }
      };

    default:
      return state;
  }
};
