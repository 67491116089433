import styled from "styled-components";
import {
  IconButton,
  InputAdornment,
} from "@superprofit/core-react-components/atoms";

export const StyledIconButton = styled(IconButton)`
  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
  transition: opacity 300ms ease-in-out;
  opacity: ${({ visibility }) => (visibility === "hidden" ? 0 : 1)};
  &:focus {
    opacity: 1;
  }
`;

export const StyledInputAdornment = styled(InputAdornment)`
  position: absolute;
  right: 0;
  top: -0.9rem;
`;
