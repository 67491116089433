import React, { useRef, useState } from "react";
import {
  Menu,
  Button,
  ButtonGroup,
  MenuItem
} from "@superprofit/core-react-components/atoms";
import { moment, weeksOfYear, years } from "@superprofit/time-util";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { StyledWeekItem } from "./periodSelect/styles";
import { ArrowLeftIcon } from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import { ArrowRightIcon } from "@material-ui/pickers/_shared/icons/ArrowRightIcon";
import { dayNames, monthNames } from "../../helpers";
import {
  add,
  getMonth,
  getISOWeekYear as getYear,
  set,
  sub,
  endOfYear,
  startOfYear,
  isSameISOWeek as isSameWeek,
  setISOWeek as setWeek,
  getISOWeek as getWeek,
  startOfISOWeek as startOfWeek,
  endOfISOWeek as endOfWeek,
  getISODay as getDay,
  weeksToDays,
  setYear,
  setDay,
  setISOWeekYear,
  setMonth,
  startOfISOWeekYear,
  endOfISOWeekYear
} from "date-fns";

import { format, eachWeekOfInterval } from "../../date-fns-wrappers";
import { useTranslation } from "react-i18next";

export type Period = {
  day?: number;
  year?: number;
  week?: number;
  month?: number;
};

interface IPeriodSelect {
  period: Period;
  showYear?: boolean;
  showMonth?: boolean;
  showWeek?: boolean;
  onChange: (period: Period) => void;
  showNavigation?: boolean;
  showDay?: boolean;
}

const PeriodSelect: React.FC<IPeriodSelect> = props => {
  const {
    period: propsPeriod = {
      year: getYear(new Date()),
      week: getWeek(new Date()),
      month: getMonth(new Date())
    },
    showDay,
    showYear = true,
    showMonth = true,
    showWeek = true,
    showNavigation,
    onChange,
    ...rest
  } = props;
  const yearBtnRef = useRef(null);
  const dayBtnRef = useRef(null);
  const monthBtnRef = useRef(null);
  const weekBtnRef = useRef(null);
  const { t } = useTranslation();

  const [showMonthPopper, setShowMonthPopper] = useState(false);
  const [showDayPopper, setShowDayPopper] = useState(false);
  const [showYearPopper, setShowYearPopper] = useState(false);
  const [showWeekPopper, setShowWeekPopper] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<Period>({
    ...propsPeriod
  });

  const [selectedPeriodDate, setSelectedPeriodDate] = useState<Date>(
    new Date()
  );

  const handleOnShowDayPopper = () => {
    setShowDayPopper(true);
  };

  const handleOnCloseDayPopper = () => {
    setShowDayPopper(false);
  };

  const handleOnShowMonthPopper = () => {
    setShowMonthPopper(true);
  };

  const handleOnCloseMonthPopper = () => {
    setShowMonthPopper(false);
  };

  const handleOnShowWeekPopper = () => {
    setShowWeekPopper(true);
  };

  const handleOnCloseWeekPopper = () => {
    setShowWeekPopper(false);
  };

  const handleOnShowYearPopper = () => {
    setShowYearPopper(true);
  };

  const handleOnCloseYearPopper = () => {
    setShowYearPopper(false);
  };

  const handleOnSelectMonth = (e: React.ChangeEvent<HTMLButtonElement>) => {
    const month = +e.target.value;
    const period = { ...selectedPeriod, month };
    const upd = setMonth(selectedPeriodDate, month);
    setSelectedPeriodDate(upd);
    setSelectedPeriod(period);
    onChange(period);
  };

  const handleOnSelectYear = (e: React.ChangeEvent<HTMLButtonElement>) => {
    const year = +e.target.value;
    const period = { ...selectedPeriod, year };
    let upd = setISOWeekYear(selectedPeriodDate, year);
    setSelectedPeriodDate(upd);
    setSelectedPeriod(period);
    onChange(period);
  };

  const handleOnSelectWeek = (e: React.ChangeEvent<HTMLButtonElement>) => {
    if (!e.currentTarget.dataset?.date) return;
    const d = new Date(e.currentTarget.dataset?.date);
    const period = { ...selectedPeriod, week: getWeek(d), year: getYear(d) };
    setSelectedPeriodDate(d);
    setSelectedPeriod(period);
    onChange(period);
  };

  const handleOnSelectDay = (e: React.ChangeEvent<HTMLButtonElement>) => {
    const day = +e.target.value;
    const period = { ...selectedPeriod, day };
    setSelectedPeriod(period);
    onChange(period);
  };
  const handleOnPrevious = () => {
    if (showDay) {
      console.warn("Not implemented showDay");
    } else if (showWeek) {
      const updated = startOfWeek(sub(selectedPeriodDate, { weeks: 1 }));
      const period = {
        ...selectedPeriod,
        week: getWeek(updated),
        year: getYear(updated)
      };
      setSelectedPeriod(period);
      setSelectedPeriodDate(updated);

      onChange(period);
    } else if (showMonth) {
      const upd = sub(selectedPeriodDate, { months: 1 });
      const period = {
        ...selectedPeriod,
        month: getMonth(upd) + 1,
        year: getYear(upd)
      };
      setSelectedPeriod(period);
      setSelectedPeriodDate(upd);
      onChange(period);
    } else {
      console.warn("Not implemented showYear");
    }
  };
  const handleOnNext = () => {
    if (showDay) {
      console.warn("Not implemented showDay");
    } else if (showWeek) {
      const updated = startOfWeek(add(selectedPeriodDate, { weeks: 1 }));
      const period = {
        ...selectedPeriod,
        week: getWeek(updated),
        year: getYear(updated)
      };
      setSelectedPeriodDate(updated);
      setSelectedPeriod(period);
      onChange(period);
    } else if (showMonth) {
      const upd = add(selectedPeriodDate, { months: 1 });
      const period = {
        ...selectedPeriod,
        month: getMonth(upd) + 1,
        year: getYear(upd)
      };

      setSelectedPeriodDate(upd);
      setSelectedPeriod(period);
      onChange(period);
    } else {
      console.warn("Not implemented showYear");
    }
  };

  const weekDatesSelectedYear = eachWeekOfInterval({
    start: startOfISOWeekYear(selectedPeriodDate),
    end: endOfISOWeekYear(selectedPeriodDate)
  });

  return (
    <React.Fragment>
      <ButtonGroup
        key="btns"
        color="primary"
        aria-label="outlined primary button group"
        {...rest}
      >
        {showNavigation && (
          <Button onClick={handleOnPrevious}>
            <ArrowLeftIcon />
          </Button>
        )}
        {showDay && typeof selectedPeriod.day === "number" && (
          <Button key="first" ref={dayBtnRef} onClick={handleOnShowDayPopper}>
            {dayNames()[selectedPeriod.day]}
            <ArrowDropDownIcon />
          </Button>
        )}
        {showMonth && typeof selectedPeriod.month === "number" && (
          <Button
            key="second"
            ref={monthBtnRef}
            onClick={handleOnShowMonthPopper}
          >
            {monthNames()[selectedPeriod.month - 1]}
            <ArrowDropDownIcon />
          </Button>
        )}

        {showWeek && (
          <Button key="third" ref={weekBtnRef} onClick={handleOnShowWeekPopper}>
            {`${t("common.week")} ${selectedPeriod.week}`}
            <ArrowDropDownIcon />
          </Button>
        )}

        {showYear && (
          <Button key="last" ref={yearBtnRef} onClick={handleOnShowYearPopper}>
            {selectedPeriod.year}
            <ArrowDropDownIcon />
          </Button>
        )}
        {showNavigation && (
          <Button onClick={handleOnNext}>
            <ArrowRightIcon />
          </Button>
        )}
      </ButtonGroup>

      <Menu
        key="first-menu"
        // keepMounted
        variant="selectedMenu"
        anchorEl={dayBtnRef.current}
        open={showDayPopper}
        onClose={handleOnCloseDayPopper}
      >
        {dayNames().map((m: string, idx: number) => {
          return (
            <MenuItem
              key={m}
              value={idx}
              selected={selectedPeriod.day === idx}
              onClick={handleOnSelectDay}
            >
              {m}
            </MenuItem>
          );
        })}
      </Menu>

      <Menu
        key="second-menu"
        // keepMounted
        variant="selectedMenu"
        anchorEl={monthBtnRef.current}
        open={showMonthPopper}
        onClose={handleOnCloseMonthPopper}
      >
        {monthNames().map((m: string, idx: number) => {
          return (
            <MenuItem
              key={m}
              value={idx + 1}
              selected={selectedPeriod.month === idx + 1}
              onClick={handleOnSelectMonth}
            >
              {m}
            </MenuItem>
          );
        })}
      </Menu>

      <Menu
        key="third-menu"
        style={{ maxHeight: 600 }}
        variant="selectedMenu"
        keepMounted
        anchorEl={weekBtnRef.current}
        open={showWeekPopper}
        onClose={handleOnCloseWeekPopper}
      >
        {weekDatesSelectedYear.map((d, idx) => (
          <StyledWeekItem
            key={d.toISOString()}
            data-date={d}
            value={getWeek(d)}
            selected={isSameWeek(selectedPeriodDate, d)}
            onClick={handleOnSelectWeek}
          >
            {isSameWeek(d, new Date()) ? (
              <div className="week-current">{t("common.current")}</div>
            ) : (
              <div className="week">
                {t("common.week")} {getWeek(d)}
              </div>
            )}
            <div className="date-range">
              {format(
                startOfWeek(setWeek(selectedPeriodDate, getWeek(d))),
                "MMM do"
              )}{" "}
              -{" "}
              {format(
                endOfWeek(setWeek(selectedPeriodDate, getWeek(d))),
                "MMM do"
              )}
            </div>
          </StyledWeekItem>
        ))}
      </Menu>

      <Menu
        key="fourth-menu"
        keepMounted
        variant="selectedMenu"
        anchorEl={yearBtnRef.current}
        open={showYearPopper}
        onClose={handleOnCloseYearPopper}
      >
        {years(getYear(new Date()), 10).map((year: number) => (
          <MenuItem
            key={year}
            value={year}
            selected={selectedPeriod.year === year}
            onClick={handleOnSelectYear}
          >
            {year}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default PeriodSelect;
