import { SET_PLAN, STAGE_UPDATES, SET_LOADING, SET_SAVING } from "./constants";

const filterSavedUpdates = (plan, updates) => {
  return Object.keys(updates).reduce((prev, next) => {
    if (!plan[next] || updates[next] !== plan[next]) {
      return { ...prev, [next]: updates[next] };
    }
    return prev;
  }, {});
};

const initialState = {
  plan: {},
  updates: {},
  stagedPlan: {},
  loading: false,
  saving: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STAGE_UPDATES:
      return {
        ...state,
        updates: { ...state.updates, ...payload.updates },
        stagedPlan: { ...state.plan, ...state.updates, ...payload.updates }
      };

    case SET_PLAN:
      return {
        ...state,
        plan: payload.plan,
        updates: filterSavedUpdates(payload.plan, state.updates),
        stagedPlan: { ...payload.plan, ...state.updates }
      };

    case SET_LOADING:
      return {
        ...state,
        loading: payload.isLoading
      };

    case SET_SAVING:
      return {
        ...state,
        saving: payload.isSaving
      };
    default:
      return state;
  }
};
