import React, { useState } from "react";
import { createWorkspaceUrl } from "../config/api";
import { useDispatch } from "react-redux";
import { ANALYTICS_EVENT, logEvent, firebase } from "../firebase";
import { push } from "connected-react-router";

const getToken = (claim: string) =>
  new Promise(resolve => {
    const interval = setInterval(async () => {
      const token = await firebase.auth().currentUser?.getIdTokenResult(true);
      if (token?.claims?.workspaces && token?.claims?.workspaces[claim]) {
        clearInterval(interval);
        resolve(true);
      }
    }, 1000);
  });

export const createWorkspace = async (
  workspaceId: string,
  workspaceDisplayName: string
) => {
  const token = await firebase.auth().currentUser?.getIdToken();

  await fetch(createWorkspaceUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token!
    },
    body: JSON.stringify({
      workspaceId,
      workspaceDisplayName
    })
  }).then(res => {
    if (!res.ok) throw new Error("Bad request");
    getToken(workspaceId);
  });
};

const useWorkspaceSignup = () => {
  const [isCreating, setCreating] = useState(false);
  const dispatch = useDispatch();

  const handleCreate = async (
    workspaceName: string,
    workspaceDisplayName = ""
  ) => {
    try {
      setCreating(true);
      logEvent(ANALYTICS_EVENT.DISPATCH_CREATE_WORKSPACE, { workspaceName });
      await createWorkspace(workspaceName, workspaceDisplayName);
      setCreating(false);
      dispatch(push(`/app/${workspaceName}`));
    } catch (e) {
      setCreating(false);
    }
  };

  return { isCreating, handleCreate };
};

export default useWorkspaceSignup;
