import styled, { css, ThemeProps } from "styled-components";
import { StandardTextFieldProps, TextField } from "@material-ui/core";
import theme, { VariantSet } from "styled-theming";
import { darken, hexToRgba } from "../../themes/shared/colorUtils";
import InputBase from "@material-ui/core/InputBase";
import { ITextField } from "../TextField";

// Todo: Special input for timesheet?
const variants = theme.variants("mode", "variant", {
  filled: {
    light: css`
      .MuiInputBase-root {
        background: ${props =>
          darken(props.theme.palette.background.default, 0.05)};
        &::before {
          display: none;
        }
        .MuiFilledInput-input {
          font-size: 1rem;
          padding-top: 20px;
        }

        .MuiFilledInput-inputMultiline {
          padding: 0;
        }
      }

      .MuiInputLabel-formControl {
        position: initial;
        top: initial;
        left: initial;
      }
      .MuiInputLabel-filled,
      .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: none; //translate(12px, 5px) scale(0.75);
      }
      .MuiInputLabel-root {
        text-transform: uppercase;
        margin-bottom: ${props => props.theme.spacing(1)}px;
        text-align: left;
        font-size: ${props => props.theme.typography.h2.fontSize};
        font-weight: ${props => props.theme.typography.h2.fontWeight};
        letter-spacing: ${props => props.theme.typography.h2.letterSpacing}px;
      }
    `,
    dark: css``
  }
});

// any here because of too much trouble to get the types right
export const StyledTextField = styled(TextField)<any>`
  ${variants};
  border-radius: ${props => props?.theme?.shape?.borderRadius}px;

  ${props =>
    props.align === "right" &&
    css`
      input {
        text-align: right;
      }
    `};

  ${props =>
    props.alignlabel === "center" &&
    css`
      .MuiInputLabel-root {
        text-align: center;
      }
    `};
`;

// any here because of too much trouble to get the types right
export const StyledElevatedTextField = styled(InputBase)<any>`
  color: ${props => props.theme.palette.text.tertiary};
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.08),
    0 1px 3px 0 rgba(63, 63, 68, 0.2);
  border-radius: ${props => props?.theme?.shape?.borderRadius}px;
  background-color: white;
  position: relative;
  outline: none;
  border-radius: ${props => props?.theme?.shape?.borderRadius}px;
  border: 1px solid transparent;
  font-size: 1rem;
  padding: 6px 14px 6px 8px;
  transition: ${props =>
    props.theme.transitions.create(["border-color", "box-shadow"])};

  &.Mui-focused {
    border-color: ${props => props?.theme?.palette?.primary?.main};
    box-shadow: 0 0 0 0.2rem
      ${props => hexToRgba(props?.theme?.palette?.primary?.main, 0.25)};

    .MuiInputBase-input {
      background: white;
      border-radius: ${props => props?.theme?.shape?.borderRadius}px;
    }
  }
`;
