import { useSelector } from "react-redux";
import { RootState } from "../index";
import CompanySetting from "../models/CompanySetting";
import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import UserSetting from "../models/UserSetting";
import useUser from "./useUser";

interface IUserSettings {
  id?: string;
}

export const getKey = (workspace: string) => [
  workspace,
  CompanySetting.collectionName
];

const useUserSettings = ({ id: propId }: IUserSettings = {}) => {
  const workspace = useWorkspace();
  const user = useUser();
  const key = getKey(workspace);
  const id = propId || user?.id;
  return useQuery(key, () => UserSetting.map(workspace, id), {
    enabled: !!id
  });
};

export default useUserSettings;
