import React from "react";
import Dialog from "../atoms/Dialog";
import DialogTitle from "../atoms/DialogTitle";
import DialogContent from "../atoms/DialogContent";
import DialogContentText from "../atoms/DialogContentText";
import DialogActions from "../atoms/DialogActions";
import Button from "../atoms/Button";

export default ({ open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Feature not yet implemented"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        This feature is not yet implemented, but we are working hard to
        implement it as soon as possible.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" autoFocus>
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);
