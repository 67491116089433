import Entry from "../../../../models/Entry";
import TimesheetComment from "../../../../models/TimesheetComment";
import { filterStats } from "../../../../utils/StatsFilter";
import { createTableExport } from "../../../../utils/report/data/SalaryBasisExport";
import { createTableExport as createTableExportNettlonn } from "../../../../utils/report/data/NettlonnBasisExport";
import { saveAsPDF, saveAsXLSX } from "../../../../utils/report";
import { Filters, IUserTable } from "../shared/types";
import Project from "../../../../models/Project";
import User from "../../../../models/User";
import useCustomers from "../../../../hooks/useCustomers";
import { ANALYTICS_EVENT, logEvent } from "../../../../firebase";
import { useTranslation } from "react-i18next";
import useGlobalSnackbar from "../../../../hooks/useGlobalSnackbar";
import useProjectSalarySettings from "../../../../hooks/useProjectSalarySettings";
import useCompanySettings from "../../../../hooks/useCompanySettings";
import { saveAsCsv } from "../../../../utils/report/exportXLSX";

type RawEntry = Entry & { total?: number };
const useExport = ({
  filters,
  filteredUsers,
  workspace,
  year,
  month,
  projects
}: {
  filteredUsers: User[];
  filters: Filters;
  workspace: string;
  year: number;
  month: number;
  projects: Project[];
}) => {
  const { data: customers } = useCustomers();
  const { update: updateSnackbar } = useGlobalSnackbar();
  const { t } = useTranslation();
  const { projectSalaryRateSettings } = useProjectSalarySettings();
  const { data: companySettings } = useCompanySettings();

  const handleExport = async (
    type: "XLSX" | "PDF",
    options: { nettlonn?: boolean } = {}
  ) => {
    try {
      const { nettlonn } = options;
      updateSnackbar({
        open: true,
        message: `${t("common.exporting")}... 📈`,
        alert: { severity: "info" }
      });

      const userIds = filteredUsers.map(u => u.id);
      const rawEntries = (await Entry.getAllByUsers(
        workspace,
        userIds,
        year,
        month
      )) as RawEntry[];
      rawEntries.forEach((e: RawEntry) => (e.total = e.hours || 0));

      const rawComments = await TimesheetComment.getAllByUsers(
        workspace,
        userIds,
        year,
        month
      );
      let mergedEntries = rawEntries.map(e => {
        const com = rawComments.filter(
          c =>
            c.year === e.year &&
            c.day === e.dayOfYear &&
            c.user === e.user &&
            c.project === e.project
        );
        return { ...e, comments: com };
      });
      const filteredEntries = filters
        ? filterStats(
            mergedEntries,
            projects,
            filters.type,
            filters.projects,
            filters.users,
            filters.customers
          )
        : mergedEntries;

      let csvData;
      if (nettlonn) {
        logEvent(ANALYTICS_EVENT.NETTLONN_EXPORTED, {
          workspaceName: workspace,
          type
        });
        csvData = createTableExportNettlonn(
          filteredUsers,
          projects,
          filteredEntries,
          customers,
          companySettings?.organization?.value?.organizationNumber

          // { projectSalaryRateSettings }
        );
      } else {
        logEvent(ANALYTICS_EVENT.SALARY_EXPORTED, {
          workspaceName: workspace,
          type
        });
        csvData = createTableExport(
          filteredUsers,
          projects,
          filteredEntries,
          customers,
          { projectSalaryRateSettings }
        );
      }

      if (type === "PDF") {
        await saveAsPDF(
          csvData,
          `timet-salary-report-by-user-${month}-${year}`
        );
      } else if (type === "XLSX" && nettlonn) {
        await saveAsCsv(csvData, `timet-nettlonn-export-${month}-${year}`);
      } else if (type === "XLSX") {
        await saveAsXLSX(
          csvData,
          `timet-salary-report-by-user-${month}-${year}`
        );
      }
      updateSnackbar({
        message: `${t("pages.reports.projectHours.exportDone")}! 📈`,
        alert: { severity: "success" }
      });
    } catch (e) {
      updateSnackbar({
        open: true,
        message: `${t("common.error")}: ${e} 🚨`,
        alert: { severity: "error" }
      });
      await new Promise(resolve => setTimeout(resolve, 3000));
    } finally {
      await new Promise(resolve => setTimeout(resolve, 2000));
      updateSnackbar({
        open: false
      });
    }
  };

  return { handleExport };
};
export default useExport;
