import { takeLatest, call, put, select, all } from "redux-saga/effects";
import { WATCH_GET } from "./constants";
import { get, getFailure, getSuccess } from "./actions";
import { db } from "../../../../firebase";
import { weeks } from "@superprofit/time-util";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;

export const doGet = async (workspace, project, year, month) => {
  const monthBeforeSnapshot = await db
    .collection("workspaces")
    .doc(workspace)
    .collection("xstats_user_project_hours_by_week")
    .where("year", "==", month === 1 ? year - 1 : year)
    .where("month", "==", month === 1 ? 12 : month - 1)
    .where("project", "==", project)
    .get();

  const monthBefore = [];
  monthBeforeSnapshot.forEach(doc => {
    monthBefore.push({ ...doc.data(), id: doc.id });
  });

  const allSnapshot = await db
    .collection("workspaces")
    .doc(workspace)
    .collection("xstats_user_project_hours_by_week")
    .where("project", "==", project)
    .get();

  const allTime = [];
  allSnapshot.forEach(doc => {
    allTime.push({ ...doc.data(), id: doc.id });
  });

  const weeksOfMonth = weeks(year, month);

  const snapshot = await db
    .collection("workspaces")
    .doc(workspace)
    .collection("xstats_user_project_hours_by_week")
    .where("project", "==", project)
    .where("year", "==", year)
    .where("month", "==", month)
    .get();

  const byWeek = [];
  snapshot.forEach(doc => {
    byWeek.push({ ...doc.data(), id: doc.id });
  });

  return {
    weeksOfMonth,
    byWeek,
    allTime,
    monthBefore
  };
};

export const getProjectStats = function* getProjectStats(args) {
  const { id, year, month } = args.payload;
  try {
    yield put(get());
    const workspace = yield select(getActiveWorkspace);
    const stats = yield call(doGet, workspace, id, year, month);
    yield put(getSuccess(stats));
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const getProjectStatsSaga = function* getProjectStatsSaga() {
  yield takeLatest(WATCH_GET, getProjectStats);
};

export default function*() {
  yield all([getProjectStatsSaga()]);
}
