import React, { useMemo } from "react";
import Hint from "../../../../../components/atoms/Hint";
import Important from "../../../../../components/atoms/Important";
import Grid from "@material-ui/core/Grid";
import { StyledLogged } from "./Logged.styles";
import { useParams } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import DurationFormat from "../../../../../components/atoms/DurationFormat";
import { useFeatures } from "../../../../../helpers";
import ConnectedCurrencyFormat from "../../../../../components/organisms/ConnectedCurrencyFormat";
import { useTranslation } from "react-i18next";
import { UserSummary } from "../../Overview";
import Project from "../../../../../models/Project";

interface LoggedProps {
  loading: boolean;
  stats: UserSummary[];
  month: number;
  projects: Project[];
}

export default ({ loading, stats, month, projects }: LoggedProps) => {
  const { userProjectIncome, userProjectedSalary } = useFeatures();
  const { id: email } = useParams();
  const { t } = useTranslation();

  const {
    billableHours,
    nonBillableHours,
    revenue,
    totalSalary
  } = useMemo(() => {
    if (loading) return {};

    const projectsById = projects.reduce(
      (prev, next) => ({
        ...prev,
        [next.id || ""]: next
      }),
      {} as { [projectId: string]: Project }
    );
    const billableProjectsStats = stats.filter(
      s => s.month === month && projectsById[s.project]?.billable
    );

    const billableHours = billableProjectsStats.reduce((prev, next) => {
      return prev + next.total;
    }, 0);
    const revenue = billableProjectsStats.reduce((prev, next) => {
      const userBillableRate =
        projectsById[next.project]?.userBillableRate?.[email];
      const projectBillableRate = projectsById[next.project]?.billableRate;
      if (userBillableRate) return prev + next.total * userBillableRate;
      return prev + next.total * (projectBillableRate || 0);
    }, 0);

    const nonBillableHours = stats
      .filter(s => s.month === month && !projectsById[s.project]?.billable)
      .reduce((prev, next) => {
        return prev + next.total;
      }, 0);

    const totalSalary = stats
      .filter(s => s.month === month)
      .reduce((prev, next) => {
        const project = projectsById[next.project];
        if (project?.salaryEnabled && userProjectedSalary) {
          return prev + next.total * (project?.getSalaryRate?.(email) || 0);
        }
        return prev;
      }, 0);
    return {
      billableHours,
      nonBillableHours,
      totalSalary,
      revenue
    };
  }, [stats, month, projects, loading, userProjectedSalary, email]);
  return (
    <StyledLogged>
      <Grid
        container
        spacing={3}
        component="div"
        alignItems="center"
        justify="flex-start"
      >
        {userProjectedSalary && (
          <Grid
            item
            xs={12}
            sm={3}
            md={6}
            lg={userProjectedSalary ? 4 : 3}
            component="div"
          >
            <Hint>{t("common.projectedSalary")}</Hint>
            {loading ? (
              <Skeleton height={20} width={100} />
            ) : (
              <Important>
                <ConnectedCurrencyFormat number={totalSalary} />
              </Important>
            )}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={3}
          md={6}
          lg={userProjectedSalary ? 4 : 3}
          component="div"
        >
          <Hint>{t("common.logged")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <DurationFormat
                value={(billableHours || 0) + (nonBillableHours || 0)}
              />
            </Important>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={6}
          lg={userProjectedSalary ? 4 : 3}
          component="div"
        >
          <Hint>{t("common.billable")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <DurationFormat value={billableHours} />
            </Important>
          )}
        </Grid>
        {userProjectIncome && (
          <Grid
            item
            xs={12}
            sm={3}
            md={6}
            lg={userProjectedSalary ? 4 : 3}
            component="div"
          >
            <Hint>{t("common.revenue")}</Hint>
            {loading ? (
              <Skeleton height={20} width={100} />
            ) : (
              <Important>
                <ConnectedCurrencyFormat number={revenue} />
              </Important>
            )}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={3}
          md={6}
          lg={userProjectedSalary ? 4 : 3}
          component="div"
        >
          <Hint>{t("common.nonBillable")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <DurationFormat value={nonBillableHours} />
            </Important>
          )}
        </Grid>
      </Grid>
    </StyledLogged>
  );
};
