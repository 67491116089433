import React, { forwardRef } from "react";
import { StyledAvatar } from "./avatar/Avatar.styles";

export default forwardRef(({ initial, colorKey, color, ...rest }, ref) => {
  if (initial) {
    return (
      <StyledAvatar ref={ref} {...rest} initial={initial} color={color}>
        {initial}
      </StyledAvatar>
    );
  }

  if (colorKey) {
    return <StyledAvatar ref={ref} {...rest} initial={colorKey} />;
  }

  return <StyledAvatar ref={ref} {...rest} />;
});
