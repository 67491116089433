import { combineReducers } from "redux";
import period from "./period/reducer";
import budgetDialog from "./budgetDialog/reducer";
import snackbar from "./snackbar/reducer";
import burndown from "./burndown/reducer";
import basis from "./basis/reducer";
import project from "./project/reducer";
import plan from "./plan/reducer";
import invoice from "./invoice/reducer";
import addUserDialog from "./addUserDialog/reducer";

export default combineReducers({
  project,
  plan,
  period,
  invoice,
  budgetDialog,
  snackbar,
  burndown,
  basis,
  addUserDialog
});
