import styled from "styled-components";
import Button from "@superprofit/core-react-components/atoms/Button";
import Fab from "@superprofit/core-react-components/atoms/Fab";

export const AddAction = styled(Button)`
  ${props => props.theme.breakpoints.down("xs")} {
    display: none;
  }
  float: right;
`;

export const FloatingAddAction = styled(Fab)`
  display: none;
  z-index: 1;
  position: fixed;
  bottom: ${props => props.theme.spacing(4)}px;
  right: ${props => props.theme.spacing(4)}px;

  ${props => props.theme.breakpoints.down("xs")} {
    display: initial;
  }
`;
