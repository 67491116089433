import styled from "styled-components";
import { Line } from "react-chartjs-2";

export const StyledLegends = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
`;

export const StyledLineChart = styled(Line)`
  // height: ${props => props.height || 200}px !important;
  // max-height: ${props => props.height || 200}px !important;
  // canvas {
  //   height: ${props => props.height || 200}px !important;
  //   max-height: ${props => props.height || 200}px !important;
  // }
`;
