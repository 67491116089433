import { STAGE_UPDATES, SET_PLAN, SET_LOADING, SET_SAVING } from "./constants";

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const setSaving = isSaving => ({
  type: SET_SAVING,
  payload: { isSaving }
});

export const stageUpdates = updates => ({
  type: STAGE_UPDATES,
  payload: { updates }
});

export const setPlan = (plan = {}) => ({
  type: SET_PLAN,
  payload: {
    plan
  }
});
