import { takeLatest, put, call, all, select } from "redux-saga/effects";
import { WATCH_GET, WATCH_SAVE } from "./constants";
import {
  get,
  getFailure,
  getSuccess,
  save,
  saveSuccess,
  saveFailure, watchGetUserSettings, watchSaveUserSettings
} from "./actions";
import UserSetting from "../../../../models/UserSetting";
import { Condition, Query } from "@superprofit/core-firestore-models";
import {watchGetTimesheetComments} from "../timesheet/comments/actions";
import UserSettingGeneric from "../../../../models/UserSettingGeneric";
import UserSettingGoal from "../../../../models/UserSettingGoal";

export const getActiveWorkspace = ({ api: { auth } }: {
  api: { auth: { activeWorkspaceId: string } };
}) => auth.activeWorkspaceId;

export const getUserEmail = ({
  api: {
    auth: { user }
  }
}: {
  api: { auth: { user: { email: string } } }
}) => user.email;

export const getUserSettings = function* getUserSettings(args: ReturnType<typeof watchGetUserSettings>) {
  try {
    yield put(get());
    const workspace:string  = yield select(getActiveWorkspace);
    const user: string = yield select(getUserEmail);
    const query = new Query();
    query.addCondition(new Condition("user", "==", user));
    const result: UserSettingGoal | UserSettingGeneric = yield call(UserSetting.map, workspace, user, query);
    yield put(getSuccess(result));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
    yield put(getFailure(e));}
  }
};

export const saveUserSettings = function* saveUserSettings(args: ReturnType<typeof watchSaveUserSettings>) {
  const {
    payload: { settings }
  } = args;
  try {
    yield put(save());
    const workspace:string  = yield select(getActiveWorkspace);
    const user: string = yield select(getUserEmail);

    const result: Array<UserSettingGeneric | UserSettingGoal> = yield all(
      settings.map((setting) => UserSetting.save(workspace, user, setting))
    );
    yield put(saveSuccess(result));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {    yield put(saveFailure(e));}
  }
};

export const getUserSettingsSaga = function* getUserSettingsSaga() {
  yield takeLatest(WATCH_GET, getUserSettings);
};

export const saveUserSettingsSaga = function* saveUserSettingsSaga() {
  yield takeLatest(WATCH_SAVE, saveUserSettings);
};

export default function*() {
  yield all([getUserSettingsSaga(), saveUserSettingsSaga()]);
}
