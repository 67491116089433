import * as yup from "yup";
import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import useWorkspace from "../../../hooks/useWorkspace";
import useTimetUserMutation from "../../../hooks/useTimetUserMutation";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ConfirmationDialog from "../../../components/molecules/ConfirmationDialog";
import FormDialog from "../../../components/molecules/FormDialog";
import Grid from "../../../components/atoms/Grid";
import TextField from "../../../components/atoms/TextField";
import Typography from "../../../components/atoms/Typography";
import useUserEditDialog from "./hooks/useUserEditDialog";
import useTimetUser, {
  getKey as getTimetUserKey
} from "../../../hooks/useTimetUser";
import useCompanySettings from "../../../hooks/useCompanySettings";
import { withFormController as withFormControllerTagInput } from "../../../components/atoms/TagInput";
import { withFormController as withFormControllerProjects } from "../../../components/molecules/MultiSelectProjects";
import useUnarchivedProjects from "../../../hooks/useUnarchivedProjects";
import Project from "../../../models/Project";
import useProjectsMap from "../../../hooks/useProjectsMap";
import useProjectMutation from "../../../hooks/useProjectMutation";
import useUser from "../../../hooks/useUser";
import useUserProjects from "../../../hooks/useUserProjects";
import { useQueryClient } from "@tanstack/react-query";
import { getKey as getProjectsKey } from "../../../hooks/useProjects";
import useFeatures from "../../../hooks/useFeatures";

interface IFormInput {
  capacity: number;
  tags: string[];
  projects: string[];
  employeeNumber: number;
}

const MultiSelectProjectsFormController = withFormControllerProjects<
  IFormInput
>();
const TagInputFromController = withFormControllerTagInput<IFormInput>();
const schema = yup
  .object({
    capacity: yup.number(),
    employeeNumber: yup
      .number()
      .transform((value, originalValue) => {
        if (typeof originalValue === "string" && originalValue.trim() === "") {
          return null;
        }
        return value;
      })
      .nullable()
  })
  .required();
export default ({ ...rest }) => {
  const { t } = useTranslation();
  const features = useFeatures();
  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);
  const workspace = useWorkspace();
  const mutation = useTimetUserMutation();
  const projectMutation = useProjectMutation();
  const { data: settings } = useCompanySettings();
  const queryClient = useQueryClient();
  const {
    data: projects,
    isInitialLoading: isInitialLoadingProjects,
    isSuccess: isSuccessProjects
  } = useUnarchivedProjects();
  const { open, close, id } = useUserEditDialog();
  const {
    data: user,
    isInitialLoading: isInitialLoadingUser,
    isSuccess: isSuccessUser
  } = useTimetUser({ id });
  const currentUser = useUser();
  const projectsMap = useProjectsMap(projects);
  const userProjects = useUserProjects(projects, user?.id);

  const isInitialLoading = isInitialLoadingProjects || isInitialLoadingUser;
  const isSuccess = isSuccessUser && isSuccessProjects;
  const shouldResetForm = !isInitialLoading && isSuccess;
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    shouldUnregister: true,
    defaultValues: {
      projects: []
    }
  });

  useEffect(() => {
    if (shouldResetForm) {
      reset({
        capacity: user?.capacity,
        tags: user?.tags,
        projects: userProjects.map((project: Project) => project.id),
        employeeNumber: user?.metadata?.employeeNumber
      });
    }
  }, [shouldResetForm, reset, user, userProjects]);

  const handleConfirmClose = () => {
    close();
  };

  const handleCancelClose = () => {
    setShowUnsavedConfirmation(false);
  };

  const handleOnClose = () => {
    if (mutation.isLoading) return;
    else {
      handleConfirmClose();
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    const {
      capacity,
      tags = [],
      projects: projectIds = [],
      employeeNumber
    } = data;

    if (!user?.id) return;
    await mutation.mutateAsync(
      {
        updates: { capacity, tags, metadata: { employeeNumber } },
        genericUser: user,
        initiatorEmail: currentUser.email
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(getTimetUserKey(workspace, user.id));
          close();
        }
      }
    );

    const projectsToAdd = projectIds.filter(
      (projectId: string) =>
        !userProjects.find((project: Project) => project.id === projectId)
    );

    const projectsToRemove = userProjects.filter(
      (project: Project) => project.id && !projectIds.includes(project.id)
    );

    for (let i = 0; i < projectsToAdd.length; i++) {
      const project = projectsMap.get(projectsToAdd[i]);
      if (!project) continue;
      const team = Array.from(new Set([...project.team, user.id]));
      await projectMutation.mutateAsync(
        {
          updates: { team },
          genericProject: project
        },
        {
          onSuccess: (updatedProject: Project) => {
            queryClient.setQueryData<Project[]>(
              getProjectsKey(workspace),
              old =>
                old?.map(project =>
                  project.id === updatedProject.id ? updatedProject : project
                )
            );
          }
        }
      );
    }

    for (let i = 0; i < projectsToRemove.length; i++) {
      const project = projectsMap.get(projectsToRemove[i].id);
      if (!project) continue;
      const team = project.team.filter((userId: string) => userId !== user.id);
      await projectMutation.mutateAsync(
        {
          updates: { team },
          genericProject: project
        },
        {
          onSuccess: (updatedProject: Project) => {
            queryClient.setQueryData<Project[]>(
              getProjectsKey(workspace),
              old =>
                old?.map(project =>
                  project.id === updatedProject.id ? updatedProject : project
                )
            );
          }
        }
      );
    }

    await queryClient.invalidateQueries(getTimetUserKey(workspace, user.id));
  };
  return (
    <Fragment>
      <ConfirmationDialog
        key="unsaved"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showUnsavedConfirmation}
      />

      <FormDialog
        {...rest}
        keepMounted={false}
        PaperProps={{ component: "form", onSubmit: handleSubmit(onSubmit) }}
        SubmitButtonProps={{ type: "submit" }}
        key="form"
        saveActionTitle={t("common.save")}
        open={open}
        onClose={handleOnClose}
        title={t("pages.users.editUserTitle")}
        inProgress={mutation.isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">{user?.displayName}</Typography>
            <Typography variant="subtitle1">{user?.email}</Typography>
          </Grid>
          {features?.nettlonn && (
            <Grid item xs={12}>
              <TextField
                {...register("employeeNumber")}
                fullWidth
                variant="filled"
                label={t("common.employeeNumber")}
                helperText={t("pages.users.employeeNumberHelperText")}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              {...register("capacity")}
              fullWidth
              variant="filled"
              label={t("common.capacity")}
              defaultValue={user?.capacity}
              placeholder={settings?.defaultCapacity?.value}
              helperText={t("pages.users.capacityHelperText")}
              // helperText="Capacity, in hours, during a normal work week"
            />
          </Grid>
          <Grid item xs={12}>
            <TagInputFromController
              ControllerProps={{ control, name: "tags" }}
              defaultValue={user?.tags || []}
              fullWidth
              style={{ marginBottom: 40 }}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelectProjectsFormController
              ControllerProps={{ control, name: "projects" }}
              projects={projects}
            />
          </Grid>
        </Grid>
      </FormDialog>
    </Fragment>
  );
};
