import React, { forwardRef, ForwardRefExoticComponent } from "react";
import { StyledCheckbox } from "./checkbox/Checkbox.styles";
import { CheckboxProps } from "@material-ui/core";
import { Controller, ControllerProps } from "react-hook-form";

interface IProps extends Omit<CheckboxProps, "color"> {
  color?: "default" | "primary" | "secondary" | undefined | "alert";
}
export const Checkbox: ForwardRefExoticComponent<IProps> = forwardRef(
  ({ color = "primary", ...rest }, ref) => (
    <StyledCheckbox
      {...rest}
      ref={ref}
      color={color === "alert" ? "primary" : color}
      extcolor={color === "alert" ? "alert" : undefined}
    />
  )
);

interface CheckboxFormControllerProps<T> extends CheckboxProps {
  ControllerProps: Omit<ControllerProps<T>, "render">;
}
export function withFormController<FieldValues>() {
  return (props: CheckboxFormControllerProps<FieldValues>) => {
    const { ControllerProps } = props;
    const { name, control } = ControllerProps;
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value = false, ...rest } = field;
          return (
            <Checkbox
              {...rest}
              checked={value}
              onChange={e => field.onChange(e.target.checked)}
              color="primary"
            />
          );
        }}
      />
    );
  };
}
export default Checkbox;
