import React, { useEffect, useMemo, useRef, useState } from "react";
import Page from "@superprofit/core-react-components/atoms/Page";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { watchGetProjects } from "../../redux/modules/ui/projects/table/actions";
import ProjectsCard from "./projects/ProjectsCard";
import { CreatePDFDialog } from "../../components/organisms/PDFRenderer";
import { Button } from "@superprofit/core-react-components/atoms";
import SearchField from "@superprofit/core-react-components/atoms/SearchField";
import AddIcon from "@material-ui/icons/Add";
import Snackbar from "@material-ui/core/Snackbar";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import TutorialContextProvider from "../../contexts/TutorialContext";
import TutorialStep from "../../components/atoms/tutorialStep/TutorialStep";
import { watchGetUserGroups } from "../../redux/modules/api/userGroups/actions";
import useDialog from "./projects/hooks/useDialog";
import useUnarchivedProjects from "../../hooks/useUnarchivedProjects";
import { useTranslation } from "react-i18next";
import ProjectDialogV2 from "./projects/ProjectDialog";

export default () => {
  const dispatch = useDispatch();

  const addButtonRef = useRef();
  const { t } = useTranslation();

  const [filter, setFilter] = useState("");

  // const { list: projects, loading } = useSelector(
  //   state => state.ui.projects.table
  // );

  const { data: projects, isLoading: loading } = useUnarchivedProjects();

  const { show } = useDialog();

  const snackbar = useSelector(state => state.ui.projects.snackbar);

  const selection = useSelector(state => state.ui.projects.selection.selected);

  const activeProjects = useMemo(() => {
    if (loading || !projects) return [];
    return projects.filter(p => !p.archived);
  }, [projects, loading]);

  const filteredProjects = React.useMemo(() => {
    return activeProjects.filter(
      p =>
        filter === "" || p.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
    );
  }, [activeProjects, filter]);

  useEffect(() => {
    dispatch(watchGetUserGroups());
    dispatch(watchGetProjects());
  }, [dispatch]);

  const handleOnAdd = () => {
    show();
  };

  const handleOnFilter = e => {
    const val = e.target.value;
    setFilter(val || "");
  };

  return (
    <TutorialContextProvider
      type="projects"
      steps={[
        {
          title: "Add your first project",
          message: 'Begin by clicking "+New" button to add a project.'
        },
        {
          title: "Add you first users",
          message: "Invite / add a team to be part of your project(s)",
          link: "/users",
          linkText: "Go to users"
        }
      ]}
    >
      <Page context={t("common.management")} title={t("common.projects")}>
        <Grid container spacing={3} component="div">
          <Grid container item xs={6} component="div">
            <SearchField
              placeholder={t("common.search")}
              onChange={handleOnFilter}
            />
          </Grid>

          <Grid container item xs={6} component="div" justify="flex-end">
            <TutorialStep index={0} immediateNext={false}>
              <Button
                id="add-project-button"
                ref={addButtonRef}
                color="primary"
                variant="contained"
                onClick={handleOnAdd}
                startIcon={<AddIcon />}
              >
                {t("common.create")}
              </Button>
            </TutorialStep>
          </Grid>

          <Grid item xs={12} component="div">
            <ProjectsCard
              projects={filteredProjects}
              loading={loading}
              selection={selection}
            />
          </Grid>
        </Grid>

        <CreatePDFDialog />
        {/*<ProjectDialog />*/}
        <ProjectDialogV2 />
        <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            open={snackbar.visible}
            message={snackbar.text}
          />
        </AppBar>
      </Page>
    </TutorialContextProvider>
  );
};
