import React from "react";
import { moment } from "@superprofit/time-util";
import {
  Amount,
  Container,
  Row,
  Date,
  Cost,
  Price,
  Sum,
  HeaderRow,
  TotalRow,
  User
} from "./hoursTable/hoursTable.styles";
import { format } from "@superprofit/core-react-components/atoms/NumberFormatIntl";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// const formatNumber = (currency, number) => {
//   const intlOptions = {
//     style: "currency",
//     currency,
//   };
// };
// // new Intl.NumberFormat("nb-NO", { maximumSignificantDigits: 3 }).format(
// new Intl.NumberFormat("nb-NO", { minimumFractionDigits: 2 }).format(number);

export default ({
  currency,
  entries = [],
  users = [],
  project,
  showUser,
  showTotal = true,
  showHeader = true,
  getTotalHours,
  getTotalCost
}) => {
  let totalHours = 0;
  const { t } = useTranslation();

  const rows = [];
  const getDisplayName = email =>
    users.find(u => u.id === email)?.displayName || email;

  const f = number => {
    let options = {};
    if (currency) {
      options = { style: "currency", currency };
    }
    return format({ number, options });
  };

  entries.forEach(e => {
    totalHours += e.hours;
    if (e.hours > 0) {
      const billableRate =
        project.userBillableRate && project.userBillableRate[e.user]
          ? project.userBillableRate[e.user]
          : project.billableRate || 0;
      rows.push(
        <Row key={`${e.id}_row`}>
          <Date>
            {moment({ year: e.year })
              .add(e.dayOfYear - 1, "day")
              .format("DD.MMMM - dddd")}
          </Date>
          {showUser && <User>{getDisplayName(e.user)}</User>}
          <Amount>{e.hours}</Amount>
          <Price>{f(billableRate)}</Price>
          <Cost>{f(e.hours * billableRate)}</Cost>
        </Row>
      );
    }
  });
  if (getTotalHours) totalHours = getTotalHours();
  const totalCost = getTotalCost
    ? getTotalCost()
    : totalHours * (project.billableRate || 0);
  return (
    <Container>
      {showHeader && (
        <HeaderRow>
          <Date>Dato</Date>
          <Amount>Antall</Amount>
          <Price>Pris</Price>
          <Cost>Beløp</Cost>
        </HeaderRow>
      )}
      {rows}
      {showTotal && (
        <TotalRow>
          <Date>{t("common.total")}</Date>
          <Amount>{totalHours}</Amount>
          {/*<Price>{formatNumber(project.billableRate || 0)}</Price>*/}
          <Price> </Price>
          <Cost>{f(totalCost)}</Cost>
        </TotalRow>
      )}
    </Container>
  );
};
