import {
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  all, take,
} from "redux-saga/effects";
import UserGroup from "../../../../../models/UserGroup";
import {WATCH_GET_USERS, WATCH_SAVE} from "./constants";
import { watchGetUsers} from "@superprofit/core-redux-saga-modules/Users/actions";
import { watchSaveUserGroup } from "../../../api/userGroups/actions";
import { SAVE_SUCCESS, SAVE_FAILURE } from "../../../api/userGroups/constants";
import { RootState } from "../../../../../index";
import {hideDialog, setError, setSaving} from "./actions";
import {delay} from "@redux-saga/core/effects";


const getUserGroup = ({ ui: { userGroups } }: RootState) =>
  userGroups.dialog.userGroup;

export const saveUserGroup = function* (): any {
  const userGroup: UserGroup = yield select(getUserGroup);
  yield put(watchSaveUserGroup(userGroup));
  const response = yield take([SAVE_SUCCESS, SAVE_FAILURE]);
  yield put(setSaving(true))
  yield delay(300); // Give an impression of loading
  if (response.type === SAVE_SUCCESS) {
    yield put(hideDialog())
  } else {
    console.error('[ui/usergroups/dialog/saga] An error occured', response)
    yield put(setError('Could not save UserGroup'))
  }
  yield put(setSaving(false))

};

export const getUsers = function* () {
  yield put(watchGetUsers());
};

export const saveUserGroupSaga = function* () {
  yield takeEvery(WATCH_SAVE, saveUserGroup);
};
export const getUsersSaga = function* () {
  yield takeEvery(WATCH_GET_USERS, getUsers);
};

export default function* () {
  yield all([saveUserGroupSaga(), getUsersSaga()]);
}
