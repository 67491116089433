import styled, { css } from "styled-components";
import { Select } from "@material-ui/core";
import theme from "styled-theming";
import { darken } from "../../themes/shared/colorUtils";
import CircularProgress from "../CircularProgress";

const variants = theme.variants("mode", "variant", {
  filled: {
    light: css`
      &.MuiInputBase-root {
        background: ${(props) =>
          darken(props.theme.palette.background.default, 0.05)};
        &::before {
          display: none;
        }
        .MuiFilledInput-input {
          font-size: 1rem;
          padding-top: 20px;
        }

        .MuiInputLabel-formControl {
          position: initial;
          top: initial;
          left: initial;
        }
        .MuiInputLabel-filled,
        .MuiInputLabel-filled.MuiInputLabel-shrink {
          transform: none; //translate(12px, 5px) scale(0.75);
        }
        .MuiInputLabel-root {
          text-transform: uppercase;
          margin-bottom: ${(props) => props.theme.spacing(1)}px;
          text-align: left;
          font-size: ${(props) => props.theme.typography.h2.fontSize};
          font-weight: ${(props) => props.theme.typography.h2.fontWeight};
          letter-spacing: ${(props) =>
            props.theme.typography.h2.letterSpacing}px;
        }
      }
    `,
    dark: css``,
  },
});

export const AsyncContainer = styled.div`
  position: relative;
  ${({ loading }) => loading && css`
    .MuiSelect-icon {
      display: none;
    }
`};
`;

export const Spinner = styled(CircularProgress)`
  position: absolute;
  right: 10px;
  top: 15px;
`;

export const StyledSelect = styled(Select)`
  ${variants}
`;
