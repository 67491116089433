import { db } from "../firebase";
import { QueryBuilder } from "@superprofit/core-firestore-models";

export default class XstatsProjectHoursByWeek {
  static collectionName = "xstats_project_hours_by_week";

  static defaultListLimit = 25;

  static converter = {
    toFirestore(customer) {
      return customer.data();
    },
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      return new XstatsProjectHoursByWeek({ ...data, id: snapshot.id });
    }
  };

  constructor({
    id,
    billable,
    billableRate,
    month,
    project,
    total,
    week,
    year
  }) {
    this.id = id;
    this.billable = billable;
    this.billableRate = billableRate;
    this.month = month;
    this.project = project;
    this.total = total;
    this.week = week;
    this.year = year;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  // setData(updates) {
  //   return Object.assign(this, updates);
  // }

  isValid = () => {
    return true;
  };

  data() {
    return {
      billable: this.billable,
      billableRate: this.billableRate,
      month: this.month,
      project: this.project,
      total: this.total,
      week: this.week,
      year: this.year
    };
  }

  static list = async (
    workspace,
    query,
    limit = XstatsProjectHoursByWeek.defaultListLimit
  ) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(XstatsProjectHoursByWeek.collectionName),
      query
    )
      .withConverter(XstatsProjectHoursByWeek.converter)
      .limit(limit)
      .get();

    return snapshot.docs.map(doc => doc.data());
  };
}
