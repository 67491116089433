import { PeriodAction, State } from "./types";
import { getYear, getISOWeek as getWeek } from "date-fns";
import { createReducer } from "typesafe-actions";
import { SET } from "./constants";

const initialState: State = {
  year: getYear(new Date()),
  week: getWeek(new Date())
};

const reducer = createReducer<State, PeriodAction>(initialState).handleType(
  SET,
  (state, action) => action.payload.period
);

export default reducer;
