import useProjects from "./useProjects";
import { useMemo } from "react";
import Project from "../models/Project";

const useUnarchivedProjects = () => {
  const query = useProjects();

  const data = useMemo(() => {
    if (query.data) {
      return query.data.filter((p: Project) => !p.archived);
    }
  }, [query.data]);

  return { ...query, data };
};

export default useUnarchivedProjects;
