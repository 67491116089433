import {SET_LOADING, CREATE_INVOICE} from './constants';

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const createInvoice = () => ({
  type: CREATE_INVOICE,
});
