import { SET_FILTERS, RESET_FILTERS } from "./constants";

const initialState = {
  filters: {
    type: "All",
    projects: [],
    users: [],
    customers: []
  },
  hasFilters: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
        hasFilters: Object.keys(action.payload.filters).length > 0
      };

    case RESET_FILTERS:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
