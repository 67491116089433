import React from "react";
import { Chip, Tooltip } from "@superprofit/core-react-components/atoms";

export default ({ tags = [] }) => {
  if (tags.length === 0) return <></>;
  const newestThree = tags.slice(0, 3) || [];
  const rest = tags.length > 3 && tags.slice(3);
  return (
    <>
      {newestThree.map(t => (
        <Chip
          key={t}
          style={{
            marginRight: 4,
            marginBottom: 2,
            fontWeight: 500,
            fontSize: ".8rem"
          }}
          label={t}
          onClick={noop => noop}
        />
      ))}
      {rest &&
        rest.reduce((acc, curr, index) => {
          const title = `${acc}${curr}`;
          if (index + 1 < rest.length) return `${title}, `;
          return (
            <Tooltip
              key={`advanced_table_modal_list_tooltip_tags_rest_tooltip`}
              title={title}
            >
              <Chip
                key="advanced_table_modal_list_tooltip_tags_rest_chip"
                label={`+${index + 1}`}
                onClick={noop => noop}
              />
            </Tooltip>
          );
        }, "")}
    </>
  );
};
