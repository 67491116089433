import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
  DELETE,
  DELETE_FAILURE,
  DELETE_SUCCESS,
  PATCH,
  PATCH_FAILURE,
  PATCH_SUCCESS,
  GET_PROJECT,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILURE,
  PATCH_MULTIPLE_FAILURE,
  PATCH_MULTIPLE_SUCCESS,
  PATCH_MULTIPLE, DUPLICATE, DUPLICATE_SUCCESS, DUPLICATE_FAILURE,
} from "./constants";
import { Project, ProjectsAction, ProjectsState } from "./types";
import { createReducer } from "typesafe-actions";

// const initialState: UserGroupsState = {
//   list: [],
//   loading: false,
//   userGroupSaving: null,
//   userGroupDeleting: null
// };

const initialState: ProjectsState = {
  list: [],
  lastUpdated: null,
  loading: false,
  loadingIdMap: {},
  errorIdMap: {},
  saveError: null,
  saving: false,
};

const addOrUpdate = (list: ProjectsState["list"], project: Project) => {
  const idx = list.findIndex((p) => p.id === project.id);
  if (idx < 0) {
    return [project].concat(list);
  } else {
    return list
      .slice(0, idx)
      .concat(project)
      .concat(list.slice(idx + 1));
  }
};

const updateList = (list: Project[], project: Project) => {
  const newList = list.slice();
  const idx = newList.findIndex((c) => c.id === project.id);
  newList[idx] = project;
  return newList;
};

const patchProject = (
  list: ProjectsState["list"],
  id: string,
  updates: Partial<Project>
) => {
  const newList = list.slice();
  const idx = newList.findIndex((c) => c.id === id);
  if (idx > -1) {
    newList[idx] = newList[idx].clone();
    newList[idx].setData(updates);
  }
  return newList;
};

const reducer = createReducer<ProjectsState, ProjectsAction>(initialState)
  .handleType(GET, (state) => ({
    ...state,
    loading: true,
  }))
  .handleType(GET_FAILURE, (state) => ({
    ...state,
    loading: false,
  }))
  .handleType(GET_SUCCESS, (state, { payload }) => ({
    ...state,
    loading: false,
    list: payload.list,
    lastUpdated: Date.now(),
  }))
  .handleType(SAVE, (state) => ({ ...state, loading: true }))
  .handleType(SAVE_FAILURE, (state, { payload }) => ({
    ...state,
    saving: false,
    list: addOrUpdate(state.list, payload.project)
  }))
  .handleType(SAVE_SUCCESS, (state, { payload }) => ({
    ...state,
    saving: false,
    list: addOrUpdate(state.list, payload.project)
  }))
  .handleType(DUPLICATE, (state) => ({ ...state, loading: true }))
  .handleType(DUPLICATE_FAILURE, (state, { payload }) => ({
    ...state,
    saving: false,
    list: addOrUpdate(state.list, payload.project)
  }))
  .handleType(DUPLICATE_SUCCESS, (state, { payload }) => ({
    ...state,
    saving: false,
    list: addOrUpdate(state.list, payload.project)
  }))
  .handleType(DELETE, (state) => ({ ...state, loading: true }))
  .handleType(DELETE_FAILURE, (state) => ({ ...state }))
  .handleType(DELETE_SUCCESS, (state,  {payload}) => ({
    ...state,
    list: state.list.filter(p => p.id !== payload.id),
    loadingIdMap: { ...state.loadingIdMap, [payload.id]: false }
  }))
  .handleType(PATCH, (state) => ({
    ...state,
    saving: true
  }))
  .handleType(PATCH_FAILURE, (state, { payload }) => ({
    ...state,
    saving: false,
    saveError: payload.error
  }))
  .handleType(PATCH_SUCCESS, (state, { payload }) => ({
    ...state,
    saving: false,
    list: patchProject(state.list, payload.id, payload.updates)
  }))
  .handleType(GET_PROJECT, (state, { payload }) => ({
    ...state,
    loadingIdMap: {
      [payload.id]: true,
    },
  }))
  .handleType(GET_PROJECT_FAILURE, (state) => ({ ...state }))
  .handleType(GET_PROJECT_SUCCESS, (state, { payload }) => ({
    ...state,
    loadingIdMap: {
      [payload.id]: true,
    },
    list: addOrUpdate(state.list, payload.project), // addIfNotExists(state.list, payload.project),
    lastUpdated: Date.now(),
  }))
  .handleType(PATCH_MULTIPLE, (state) => ({
    ...state,
    saving: true
  }))
  .handleType(PATCH_MULTIPLE_FAILURE, (state) => ({
    ...state,
    saving: false,
  }))
  .handleType(PATCH_MULTIPLE_SUCCESS, (state, { payload }) => ({
    ...state,
    saving: false,
    list: payload.ids.reduce(
        (prev, next) =>
            patchProject(prev, next, {
              ...payload.updates,
              ...payload.updatesById[next]
            }),
        state.list
    )
  }));

export default reducer;

/*
export default (state = initialState, { type, payload = {} }) => {
  const { id, error } = payload;
  switch (type) {
    case GET_PROJECT:
      return {
        ...state,
        loadingIdMap: {
          [payload.id]: true
        }
      };

    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        loadingIdMap: {
          [payload.id]: true
        },
        list: addOrUpdate(state.list, payload.project), // addIfNotExists(state.list, payload.project),
        lastUpdated: Date.now()
      };

    case GET_PROJECT_FAILURE:
      return {
        ...state,
        loadingIdMap: {
          [payload.id]: true
        }
      };

    case GET:
      return {
        ...state,
        loading: true
      };

    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.list,
        lastUpdated: Date.now()
      };

    case GET_FAILURE:
      return {
        ...state,
        loading: false,
        list: payload.list
      };

    case SAVE:
      return {
        ...state,
        saving: true
      };

    case SAVE_SUCCESS: {
      return {
        ...state,
        saving: false,
        list: addOrUpdate(state.list, payload.project)
      };
    }

    case PATCH:
      return {
        ...state,
        saving: true
      };

    case PATCH_SUCCESS:
      return {
        ...state,
        saving: false,
        list: patchProject(state.list, payload.id, payload.updates)
      };

    case PATCH_FAILURE:
      return {
        ...state,
        saving: false,
        saveError: payload.error
      };

    case PATCH_MULTIPLE:
      return {
        ...state,
        saving: true
      };

    case PATCH_MULTIPLE_SUCCESS:
      return {
        ...state,
        saving: false,
        list: payload.ids.reduce(
            (prev, next) =>
                patchProject(prev, next, {
                  ...payload.updates,
                  ...payload.updatesById[next]
                }),
            state.list
        )
      };

    case PATCH_MULTIPLE_FAILURE:
      return {
        ...state,
        saving: false,
        saveError: payload.error
      };

    case SAVE_FAILURE:
      return {
        ...state,
        saveError: error
      };

    case DELETE:
      return {
        ...state,
        loadingIdMap: { ...state.loadingIdMap, [id]: true }
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(p => p.id !== id),
        loadingIdMap: { ...state.loadingIdMap, [id]: false }
      };
    case DELETE_FAILURE:
      return {
        ...state,
        loadingIdMap: { ...state.loadingIdMap, [id]: false },
        errorIdMap: { ...state.errorIdMap, [id]: error }
      };

    default:
      return state;
  }
};*/
