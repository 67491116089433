import React from "react";
import PropTypes from "prop-types";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
//   Button
// } from "@superprofit/core-react-components/atoms";
import { DialogProps } from "@material-ui/core";
import DialogActions from "../atoms/DialogActions";
import Button from "../atoms/Button";
import DialogTitle from "../atoms/DialogTitle";
import DialogContent from "../atoms/DialogContent";
import DialogContentText from "../atoms/DialogContentText";
import Dialog from "../atoms/Dialog";
import { useTranslation } from "react-i18next";

interface IConfirmationDialog extends DialogProps {
  onConfirm: () => void;
  onClose: () => void;
  open: boolean;
  title?: string;
  text?: string;
}
const ConfirmationDialog = (props: IConfirmationDialog) => {
  const { onClose, onConfirm, title, text, open, ...rest } = props;
  const { t } = useTranslation();
  const handleOnConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      {...rest}
      open={open}
      onClose={noop => noop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title || t("components.atoms.confirmationDialog.defaultTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text || t("components.atoms.confirmationDialog.defaultText")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          {t("components.atoms.confirmationDialog.cancel")}
        </Button>
        <Button onClick={handleOnConfirm} color="alert" autoFocus>
          {t("components.atoms.confirmationDialog.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
