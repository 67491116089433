import { UPDATE, ERROR } from "./constants";

export const update = updates => ({
  type: UPDATE,
  payload: { updates }
});

export const error = errors => ({
  type: ERROR,
  payload: { errors }
});
