import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
  DELETE,
  DELETE_FAILURE,
  DELETE_SUCCESS
} from "./constants";

const initialState = {
  list: [],
  lastUpdated: Date.now(),
  loading: false,
  pagination: {},
  error: null
};

const addOrUpdate = (list, user) => {
  const idx = list.findIndex(u => u.id === user.id);
  if (idx < 0) {
    return [user].concat(list);
  } else {
    return list.slice(0, idx)
      .concat(user)
      .concat(list.slice(idx + 1));
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET:
      return {
        ...state,
        list: [],
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.list
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    case SAVE:
      return {
        ...state,
        loading: true
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: addOrUpdate(state.list, payload.user)
      };
    case SAVE_FAILURE:
      return {
        ...state,
        error: payload.error,
        loading: false
      };
    case DELETE:
      return {
        ...state,
        loading: true
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DELETE_FAILURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
