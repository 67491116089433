import { all, put, takeLatest } from "redux-saga/effects";
import { PATCH_MULTIPLE_SUCCESS } from "../../../api/projects/constants";

import { setSelectedProjects } from "./actions";

export const clearSelection = function*(args) {
  try {
    yield put(setSelectedProjects([]));
  } catch (e) {
    console.warn(e);
  }
};

export const handleClearSelectionSaga = function*() {
  yield takeLatest([PATCH_MULTIPLE_SUCCESS], clearSelection);
};

export default function*() {
  yield all([handleClearSelectionSaga()]);
}
