import React, { Fragment, useMemo, useState } from "react";
import FormDialog from "@superprofit/core-react-components/molecules/FormDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  hideFilterDialog,
  updateFilterDialogData
} from "../../../../redux/modules/ui/report/filtersDialog/actions";
import { setFilters } from "../../../../redux/modules/ui/report/filters/actions";
import ConfirmationDialog from "../../../../components/molecules/ConfirmationDialog";
import MultiSelectProjects from "../../../../components/molecules/MultiSelectProjects";
import AutocompleteUsers from "@superprofit/core-react-components/molecules/AutocompleteUsers";
import Select from "@superprofit/core-react-components/atoms/Select";
import MenuItem from "@superprofit/core-react-components/atoms/MenuItem";
import InputLabel from "@superprofit/core-react-components/atoms/InputLabel";
import MultiSelectCustomers from "../../../../components/molecules/MultiSelectCustomers";
import { useTranslation } from "react-i18next";
import useUnarchivedProjects from "../../../../hooks/useUnarchivedProjects";
import useCustomersMap from "../../../../hooks/useCustomersMap";
import useProjectsMap from "../../../../hooks/useProjectsMap";
import useUsersMap from "../../../../hooks/useUsersMap";
import useCustomers from "../../../../hooks/useCustomers";
import useUsers from "../../../../hooks/useUsers";

export default ({ ...rest }) => {
  const [isClosable, setIsClosable] = useState(true);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);

  const { open, filters, hasUnsaved } = useSelector(
    state => state.ui.report.filtersDialog
  );

  // const { projects, users, customers } = useSelector(
  //   state => state.ui.report.basis
  // );

  const { data: projects = [] } = useUnarchivedProjects();
  const { data: users = [] } = useUsers();
  const { data: customers = [] } = useCustomers();

  const customersMap = useCustomersMap();
  const projectsMap = useProjectsMap();
  const usersMap = useUsersMap();

  // const projectsById = useMemo(
  //   () =>
  //     projects.reduce(
  //       (prev, next) => ({
  //         ...prev,
  //         [next.id]: next
  //       }),
  //       {}
  //     ),
  //   [projects]
  // );
  //
  // const usersById = useMemo(
  //   () =>
  //     users.reduce(
  //       (prev, next) => ({
  //         ...prev,
  //         [next.id]: next
  //       }),
  //       {}
  //     ),
  //   [users]
  // );
  //
  // const customersById = useMemo(
  //   () =>
  //     customers.reduce(
  //       (prev, next) => ({
  //         ...prev,
  //         [next.id]: next
  //       }),
  //       {}
  //     ),
  //   [customers]
  // );

  const filteredProjects = useMemo(() => {
    if (filters.customers.length === 0) return projects;

    return projects.filter(p => {
      return filters.customers.indexOf(p.customer) > -1;
    });
  }, [filters.customers, projects]);

  const handleConfirmClose = () => {
    dispatch(hideFilterDialog());
  };

  const handleCancelClose = () => {
    setShowUnsavedConfirmation(false);
  };

  const handleOnClose = e => {
    if (!isClosable) return;
    if (hasUnsaved) {
      setShowUnsavedConfirmation(true);
    } else {
      handleConfirmClose();
    }
  };

  const handleOnBlur = (e, key) => {
    dispatch(updateFilterDialogData({ [key]: e.target.value }));
  };

  const handleOnChangeProjects = (e, selected) => {
    dispatch(updateFilterDialogData({ projects: selected.map(p => p.id) }));
  };

  const handleOnChangeCustomers = (e, selected) => {
    const selectedCustomers = selected.map(c => c.id);

    const selectedProjects = filters.projects.filter(p => {
      return selectedCustomers.indexOf(projectsMap.get(p).customer) > -1;
    });

    dispatch(
      updateFilterDialogData({
        customers: selectedCustomers,
        projects: selectedProjects
      })
    );
  };

  const handleOnChangeUsers = (e, selected) => {
    dispatch(updateFilterDialogData({ users: selected.map(u => u.id) }));
  };

  const handleOnChangeType = e => {
    dispatch(updateFilterDialogData({ type: e.target.value }));
  };

  const handleOnApply = () => {
    dispatch(setFilters({ ...filters }));
    handleConfirmClose();
  };

  const hasProjectFilter = filters.projects.length > 0;
  const hasUserFilter = filters.users.length > 0;
  const hasCustomerFilter = filters.customers.length > 0;

  return (
    <Fragment>
      <ConfirmationDialog
        key="unsaved"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showUnsavedConfirmation}
      />
      <FormDialog
        {...rest}
        key="form"
        saveActionTitle="Apply"
        onSave={handleOnApply}
        open={open}
        onClose={handleOnClose}
        title="Filter"
      >
        <div>
          <InputLabel variant="standard" shrink={false} id="project-type-label">
            Type
          </InputLabel>
          <Select
            fullWidth
            variant="filled"
            labelId="project-type-label"
            id="project-budget-type"
            value={filters.type}
            name="project-type"
            onChange={handleOnChangeType}
          >
            {["All", "Billable", "Non-billable"].map(type => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </div>

        <MultiSelectCustomers
          onClose={() => {
            setIsClosable(true);
          }}
          value={filters.customers.map(c => customersMap.get(c))}
          customers={customers}
          onChange={handleOnChangeCustomers}
          onOpen={e => setIsClosable(false)}
          variant="filled"
          fullWidth
          label="Customers"
          placeholder={hasCustomerFilter ? "" : "All"}
        />

        <MultiSelectProjects
          onClose={() => {
            setIsClosable(true);
          }}
          value={filters.projects.map(p => projectsMap.get(p))}
          projects={filteredProjects}
          onChange={handleOnChangeProjects}
          onOpen={e => setIsClosable(false)}
          variant="filled"
          fullWidth
          label={t("common.projects")}
          placeholder={hasProjectFilter ? "" : "All"}
        />

        <AutocompleteUsers
          onClose={() => {
            setIsClosable(true);
          }}
          defaultValue={filters.users.map(u => usersMap.get(u))}
          users={users}
          onChange={handleOnChangeUsers}
          onOpen={e => setIsClosable(false)}
          variant="filled"
          fullWidth
          label={t("common.users")}
          placeholder={hasUserFilter ? "" : "All"}
        />
      </FormDialog>
    </Fragment>
  );
};
