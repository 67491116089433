export const GET = "api/userSettings/GET";
export const GET_SUCCESS = "api/userSettings/GET_SUCCESS";
export const GET_FAILURE = "api/userSettings/GET_FAILED";

export const SAVE = "api/userSettings/SAVE";
export const SAVE_SUCCESS = "api/userSettings/SAVE_SUCCESS";
export const SAVE_FAILURE = "api/userSettings/SAVE_FAILURE";

export const WATCH_GET = "api/userSettings/WATCH_GET";
export const WATCH_SAVE = "api/userSettings/WATCH_SAVE";
