import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel
} from "@superprofit/core-react-components/atoms";
import React, { useState } from "react";
import { dayNames } from "../../../helpers";

export default ({ name, defaultValue, onChange }) => {
  const [checked, setChecked] = useState(defaultValue);
  const days = dayNames();

  const handelCheck = e => {
    const value = e.target.value;
    const newValue = {
      ...checked,
      [value]: !checked[value]
    };
    setChecked(newValue);
    if (onChange) onChange(name, newValue);
  };

  return (
    <FormControl component="fieldset" margin="normal">
      <FormLabel component="legend">Default workdays</FormLabel>
      <FormGroup>
        {Object.keys(checked).map(d => (
          <FormControlLabel
            key={d}
            control={
              <Checkbox checked={checked[d]} onChange={handelCheck} value={d} />
            }
            label={days[d - 1]}
          />
        ))}
      </FormGroup>
      <FormHelperText>Normal working days during a week</FormHelperText>
    </FormControl>
  );
};
