import { actionTypes } from "../../reducer";

export default ({ dispatch, subscription, paymentMethodId, priceId }) => {
  if (subscription.status === "active") {
    // subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  } else if (
    subscription.latest_invoice.payment_intent.status ===
    "requires_payment_method"
  ) {
    // Using localStorage to manage the state of the retry here,
    // feel free to replace with what you prefer.
    // Store the latest invoice ID and status.

    /** Maybe use reducer later idk **/
    localStorage.setItem(
      "stripe_latestInvoiceId",
      subscription.latest_invoice.id
    );
    localStorage.setItem(
      "stripe_latestInvoicePaymentIntentStatus",
      subscription.latest_invoice.payment_intent.status
    );
    dispatch({
      type: actionTypes.setCreatedSubscriptionStatus,
      payload: {
        message: "Your card was declined.",
        status: "error",
      },
    });
  } else {
    return { subscription, priceId, paymentMethodId };
  }
};
