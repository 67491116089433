import React, { Fragment } from "react";
import { CloseOutlined } from "@material-ui/icons";
import {
  FormDialogContent,
  FormDialogTitle,
  StyledFormDialog
} from "./formDialog/FormDialog.styles";
import Toolbar from "../atoms/Toolbar";
import IconButton from "../atoms/IconButton";
import Button from "../atoms/Button";
import DialogActions from "../atoms/DialogActions";
import Divider from "../atoms/Divider";
import CircularProgress from "../atoms/CircularProgress";

export { FormDialogGroup } from "./formDialog/FormDialog.styles";

export default ({
  title,
  saveActionTitle,
  children,
  onSave,
  onClose,
  inProgress,
  disabled,
  ...rest
}) => {
  return (
    <StyledFormDialog {...rest} anchor="right" onClose={onClose}>
      <Toolbar>
        <FormDialogTitle variant="h6">{title}</FormDialogTitle>

        {inProgress && <CircularProgress color="inherit" size={30} />}

        {!inProgress && (
          <IconButton
            onClick={onClose}
            edge="start"
            color="inherit"
            aria-label="close"
          >
            <CloseOutlined />
          </IconButton>
        )}
      </Toolbar>

      <FormDialogContent>{children}</FormDialogContent>

      <Fragment>
        <Divider />
        <DialogActions>
          {inProgress && <CircularProgress size={30} />}
          {onSave && (
            <Button
              disabled={inProgress || disabled}
              autoFocus
              size="large"
              onClick={onSave}
              color="primary"
            >
              {saveActionTitle}
            </Button>
          )}
        </DialogActions>
      </Fragment>
    </StyledFormDialog>
  );
};
