import { Timestamp } from "@superprofit/core-firestore-models/dist";
import { db, firebase } from "../firebase";

type ProjectsTableFilters = { projects: string[] };
interface UserProfileData {
  id: string;
  planned: any;
  projectSortOrder: any;
  hiddenProjectTimesheets: any[];
  projectsTableFilters: ProjectsTableFilters;
}

export default class UserProfile {
  static collectionName = "userProfile";

  static converter = {
    toFirestore(profile: UserProfile): UserProfileData {
      return profile.data();
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions
    ): UserProfile {
      const data = snapshot.data(options) as UserProfileData;
      return new UserProfile({
        ...data,
        id: snapshot.id
      });
    }
  };
  id: string;
  planned: any;
  projectSortOrder: any;
  hiddenProjectTimesheets: any[];
  projectsTableFilters: ProjectsTableFilters;

  constructor({
    id,
    planned = {},
    projectSortOrder = {},
    hiddenProjectTimesheets = [],
    projectsTableFilters = { projects: [] }
  }: UserProfileData) {
    this.id = id;
    this.planned = planned;
    this.projectSortOrder = projectSortOrder;
    this.hiddenProjectTimesheets = hiddenProjectTimesheets;
    this.projectsTableFilters = projectsTableFilters;
  }

  clone(): UserProfile {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  data() {
    return {
      id: this.id,
      planned: this.planned || {},
      projectSortOrder: this.projectSortOrder || {},
      hiddenProjectTimesheets: this.hiddenProjectTimesheets || [],
      projectsTableFilters: this.projectsTableFilters || { projects: [] }
    };
  }

  setData(updates: Partial<UserProfileData>): UserProfile {
    Object.assign(this, updates);
    return this;
  }

  static save = async (
    workspace: string,
    user: string,
    profile: UserProfile,
    updates: Partial<UserProfileData>
  ): Promise<UserProfile> => {
    const updatedProfile = profile.clone();

    const allUpdates = {
      updatedAt: Timestamp.now(),
      updatedBy: user,
      ...updates
    };

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserProfile.collectionName)
      .doc(profile.id)
      .set(allUpdates, { merge: true });

    updatedProfile.setData(allUpdates);

    return updatedProfile;
  };

  static get = async (
    workspace: string,
    user: string
  ): Promise<UserProfile> => {
    const doc = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserProfile.collectionName)
      .doc(user)
      .withConverter(UserProfile.converter)
      .get();
    if (!doc.exists) {
      return new UserProfile({
        id: user,
        planned: {},
        projectSortOrder: {},
        hiddenProjectTimesheets: [],
        projectsTableFilters: { projects: [] }
      });
    }
    return doc.data() as UserProfile;
  };
}
