import React, { forwardRef, useMemo } from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import DurationFormat from "../../../../components/atoms/DurationFormat";
import NumberFormat from "react-number-format";
import ProgressBar from "../../../../components/atoms/ProgressBar";
import { useTheme } from "@material-ui/styles";
import WeekDistribution from "../../../../components/atoms/WeekDistribution";
import ProjectAvatar from "../../../../components/molecules/ProjectAvatar";
import ProjectLink from "../../../../components/molecules/ProjectLink";
import ConnectedCurrencyFormat from "../../../../components/organisms/ConnectedCurrencyFormat";

export default forwardRef(
  (
    {
      project,
      hours,
      money,
      byWeek,
      percentage,
      customer,
      weekNumbers,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();

    // const weekDistribution = useMemo(
    //   () =>
    //     weekNumbers.map(w => ({
    //       tooltip: `${t("common.week")} ${w}`,
    //       value: byWeek[w] || 0,
    //       color: "secondary"
    //     })),
    //   [weekNumbers, byWeek]
    // );

    return (
      <TableRow
        {...rest}
        ref={ref}
        style={{ background: theme.palette.background.light }}
      >
        <TableCell style={{ maxWidth: 80, width: 80 }} />
        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <ProjectAvatar project={project} />
        </TableCell>

        <TableCell>
          <ProjectLink name={project.name} id={project.id} />
        </TableCell>

        {/*<TableCell align="right">*/}
        {/*  <WeekDistribution weeks={weekDistribution} />*/}
        {/*</TableCell>*/}

        <TableCell align="right">
          <DurationFormat value={hours} component="b" />
        </TableCell>

        <TableCell>
          <ProgressBar
            align="left"
            color={project.getColor()}
            progress={percentage}
          />
        </TableCell>

        <TableCell align="right">
          {project.billable && (
            <b>
              <ConnectedCurrencyFormat number={money} />
            </b>
          )}
        </TableCell>
      </TableRow>
    );
  }
);
