import React from "react";
import IconButton from "../../../../../../components/atoms/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "../../../../../../components/atoms/Menu";
import MenuItem from "../../../../../../components/atoms/MenuItem";
import ListItemIcon from "../../../../../../components/atoms/ListItemIcon";
import Typography from "../../../../../../components/atoms/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

interface Props {
  onHideProject: () => void;
  onShowProject: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  isHidden: boolean;
}

export function More(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHideProject = () => {
    props.onHideProject();
    handleClose();
  };
  const handleShowProject = () => {
    props.onShowProject();
    handleClose();
  };

  const handleMoveUp = () => {
    if (props.onMoveUp) props.onMoveUp();
    handleClose();
  };

  const handleMoveDown = () => {
    if (props.onMoveDown) props.onMoveDown();
    handleClose();
  };

  return (
    <div>
      <IconButton aria-label="more" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {!props.isHidden && props.onMoveUp && (
          <MenuItem onClick={handleMoveUp}>
            <ListItemIcon>
              <ArrowUpwardIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Move up
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={props.isHidden ? handleShowProject : handleHideProject}
        >
          <ListItemIcon>
            {props.isHidden ? (
              <VisibilityOffIcon fontSize="small" />
            ) : (
              <VisibilityIcon fontSize="small" />
            )}
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Hide project
          </Typography>
        </MenuItem>
        {!props.isHidden && props.onMoveDown && (
          <MenuItem onClick={handleMoveDown}>
            <ListItemIcon>
              <ArrowDownwardIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Move down
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
