import styled, { createGlobalStyle, css } from 'styled-components';
import theme from 'styled-theming';
import styledNormalize from 'styled-normalize';

const fontColor =  theme('mode', {
  light: css`
    color: ${props => props.theme.palette.text.primary};
  `,
  dark: css`
   color: ${props => props.theme.palette.text.primary};
  `,
});

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,400i,500,500i,700&display=swap');

  ${styledNormalize};

  *,
  *::before,
  *::after{box-sizing:border-box;}

  :root {
    ${fontColor};
    font-size: ${props => props.theme.typography.body1.fontSize};
    font-family: ${props => props.theme.typography.fontFamily};
    font-weight: ${props => props.theme.typography.fontWeightMedium};
    -webkit-font-smoothing: antialiased;
  }
  #root, body, html {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }

  button {
    padding: 0;
  }
  
  //Intercom should be hidden underneath modals and similar. 
  // default intercom z-index is too high. Modals in mui are 1300 z-index
  .intercom-lightweight-app {
    z-index: 1000 !important;
  }
`;

const backgroundColor = theme('mode', {
  light: css`
    background-color: ${props => props.theme.palette.background.default};
  `,
  dark: css`
    background-color: ${props => props.theme.palette.background.default};
  `,
});

export const AppContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${backgroundColor};
  overflow: hidden;
`;

export const DeployVersion = styled.div`
  position: fixed;
  font-size: 0.5rem;
  bottom:0;
  right: 0;
  opacity: 0.1;
  &:hover {
    opacity: 1;
  }
`;