import React from "react";
import {
  Checkbox,
  Chip,
  Autocomplete,
  TextField
} from "@superprofit/core-react-components/atoms";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CustomerAvatar from "./CustomerAvatar";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const renderTags = (value, getCustomizedTagProps) => {
  return value.map((customer, index) => (
    <Chip
      color="primary"
      label={customer.name}
      size="medium"
      avatar={<CustomerAvatar customer={customer} />}
      {...getCustomizedTagProps({ index })}
    />
  ));
};

export default ({
  variant,
  fullWidth,
  customers,
  onClose,
  placeholder,
  label,
  ...rest
}) => {
  return (
    <Autocomplete
      multiple
      renderTags={renderTags}
      options={customers}
      disableCloseOnSelect
      onClose={e =>
        setTimeout(() => {
          // hack to prevent backdrop close parent modal when closing popper
          onClose && onClose();
        }, 500)
      }
      getOptionLabel={option => option.name}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            color="primary"
            checked={selected}
          />
          {option.name}
        </React.Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant={variant}
          fullWidth={fullWidth}
          label={label}
          placeholder={placeholder}
        />
      )}
      {...rest}
    />
  );
};
