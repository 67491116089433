import { combineReducers } from "redux";
import app from "./app";
import theme from "./theme/reducer";
import login from "./login/reducer";
import users from "./users/reducer";
import customers from "./customers/reducer";
import customerOverview2 from "./customerOverview2/reducer";
import leads from "./leads/reducer";
import projects from "./projects/reducer";
import projectOverview from "./projectOverview/reducer";
import invoices from "./invoices/reducer";
import timesheet from "./timesheet/reducer";
import dashboard from "./dashboard/reducer";
import userOverview from "./userOverview/reducer";
import companySettings from "./companySettings/reducer";
import userSettings from "./userSettings/reducer";
import userGroups from "./userGroups/reducer";
import integrations from "./integrations/reducer";
import pdf from "./pdf/reducer";
import signup from "./signup/reducer";
import workspaceOverview from "./workspaceOverview/reducer";

// reports
import report from "./report/reducer";

export default combineReducers({
  app,
  theme,
  login,
  users,
  customers,
  customerOverview2,
  projects,
  projectOverview,
  leads,
  timesheet,
  invoices,
  dashboard,
  userGroups,
  userOverview,
  companySettings,
  userSettings,
  integrations,
  pdf,
  signup,
  report,
  workspaceOverview
});
