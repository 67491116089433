import { SET_DATA, SET_LOADING } from "./constants";

const initialState = {
  list: [],
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DATA:
      return {
        ...state,
        list: payload.data
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload.isLoading
      };
    default:
      return state;
  }
};
