import React, { forwardRef } from "react";
import Tooltip from "@superprofit/core-react-components/atoms/Tooltip";
import { Week, Weeks } from "./weekDistribution/WeekDistribution.styles";

type IWeek = {
  tooltip?: string;
  value?: string | number;
  color?: "primary" | "secondary";
  splitStart?: string;
  splitEnd?: string;
};
interface IWeekDistribution {
  weeks: IWeek[];
}
const WeekDistribution = forwardRef<HTMLDivElement, IWeekDistribution>(
  (props, ref) => {
    const { weeks, ...rest } = props;
    if (weeks.length === 0) return <span />;
    return (
      <Weeks {...rest} ref={ref}>
        {weeks.map((b, idx) => (
          <Tooltip key={idx} arrow aria-label={b.tooltip} title={b.tooltip}>
            <Week
              color={b.color}
              splitStart={b.splitStart}
              splitEnd={b.splitEnd}
            >
              {b.value || 0}
            </Week>
          </Tooltip>
        ))}
      </Weeks>
    );
  }
);

export default WeekDistribution;
