import DialogActions, {
  DialogActionsProps
} from "@material-ui/core/DialogActions";
import React, { forwardRef, ForwardRefExoticComponent } from "react";

const Component: ForwardRefExoticComponent<DialogActionsProps> = forwardRef(
  (props, ref) => <DialogActions {...props} ref={ref} />
);

export default Component;
