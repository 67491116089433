import React, { useRef, useState } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@superprofit/core-react-components/atoms/Button";
import Card from "@superprofit/core-react-components/atoms/Card";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import FeatureNotImplementedDialog from "@superprofit/core-react-components/organisms/FeatureNotImplementedDialog";
import CustomersTable from "./customerTable/CustomerTable";
import { getPathWithParams } from "../../../router/routes";
import { showCustomerDialog } from "../../../redux/modules/ui/customers/dialog/actions";
import { selectAllCustomers } from "../../../redux/modules/ui/customers/selection/actions";
import { watchPatchCustomer } from "../../../redux/modules/api/customers/actions";
import ConfirmationDialog from "../../../components/molecules/ConfirmationDialog";
import { useTranslation } from "react-i18next";

const CustomersCard = ({
  customers,
  loading,
  selection: selected,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [notYetDialog, setNotYetDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(null);

  const handleOnEdit = (e, data) => {
    const customer = customers.find(p => p.id === data.id);
    dispatch(showCustomerDialog(customer));
  };
  const handleOnCloseConfirm = () => {
    setConfirmDialog(null);
  };

  const handleOnArchive = (e, data) => {
    setConfirmDialog({
      title: `Archive ${data.name}?`,
      text: "Are you sure you want to archive selected customer(s)",
      callback: () => dispatch(watchPatchCustomer(data.id, { archived: true }))
    });
  };

  const handleOnView = (e, data) =>
    dispatch(push(getPathWithParams("customerOverview", { id: data.id })));

  const handleOnSelectionChange = selection => {
    dispatch(selectAllCustomers(selection));
  };

  const handleOnClearSelection = () => {
    dispatch(selectAllCustomers([]));
  };

  return (
    <div>
      <ConfirmationDialog
        key="confirm"
        title={confirmDialog?.title}
        text={confirmDialog?.text}
        onConfirm={confirmDialog?.callback}
        onClose={handleOnCloseConfirm}
        open={Boolean(confirmDialog?.callback)}
      />
      <Card {...rest}>
        <CardHeader title={t("common.customers")} />

        <Divider />

        <CustomersTable
          onArchive={handleOnArchive}
          onEdit={handleOnEdit}
          onView={handleOnView}
          onDelete={handleOnArchive}
          onSelectionChange={handleOnSelectionChange}
          customers={customers}
          loading={loading}
          selection={selected}
        />
      </Card>

      <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={selected.length > 0}
          autoHideDuration={null}
          onClose={noop => noop}
          message={`${selected.length} customers selected`}
          action={
            <React.Fragment>
              <Button
                color="inherit"
                size="small"
                onClick={handleOnClearSelection}
              >
                CLEAR
              </Button>
              <Button color="alert" size="small" onClick={handleOnArchive}>
                ARCHIVE
              </Button>
            </React.Fragment>
          }
        />
      </AppBar>

      <FeatureNotImplementedDialog
        open={notYetDialog}
        onClose={e => setNotYetDialog(false)}
      />
    </div>
  );
};

export default CustomersCard;
