import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const colors = {
  primary: css`
    color: ${props => props.theme.palette.primary.main};
    &:visited,
    &:hover {
      color: ${props => props.theme.palette.primary.main};
    }
  `,
  secondary: css`
    color: ${props => props.theme.palette.secondary.main};
    &:visited,
    &:hover {
      color: ${props => props.theme.palette.secondary.main};
    }
  `,
  inherit: css`
    color: inherit;
    &:visited,
    &:hover {
      color: inherit;
    }
  `
};

export const StyledLink = styled(Link)`
  ${props => colors[props.color]};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledAnchor = styled.a`
  ${props => colors[props.color]};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
