import React, { useMemo, useState } from "react";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import HoursHistoryLoader from "./activityCard/HoursHistoryLoader";
import LineChart from "../../../../components/atoms/LineChart";
import { useTheme } from "@material-ui/core";
import styled from "styled-components";
import Card from "@superprofit/core-react-components/atoms/Card";
import { moment } from "@superprofit/time-util";
import { getLabels } from "../../../../utils/ChartUtils";
import { useTranslation } from "react-i18next";

const DashboardCard = styled(Card)`
  position: relative;
  pointer-events: all;
`;

export default ({ stats, project, year, loading, ...rest }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [showPoints, setShowPoints] = useState(false);

  const { labels = [], hours = [] } = useMemo(() => {
    const labels = getLabels(stats);

    const hoursByWeek = stats.reduce((prev, next) => {
      const key = `${next.year}-${next.week}`;
      return {
        ...prev,
        [key]: (prev[key] || 0) + next.total
      };
    }, {});

    const hours = labels.map(l => {
      return hoursByWeek[l] || 0;
    });

    return {
      labels,
      hours
    };
  }, [stats, year, loading]);

  const color = project?.billable
    ? theme.palette["primary"].main
    : theme.palette["tertiary"].main;

  const handleMouseOver = () => {
    if (showPoints) return;
    setShowPoints(true);
  };

  const handleMouseLeave = () => {
    if (!showPoints) return;
    setShowPoints(false);
  };

  if (loading || !project) {
    return <HoursHistoryLoader />;
  }

  return (
    <DashboardCard
      {...rest}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <CardHeader
        title={t("common.activityOverTime")}
        subheader={t("common.templates.showingWeeks", {
          firstLabel: labels[0],
          secondLabel: labels[labels.length - 1]
        })}
      />

      <LineChart
        id="project-weekly-hours"
        height={100}
        showPoints={showPoints}
        datasets={[
          {
            color,
            values: hours || [],
            label: project?.billable ? "Billable" : "Non-billable"
          }
        ]}
        labels={labels}
      />
    </DashboardCard>
  );
};
