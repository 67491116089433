import React from "react";
import withTheme from "@material-ui/core/styles/withTheme";
import { StyledPageHeader } from "./pageHeader/PageHeader.styles";
import { Typography, Box } from "@material-ui/core";

const PageHeader = withTheme(({ theme, context, title }) => {
  return (
    <StyledPageHeader>
      <Typography component="h2" gutterBottom>
        <Box {...theme.typography.h2}>{context.toUpperCase()}</Box>
      </Typography>
      { typeof title === 'string' ? (
        <Box {...theme.typography.h1}>{title}</Box>
      ) : title}
    </StyledPageHeader>
  );
});

PageHeader.defaultProps = {
  title: "Users",
  context: "Management"
};

export default PageHeader;
