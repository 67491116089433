import { GET, GET_FAILURE, GET_SUCCESS, WATCH_GET } from "./constants";

export const get = () => ({
  type: GET,
  payload: {}
});

export const getSuccess = data => ({
  type: GET_SUCCESS,
  payload: { data }
});

export const getFailure = error => ({
  type: GET_FAILURE,
  payload: { error }
});

export const watchGet = () => ({
  type: WATCH_GET,
  payload: {}
});
