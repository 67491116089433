import hash from "object-hash";
import { db, firebase, functions } from "../firebase";
import * as yup from "yup";
import { Timet } from "@superprofit/timet-types";

import {
  getDayOfYear,
  getDaysInYear,
  getISOWeek,
  getISOWeekYear,
  getMonth,
  getYear,
  isAfter,
  setDayOfYear,
  setYear
} from "date-fns";

type CollectionReference<T> = firebase.firestore.CollectionReference<T>;
type DocumentData = firebase.firestore.DocumentData;
type Query<T> = firebase.firestore.Query<T>;
export type QuerySnapshot = firebase.firestore.QuerySnapshot;
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
export type SnapshotOptions = firebase.firestore.SnapshotOptions;
export type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
export type Timestamp = firebase.firestore.Timestamp;

type BatchUpsertEntry = Pick<
  IEntry,
  "project" | "user" | "dayOfYear" | "year" | "hours"
> & { entry?: Entry };

export interface IEntry {
  id: string;
  user: string;
  project: string;
  year: number;
  dayOfYear: number;
  hours: number;
  month: number;
  /* ISO Week */
  week: number;
  isoWeek: number;
  isoWeekYear: number;
  updatedBy: string | null;
  updatedAt: Timestamp | firebase.firestore.FieldValue | null;
  createdAt: Timestamp | firebase.firestore.FieldValue | null;
  createdBy: string | null;
}

type IEntryFirestore = Omit<IEntry, "dayOfYear"> & { day: number };

export type GetEntriesRangeOptions = {
  limit?: number;
  user?: string;
  project?: string;
  customer?: string;
};

const schema = yup.object({
  project: yup.string().required(),
  user: yup.string().required(),
  year: yup.number().required(),
  day: yup.number().required(),
  hours: yup.number().required(),
  week: yup.number().required(),
  month: yup.number().required(),
  isoWeek: yup.number().required(),
  isoWeekYear: yup.number().required()
});

export default class Entry implements IEntry {
  id: string;
  user: string;
  project: string;
  year: number;
  dayOfYear: number;
  hours: number;
  month: number;
  week: number;
  isoWeek: number;
  isoWeekYear: number;
  updatedBy: string | null;
  updatedAt: Timestamp | firebase.firestore.FieldValue | null;
  createdAt: Timestamp | firebase.firestore.FieldValue | null;
  createdBy: string | null;
  static collectionName = "timesheet_entries";
  static createId = (
    user: string,
    project: string,
    year: number,
    dayOfYear: number
  ) => hash({ user, project, year, dayOfYear });

  static converter = {
    toFirestore(entry: Entry) {
      return entry.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IEntryFirestore;
      const {
        user,
        project,
        year,
        day: dayOfYear,
        hours: hoursThatCanBeStringBecauseOfHistoricReasons,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy
      } = data;

      const hours =
        typeof hoursThatCanBeStringBecauseOfHistoricReasons === "number"
          ? hoursThatCanBeStringBecauseOfHistoricReasons
          : parseFloat(hoursThatCanBeStringBecauseOfHistoricReasons);

      return new Entry(
        user,
        project,
        year,
        dayOfYear,
        hours,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy
      );
    }
  };

  static parseHours = (hours: string | number) => {
    const valueParsed =
      typeof hours === "string" ? hours.replace(",", ".") : hours;
    const parsedHours =
      typeof valueParsed === "string" ? parseFloat(valueParsed) : valueParsed;
    if (!Number.isNaN(parsedHours) && parsedHours >= 0 && parsedHours <= 24) {
      return parsedHours;
    } else if (Number.isNaN(parsedHours) || parsedHours < 0) {
      return 0;
    } else {
      return 24;
    }
  };

  constructor(
    user: string,
    project: string,
    year: number,
    dayOfYear: number,
    hours: number,
    createdAt: Timestamp | firebase.firestore.FieldValue | null = null,
    createdBy: string | null = null,
    updatedAt: Timestamp | firebase.firestore.FieldValue | null = null,
    updatedBy: string | null = null
  ) {
    this.id = Entry.createId(user, project, year, dayOfYear);
    this.user = user;
    this.project = project;
    this.year = year;
    this.dayOfYear = dayOfYear;

    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
    this.createdAt = createdAt;
    this.createdBy = createdBy;

    if (hours < 0) this.hours = 0;
    else if (hours > 24) this.hours = 24;
    else this.hours = hours;

    const date = setDayOfYear(setYear(new Date(), year), dayOfYear);
    this.month = getMonth(date) + 1;
    this.week = getISOWeek(date);

    this.isoWeekYear = getISOWeekYear(date);
    this.isoWeek = getISOWeek(date);

    /* Keeping this here for historic purpose for a while
  We should be aware that when the ISO week is 1, but the actual day is in the previous year,
  this might lead to some issues when displaying the data. If we always use dayOfYear and year,
    we should be fine.
    if (this.month === 12 && this.week === 1) {
      //Hack to handle last week of year === 1, this breaks aggregation
      this.week =
        date
          .clone()
          .subtract(1, "week")
          .week() + 1;
    }*/
  }

  clone() {
    return Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this
    ) as Entry;
  }

  async isValid() {
    return schema.validate(this.data());
  }

  data() {
    return {
      user: this.user,
      project: this.project,
      year: this.year,
      day: this.dayOfYear,
      hours: this.hours,
      month: this.month,
      week: this.week,
      isoWeek: this.isoWeek,
      isoWeekYear: this.isoWeekYear,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
      createdBy: this.createdBy
    };
  }

  static getUserTimesheet = async (
    workspace: string,
    user: string,
    year: number,
    week: number
  ) => {
    const snapshot = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Entry.collectionName)
      .where("week", "==", week)
      .where("year", "==", year)
      .where("user", "==", user)
      .withConverter(Entry.converter)
      .get();

    const list: Entry[] = [];
    snapshot.forEach(doc => {
      list.push(doc.data());
    });
    return list;
  };

  static getUserEntriesRange = async (
    workspace: string,
    user: string,
    fromDate: Date,
    toDate: Date
  ) => {
    const list: Entry[] = [];
    const fromYear = getYear(fromDate);
    const toYear = getYear(toDate);
    const fromDayOfYear = getDayOfYear(fromDate);
    const toDayOfYear = getDayOfYear(toDate);
    if (isAfter(fromDate, toDate)) {
      throw new Error("fromDate cannot be after toDate");
    }

    if (fromYear === toYear) {
      const snapshot = await db
        .collection("workspaces")
        .doc(workspace)
        .collection(Entry.collectionName)
        .where("year", "==", fromYear)
        .where("user", "==", user)
        .where("day", ">=", fromDayOfYear)
        .where("day", "<=", toDayOfYear)
        .withConverter(Entry.converter)
        .get();
      snapshot.forEach(doc => {
        list.push(doc.data());
      });
    } else {
      for (let year = fromYear; year <= toYear; year++) {
        const numberOfDaysCurrentYear = getDaysInYear(new Date(year, 0, 1));
        let dayOfYear =
          year === fromYear ? fromDayOfYear : numberOfDaysCurrentYear;
        if (year === toYear) {
          dayOfYear = toDayOfYear;
        }
        const dayOperator = year === fromYear ? ">=" : "<=";
        const snapshot = await db
          .collection("workspaces")
          .doc(workspace)
          .collection(Entry.collectionName)
          .where("year", "==", year)
          .where("user", "==", user)
          .where("day", dayOperator, dayOfYear)
          .withConverter(Entry.converter)
          .get();
        snapshot.forEach(doc => {
          list.push(doc.data());
        });
      }
    }

    return list;
  };
  static getEntriesRange = async (
    workspace: string,
    fromDate: Date,
    toDate: Date,
    options: GetEntriesRangeOptions = {}
  ) => {
    const list: Entry[] = [];
    const fromYear = getYear(fromDate);
    const toYear = getYear(toDate);
    const fromDayOfYear = getDayOfYear(fromDate);
    const toDayOfYear = getDayOfYear(toDate);
    if (isAfter(fromDate, toDate)) {
      throw new Error("fromDate cannot be after toDate");
    }
    let dbRef:
      | CollectionReference<DocumentData>
      | Query<CollectionReference<DocumentData>> = db
      .collection("workspaces")
      .doc(workspace)
      .collection(Entry.collectionName);
    if (options.user) {
      dbRef = dbRef.where("user", "==", options.user) as Query<
        CollectionReference<DocumentData>
      >;
    }
    if (options.project) {
      dbRef = dbRef.where("project", "==", options.project) as Query<
        CollectionReference<DocumentData>
      >;
    }
    if (options.customer) {
      dbRef = dbRef.where("customer", "==", options.customer) as Query<
        CollectionReference<DocumentData>
      >;
    }

    if (fromYear === toYear) {
      // const snapshot = await addQueries(dbRef)
      const snapshot = await dbRef
        .where("year", "==", fromYear)
        .where("day", ">=", fromDayOfYear)
        .where("day", "<=", toDayOfYear)
        .withConverter(Entry.converter)
        .get();
      snapshot.forEach((doc: QueryDocumentSnapshot) => {
        const data = doc.data() as Entry;
        if (data) list.push(data);
      });
    } else {
      for (let year = fromYear; year <= toYear; year++) {
        const numberOfDaysCurrentYear = getDaysInYear(new Date(year, 0, 1));
        let dayOfYear =
          year === fromYear ? fromDayOfYear : numberOfDaysCurrentYear;
        if (year === toYear) {
          dayOfYear = toDayOfYear;
        }
        const dayOperator = year === fromYear ? ">=" : "<=";
        const snapshot = await dbRef
          .where("year", "==", year)
          .where("day", dayOperator, dayOfYear)
          .withConverter(Entry.converter)
          .get();
        snapshot.forEach((doc: QueryDocumentSnapshot) => {
          const data = doc.data() as Entry;
          if (data) list.push(data);
        });
      }
    }

    return list;
  };

  static getAllByProjects = async (
    workspace: string,
    projectIds: string[],
    year: number,
    month: number
  ) => {
    const inOpLimit = 10; // firestore in-operator limitation

    let result = [];
    let head;
    let tail = projectIds.slice();
    while (tail && tail.length) {
      head = tail.slice(0, inOpLimit);
      tail = tail.slice(inOpLimit);
      let ref = db
        .collection("workspaces")
        .doc(workspace)
        .collection(Entry.collectionName)
        .where("project", "in", head)
        .where("year", "==", year)
        .where("month", "==", month)
        .withConverter(Entry.converter);

      const res = await ref.get();
      result.push(...res.docs.map(d => d.data()));
    }
    return result;
  };

  static getAllByUsers = async (
    workspace: string,
    userIds: string[],
    year: number,
    month: number
  ) => {
    const inOpLimit = 10; // firestore in-operator limitation

    let result = [];
    let head;
    let tail = userIds.slice();
    while (tail && tail.length) {
      head = tail.slice(0, inOpLimit);
      tail = tail.slice(inOpLimit);
      let ref = db
        .collection("workspaces")
        .doc(workspace)
        .collection(Entry.collectionName)
        .where("user", "in", head)
        .where("year", "==", year)
        .where("month", "==", month)
        .withConverter(Entry.converter);
      // .where("day", ">=", fromDay)
      // .where("day", "<=", toDay)
      // .where("year", ">=", fromYear)
      // .where("year", "<=", toYear)

      const res = await ref.get();
      result.push(...res.docs.map(d => d.data()));
    }
    return result;
  };

  static getAll = async (
    workspace: string,
    projectIds: string[] = [],
    users: string[] = [],
    fromYear: number,
    toYear: number,
    fromDay: number,
    toDay: number,
    limit: number = 1000
  ) => {
    // Need to be careful here. Should have a better way of handling data extracting like this.
    // Using functions or indexes or whatever. We need to keep in mind cost vs value.

    //Just a small check so we dont go full overload
    if (users.length > 20)
      throw new Error("Users length can not exceed ten at the moment");
    if (toYear - fromYear > 2)
      throw new Error("Can only query a maximum of two years at a time");

    const ref = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Entry.collectionName);
    const promises: Promise<QuerySnapshot>[] = [];
    for (let y = fromYear; y <= toYear; y++) {
      users.forEach(u => {
        promises.push(
          ref
            .where("day", ">=", fromDay)
            .where("day", "<=", toDay)
            .where("year", "==", y)
            .where("user", "==", u)
            .orderBy("day")
            .limit(limit)
            .withConverter(Entry.converter)
            .get()
        );
      });
    }
    const snapshots = await Promise.all(promises);
    const flattened = snapshots.reduce(
      (acc, curr) => [...acc, ...curr.docs],
      [] as QueryDocumentSnapshot[]
    );
    const entries = flattened.map(d => d.data());
    const filtered = entries.filter(e => projectIds.includes(e.project));
    return filtered;
  };

  setData(updates: Partial<IEntry>) {
    Object.assign(this, updates);
    return this;
  }

  static save = async (workspace: string, entry: Entry) => {
    await entry.isValid();
    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Entry.collectionName)
      .doc(entry.id)
      .withConverter(Entry.converter)
      .set(entry.data(), { merge: true });

    return entry;
  };

  static batchSave = async (workspace: string, entries: Entry[]) => {
    const batch = db.batch();

    for (let entry of entries) {
      await entry.isValid();
      const docRef = db
        .collection("workspaces")
        .doc(workspace)
        .collection(Entry.collectionName)
        .withConverter(Entry.converter)
        .doc(entry.id);
      batch.set(docRef, entry, { merge: true });
    }
    await batch.commit();

    return entries;
  };

  static upsertApi = async (
    payload: Timet.Api.Entries.UpsertManyCallablePayload
  ) => {
    yup
      .object({
        workspaceId: yup.string().required(),
        entries: yup.array().of(
          yup.object({
            project: yup.string().required(),
            hours: yup
              .number()
              .min(0)
              .max(24)
              .required(),
            user: yup.string().required(),
            date: yup.string().required()
          })
        )
      })
      .validateSync(payload);

    const callable = functions.httpsCallable("entries-upsert-manyCallable");
    const result = await callable(payload);
    const data: Timet.Firestore.Entry[] = result.data;
    return data.map(d => new Entry(d.user, d.project, d.year, d.day, d.hours));
  };

  static batchUpsert = async (
    workspace: string,
    user: string,
    originalEntries: Array<Entry> | null,
    updates: BatchUpsertEntry[]
  ) => {
    const originalIds = originalEntries?.map(e => e && e.id) || [];
    const updateIds: string[] = updates
      .map(u => u.entry?.id)
      .filter((u): u is string => u !== undefined);
    const difference = updateIds.filter(x => !originalIds.includes(x));
    if (difference.length > 0) {
      throw new Error(
        `The following entries are missing from the update: ${difference.join(
          ", "
        )}`
      );
    }

    const entries = updates.map((update, i) => {
      let entry = originalEntries?.find(e => e && e.id === update.entry?.id);
      if (!entry) {
        entry = new Entry(
          update.user,
          update.project,
          update.year,
          update.dayOfYear,
          update.hours
        );
        entry.setData({
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: user,
          updatedBy: user,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });
      } else if (entry instanceof Entry) {
        entry = entry.clone();
        entry.setData({
          hours: update.hours,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: user
        });
      }
      return entry;
    });
    await Entry.batchSave(workspace, entries);

    return entries;
  };

  static delete = async (workspace: string, email: string, year: number) => {
    const snapshot = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Entry.collectionName)
      .where("user", "==", email)
      .where("year", "==", year)
      .get();

    snapshot.forEach(doc => {
      doc.ref.delete();
    });
  };

  static recent = async (
    workspace: string,
    email: string,
    limit: number = 10
  ) => {
    const snapshot = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Entry.collectionName)
      .where("user", "==", email)
      .orderBy("year", "desc")
      .orderBy("day", "desc")
      .limit(limit)
      .withConverter(Entry.converter)
      .get();

    const list: Entry[] = [];
    snapshot.forEach(doc => {
      list.push(doc.data());
    });
    return list;
  };

  static byWeek = async (
    workspace: string,
    email: string,
    year: number,
    week: number
  ) => {
    const snapshot = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Entry.collectionName)
      .where("user", "==", email)
      .where("year", "==", year)
      .where("week", "==", week)
      .where("hours", ">", 0)
      .withConverter(Entry.converter)
      .get();

    const list: Entry[] = [];
    snapshot.forEach(doc => {
      list.push(doc.data());
    });
    return list;
  };
}
