import { takeLatest, put, take, all } from "redux-saga/effects";

// constants
import { WATCH_GET } from "./constants";
import { GET_PROJECT_SUCCESS } from "../projects/constants";
import { GET_SUCCESS as GET_STATS_SUCCESS } from "../projectStats/constants";
import { GET_CUSTOMER_SUCCESS } from "../customers/constants";
import { GET_SUCCESS as GET_USER_GROUPS_SUCCESS } from "../userGroups/constants";
import { GET_SUCCESS as GET_USERS_SUCCESS } from "@superprofit/core-redux-saga-modules/Users/constants";

// actions
import { get, getFailure, getSuccess } from "./actions";
import { watchGetProject } from "../projects/actions";
import { watchGetCustomer } from "../customers/actions";
import { watchGetProjectStats } from "../projectStats/actions";
import { watchGetUsers } from "@superprofit/core-redux-saga-modules/Users/actions";
import {watchGetUserGroups} from '../userGroups/actions';

export const getProjectOverview = function* getProjectOverview(args) {
  const { id, year, month } = args.payload;

  try {
    yield put(get());
    yield put(watchGetProject(id));
    const {
      payload: { project }
    } = yield take(GET_PROJECT_SUCCESS);

    let customer;
    if (project.customer) {
      yield put(watchGetCustomer(project.customer));
      const {
        payload: { customer: cust }
      } = yield take(GET_CUSTOMER_SUCCESS);
      customer = cust;
    }

    yield put(watchGetProjectStats(id, year, month));
    yield put(watchGetUsers());
    yield put(watchGetUserGroups());

    const [
      {
        payload: { weeksOfMonth, byWeek, allTime, monthBefore }
      },
      {
        payload: { list: users }
      },
      {
        payload: { userGroups }
      }
    ] = yield all([take(GET_STATS_SUCCESS), take(GET_USERS_SUCCESS), take(GET_USER_GROUPS_SUCCESS)]);
    yield put(
      getSuccess({
        customer,
        project,
        stats: {
          weeksOfMonth,
          byWeek,
          allTime,
          monthBefore
        },
        users,
        userGroups,
        year,
        month
      })
    );
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const getProjectOverviewSaga = function* getProjectOverviewSaga() {
  yield takeLatest(WATCH_GET, getProjectOverview);
};

export default function*() {
  yield all([getProjectOverviewSaga()]);
}
