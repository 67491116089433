import React from "react";
import Label from "@superprofit/core-react-components/atoms/Label";
import { useTranslation } from "react-i18next";

export default ({ billable }) => {
  const { t } = useTranslation();

  return billable ? (
    <Label variant="filled" color="success" label={t("common.billable")} />
  ) : (
    <Label variant="filled" color="warning" label="Non billable" />
  );
};
