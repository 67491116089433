import React, { forwardRef, ForwardRefExoticComponent } from "react";
import { StyledLabel } from "./label/Label.styles";
import { BoxProps } from "@material-ui/core/Box/Box";

type LabelProps = {
  label: string;
  variant?: "filled" | "outlined";
} & BoxProps;

export const Label: ForwardRefExoticComponent<LabelProps> = forwardRef(
  ({ label, variant = "filled", color = "success", ...rest }, ref) => (
    <StyledLabel
      aria-label={label}
      variant={variant}
      color={color}
      {...rest}
      ref={ref}
    >
      {label}
    </StyledLabel>
  )
);
export default Label;
