import { TableCell } from "@superprofit/timet-react-client/src/components/atoms/Table";
import DurationFormat from "@superprofit/timet-react-client/src/components/atoms/DurationFormat";
import React from "react";
import { format } from "../../../../date-fns-wrappers";
import { StyledRow } from ".//entryRow/EntryRow.styles";

type EntryRow = {
  date: Date;
  hours?: number;
};
export const EntryRow = ({ date, hours = 0 }: EntryRow) => {
  return (
    <StyledRow>
      <TableCell style={{ maxWidth: 80, width: 80 }}></TableCell>
      <TableCell style={{ maxWidth: 80, width: 80 }}></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell align="right">{format(date, "eee. d.LLL")}</TableCell>
      <TableCell align="right">
        <DurationFormat value={hours} component="b" />
      </TableCell>
      <TableCell align="right"></TableCell>
    </StyledRow>
  );
};
