import React from "react";
import CardContent from "@superprofit/core-react-components/atoms/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import { moment } from "@superprofit/time-util";
import i18next from "i18next";

import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import { Timestamp } from "@superprofit/core-firestore-models";
import {
  StyledCard,
  FlexColumn,
  StyledHeader,
  Subheader
} from "./invoiceCard/InvoiceCard.styles";
import Label from "@superprofit/core-react-components/atoms/Label";
import ConnectedCurrencyFormat from "../../../components/organisms/ConnectedCurrencyFormat";
import { useTranslation } from "react-i18next";

// Access the translation function directly
const translate = i18next.t;
const sumEntries = entries =>
  entries.reduce((prev, next) => prev + next.hours, 0);

const sumProjects = projects =>
  projects.reduce(
    (prev, next) => prev + sumEntries(next.entries) * next.billableRate,
    0
  );

const renderEntries = p => {
  if (!p || !p.entries) return null;

  return p.entries.map(userEntry => (
    <TableRow key={userEntry.id}>
      <TableCell component="th" scope="u">
        {userEntry.displayName}
      </TableCell>

      <TableCell align="right">{userEntry.hours}</TableCell>

      <TableCell align="right">
        <ConnectedCurrencyFormat number={userEntry.billableRate} />
      </TableCell>

      <TableCell align="right">
        <ConnectedCurrencyFormat
          number={userEntry.billableRate * userEntry.hours}
        />
      </TableCell>
    </TableRow>
  ));
};

const renderProjectRow = p => {
  if (!p || !p.entries) return null;
  const totalHours = sumEntries(p.entries);

  const sumTotal = p.entries.reduce(
    (prev, next) => prev + next.billableRate * next.hours,
    0
  );

  return (
    <TableRow key={p.id}>
      <TableCell component="th" scope="u">
        <b>{p.name}</b>
      </TableCell>

      <TableCell align="right">
        <b>{totalHours}</b>
      </TableCell>

      <TableCell align="right">
        <b>
          {/*<NumberFormat*/}
          {/*  displayType="text"*/}
          {/*  thousandSeparator*/}
          {/*  isNumericString*/}
          {/*  value={p.billableRate}*/}
          {/*/>*/}
        </b>
      </TableCell>

      <TableCell align="right">
        <b>
          <ConnectedCurrencyFormat number={sumTotal} />
        </b>
      </TableCell>
    </TableRow>
  );
};

const renderInvoiceTotal = basis => {
  if (!basis || !basis.projects) return null;
  const total = sumProjects(basis.projects || []);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <b>
          <Typography variant="subtitle2">
            {translate("common.total")}
          </Typography>
        </b>
      </TableCell>
      <TableCell align="right"></TableCell>
      <TableCell align="right"></TableCell>
      <TableCell align="right">
        {total && (
          <Typography variant="subtitle2">
            <b>
              <ConnectedCurrencyFormat number={basis.totalSum} />
            </b>
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ({ invoice }) => {
  const { t } = useTranslation();

  return (
    <StyledCard raised>
      <StyledHeader
        disableTypography
        title={<Typography gutterBottom>INVOICE</Typography>}
        subheader={
          <Subheader>
            <FlexColumn>
              <Typography variant="caption">{t("common.customer")}</Typography>
              <Typography variant="subtitle2">
                {invoice.customer && invoice.customer.name}
              </Typography>
            </FlexColumn>
            <FlexColumn>
              <Typography variant="subtitle1">{`#${invoice.invoiceNumber ||
                "--"}`}</Typography>
              {invoice.paid ? (
                <Label label={t("common.paid")} color="success" />
              ) : (
                <Label label={t("common.unpaid")} color="warning" />
              )}
            </FlexColumn>
          </Subheader>
        }
      />
      <CardContent>
        <Grid container spacing={3} component="div">
          <Grid item xs={4} component="div">
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {t("common.issued")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {invoice.issueDate &&
                        Timestamp.toMoment(invoice.issueDate).format("LL")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} component="div">
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {t("common.period")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {invoice.year &&
                        invoice.month &&
                        moment()
                          .year(invoice.year)
                          .month(invoice.month)
                          .format("MMMM YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} component="div">
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="caption">{t("common.due")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {invoice.dueDate &&
                        Timestamp.toMoment(invoice.dueDate).format("LL")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} component="div">
            <Divider />
          </Grid>
          <Grid item xs={12} component="div">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("common.name")}</TableCell>
                  <TableCell align="right">{t("common.hours")}</TableCell>
                  <TableCell align="right">{t("common.rate")}</TableCell>
                  <TableCell align="right">{t("common.sum")}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {invoice?.basis?.projects?.map(p => (
                  <React.Fragment key={p.id}>
                    {renderProjectRow(p)}
                    {renderEntries(p)}
                  </React.Fragment>
                ))}
                {renderInvoiceTotal(invoice?.basis)}
              </TableBody>

              {/*<TableBody>*/}
              {/*  {invoice?.users?.map((u, idx) => (*/}
              {/*    <TableRow key={u.user}>*/}
              {/*      <TableCell component="th" scope="u">*/}
              {/*        {u.user}*/}
              {/*      </TableCell>*/}
              {/*      <TableCell align="right">{u.totalHours}</TableCell>*/}
              {/*      <TableCell align="right">*/}
              {/*        <NumberFormat*/}
              {/*          displayType="text"*/}
              {/*          thousandSeparator*/}
              {/*          isNumericString*/}
              {/*          value={u.totalBillable}*/}
              {/*        />*/}
              {/*      </TableCell>*/}
              {/*    </TableRow>*/}
              {/*  ))}*/}
              {/*  <TableRow>*/}
              {/*    <TableCell component="th" scope="row">*/}
              {/*      <Typography variant="subtitle2">Total</Typography>*/}
              {/*    </TableCell>*/}
              {/*    <TableCell align="right">*/}
              {/*      <Typography variant="subtitle2">*/}
              {/*        {invoice.totalHours}*/}
              {/*      </Typography>*/}
              {/*    </TableCell>*/}
              {/*    <TableCell align="right">*/}
              {/*      {invoice.totalBillable && (*/}
              {/*        <Typography variant="subtitle2">*/}
              {/*          <NumberFormat*/}
              {/*            displayType="text"*/}
              {/*            thousandSeparator*/}
              {/*            isNumericString*/}
              {/*            value={invoice.totalBillable}*/}
              {/*          />*/}
              {/*        </Typography>*/}
              {/*      )}*/}
              {/*    </TableCell>*/}
              {/*  </TableRow>*/}
              {/*</TableBody>*/}
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};
