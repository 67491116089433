import React, { forwardRef } from "react";
import {
  StyledElevatedTextField,
  StyledTextField
} from "./textField/TextField.styles";

export default forwardRef((props, ref) => (
  <StyledTextField {...props} ref={ref} />
));

export const ElevatedTextField = forwardRef((props, ref) => (
  <StyledElevatedTextField {...props} ref={ref} />
));
