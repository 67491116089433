import {
  getUserHoursByProject,
  getUserSalaryByProject
} from "../../ProjectStats";
import {
  ReportTable,
  ReportTableCell,
  ReportTableHeader,
  ReportTableRow,
  Report
} from "../Report";
import { format } from "@superprofit/core-react-components/atoms/NumberFormatIntl";
import Customer from "../../../models/Customer";
import {
  format as formatDatefns,
  endOfMonth,
  formatISO,
  setMonth,
  setYear,
  startOfMonth
} from "date-fns";
import Project from "../../../models/Project";

const build = (
  ogUsers: any,
  ogProjects: Project[],
  entries: any,
  customers: any = [],
  organizationNumber: string
) => {
  const projectsById = ogProjects.reduce(
    (prev: any, next: any) => ({ ...prev, [next.id]: next }),
    {}
  );
  const userSalaryByProject = getUserSalaryByProject(entries, projectsById);
  const userHoursByProject = getUserHoursByProject(entries);

  let month = null;
  let year = null;

  for (const entry of entries) {
    if (!month || !year) {
      month = entry.month;
      year = entry.year;
    }
    if (entry.month !== month || entry.year !== year) {
      throw new Error("All entries must be from the same month and year");
    }
  }
  let date = new Date(year, month - 1, 1);
  let startDate = formatDatefns(startOfMonth(date), "dd.MM.yy");
  const endDate = formatDatefns(endOfMonth(date), "dd.MM.yy");

  const report = new Report();
  const tableHeader = new ReportTableHeader([
    new ReportTableCell("Organisasjonsnummer"),
    new ReportTableCell("Ansattnummer"),
    new ReportTableCell("Lønnsartnummer"),
    new ReportTableCell("Sats"), //salary rate
    new ReportTableCell("Antall"), //hours
    new ReportTableCell("Fra dato"),
    new ReportTableCell("Til dato"),
    new ReportTableCell("Dimensjon 1"),
    new ReportTableCell("Dimensjon 2"),
    new ReportTableCell("Dimensjon 3"),
    new ReportTableCell("Tekst på lønnspost"),
    new ReportTableCell("Konto"),
    new ReportTableCell("MVA-kode")
  ]);

  const tableRows: any[] = [];

  const projects = ogProjects.slice().filter((p: Project) => p.salaryEnabled);
  projects.sort((a: any, b: any) => a?.name?.localeCompare(b?.name));

  const users = ogUsers.slice();
  users.sort((a: any, b: any) => a?.fullName?.localeCompare(b?.fullName));
  const f = (number: any) => {
    let options = { minimumFractionDigits: 2 };
    // if (currency) {
    //   options = { style: "currency", currency };
    // }
    return format({ number, options });
  };
  users.forEach((user: any) => {
    let sumTotalMoney = 0;
    let sumTotalHours = 0;
    let hasEntries = false;
    Object.entries(userHoursByProject).forEach(
      ([pid, hoursByUsers]: [string, any]) => {
        const project = projects.find((p: any) => p.id === pid);
        const hours = hoursByUsers[user.id];
        const total =
          userSalaryByProject[pid] && userSalaryByProject[pid][user.id];
        if (project && hours) {
          hasEntries = true;
          sumTotalHours += hours;
          sumTotalMoney = total ? sumTotalMoney + total : sumTotalMoney;
          tableRows.push(
            new ReportTableRow([
              new ReportTableCell(organizationNumber || ""),
              new ReportTableCell(user?.metadata?.employeeNumber),
              new ReportTableCell(project?.metadata?.nettlonn?.salaryArt),
              new ReportTableCell(
                project.salaryEnabled ? project.getSalaryRate(user.id) : "",
                {
                  textAlign: "right"
                }
              ),
              new ReportTableCell(hours, {
                textAlign: "right"
              }),
              new ReportTableCell(startDate),
              new ReportTableCell(endDate),
              new ReportTableCell(project?.metadata?.nettlonn?.dimensionOne),
              new ReportTableCell(""),
              new ReportTableCell(""),
              new ReportTableCell(""),
              new ReportTableCell(""),
              new ReportTableCell("")
            ])
          );
        }
      }
    );
    // if (hasEntries) {
    //   tableRows.push(
    //     new ReportTableRow([
    //       new ReportTableCell("Sum"),
    //       new ReportTableCell(),
    //       new ReportTableCell(),
    //       new ReportTableCell(),
    //       new ReportTableCell(sumTotalHours),
    //       new ReportTableCell(),
    //       new ReportTableCell(sumTotalMoney)
    //     ])
    //   );
    //   tableRows.push(
    //     new ReportTableRow([
    //       new ReportTableCell(),
    //       new ReportTableCell(),
    //       new ReportTableCell(),
    //       new ReportTableCell(),
    //       new ReportTableCell(),
    //       new ReportTableCell(),
    //       new ReportTableCell()
    //     ])
    //   );
    // }
  });
  report.push(new ReportTable(tableHeader, tableRows));
  return report;
};

export const createTableExport = (
  users: any,
  projects: any,
  entries: any,
  customers: Customer[] = [],
  organizationNumber: string
) => {
  return build(users, projects, entries, customers, organizationNumber);
};
