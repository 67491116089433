import { all, put, take, select, takeLatest } from "redux-saga/effects";
import {
  hideAccessControlDialog,
  setSaving,
} from './actions';
import { WATCH_SAVE_USER_ACCESS } from "./constants";
import { selectAllUsers } from "../selection/actions";
import { addGlobalAlert } from "../../app/actions";
import userAccessActions from '@superprofit/core-redux-saga-modules/UserAccess/actions';
import userAccessConstants from '@superprofit/core-redux-saga-modules/UserAccess/constants';

export const handleUpdateUserAccess = function* () {
  const { updates, users } = yield select(
    (state) => state.ui.users.accessControlDialog
  );
  const userAccessOriginals = yield select((state) => state.api.userAccess.list);

  yield put(setSaving(true));

  for (let user of users) {
    const org = userAccessOriginals.find((ua) => ua.id === user.email);

    if (!org) {
      yield put( userAccessActions.watchCreate({email: user.email, ...updates}));
      const response = yield take([userAccessConstants.CREATE_SUCCESS, userAccessConstants.CREATE_FAILURE]);
      if (response.type === userAccessConstants.CREATE_FAILURE) {
        yield put(setSaving(false));
        throw new Error("CREATE FAILURE");
      }
    } else {
      yield put( userAccessActions.watchUpdate(org, updates));
      const response = yield take([userAccessConstants.UPDATE_SUCCESS, userAccessConstants.UPDATE_FAILURE]);
      if (response.type === userAccessConstants.UPDATE_FAILURE) {
        yield put(setSaving(false));
        throw new Error("UPDATE FAILURE");
      }
    }

  }
  yield put(setSaving(false));
  yield put(selectAllUsers([]));
  yield put(hideAccessControlDialog());
  yield put(
    addGlobalAlert({
      severity: "success",
      title: "Update success",
      message: `${users.length} user(s) access updated.`,
      // vertical: "bottom",
      duration: 3000,
      // horizontal: "center",
    })
  );
};

export const handleUpdateUserAccessSaga = function* () {
  yield takeLatest(WATCH_SAVE_USER_ACCESS, handleUpdateUserAccess);
};

export default function* () {
  yield all([handleUpdateUserAccessSaga()]);
}
