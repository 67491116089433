import { all, delay, put, takeLatest } from "@redux-saga/core/effects";
import {
  BATCH_SAVE,
  BATCH_SAVE_SUCCESS,
  SAVE,
  SAVE_SUCCESS
} from "../../../api/timesheet/entries/constants";

import { updateSnackbar } from "./actions";

export const handleTimesheetEntriesSaving = function*(args) {
  try {
    yield put(updateSnackbar({ visible: true, text: "Saving" }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleTimesheetEntriesSaved = function*() {
  try {
    yield put(updateSnackbar({ visible: true, text: "All changes saved" }));
    yield delay(2000);
    yield put(updateSnackbar({ visible: false }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleTimesheetEntrySavingSaga = function*() {
  yield takeLatest(SAVE, handleTimesheetEntriesSaving);
};

export const handleTimesheetEntrySavedSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleTimesheetEntriesSaved);
};

export const handleTimesheetEntriesSavingSaga = function*() {
  yield takeLatest(BATCH_SAVE, handleTimesheetEntriesSaving);
};

export const handleTimesheetEntriesSavedSaga = function*() {
  yield takeLatest(BATCH_SAVE_SUCCESS, handleTimesheetEntriesSaved);
};

export default function*() {
  yield all([
    handleTimesheetEntriesSavedSaga(),
    handleTimesheetEntriesSavingSaga(),
    handleTimesheetEntrySavingSaga(),
    handleTimesheetEntrySavedSaga()
  ]);
}
