import { useDispatch, useSelector } from "react-redux";
import {
  showDialog,
  hideDialog
} from "../../../../redux/modules/ui/projects/dialog/actions";
import { RootState } from "../../../../index";

const useDialog = () => {
  const dispatch = useDispatch();
  const { open, id } = useSelector(
    (state: RootState) => state.ui.projects.dialog
  );

  const show = (id?: string | null) => {
    dispatch(showDialog(id));
  };
  const close = () => {
    dispatch(hideDialog());
  };

  return {
    open,
    id,
    show,
    close
  };
};

export default useDialog;
