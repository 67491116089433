import { SET_FILTERS, RESET_FILTERS } from "./constants";

export const setFilters = filters => ({
  type: SET_FILTERS,
  payload: { filters }
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
  payload: {}
});
