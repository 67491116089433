import Grid from "@material-ui/core/Grid";
import Typography from "../../../atoms/Typography";
import { Button, Card, CircularProgress, TextField } from "../../../atoms";
import CardContent from "../../../atoms/CardContent";
import React, { useContext, useRef } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { options as cardElementOpts } from "./cardElement";
import Context from "./context";
import { ButtonContainer } from "./updatePaymentMethod/updatePaymentMethod.styles";
import { actionTypes } from "./reducer";
import Isemail from "isemail";

const setupIntent = async ({
  baseUrl,
  customerId,
  paymentMethodId,
  priceId,
  quantity,
  firebaseIdToken,
}) => {
  const response = await fetch(`${baseUrl}/createSetupIntent`, {
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: firebaseIdToken,
    },
    body: JSON.stringify({
      customerId,
    }),
  });
  if (!response.ok) {
    return {
      error: `[create-setup-intent] Status ${response.status} - ${response.statusText}`,
    };
  }
  return response.json();
};

const updateCustomerDefaultPaymentMethod = async ({
  baseUrl,
  customerId,
  paymentMethodId,
  firebaseIdToken,
}) => {
  const response = await fetch(
    `${baseUrl}/updateCustomerDefaultPaymentMethod`,
    {
      method: "post",
      headers: {
        "Content-type": "application/json",
        Authorization: firebaseIdToken,
      },
      body: JSON.stringify({
        customerId,
        paymentMethodId,
      }),
    }
  );
  if (!response.ok) {
    return {
      error: `[create-setup-intent] Status ${response.status} - ${response.statusText}`,
    };
  }
  return response;
};

export default ({ state, dispatch }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { reset, initialize } = useContext(Context);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  if (
    !state.showUpdatePaymentMethod ||
    state.isLoadingProducts ||
    state.isLoadingSubscriptions
  )
    return null;
  const {
    baseUrl,
    isUpdatingPaymentMethod,
    firebaseIdToken,
    customerId,
    subscriptionInformation,
    billingInformationInputErrors,
  } = state;
  const subscription = subscriptionInformation?.subscription;

  const validateInputs = () => {
    const email = emailRef?.current?.value;
    const name = nameRef?.current?.value;
    let ret;
    if (name?.length <= 0) {
      ret = { name: true };
    }
    if (!Isemail.validate(email)) {
      ret = { ...(ret || {}), email: true };
    }
    return ret;
  };

  const update = async () => {
    const billingInformationInputErrors = validateInputs();
    if (billingInformationInputErrors) {
      dispatch({
        type: actionTypes.setBillingInformationInputErrors,
        payload: { billingInformationInputErrors },
      });
    } else {
      dispatch({
        type: actionTypes.setBillingInformationInputErrors,
        payload: {},
      });
      try {
        dispatch({ type: actionTypes.toggleUpdatingPaymentMethod });
        const intent = await setupIntent({
          subscription,
          firebaseIdToken,
          baseUrl,
          customerId,
        });

        const result = await stripe.confirmCardSetup(intent.client_secret, {
          payment_method: {
            card: elements.getElement("card"),
            billing_details: {
              name: nameRef?.current?.value,
              email: emailRef?.current?.value,
            },
          },
        });
        await updateCustomerDefaultPaymentMethod({
          baseUrl,
          firebaseIdToken,
          paymentMethodId: result.setupIntent.payment_method,
          customerId,
        });
        reset();
        initialize();
      } catch (e) {
        dispatch({
          type: actionTypes.setUpdatedPaymentMethodStatus,
          payload: {
            updatedPaymentMethodStatus: {
              status: "error",
              message: e.toString(),
            },
          },
        });
        console.error(e);
      }
    }
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">Update payment method</Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        variant="body2"
                        color="textSecondary"
                      >
                        Adding a new card will replace it with your current
                        card.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        variant="body2"
                        color="textSecondary"
                      >
                        The new card will be used for all future charges
                        effective immediately.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    id="billingInformation"
                    component="form"
                    container
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <Typography color="textSecondary">
                        Billing information
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        inputProps={{ ref: nameRef }}
                        error={billingInformationInputErrors?.name}
                        variant="filled"
                        label="Name"
                        helperText="Name of the person to contact regarding billing."
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        inputProps={{ ref: emailRef }}
                        required
                        error={billingInformationInputErrors?.email}
                        variant="filled"
                        id="standard-required"
                        label="Email"
                        helperText="Email to where receipts and other billing information will be sent."
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <CardElement
                    options={cardElementOpts}
                    // onChange={handleCardDetailsChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonContainer>
                    <Button
                      disabled={isUpdatingPaymentMethod}
                      variant="contained"
                      onClick={update}
                      color="primary"
                    >
                      Save
                    </Button>
                    {isUpdatingPaymentMethod && <CircularProgress size={24} />}
                  </ButtonContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
