import { useMutation, useQueryClient } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import CompanySetting from "../models/CompanySetting";
import useUser from "./useUser";
import { getKey as getCompanySettingsKey } from "./useCompanySettings";
import { AllowedInstanceTypes } from "../models/companySetting/types";

const useCompanySettingMutation = () => {
  const workspace = useWorkspace();
  const queryClient = useQueryClient();
  const user = useUser();
  const save = (payload: AllowedInstanceTypes) => {
    if (payload instanceof CompanySetting) {
      return CompanySetting.save(workspace, user.email, payload);
    } else throw new Error("No company setting to save");
  };

  return useMutation(save, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getCompanySettingsKey(workspace));
    }
  });
};

export default useCompanySettingMutation;
