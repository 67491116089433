import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../index";
import { setPeriod } from "../../../../redux/modules/ui/report/period/actions";

const usePeriod = () => {
  const { period } = useSelector((state: RootState) => state.ui.report);
  const dispatch = useDispatch();
  const update = (updates: { year: number; month: number }) =>
    dispatch(setPeriod(updates.year, updates.month));
  return { ...period, update };
};

export default usePeriod;
