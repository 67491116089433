import React, { forwardRef } from "react";
import {
  TableRow,
  TableCell
} from "@superprofit/core-react-components/atoms/Table";
import {
  IconButton,
  Tooltip,
  Checkbox
} from "@superprofit/core-react-components/atoms";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tags from "../../../../components/molecules/Tags";
import ProjectLink from "../../../../components/molecules/ProjectLink";
import UserDisplay from "../../../../components/molecules/UserDisplay";
import ProjectAvatar from "../../../../components/molecules/ProjectAvatar";
import ProjectLabel from "../../../../components/molecules/ProjectLabel";
import { useWorkspaceHistory } from "../../../../contexts/WorkspaceContext";
import { IProjectRow } from "./types";

export default forwardRef(
  (
    { project, onMore, onSelect, selected = false, ...rest }: IProjectRow,
    ref
  ) => {
    return (
      <TableRow key={project.id} {...rest} ref={ref}>
        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <Checkbox
            checked={selected}
            value={project.id}
            onChange={onSelect}
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          />
        </TableCell>

        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <ProjectAvatar project={project} />
        </TableCell>

        <TableCell>
          <ProjectLink id={project.id || "noid"} name={project.name} />
        </TableCell>

        <TableCell>
          <ProjectLabel billable={project.billable} />
        </TableCell>

        <TableCell>
          <UserDisplay
            name={project.managerName}
            email={project.managerEmail}
          />
        </TableCell>

        <TableCell>
          <Tags tags={project.tags} />
        </TableCell>

        <TableCell align="right">
          <Tooltip title="More" aria-label="More">
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onMore(e, project);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }
);
