import { Timet } from "@superprofit/timet-types";
import { QueryDocumentSnapshot } from "@firebase/firestore";
import { functions } from "../firebase";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc
} from "firebase/firestore";
import { isStaging } from "@superprofit/timet-react-client/src/helpers";
type Comment = Timet.Firestore.ApprovalRecord.Comment;

export type GetApprovalRecordsOptions = {
  workspaceId: string;
  projects?: string[];
  users?: string[];
  statuses?: Timet.Firestore.ApprovalRecord.ApprovalRecordData["status"][];
  fromDate?: Date;
  toDate?: Date;
  id?: string;
};
export class ApprovalRecord
  implements Timet.Firestore.ApprovalRecord.ApprovalRecord {
  id: string;
  fromDate: Date;
  toDate: Date;
  comments: Comment[];
  statusLogs: Timet.Firestore.ApprovalRecord.StatusLog[];
  createdAt: Date;
  updatedAt: Date;
  user: string;
  project: string;
  status: "submitted" | "rejected" | "approved";
  checksum: string;
  createdBy: string;
  updatedBy: string;

  static COLLECTION = "approvalRecords";

  static converter = {
    fromFirestore(
      snap: QueryDocumentSnapshot<
        Timet.Firestore.ApprovalRecord.ApprovalRecordData
      >
    ): Timet.Firestore.ApprovalRecord.ApprovalRecord {
      const data = snap.data();
      const createdAt = data.createdAt.toDate();
      const updatedAt = data.updatedAt.toDate();
      const fromDate = data.fromDate.toDate();
      const toDate = data.toDate.toDate();
      const comments = (data.comments || []).map(comment => ({
        ...comment,
        createdAt: comment.createdAt.toDate()
      }));
      const statusLogs = (data.statusLogs || []).map(statusLog => ({
        ...statusLog,
        createdAt: statusLog.createdAt.toDate()
      }));

      return new ApprovalRecord({
        id: snap.id,
        ...data,
        comments,
        statusLogs,
        fromDate,
        toDate,
        updatedAt,
        createdAt
      });
    },
    toFirestore(modelObject: any) {
      return modelObject;
    }
  };

  constructor(data: Timet.Firestore.ApprovalRecord.ApprovalRecord) {
    this.id = data.id;
    this.fromDate = data.fromDate;
    this.toDate = data.toDate;
    this.comments = data.comments;
    this.statusLogs = data.statusLogs;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.user = data.user;
    this.project = data.project;
    this.status = data.status;
    this.checksum = data.checksum;
    this.createdBy = data.createdBy;
    this.updatedBy = data.updatedBy;
  }

  static async create(payload: Timet.Api.ApprovalRecords.CreatePayload) {
    // Create approval record
    const payloadWithMetadata: Timet.Api.WithCallableMetadata<Timet.Api.ApprovalRecords.CreatePayload> = {
      ...payload,
      metadata: {
        databaseId: isStaging() ? "staging" : undefined,
        triggerEmail: true
      }
    };
    const callable = functions.httpsCallable("approval-records-create");
    const result = await callable(payloadWithMetadata);
    const data = result.data as Timet.Firestore.ApprovalRecord.ApprovalRecord;
    return new ApprovalRecord(data);
  }

  static async update(payload: Timet.Api.ApprovalRecords.UpdatePayload) {
    // Update approval record

    const payloadWithMetadata: Timet.Api.WithCallableMetadata<Timet.Api.ApprovalRecords.UpdatePayload> = {
      ...payload,
      metadata: {
        databaseId: isStaging() ? "staging" : undefined,
        triggerEmail: true
      }
    };
    const callable = functions.httpsCallable("approval-records-update");
    const result = await callable(payloadWithMetadata);
    const data = result.data as Timet.Firestore.ApprovalRecord.ApprovalRecord;
    return new ApprovalRecord(data);
  }

  static async getApprovalRecords(options: GetApprovalRecordsOptions) {
    const records: Timet.Firestore.ApprovalRecord.ApprovalRecord[] = [];

    const { workspaceId } = options;
    const workspaceRef = doc(db, "workspaces", workspaceId);

    if (options.id) {
      const docRef = doc(
        workspaceRef,
        ApprovalRecord.COLLECTION,
        options.id
      ).withConverter(ApprovalRecord.converter);
      const result = await getDoc(docRef);
      const data = result.data();
      if (data) {
        records.push(data);
      }
      return records;
    }

    const wheres = [];
    if (options.projects) {
      wheres.push(where("project", "in", options.projects));
    }
    if (options.users) {
      wheres.push(where("user", "in", options.users));
    }
    if (options.statuses) {
      wheres.push(where("status", "in", options.statuses));
    }

    if (options.fromDate && options.toDate) {
      wheres.push(where("fromDate", ">=", options.fromDate));
      wheres.push(where("toDate", "<=", options.toDate));
    }

    const q = query(
      collection(workspaceRef, ApprovalRecord.COLLECTION),
      ...wheres
    ).withConverter(ApprovalRecord.converter);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc => {
      if (doc.exists()) {
        records.push(doc.data());
      }
    });
    return records;
  }
}
