import { useSelector } from "react-redux";
import { RootState } from "../index";

const useClaims = () => {
  const claims = useSelector(
    (state: RootState) => state.api.auth?.idTokenResult?.claims
  );
  return claims;
};

export default useClaims;
