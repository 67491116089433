import styled, { css } from "styled-components";
import theme from "styled-theming";
import Grid from "@superprofit/core-react-components/atoms/Grid";

const colors = theme.variants("mode", "color", {
  gradient: {
    light: css`
      color: #f8f8f8;
      background: linear-gradient(
        ${props => props.theme.palette.primary.main},
        ${props => props.theme.palette.primary.dark}
      );
    `
  },
  white: {
    light: css`
      color: ${props => props.theme.palette.text.primary};
      background: #f8f8f8;
    `
  }
});

export const StyledLandingPage = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`;

export const LandingSection = styled.div`
  min-height: 760px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${colors};

  > * {
    margin: 30px 0 30px 30px;
    overflow-x: hidden;
  }
`;

export const PlansContainer = styled.div`
  margin: ${props => props.theme.spacing(3)}px;
  max-width: 1080px;
`;

export const TitleContainer = styled.div`
  margin: ${props => props.theme.spacing(3)}px;
  flex: 1;

  .MuiTypography-h1 {
    margin: ${props => props.theme.spacing(3)}px;
    margin-left: 0;
    font-size: 2rem;
    font-weight: 300;
  }

  .MuiTypography-h2 {
    margin: ${props => props.theme.spacing(3)}px;
    margin-left: 0;
    font-size: 1.3rem;
    font-weight: 300;
  }
`;

export const FeaturesSection = styled.div`
  margin: ${props => props.theme.spacing(3)}px;
  flex: 1;
  overflow-x: visible;

  .features-heading {
    text-align: center;
    margin: ${props => props.theme.spacing(3)}px;
    margin-left: 0;
    font-size: 2rem;
    font-weight: 300;
  }

  .features-sub {
    text-align: center;
    margin: ${props => props.theme.spacing(3)}px;
    margin-left: 0;
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 2rem;
  }

  .features-grid {
    max-width: 960px;
  }
`;

export const ClickToSignIn = styled.span`
  font-size: 14px;
  font-weight: 300;
  margin-left: ${props => props.theme.spacing(3)}px;
`;

export const FeatureCard = styled.div`
  padding: ${props => props.theme.spacing(3)}px;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.8rem;
  border-left: 2px solid ${props => props.theme.palette.secondary.main};
  background: white;
  box-shadow: ${props => props.theme.shadows["18"]};
`;

export const FeaturesGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  > * {
    box-sizing: border-box;
    padding: ${props => props.theme.spacing(3)}px;
    flex-basis: 50%;
    display: flex;
    > * {
      flex: 1;
      width: 100%;
      padding: ${props => props.theme.spacing(3)}px;
    }
  }
`;
