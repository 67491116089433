import React, { useMemo } from "react";
import DurationFormat from "../../../../../components/atoms/DurationFormat";
import DistributionBar from "../../../../../components/atoms/DistributionBar";
import Hint from "../../../../../components/atoms/Hint";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  StyledProjectDistribution,
  StyledProjectDistributionStats
} from "./ProjectDistribution.styles";
import Important from "../../../../../components/atoms/Important";
import { getHoursByProject } from "../../../../../utils/ProjectStats";
import useTheme from "@material-ui/core/styles/useTheme";
import useWorkspaceStats from "../hooks/useWorkspaceStats";
import TimetUser from "../../../../../models/TimetUser";
import { getDaysInMonth, set } from "date-fns";
import { useTranslation } from "react-i18next";

export default () => {
  const theme = useTheme<{ palette: any }>();
  const {
    statsCurrentMonth: stats,
    isLoading: loading,
    users,
    period: { year, month },
    projectsMap
  } = useWorkspaceStats();

  const { t } = useTranslation();

  const projectsById = Object.fromEntries(projectsMap);

  const totalCapacity = useMemo(() => {
    if (!users || loading) return 0;

    const periodAsDate = set(new Date(), { year, month: month - 1 });

    // The previous code before date-fns:

    // const weeks =
    //   moment()
    //     .year(year)
    //     .month(month - 1)
    //     .daysInMonth() / 7;
    const weeks = getDaysInMonth(periodAsDate) / 7;
    return users.reduce((prev: number, next: TimetUser) => {
      return prev + next.getCapacity() * weeks;
    }, 0);
  }, [users, loading]);

  const filteredStats = useMemo(() => {
    if (loading || !stats) return [];
    return stats;
  }, [stats, loading]);

  const [billableHours, nonBillableHours] = useMemo(() => {
    if (loading || !filteredStats) return [0, 0];
    const hoursByProject = getHoursByProject(filteredStats) as {
      [key: string]: number;
    };
    let billable = 0;
    let nonBillable = 0;
    Object.keys(hoursByProject).forEach(k => {
      if (projectsById[k].billable) billable += hoursByProject[k];
      else nonBillable += hoursByProject[k];
    });
    return [billable, nonBillable];
  }, [filteredStats, projectsById, loading]);

  const distributionDataset = useMemo(() => {
    if (loading || !projectsById) return [];
    return [
      {
        value: billableHours,
        color: theme.palette.primary.main,
        tooltip: (
          <DurationFormat
            value={billableHours}
            prefix={`${t("common.billable")}: `}
          />
        )
      },
      {
        value: nonBillableHours,
        color: theme.palette.tertiary.main,
        tooltip: (
          <DurationFormat
            value={nonBillableHours}
            prefix={`${t("common.nonBillable")}: `}
          />
        )
      }
    ];
  }, [loading, projectsById, billableHours, nonBillableHours]);

  return (
    <StyledProjectDistribution>
      <div>
        <Hint>{t("components.atoms.typeDistribution.header")}</Hint>
        {loading ? (
          <Skeleton height={20} width="100%" />
        ) : (
          <DistributionBar dataset={distributionDataset} max={totalCapacity} />
        )}
      </div>
      <StyledProjectDistributionStats>
        <li>
          <Hint>{t("common.billable")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <DurationFormat value={billableHours} />
            </Important>
          )}
        </li>
        <li>
          <Hint>{t("common.nonBillable")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <DurationFormat value={nonBillableHours} />
            </Important>
          )}
        </li>
      </StyledProjectDistributionStats>
    </StyledProjectDistribution>
  );
};
