import { combineReducers } from "redux";

import settings from "./settings/reducer";
import snackbar from "./snackbar/reducer";
import goalDialog from "./goalDialog/reducer";
import goals from "./goals/reducer";
import fixEmployeeNumbersDialog from "./fixEmployeeNumbersDialog/reducer";
import nettlonnFixProjectsDialog from "./nettlonnFixProjectsDialog/reducer";
import projectSalaryRateFixProjectsDialog from "./projectSalaryRateFixProjectsDialog/reducer";

export default combineReducers({
  settings,
  snackbar,
  goalDialog,
  goals,
  fixEmployeeNumbersDialog,
  nettlonnFixProjectsDialog,
  projectSalaryRateFixProjectsDialog
});
