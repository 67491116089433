export const getTotalHours = stats => {
  return stats.reduce((prev, next) => prev + next.total, 0);
};

export const getTotalMoney = (stats, projectsById) => {
  return stats.reduce(
    (prev, next) =>
      prev + next.total * projectsById[next.project].getBillableRate(next.user),
    0
  );
};

export const getTotalCapacity = users => {};

export const getTotalPlannedHours = (year, month, projects) => {
  return projects.reduce(
    (prev, next) => prev + next.getProjectPlannedHoursByMonth(year, month),
    0
  );
};

export const getTotalPlannedMoney = (year, month, projects) => {
  return projects.reduce(
    (prev, next) => prev + next.getProjectPlannedMoneyByMonth(year, month),
    0
  );
};
