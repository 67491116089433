import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  POST,
  POST_FAILURE,
  POST_SUCCESS,
  WATCH_GET,
  WATCH_POST,
} from "./constants";

export const get = () => ({
  type: GET,
  payload: {}
});

export const getSuccess = ({email, role}) => ({
  type: GET_SUCCESS,
  payload: { email, role }
});

export const getFailure = error => ({
  type: GET_FAILURE,
  payload: { error }
});

export const post = () => ({
  type: POST,
  payload: {}
});

export const postSuccess = ({email, role}) => ({
  type: POST_SUCCESS,
  payload: { email, role }
});

export const postFailure = error => ({
  type: POST_FAILURE,
  payload: { error }
});

export const watchGetUserRole = ({email}) => ({
  type: WATCH_GET,
  payload: { email}
});

export const watchPostUserRole = ({email, role}) => ({
  type: WATCH_POST,
  payload: { email, role }
});
