import Timesheet from "../pages/protected/Timesheet";
import TimesheetV2 from "../pages/protected/TimesheetV2";
import Projects from "../pages/protected/Projects";
import Users from "../pages/protected/Users";
import Leads from "../pages/protected/Leads";
import Customers from "../pages/protected/Customers";
import Dashboard from "../pages/protected/WorkspaceOverview";
import Subscription from "../pages/protected/Subscription";
import Purchase from "../pages/protected/Purchase";
import ProfileSettings from "../pages/protected/ProfileSettings";
import Invoices from "../pages/protected/Invoices";
import CompanySettings from "../pages/protected/CompanySettings";
import CustomerOverview from "../pages/protected/CustomerOverview";
import UserOverview from "../pages/protected/UserOverview";
import ProjectOverview from "../pages/protected/ProjectOverview";
import UserHoursMonth from "../pages/protected/reports/UsersHours";
import ProjectHoursReport from "../pages/protected/reports/ProjectsHours";
import CustomerHoursMonth from "../pages/protected/reports/CustomersHours";
import SalaryReports from "../pages/protected/reports/Salary";
import Success from "../pages/protected/subscription/Success";
import Groups from "../pages/protected/UserGroups";
import { Approvals } from "../pages/protected/Approvals";
import { UserApproval } from "../pages/protected/UserApproval";
import { ApprovalsByUser } from "@superprofit/timet-react-client/src/pages/protected/ApprovalsByUser";

const routes = [
  {
    id: "approvals",
    path: "/approvals",
    component: Approvals
  },
  {
    id: "approvalsByUser",
    path: "/approvals-by-user",
    component: ApprovalsByUser
  },
  {
    id: "dashboard",
    path: "/dashboard",
    component: Dashboard
  },
  {
    id: "reports-by-users",
    path: "/reports/by-users",
    component: UserHoursMonth
  },
  {
    id: "reports-by-projects",
    path: "/reports/by-projects",
    component: ProjectHoursReport
  },
  {
    id: "reports-by-customers",
    path: "/reports/by-customers",
    component: CustomerHoursMonth
  },
  {
    id: "reports-for-salary",
    path: "/reports/salary",
    component: SalaryReports
  },
  {
    id: "userApprovals",
    path: "/users/:id/approve",
    component: UserApproval
  },
  {
    id: "userOverview",
    path: "/users/:id",
    component: UserOverview,
    routes: [
      // {
      //   id: "userApprovals",
      //   path: "/approve",
      //   component: UserApproval
      // }
    ]
  },
  {
    id: "users",
    path: "/users",
    component: Users
  },
  {
    id: "groups",
    path: "/groups",
    component: Groups
  },
  {
    id: "projectOverview",
    path: "/projects/:id",
    component: ProjectOverview
  },
  {
    id: "projects",
    path: "/projects",
    component: Projects
  },
  {
    id: "leads",
    path: "/leads",
    component: Leads
  },
  {
    id: "customerOverview",
    path: "/customers/:id",
    component: CustomerOverview
  },
  {
    id: "customers",
    path: "/customers",
    component: Customers
  },
  {
    id: "company-settings",
    path: "/settings",
    component: CompanySettings
  },
  {
    id: "subscription",
    path: "/subscription",
    component: Subscription,
    routes: [
      {
        id: "subscriptionSuccess",
        path: "/success",
        component: Success
      }
    ]
  },
  {
    id: "purchase",
    path: "/purchase",
    component: Purchase
  },
  {
    id: "invoices",
    path: "/invoices",
    component: Invoices
  },
  {
    id: "timesheet",
    path: "/timesheet",
    component: Timesheet
  },
  {
    id: "timesheetV2",
    path: "/timesheet-v2",
    component: TimesheetV2
  },
  {
    id: "timesheetV2Card",
    path: "/timesheet-v2-card",
    component: TimesheetV2
  },
  {
    id: "profile-settings",
    path: "/profile/settings",
    component: ProfileSettings
  }
];

function getNestedRoutePath(id, routes) {
  for (let route of routes) {
    if (route.id === id) {
      return route.path;
    }
    if (route.routes) {
      let nestedPath = getNestedRoutePath(id, route.routes);
      if (nestedPath) {
        return route.path + nestedPath;
      }
    }
  }
  return null;
}

function getNestedRoute(id, routes) {
  for (let route of routes) {
    if (route.id === id) {
      return route;
    }
    if (route.routes) {
      let nestedRoute = getNestedRoute(id, route.routes);
      if (nestedRoute) {
        return nestedRoute;
      }
    }
  }
  return null;
}

// A bit hacky to prefix workspace at any given time
export const getPathWithKey = key => {
  const [empty, app, workspace] = window.location.pathname.split("/");

  const path = getNestedRoutePath(key, routes);

  if (!path) {
    throw new Error(`Route with id ${key} not found`);
  }

  return `/${app}/${workspace}${path}`;
};

export const getPathWithParams = (key, params = {}) => {
  let path = getPathWithKey(key);
  Object.keys(params).forEach(param => {
    path = path.replace(`:${param}`, params[param]);
  });
  return path;
};

export const getPathWithKeyRaw = key => getNestedRoute(key, routes).path;

export const getPathWithParamsRaw = (key, params = {}) => {
  let path = getPathWithKeyRaw(key);
  Object.keys(params).forEach(param => {
    path = path.replace(`:${param}`, params[param]);
  });
  return path;
};

export default routes;
