import ProgressBar from "../../../../components/atoms/ProgressBar";
import React, { useMemo } from "react";
import Hint from "../../../../components/atoms/Hint";
import { StyledBudget, StyledBudgetStats } from "./Budget.styles";
import Important from "../../../../components/atoms/Important";
import Skeleton from "@material-ui/lab/Skeleton";
import { BUDGET_TYPE_ENUM } from "../../../../models/BudgetTypes";
import DurationFormat from "../../../../components/atoms/DurationFormat";
import NumberFormat from "react-number-format";
import {
  getHoursByProject,
  getMoneyByProject
} from "../../../../utils/ProjectStats";
import ConnectedCurrencyFormat from "../../../../components/organisms/ConnectedCurrencyFormat";
import { useTranslation } from "react-i18next";

export default ({ loading, project, stats }) => {
  const budget = (project && project.budget) || {};
  const { t } = useTranslation();

  const { money, hours } = useMemo(() => {
    if (loading || !project) return {};
    const totalHours = getHoursByProject(stats);
    const totalMoney = getMoneyByProject(stats, { [project.id]: project });

    return {
      money: totalMoney[project.id],
      hours: totalHours[project.id]
    };
  }, [project, stats, loading]);

  let progress = 0;
  let remaining = "∞";
  let total = <ConnectedCurrencyFormat number={money} />;

  if (budget && budget.type === BUDGET_TYPE_ENUM.MONEY_BUDGET) {
    progress = Math.min(100, (money / budget.value) * 100);
    remaining = <ConnectedCurrencyFormat number={budget.value - money} />;
    total = <ConnectedCurrencyFormat number={money} />;
  } else if (budget && budget.type === BUDGET_TYPE_ENUM.TIME_BUDGET) {
    progress = (hours / budget.value) * 100;
    remaining = <DurationFormat value={budget.value - hours} />;
    total = <DurationFormat value={hours} />;
  }

  return (
    <StyledBudget>
      <div>
        <Hint>Budget spent</Hint>
        {loading ? (
          <Skeleton height={20} width="100%" />
        ) : (
          <ProgressBar
            align="left"
            progress={Math.min(100, progress)}
            color="primary"
          />
        )}
      </div>

      <StyledBudgetStats>
        <li>
          <Hint>{t("common.spent")} %</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>{budget ? `${progress.toFixed(1)}%` : "-"}</Important>
          )}
        </li>

        <li>
          <Hint>{t("common.spent")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>{total}</Important>
          )}
        </li>

        <li>
          <Hint>{t("common.remaining")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>{remaining}</Important>
          )}
        </li>
      </StyledBudgetStats>
    </StyledBudget>
  );
};
