import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/functions";
import { registerFirestore } from "firebase/compat/firestore";
import { initializeFirestore, getFirestore, fi } from "firebase/firestore";

import {
  apiKey,
  appId,
  authDomain,
  databaseUrl,
  projectId,
  messagingSenderId,
  storageBucket,
  measurmentId
} from "../config/firebase";
import { isStaging } from "../helpers";

/*// Configure Firebase.
let config = {
  apiKey: "AIzaSyAD8eA-knW7XgjCvxyxg4gJ_B4jBGWvUw4",
  authDomain: "timetrack-222415.firebaseapp.com",
  databaseURL: "https://timetrack-222415.firebaseio.com",
  projectId: "timetrack-222415",
  storageBucket: "timetrack-222415.appspot.com",
  messagingSenderId: "940492193926",
};
let config = {
  apiKey: "AIzaSyAD8eA-knW7XgjCvxyxg4gJ_B4jBGWvUw4",
  authDomain: "timetrack-222415.firebaseapp.com",
  databaseURL: "https://timetrack-222415.firebaseio.com",
  projectId: "timetrack-222415",
  storageBucket: "timetrack-222415.appspot.com",
  messagingSenderId: "940492193926",
};

if (window.location.hostname === "prod-timet.web.app") {
  config = {
    apiKey: "AIzaSyDLU_veyYOQ8Z1PRKkkDkTLqiiatvHugmw",
    authDomain: "prod-timet.firebaseapp.com",
    databaseURL: "https://prod-timet.firebaseio.com",
    projectId: "prod-timet",
    storageBucket: "prod-timet.appspot.com",
    messagingSenderId: "705891831442",
    appId: "1:705891831442:web:29c5652b6186d5a3c5a04b",
    measurementId: "G-898DSCXZ53",
  };
}

// prod analytics
// firebase.analytics();*/
const app = firebase.initializeApp({
  apiKey,
  appId,
  authDomain,
  databaseUrl,
  projectId,
  messagingSenderId,
  storageBucket,
  measurmentId
});

if (projectId === "dev-timet-eu" && isStaging()) {
  firebase.firestore()._delegate._databaseId = {
    ...firebase.firestore()._delegate._databaseId,
    database: "staging"
  };
}

export const db = firebase.firestore();

export const logEvent = (...opts) => {
  if (window.__TIMET_DEBUG_LOG_EVENT__)
    console.log(["log-event]"], measurmentId, ...opts);
  if (measurmentId) {
    return firebase.analytics().logEvent(...opts);
  }
};

export default firebase;
