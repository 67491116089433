import {
  GET,
  GET_FAILURE,
  GET_SUCCESS, REMOVE, REMOVE_FAILURE, REMOVE_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS
} from "./constants";
import {Comment, CommentsState} from "./types";
import {Reducer} from "redux";



const initialState: CommentsState = {
  list: [],
  loading: false,
  commentSaving: null
};

const reducer: Reducer<CommentsState>  = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.comments
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };

    case SAVE:
      return {
        ...state,
        commentSaving: true
      };

    case SAVE_SUCCESS: {
      return {
        ...state,
        commentSaving: false,
        list: [payload.comment].concat(state.list)
      };
    }

    case SAVE_FAILURE:
      return {
        ...state,
        commentError: payload.error
      };

    case REMOVE:
      return {
        ...state,
        deleting: true
      };

    case REMOVE_SUCCESS: {
      return {
        ...state,
        deleting: false,
        list: state.list.filter((c) => c.id !== payload.id)
      };
    }

    case REMOVE_FAILURE:
      return {
        ...state,
        deleteError: payload.error,
        deleting: false
      };

    default:
      return state;
  }
};

export default reducer;
