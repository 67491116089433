import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  SAVE_FAILURE
} from "./constants";

import {UserSettingsState} from "./types";
import {Reducer} from "redux";
import UserSettingGeneric from "../../../../models/UserSettingGeneric";
import UserSettingGoal from "../../../../models/UserSettingGoal";

const initialState: UserSettingsState = {
  loading: false,
  saving: false,
  settings: {
  }
};

const reducer: Reducer<UserSettingsState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...payload.settings
        },
        loading: false
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false
      };

    case SAVE:
      return {
        ...state,
        saving: true
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        saving: false,
        settings: {
          ...state.settings,
          ...payload.savedSettings.reduce((acc: {[key:string]: UserSettingGeneric | UserSettingGoal}, curr: UserSettingGeneric | UserSettingGoal) => ({...acc, [curr.id as string]: curr}), {})
        }
      };
    case SAVE_FAILURE:
      return {
        ...state,
        saving: false
      };

    default:
      return state;
  }
};

export default reducer;
