import {
  SET_ENTRIES_BY_PROJECT,
  STAGE_ENTRIES,
  WATCH_REFRESH_ENTRIES
} from "./constants";

export const stageEntry = entry => ({
  type: STAGE_ENTRIES,
  payload: {
    entries: [entry]
  }
});

export const stageEntries = entries => ({
  type: STAGE_ENTRIES,
  payload: {
    entries
  }
});

export const setEntriesByProject = entries => ({
  type: SET_ENTRIES_BY_PROJECT,
  payload: {
    entries
  }
});

export const watchRefreshEntries = () => ({
  type: WATCH_REFRESH_ENTRIES
});
