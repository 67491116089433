import { combineReducers } from "redux";

import settings from "./settings/reducer";
import snackbar from "./snackbar/reducer";
import goalDialog from "./goalDialog/reducer";
import goals from "./goals/reducer";

export default combineReducers({
  settings,
  snackbar,
  goalDialog,
  goals
});
