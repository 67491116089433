import React from "react";
import { moment } from "@superprofit/time-util";

import UserInfo from "./userHours/UserInfo";
import HoursTable from "./HoursTable";
import { Container } from "./userHours/userHours.styles";

export default ({ currency, entries, project, user, getTotalCost }) => {
  const { from, to } = entries.reduce(
    (acc, curr) => {
      const momentBasicOrdinal = curr.year * 1000 + curr.dayOfYear;
      return {
        from: momentBasicOrdinal >= acc.from ? acc.from : momentBasicOrdinal,
        to: momentBasicOrdinal <= acc.to ? acc.to : momentBasicOrdinal,
      };
    },
    { from: 9999999, to: 0 }
  );
  let period = moment(from.toString()).format("MMMM");
  if (moment(from.toString()).month() !== moment(to.toString()).month()) {
    period = `${moment(from.toString()).format("MMMM")} - ${moment(
      to.toString()
    ).format("MMMM")}`;
  }
  period = `${period[0].toUpperCase()}${period.slice(1)}`;
  return (
    <Container>
      <UserInfo {...user} period={period} />
      <HoursTable
        currency={currency}
        project={project}
        entries={entries}
        getTotalCost={getTotalCost}
      />
    </Container>
  );
};
