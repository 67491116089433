import React, { Fragment, useEffect, useState } from "react";
import FormDialog from "@superprofit/core-react-components/molecules/FormDialog";
import MultiSelectProjects from "../../../../components/molecules/MultiSelectProjects";
import useFilterDialog from "./useFilterDialog";
import Project from "../../../../models/Project";
import { useTranslation } from "react-i18next";
import { useForm, Controller, FieldValues } from "react-hook-form";
import { State } from "../../../../redux/modules/ui/userOverview/projectsTable/types";

export default ({ ...rest }) => {
  const { t } = useTranslation();

  const {
    close,
    isOpen,
    filters,
    update,
    projectsMap,
    setIsClosable
  } = useFilterDialog();
  const projects = Array.from(projectsMap.values());
  const { handleSubmit, control, getValues, reset } = useForm<State["filters"]>(
    {
      defaultValues: {
        projects: filters.projects
      }
    }
  );

  useEffect(() => {
    reset({ projects: filters.projects });
  }, [isOpen]);

  const handleOnChangeProjects = (
    e: React.ChangeEvent<HTMLElement>,
    selected: Project[]
  ) => {
    update({ projects: selected });
  };

  const handleOnApply = () => {
    update(getValues());
    close();
  };

  const hasProjectFilter = filters.projects.length > 0;
  return (
    <Fragment>
      <FormDialog
        {...rest}
        key="form"
        saveActionTitle="Apply"
        onSave={handleOnApply}
        open={isOpen}
        onClose={close}
        title="Filter"
      >
        <form onSubmit={handleSubmit(handleOnApply)}>
          <Controller
            name="projects"
            control={control}
            render={({ field }) => {
              const { value, ref, onChange, ...rest } = field;
              return (
                <MultiSelectProjects
                  onClose={() => {
                    setIsClosable(true);
                  }}
                  value={value?.map((id: string) => projectsMap.get(id)) || []}
                  onChange={(
                    e: React.ChangeEvent<HTMLElement>,
                    selected: Project[]
                  ) => {
                    onChange(
                      selected.map(({ id }) => id).filter(Boolean) as string[]
                    );
                  }}
                  projects={projects}
                  onOpen={() => setIsClosable(false)}
                  variant="filled"
                  inputRef={ref}
                  fullWidth
                  label={t("common.projects")}
                  placeholder={hasProjectFilter ? "" : "All"}
                  {...rest}
                />
              );
            }}
          />
        </form>
      </FormDialog>
    </Fragment>
  );
};
