import styled, { css } from "styled-components";
import {
  List as L,
  AvatarGroup as AG,
} from "@superprofit/core-react-components/atoms";
import {
  ListSubheader as LS,
  ListItemSecondaryAction as LISA,
  ListItemText as LIT,
} from "@superprofit/core-react-components/atoms/List";

export const List = styled(L)`
  width: 100%;
  position: relative;
  overflow: auto;
  height: 0;
  flex: 1 1 auto;

  ul {
    background-color: inherit;
    padding: 0;
  }

  > li {
    background-color: inherit;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ListSubheader = styled(LS)`
  background-color: white;
`;

export const AvatarGroup = styled(AG)`
  > div {
    width: 2rem;
    height: 2rem;
  }
`;

export const ListItemText = styled(LIT)`
  ${({ removing, theme }) =>
    removing &&
    css`
      text-decoration: line-through;
      color: ${theme.palette.error.main};
      > span {
        font-weight: bold;
      }
    `}
  ${({ adding, theme }) =>
    adding &&
    css`
      color: ${theme.palette.success.main};
      > span {
        font-weight: bold;
      }
    `}
`;
export const ListItemSecondaryAction = styled(LISA)``;
