
import {Workspace} from "@superprofit/core-firestore-models";

export default class ApplicationAccess {
  static converter = {
    toFirestore(accountDetails) {
      return accountDetails.data();
    },
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      return new ApplicationAccess({ ...data, id: snapshot.id });
    },
  };

  static collectionName = "applicationAccess";
  static documentId = 'data';

  constructor({
                accessUntilTimestamp,
                maxInvoices,
                maxUsers,
                maxProjects,
              }) {
    this.accessUntilTimestamp = accessUntilTimestamp;
    this.maxInvoices = maxInvoices;
    this.maxUsers = maxUsers;
    this.maxProjects = maxProjects;
  }

  data() {
    return {
      accessUntilTimestamp: this.accessUntilTimestamp,
      maxInvoices: this.maxInvoices,
      maxUsers: this.maxUsers,
      maxProjects: this.maxProjects
    };
  }

  static listen = (db, workspace, callback) => {
    return db
      .collection(Workspace.collectionName)
      .doc(workspace)
      .collection(ApplicationAccess.collectionName)
      .doc(ApplicationAccess.documentId)
      .onSnapshot((doc) => {
        callback(doc.data());
      });
  };
  static get = async (db, workspace) => {
    const doc = await db
      .collection(Workspace.collectionName)
      .doc(workspace)
      .collection(ApplicationAccess.collectionName)
      .doc(ApplicationAccess.documentId)
      .get();
    return doc.data();
  };
}
