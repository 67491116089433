import React, { useState } from "react";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import Button from "@superprofit/core-react-components/atoms/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Input,
  NumberFormatIntl, CircularProgress,
} from '@superprofit/core-react-components/atoms';
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

export default () => {

  return (
    <Grid container justifyContent="center" alignItems="center" style={{padding: '2rem'}}>
      <Grid item >
        <Typography variant="h3" gutterBottom>Thank you for your purchase!</Typography>
      <Typography>You can manage your subscription at any time by clicking the link in the left menu under the <i>ACCOUNT</i> section.
      </Typography>
      </Grid>
    </Grid>

  );
};
