import DialogContent, {
  DialogContentProps
} from "@material-ui/core/DialogContent";
import React, { forwardRef, ForwardRefExoticComponent } from "react";

const Component: ForwardRefExoticComponent<DialogContentProps> = forwardRef(
  (props, ref) => <DialogContent {...props} ref={ref} />
);

export default Component;
