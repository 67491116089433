import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Grid from "../../../../components/atoms/Grid";
import TextField from "../../../../components/atoms/TextField";
import FormControl from "../../../../components/atoms/FormControl";
import React, { useMemo } from "react";
import ThemeProvider from "../../../../ThemeProvider";
import ReactDOM from "react-dom";
import Dialog from "../../../../components/atoms/Dialog";
import DialogContent from "../../../../components/atoms/DialogContent";
import DialogActions from "../../../../components/atoms/DialogActions";
import Button from "../../../../components/atoms/Button";
import DialogTitle from "../../../../components/atoms/DialogTitle";
import Select from "../../../../components/atoms/Select";
import MenuItem from "../../../../components/atoms/MenuItem";
import LinearProgress from "../../../../components/atoms/LinearProgress";
import FormHelperText from "../../../../components/atoms/FormHelperText";
import {
  getAuthToken,
  getIdentities,
  setAuthTokenInLocalStorage
} from "./helpers";

interface IFormInput {
  email: string;
  password: string;
  identity: string;
}

interface AsyncAuthenticateDialogProps {
  open: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  onError: () => void;
  containerElement: HTMLElement;
  workspace: string;
}

export function AsyncAuthenticateDialog(props: AsyncAuthenticateDialogProps) {
  const { t } = useTranslation();
  const { open = true, onCancel, onError, onSuccess, workspace } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [localOpen, setLocalOpen] = React.useState(open);
  const [identities, setIdentities] = React.useState<Identity[] | null>(null);

  const schema = useMemo(() => {
    let shape: any = {
      email: yup
        .string()
        .email()
        .required(),
      password: yup.string().required()
    };
    if (identities) {
      shape.identity = yup.string().required();
    }
    return yup.object().shape(shape);
  }, [identities]);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit"
  });

  const handleConfirmClose = () => {};

  const handleCancelClose = () => {};

  const handleOnClose = () => {
    setLocalOpen(false);
    onCancel();
  };
  const onSubmit: SubmitHandler<IFormInput> = async data => {
    try {
      setIsLoading(true);
      let identity = data.identity;
      if (identity !== null) {
        const identities = await getIdentities(data.email, data.password);

        if (identities.length === 0) {
          setIsLoading(false);
          throw new Error("No identities found");
        } else if (identities.length === 1) {
          identity = identities[0].id;
        } else {
          setIsLoading(false);
          setIdentities(identities);
        }
      }

      if (identity) {
        const token = await getAuthToken(
          data.email,
          data.password,
          identity,
          workspace
        );
        setAuthTokenInLocalStorage(token, workspace);
        setLocalOpen(false);
        setIsLoading(false);
        onSuccess();
      }
    } catch (error) {
      setIsLoading(false);
      onError();
    }
  };
  return (
    <ThemeProvider mode="light">
      <Dialog
        container={props.containerElement}
        keepMounted={false}
        PaperProps={{ component: "form", onSubmit: handleSubmit(onSubmit) }}
        key="form"
        open={localOpen}
        onClose={handleOnClose}
      >
        {isLoading && <LinearProgress />}
        <DialogTitle>
          {t("integrations.twentyFourSevenOffice.authenticate")}
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" fullWidth variant="outlined">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  {...register(`email`)}
                  fullWidth
                  variant="filled"
                  label={t("common.email")}
                  autoComplete={"email"}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register("password")}
                  fullWidth
                  variant="filled"
                  type={"password"}
                  label={t("common.password")}
                  autoComplete={"current-password"}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              </Grid>
              {identities && (
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <FormHelperText>
                          {t(
                            "integrations.twentyFourSevenOffice.identitySelectHelperText",
                            { workspace }
                          )}
                        </FormHelperText>
                        <Select
                          variant="filled"
                          fullWidth
                          error={!!errors.identity}
                          {...field}
                        >
                          {identities.map(identity => (
                            <MenuItem value={identity.id}>
                              {identity.client.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.identity && (
                          <FormHelperText error>
                            {errors.identity?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                    defaultValue=""
                    control={control}
                    name="identity"
                  />
                </Grid>
              )}
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelClose}
            color="primary"
            variant="outlined"
            disabled={isLoading}
          >
            {t("common.cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isLoading}
          >
            {t(identities ? "common.authenticate" : "common.login")}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

type ResolvePayload = {
  reason: "success" | "cancel";
  data: any;
};

type RejectPayload = {
  reason: "error";
  data: any;
};

type Resolve = (value: ResolvePayload) => void;
type Reject = (value: RejectPayload) => void;
type Options = {
  props: Partial<AsyncAuthenticateDialogProps> & { workspace: string };
};
export async function authenticate(options: Options) {
  const props = options.props || {};
  return new Promise((resolve: Resolve, reject: Reject) => {
    const containerElement = document.createElement("div");
    document.body.appendChild(containerElement);
    const unmount = () => {
      setTimeout(() => {
        document.body.removeChild(containerElement);
      }, 1000);
    };
    const {
      onSuccess,
      onCancel,
      onError,
      containerElement: ce,
      open,
      ...rest
    } = props;
    ReactDOM.render(
      <AsyncAuthenticateDialog
        open
        containerElement={containerElement}
        onSuccess={() => {
          unmount();
          resolve({ reason: "success", data: null });
        }}
        onCancel={() => {
          unmount();
          resolve({ reason: "cancel", data: null });
        }}
        onError={() => {
          unmount();
          reject({ reason: "error", data: null });
        }}
        {...rest}
      />,
      containerElement
    );
  });
}
