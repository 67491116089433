// export const GET = "@superprofit/core-redux-saga-modules/Workspaces/GET";
// export const GET_SUCCESS = "@superprofit/core-redux-saga-modules/Workspaces/GET_SUCCESS";
// export const GET_FAILURE = "@superprofit/core-redux-saga-modules/Workspaces/GET_FAILED";
//
// export const SAVE = "@superprofit/core-redux-saga-modules/Workspaces/SAVE";
// export const SAVE_SUCCESS = "@superprofit/core-redux-saga-modules/Workspaces/SAVE_SUCCESS";
// export const SAVE_FAILURE = "@superprofit/core-redux-saga-modules/Workspaces/SAVE_FAILED";
//
// export const EXIST = "@superprofit/core-redux-saga-modules/Workspaces/EXIST";
// export const EXIST_SUCCESS = "@superprofit/core-redux-saga-modules/Workspaces/EXIST_SUCCESS";
// export const EXIST_FAILURE = "@superprofit/core-redux-saga-modules/Workspaces/EXIST_FAILED";
//
// export const DELETE = "@superprofit/core-redux-saga-modules/Workspaces/DELETE";
// export const DELETE_SUCCESS = "@superprofit/core-redux-saga-modules/Workspaces/DELETE_SUCCESS";
// export const DELETE_FAILURE = "@superprofit/core-redux-saga-modules/Workspaces/DELETE_FAILED";
//
// export const WATCH_GET = "@superprofit/core-redux-saga-modules/Workspaces/WATCH_GET";
// export const WATCH_SAVE = "@superprofit/core-redux-saga-modules/Workspaces/WATCH_SAVE";
// export const WATCH_DELETE = "@superprofit/core-redux-saga-modules/Workspaces/WATCH_DELETE";
// export const WATCH_EXIST = "@superprofit/core-redux-saga-modules/Workspaces/WATCH_EXIST";
import createConstants from '../Helpers/moduleFactory/constants';
import {Workspace} from '@superprofit/core-firestore-models';

const constants = createConstants(Workspace, '', 'Workspaces');

export default constants;
