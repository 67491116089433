import React, { useMemo, useState } from "react";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import HoursHistoryLoader from "./activityCard/HoursHistoryLoader";
import LineChart from "../../../../components/atoms/LineChart";
import useTheme from "@material-ui/core/styles/useTheme";
import { getLabel, getLabels } from "../../../../utils/ChartUtils";
import { DashboardCard, EmptyState } from "./activityCard/ActivityCard.styles";
import { Typography } from "@superprofit/core-react-components/atoms";
import useWorkspaceStats from "./hooks/useWorkspaceStats";

import { useTranslation } from "react-i18next";

export default () => {
  const {
    statsYear: stats,
    isLoading: loading,
    projectsMap,
    period
  } = useWorkspaceStats();

  const [showPoints, setShowPoints] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme<{ palette: any }>();

  const projectsById = Object.fromEntries(projectsMap);

  const { labels, datasets } = useMemo(() => {
    if (loading || !stats) return { datasets: [], labels: [] };
    const billableStats = stats.filter(s => projectsById[s.project].billable);
    const nonBillableStats = stats.filter(
      s => !projectsById[s.project].billable
    );

    const labels = getLabels(stats);

    const billableHoursByWeek = billableStats.reduce(
      (prev, next) => ({
        ...prev,
        [getLabel(next)]: (prev[getLabel(next)] || 0) + next.total
      }),
      {} as Record<string, number>
    );

    const nonBillableHoursByWeek = nonBillableStats.reduce(
      (prev, next) => ({
        ...prev,
        [getLabel(next)]: (prev[getLabel(next)] || 0) + next.total
      }),
      {} as Record<string, number>
    );

    const datasets = [
      {
        color: theme.palette.tertiary.main,
        values: labels.map(l => nonBillableHoursByWeek[l] || 0),
        label: t("common.nonBillable")
      },
      {
        color: theme.palette.primary.main,
        values: labels.map(l => billableHoursByWeek[l] || 0),
        label: t("common.billable")
      }
    ];
    return {
      labels,
      datasets
    };
  }, [stats, loading, projectsById]);

  const handleMouseOver = () => {
    if (showPoints) return;
    setShowPoints(true);
  };

  const handleMouseLeave = () => {
    if (!showPoints) return;
    setShowPoints(false);
  };

  if (loading) {
    return <HoursHistoryLoader />;
  }

  return (
    <DashboardCard
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <CardHeader
        title={t("pages.workspaceOverview.templates.activityInYear", {
          year: period.year
        })}
        subheader={
          labels &&
          labels.length > 0 &&
          t("pages.workspaceOverview.templates.showingWeek", {
            weekOne: labels[0],
            weekTwo: labels[labels.length - 1]
          })
        }
      />
      {/*<ChartWrapper>*/}

      {labels && labels.length ? (
        <LineChart
          id="billable-nonbillable-customer"
          showPoints={showPoints}
          datasets={datasets}
          labels={labels}
        />
      ) : (
        <EmptyState>
          <Typography variant="h1">
            {t("pages.workspaceOverview.noRecentActivity")}
          </Typography>
          <Typography>{t("pages.workspaceOverview.logHours")}</Typography>
        </EmptyState>
      )}
      {/*</ChartWrapper>*/}
    </DashboardCard>
  );
};
