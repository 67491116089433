import React, { forwardRef } from "react";
import Link from "../atoms/Link";
import { getPathWithParamsRaw } from "../../router/routes";
import { useUserAcccess } from "../../helpers";

interface IProjectLink {
  id: string;
  name: string;
}

export default forwardRef(({ id, name, ...rest }: IProjectLink, ref) => {
  const access = useUserAcccess();
  if (!access?.isAdmin) return <b>{name}</b>;

  return (
    <Link
      to={getPathWithParamsRaw("projectOverview", { id })}
      color="inherit"
      withWorkspacePrefix
      {...rest}
      ref={ref}
    >
      <b>{name}</b>
    </Link>
  );
});
