const modifyChannel = (channelBase: number, modifier: number, bg = 255) =>
  Math.round(bg + (channelBase - bg) * modifier);

const toRgb = (channels: any) => `rgb(${channels.join(",")})`;

const modifyColor = (channels: any, modifier: any, bg: any) =>
  channels.map((channel: any) => modifyChannel(channel, modifier, bg));

export const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

export const rgbToHex = ([r, g, b]: any) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const hexToRgb = (hex: any) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
};

export const hexToRgba = (hex: any, alpha: any) => {
  const channels = hexToRgb(hex)?.concat(alpha);
  return `rgba(${channels?.join(",")})`;
};

export const darken = (color: any, amount: any) =>
  rgbToHex(modifyColor(hexToRgb(color), 1 - amount, 0));

export const lighten = (color: any, amount: any) =>
  rgbToHex(modifyColor(hexToRgb(color), 1 - amount, 255));
