import { action } from "typesafe-actions";
import * as constants from "./constants";
import { AuthState } from "./types";

export const signedIn = (user: AuthState["user"]) =>
  action(constants.SIGNED_IN, { user });
export const signedOut = () => action(constants.SIGNED_OUT);
export const setIdTokenResult = (idTokenResult: AuthState["idTokenResult"]) =>
  action(constants.SET_ID_TOKEN, { idTokenResult });
export const setActiveWorkspaceId = (
  activeWorkspaceId: AuthState["activeWorkspaceId"]
) => action(constants.SET_ACTIVE_WORKSPACE, { activeWorkspaceId });
export const setWorkspace = (workspace: AuthState["workspace"]) =>
  action(constants.SET_WORKSPACE, { workspace });
export const workspaceJoined = (workspace: AuthState["workspaceJoining"]) =>
  action(constants.WORKSPACE_JOINED, { workspace });
export const workspaceJoining = (workspace: AuthState["workspaceJoining"]) =>
  action(constants.WORKSPACE_JOINING, { workspace });
export const workspaceNoAccess = ({ workspace }: { workspace: string }) =>
  action(constants.WORKSPACE_NO_ACCESS, { workspace });
