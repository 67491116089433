/* eslint-disable no-use-before-define */

import React, { forwardRef, ForwardRefExoticComponent } from "react";
import Checkbox from "../atoms/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "../atoms/Autocomplete";
import TextField from "../atoms/TextField";
import Avatar from "../atoms/Avatar";
import Chip from "../atoms/Chip";
import User from "../../models/User";
import { AutocompleteGetTagProps } from "@material-ui/lab/Autocomplete/Autocomplete";
import { Controller, ControllerProps } from "react-hook-form";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const renderTags = (
  value: User[],
  getCustomizedTagProps: AutocompleteGetTagProps
) => {
  return value.map((user, index) => (
    <Chip
      color="primary"
      label={user?.displayName || user?.email}
      size="medium"
      avatar={<Avatar src={user?.picture ?? undefined} />}
      {...getCustomizedTagProps({ index })}
    />
  ));
};

interface IAutocompleteUsers {
  variant?: "filled" | "outlined" | "standard";
  fullWidth?: boolean;
  users: User[];
  onChange: (e: unknown, groups: User[]) => void;
  onOpen?: () => void;
  onClose?: () => void;
  placeholder?: string;
  label: string;
  value?: User[];
  defaultValue?: User[];
}

const AutocompleteUsers: ForwardRefExoticComponent<IAutocompleteUsers> = forwardRef(
  (props: IAutocompleteUsers, ref) => {
    const {
      variant,
      fullWidth,
      users = [],
      onClose = () => {},
      placeholder,
      label,
      ...rest
    } = props;
    return (
      <Autocomplete
        ref={ref}
        multiple
        renderTags={renderTags}
        options={users}
        disableCloseOnSelect
        onClose={e =>
          setTimeout(() => {
            // hack to prevent backdrop close parent modal when closing popper
            onClose && onClose();
          }, 500)
        }
        getOptionLabel={option =>
          (option.displayName !== "" && option.displayName) ||
          option.email ||
          option.id ||
          (typeof option === "string" && option)
        }
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              color="primary"
              checked={selected}
            />
            {(option.displayName !== "" && option.displayName) ||
              option.email ||
              option.id}
          </React.Fragment>
        )}
        renderInput={params => (
          <TextField
            {...params}
            variant={variant}
            fullWidth={fullWidth}
            label={label}
            placeholder={placeholder}
          />
        )}
        {...rest}
      />
    );
  }
);

interface AutoCompleteUsersControllerProps<T>
  extends Omit<IAutocompleteUsers, "onChange"> {
  ControllerProps: Omit<ControllerProps<T>, "render">;
}

export function withFormController<FieldValues>() {
  return (props: AutoCompleteUsersControllerProps<FieldValues>) => {
    const { ControllerProps, users, ...other } = props;
    const { name, control } = ControllerProps;
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { onChange, value = [], ...rest } = field;
          return (
            <AutocompleteUsers
              fullWidth
              value={value}
              users={users}
              variant="filled"
              onChange={(e: unknown, value: User[]) => {
                onChange(value);
              }}
              {...other}
              {...rest}
            />
          );
        }}
      />
    );
  };
}

export default AutocompleteUsers;
