import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { GET, GET_SUCCESS } from "../../../api/companyDashboard/constants";
import { setLoading, setNonBillableMonth } from "./actions";
import { getWorkingDays } from "../../../../../utils/WorkingDays";
import { SETTING_TYPES } from "../../../../../models/CompanySetting";

export const getStats = ({
  api: {
    companyStats: { stats }
  }
}) => stats;
export const getPeriod = ({
  ui: {
    dashboard: { period }
  }
}) => period;

export const getCompanySettings = ({
  api: {
    companySettings: { settings }
  }
}) => settings;

export const getMonthBefore = (year, month) => {
  return {
    year: month === 1 ? year - 1 : year,
    month: month === 1 ? 12 : month - 1
  };
};

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const getGoal = (year, month, settings) => {
  let goal = null;
  Object.keys(settings).forEach(key => {
    const setting = settings[key];
    if (setting.type === SETTING_TYPES.PERFORMANCE_GOAL) {
      if (setting.year === year && setting.month === month) {
        goal = setting;
      }
      if (!goal && setting.year === year && setting.month === "*") {
        goal = setting;
      }
    }
  });
  return goal;
};

export const handleGetSuccess = function*(args) {
  try {
    const settings = yield select(getCompanySettings);
    const { raw, monthBefore: monthBeforeData } = yield select(getStats);
    const { year, month } = yield select(getPeriod);

    const goal = getGoal(year, month, settings);

    const prevMonth = getMonthBefore(year, month);

    const nonBillableMonth = raw
      .filter(stats => stats.billable === false && stats.month === month)
      .reduce((prev, next) => prev + next.total, 0);

    const nonBillablePrevMonth = monthBeforeData
      .filter(
        stats =>
          stats.month === prevMonth.month && stats.year === prevMonth.year
      )
      .filter(stats => stats.billable === false)
      .reduce((prev, next) => prev + next.total, 0);

    let change = 0;
    if (nonBillablePrevMonth) {
      const diff = (nonBillableMonth || 0) - nonBillablePrevMonth;
      change = (diff / nonBillablePrevMonth) * 100;
    }

    yield put(setNonBillableMonth(nonBillableMonth, change, goal));

    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleGetSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export const handleGetSuccessSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleGetSuccess);
};

export default function*() {
  yield all([handleGetSuccessSaga(), handleGetSaga()]);
}
