import * as actions from './actions';
import {ActionType} from "typesafe-actions";
import UserGroup, {IUserGroup} from "../../../../../models/UserGroup";

export type TableState = {
  readonly loading: boolean,
  readonly orderBy: keyof IUserGroup,
  readonly orderDir: SortDirection,
  readonly rowsPerPage: number,
  readonly page: number,
  readonly selection: Array<UserGroup>,
  readonly userGroups: Array<UserGroup>,
};
export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export type TableAction = ActionType<typeof actions>
