import React, { Fragment, useEffect, useState } from "react";
import {
  ChartOptions,
  createDataset,
  createGradientFill,
  createGradientStroke,
  pointsOptions
} from "./lineChart/chart";

import { StyledLegends, StyledLineChart } from "./lineChart/Chart.styles";
import { ChartLegend } from "./ChartLegend";
import { asHoursAndMinutes } from "./DurationFormat";
import { useMediaQuery, withWidth } from "@material-ui/core";

const getTooltipTitleDefault = (tooltipItems, data) => {
  return "Week: " + tooltipItems[0].xLabel;
};

const getTooltipLabelDefault = (tooltipItem, data, offset) => {
  return tooltipItem.yLabel
    ? asHoursAndMinutes(-offset + parseFloat(tooltipItem.yLabel))
    : asHoursAndMinutes(0);
};

const getRange = datasets => {
  const allPoints = datasets.reduce(
    (prev, next) => [...prev, ...next.values],
    []
  );

  return Math.max.apply(this, allPoints) || 500;
};

const getOffset = datasets => {
  return (getRange(datasets) || 500) * 0.2;
};

const createFill = (id, dataset, range, offset, color) => {
  const max = Math.max.apply(this, dataset.values);

  return createGradientFill(id, dataset.color, range + offset, max);
};

const createStroke = (id, dataset) => {
  return createGradientStroke(id, dataset.color);
};

const hasValues = datasets =>
  datasets.some(dataset => dataset.values && dataset.values.length > 0);

const getHeight = width => {
  switch (width) {
    case "xxl":
      return 30;
    case "xl":
      return 50;
    case "lg":
      return 100;
    case "xs":
      return 200;
    default:
      return 150;
  }
};

const chart = withWidth()(
  ({
    width,
    datasets,
    labels,
    isHours,
    showPoints,
    offset: fixedOffset,
    getTooltipTitle = getTooltipTitleDefault,
    getTooltipLabel = getTooltipLabelDefault,
    id = "line-chart",
    ...rest
  }) => {
    const height = getHeight(width);

    const defaultColors = new Array(datasets.length).fill("#333");

    const [fills, setFills] = useState(defaultColors);
    const [strokes, setStrokes] = useState(defaultColors);

    const offset =
      fixedOffset !== undefined ? fixedOffset : getOffset(datasets); // Get offset from bottom

    const showXAxis = datasets.some(d => Math.min.apply(this, d.values) < 0);

    const setColors = () => {
      if (hasValues(datasets)) {
        const range = getRange(datasets);

        const fillList = datasets.map(d => createFill(id, d, range, offset));
        const strokeList = datasets.map(d => createStroke(id, d));

        setFills(fillList);
        setStrokes(strokeList);
      }
    };

    useEffect(() => {
      setColors();
    }, [datasets]);

    const chartDatasets = datasets.map((dataset, idx) =>
      createDataset(
        dataset.label,
        dataset.values.map(c => c + offset),
        dataset.color,
        {
          backgroundColor: fills[idx],
          borderColor: strokes[idx],
          ...pointsOptions(showPoints)
        }
      )
    );

    const data = {
      labels,
      datasets: chartDatasets
    };

    return (
      <Fragment>
        <StyledLineChart
          key={height}
          {...rest}
          height={height}
          aspectRatio={2}
          id={id}
          data={data}
          options={{
            ...ChartOptions(offset, getTooltipTitle, getTooltipLabel, {
              showXAxis
            }),
            responsive: true,
            maintainAspectRatio: true,
            resizeDelay: 100,
            onResize: setColors
          }}
        />
        <StyledLegends>
          {datasets.map((d, idx) => (
            <ChartLegend key={d.label || idx} color={d.color}>
              {d.label}
            </ChartLegend>
          ))}
        </StyledLegends>
      </Fragment>
    );
  }
);

export default chart;
