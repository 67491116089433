import { Actions, State } from "./types";
import { SET, UPDATE } from "./constants";
import { createReducer } from "typesafe-actions";

const initialState: State = {
  options: null
};

const reducer = createReducer<State, Actions>(initialState)
  .handleType(SET, (state, action) => action.payload)
  .handleType(UPDATE, (state, action) => ({
    ...state,
    ...action.payload,
    options: { ...state.options, ...action.payload.options }
  }));

export default reducer;
