import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  Grid,
  Select,
} from "@superprofit/core-react-components/atoms";
import React from "react";
import MenuItem from "@superprofit/core-react-components/atoms/MenuItem";

export default ({ onChange, language, currency }) => {

  const handleChangeLanguage = (e) => {
    const value = e.target.value;
    onChange('language', value);
  };
  const handleChangeCurrency = (e) => {
    const value = e.target.value;
    onChange('currency', value);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControl component="fieldset" margin="normal">
          <InputLabel
            variant="standard"
            shrink={false}
            id="application-lang-label"
          >
            Application language
          </InputLabel>
          <Select
            disabled
            variant="filled"
            labelId="application-lang-label"
            id="application-lang-select"
            value={language || 'en'}
            onChange={handleChangeLanguage}
          >
            <MenuItem key="en" value="en">
              English
            </MenuItem>
          </Select>
          <FormHelperText>Choose your preferred language</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset" margin="normal">
          <InputLabel variant="standard" shrink={false} id="currency-label">
            Currency
          </InputLabel>
          <Select
            variant="filled"
            labelId="currency-label"
            id="currency-select"
            value={currency || 'none'}
            onChange={handleChangeCurrency}
          >
            <MenuItem key="none" value="none">
              None
            </MenuItem>
            <MenuItem key="usd" value="usd">
              USD
            </MenuItem>
            <MenuItem key="nok" value="nok">
              NOK
            </MenuItem>
          </Select>
          <FormHelperText>
            The currency to use when displaying cost, profits, etc. This can be
            changed at any time, but it will not convert any numbers so
            previously saved data may be displayed in a wrong way.
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};
