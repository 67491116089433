import React, { forwardRef, ForwardRefExoticComponent } from "react";
import { StyledDialogTitle } from "./dialogTitle/DialogTitle.styles";
import { DialogTitleProps } from "@material-ui/core";

interface IDialogTitle extends DialogTitleProps {
  color?: "primary" | "default";
}

const DialogTitle: ForwardRefExoticComponent<IDialogTitle> = forwardRef(
  (props, ref) => <StyledDialogTitle {...props} ref={ref} />
);

export default DialogTitle;
