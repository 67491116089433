export default ({
  apiBase,
  priceId,
  subscriptionId,
  customerId,
  quantity,
  cancelAtPeriodEnd,
  coupon,
}) => {
  return fetch(`${apiBase}/updateSubscription`, {
    method: "post",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      customerId,
      subscriptionId: subscriptionId,
      priceId,
      quantity,
      cancelAtPeriodEnd,
      coupon,
    }),
  }).then(async (response) => {
    if (response.headers.get("Content-Type")?.includes("application/json")) {
      const json = await response.json();
      return { response, json };
    }
    return response;
  });
};
