import React, { useState, useEffect, useCallback } from "react";
import { Container } from "./withSidebar/withSidebar.styles";
import { Theme, withTheme } from "@material-ui/core";
import SidebarComp from "../organisms/Sidebar";

export function withSidebar(Component: React.FC<any>) {
  return function(Sidebar: React.FC<any>) {
    return withTheme(
      ({ theme, ...rest }: { theme: Theme; [key: string]: any }) => {
        const BREAKPOINT = theme.breakpoints.values.md;
        const [stickySidebar, setStickySidebar] = useState(
          window.innerWidth > BREAKPOINT
        );

        const handleResize = useCallback(() => {
          if (stickySidebar && window.innerWidth < BREAKPOINT) {
            setStickySidebar(false);
          } else if (!stickySidebar && window.innerWidth > BREAKPOINT) {
            setStickySidebar(true);
          }
        }, [BREAKPOINT, stickySidebar]);

        useEffect(() => {
          window.addEventListener("resize", handleResize);
          return () => {
            window.removeEventListener("resize", handleResize);
          };
        }, [handleResize]);

        return (
          <Container key="sidebar-container">
            <Sidebar key="sidebar" sticky={stickySidebar} />
            <div>
              <Component {...rest} />
            </div>
          </Container>
        );
      }
    ) as React.FC<any>;
  };
}
