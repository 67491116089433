import React, {ComponentPropsWithoutRef, forwardRef} from "react";
import { StyledUserDisplay } from "./userDisplay/UserDisplay.styles";
//https://www.newline.co/@kchan/annotating-react-styled-components-with-typescript--e8076d1d
interface IUserDisplay extends ComponentPropsWithoutRef<"div">{
  name?: string | null,
  email?: string | null,
  hideEmail?: boolean
}

export default forwardRef<HTMLDivElement, IUserDisplay>(({ name, email, hideEmail, ...rest }: IUserDisplay, ref) => (
  <StyledUserDisplay {...rest} ref={ref}>
    {name && name.length ? (
      <div className="display-name">{name || email}</div>
    ) : (
      <div className="display-name">{email}</div>
    )}
    {name && name.length && !hideEmail && email && (
      <div className="email">{email}</div>
    )}
  </StyledUserDisplay>
));
