import styled from "styled-components";

export const StyledLogged = styled.div`
  padding: ${props => props.theme.spacing(2)}px;
`;

export const LoggedItem = styled.span`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing(2)}px;

  > * {
    margin-right: ${props => props.theme.spacing(1)}px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
