import React from "react";
import { StyledPage } from "./page/Page.styles";
import PageHeader from "./PageHeader";

export default ({ context, title, children, ...rest }) => (
  <StyledPage {...rest}>
    <PageHeader context={context} title={title} />
    {children}
  </StyledPage>
);
