import { View } from "@react-pdf/renderer";
import React from "react";
import { PageNumber, Line, Container } from "./pageFooter/pageFooter.styles";
import {Timet} from './pdfRenderer.styles';

export default ({ children }) => (
  <Container>
    <Line />
    <PageNumber>{children}</PageNumber>
    <Timet>timet.io</Timet>
  </Container>
);
