import styled from "styled-components";
import Card from "@superprofit/core-react-components/atoms/Card";
import Stepper from "@superprofit/core-react-components/atoms/Stepper";

export const LeadCard = styled(Card)`
  margin-bottom: ${props => props.theme.spacing(3)}px;
`;

export const LeadTags = styled.div`
  margin-top: ${props => props.theme.spacing(1)}px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  > * {
    margin-right: ${props => props.theme.spacing(1)}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const LeadStepper = styled(Stepper)`
  padding: 24px 0 24px 0;
`;

export const LeaveComment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  > * {
    margin-right: ${props => props.theme.spacing(0.5)}px;
    margin-left: ${props => props.theme.spacing(0.5)}px;

    &.lead-comment {
      flex: 1;
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
