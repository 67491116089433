/* eslint-disable no-use-before-define */

import React, { forwardRef, ForwardRefExoticComponent } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "../atoms/Autocomplete";
import TextField from "../atoms/TextField";
import Avatar from "../atoms/Avatar";
import Chip from "../atoms/Chip";
import UserGroup from "../../models/UserGroup";
import { Controller, ControllerProps } from "react-hook-form";
import { AutocompleteGetTagProps } from "@material-ui/lab/Autocomplete/Autocomplete";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const renderTags = (
  value: UserGroup[],
  getCustomizedTagProps: AutocompleteGetTagProps
) => {
  return value.map((group, index) => (
    <Chip
      color="primary"
      label={group?.name}
      size="medium"
      avatar={<Avatar>{group?.name?.[0]}</Avatar>}
      {...getCustomizedTagProps({ index })}
    />
  ));
};

interface IAutocompleteUserGroups {
  variant: string;
  fullWidth?: boolean;
  groups: UserGroup[];
  onChange: (e: unknown, groups: UserGroup[]) => void;
  onOpen?: () => void;
  onClose?: () => void;
  placeholder?: string;
  label: string;
  defaultValue?: UserGroup[];
  value?: UserGroup[];
}

const AutocompleteUserGroups: ForwardRefExoticComponent<IAutocompleteUserGroups> = forwardRef(
  (
    {
      variant,
      fullWidth,
      groups = [],
      onClose = () => {},
      placeholder,
      label,
      ...rest
    },
    ref
  ) => {
    return (
      <Autocomplete
        ref={ref}
        multiple
        renderTags={renderTags}
        options={groups}
        disableCloseOnSelect
        onClose={() =>
          setTimeout(() => {
            // hack to prevent backdrop close parent modal when closing popper
            onClose && onClose();
          }, 500)
        }
        getOptionLabel={option =>
          (option?.name !== "" && option?.name) || option?.id
        }
        renderOption={(
          option: UserGroup,
          { selected }: { selected: boolean }
        ) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              color="primary"
              checked={selected}
            />
            {(option.name !== "" && option.name) || option.id}
          </React.Fragment>
        )}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant={variant}
            fullWidth={fullWidth}
            label={label}
            placeholder={placeholder}
          />
        )}
        {...rest}
      />
    );
  }
);

interface AutoCompleteUserGroupsControllerProps<T>
  extends Omit<IAutocompleteUserGroups, "onChange"> {
  ControllerProps: Omit<ControllerProps<T>, "render">;
}

export function withFormController<FieldValues>() {
  return (props: AutoCompleteUserGroupsControllerProps<FieldValues>) => {
    const { ControllerProps, groups, ...other } = props;
    const { name, control } = ControllerProps;
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { onChange, value = [], ...rest } = field;
          return (
            <AutocompleteUserGroups
              fullWidth
              value={value}
              groups={groups}
              onChange={(e: unknown, value: UserGroup[]) => {
                onChange(value);
              }}
              {...other}
              {...rest}
            />
          );
        }}
      />
    );
  };
}

export default AutocompleteUserGroups;
