import { firebase } from "../firebase";
type CollectionReference = firebase.firestore.CollectionReference;
type FieldPath = firebase.firestore.FieldPath;
type WhereFilterOp = firebase.firestore.WhereFilterOp;

interface ICondition {
  fieldPath: string | FieldPath;
  opStr: WhereFilterOp;
  value: any;
}

export class Condition implements ICondition {
  fieldPath: string | FieldPath;
  opStr: WhereFilterOp;
  value: any;
  constructor(fieldPath: string | FieldPath, opStr: WhereFilterOp, value: any) {
    this.fieldPath = fieldPath;
    this.opStr = opStr;
    this.value = value;
  }
}

interface IQuery {
  _conditions: ICondition[];
}

export class Query {
  _conditions: ICondition[];
  constructor(props?: Partial<IQuery>) {
    this._conditions = props?._conditions || [];
  }

  clone() {
    return new Query(this);
  }

  addCondition(condition: ICondition) {
    this._conditions = [...this._conditions, condition];
  }
}

export class QueryBuilder {
  static build(
    ref: CollectionReference,
    query = new Query()
  ): CollectionReference {
    for (const condition of query._conditions) {
      ref = ref.where(
        condition.fieldPath,
        condition.opStr,
        condition.value
      ) as CollectionReference;
    }
    return ref;
  }
}
