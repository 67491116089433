import styled, { css } from "styled-components";
import { DialogTitle } from "@material-ui/core";
import theme from "styled-theming";

const colors = theme.variants("mode", "color", {
  default: {
    light: css`
      background: ${props => props.theme.palette.background.paper};
      color: ${props => props.theme.palette.text.primary};
    `,
    dark: css``
  },
  primary: {
    light: css`
      background: ${props => props.theme.palette.primary.main};
      color: white;
    `,
    dark: css``
  }
});

export const StyledDialogTitle = styled(DialogTitle)`
  ${colors};
`;
