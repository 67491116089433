
export default class XstatsUsersInWorkspace {
  static collectionName = "xstats_users_in_workspace";
  static docName = "xstats_users_in_workspace";

  static converter = {
    toFirestore(customer) {
      return customer.data();
    },
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      return new XstatsUsersInWorkspace({ ...data, id: snapshot.id });
    }
  };

  constructor({ updatedAt, numberOfUsers, numberOfActiveUsers, numberOfDeactivatedUsers, numberOfPendingInvites }) {
    this.updatedAt = updatedAt;
    this.numberOfUsers = numberOfUsers;
    this.numberOfActiveUsers = numberOfActiveUsers;
    this.numberOfPendingInvites = numberOfPendingInvites;
    this.numberOfDeactivatedUsers = numberOfDeactivatedUsers;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  data() {
    return {
      numberOfUsers: this.numberOfUsers,
      numberOfActiveUsers: this.numberOfActiveUsers,
      numberOfPendingInvites: this.numberOfPendingInvites,
      numberOfDeactivatedUsers: this.numberOfDeactivatedUsers,
      updatedAt: this.updatedAt
    };
  }

  static listen = (db, workspace, callback) => {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(XstatsUsersInWorkspace.collectionName)
      .doc(XstatsUsersInWorkspace.docName)
      .withConverter(XstatsUsersInWorkspace.converter)
      .onSnapshot((doc) => {
        callback(doc.data());
      });
  };

  static get = async (db, workspace) => {
    const snapshot = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(XstatsUsersInWorkspace.collectionName)
      .doc(XstatsUsersInWorkspace.docName)
      .withConverter(XstatsUsersInWorkspace.converter)
      .get();
    return snapshot.data();
  };
}
