import styled from "styled-components";

export const Code = styled.code`
  font-size: 14px;
  font-family: "Fira Code", monospace;
  background-color: #f4f4f4;
  padding: 1rem;
  border-radius: 4px;
  margin: 0 4px;
  overflow-x: auto;
`;
