import styled from "styled-components";
import Card from "@superprofit/core-react-components/atoms/Card";
import Grid from "@superprofit/core-react-components/atoms/Grid";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";

export const StyledCard = styled(Card)`
  //background: ${props => props.theme.palette.primary.main};
  //> div {
  //  padding: 0;
  //
  //  margin: 16px;
  //margin-bottom: 24px;
  //}

`;

export const MainGrid = styled(Grid)`
  // background: ${props => props.theme.palette.background.paper};
`;

export const HeaderGrid = styled(Grid).attrs(() => ({ item: true, xs: 12 }))`
  background: ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.background.paper};
`;

export const StyledHeader = styled(CardHeader)`
  background: ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.background.paper};
`;

export const ContentGrid = styled(Grid).attrs(() => ({ item: true, xs: 12 }))`
  //border-radius: 0.5rem;
`;

export const Subheader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
