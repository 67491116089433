import React, { Fragment, useRef, useState } from "react";
import ConfirmationDialog from "../../../components/molecules/ConfirmationDialog";
import useDialog from "./hooks/useDialog";
import { useTranslation } from "react-i18next";
import FormDialog from "../../../components/molecules/FormDialog";
import useProject from "../../../hooks/useProject";
import useProjectCreateMutation from "../../../hooks/useProjectCreateMutation";
import useProjectUpdateMutation from "../../../hooks/useProjectUpdateMutation";

import Form, { IFormRef } from "./projectDialog/Form";
import useCustomers from "../../../hooks/useCustomers";
import useCustomersMap from "../../../hooks/useCustomersMap";
import useUsers from "../../../hooks/useUsers";
import useUserGroups from "../../../hooks/useUserGroups";

export default ({ ...rest }) => {
  const { t } = useTranslation();

  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);
  const { open, id, close } = useDialog();
  const { data: project, isSuccess } = useProject(id);
  const { isLoading: isCustomersLoading } = useCustomers();
  const { isLoading: isUsersLoading } = useUsers();
  const { isLoading: isUserGroupsLoading } = useUserGroups();
  const {
    mutate: create,
    isLoading: isSavingCreate
  } = useProjectCreateMutation();
  const {
    mutate: update,
    isLoading: isSavingUpdate
  } = useProjectUpdateMutation(project?.id);
  const isSaving = isSavingCreate || isSavingUpdate;
  const handleConfirmClose = () => {
    close();
  };
  const handleCancelClose = () => {
    setShowUnsavedConfirmation(false);
  };
  const formRef = useRef<IFormRef>(null);
  const handleClose = () => {
    const isDirty = formRef.current?.isDirty && formRef.current.isDirty();
    if (isDirty) {
      setShowUnsavedConfirmation(true);
    } else {
      close();
    }
  };
  const isLoading =
    isCustomersLoading ||
    isUsersLoading ||
    isUserGroupsLoading ||
    (id && !isSuccess);
  return (
    <Fragment>
      <ConfirmationDialog
        key="unsaved"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showUnsavedConfirmation}
      />

      <FormDialog
        {...rest}
        key="form"
        SubmitButtonProps={{ type: "submit", form: "project_edit_form" }}
        saveActionTitle={project?.id ? t("common.save") : t("common.create")}
        open={open}
        onClose={handleClose}
        title={
          project?.id
            ? t("pages.projects.editProject")
            : t("pages.projects.addProject")
        }
        inProgress={isSaving}
      >
        {!isLoading ? (
          <Form
            id="project_edit_form"
            project={project}
            ref={formRef}
            create={create}
            update={update}
          />
        ) : null}
      </FormDialog>
    </Fragment>
  );
};
