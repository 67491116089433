import { useQueries } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import { endOfISOWeek, startOfISOWeek } from "date-fns";
import useProjects from "./useProjects";
import Project from "../models/Project";
import {
  getKey as getProjectEntriesKey,
  getFactory as getProjectEntriesFactory
} from "./useProjectEntries";
import { is } from "date-fns/locale";

export type Options = {
  id: string;
  year?: number;
  week?: number;
  fromDate?: Date;
  toDate?: Date;
};

const attachFirestoreListener = (workspace: string, id: string) => {
  // TODO: Implement this
  /*
    // refresh react-query cache when firestore changes
    const unsubscribe = .onSnapshot(
        (doc: any) => {
        console.log("Current data: ", doc.data());
        },
        (err: any) => {
        console.log("Encountered error: ", err);
        }
    );
    return unsubscribe;*/
};
const useCustomerEntries = (options: Options) => {
  const workspace = useWorkspace();
  let { id, year, week, fromDate, toDate } = options;
  const { data: allProjects, isLoading } = useProjects();
  const projects = allProjects?.filter(
    (project: Project) => project.customer === id && project.id
  );

  if (year && week) {
    fromDate = startOfISOWeek(new Date(year, week - 1, 1));
    toDate = endOfISOWeek(fromDate);
  }

  if (fromDate && toDate) {
    const from = fromDate;
    const to = toDate;
    const queries =
      projects
        ?.map((project: Project) =>
          project.id
            ? {
                queryKey: getProjectEntriesKey(workspace, project.id, from, to),
                queryFn: getProjectEntriesFactory(workspace, from, to, {
                  project: project.id
                }),
                staleTime: Infinity
              }
            : null
        )
        .filter(Boolean) || [];
    const results = useQueries({
      queries
    });
    const entries = results.map((result: any) => result.data).flat();
    const isLoading = results.some((result: any) => result.isLoading);
    const error = results.some((result: any) => result.error);
    const data = isLoading || error ? [] : entries;
    return { data, isLoading, error };
  }
  throw new Error(
    "useCustomerEntries: Either fromDate and toDate or year and week must be provided."
  );
};

export default useCustomerEntries;
