import React, {
  useEffect,
} from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Summary from './stripe/newstuff/Summary';

// Learning
// To best leverage Stripe’s advanced fraud functionality,
// include this script on every page, not just the checkout page.
// This allows Stripe to detect anomalous behavior that may be indicative
// of fraud as customers browse your website.
// Note: This is why we are adding it to a Layout component.

// let stripePromise = loadStripe("pk_test_GYfuqAZ9t1Z931l6mkWrV7WK");
let stripePromise;

const Stripe = ({
  customerId,
  filterProductsFunc = (f) => f,
  baseUrl,
  firebaseIdToken,
}) => {

  return <Summary firebaseIdToken={firebaseIdToken} baseUrl={baseUrl} customerId={customerId} filterProductsFunc={filterProductsFunc}/>
};

export default (props) => {
  useEffect(() => {
    stripePromise = loadStripe(props.stripeKey);
  }, [props.stripeKey]);
  if (!stripePromise) return null;
  return (
    <Elements stripe={stripePromise}>
      <Stripe {...props} />
    </Elements>
  );
};
