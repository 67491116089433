import { IFormInput } from "./types";

export const isSalaryEnabledFromSalaryType = (
  salaryType: IFormInput["salaryType"]
) => salaryType !== "none";

export const defaultValues: IFormInput = {
  salaryType: "none",
  name: "",
  managerEmail: "",
  managerName: "",
  twntyFourSevenId: "",
  externalReference: "",
  billable: false,
  salaryEnabled: false,
  globalSalaryPercentageEnabled: false,
  nettlonnSalaryArt: null,
  nettlonnDimensionOne: null,
  tags: [],
  color: "#4422ff",
  customer: null,
  team: [],
  userGroups: []
};
