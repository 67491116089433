import { db, firebase } from "../firebase";

import { QueryBuilder } from "@superprofit/core-firestore-models";
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;
type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

interface IXStatsUserProjectHoursByWeek {
  id: string;
  month: number;
  project: string;
  total: number;
  week: number;
  year: number;
  user: string;
}

export default class XstatsUserProjectHoursByWeek
  implements IXStatsUserProjectHoursByWeek {
  id: string;
  month: number;
  project: string;
  total: number;
  week: number;
  user: string;
  year: number;
  static collectionName = "xstats_user_project_hours_by_week";

  static defaultListLimit = 25;

  static converter = {
    toFirestore(stats: XstatsUserProjectHoursByWeek) {
      return stats.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IXStatsUserProjectHoursByWeek;
      return new XstatsUserProjectHoursByWeek({ ...data, id: snapshot.id });
    }
  };
  constructor({
    id,
    month,
    project,
    total,
    week,
    year,
    user
  }: IXStatsUserProjectHoursByWeek) {
    this.id = id;
    this.month = month;
    this.project = project;
    this.total = total;
    this.week = week;
    this.user = user;
    this.year = year;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  isValid = () => {
    return true;
  };

  data() {
    return {
      month: this.month,
      project: this.project,
      total: this.total,
      week: this.week,
      year: this.year,
      user: this.user
    };
  }

  static listMonth = async (workspace: string, month: number, year: number) => {
    const snapshot = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(XstatsUserProjectHoursByWeek.collectionName)
      .withConverter(XstatsUserProjectHoursByWeek.converter)
      .where("month", "==", month)
      .where("year", "==", year)
      .get();

    return snapshot.docs.map(doc => doc.data());
  };
  static listWeek = async (workspace: string, week: number, year: number) => {
    const snapshot = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(XstatsUserProjectHoursByWeek.collectionName)
      .withConverter(XstatsUserProjectHoursByWeek.converter)
      .where("week", "==", week)
      .where("year", "==", year)
      .get();

    return snapshot.docs.map(doc => doc.data());
  };

  static list = async (
    workspace: string,
    query: any,
    limit = XstatsUserProjectHoursByWeek.defaultListLimit // Hmm, blir ikke dette problemer?
  ) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(XstatsUserProjectHoursByWeek.collectionName),
      query
    )
      .withConverter(XstatsUserProjectHoursByWeek.converter)
      .limit(limit)
      .get();

    return snapshot.docs.map((doc: QueryDocumentSnapshot) => doc.data());
  };
}
