import { all, put, take, select, takeLatest } from "redux-saga/effects";
import {
  watchGetUsers,
  watchDeleteUser
} from "@superprofit/core-redux-saga-modules/Users/actions";
import {
  SAVE_SUCCESS,
  GET_SUCCESS as GET_USERS_SUCCESS,
  DELETE_SUCCESS,
  DELETE_FAILURE
} from "@superprofit/core-redux-saga-modules/Users/constants";
import {
  setLoading,
  setUsersTable,
  watchRefreshUsersTable,
  watchGetUsers as uiWatchGetUsers
} from "./actions";
import {
  WATCH_DELETE_USERS,
  WATCH_GET_USERS,
  WATCH_REFRESH_USERS_TABLE
} from "./constants";
import { deselectAllUsers } from "../selection/actions";
import userAccessConstants from "@superprofit/core-redux-saga-modules/UserAccess/constants";

export const getUsersState = state => {
  return {
    users: state.api.users.list
  };
};

export const getUserAccessState = state => state.api.userAccess;

export const handleRefreshTable = function*(args) {
  try {
    const { users } = yield select(getUsersState);
    const { list } = yield select(getUserAccessState);

    const tableList = users.map(u =>
      u.setData({ userAccess: list.find(ua => ua.id === u.id) })
    );

    yield put(setUsersTable(tableList));
    yield put(deselectAllUsers());
    yield put(setLoading(false));
  } catch (e) {
    console.error(e);
  }
};

export const handleGetUsers = function*(args) {
  try {
    yield put(setLoading(true));
    yield put(watchGetUsers());

    const [users] = yield all([take(GET_USERS_SUCCESS)]);

    // Dont care about the data, lets just get the values from store.

    yield put(watchRefreshUsersTable());
  } catch (e) {}
};

export const handleDispatchRefresh = function*(args) {
  try {
    yield put(watchRefreshUsersTable());
  } catch (e) {}
};

export const handleDeleteUsers = function*(args) {
  const usersToDelete = args.payload.users;
  try {
    yield put(setLoading(true));
    for (let user of usersToDelete) {
      yield put(watchDeleteUser(user));
      const res = yield take([DELETE_SUCCESS, DELETE_FAILURE]);
      if (res.type === DELETE_FAILURE) {
        console.error("ERROR DELETING USER", res);
      }
    }
    yield put(uiWatchGetUsers());
  } catch (e) {}
};

export const handleDispatchRefreshSaga = function*() {
  yield takeLatest([SAVE_SUCCESS], handleDispatchRefresh);
};

export const getUsersSaga = function*() {
  yield takeLatest(WATCH_GET_USERS, handleGetUsers);
};

export const handleRefreshTableSaga = function*() {
  yield takeLatest(
    [WATCH_REFRESH_USERS_TABLE, userAccessConstants.LISTEN_ALL_SUCCESS],
    handleRefreshTable
  );
};

export const handleDeleteUsersSaga = function*() {
  yield takeLatest(WATCH_DELETE_USERS, handleDeleteUsers);
};

export default function*() {
  yield all([
    handleDispatchRefreshSaga(),
    handleDeleteUsersSaga(),
    getUsersSaga(),
    handleRefreshTableSaga()
  ]);
}
