import React from 'react';
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import CardContent from "@superprofit/core-react-components/atoms/CardContent";
import Grid from '@material-ui/core/Grid';
import { LeadCard, LeadTags, LeaveComment } from './LeadList.styles';
import Divider from "@superprofit/core-react-components/atoms/Divider";
import { Skeleton } from "@material-ui/lab";

const LoaderItem = () => {

  return (
    <LeadCard>
      <CardHeader
        title={<Skeleton variant="text" width={200} />}
        subheader={<Skeleton variant="text" width={180} />}
        avatar={<Skeleton variant="rect" width={40} height={40} />} />
      <CardContent>
        <Grid container spacing={2} component="div" alignItems="flex-end">
          <Grid component="div" item xs={12}>
            <Skeleton variant="text" width="95%" />
            <Skeleton variant="text" width="95%" />
            <Skeleton variant="text" width="95%" />

            <LeadTags>
              <Skeleton variant="rect" width={90} height={32} />
              <Skeleton variant="rect" width={80} height={32} />
            </LeadTags>

          </Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <Grid container spacing={2} component="div" alignItems="flex-end">

          <Grid component="div" item xs={12}>
            <LeaveComment>
              <Skeleton variant="circle" height={40} width={40} />
              <Skeleton variant="rect" height={40} className="lead-comment" />
            </LeaveComment>
          </Grid>

        </Grid>
      </CardContent>
    </LeadCard>
  )
}


export default ({ count = 3}) => (
  new Array(count).fill(3).map((k, i) => (
    <LoaderItem key={i} />
  ))
)
