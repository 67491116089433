import { firebase, db } from "../firebase";
import { Query, QueryBuilder } from "./QueryBuilder";
import User, { IUser } from "./User";
type SnapshotOptions = firebase.firestore.SnapshotOptions;
type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;

export default class TimetUser extends User {
  static collectionName = User.collectionName;
  static converter = {
    toFirestore(user: TimetUser) {
      return user.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IUser;
      return new TimetUser({ ...data, id: snapshot.id });
    }
  };

  constructor(props: IUser) {
    super(props);
  }

  data() {
    return {
      ...super.data(),
      capacity: this.capacity || null,
      planned: this.planned || null,
      projectSortOrder: this.projectSortOrder || {}
    };
  }

  getCapacity() {
    return this.capacity;
  }

  getOnboarding(type: string) {
    return (this?.metadata?.onboarding || {})[type];
  }

  async updateOnboarding(
    workspace: string,
    type: string,
    data: any
  ): Promise<TimetUser> {
    const metadata = {
      ...this?.metadata,
      onboarding: {
        ...this?.metadata?.onboarding,
        [type]: data
      }
    };

    return User.update(workspace, this.id, this, { metadata });
  }

  static get = async (workspace: string, id: string) => {
    const doc = await db
      .collection("workspaces")
      .doc(workspace)
      .collection("users")
      .doc(id)
      .withConverter(TimetUser.converter)
      .get();
    return doc.data();
  };

  static list = async (
    customDB: typeof db | null,
    workspace: string,
    query?: Query
  ) => {
    const snapshot = await QueryBuilder.build(
      (customDB || db)
        .collection("workspaces")
        .doc(workspace)
        .collection(User.collectionName),
      query
    )
      .withConverter(TimetUser.converter)
      .get();

    return snapshot.docs.map((doc: QueryDocumentSnapshot) => doc.data());
  };
}
