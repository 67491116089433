/* eslint-disable no-use-before-define */

import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "../atoms/Autocomplete";
import TextField from "../atoms/TextField";
import Avatar from "../atoms/Avatar";
import Chip from "../atoms/Chip";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const renderTags = (value, getCustomizedTagProps) => {
  return value.map((user, index) => (
    <Chip
      color="primary"
      label={user?.displayName}
      size="medium"
      avatar={<Avatar src={user?.picture} />}
      {...getCustomizedTagProps({ index })}
    />
  ));
};

export default ({ variant, fullWidth, users, onClose, placeholder, label, ...rest }) => {
  return (
    <Autocomplete
      multiple
      renderTags={renderTags}
      options={users}
      disableCloseOnSelect
      onClose={e =>
        setTimeout(() => {
          // hack to prevent backdrop close parent modal when closing popper
          onClose && onClose();
        }, 500)
      }
      getOptionLabel={option => (option.displayName !== '' && option.displayName) || option.email || option.id}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            color="primary"
            checked={selected}
          />
          {(option.displayName !== '' && option.displayName) || option.email || option.id}
        </React.Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant={variant}
          fullWidth={fullWidth}
          label={label}
          placeholder={placeholder}
        />
      )}
      {...rest}
    />
  );
};
