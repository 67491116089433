import { SET_LOADING, SET_CUSTOMER } from "./constants";

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const setCustomer = customer => ({
  type: SET_CUSTOMER,
  payload: {
    customer
  }
});
