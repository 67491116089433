import React, { FormEvent, Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteUsers from "@superprofit/core-react-components/molecules/AutocompleteUsers";
import FormDialog from "@superprofit/core-react-components/molecules/FormDialog";

import ConfirmationDialog from "../../../components/molecules/ConfirmationDialog";

import { RootState } from "../../../index";
import UserGroup from "../../../models/UserGroup";
import {
  hideDialog,
  setIsClosable,
  setShowConfirmation,
  updateDialogData,
  watchGetUsers,
  watchSave
} from "../../../redux/modules/ui/userGroups/dialog/actions";
import {
  DialogState,
  User,
  Users
} from "../../../redux/modules/ui/userGroups/dialog/types";
import { TextField, Grid } from "@superprofit/core-react-components/atoms";
import { useTranslation } from "react-i18next";

export default ({ ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    open,
    userGroup,
    saving,
    showConfirmation,
    hasUnsaved
  }: DialogState = useSelector(
    (state: RootState) => state.ui.userGroups.dialog
  );

  const userList = useSelector(
    (state: RootState) => state.api?.users?.list || []
  );

  const usersMap = useMemo(() => {
    return (userList as Users).reduce((m, usr) => ({ ...m, [usr.id]: usr }), {
      all: { id: "all", displayName: "All users" }
    } as Record<User["id"], Partial<User>>);
  }, [userList]);

  const { id, name, users } = userGroup as UserGroup;

  useEffect(() => {
    dispatch(watchGetUsers());
  }, [watchGetUsers, dispatch]);

  const handleConfirmClose = () => {
    dispatch(hideDialog());
  };

  const handleOnClose = () => {
    if (saving) return;
    if (hasUnsaved) {
      dispatch(setShowConfirmation(true));
    } else {
      handleConfirmClose();
    }
  };
  const handleCancelClose = () => {
    dispatch(setShowConfirmation(false));
  };

  const handleIsClosable = () => {
    dispatch(setIsClosable(true));
  };
  const handleNotClosable = () => {
    dispatch(setIsClosable(false));
  };

  const handleOnSave = () => {
    dispatch(watchSave());
  };

  const handleEnter = (e: FormEvent<HTMLFormElement>) => {
    // e.preventDefault();
    // handleOnSave();
  };

  const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateDialogData({ name: e.currentTarget.value }));
  };

  const handleOnChangeUsers = (e: Event, usersUpdated: Users) => {
    dispatch(updateDialogData({ users: usersUpdated.map(u => u.id) }));
  };
  return (
    <Fragment>
      <ConfirmationDialog
        key="unsaved"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showConfirmation}
        title="Unsaved changes"
        text="Do you want to close?"
      />

      <FormDialog
        {...rest}
        key="form"
        saveActionTitle={id ? t("common.update") : t("common.create")}
        onSave={handleOnSave}
        open={open}
        onClose={handleOnClose}
        title={
          id
            ? t("pages.userGroups.updateUserGroup")
            : t("pages.userGroups.createUserGroup")
        }
        inProgress={saving}
      >
        <form onSubmit={handleEnter}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                defaultValue={name}
                fullWidth
                variant="filled"
                label={t("common.name")}
                placeholder="The group"
                onBlur={handleOnChangeName}
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteUsers
                onClose={handleIsClosable}
                value={users.map(u => usersMap[u] || { id: u, displayName: u })}
                users={Object.values(usersMap)}
                onChange={handleOnChangeUsers}
                onOpen={handleNotClosable}
                variant="filled"
                fullWidth
                label={t("common.users")}
                placeholder=""
              />
            </Grid>
          </Grid>
        </form>
      </FormDialog>
    </Fragment>
  );
};
