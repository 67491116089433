import { STAGE_UPDATES, SET_SAVING } from "./constants";

const initialState = {
  updatesByProjectId: {},
  stagedPlansByProjectId: {},
  saving: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STAGE_UPDATES:
      return {
        ...state,
        updatesByProjectId: {
          ...state.updatesByProjectId,
          [payload.project.id]: {
            ...(state.updatesByProjectId[payload.project.id] || {}),
            ...payload.updates
          }
        },
        stagedPlansByProjectId: {
          ...state.stagedPlansByProjectId,
          [payload.project.id]: {
            planned: {
              ...payload.project?.planned,
              ...(state.stagedPlansByProjectId[payload.project.id]?.planned ||
                {}),
              ...payload.updates
            }
          }
        }
      };

    case SET_SAVING:
      return {
        ...state,
        saving: payload.isSaving
      };
    default:
      return state;
  }
};
