import projects from "./projects/sagas";
import projectStats from "./projectStats/sagas";
import projectOverview from "./projectOverview/sagas";
import timesheetEntries from "./timesheet/entries/sagas";
import timesheetComments from "./timesheet/comments/sagas";
import customers from "./customers/sagas";
import customerStats from "./customerStats/sagas";
import customerOverview from "./customerOverview/sagas";
import leads from "./leads/sagas";
import invoices from "./invoices/sagas";
import companyStats from "./companyStats/sagas";
import userStats from "./userStats/sagas";
import companyDashboard from "./companyDashboard/sagas";
import companySettings from "./companySettings/sagas";
import userSettings from "./userSettings/sagas";
import xstats from "./xstats/sagas";
import userProfile from "./userProfile/sagas";
import userGroups from "./userGroups/sagas";
import currentUser from "./currentUser/sagas";
import integrations from "./integrations/sagas";
import { sagas as auth } from "@superprofit/core-redux-saga-modules/Auth";
import { sagas as users } from "@superprofit/core-redux-saga-modules/Users";
import { handleNoWorkspaceAccessSaga } from "./workspaces";
import { sagas as workspaces } from "@superprofit/core-redux-saga-modules/Workspaces";
import { sagas as userRole } from "@superprofit/core-redux-saga-modules/UserRole";
import { sagas as userAccess } from "@superprofit/core-redux-saga-modules/UserAccess";
import { sagas as stripeAccountDetails } from "./stripeAccountDetails";
import { sagas as applicationAccess } from "./applicationAccess";

// Reports
import report from "./report/sagas";

export default [
  applicationAccess(),
  userProfile(),
  userAccess(),
  auth(),
  currentUser(),
  users(),
  userRole(),
  userGroups(),
  userSettings(),
  handleNoWorkspaceAccessSaga(),
  projects(),
  projectStats(),
  projectOverview(),
  customers(),
  customerStats(),
  customerOverview(),
  leads(),
  invoices(),
  timesheetEntries(),
  timesheetComments(),
  companyStats(),
  companyDashboard(),
  stripeAccountDetails(),
  userStats(),
  companySettings(),
  userSettings(),
  xstats(),
  integrations(),
  workspaces(),
  report()
];
