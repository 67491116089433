import { all, put, takeLatest } from "redux-saga/effects";
import {
  DELETE_MULTIPLE_SUCCESS,
  DELETE_SUCCESS
} from "../../../api/invoices/constants";

import { setSelectedInvoices } from "./actions";

export const clearSelection = function*(args) {
  try {
    yield put(setSelectedInvoices([]));
  } catch (e) {
    console.warn(e);
  }
};

export const handleClearSelectionSaga = function*() {
  yield takeLatest([DELETE_MULTIPLE_SUCCESS, DELETE_SUCCESS], clearSelection);
};

export default function*() {
  yield all([handleClearSelectionSaga()]);
}
