import { all, put, takeLatest } from "@redux-saga/core/effects";
import { SAVE_SUCCESS } from "../../../api/companySettings/constants";

import { hideSettingsDialog } from "./actions";

export const handleSaveSuccess = function*(args) {
  const { savedSettings } = args.payload;
  try {
    yield put(hideSettingsDialog(savedSettings));
  } catch (e) {
    console.warn(e);
  }
};

export const handleSaveSettingsSuccessSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleSaveSuccess);
};

export default function*() {
  yield all([handleSaveSettingsSuccessSaga()]);
}
