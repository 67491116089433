import Entry from "../models/Entry";
import { useMemo } from "react";
import { isWithinInterval, setDayOfYear } from "date-fns";

export enum StatsType {
  XStatsUserProjectHoursByMonth = "XStatsUserProjectHoursByMonth",
  XStatsUserProjectHoursByWeek = "XStatsUserProjectHoursByWeek"
}

// Returns the format of XStats

export type XStatsUserProjectHoursByMonth = {
  user: string;
  project: string;
  month: number;
  year: number;
  total: number;
};

export type XStatsUserProjectHoursByWeek = {
  user: string;
  project: string;
  week: number;
  year: number;
  month: number;
  total: number;
};

type StatsTypeMap = {
  [StatsType.XStatsUserProjectHoursByMonth]: XStatsUserProjectHoursByMonth[];
  [StatsType.XStatsUserProjectHoursByWeek]: XStatsUserProjectHoursByWeek[];
};

type Options<T extends StatsType> = {
  type: T;
  fromDate?: Date;
  toDate?: Date;
};

type Period = {
  year: number;
  month: number;
};
const useStatsFromEntries = <T extends StatsType>(
  entries: Entry[],
  options: Options<T>
) => {
  const { fromDate, toDate, type } = options;
  const stats = useMemo(() => {
    if (!entries || !fromDate || !toDate) return [];
    const isInterval = (entry: Entry) => {
      let entryDate = new Date(entry.year, entry.month - 1);
      entryDate = setDayOfYear(entryDate, entry.dayOfYear);
      return isWithinInterval(entryDate, { start: fromDate, end: toDate });
    };

    if (type === StatsType.XStatsUserProjectHoursByWeek) {
      const weekTotals = entries.filter(isInterval).reduce((acc, item) => {
        const weekKey =
          item.user +
          "-" +
          item.project +
          "-" +
          item.year +
          "-" +
          item.isoWeek +
          "-" +
          item.month +
          "-" +
          item.isoWeekYear;
        if (!acc[weekKey]) {
          acc[weekKey] = {
            user: item.user,
            project: item.project,
            week: item.isoWeek,
            year: item.isoWeekYear,
            month: item.month,
            total: 0
          };
        }
        acc[weekKey].total += item.hours;
        return acc;
      }, {} as { [key: string]: XStatsUserProjectHoursByWeek });
      return Object.values(weekTotals) as XStatsUserProjectHoursByWeek[];
    } else if (type === StatsType.XStatsUserProjectHoursByMonth) {
      const monthTotals = entries.filter(isInterval).reduce((acc, item) => {
        const monthKey =
          item.user + "-" + item.project + "-" + item.year + "-" + item.month;
        if (!acc[monthKey]) {
          acc[monthKey] = {
            user: item.user,
            project: item.project,
            year: item.year,
            month: item.month,
            total: 0
          };
        }
        acc[monthKey].total += item.hours;
        return acc;
      }, {} as { [key: string]: XStatsUserProjectHoursByMonth });
      return Object.values(monthTotals) as XStatsUserProjectHoursByMonth[];
    }
  }, [entries, options]);

  if (type === StatsType.XStatsUserProjectHoursByWeek)
    return (stats || []) as StatsTypeMap[T];
  else if (type === StatsType.XStatsUserProjectHoursByMonth)
    return (stats || []) as StatsTypeMap[T];
  else throw new Error("useStatsFromEntries: Invalid type");
};

export default useStatsFromEntries;
