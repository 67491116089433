import React, { useMemo } from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import WeekDistribution from "../../../../components/atoms/WeekDistribution";
import DurationFormat from "../../../../components/atoms/DurationFormat";
import { Trans, useTranslation } from "react-i18next";

export default ({ sortedProjectsList, columns, weekNumbers }) => {
  const { t } = useTranslation();

  const { weekDistribution, totalHours } = useMemo(() => {
    let total = 0;
    const wd = weekNumbers.map(w => {
      const value = sortedProjectsList.reduce(
        (sum, { byWeek = {} }) => (byWeek[w] || 0) + sum,
        0
      );
      total += value;
      return {
        tooltip: `${t("common.week")} ${w}`,
        value: value,
        color: "secondary"
      };
    });
    return { weekDistribution: wd, totalHours: total };
  }, [weekNumbers, sortedProjectsList]);
  return (
    <TableRow key="sum" style={{ background: "rgba(0,0,0,0.025)" }}>
      {columns.expand && <TableCell style={{ maxWidth: 80, width: 80 }} />}

      {columns.avatar && <TableCell style={{ maxWidth: 80, width: 80 }} />}

      <TableCell>
        <b>
          <Trans
            i18nKey="pages.reports.projectHours.weekDistributionSum"
            values={{ amount: sortedProjectsList.length }}
            count={sortedProjectsList.length}
          />
        </b>
      </TableCell>

      {columns.label && <TableCell></TableCell>}

      {columns.weekDistribution && (
        <TableCell align="right">
          <WeekDistribution weeks={weekDistribution} />
        </TableCell>
      )}

      {columns.hours && (
        <TableCell align="right">
          <DurationFormat value={totalHours} component="b" />
        </TableCell>
      )}

      {columns.relativeShare && (
        <TableCell align="right" style={{ minWidth: 120 }} />
      )}

      {columns.relative && (
        <TableCell align="right" style={{ minWidth: 120 }} />
      )}

      {columns.sync && <TableCell align="right" style={{ minWidth: 120 }} />}

      {columns.billable && (
        <TableCell align="right">{t("common.billable")}</TableCell>
      )}
    </TableRow>
  );
};
