import { GET, GET_FAILURE, GET_SUCCESS } from "./constants";

const initialState = {
  stats: [],
  customers: [],
  projects: [],
  users: [],
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        stats: payload.stats,
        customers: payload.customers,
        projects: payload.projects,
        users: payload.users
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
