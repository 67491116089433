import { db } from "../../../../firebase";

export const getStats = async (workspace, year, month) => {
  const snapshot = await db
    .collection("workspaces")
    .doc(workspace)
    .collection("xstats_user_project_hours_by_week")
    .where("year", "==", year)
    .where("month", "==", month)
    .get();

  const all = [];
  snapshot.forEach(doc => {
    all.push({ ...doc.data(), id: doc.id });
  });

  return all;
};
