import React, { forwardRef, ForwardRefExoticComponent } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@superprofit/core-react-components/atoms";
import { push } from "connected-react-router";
import useWorkspace from "../../hooks/useWorkspace";

interface LinkButtonProps {
  color: "primary" | "secondary" | "inherit";
  to: string;
  withWorkspacePrefix?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  startIcon?: React.ReactNode;
}

const LinkButton: ForwardRefExoticComponent<LinkButtonProps> = forwardRef(
  ({ to, withWorkspacePrefix, onClick, children, ...rest }, ref) => {
    const activeWorkspaceId = useWorkspace();
    const target = withWorkspacePrefix ? `/app/${activeWorkspaceId}${to}` : to;
    const dispatch = useDispatch();

    const handleOnClick = e => {
      dispatch(push(target));
      onClick && onClick(e);
    };

    return (
      <Button ref={ref} {...rest} onClick={handleOnClick}>
        {children}
      </Button>
    );
  }
);

export default LinkButton;
