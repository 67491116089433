import {
  all,
  put,
  takeLatest,
  select,
  call,
  delay,
  take,
} from "@redux-saga/core/effects";
import { setLoading } from "./actions";
import { CREATE_INVOICE } from "./constants";
import { Timestamp } from "@superprofit/core-firestore-models";
import moment from "moment";
import { watchSaveInvoice } from "../../../api/invoices/actions";
import Invoice2 from "../../../../../models/Invoice";
import { updateSnackbar } from "../snackbar/actions";
import { SAVE_FAILURE, SAVE_SUCCESS } from "../../../api/invoices/constants";
import {watchExportInvoice} from '../../integrations/twentyfourseven/invoices/actions';
import {EXPORT_FAILURE, EXPORT_SUCCESS} from '../../../api/integrations/twentyfourseven/invoices/constants';

export const createInvoice = function* () {
  yield put(setLoading(true));
  const period = yield select((state) => state.ui.customerOverview2.period);
  const { settings } = yield select((state) => state.api.companySettings);

  const twentyFourSevenIntegration = settings.features?.value?.twentyFourSevenIntegration;

  const {
    data: { projects, customer },
  } = yield select((state) => state.ui.customerOverview2.basis);

  const invoice = {
    customer,
    month: period.month,
    year: period.year,
    issueDate: Timestamp.fromMoment(
      moment(`${period.year} ${period.month}`, "YYYY MM").endOf("month")
    ),
    dueDate: Timestamp.fromMoment(
      moment(`${period.year} ${period.month}`, "YYYY MM")
        .add(1, "m")
        .endOf("month")
    ),
    projects,
  };
  const workspace = yield select(({ api: { auth } }) => auth.activeWorkspaceId);
  let basis = yield call(
    Invoice2.createDataFromXstats,
    workspace,
    invoice.year,
    invoice.month,
    invoice.customer,
    projects,
    invoice.dueDate,
    invoice.issueDate
  );
  basis.projects = basis.projects.filter(p => p?.entries?.length > 0);
  invoice.projects = projects.filter(p => basis.projects.findIndex(bp => bp.id === p.id) > -1 );
  invoice.basis = basis;

  yield put(watchSaveInvoice({}, invoice));
  yield put(updateSnackbar({ visible: true, text: "Creating invoice..." }));
  const response = yield take([SAVE_SUCCESS, SAVE_FAILURE]);
  if (twentyFourSevenIntegration) {
    if (response.type === SAVE_SUCCESS) {
      yield delay(1000);
      yield put(
        updateSnackbar({ visible: true, text: "Invoice created successfully!" })
      );
      yield delay(1000);
      yield put(
        updateSnackbar({ visible: true, text: "Sending invoice to 24/7..." })
      );
      yield put(watchExportInvoice(response.payload.invoice));
      const response2 = yield take([EXPORT_SUCCESS, EXPORT_FAILURE]);
      if (response2.type === EXPORT_SUCCESS) {
        yield put(
          updateSnackbar({ visible: true, text: "Invoice sent to 24/7 successfully!" })
        );
        yield delay(1000);

        yield put(
          updateSnackbar({ visible: false, text: "Invoice sent to 24/7 successfully!" })
        );
        yield put(setLoading(false));

      } else {
        yield delay(1000);
        yield put(updateSnackbar({ visible: true, text: "An error occured!" }));
        yield delay(3000);
        yield put(updateSnackbar({ visible: false, text: "An error occured!" }));
        yield put(setLoading(false));
      }

      // yield put(setLoading(false));
    } else {
      yield delay(1000);
      yield put(updateSnackbar({ visible: true, text: "An error occured!" }));
      yield delay(3000);
      yield put(updateSnackbar({ visible: false, text: "An error occured!" }));
      yield put(setLoading(false));
    }
  } else {
    if (response.type === SAVE_SUCCESS) {
      yield delay(1000);
      yield put(
        updateSnackbar({ visible: true, text: "Invoice created successfully!" })
      );
      yield delay(3000);
      yield put(
        updateSnackbar({ visible: false, text: "Invoice created successfully!" })
      );
      yield put(setLoading(false));
    } else {
      yield delay(1000);
      yield put(updateSnackbar({ visible: true, text: "An error occured!" }));
      yield delay(3000);
      yield put(updateSnackbar({ visible: false, text: "An error occured!" }));
      yield put(setLoading(false));
    }
  }
};

export default function* () {
  yield all([takeLatest(CREATE_INVOICE, createInvoice)]);
}
