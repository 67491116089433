import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import Entry, { GetEntriesRangeOptions } from "../models/Entry";
import { endOfISOWeek, startOfISOWeek } from "date-fns";
import { element } from "prop-types";

export const getKey = (
  workspace: string,
  id: string,
  fromDate: Date,
  toDate: Date
) => [workspace, id, Entry.collectionName, fromDate, toDate];

export type UseProjectEntriesOptions = {
  id: string;
  year?: number;
  week?: number;
  fromDate?: Date;
  toDate?: Date;
};

export const getFactory = (
  workspace: string,
  from: Date,
  to: Date,
  options: GetEntriesRangeOptions
) => () => Entry.getEntriesRange(workspace, from, to, options);

const useProjectEntries = (options: UseProjectEntriesOptions) => {
  const workspace = useWorkspace();

  let { id, year, week, fromDate, toDate } = options;

  if (year && week) {
    fromDate = startOfISOWeek(new Date(year, week - 1, 1));
    toDate = endOfISOWeek(fromDate);
  }

  // factory because ts workaround because of let

  if (fromDate && toDate) {
    let get = getFactory(workspace, fromDate, toDate, { project: id });
    const key = getKey(workspace, id, fromDate, toDate);
    const query = useQuery(key, get, {
      enabled: !!id && !!fromDate && !!toDate
    });
    return query;
  } else {
    throw new Error(
      "useProjectEntries: Either fromDate and toDate or year and week must be provided."
    );
  }
};

export default useProjectEntries;
