import React, { useState, useMemo } from "react";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import HoursHistoryLoader from "../overview/activityCard/HoursHistoryLoader";
import Grid from "@material-ui/core/Grid";
import LineChart from "../../../../components/atoms/LineChart";
import { useTheme } from "@material-ui/core";
import { BudgetCard } from "./Budget.styles";
import { getLabels } from "../../../../utils/ChartUtils";
import { BUDGET_TYPE_ENUM } from "../../../../models/BudgetTypes";

export default ({ project, loading, stats, ...rest }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;

  const { labels, values } = useMemo(() => {
    if (!project || !stats || loading) return { labels: [], values: [] };

    const labels = getLabels(stats, 1, 1);
    const burndownByWeek = stats.reduce((prev, next) => {
      const nextKey = `${next.year}-${next.week}`;
      return {
        ...prev,
        [nextKey]:
          (prev[nextKey] ? prev[nextKey] : 0) +
          (project?.budget?.type === BUDGET_TYPE_ENUM.MONEY_BUDGET
            ? next.total * project.getBillableRate(next.user)
            : next.total)
      };
    }, {});

    const values = labels.map(l => burndownByWeek[l] || 0);
    return { values, labels };
  }, [project, stats, loading]);

  const [showPoints, setShowPoints] = useState(false);

  const isLoading = loading;

  const handleMouseOver = () => {
    if (showPoints) return;
    setShowPoints(true);
  };

  const handleMouseLeave = () => {
    if (!showPoints) return;
    setShowPoints(false);
  };

  if (isLoading) {
    return <HoursHistoryLoader />;
  }

  let startVal = project?.budget?.value;

  const burndown = values.map(v => {
    startVal = startVal - v;
    return startVal;
  });

  return (
    <BudgetCard
      {...rest}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <CardHeader title="Burndown" subheader={"Budget spend over time"} />
      <Grid container spacing={3} component="div">
        <Grid item xs={12} component="div">
          <LineChart
            id="project-burndown"
            isHours={false}
            showPoints={showPoints}
            datasets={[
              {
                color,
                values: burndown || [],
                label: "Burndown"
              }
            ]}
            labels={labels}
          />
        </Grid>
      </Grid>
    </BudgetCard>
  );
};
