import Dialog from "@superprofit/core-react-components/atoms/Dialog";
import DialogTitle from "@superprofit/core-react-components/atoms/DialogTitle";
import DialogContent from "@superprofit/core-react-components/atoms/DialogContent";
import DialogContentText from "@superprofit/core-react-components/atoms/DialogContentText";
import DialogActions from "@superprofit/core-react-components/atoms/DialogActions";
import Button from "@superprofit/core-react-components/atoms/Button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { watchDeleteUsers } from "../../../redux/modules/ui/users/table/actions";
import {hideDeleteUsersDialog, resetDeleteUsersDialog} from '../../../redux/modules/ui/users/deleteUsersDialog/actions';

export default () => {
  const dispatch = useDispatch();
  const { open, users } = useSelector(
    (state) => state.ui.users.deleteUsersDialog
  );
  const handleDelete = () => {
    dispatch(watchDeleteUsers(users));
    dispatch(hideDeleteUsersDialog());
  };

  const handleCancel = () => {
    dispatch(hideDeleteUsersDialog());
  };

  const handleExited = () => {
    dispatch(resetDeleteUsersDialog());
  };

  return (
    <Dialog
      onExited={handleExited}
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete users?</DialogTitle>
      <DialogContent>
        {users.length === 1 ? (
          <DialogContentText id="alert-dialog-description">
            This will remove user with email {users[0].email} permanently. All
            statistics will be lost.
          </DialogContentText>
        ) : (
          <DialogContentText id="alert-dialog-description">
            Permanently removing {users.length} users. All statistics will be
            lost.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" autoFocus>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleDelete}
          color="alert"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
