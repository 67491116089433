import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import Entry, { GetEntriesRangeOptions } from "../models/Entry";

export const useEntriesWithDateRange = ({
  user,
  fromDate,
  toDate,
  options = {}
}: {
  user?: string;
  fromDate: Date;
  toDate: Date;
  options?: GetEntriesRangeOptions;
}) => {
  const workspace = useWorkspace();
  const get = () =>
    Entry.getEntriesRange(workspace, fromDate, toDate, { user, ...options });
  const key = [workspace, user, Entry.collectionName, fromDate, toDate];

  const query = useQuery(key, get, {
    enabled: !!fromDate && !!toDate
  });
  return query;
};

export const useEntries = ({
  id,
  year,
  week
}: {
  id: string;
  year: number;
  week: number;
}) => {
  const workspace = useWorkspace();
  const get = () => Entry.getUserTimesheet(workspace, id, year, week);
  const key = [workspace, id, Entry.collectionName];

  const query = useQuery(key, get, {
    enabled: !!id && !!year && !!week
  });
  return query;
};

export default useEntries;
