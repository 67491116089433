import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../index";
import { setPeriod } from "../../../../../redux/modules/ui/workspaceOverview/period/actions";

const usePeriod = () => {
  const period = useSelector(
    (state: RootState) => state.ui.workspaceOverview.period
  );
  const dispatch = useDispatch();
  const update = (updates: { year?: number; month?: number }) => {
    const ups = { ...period, ...updates };
    dispatch(setPeriod(ups.year, ups.month));
  };
  return { ...period, update };
};

export default usePeriod;
