import styled, { css } from "styled-components";
import { Box, lighten, Theme } from "@material-ui/core";
import theme from "styled-theming";

// const color = props =>
//   ({
//     primary: props.theme.palette.primary.main,
//     secondary: props.theme.palette.secondary.main,
//     tertiary: props.theme.palette.tertiary.main,
//     success: props.theme.palette.success.main,
//     info: props.theme.palette.info.main,
//     warning: props.theme.palette.warning.main,
//     error: props.theme.palette.error.main
//   }[props.color]);
//
// const accentColor = props =>
//   ({
//     primary: lighten(props.theme.palette.primary.main, .8),
//     secondary: lighten(props.theme.palette.secondary.main, .8),
//     tertiary: lighten(props.theme.palette.tertiary.main, .8),
//     success: props.theme.palette.success.light,
//     info: props.theme.palette.info.light,
//     warning: props.theme.palette.warning.light,
//     error: props.theme.palette.error.light
//   }[props.color]);

type ColorKey =
  | "primary"
  | "secondary"
  | "tertiary"
  | "success"
  | "info"
  | "warning"
  | "error";

interface ColorProps {
  theme: Theme;
  color: ColorKey;
}

const color = ({ theme, color }: ColorProps): string => {
  const colors: Record<ColorKey, string> = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    tertiary: theme.palette.tertiary.main,
    success: theme.palette.success.main,
    info: theme.palette.text.white,
    warning: theme.palette.warning.main,
    error: theme.palette.error.main
  };
  return colors[color];
};

const accentColor = ({ theme, color }: ColorProps): string => {
  const accentColors: Record<ColorKey, string> = {
    primary: lighten(theme.palette.primary.main, 0.8),
    secondary: lighten(theme.palette.secondary.main, 0.8),
    tertiary: lighten(theme.palette.tertiary.main, 0.8),
    success: theme.palette.success.light,
    info: theme.palette.info.light,
    warning: theme.palette.warning.light,
    error: theme.palette.error.light
  };
  return accentColors[color];
};

const variants = theme.variants("mode", "variant", {
  outlined: {
    light: css`
      border: 1px solid ${props => color(props)};
      background: ${props => props.theme.palette.common.white};
      color: ${props => color(props)};
    `,
    dark: css``
  },
  filled: {
    light: css`
      background: ${props => accentColor(props)};
      color: ${props => color(props)};
    `,
    dark: css``
  }
});

export const StyledLabel = styled(Box)<any>`
  ${variants};
  box-sizing: border-box;
  font-size: 0.75rem;
  letter-spacing: 1.2px;
  display: inline-flex;
  text-transform: uppercase;
  padding: ${props => props.theme.spacing(0.5, 1, 0.5, 1)};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  border-radius: ${props => props.theme.shape.borderRadius}px;
`;
