import React, { forwardRef, ForwardRefExoticComponent } from "react";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";

const Component: ForwardRefExoticComponent<MenuItemProps> = forwardRef(
  ({ button, ...props }, ref) => (
    <MenuItem
      /*types workaround*/ button={button ? true : undefined}
      {...props}
      ref={ref}
    />
  )
);
export default Component;
