import { takeLatest, call, put, select, all } from "redux-saga/effects";
import {
  WATCH_DELETE,
  WATCH_GET,
  WATCH_SAVE,
  WATCH_UPDATE_ALL,
  WATCH_PATCH,
  WATCH_DELETE_MULTIPLE
} from "./constants";
import {
  batchUpdate,
  batchUpdateFailure,
  batchUpdateSuccess,
  deleteFailure,
  deleteInvoice as deleteInvoiceAction,
  deleteSuccess,
  get,
  getFailure,
  getSuccess,
  save,
  saveFailure,
  saveSuccess,
  patch,
  patchFailure,
  patchSuccess,
  deleteMultiple,
  deleteMultipleSuccess,
  deleteMultipleFailure
} from "./actions";
import Invoice from "../../../../models/Invoice";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;
export const getUserEmail = ({
  api: {
    auth: { user }
  }
}) => user.email;

export const getInvoices = function* getInvoices() {
  try {
    yield put(get());
    const workspace = yield select(getActiveWorkspace);
    const invoices = yield call(Invoice.list, workspace);

    yield put(getSuccess(invoices));
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const updateInvoices = function* getInvoices(args) {
  const { invoices, updates } = args.payload;
  try {
    yield put(batchUpdate());
    const workspace = yield select(getActiveWorkspace);
    const updated = yield call(
      Invoice.batchUpdate,
      workspace,
      invoices,
      updates
    );

    yield put(batchUpdateSuccess(updated));
  } catch (e) {
    console.warn(e);
    yield put(batchUpdateFailure(e));
  }
};

export const deleteInvoice = function* deleteInvoice(args) {
  const { id } = args.payload;
  try {
    yield put(deleteInvoiceAction(id));
    const workspace = yield select(getActiveWorkspace);
    yield call(Invoice.delete, workspace, id);
    yield put(deleteSuccess(id));
  } catch (e) {
    console.warn(e);
    yield put(deleteFailure(e));
  }
};

export const deleteMultipleInvoices = function* deleteMultipleInvoices(args) {
  const { ids } = args.payload;
  try {
    yield put(deleteMultiple(ids));
    const workspace = yield select(getActiveWorkspace);
    yield call(Invoice.deleteMultiple, workspace, ids);
    yield put(deleteMultipleSuccess(ids));
  } catch (e) {
    console.warn(e);
    yield put(deleteMultipleFailure(e));
  }
};

export const saveInvoice = function* saveInvoice(arg) {
  const {
    payload: { invoice, updates }
  } = arg;
  try {
    yield put(save(invoice, updates));
    const workspace = yield select(getActiveWorkspace);
    const user = yield select(getUserEmail);
    const isNew = !invoice || !invoice.id;
    let savedInvoice;
    if (isNew) {
      savedInvoice = yield call(Invoice.create, workspace, user, updates);
    } else {
      savedInvoice = yield call(
        Invoice.update,
        workspace,
        user,
        invoice,
        updates
      );
    }
    yield put(saveSuccess(savedInvoice, isNew));
  } catch (e) {
    console.warn(e);
    yield put(saveFailure(e));
  }
};

export const patchInvoice = function* patchInvoice(arg) {
  try {
    const {
      payload: { id, updates }
    } = arg;

    yield put(patch(id));

    const workspace = yield select(getActiveWorkspace);
    const user = yield select(getUserEmail);

    yield call(Invoice.patch, workspace, user, id, updates);

    yield put(patchSuccess(id, updates));
  } catch (e) {
    console.warn(e);
    yield put(patchFailure(e));
  }
};

export const getInvoicesSaga = function* getInvoicesSaga() {
  yield takeLatest(WATCH_GET, getInvoices);
};

export const saveInvoiceSaga = function* saveInvoiceSaga() {
  yield takeLatest(WATCH_SAVE, saveInvoice);
};

export const deleteInvoiceSaga = function* deleteInvoiceSaga() {
  yield takeLatest(WATCH_DELETE, deleteInvoice);
};

export const updateInvoicesSaga = function* updateInvoicesSaga() {
  yield takeLatest(WATCH_UPDATE_ALL, updateInvoices);
};

export const patchInvoiceSaga = function* patchInvoiceSaga() {
  yield takeLatest(WATCH_PATCH, patchInvoice);
};

export const deleteMultipleInvoicesSaga = function* deleteMultipleInvoicesSaga() {
  yield takeLatest(WATCH_DELETE_MULTIPLE, deleteMultipleInvoices);
};

export default function*() {
  yield all([
    saveInvoiceSaga(),
    getInvoicesSaga(),
    deleteInvoiceSaga(),
    updateInvoicesSaga(),
    patchInvoiceSaga(),
    deleteMultipleInvoicesSaga()
  ]);
}
