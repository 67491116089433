import React, { forwardRef } from "react";
import {
  List as MuiList,
  ListSubheader as MuiListSubheader,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  ListItemSecondaryAction as MuiListItemSecondaryAction,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";

export default forwardRef((props, ref) => <MuiList {...props} ref={ref} />);

export const ListSubheader = forwardRef((props, ref) => (
  <MuiListSubheader {...props} ref={ref} />
));

export const ListItemSecondaryAction = forwardRef((props, ref) => (
  <MuiListItemSecondaryAction {...props} ref={ref} />
));

export const ListItem = forwardRef((props, ref) => (
  <MuiListItem {...props} ref={ref} />
));

export const ListItemIcon = forwardRef((props, ref) => (
  <MuiListItemIcon {...props} ref={ref} />
));

export const ListItemText = forwardRef((props, ref) => (
  <MuiListItemText {...props} ref={ref} />
));

export const NavListItem = forwardRef(({ path, onClick, ...rest }, ref) => {
  const history = useHistory();
  const location = useLocation();

  const handleOnClick = e => {
    if (path) history.push(path);
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <ListItem
      button
      {...rest}
      selected={location.pathname === path}
      onClick={handleOnClick}
    />
  );
});
