import React from "react";
import { TrendingDown, TrendingFlat, TrendingUp } from "@material-ui/icons";
import Tooltip from "@superprofit/core-react-components/atoms/Tooltip";
import useTheme from "@material-ui/core/styles/useTheme";
import { useTranslation } from "react-i18next";
export default props => {
  const { changePercentage = 0, suffix = "previous month" } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  let text = t("components.atoms.trendIndicator.templates.noChange", {
    suffix
  });
  let icon = (
    <span style={{ color: theme.palette.info.main }}>
      <TrendingFlat fontSize="small" color="inherit" />
    </span>
  );

  if (changePercentage > 0) {
    text = t("components.atoms.trendIndicator.templates.changeUp", {
      changePercentage,
      suffix
    });
    icon = (
      <span style={{ color: theme.palette.success.main }}>
        <TrendingUp fontSize="small" color="inherit" />
      </span>
    );
  } else if (changePercentage < 0) {
    text = t("components.atoms.trendIndicator.templates.changeDown", {
      changePercentage
    });
    icon = (
      <span style={{ color: theme.palette.warning.main }}>
        <TrendingDown fontSize="small" color="inherit" />
      </span>
    );
  }

  return (
    <Tooltip arrow title={text} aria-label={text}>
      {icon}
    </Tooltip>
  );
};
