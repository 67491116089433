import { SAVE_SUCCESS, SAVING, SAVE_FAILED } from "./constants";

export const saved = (leadId, comment) => ({
  type: SAVE_SUCCESS,
  payload: { leadId, comment }
});

export const saving = (leadId, comment) => ({
  type: SAVING,
  payload: { leadId, comment }
});

export const saveError = (leadId, comment) => ({
  type: SAVE_FAILED,
  payload: { leadId, comment }
});
