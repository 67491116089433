import React, { useMemo } from "react";
import Project from "../../../../models/Project";
import { Card } from "./card/Card";
import Entry from "../../../../models/Entry";
import { Container } from "./ProjectView.styles";
import useSaveEntry from "../useSaveEntry";
import { useProjectSortOrder } from "./useProjectSortOrder";

interface CardProps {
  datesBetween: Date[];
  entries: Entry[];
  isLoading: boolean;
  projects: Project[];
  hiddenProjects: Project[];
  showHiddenProjects: boolean;
  userEmail: string;
}
export function ProjectView(props: CardProps) {
  const {
    entries = [],
    isLoading,
    projects = [],
    hiddenProjects = [],
    showHiddenProjects
  } = props;
  const handleSaveEntry = useSaveEntry();
  const { reorder, getSortedProjects } = useProjectSortOrder();

  const sortedProjects = useMemo(() => getSortedProjects(projects), [
    getSortedProjects,
    projects
  ]);

  const handleMoveProjectUp = (project: Project) => {
    const index = sortedProjects.findIndex(p => p.id === project.id);
    if (index === 0) return;
    reorder(sortedProjects, index, index - 1);
  };
  const handleMoveProjectDown = (project: Project) => {
    const index = sortedProjects.findIndex(p => p.id === project.id);
    if (index === sortedProjects.length - 1) return;
    reorder(sortedProjects, index, index + 1);
  };

  return (
    <Container>
      {!isLoading &&
        sortedProjects?.map((project: Project, index: number) => (
          <Card
            key={project.id}
            {...props}
            entries={entries.filter(e => e.project === project.id)}
            project={project}
            onSaveEntry={handleSaveEntry}
            onMoveProjectDown={
              index === sortedProjects.length - 1
                ? undefined
                : handleMoveProjectDown
            }
            onMoveProjectUp={index === 0 ? undefined : handleMoveProjectUp}
          />
        ))}
      {!isLoading &&
        showHiddenProjects &&
        hiddenProjects?.map((project: Project, index: number) => (
          <Card
            key={project.id}
            {...props}
            hidden
            entries={entries.filter(e => e.project === project.id)}
            project={project}
            onSaveEntry={handleSaveEntry}
            onMoveProjectDown={
              index === sortedProjects.length - 1
                ? undefined
                : handleMoveProjectDown
            }
            onMoveProjectUp={index === 0 ? undefined : handleMoveProjectUp}
          />
        ))}
    </Container>
  );
}
