import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  SAVE_FAILURE,
  WATCH_GET,
  WATCH_SAVE
} from "./constants";

export const get = () => ({
  type: GET,
  payload: {}
});

export const getSuccess = settings => ({
  type: GET_SUCCESS,
  payload: { settings }
});

export const getFailure = error => ({
  type: GET_FAILURE,
  payload: { error }
});

export const save = () => ({
  type: SAVE,
  payload: {}
});

export const saveSuccess = savedSettings => ({
  type: SAVE_SUCCESS,
  payload: { savedSettings }
});

export const saveFailure = error => ({
  type: SAVE_FAILURE,
  payload: { error }
});

export const watchSaveCompanySettings = settings => ({
  type: WATCH_SAVE,
  payload: { settings }
});

export const watchGetCompanySettings = () => ({
  type: WATCH_GET,
  payload: {}
});
