import React, { useEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { authUi, firebase } from "../../firebase";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import SelectWorkspace from "./login/SelectWorkspace";
import { StyledLoginSection } from "./login/Login.styles";
import { Dialog, DialogTitle } from "@superprofit/core-react-components/atoms";
import Link from "@material-ui/core/Link";
import { getCurrentWorkspace } from "../../helpers";

export default React.forwardRef(
  (
    {
      open = true,
      routerHelpers: { getProtectedPathWithKey, getPublicPathWithKey },
      scrollYProgress,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(({ api: { auth } }) => auth.user);
    const idTokenResult = useSelector(
      ({ api: { auth } }) => auth.idTokenResult
    );
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    // NOTICE
    // if we use mu
    // myemail+something@gmail.com the + will be replaced by ' '.
    // will not fix that now as that probably only happens when testing and will only result in
    // the potential 'app/workspace/users/myemail something@gmail.com' statying in loading state
    const paramsNext = params.get("next");
    const next = paramsNext && decodeURIComponent(paramsNext);

    // const workspace = useSelector(
    //   ({ api: { auth } }) => auth.activeWorkspaceId
    // );
    const workspace = getCurrentWorkspace(next);

    useEffect(() => {
      if (!!user && next) {
        dispatch(push(next));

        // if (next) {
        //   dispatch(push(next));
        // } else {
        //   if (workspace) {
        //     dispatch(push(getProtectedPathWithKey("dashboard")));
        //   }
        // }
      }
    }, [dispatch, workspace, user]);
    let title = !!user
      ? t("You are signed in.")
      : workspace
      ? t("pages.login.templates.signInTo", { workspace })
      : t("pages.login.signInHere");
    const isSignedIn = idTokenResult && !workspace;
    return (
      <Dialog open={open} {...rest}>
        <DialogTitle>{isSignedIn ? "Welcome" : "Sign in"}</DialogTitle>
        <StyledLoginSection ref={ref} color="white">
          {isSignedIn ? (
            <SelectWorkspace signInPath={getPublicPathWithKey("login")} />
          ) : (
            <>
              <StyledFirebaseAuth
                uiConfig={authUi}
                firebaseAuth={firebase.auth()}
              />
              {/*<span className="login-alt">- or -</span>*/}
              {/*<Link className="login-create" href="#" onClick={() => signInWithEmail}>*/}
              {/*  Create account.*/}
              {/*</Link>*/}
            </>
          )}
        </StyledLoginSection>
      </Dialog>
    );
  }
);
