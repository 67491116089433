import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  SAVE_FAILURE,
  WATCH_GET,
  WATCH_SAVE
} from "./constants";
import UserSettingGeneric from "../../../../models/UserSettingGeneric";
import UserSettingGoal from "../../../../models/UserSettingGoal";

export const get = () => ({
  type: GET,
  payload: {}
});

export const getSuccess = (settings: UserSettingGeneric | UserSettingGoal) => ({
  type: GET_SUCCESS,
  payload: { settings }
});

export const getFailure = (error: Error) => ({
  type: GET_FAILURE,
  payload: { error }
});

export const save = () => ({
  type: SAVE,
  payload: {}
});

export const saveSuccess = (savedSettings: Array<UserSettingGeneric | UserSettingGoal>) => ({
  type: SAVE_SUCCESS,
  payload: { savedSettings }
});

export const saveFailure = (error: Error) => ({
  type: SAVE_FAILURE,
  payload: { error }
});

export const watchSaveUserSettings = (settings: (UserSettingGeneric | UserSettingGoal)[]) => ({
  type: WATCH_SAVE,
  payload: { settings }
});

export const watchGetUserSettings = () => ({
  type: WATCH_GET,
  payload: {}
});
