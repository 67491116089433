import React from "react";
import {
  Autocomplete,
  TextField,
  Chip,
  Checkbox
} from "@superprofit/core-react-components/atoms";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const renderTags = (value, getCustomizedTagProps) => {
  return value.map((project, index) => (
    <Chip
      color="primary"
      label={project.name}
      size="medium"
      {...getCustomizedTagProps({ index })}
    />
  ));
};

export default ({ variant, fullWidth, projects, onClose, label, ...rest }) => (
  <Autocomplete
    multiple
    renderTags={renderTags}
    options={projects}
    disableCloseOnSelect
    onClose={e =>
      setTimeout(() => {
        // hack to prevent backdrop close parent modal when closing popper
        onClose && onClose();
      }, 500)
    }
    getOptionLabel={option => option.name || option.id}
    renderOption={(option, { selected }) => (
      <React.Fragment>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          color="primary"
          checked={selected}
        />
        {option.name}
      </React.Fragment>
    )}
    renderInput={params => (
      <TextField
        {...params}
        variant={variant}
        fullWidth={fullWidth}
        label={label}
        placeholder=""
      />
    )}
    {...rest}
  />
);
