import React, { forwardRef, ForwardRefExoticComponent, useState } from "react";
import {
  ColorButton,
  StyledColorPicker
} from "./colorPicker/ColorPicker.styles";
import { TwitterPicker } from "react-color";
import Popover from "@superprofit/core-react-components/atoms/Popover";
import FormLabel from "@superprofit/core-react-components/atoms/FormLabel";
import { useTranslation } from "react-i18next";
import { Controller, ControllerProps } from "react-hook-form";

interface IColorPicker {
  defaultValue?: string;
  onChange: (str: string) => void;
  value?: string;
}

const ColorPicker: ForwardRefExoticComponent<IColorPicker> = forwardRef<
  HTMLDivElement,
  IColorPicker
>((props, ref) => {
  const { defaultValue, onChange, value, ...rest } = props;
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState(defaultValue);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const actualValue = value || val;

  const handleChange = ({ hex }: { hex: string }) => {
    setVal(hex);
    onChange(hex);
  };

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };

  return (
    <StyledColorPicker {...rest} ref={ref}>
      <FormLabel component="legend">{t("common.color")}</FormLabel>
      <ColorButton
        type="button"
        ref={anchorRef}
        color={actualValue}
        onClick={handleOpen}
      />
      <Popover
        anchorEl={anchorRef.current}
        open={!!open}
        anchorOrigin={{
          vertical: "bottom"
        }}
        onClose={() => setOpen(false)}
        aria-label="color picker"
      >
        <TwitterPicker
          colors={[
            "#FF4400",
            "#EB144C",
            "#F78DA7",
            "#FF6600",
            "#FCB900",
            "#117711",
            "#228822",
            "#55AA55",
            "#7BDCB5",
            "#0222DD",
            "#0255DD",
            "#0693E3",
            "#3333FF",
            "#3f51b5",
            "#9900EF",
            "#8E1155",
            "#ABB8C3"
          ]}
          hex={actualValue}
          triangle="top-left"
          onChange={handleChange}
        />
      </Popover>
    </StyledColorPicker>
  );
});

interface ColorPickerFormControllerProps<T>
  extends Omit<IColorPicker, "onChange"> {
  control: ControllerProps<T>["control"];
  name: ControllerProps<T>["name"];
}

export function withFormController<FieldValues>() {
  return (props: ColorPickerFormControllerProps<FieldValues>) => {
    const { control, name, defaultValue, ...rest } = props;
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const { onChange, value } = field;
          return (
            <ColorPicker
              onChange={color => onChange(color)}
              value={value}
              {...rest}
            />
          );
        }}
      />
    );
  };
}

export default ColorPicker;
