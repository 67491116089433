import Invoice, {
  InvoiceData,
  InvoiceDataCreatePayload
} from "../models/Invoice";
import useWorkspace from "./useWorkspace";
import { useMutation } from "@tanstack/react-query";
import useUser from "./useUser";

export function useInvoiceCreateMutation() {
  const workspace = useWorkspace();

  const user = useUser();
  const create = async (invoiceData: InvoiceDataCreatePayload) => {
    return Invoice.create(workspace, user.email, invoiceData);
  };

  const mutationResult = useMutation(create);
  return mutationResult;
}
