import { useMemo } from "react";
import Project from "../../../../models/Project";
import useUnarchivedProjects from "../../../../hooks/useUnarchivedProjects";

const useProjectsMissingNettlonnConfig = () => {
  const { data: projects = [] } = useUnarchivedProjects();
  return useMemo(
    () =>
      projects.filter(
        (project: Project) =>
          project.salaryEnabled &&
          (!project.metadata?.nettlonn?.salaryArt ||
            !project.metadata?.nettlonn?.dimensionOne)
      ),
    [projects]
  );
};

export default useProjectsMissingNettlonnConfig;
