import mmnt from "moment";

mmnt.updateLocale("en", { week: {
    dow: 1, // First day of week is Monday
    doy: 4
  }});

export const moment = mmnt;

export const years = (end, count) =>
  new Array(count).fill(0).map((x, idx) => end - idx);

export const weeks = (year, month) => {
  const weeks = [];
  let start = moment().year(year).month(month-1).startOf("month");
  let w = start.week();
  while(start.clone().startOf('week').month() === month-1 || start.clone().endOf('week').month() === month-1) {
    weeks.push(w);
    start.add(1, 'week');
    w = start.week();
  }
  // release
  return weeks;
};

export const getCurrentMonth = () => moment().month();
export const getCurrentYear = () => moment().year();

export const weeksOfYear = year => {
  let ws = [];
  const start = moment()
    .year(year)
    .startOf('year');

  while(start.year() === year) {
    ws.push({
      week: start.week(),
      range: {
        start: start.clone()
          .startOf("week"),
        end: start.clone()
          .endOf("week")
      }
    });
    start.add(1, "week");
  }

  return ws;
};

export const dayNames = () => [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

export const dayNamesAbbr = () => dayNames().map(d => d.slice(0, 3));

window.moment = moment;
export const dayNamesAbbrWithDate = (year, week) => {


  const weekStart = moment()
    .year(year)
    .week(week)
    .year(year)
    .startOf("week")
    .isoWeekday(1);

  return new Array(7).fill('').map((d, idx) => (
    `${d} ${weekStart
      .clone()
      .add(idx, "days")
      .format('ddd DD')}`
  ));
};

export const monthNames = () => [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
