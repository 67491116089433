import React from "react";
import styled from "styled-components";
import UserAvatar from "./UserAvatar";
import useTheme from "@material-ui/core/styles/useTheme";

const StyledUserHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > * {
    &:first-child {
      margin-right: ${props => props.theme.spacing(2)}px;
    }
  }
`;

export default ({ user, ...rest }) => {
  const theme = useTheme();

  const size = theme.spacing(6);

  return (
    <StyledUserHeader>
      <UserAvatar user={user} style={{ height: size, width: size }} />
      <div>{user.displayName || user.email || "Unnamed"}</div>
    </StyledUserHeader>
  );
};
