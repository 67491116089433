import React, { useEffect, useMemo, useState } from "react";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@superprofit/core-react-components/atoms/Table";
import Card from "@superprofit/core-react-components/atoms/Card";
import ProjectItem from "./ProjectItem";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import {
  getUserHoursByProject,
  getMoneyByProject,
  getHoursByProject,
  getRelativeHoursPercentageByProject,
  getHoursPerWeekByProject,
  getUserHoursPerWeekByProject,
  getWeekOfMonthDates
} from "../../../../utils/ProjectStats";
import WeekDistribution from "../../../../components/atoms/WeekDistribution";
import { weeks } from "@superprofit/time-util";
import TableLoader from "../../../../components/molecules/TableLoader";
import { filterStats } from "../../../../utils/StatsFilter";
import Entry from "../../../../models/Entry";
import { createTableExport as createTableExportCustomer } from "../../../../utils/report/data/CustomerTableExport";
import { createTableExport } from "../../../../utils/report/data/ProjectTableExport";
import { createTableExport as createTableExportBasis } from "../../../../utils/report/data/BasisExport";
import { saveAsPDF, saveAsXLSX } from "../../../../utils/report";
import { useSelector } from "react-redux";
import TimetUser from "../../../../models/TimetUser";
import ActionMenu from "../shared/ActionMenu";
import WeekDistributionSum from "./WeekDistributionSum";
import TimesheetComment from "../../../../models/TimesheetComment";
import useCustomers from "../../../../hooks/useCustomers";
import { ANALYTICS_EVENT, logEvent } from "../../../../firebase";
import { useTranslation } from "react-i18next";
import { useFeatures, useUserAcccess } from "../../../../helpers";
import useWorkspace from "../../../../hooks/useWorkspace";
import useWorkspaceDisplayName from "../../../../hooks/useWorkspaceDisplayName";
import useUsersMap from "../../../../hooks/useUsersMap";
import useProjectsMap from "../../../../hooks/useProjectsMap";
import useFilteredStats from "../shared/useFilteredStats";
import Project from "../../../../models/Project";
import Customer from "../../../../models/Customer";
import User from "../../../../models/User";
import useGlobalSnackbar from "../../../../hooks/useGlobalSnackbar";
import { format } from "../../../../date-fns-wrappers";

type ProjectListItem = {
  project: Project;
  hours: number;
  money: number;
  relativeHours: number;
  byWeek: any;
  byUserWeek: any;
};

type ExtendedEntry = Entry & { total?: number };
const getProp = (data: ProjectListItem, orderBy: Property) => {
  switch (orderBy) {
    case "name":
      return data.project.name;
    case "label":
      return data.project.billable ? 1 : -1;
    default:
      return data[orderBy] || 0;
  }
};

const descendingComparator = (
  a: ProjectListItem,
  b: ProjectListItem,
  orderBy: Property
) => {
  if (getProp(b, orderBy) < getProp(a, orderBy)) return -1;
  if (getProp(b, orderBy) > getProp(a, orderBy)) return 1;
  return 0;
};

const getComparator = (orderDir: Direction, orderBy: Property) => {
  return orderDir === "desc"
    ? (a: ProjectListItem, b: ProjectListItem) =>
        descendingComparator(a, b, orderBy)
    : (a: ProjectListItem, b: ProjectListItem) =>
        -descendingComparator(a, b, orderBy);
};
type Period = {
  day?: number;
  year: number;
  week?: number;
  month: number;
};

type Direction = "asc" | "desc";

type Property = "hours" | "money" | "relativeHours" | "name" | "label";

interface Props {
  filters?: any;
  stats: any;
  projects: Project[];
  customer?: Customer;
  customers?: Customer[];
  users: User[];
  userGroups?: any;
  period: Period;
  loading: boolean;
  title?: string;
  columns: any;
  show?: any;
  onFilterOpen?: () => void;
  onSyncProject?: (project: Project) => void;
}

const ProjectsTable: React.FC<Props> = props => {
  const {
    filters,
    stats,
    projects = [],
    customer,
    customers,
    users,
    userGroups,
    period,
    loading,
    onFilterOpen,
    onSyncProject,
    title,
    columns,
    show = {},
    ...rest
  } = props;
  const workspace = useWorkspace();
  const workspaceDisplayName = useWorkspaceDisplayName();
  const { update: updateSnackbar } = useGlobalSnackbar();

  const features = useFeatures();
  const { isAdmin } = useUserAcccess();

  const { t } = useTranslation();

  const { data: testcusts } = useCustomers();

  const usersMap = useUsersMap(users);

  const projectsMap = useProjectsMap(projects);

  const [orderBy, setOrderBy] = useState<Property>("hours");
  const [orderDir, setOrderDir] = useState<Direction>("desc");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { year, month } = period;

  const weekNumbers = useMemo(() => {
    return weeks(year, month);
  }, [year, month]);

  const usersById = Object.fromEntries(usersMap);

  const projectsById = Object.fromEntries(projectsMap);

  const filteredStats = useFilteredStats({ filters, stats, projects });

  const filteredProjects = useMemo(() => {
    if (
      !filters ||
      (filters?.projects?.length === 0 && filters?.customers?.length === 0)
    )
      return projects;
    let shown = projects;

    if (filters?.projects?.length !== 0) {
      const shownProjectsById = filters.projects.reduce(
        (prev: any, next: string) => ({
          ...prev,
          [next]: true
        }),
        {}
      );
      shown = shown.filter(p => !!(p?.id !== null && shownProjectsById[p.id]));
    }
    if (filters.type && filters.type !== "All") {
      const billable = filters.type === "Billable";
      shown = shown.filter(p => p.billable === billable);
    }

    if (filters?.customers?.length > 0) {
      shown = shown.filter(p => filters.customers.includes(p.customer));
    }
    return shown || [];
  }, [filters, projects]);

  const projectsList = useMemo(() => {
    const userHoursByProject = getUserHoursByProject(filteredStats) as any;
    const moneyByProject = getMoneyByProject(
      filteredStats,
      projectsById
    ) as any;
    const hoursByProject = getHoursByProject(filteredStats) as any;
    const relativeHours = getRelativeHoursPercentageByProject(
      filteredStats
    ) as any;
    const hoursByWeek = getHoursPerWeekByProject(filteredStats) as any;
    const hoursByProjectUserWeek = getUserHoursPerWeekByProject(
      filteredStats
    ) as any;

    return (filteredProjects || []).map((project: Project) => ({
      project,
      hours:
        project?.id && hoursByProject[project.id]
          ? hoursByProject[project.id]
          : 0,

      userHours:
        project?.id && userHoursByProject[project.id]
          ? userHoursByProject[project.id]
          : {},
      money:
        project?.id && moneyByProject[project.id]
          ? moneyByProject[project.id]
          : 0,
      relativeHours:
        project?.id && relativeHours[project.id]
          ? relativeHours[project.id]
          : 0,
      byWeek:
        project?.id && hoursByWeek[project.id] ? hoursByWeek[project.id] : {},
      byUserWeek:
        project?.id && hoursByProjectUserWeek[project.id]
          ? hoursByProjectUserWeek[project.id]
          : {}
    }));
  }, [stats, projectsById, filteredProjects]);

  const sortedProjectsList = useMemo(() => {
    const sorted = projectsList.slice();
    sorted.sort(getComparator(orderDir, orderBy));
    return sorted;
  }, [projectsList, orderBy, orderDir]);

  const pageList = useMemo(() => {
    return rowsPerPage > 0
      ? sortedProjectsList.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : sortedProjectsList;
  }, [sortedProjectsList, page, rowsPerPage]);

  const weekDistribution = useMemo(
    () =>
      weekNumbers &&
      year &&
      month &&
      weekNumbers.map((w: number) => ({
        tooltip: `${t("common.week")} ${w}`,
        value: getWeekOfMonthDates(year, month, w)
      })),
    [weekNumbers]
  );

  const count = projectsList.length;

  const handleOnSort = (event: React.MouseEvent, property: Property) => {
    const isAsc = orderBy === property && orderDir === "asc";
    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExport = async (type: "XLSX" | "PDF") => {
    logEvent(ANALYTICS_EVENT.PROJECT_HOURS_EXPORTED, {
      workspaceName: workspace,
      type
    });

    try {
      updateSnackbar({
        open: true,
        message: `${t("common.exporting")}... 📈`,
        alert: { severity: "info" }
      });
      const projectIds: string[] = filteredProjects.map(p => p.id || "") || [];
      const userIds = users.map(u => u.id);
      const rawEntries = await Entry.getAllByProjects(
        workspace,
        projectIds,
        year,
        month
      );
      let rawComments: TimesheetComment[] = [];
      if (userIds?.length > 0) {
        rawComments = await TimesheetComment.getAllByProjectsAndUsers(
          workspace,
          projectIds,
          userIds,
          year,
          month
        );
      } else {
        rawComments = await TimesheetComment.getAllByProjects(
          workspace,
          projectIds,
          year,
          month
        );
      }

      let fetchedUsers = users;
      if (!fetchedUsers || fetchedUsers.length === 0) {
        fetchedUsers = await TimetUser.list(null, workspace);
      }
      rawEntries.forEach((e: ExtendedEntry) => (e.total = e.hours || 0));
      let mergedEntries = rawEntries.map(e => {
        const com = rawComments.filter(
          c =>
            c.year === e.year &&
            c.day === e.dayOfYear &&
            c.user === e.user &&
            c.project === e.project
        );
        return { ...e, comments: com };
      });
      const filteredEntries = filters
        ? filterStats(
            mergedEntries,
            projects,
            filters.type,
            filters.projects,
            filters.users,
            filters.customers
          )
        : mergedEntries;

      let report;
      let reportCustomers = customer && [customer];
      if (
        !reportCustomers &&
        customers &&
        filters &&
        filters.customers.length > 0
      ) {
        reportCustomers = customers.filter(c =>
          filters.customers.includes(c.id)
        );
      }
      if (reportCustomers) {
        report = createTableExportCustomer(
          reportCustomers,
          filteredProjects,
          fetchedUsers,
          filteredEntries
        );
      } else {
        report = createTableExport(
          fetchedUsers,
          filteredProjects,
          filteredEntries
        );
      }
      const formattedDate = format(new Date(year, month - 1, 1), "MMMM yyyy");

      let filename = t("pages.reports.filenames.projectHours_multiple", {
        formattedDate
      });

      if (reportCustomers && reportCustomers.length === 1) {
        filename = t("pages.reports.filenames.customerHours_one", {
          formattedDate,
          customerName: reportCustomers[0].name
        });
      }

      if (filteredProjects.length === 1) {
        const project = filteredProjects[0];
        if (project) {
          filename = t("pages.reports.filenames.projectHours_one", {
            formattedDate,
            projectName: project.name
          });
        }
      }

      if (type === "PDF") {
        await saveAsPDF(report, filename, { workspaceDisplayName });
      } else if (type === "XLSX") {
        const csvData = createTableExportBasis(
          fetchedUsers,
          projects,
          filteredEntries,
          testcusts,
          features,
          isAdmin
        );
        await saveAsXLSX(csvData, filename);
      }
      updateSnackbar({
        message: `${t("pages.reports.projectHours.exportDone")}! 📈`,
        alert: { severity: "success" }
      });
    } catch (e) {
      updateSnackbar({
        open: true,
        message: `${t("common.error")}: ${e} 🚨`,
        alert: { severity: "error" }
      });
    } finally {
      await new Promise(resolve => setTimeout(resolve, 2000));
      updateSnackbar({
        open: false
      });
    }
  };

  const hasFilters = filters?.projects?.length > 0;

  return (
    <Card {...rest}>
      <CardHeader
        title={title || ""}
        action={
          <ActionMenu
            onExportXLSX={() => handleExport("XLSX")}
            onExportPDF={() => handleExport("PDF")}
            onFilter={onFilterOpen}
            hasFilters={hasFilters}
          />
        }
      />

      <Divider />

      <TableContainer>
        <Table aria-label={t("common.hoursByProject")}>
          <TableHead>
            <TableRow>
              {columns.expand && (
                <TableCell style={{ maxWidth: 80, width: 80 }} />
              )}

              {columns.avatar && (
                <TableCell style={{ maxWidth: 80, width: 80 }} />
              )}

              <TableCell sortDirection={orderBy === "name" ? orderDir : false}>
                <TableSortLabel
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? orderDir : "asc"}
                  onClick={e => handleOnSort(e, "name")}
                >
                  {t("common.name")}
                </TableSortLabel>
              </TableCell>

              {columns.label && (
                <TableCell
                  sortDirection={orderBy === "label" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "label"}
                    direction={orderBy === "label" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "label")}
                  >
                    {t("common.type")}
                  </TableSortLabel>
                </TableCell>
              )}

              {columns.weekDistribution && (
                <TableCell align="right">
                  <WeekDistribution weeks={weekDistribution || []} />
                </TableCell>
              )}

              {columns.hours && (
                <TableCell
                  align="right"
                  sortDirection={orderBy === "hours" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "hours"}
                    direction={orderBy === "hours" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "hours")}
                  >
                    {t("common.logged")}
                  </TableSortLabel>
                </TableCell>
              )}

              {columns.relativeShare && (
                <TableCell align="right" style={{ minWidth: 120 }} />
              )}

              {columns.relative && (
                <TableCell align="right" style={{ minWidth: 120 }} />
              )}

              {columns.billable && (
                <TableCell align="right">{t("common.billable")}</TableCell>
              )}
              {columns.sync && <TableCell align="right" />}
            </TableRow>
          </TableHead>

          {!loading && (
            <TableBody>
              {pageList.map(data => (
                <ProjectItem
                  key={data.project.id}
                  columns={columns}
                  data={data}
                  weekNumbers={weekNumbers}
                  usersById={usersById}
                  onSyncProject={onSyncProject}
                />
              ))}
              {show.weekDistributionSum && (
                <WeekDistributionSum
                  columns={columns}
                  weekNumbers={weekNumbers}
                  sortedProjectsList={sortedProjectsList}
                />
              )}
            </TableBody>
          )}

          {!loading && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelRowsPerPage={t("common.rowsPerPage")}
                  rowsPerPageOptions={[
                    10,
                    25,
                    50,
                    { label: t("common.all"), value: -1 }
                  ]}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": t("common.rowsPerPage") || "" },
                    native: true
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}

          {loading && (
            <TableLoader
              cols={[
                "rect",
                "square",
                "text",
                "text",
                "text",
                "textRight",
                "textRight"
              ]}
            />
          )}
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ProjectsTable;
