import {
  endOfWeek as endOfWeekFn,
  format as formatFn,
  getWeek as getWeekFn,
  getISOWeek as getISOWeekFn,
  setWeek as setWeekFn,
  startOfWeek as startOfWeekFn,
  eachWeekOfInterval as eachWeekOfIntervalFn,
  isSameWeek as isSameWeekFn,
  setYear,
  setMonth,
  endOfMonth
} from "date-fns";
import { nb, enUS } from "date-fns/locale";
import i18n from "./i18n";

export { parse, getDay } from "date-fns";

/*
SUGGESTED WRAPPERS BY DATE-FNS
 *differenceInCalendarWeeks
eachWeekOfInterval
endOfWeek
format
formatDistance
formatDistanceStrict
formatRelative
getWeek
getWeekOfMonth
getWeeksInMonth
getWeekYear
isMatch
isSameWeek
isThisWeek
lastDayOfWeek
parse
setDay
setWeek
setWeekYear
startOfWeek
startOfWeekYear
 * */

//Read this: https://en.wikipedia.org/wiki/ISO_week_date

let locale = i18n.language === "nb-NO" || i18n.language === "nb" ? nb : enUS;
i18n.on("languageChanged", (lng: string) => {
  locale = lng === "nb-NO" || lng === "nb" ? nb : enUS;
});

const weekStartsOn = 1;
export const getWeek: typeof getWeekFn = (
  date,
  options = {
    weekStartsOn,
    locale
  }
) => getWeekFn(date, options);

export const setWeek: typeof setWeekFn = (
  date,
  week,
  options = {
    locale,
    weekStartsOn
  }
) => setWeekFn(date, week, options);

export const endOfWeek: typeof endOfWeekFn = (
  date,
  options = {
    weekStartsOn
  }
) => endOfWeekFn(date, options);

export const startOfWeek: typeof startOfWeekFn = (
  date,
  options = {
    weekStartsOn
  }
) => startOfWeekFn(date, options);

export const isSameWeek: typeof isSameWeekFn = (
  dateLeft,
  dateRight,
  options = {
    locale
  }
) => isSameWeekFn(dateLeft, dateRight, options);

export const eachWeekOfInterval: typeof eachWeekOfIntervalFn = (
  interval,
  options = {
    locale,
    weekStartsOn
  }
) => eachWeekOfIntervalFn(interval, options);

export const format: typeof formatFn = (
  date,
  format,
  options = {
    locale
  }
) => formatFn(date, format, options);

export type MonthNumbers = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
export const getISOWeekNumbersInMonth = (year: number, month: MonthNumbers) => {
  let start = new Date();
  start = setYear(start, year);
  start = setMonth(start, month - 1);

  const ws = eachWeekOfInterval({
    start,
    end: endOfMonth(start)
  });
  return ws.map(w => getISOWeekFn(w));
};
