import React from "react";
import Popover from "@superprofit/core-react-components/atoms/Popover";
import Button from "@superprofit/core-react-components/atoms/Button";
import { ExitToAppOutlined } from "@material-ui/icons";
import { useAuth } from "../../../hooks/useAuth";

export default props => {
  const { signOut } = useAuth();
  const handleLogout = async () => {
    await signOut();
  };
  return (
    <Popover
      {...props}
      BackdropProps={{ invisible: false }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
    >
      <Button startIcon={<ExitToAppOutlined />} onClick={handleLogout}>
        Logout
      </Button>
    </Popover>
  );
};
