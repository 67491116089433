import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import Customer from "../models/Customer";

const useCustomers = () => {
  const workspace = useWorkspace();
  const get = () => Customer.list(workspace);
  const key = [workspace, Customer.collectionName];
  // 5 minutes stale time for customers
  const query = useQuery(key, get, { staleTime: 1000 * 50 * 5});
  return query;
};

export default useCustomers;
