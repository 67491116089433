import { UPDATE_SNACKBAR } from "./constants";
import { SnackbarAction, SnackbarState } from "./types";
import { createReducer } from "typesafe-actions";

const initialState: SnackbarState = {
  text: "",
  visible: false
};

const reducer = createReducer<SnackbarState, SnackbarAction>(
  initialState
).handleType(UPDATE_SNACKBAR, (state, action) => ({
  ...state,
  ...action.payload.updates
}));
export default reducer;
