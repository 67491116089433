import User, { IUser, IUserCreatePayload } from "../models/User";
import useUser from "./useUser";
import useTimetUser, { getKey } from "./useTimetUser";
import { getKey as getUsersKey } from "./useUsers";
import useWorkspace from "./useWorkspace";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import TimetUser from "../models/TimetUser";

interface ITimetUserMutation {
  id: string | null;
}
const useTimetUserMutation = ({ id }: ITimetUserMutation = { id: null }) => {
  const { data } = useTimetUser({ id });
  const workspace = useWorkspace();
  const loggedInUser = useUser();
  const user = (data as User) || null;
  const queryClient = useQueryClient();
  const key = getKey(workspace, id);
  const usersKey = getUsersKey(workspace);
  const save = (
    payload:
      | IUserCreatePayload
      | Partial<IUser>
      | {
          updates: Partial<IUser>;
          genericUser: TimetUser;
          initiatorEmail: string;
        }
  ) => {
    if ("genericUser" in payload && "updates" in payload) {
      // Update on behalf of another user
      return TimetUser.update(
        workspace,
        payload.initiatorEmail,
        payload.genericUser as TimetUser,
        payload.updates
      );
    }
    let updates = payload as Partial<IUser>;
    if (!user?.id) {
      return TimetUser.create(
        workspace,
        loggedInUser.email,
        updates as IUserCreatePayload
      );
    }
    if (user) return TimetUser.update(workspace, user.email, user, updates);
    else throw new Error("No user to update");
  };

  return useMutation(save, {
    onSuccess: (data: User, variables, context) => {},
    onMutate: async updates => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(key);

      // Snapshot the previous value
      const previous = queryClient.getQueryData(key);

      // Optimistically update to the new value
      queryClient.setQueryData(key, (old: any) => ({ ...old, ...updates }));

      // Return a context object with the snapshotted value
      return { previous };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newTodo, context: any) => {
      console.error(err);
      queryClient.setQueryData(key, context.previous);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(key).catch(console.error);
      queryClient.invalidateQueries(usersKey).catch(console.error);
    }
  });
};

export default useTimetUserMutation;
