import styled, { css } from "styled-components";
import { MenuItem } from "@material-ui/core";
import theme from "styled-theming";

const colors = theme.variants("mode", "color", {
  default: {},
  alert: {
    light: css`
      background: ${props => props.theme.palette.error.main};
      color: ${props => props.theme.palette.common.white};

      &:hover {
        background: ${props => props.theme.palette.error.dark};
      }
    `,
    dark: css``
  }
});

export const StyledMenuItem = styled(MenuItem)`
  ${colors};

  > .MuiSvgIcon-root {
    margin-right: ${props => props.theme.spacing(2)}px;
  }
`;
