import styled from 'styled-components';
import Card from '@superprofit/core-react-components/atoms/Card';

export const DashboardCard = styled(Card)`
  position: relative;
  pointer-events: all;
`;

export const ChartWrapper = styled.div`
  height: 25rem;
  padding-bottom: 3rem;
`;

export const EmptyState = styled.div`
  width: 100%;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;