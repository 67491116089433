import { actionTypes } from "../../reducer";
import retrieveSubscriptionInformation from "./retrieveSubscriptionInformation";

const handlePaymentThatRequiresCustomerAction = async (args) => {
  const {
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
    stripe,
    dispatch,
  } = args;
  if (subscription && subscription.status === "active") {
    // Subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  }
  // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
  // If it's a retry, the payment intent will be on the invoice itself.
  let paymentIntent = invoice
    ? invoice.payment_intent
    : subscription.latest_invoice.payment_intent;

  if (
    paymentIntent.status === "requires_action" ||
    (isRetry === true && paymentIntent.status === "requires_payment_method")
  ) {
    console.log("[Payment requires action]");
    const result = await stripe.confirmCardPayment(
      paymentIntent.client_secret,
      {
        payment_method: paymentMethodId,
      }
    );
    if (result.error) {
      console.log("[Result error]");
      // Start code flow to handle updating the payment details.
      // Display error message in your UI.
      // The card was declined (i.e. insufficient funds, card has expired, etc).
      dispatch({
        type: actionTypes.setCreatedSubscriptionStatus,
        payload: {
          message:
            typeof result.error === "string"
              ? result.error
              : result.error.toString(),
          status: "error",
        },
      });
    } else if (result.paymentIntent.status === "succeeded") {
      console.log("[Payment succeeded]");
      const {subscription: updatedSub} = await retrieveSubscriptionInformation({
        ...args,
        subscriptionId: subscription.id,
        token: args.firebaseIdToken,
      });
      console.log("[Updated sub status", updatedSub?.status);

      // Show a success message to your customer.
      // There's a risk of the customer closing the window before the callback.
      // We recommend setting up webhook endpoints later in this guide.
      return {
        priceId,
        subscription: {
          ...subscription,
          ...updatedSub,
        },
        invoice,
        paymentMethodId,
      };
    }
  } else {
    console.log('[Payment intent status] ', result.paymentIntent.status)
    // No customer action needed.
    return { subscription, priceId, paymentMethodId };
  }
};

export default handlePaymentThatRequiresCustomerAction;
