import { moment } from "@superprofit/time-util";
import { SET_PERIOD } from "./constants";

const initialState = {
  year: moment().year(),
  month: moment().month() + 1
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PERIOD:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};
