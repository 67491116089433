import {
  HIDE_DIALOG,
  SHOW_DIALOG,
  UPDATE_DIALOG_DATA, WATCH_SAVE, WATCH_GET_USERS, SET_IS_CLOSABLE, SET_SHOW_CONFIRMATION, SET_SAVING, SET_ERROR
} from "./constants";
import { action } from "typesafe-actions";
import UserGroup, {IUserGroup} from "../../../../../models/UserGroup";

export const hideDialog = () => action(HIDE_DIALOG);
export const setSaving = (saving: boolean) => action(SET_SAVING, { saving });
export const setError = (error: string) => action(SET_ERROR, { error });
export const setIsClosable = (isClosable: boolean) => action(SET_IS_CLOSABLE, { isClosable });
export const setShowConfirmation = (show: boolean) => action(SET_SHOW_CONFIRMATION, { show });
export const showDialog = (userGroup: UserGroup | null = null) => action(SHOW_DIALOG, { userGroup});
export const updateDialogData = (updates: Partial<IUserGroup>) => action(UPDATE_DIALOG_DATA, {updates});
export const watchSave = () => action(WATCH_SAVE);
export const watchGetUsers = () => action(WATCH_GET_USERS);



