import { actionTypes } from "../../reducer";

export default ({ dispatch, result }) => {
  // Payment was successful.
  if (result.subscription.status === "active") {
    console.log('[Dispatch subscribe success]')
    dispatch({
      type: actionTypes.setCreatedSubscriptionStatus,
      payload: {
        createdSubscriptionStatus: {
          message: "Created subscription successfully!",
          status: "success",
        },
      },
    });
    // Change your UI to show a success message to your customer.
    // Call your backend to grant access to your service based on
    // `result.subscription.items.data[0].price.product` the customer subscribed to.
  } else {
    console.log('[Dispatch subscribe error]')
    dispatch({
      type: actionTypes.setCreatedSubscriptionStatus,
      payload: {
        message: "Subscription not active. Please contact support.",
        status: "error",
      },
    });
  }

  dispatch({
    type: actionTypes.toggleCreatingSubscription,
  });
};
