import styled from "styled-components";
import { Grid, Card, Button } from "@superprofit/core-react-components/atoms";

export const ContainerGrid = styled(Grid)`
  padding: 0 2rem 2rem 2rem;
  max-width: 50rem;
  margin: auto;
`;
export const WorkspaceButton = styled(Button)`
  padding: 1rem;
  justify-content: space-between;
`;

export const AnotherWorkspaceCard = styled(Card)`
  background-color: ${props => props.theme.palette.grey.main};
  padding: 1rem;
`;

export const ImgGrid = styled(Grid)`
  background-color: ${props => props.theme.palette.primary.main};
  margin-bottom: 2rem;

  img {
    max-width: 10rem;
  }
`;
export const LinkWrapper = styled.div`
  ${({ disabled }) => disabled && "pointer-events: none; opacity: 0.5;"}
`;
