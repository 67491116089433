import React from "react";
import { Text, View, Font } from "@react-pdf/renderer";
import { colors } from "@superprofit/core-react-components/themes/light/colors";
import robotoRegular from "./font/Roboto-Regular.ttf";
import robotoBold from "./font/Roboto-Bold.ttf";

Font.register({
  family: "Roboto",
  fonts: [{ src: robotoRegular }, { src: robotoBold, fontWeight: 700 }]
});

export class ReportTitle {
  title = "";
  titleStyles = {
    fontFamily: "Roboto",
    fontSize: 18,
    color: "#3f51b5",
    marginTop: -16,
    marginBottom: 16
  };

  constructor(title) {
    this.title = title;
  }

  renderAsPDF = () => {
    return (
      <View style={this.titleStyles} wrap={false}>
        <Text>{this.title}</Text>
      </View>
    );
  };

  renderAsXLSX = () => {
    return [[this.title]];
  };
}

export class ReportSubtitle {
  subtitle = "";
  subtitleStyles = {
    fontFamily: "Roboto",
    fontSize: 14,
    color: "#000000",
    marginBottom: 5
  };

  constructor(subtitle) {
    this.subtitle = subtitle;
  }

  renderAsPDF = () => {
    return (
      <View style={this.subtitleStyles} wrap={false}>
        <Text>{this.subtitle}</Text>
      </View>
    );
  };

  renderAsXLSX = () => {
    return [[this.subtitle]];
  };
}

export class ReportTableCell {
  data;
  contentStyles;
  cellStyles = {
    fontFamily: "Roboto",
    display: "table-cell",
    flex: 1,
    fontSize: 12
  };

  constructor(data, contentStyles) {
    this.data = data || "";
    this.contentStyles = contentStyles || {
      textAlign: typeof data === "number" ? "right" : "left"
    };
    this.cellStyles = {
      textAlign: typeof data === "number" ? "right" : "left",
      ...this.cellStyles
    };
  }

  renderAsPDF = () => {
    return (
      <View style={{ ...this.cellStyles, ...this.contentStyles }}>
        <Text style={{ marginTop: 5, fontSize: 10, ...this.contentStyles }}>
          {this.data}
        </Text>
      </View>
    );
  };

  renderAsXLSX = () => {
    return this.data;
  };
}

export class ReportTableRow {
  cells;
  rowStyle;
  styles = {
    fontFamily: "Roboto",
    display: "table-row",
    flexDirection: "row"
  };

  constructor(cells, rowStyle = {}) {
    this.cells = cells;
    this.rowStyle = rowStyle;
  }

  renderAsPDF = () => {
    return (
      <View style={{ ...this.styles, ...this.rowStyle }} wrap={false}>
        {this.cells.map(cell => cell.renderAsPDF())}
      </View>
    );
  };

  renderAsXLSX = () => {
    return this.cells.map((c, idx) => c.renderAsXLSX(idx));
  };
}

export class ReportTableHeader {
  row;
  styles = {
    fontFamily: "Roboto",
    backgroundColor: "#3f51b5",
    color: "white",
    fontSize: 10,
    padding: 5,
    display: "table-header-group"
  };

  constructor(cells) {
    this.row = new ReportTableRow(
      cells.map(
        c =>
          new ReportTableCell(c.data, {
            fontSize: 10,
            fontWeight: 700,
            ...c.contentStyles
          })
      ),
      {
        fontSize: 10
      }
    );
  }

  renderAsPDF = () => {
    return (
      <View style={this.styles} wrap={false}>
        {this.row.renderAsPDF()}
      </View>
    );
  };

  renderAsXLSX = () => {
    return this.row.renderAsXLSX();
  };
}

export class ReportTableFooter {
  row;
  styles = {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 10,
    display: "table-footer-group",
    borderTopColor: "#000000",
    borderTopWidth: 2,
    borderTopStyle: "solid",
    borderBottomColor: "#000000",
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    marginTop: 5,
    paddingBottom: 5,
    marginBottom: 16
  };

  constructor(cells) {
    this.row = new ReportTableRow(
      cells.map(
        c =>
          new ReportTableCell(c.data, {
            fontWeight: 700,
            fontSize: 10,
            ...c.contentStyles
          })
      ),
      {
        fontWeight: 700,
        fontSize: 10
      }
    );
  }

  renderAsPDF = () => {
    return (
      <View style={this.styles} wrap={false}>
        {this.row.renderAsPDF()}
      </View>
    );
  };

  renderAsXLSX = () => {
    return this.row.renderAsXLSX();
  };
}

export class ReportTable {
  rows;
  header;
  footer;
  styles = {
    width: "100%",
    // width: "auto",
    display: "table",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0
  };

  constructor(
    header = new ReportTableHeader([]),
    rows = [],
    footer = new ReportTableFooter([])
  ) {
    this.rows = rows;
    this.header = header;
    this.footer = footer;
  }

  renderAsPDF = () => {
    return (
      <View style={this.styles}>
        {this.header.renderAsPDF()}
        <View style={{ display: "table-row-group" }}>
          {this.rows.map(r => {
            if (!r.renderAsPDF) {
              return;
            }
            return r.renderAsPDF();
          })}
        </View>
        {this.footer.renderAsPDF()}
      </View>
    );
  };

  renderAsXLSX = () => {
    return [this.header.renderAsXLSX()]
      .concat(
        this.rows.reduce(
          (prev, next, idx) =>
            prev.concat([next?.renderAsXLSX ? next.renderAsXLSX(idx) : []]),
          []
        )
      )
      .concat([this.footer.renderAsXLSX()]);
  };
}

export class ReportSection {
  children;

  constructor(children = []) {
    this.children = children;
  }

  renderAsPDF = sectionIdx => {
    return (
      <View style={{ paddingBottom: 50 }} break={sectionIdx !== 0}>
        {this.children.map(c => c.renderAsPDF())}
      </View>
    );
  };

  renderAsXLSX = () => {
    return this.children.reduce(
      (prev, next, idx) => prev.concat(next.renderAsXLSX(idx)),
      []
    );
  };

  push(newChildren) {
    this.children = this.children.concat(newChildren);
  }
}

export class Report {
  elements;

  constructor(elements = []) {
    this.elements = elements;
  }

  renderAsPDF = () => {
    return (
      <View style={{ padding: 10, margin: 10 }}>
        {this.elements.map((d, idx) => d.renderAsPDF(idx))}
      </View>
    );
  };

  renderAsXLSX = () => {
    return this.elements.reduce(
      (prev, next, idx) => prev.concat(next.renderAsXLSX(idx)),
      []
    );
  };

  push(newElements) {
    this.elements = this.elements.concat(newElements);
  }
}
