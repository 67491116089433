import ProgressBar from "../../../../../components/atoms/ProgressBar";
import React, { useMemo } from "react";
import Hint from "../../../../../components/atoms/Hint";
import { StyledBudget, StyledBudgetStats } from "./Planned.styles";
import Important from "../../../../../components/atoms/Important";
import Skeleton from "@material-ui/lab/Skeleton";
import NumberFormat from "react-number-format";
import DurationFormat from "../../../../../components/atoms/DurationFormat";
import { moment } from "@superprofit/time-util";
import { useTranslation } from "react-i18next";

const getPlanned = (user, projects, year, month) => {
  let money = 0;
  let hours = 0;
  if (projects)
    projects.forEach(p => {
      hours += p.getPlannedHoursByMonthByUser(year, month, user?.id);
      money += p.getPlannedMoneyByMonthByUser(year, month, user?.id);
    });

  return { money, hours };
};

export default ({
  loading,
  stats = [],
  year,
  month,
  projects,
  user,
  ...rest
}) => {
  const actual = useMemo(() => {
    return stats.reduce((prev, next) => prev + next.total, 0);
  }, [stats]);
  const { t } = useTranslation();

  const modifier =
    moment()
      .year(year)
      .month(month - 1)
      .daysInMonth() / 7;

  const { hours: planned } = getPlanned(user, projects, year, month);
  const progress = planned === 0 ? 100 : ((actual || 0) / planned) * 100;
  const remaining = Math.max(0, planned - (actual || 0));
  const remainingPct = Math.max(0, 100 - progress);

  return (
    <StyledBudget>
      <div>
        <Hint>{t("common.plannedVsActual")}</Hint>
        {loading ? (
          <Skeleton height={20} width="100%" />
        ) : (
          <ProgressBar
            align="left"
            progress={Math.min(100, progress)}
            color="primary"
          />
        )}
      </div>

      <StyledBudgetStats>
        <li>
          <Hint>{t("common.progress")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <NumberFormat
                displayType="text"
                thousandSeparator
                isNumericString
                decimalScale={2}
                value={progress}
                suffix="%"
              />
            </Important>
          )}
        </li>
        <li>
          <Hint>{t("common.remaining")} %</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <NumberFormat
                displayType="text"
                thousandSeparator
                isNumericString
                decimalScale={2}
                value={remainingPct}
                suffix="%"
              />
            </Important>
          )}
        </li>
        <li>
          <Hint>{t("common.remaining")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <DurationFormat value={remaining} />
            </Important>
          )}
        </li>
      </StyledBudgetStats>
    </StyledBudget>
  );
};
