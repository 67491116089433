import {
  SET_HOURS_BY_USER,
  SET_LOADING,
  WATCH_REFRESH_HOURS_BY_USER
} from "./constants";

export const setHoursByUser = hoursByUser => ({
  type: SET_HOURS_BY_USER,
  payload: { hoursByUser }
});

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const watchRefreshHoursByProject = () => ({
  type: WATCH_REFRESH_HOURS_BY_USER
});
