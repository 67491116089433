import {
  HIDE_DASHBOARD_SETTINGS_DIALOG,
  SHOW_DASHBOARD_SETTINGS_DIALOG,
  UPDATE_DASHBOARD_SETTINGS_DIALOG_DATA
} from "./constants";

export const showSettingsDialog = settings => ({
  type: SHOW_DASHBOARD_SETTINGS_DIALOG,
  payload: { settings }
});

export const hideSettingsDialog = () => ({
  type: HIDE_DASHBOARD_SETTINGS_DIALOG,
  payload: {}
});

export const updateSettingsDialogData = updates => ({
  type: UPDATE_DASHBOARD_SETTINGS_DIALOG_DATA,
  payload: { updates }
});
