export const filterStatsByUsers = (stats, users) => {
  if (users.length === 0) return stats;

  const shownUsersById = users.reduce(
    (prev, next) => ({
      ...prev,
      [next]: true
    }),
    {}
  );

  return stats.filter(s => !!shownUsersById[s.user]);
};

export const filterStatsByProjects = (stats, projects) => {
  if (projects.length === 0) return stats;

  const shownProjectsById = projects.reduce(
    (prev, next) => ({
      ...prev,
      [next]: true
    }),
    {}
  );

  return stats.filter(s => !!shownProjectsById[s.project]);
};

export const filterStatsByType = (stats, type) => {
  if (!type || type === "All") return stats;

  const billable = type === "Billable";
  return stats.filter(s => s.billable === billable);
};

export const filterStatsByCustomers = (stats, projectsById, customers) => {
  if (customers.length === 0) return stats;
  let filteredStats = stats.slice();

  // Filter by customers
  if (customers.length) {
    const shownCustomersById = customers.reduce(
      (prev, next) => ({
        ...prev,
        [next]: true
      }),
      {}
    );
    filteredStats = stats.filter(
      s => !!shownCustomersById[projectsById[s.project].customer]
    );
  }
  return filteredStats;
};

export const filterStats = (
  stats,
  allProjects,
  type,
  shownProjects = [],
  shownUsers = [],
  shownCustomers = []
) => {
  if (!allProjects) return stats;
  const projectsById = allProjects.reduce((prev, next) => {
    return {
      ...prev,
      [next.id]: next
    };
  }, {});

  let filteredStats = filterStatsByType(stats, type || null);
  filteredStats = filterStatsByCustomers(
    filteredStats,
    projectsById,
    shownCustomers
  );
  filteredStats = filterStatsByProjects(filteredStats, shownProjects);
  filteredStats = filterStatsByUsers(filteredStats, shownUsers);

  return filteredStats;
};
