import { useMutation, useQueryClient } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import Entry, { IEntry } from "../models/Entry";
import { getKey } from "./useUserTimesheet";
import useUser from "./useUser";
import { Timet } from "@superprofit/timet-types";

export type EntryPayload = Pick<
  IEntry,
  "project" | "hours" | "user" | "dayOfYear" | "year"
> & { entry?: Entry };
type UpsertPayload = EntryPayload[];

export const isApiEnabled = (payload: Array<{ user: string }>) => {
  return true;
};

const useUserTimesheetMutation = () => {
  const workspace = useWorkspace();
  const queryClient = useQueryClient();
  const user = useUser();

  const upsert = (payload: UpsertPayload) => {
    if (isApiEnabled(payload)) {
      console.log("Upserting with API");
      const upsertPayload: Timet.Api.Entries.UpsertManyCallablePayload = {
        workspaceId: workspace,
        entries: payload.map(p => ({
          project: p.project,
          hours: p.hours,
          user: p.user,
          date: new Date(p.year, 0, 1 + p.dayOfYear).toISOString().split("T")[0]
        }))
      };
      return Entry.upsertApi(upsertPayload);
    }

    const originalEntries: Entry[] = payload
      .map(p => p.entry)
      .filter((e): e is Entry => e !== undefined);
    return Entry.batchUpsert(workspace, user.email, originalEntries, payload);
  };

  return useMutation(upsert, {
    onSuccess: (data: Entry[], variables, context) => {
      const uniqueKeys = new Map<string, ReturnType<typeof getKey>>();
      const entries = new Map<string, Entry>();

      /*
      // We can invalidate, but the updating of collections is async so we probaably want a
      // setTimeout here. Or adjust staleTime or have listeners in the respective hooks for xstats
        void queryClient.invalidateQueries({
          predicate: query =>
            query.queryKey.includes(
              XstatsUserProjectHoursByWeek.collectionName
            ) ||
            query.queryKey.includes(XstatsProjectHoursByWeek.collectionName) ||
            query.queryKey.includes(
              XstatsUserProjectHoursByMonth.collectionName
            )
        });*/

      queryClient
        .invalidateQueries({
          predicate: query => query.queryKey.includes(Entry.collectionName)
        })
        .catch(console.error);

      for (let entry of data) {
        const k = getKey(workspace, entry.user, entry.year, entry.week);
        uniqueKeys.set(k.toString(), k);
        entries.set(entry.id, entry);
      }

      for (let key of Array.from(uniqueKeys.values())) {
        queryClient.setQueryData(key, (prevEntries: Entry[] = []) => {
          const next = prevEntries.map(e => {
            let ret = entries.get(e.id);
            if (!ret) {
              return e;
            }
            entries.delete(e.id);
            return ret;
          });
          return next.concat(Array.from(entries.values()));
        });
      }
    }
  });
};

export default useUserTimesheetMutation;
