import User, { IUser } from "../models/User";
import useUser from "./useUser";
import useUsers from "./useUsers";
import useWorkspace from "./useWorkspace";
import { useMutation } from "@tanstack/react-query";
import TimetUser from "../models/TimetUser";

const useTimetUserUpdateMutation = () => {
  const workspace = useWorkspace();
  const loggedInUser = useUser();
  const { data: allUsers = [] } = useUsers();
  const update = (payload: Partial<IUser> & { id: string }) => {
    const user = allUsers.find((u: User) => u.id === payload.id);
    if (user)
      return TimetUser.update(workspace, loggedInUser.email, user, payload);
    else throw new Error("No user to update");
  };

  return useMutation(update);
};

export default useTimetUserUpdateMutation;
