import useCompanySettings from "./useCompanySettings";
import { CompanySettingGeneric } from "../models/CompanySetting";

const useProjectSalaryRateSettings = () => {
  const { data: settings, ...rest } = useCompanySettings();

  let projectSalaryRateSettings: CompanySettingGeneric | undefined;

  if (settings?.projectSalaryRateSettings instanceof CompanySettingGeneric) {
    projectSalaryRateSettings = settings.projectSalaryRateSettings;
  }
  return {
    data: projectSalaryRateSettings,
    projectSalaryRateSettings,
    ...rest
  };
};

export default useProjectSalaryRateSettings;
