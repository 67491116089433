import { SET_GOALS } from "./constants";
import { GET, GET_SUCCESS } from "../../../api/companySettings/constants";

const initialState = {
  list: [],
  loading: false
};

// Use sagas for this instead
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GOALS:
      return {
        ...state,
        list: payload.goals
      };
    case GET:
      return {
        ...state,
        loading: true
      };

    case GET_SUCCESS:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
