export const GET = "api/timesheetComments/GET";
export const GET_SUCCESS = "api/timesheetComments/GET_SUCCESS";
export const GET_FAILURE = "api/timesheetComments/GET_FAILED";

export const SAVE = "api/timesheetComments/SAVE";
export const SAVE_SUCCESS = "api/timesheetComments/SAVE_SUCCESS";
export const SAVE_FAILURE = "api/timesheetComments/SAVE_FAILED";

export const REMOVE = "api/timesheetComments/REMOVE";
export const REMOVE_SUCCESS = "api/timesheetComments/REMOVE_SUCCESS";
export const REMOVE_FAILURE = "api/timesheetComments/REMOVE_FAILED";

export const WATCH_GET = "api/timesheetComments/WATCH_GET";
export const WATCH_SAVE = "api/timesheetComments/WATCH_SAVE";
export const WATCH_REMOVE = "api/timesheetComments/WATCH_REMOVE";
