import { useCompanyFeatures } from "../../../hooks/useFeatures";

import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Card from "../../../components/atoms/Card";
import CardHeader from "../../../components/atoms/CardHeader";
import Divider from "../../../components/atoms/Divider";
import CardContent from "../../../components/atoms/CardContent";
import TextField from "../../../components/atoms/TextField";
import Button from "../../../components/atoms/Button";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useCompanySettingMutation from "../../../hooks/useCompanySettingMutation";
import useCompanySettings from "../../../hooks/useCompanySettings";
import CompanySettingGeneric from "../../../models/CompanySettingGeneric";
import Grid from "../../../components/atoms/Grid";
import Typography from "../../../components/atoms/Typography";
import Project from "../../../models/Project";
import useProjectSalarySettings from "../../../hooks/useProjectSalarySettings";
import useProjectSalaryRateSettings from "../../../hooks/useProjectSalarySettings";
import useGlobalSnackbar from "../../../hooks/useGlobalSnackbar";
import { promiseDelay } from "../../../helpers";
import useUnarchivedProjects from "../../../hooks/useUnarchivedProjects";
import Alert from "../../../components/atoms/Alert";
import useFixProjectsDialog from "./projectSalaryRate/fixProjectsDialog/useFixProjectsDialog";
import FixProjectsDialog from "./projectSalaryRate/FixProjectsDialog";
import AsyncQueue, { AsyncTask } from "../../../utils/AsyncQueue";
import useProjectUpdateMutation from "../../../hooks/useProjectUpdateMutation";

interface IFormInput {
  globalProvisionOfProjectBillableRate: number | null;
}

const ProjectSalaryRate = () => {
  const { update: updateSnackbar } = useGlobalSnackbar();
  const { t } = useTranslation();
  const { features } = useCompanyFeatures();
  const { data: projects } = useUnarchivedProjects();
  const mutation = useCompanySettingMutation();
  const { mutateAsync: updateProject } = useProjectUpdateMutation();
  const { show } = useFixProjectsDialog();
  const defaultSetting = useMemo(
    () =>
      new CompanySettingGeneric({
        id: "projectSalaryRateSettings",
        value: {},
        updatedAt: null,
        updatedBy: null
      }),
    []
  );
  let {
    data: projectSalaryRateSettings = defaultSetting,
    isInitialLoading,
    isSuccess
  } = useProjectSalaryRateSettings();

  const schema = useMemo(
    () =>
      yup.object().shape({
        globalProvisionOfProjectBillableRate: yup
          .number()
          .nullable()
          .transform((value, originalValue) => {
            if (
              typeof originalValue === "string" &&
              originalValue.trim() === ""
            )
              return null;
            return value;
          })
          .min(1, "Percentage must be between 0 and 100")
          .max(100, "Percentage must be between 0 and 100")
          .notRequired()
      }),
    [t]
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset
  } = useForm<IFormInput>({
    resolver: yupResolver(schema)
  });

  const projectsDiffProvision = useMemo(() => {
    if (!projects || !projectSalaryRateSettings?.value) {
      return [];
    }
    return projects.filter((project: Project) => {
      return (
        project.salaryType === "provision" &&
        projectSalaryRateSettings?.value
          ?.globalProvisionOfProjectBillableRate !==
          project.salaryProvisionPercentage
      );
    });
  }, [projects, projectSalaryRateSettings]);
  const shouldResetForm = !isInitialLoading && isSuccess;

  useEffect(() => {
    if (shouldResetForm) {
      reset({
        globalProvisionOfProjectBillableRate:
          projectSalaryRateSettings?.value?.globalProvisionOfProjectBillableRate
      });
    }
  }, [shouldResetForm, reset, projectSalaryRateSettings]);
  const onSubmit = async (data: IFormInput) => {
    const cl = projectSalaryRateSettings.clone();
    if (data.globalProvisionOfProjectBillableRate === null) {
      cl.setData({ value: {} });
    } else {
      cl.setData({
        value: {
          globalProvisionOfProjectBillableRate:
            data.globalProvisionOfProjectBillableRate
        }
      });
    }
    await mutation.mutateAsync(cl, {
      onSuccess: async () => {
        updateSnackbar({
          open: true,
          message: `${t("common.saved")}!   🎉`,
          alert: { severity: "success" }
        });
        await promiseDelay(2000);
        updateSnackbar({
          open: false
        });
      },
      onError: async e => {
        updateSnackbar({
          open: true,
          message: `${t("common.error")}! ${e} 🚨 `,
          alert: { severity: "error" }
        });
        await promiseDelay(2000);
        updateSnackbar({
          open: false
        });
      }
    });
  };

  const handleOnConfigureProjects = () => {
    show();
  };

  const fixProjectsWithDifferentProvision = async () => {
    const queue = new AsyncQueue();

    try {
      updateSnackbar({
        open: true,
        message: `${t("common.updating")}... 🛠️`,
        alert: { severity: "info" }
      });
      for (const project of projectsDiffProvision) {
        const fix = async () => {
          const cl = project.clone();
          cl.setData({
            salaryProvisionPercentage:
              projectSalaryRateSettings?.value
                ?.globalProvisionOfProjectBillableRate
          });
          await updateProject(cl);
        };
        queue.enqueue(fix);
      }
      while (queue.taskCount > 0) {
        await promiseDelay(200);
      }

      updateSnackbar({
        open: true,
        message: `${t("common.saved")}!   🎉`,
        alert: { severity: "success" }
      });
      await promiseDelay(2000);
      updateSnackbar({
        open: false
      });
    } catch (e) {
      updateSnackbar({
        open: true,
        message: `${t("common.error")}! ${e} 🚨 `,
        alert: { severity: "error" }
      });
      await promiseDelay(2000);
      updateSnackbar({
        open: false
      });
    }
  };

  if (!features?.projectSalaryRate) {
    return null;
  }

  return (
    <>
      <Card>
        <CardHeader
          title={t("pages.companySettings.projectSalaryRate.title")}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2} justify="flex-end">
            <Grid
              component="form"
              id="globalProvisionOfProjectBillableRateForm"
              onSubmit={handleSubmit(onSubmit)}
              item
              xs={12}
            >
              <TextField
                {...register("globalProvisionOfProjectBillableRate")}
                variant="filled"
                label={t(
                  "pages.companySettings.projectSalaryRate.globalProvisionOfProjectBillableRateLabel"
                )}
                error={!!errors.globalProvisionOfProjectBillableRate}
                helperText={t(
                  "pages.companySettings.projectSalaryRate.globalProvisionOfProjectBillableRateHelperText"
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">
                {t(
                  "pages.companySettings.projectSalaryRate.globalProvisionOfProjectBillableRateDesc"
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Alert
                severity="info"
                action={
                  <Button color="primary" onClick={handleOnConfigureProjects}>
                    {t("common.configure")}
                  </Button>
                }
              >
                Konfigurer prosjekter
                {/*{t("pages.companySettings.nettlonn.projectsMissingConfig", {*/}
                {/*  count: projectsDiffProvision.length*/}
                {/*})}*/}
              </Alert>
            </Grid>

            {projectsDiffProvision.length > 0 && (
              <Grid item xs={12}>
                <Alert
                  severity="warning"
                  action={
                    <Button
                      color="primary"
                      onClick={fixProjectsWithDifferentProvision}
                    >
                      {t("common.fixIt")}
                    </Button>
                  }
                >
                  {t("pages.companySettings.nettlonn.projectsMissingConfig", {
                    count: projectsDiffProvision.length
                  })}
                </Alert>
              </Grid>
            )}
            <Grid item>
              <Button
                form="globalProvisionOfProjectBillableRateForm"
                disabled={
                  !isDirty || !!errors?.globalProvisionOfProjectBillableRate
                }
                color="primary"
                variant="contained"
                type="submit"
              >
                {t("common.save")}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <FixProjectsDialog />
    </>
  );
};

export default ProjectSalaryRate;
