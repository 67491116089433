import React, { useRef } from "react";
import Page from "@superprofit/core-react-components/atoms/Page";
import TimesheetLoader from "./timesheet/TimesheetLoader";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import Snackbar from "../../components/atoms/Snackbar";
import PeriodSelect from "../../components/molecules/PeriodSelect";
import {
  Button,
  Grid,
  Typography
} from "@superprofit/core-react-components/atoms";
import TimesheetList from "./timesheet/TimesheetList";
import SearchField from "@superprofit/core-react-components/atoms/SearchField";
import useSnackbar from "./timesheet/hooks/useSnackbar";
import useUserGroups from "../../hooks/useUserGroups";
import useUser from "../../hooks/useUser";
import useActiveProjects from "../../hooks/useActiveProjects";
import useUserTimesheet from "../../hooks/useUserTimesheet";
import usePeriod from "./timesheet/hooks/usePeriod";
import useUserProfile from "../../hooks/useUserProfile";
import useFilterProjects from "./timesheet/hooks/useFilterProjects";
import FilterIcon from "@material-ui/icons/FilterList";
import Badge from "@superprofit/core-react-components/atoms/Badge";
import FilterDialog from "./timesheet/FilterDialog";
import DurationFormat from "../../components/atoms/DurationFormat";
import ConnectedCurrencyFormat from "../../components/organisms/ConnectedCurrencyFormat";
import useUserStatsByWeek from "../../hooks/useUserStatsByWeek";
import { useFeatures } from "../../helpers";
import { format } from "../../date-fns-wrappers";
import useISOWeekBookendsAsDate from "./timesheet/hooks/useISOWeekBookendsAsDate";
import { DayItem, Days } from "./timesheet/Timesheet.styles";
import { eachDayOfInterval, getDayOfYear } from "date-fns";
import { getYear, isWeekend } from "date-fns/fp";
import { useTranslation } from "react-i18next";
import Tooltip from "../../components/atoms/Tooltip";
import IconButton from "../../components/atoms/IconButton";
import { getPathWithKey } from "../../router/routes";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ViewDayIcon from "@material-ui/icons/ViewDay";
import { useHistory, useLocation } from "react-router-dom";
import { useOverrideTimesheetUser } from "./timesheet/hooks/useOverrideTimesheetUser";

export default () => {
  const { t, i18n } = useTranslation();
  const initalMount = useRef(true);
  const { update: setPeriod, ...period } = usePeriod();
  const { isLoading: profileLoading } = useUserProfile();
  const history = useHistory();
  const location = useLocation();
  const user = useUser();
  const overridenUserEmail = useOverrideTimesheetUser();

  const userEmail = overridenUserEmail || user.email;
  const {
    isLoading: timesheetEntriesLoading,
    data: entries = []
  } = useUserTimesheet({
    id: userEmail,
    ...period
  });
  const { data: projects } = useActiveProjects({
    period
  });
  const {
    data: filteredProjects,
    isLoading: projectsLoading,
    update: updateFilter,
    toggleModal,
    onlyShowProjectsWith,
    showHiddenProjects
  } = useFilterProjects();

  const { isLoading: userGroupsLoading } = useUserGroups({
    ids: [userEmail]
  });

  const [startOfWeekDate, endOfWeekDate] = useISOWeekBookendsAsDate(period);
  const datesBetween = eachDayOfInterval({
    start: startOfWeekDate,
    end: endOfWeekDate
  });

  const isLoading =
    timesheetEntriesLoading ||
    projectsLoading ||
    profileLoading ||
    userGroupsLoading;

  const handleOnChangePeriod = ({
    year,
    week
  }: {
    year?: number;
    week?: number;
  }) => {
    setPeriod({ year, week });
  };

  const handleOnFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFilter({ search: e.target.value });
  };
  const dateRange = `${format(startOfWeekDate, "MMM dd")} - ${format(
    endOfWeekDate,
    "MMM dd"
  )}`;
  // ugly empty state
  if (!isLoading && projects?.length <= 0 && !initalMount.current) {
    return (
      <Grid
        style={{ height: "100%", width: "100%" }}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h1" gutterBottom>
            Welcome, {user.displayName}!
          </Typography>
        </Grid>
        <Grid item>
          <Typography gutterBottom>
            It looks that you have no projects assigned to you.
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Make sure you are assigned to the correct projects by heading over
            to the projects page
          </Typography>
        </Grid>
        <Grid item>
          <Typography gutterBottom>
            ...or ask your project manager to assign a project for you.
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            You will be able to see your projects timesheets on this page once
            you're done!
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Page
      context={userEmail}
      title={
        <Grid container alignItems="baseline" spacing={1}>
          <Grid item xs={12} md>
            <Typography variant="h1">
              {t("common.timesheet") as string}
            </Typography>
            <Typography variant="caption">
              {dateRange.toUpperCase()}
              {" | "}
              <DurationFormat
                component={({ children }: { children: JSX.Element }) => (
                  <Typography variant="caption" children={children} />
                )}
                value={entries.reduce((total, entry) => entry.hours + total, 0)}
              />
            </Typography>
            <Days>
              {datesBetween.map((d, index) => (
                <DayItem isWeekend={isWeekend(d)}>
                  <Typography variant="caption">
                    {index > 0 && " | "}
                    <DurationFormat
                      component={({ children }: { children: JSX.Element }) => (
                        <Typography variant="caption" children={children} />
                      )}
                      value={entries.reduce(
                        (total, entry) =>
                          ((entry.dayOfYear === getDayOfYear(d) &&
                            entry.year === getYear(d) &&
                            entry.hours) ||
                            0) + total,
                        0
                      )}
                    />
                  </Typography>
                </DayItem>
              ))}
            </Days>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={3}
        justify="space-between"
        alignItems="center"
        style={{ maxWidth: 1680 }}
      >
        <Grid item>
          <div>
            <PeriodSelect
              showNavigation
              showMonth={false}
              period={period}
              onChange={handleOnChangePeriod}
            />
          </div>
        </Grid>

        <Grid item style={{ marginRight: "0.5rem" }}>
          <SearchField
            placeholder={t("common.search")}
            onChange={handleOnFilter}
          />
        </Grid>
        <Grid item>
          <Grid container justify="center" spacing={1} alignItems="center">
            <Grid item>
              <Tooltip
                arrow
                title="Beta"
                aria-label={t("pages.users.subscriptionTooltip") && ""}
              >
                <IconButton
                  onClick={() => {
                    history.push(
                      getPathWithKey("timesheetV2") + location.search
                    );
                  }}
                >
                  <ViewComfyIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  history.push(getPathWithKey("timesheet") + location.search);
                }}
              >
                <ViewDayIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Badge
                color="error"
                variant="dot"
                invisible={onlyShowProjectsWith === null && !showHiddenProjects}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={toggleModal}
                  disabled={isLoading}
                  startIcon={<FilterIcon />}
                >
                  Filter
                </Button>
              </Badge>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!isLoading && projects.length > 0 && filteredProjects.length === 0 && (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ padding: 16, marginTop: 32 }}
        >
          <Grid item>
            <Typography variant="h1" gutterBottom>
              Not found
            </Typography>
          </Grid>
          <Grid item>
            <Typography gutterBottom>
              The project seems to not exist is not assigned to you.
            </Typography>
          </Grid>
        </Grid>
      )}

      {isLoading ? <TimesheetLoader /> : <TimesheetList />}

      <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
        {/*<Snackbar*/}
        {/*  anchorOrigin={{*/}
        {/*    vertical: "bottom",*/}
        {/*    horizontal: "center"*/}
        {/*  }}*/}
        {/*  severity={snackbar.severity}*/}
        {/*  open={snackbar.visible}*/}
        {/*  message={snackbar.text}*/}
        {/*/>*/}
      </AppBar>
      <FilterDialog />
    </Page>
  );
};
