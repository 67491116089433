import NumberFormatIntl from "@superprofit/core-react-components/atoms/NumberFormatIntl";
import React from "react";
import { useSelector } from "react-redux";

export default (props) => {
  const settings = useSelector((state) => state.api.companySettings.settings);
  const currency = settings.currency?.value;
  let intlOptions = {};
  if (currency && currency !== "none") {
    intlOptions = {
      style: "currency",
      currency,
    };
  }
  const number = props.number || props.children || 0;
  return <NumberFormatIntl number={number} options={intlOptions} />;
};
