import React, { forwardRef } from "react";
import { StyledCheckbox } from "./checkbox/Checkbox.styles";

export default forwardRef(({ color = "primary", ...rest }, ref) => (
  <StyledCheckbox
    {...rest}
    ref={ref}
    color={color === "alert" ? "primary" : color}
    extcolor={color === "alert" ? "alert" : undefined}
  />
));
