// import {
//   GET,
//   GET_FAILURE,
//   GET_SUCCESS,
//   SAVE,
//   SAVE_FAILURE,
//   SAVE_SUCCESS,
//   DELETE,
//   DELETE_FAILURE,
//   DELETE_SUCCESS,
//   EXIST,
//   EXIST_FAILURE,
//   EXIST_SUCCESS
// } from "./constants";
//
// const initialState = {
//   list: [],
//   loading: false,
//   pagination: {},
//   error: null,
//   exist: {}
// };
//
// export default (state = initialState, { type, payload }) => {
//   switch (type) {
//     case GET:
//       return {
//         ...state,
//         list: [],
//         loading: true
//       };
//     case GET_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         list: payload.list
//       };
//     case GET_FAILURE:
//       return {
//         ...state,
//         loading: false,
//         error: payload.error
//       };
//     case SAVE:
//       return {
//         ...state,
//         loading: true
//       };
//     case SAVE_SUCCESS:
//       return {
//         ...state,
//         loading: false
//       };
//     case SAVE_FAILURE:
//       return {
//         ...state,
//         loading: false
//       };
//     case EXIST:
//       return {
//         ...state,
//         loading: true
//       };
//     case EXIST_SUCCESS:
//       return {
//         ...state,
//         loading: false
//       };
//     case EXIST_FAILURE:
//       return {
//         ...state,
//         loading: false
//       };
//     case DELETE:
//       return {
//         ...state,
//         loading: true
//       };
//     case DELETE_SUCCESS:
//       return {
//         ...state,
//         loading: false
//       };
//     case DELETE_FAILURE:
//       return {
//         ...state,
//         loading: false
//       };
//
//     default:
//       return state;
//   }
// };
import createRedcuer from '../Helpers/moduleFactory/reducer';
import constants from './constants';

export default createRedcuer(constants);
