/* eslint-disable no-use-before-define */

import React, { forwardRef, ForwardRefExoticComponent } from "react";
import Checkbox from "../atoms/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "../atoms/Autocomplete";
import TextField from "../atoms/TextField";
import Avatar from "../atoms/Avatar";
import Chip from "../atoms/Chip";
import User from "../../models/User";
import { AutocompleteGetTagProps } from "@material-ui/lab/Autocomplete/Autocomplete";
import { Controller, ControllerProps } from "react-hook-form";
import Customer from "../../models/Customer";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const renderTags = (
  value: User[],
  getCustomizedTagProps: AutocompleteGetTagProps
) => {
  return value.map((user, index) => (
    <Chip
      color="primary"
      label={user?.displayName || user?.email}
      size="medium"
      avatar={<Avatar src={user?.picture ?? undefined} />}
      {...getCustomizedTagProps({ index })}
    />
  ));
};

interface IAutocompleteCustomer {
  variant?: "filled" | "outlined" | "standard";
  fullWidth?: boolean;
  customers: Customer[];
  onChange: (e: unknown, customer: Customer) => void;
  onOpen?: () => void;
  onClose?: () => void;
  placeholder?: string;
  label: string;
  value?: Customer;
  defaultValue?: Customer;
}

const AutocompleteCustomer: ForwardRefExoticComponent<IAutocompleteCustomer> = forwardRef(
  (props: IAutocompleteCustomer, ref) => {
    const {
      variant,
      fullWidth,
      customers = [],
      onClose = () => {},
      placeholder,
      label,
      ...rest
    } = props;

    return (
      <Autocomplete
        ref={ref}
        options={customers}
        onClose={() =>
          setTimeout(() => {
            // hack to prevent backdrop close parent modal when closing popper
            onClose && onClose();
          }, 500)
        }
        getOptionLabel={option => option.name}
        renderOption={option => option.name}
        renderInput={params => (
          <TextField
            {...params}
            variant={variant}
            fullWidth={fullWidth}
            label={label}
            placeholder={placeholder}
          />
        )}
        {...rest}
      />
    );
  }
);

interface AutoCompleteUsersControllerProps<T>
  extends Omit<IAutocompleteCustomer, "onChange"> {
  ControllerProps: Omit<ControllerProps<T>, "render">;
}

export function withFormController<FieldValues>() {
  return (props: AutoCompleteUsersControllerProps<FieldValues>) => {
    const { ControllerProps, customers = [], ...other } = props;
    const { name, control } = ControllerProps;
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { onChange, value = null, ...rest } = field;
          return (
            <AutocompleteCustomer
              fullWidth
              value={value}
              customers={customers}
              variant="filled"
              onChange={(e: unknown, value: Customer) => {
                onChange(value);
              }}
              {...other}
              {...rest}
            />
          );
        }}
      />
    );
  };
}

export default AutocompleteCustomer;
