import { useSelector } from "react-redux";
import { RootState } from "../index";

const useWorkspace = () => {
  const workspace = useSelector(
    ({ api: { auth } }: RootState) => auth.activeWorkspaceId
  );
  return workspace;
};

export default useWorkspace;
