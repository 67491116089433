import {
  QueryBuilder,
  Query,
  Condition,
  Timestamp,
} from "@superprofit/core-firestore-models";
import { db, firebase } from "../firebase";
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;
type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

export type IUserGroup = Pick<
  UserGroup,
  | "users"
  | "archived"
  | "name"
  | "createdBy"
  | "createdAt"
  | "updatedBy"
  | "updatedAt"
  | "id"
>;

export default class UserGroup {
  users: Array<string>;
  id: string | null;
  archived: boolean;
  name: string;
  createdBy: string;
  createdAt: typeof Timestamp;
  updatedBy: string;
  updatedAt: string;

  static collectionName = "userGroups";

  static converter = {
    toFirestore(group: UserGroup) {
      const d = group.data();
      const firestoreMap = d.users.reduce(
        (acc, user: string) => ({ ...acc, [user]: true }),
        {} as Record<string, boolean>
      );
      // We use map instead of array for better firestore querying
      // because limit in array queries is 10 https://firebase.googleblog.com/2018/08/better-arrays-in-cloud-firestore.html
      return { ...d, users: firestoreMap };
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IUserGroup;
      return new UserGroup({
        ...data,
        id: snapshot.id,
        users: data.users ? Object.keys(data.users) : [],
      });
    },
  };

  static createId(workspace: string): string {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserGroup.collectionName)
      .doc().id;
  }

  constructor({
    users = [],
    name = "",
    id = null,
    archived = false,
    createdBy = "",
    createdAt = "",
    updatedBy = "",
    updatedAt = "",
  }: Partial<IUserGroup> = {}) {
    this.id = id;
    this.archived = archived;
    this.users = users;
    this.name = name;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.updatedBy = updatedBy;
    this.updatedAt = updatedAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<UserGroup>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      users: this.users,
      name: this.name,
      archived: this.archived,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
    };
  }

  static list = async (
    workspace: string,
    users: UserGroup["users"] | [] = []
  ) => {
    const query = new Query();

    if (users.length > 90) {
      console.warn(
        "[UserGroup] ********************\n\n WE MIGHT EXCEED FILTERS LIMIT https://firebase.google.com/docs/firestore/query-data/queries\n\n****************"
      );
    }

    users.forEach((u) => {
      query.addCondition(
        new Condition(new firebase.firestore.FieldPath("users", u), "==", true)
      );
    });
    let allGroups;

    if (users.length > 0) {
      // make sure to fetch user groups with the "all" key when filtering
      allGroups = await db
        .collection("workspaces")
        .doc(workspace)
        .collection(UserGroup.collectionName)
        .where("users.all", "==", true)
        .withConverter(UserGroup.converter)
        .get();
    }

    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(UserGroup.collectionName),
      query
    )
      .withConverter(UserGroup.converter)
      .get();

    let result = snapshot.docs.map((doc: QueryDocumentSnapshot) => doc.data());
    if (allGroups)
      result = result.concat(
        allGroups.docs.map((doc: QueryDocumentSnapshot) => doc.data())
      ) as UserGroup[];
    return result;
  };

  static create = async (
    workspace: string,
    user: string,
    data: Pick<IUserGroup, "name" | "users">
  ) => {
    const group = new UserGroup({
      ...data,
      id: UserGroup.createId(workspace),
      updatedAt: Timestamp.now(),
      createdAt: Timestamp.now(),
      updatedBy: user,
      createdBy: user,
    });
    if (!group.id) throw new Error("[UserGroup] No id.");

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserGroup.collectionName)
      .doc(group.id)
      .withConverter(UserGroup.converter)
      .set(group, { merge: true });

    return group;
  };

  static delete = async (workspace: string, group: IUserGroup) => {
    if (!group.id) throw new Error("[UserGroup] id is missing from group!");
    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserGroup.collectionName)
      .doc(group.id)
      .delete();

    return group;
  };

  static update = async (workspace: string, user: string, group: UserGroup) => {
    const updatedGroup = group.clone();
    updatedGroup.setData({
      updatedAt: Timestamp.now(),
      updatedBy: user,
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserGroup.collectionName)
      .doc(updatedGroup.id)
      .withConverter(UserGroup.converter)
      .set(updatedGroup);
    return updatedGroup;
  };

  static batchUpdate = async (
    workspace: string,
    user: string,
    groups: UserGroup[]
  ) => {
    const batch = db.batch();

    groups.forEach((group) => {
      const updatedGroup = group.clone();
      updatedGroup.setData({
        updatedAt: Timestamp.now(),
        updatedBy: user,
      });

      const docRef = db
        .collection("workspaces")
        .doc(workspace)
        .collection(UserGroup.collectionName)
        .doc(updatedGroup.id)
        .withConverter(UserGroup.converter);

      batch.set(docRef, updatedGroup, { merge: true });
    });

    await batch.commit();
    return groups;
  };
}
