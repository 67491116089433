import React from "react";
import { DayHeader, DayHeaderContainer } from "./TimesheetV2.styles";
import Typography from "../../../components/atoms/Typography";
import { format } from "../../../date-fns-wrappers";
import { getDayOfYear } from "date-fns";
import { getYear } from "date-fns/fp";
import DurationFormat from "../../../components/atoms/DurationFormat";

interface IProps {
  date: Date;
  hours: number;
  isToday: boolean;
}
const Header: React.FC<IProps> = ({ date, hours, isToday }) => {
  const dayAbbreviation = format(date, "EEE.");
  const dayNumber = format(date, "d");
  return (
    <DayHeader isToday={isToday}>
      <DayHeaderContainer>
        <Typography variant="caption">{dayAbbreviation}</Typography>
        <Typography>{dayNumber}</Typography>
        <DurationFormat
          component={({ children }: { children: JSX.Element }) => (
            <Typography variant="caption" children={children} />
          )}
          value={hours}
        />
      </DayHeaderContainer>
    </DayHeader>
  );
};

export default Header;
