import { all, put, takeLatest } from "@redux-saga/core/effects";
import { GET, GET_SUCCESS } from "../../../api/projectOverview/constants";
import { setLoading, setPlan, setSaving } from "./actions";
import { SAVE, SAVE_SUCCESS } from "../../../api/projects/constants";

export const getCurrentProject = ({
  ui: {
    projectOverview: {
      project: { project }
    }
  }
}) => project;

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const handleSave = function*(args) {
  yield put(setSaving(true));
};

export const handleGetSuccess = function*(args) {
  try {
    const {
      payload: { project }
    } = args;
    yield put(setPlan(project.planned));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleSaveSuccess = function*(args) {
  try {
    const {
      payload: { project }
    } = args;

    yield put(setPlan(project.planned));
    yield put(setSaving(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleGetSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export const handleSaveSaga = function*() {
  yield takeLatest(SAVE, handleSave);
};

export const handleGetSuccessSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleGetSuccess);
};

export const handleSaveSuccessSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleSaveSuccess);
};

export default function*() {
  yield all([
    handleGetSuccessSaga(),
    handleGetSaga(),
    handleSaveSaga(),
    handleSaveSuccessSaga()
  ]);
}
