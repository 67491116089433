import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const WorkspaceContext = React.createContext({
  activeWorkspaceId: null
});

/**
 * Returns the relative path for the given workspace
 * @returns {string}
 */
export const useWorkspacePath = () => {
  const { activeWorkspaceId } = useContext(WorkspaceContext);

  return `/app/${activeWorkspaceId}`;
};

export const useWorkspace = () => {
  return useContext(WorkspaceContext);
};

/**
 * Returns a useHistory that takes into account the given workspace
 * @returns {{ push: Function }}
 */
export const useWorkspaceHistory = () => {
  const workspacePath = useWorkspacePath();
  const history = useHistory();
  return {
    push: subPath => {
      const target = `${workspacePath}/${
        subPath[0] && subPath[0] === "/" ? subPath.slice(1) : subPath
      }`;
      history.push(target);
    }
  };
};

export default props => {
  const { activeWorkspaceId } = useSelector(state => state.api.auth);

  return <WorkspaceContext.Provider value={{ activeWorkspaceId }} {...props} />;
};
