import { useMemo } from "react";
import {
  getHoursByProject,
  getHoursPerWeekByProject,
  getRelativeHoursPercentageByProject,
  getSalaryByProject,
  getUserHoursByProject,
  getUserHoursPerWeekByProject
} from "../../../../utils/ProjectStats";
import useFilteredProjects from "./useFilteredProjects";
import useFilteredStats from "./useFilteredStats";
import Project from "../../../../models/Project";
import useUsersMap from "../../../../hooks/useUsersMap";
import useProjectsMap from "../../../../hooks/useProjectsMap";
import {
  getHoursByUser,
  getHoursPerWeekByUser,
  getMoneyByUser,
  getProjectHoursByUser,
  getProjectHoursPerWeekByUser,
  getProjectMoneyByUser,
  getProjectSalaryByUser,
  getRelativeHoursPercentageByUser,
  getSalaryByUser
} from "../../../../utils/UserStats";
import { Filters } from "./types";
import XstatsUserProjectHoursByWeek from "../../../../models/XstatsUserProjectHoursByWeek";
import useFilteredUsers from "./useFilteredUsers";
import User from "../../../../models/User";

const useUsersList = ({
  users,
  projects,
  stats,
  filters,
  loading
}: {
  projects: Project[];
  users: User[];
  stats: XstatsUserProjectHoursByWeek[];
  filters: Filters;
  loading: boolean;
}) => {
  const filteredStats = useFilteredStats({ filters, stats, projects });
  const filteredUsers = useFilteredUsers({ filters, users });
  const projectsMap = useProjectsMap(projects);
  const projectsById = Object.fromEntries(projectsMap);
  return useMemo(() => {
    if (loading) return [];

    const projectMoneyByUser = getProjectMoneyByUser(
      filteredStats,
      projectsById
    );
    const projectSalaryByUser = getProjectSalaryByUser(
      filteredStats,
      projectsById
    );
    const projectHoursByUser = getProjectHoursByUser(filteredStats) as any;

    const moneyByUser = getMoneyByUser(filteredStats, projectsById) as any;
    const salaryByUser = getSalaryByUser(filteredStats, projectsById) as any;
    const hoursByUser = getHoursByUser(filteredStats) as any;
    const relativeHours = getRelativeHoursPercentageByUser(
      filteredStats
    ) as any;
    const hoursByWeek = getHoursPerWeekByUser(filteredStats) as any;
    const hoursByUserProjectWeek = getProjectHoursPerWeekByUser(
      filteredStats
    ) as any;

    return filteredUsers.map(user => ({
      user,
      hours:
        user?.email && hoursByUser[user.email] ? hoursByUser[user.email] : 0,
      projectHours:
        user?.email && projectHoursByUser[user.email]
          ? projectHoursByUser[user.email]
          : {},
      projectMoney:
        user?.email && projectMoneyByUser[user.email]
          ? projectMoneyByUser[user.email]
          : {},
      projectSalary:
        user?.email && projectSalaryByUser[user.email]
          ? projectSalaryByUser[user.email]
          : {},
      money:
        user?.email && moneyByUser[user.email] ? moneyByUser[user.email] : 0,
      salary:
        user?.email && salaryByUser[user.email] ? salaryByUser[user.email] : 0,
      relativeHours:
        user?.email && relativeHours[user.email]
          ? relativeHours[user.email]
          : 0,
      byWeek:
        user?.email && hoursByWeek[user.email] ? hoursByWeek[user.email] : {},
      byProjectWeek:
        user?.email && hoursByUserProjectWeek[user.email]
          ? hoursByUserProjectWeek[user.email]
          : {}
    }));
  }, [filteredUsers, filteredStats, projectsById, loading]);
};

export default useUsersList;
