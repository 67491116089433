export const HIDE_DIALOG = "ui/userGroups/dialog/HIDE_USER_GROUP_DIALOG";
export const SHOW_DIALOG = "ui/userGroups/dialog/SHOW_USER_GROUP_DIALOG";
export const SET_IS_CLOSABLE = "ui/userGroups/dialog/SET_IS_CLOSABLE_USER_GROUP_DIALOG";
export const SET_SAVING = "ui/userGroups/dialog/SET_IS_SAVING_USER_GROUP_DIALOG";
export const SET_ERROR = "ui/userGroups/dialog/SET_ERROR_USER_GROUP_DIALOG";
export const SET_SHOW_CONFIRMATION = "ui/userGroups/dialog/SET_SHOW_CONFIRMATION_USER_GROUP_DIALOG";
export const WATCH_SAVE = "ui/userGroups/dialog/WATCH_SAVE_USER_GROUP";
export const WATCH_GET_USERS = "ui/userGroups/dialog/WATCH_GET_USERS";
export const UPDATE_DIALOG_DATA =
    "ui/userGroups/dialog/UPDATE_USER_GROUP_DIALOG_DATA";
