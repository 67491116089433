import { takeLatest, call, put, select, all } from "redux-saga/effects";
import { WATCH_GET } from "./constants";
import { get, getFailure, getSuccess } from "./actions";
import { db } from "../../../../firebase";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;

export const doGet = async (workspace, user, year, month, week) => {
  const snapshot = await db
    .collection("workspaces")
    .doc(workspace)
    .collection("xstats_user_project_hours_by_week")
    .where("year", "==", year)
    .where("user", "==", user)
    .get();

  const all = [];
  snapshot.forEach(doc => {
    all.push({ ...doc.data(), id: doc.id });
  });

  return {
    currentYearByWeek: all,
    monthBefore: []
  };
};

export const getUserStats = function* getUserStats(args) {
  const { user, year, month, week } = args.payload;
  try {
    yield put(get());
    const workspace = yield select(getActiveWorkspace);
    const stats = yield call(doGet, workspace, user, year, month, week);

    yield put(getSuccess(stats));
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const getUserStatsSaga = function* getUserStatsSaga() {
  yield takeLatest(WATCH_GET, getUserStats);
};

export default function*() {
  yield all([getUserStatsSaga()]);
}
