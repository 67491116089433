import React from "react";
import withTheme from "@material-ui/core/styles/withTheme";
import { StyledPageHeader } from "./pageHeader/PageHeader.styles";
import Typography from "./Typography";
import Box from "./Box";

type PageHeaderProps = {
  theme: any;
  context: string;
  title: string | React.ReactNode;
};
const WrapperComponent = ({ theme, context, title }: PageHeaderProps) => {
  return (
    <StyledPageHeader>
      <Typography component="h2" gutterBottom>
        <Box {...theme.typography.h2}>{context.toUpperCase()}</Box>
      </Typography>
      {typeof title === "string" ? (
        <Box {...theme.typography.h1}>{title}</Box>
      ) : (
        title
      )}
    </StyledPageHeader>
  );
};

const PageContextHeader = withTheme(WrapperComponent);

export default PageContextHeader;
