export const GET = "@superprofit/core-redux-saga-modules/UserRole/GET";
export const GET_SUCCESS = "@superprofit/core-redux-saga-modules/UserRole/GET_SUCCESS";
export const GET_FAILURE = "@superprofit/core-redux-saga-modules/UserRole/GET_FAILED";

export const POST = "@superprofit/core-redux-saga-modules/UserRole/POST";
export const POST_SUCCESS = "@superprofit/core-redux-saga-modules/UserRole/POST_SUCCESS";
export const POST_FAILURE = "@superprofit/core-redux-saga-modules/UserRole/POST_FAILED";

export const WATCH_GET = "@superprofit/core-redux-saga-modules/UserRole/WATCH_GET";
export const WATCH_POST = "@superprofit/core-redux-saga-modules/UserRole/WATCH_POST";
