import { NumberFormatIntl, Skeleton } from "../../../../atoms";
import Typography from "../../../../atoms/Typography";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import React from "react";

export default ({ state }) => {
  const {
    subscriptionInformation,
    isLoadingUpcomingInvoice,
    updatedSubscriptionStatus,
    upcomingInvoice,
  } = state;
  const subscription = subscriptionInformation?.subscription;
  const {
    updatedSubscriptionProductId = subscription?.plan?.product?.id,
    updatedSubscriptionSeats = subscription?.quantity,
  } = state;
  if (isLoadingUpcomingInvoice)
    return (
      <Grid item xs={12}>
        <Skeleton />
      </Grid>
    );

  if (
    !updatedSubscriptionProductId ||
    !updatedSubscriptionSeats ||
    (updatedSubscriptionProductId === subscription?.plan?.product?.id &&
      updatedSubscriptionSeats === subscription.quantity)
  ) {
    return null;
  }


  if (updatedSubscriptionStatus?.status === "success")
    return (
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          Your changes are effective immediately.
        </Typography>
      </Grid>
    );


  return (
    <Grid item xs={12}>
      <Typography variant="body2" color="textSecondary">
        <>
          Your next payment of{" "}
          <b>
            <NumberFormatIntl
              locales="en"
              number={upcomingInvoice?.next_invoice_sum / 100}
              options={{
                style: "currency",
                currency: subscription?.latest_invoice?.currency?.toUpperCase(),
              }}
            />
          </b>
        </>{" "}
        will be due{" "}
        <b>
          {moment
            .unix(upcomingInvoice?.invoice?.next_payment_attempt)
            .format("DD/MM/YYYY")}
        </b>
      </Typography>
    </Grid>
  );
};
