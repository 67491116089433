export const GET = "api/customers/GET";
export const GET_SUCCESS = "api/customers/GET_SUCCESS";
export const GET_FAILURE = "api/customers/GET_FAILED";

export const GET_CUSTOMER = "api/customers/GET_CUSTOMER";
export const GET_CUSTOMER_SUCCESS = "api/customers/GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILURE = "api/customers/GET_CUSTOMER_FAILURE";

export const SAVE = "api/customers/SAVE";
export const SAVE_SUCCESS = "api/customers/SAVE_SUCCESS";
export const SAVE_FAILURE = "api/customers/SAVE_FAILURE";

export const PATCH = "api/customers/PATCH";
export const PATCH_SUCCESS = "api/customers/PATCH_SUCCESS";
export const PATCH_FAILURE = "api/customers/PATCH_FAILURE";

export const WATCH_GET_CUSTOMER = "api/customers/WATCH_GET_CUSTOMER";
export const WATCH_GET = "api/customers/WATCH_GET";
export const WATCH_SAVE = "api/customers/WATCH_SAVE";
export const WATCH_PATCH = "api/customers/WATCH_PATCH";
