import { UPDATE, ERROR } from "./constants";
import workspaceConstants  from "@superprofit/core-redux-saga-modules/Workspaces/constants";

export const initialState = {
  workspaceName: "",
  workspaceExist: null,
  created: null,
  errors: {}
};

export default (state = initialState, action) => {
  const { payload = {} } = action;
  const { updates = {}, errors, docId, exist } = payload;

  switch (action.type) {
    //api
    case workspaceConstants.EXISTS:
      return { ...state, loading: true };
    case workspaceConstants.EXISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        workspaceExist: !!(docId === state.workspaceName && exist)
      };
    case workspaceConstants.EXISTS_FAILURE:
      return { ...state, loading: false };
    case workspaceConstants.CREATE:
      return { ...state, created: false };
    case workspaceConstants.CREATE_SUCCESS:
      return { ...state, created: true };
    case workspaceConstants.CREATE_FAILURE:
      return { ...state, created: false };

    case UPDATE:
      return { ...state, ...updates };
    case ERROR:
      return { ...state, errors };
    default:
      return state;
  }
};
