import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { GET, GET_SUCCESS } from "../../../api/companyDashboard/constants";
import { setLoading, setBillableMonth } from "./actions";
import { SETTING_TYPES } from "../../../../../models/CompanySetting";
import { mapRatesWithProjects } from "../utils";

export const getStats = ({
  api: {
    companyStats: { stats }
  }
}) => stats;

export const getPeriod = ({
  ui: {
    dashboard: { period }
  }
}) => period;

export const getProjects = ({
  api: {
    projects: { list }
  }
}) => list;

export const getCompanySettings = ({
  api: {
    companySettings: { settings }
  }
}) => settings;

export const getMonthBefore = (year, month) => {
  return {
    year: month === 1 ? year - 1 : year,
    month: month === 1 ? 12 : month - 1
  };
};

export const getGoal = (year, month, settings) => {
  let goal = null;
  Object.keys(settings).forEach(key => {
    const setting = settings[key];
    if (setting.type === SETTING_TYPES.PERFORMANCE_GOAL) {
      if (setting.year === year && setting.month === month) {
        goal = setting;
      }
      if (!goal && setting.year === year && setting.month === "*") {
        goal = setting;
      }
    }
  });
  return goal;
};

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const handleGetSuccess = function*(args) {
  try {
    const projects = yield select(getProjects);

    const { raw, monthBefore } = yield select(getStats);
    const { year, month } = yield select(getPeriod);
    const companySettings = yield select(getCompanySettings);

    const goal = getGoal(year, month, companySettings);

    const prevMonth = getMonthBefore(year, month);

    const mappedRaw = mapRatesWithProjects(raw, projects);
    const mappedMonthBefore = mapRatesWithProjects(monthBefore, projects);

    const billableHours = mappedRaw
      .filter(stats => stats.billable && stats.month === month)
      .reduce((prev, next) => prev + next.total, 0);

    const billableHoursPrev = mappedMonthBefore
      .filter(
        stats =>
          stats.billable &&
          stats.month === prevMonth.month &&
          stats.year === prevMonth.year
      )
      .reduce((prev, next) => prev + next.total, 0);

    const billableIncome = mappedRaw
      .filter(stats => stats.billable && stats.month === month)
      .reduce((prev, next) => prev + next.total * next.billableRate, 0);

    const billableIncomePrev = mappedMonthBefore
      .filter(
        stats =>
          stats.billable &&
          stats.month === prevMonth.month &&
          stats.year === prevMonth.year
      )
      .reduce((prev, next) => prev + next.total * next.billableRate, 0);

    let hoursChange = 0;
    if (billableHoursPrev) {
      const diff = billableHours - billableHoursPrev;
      hoursChange = (diff / billableHoursPrev) * 100;
    }

    let incomeChange = 0;
    if (billableIncomePrev) {
      const diff = billableIncome - billableIncomePrev;
      incomeChange = (diff / billableIncomePrev) * 100;
    }

    yield put(
      setBillableMonth(
        billableHours,
        billableHoursPrev,
        hoursChange,
        billableIncome,
        billableIncomePrev,
        incomeChange,
        goal
      )
    );

    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleGetSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export const handleGetSuccessSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleGetSuccess);
};

export default function*() {
  yield all([handleGetSuccessSaga(), handleGetSaga()]);
}
