import usePeriod from "./usePeriod";
import usePrevMonthPeriodPeriod from "./usePrevMonthPeriod";
import useUserStats from "../../../../../hooks/useUserStats";
import useProjects from "../../../../../hooks/useProjects";
import useUsers from "../../../../../hooks/useUsers";
import useProjectsMap from "../../../../../hooks/useProjectsMap";

const useWorkspaceStats = () => {
  const period = usePeriod();
  const prevMonthPeriod = usePrevMonthPeriodPeriod();
  const { data: statsCurrentMonth, isLoading: statsLoading } = useUserStats(
    period
  );
  const {
    data: statsPreviousMonth,
    isLoading: prevStatsLoading
  } = useUserStats(prevMonthPeriod);

  const { data: statsYear, isLoading: yearStatsLoading } = useUserStats(
    {
      year: period.year
    },
    { staleTime: 10 * (60 * 1000), cacheTime: 11 * (60 * 1000) }
  );

  const { data: projects, isLoading: projectsLoading } = useProjects();
  const { data: users, isLoading: usersLoading } = useUsers();
  const projectsMap = useProjectsMap(projects);

  const isLoading =
    statsLoading ||
    prevStatsLoading ||
    projectsLoading ||
    usersLoading ||
    yearStatsLoading;

  return {
    statsCurrentMonth,
    statsPreviousMonth,
    statsYear,
    projects,
    projectsMap,
    users,
    isLoading,
    period
  };
};

export default useWorkspaceStats;
