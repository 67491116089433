import { takeLatest, call, put, select, all } from "redux-saga/effects";
import { WATCH_GET } from "./constants";
import { get, getFailure, getSuccess } from "./actions";
import XstatsInvoicesCreated from "../../../../../models/XstatsInvoicesCreated";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;

export const getStats = function*(args = {}) {
  const { payload } = args;

  try {
    const workspace = yield select(getActiveWorkspace);
    yield put(get());
    const data = yield call(XstatsInvoicesCreated.get, workspace);
    yield put(getSuccess(data));
  } catch (e) {
    yield put(getFailure());
  }
};

export const getSaga = function*() {
  yield takeLatest(WATCH_GET, getStats);
};

export default function*() {
  yield all([getSaga()]);
}
