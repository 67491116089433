import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Divider
} from "@superprofit/core-react-components/atoms";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import CommentEntry from "./commentsAccordion/CommentEntry";
import { useDispatch, useSelector } from "react-redux";
import { ICommentsAccordion } from "./types";
import TimesheetComment from "../../../../models/TimesheetComment";
import { watchRemoveTimesheetComment } from "../../../../redux/modules/api/timesheet/comments/actions";

export default ({ comments, onDelete }: ICommentsAccordion) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="comments-content"
        id="comments-header"
      >
        <Typography variant="caption">{comments.length} comment(s)</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} flexDirection="column">
          {comments.map((c: TimesheetComment) => (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <CommentEntry onDelete={onDelete} comment={c} />
              </Grid>
            </>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
