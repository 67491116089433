import { SET_LOADING, SET_CUSTOMER } from "./constants";

const initialState = {
  customer: {},
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload.isLoading
      };
    case SET_CUSTOMER:
      return {
        ...state,
        customer: payload.customer
      };
    default:
      return state;
  }
};
