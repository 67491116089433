import styled, { css } from "styled-components";
import theme from "styled-theming";
import { Button } from "@material-ui/core";

const colors = theme.variants("mode", "color", {
  default: {
    light: css`
      background: ${props => props.theme.palette.background.paper};
    `,
    dark: css``
  },
  alert: {
    light: css`
      color: ${props => props.theme.palette.error.main};
      ${props =>
        props.variant === "contained" &&
        css`
          color: white;
          background-color: ${props => props.theme.palette.error.main};
        `};
    `,
    dark: css``
  },
  success: {
    light: css`
      color: ${props => props.theme.palette.success.main};
      ${props =>
        props.variant === "contained" &&
        css`
          color: white;
          background-color: ${props => props.theme.palette.success.main};
        `};
    `,
    dark: css``
  }
});

// any here because of too much trouble to get the types right

export const StyledButton = styled(Button)<any>`
  ${colors};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  font-size: 0.875rem;

  .absolute {
    position: absolute;
  }
`;
