import {
  ADD_GLOBAL_ALERT,
  TOGGLE_SIDEBAR,
  REMOVE_GLOBAL_ALERT,
} from "./constants";
import {action} from "typesafe-actions";

export const toggleSidebar = (open: boolean) => action(TOGGLE_SIDEBAR, { open });

export const addGlobalAlert = (alert: any) => action(ADD_GLOBAL_ALERT, { ...alert });
export const removeGlobalAlert = (alert: any) => action(REMOVE_GLOBAL_ALERT, {...alert})

export default toggleSidebar;
