import React, { useMemo, useState } from "react";
import { TableCell, TableRow } from "../../../components/atoms/Table";
import IconButton from "../../../components/atoms/IconButton";
import Button from "../../../components/atoms/Button";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ProjectAvatar from "../../../components/molecules/ProjectAvatar";
import { CheckableAvatar } from "../../../components/molecules/CheckableAvatar";
import Project from "@superprofit/timet-react-client/src/models/Project";
import DurationFormat from "../../../components/atoms/DurationFormat";
import { useTranslation } from "react-i18next";
import Label from "@superprofit/core-react-components/atoms/Label";
import Entry from "@superprofit/timet-react-client/src/models/Entry";
import entries from "@superprofit/timet-react-client/src/redux/modules/ui/timesheet/entries";
import { ApprovalRecord } from "@superprofit/timet-react-client/src/models/ApprovalRecords";
import { Status } from "@superprofit/timet-react-client/src/pages/protected/userApproval/Status";
import { eachDayOfInterval, isEqual } from "date-fns";
import { format } from "../../../date-fns-wrappers";
import {
  UserRow,
  ProjectRow
} from "@superprofit/timet-react-client/src/pages/protected/approvalsByUser/userItem/ProjectRow";
import useUsersMap from "@superprofit/timet-react-client/src/hooks/useUsersMap";
import useConvertToMap from "@superprofit/timet-react-client/src/hooks/useConvertToMap";
import useCustomersMap from "@superprofit/timet-react-client/src/hooks/useCustomersMap";
import useUniqueUsersInProjects from "@superprofit/timet-react-client/src/hooks/useUniqueUsersInProjects";
import useUserProjectsMap from "@superprofit/timet-react-client/src/hooks/useUserProjectsMap";
import TimetUser from "@superprofit/timet-react-client/src/models/TimetUser";
import UserAvatar from "@superprofit/timet-react-client/src/components/molecules/UserAvatar";
import UserLink from "@superprofit/timet-react-client/src/components/molecules/UserLink";
import { useFilterDialog } from "@superprofit/timet-react-client/src/components/organisms/FilterDialog";
import useUserGroups from "@superprofit/timet-react-client/src/hooks/useUserGroups";
import useUnarchivedProjects from "@superprofit/timet-react-client/src/hooks/useUnarchivedProjects";
type UserItemProps = {
  user: TimetUser;
  entries?: Entry[];
  totalHours: number;
  approvalRecords?: ApprovalRecord[];
  startDate: Date;
  endDate: Date;
  onCreate: (projects: Project[], user: string) => void;
  onReject: (approvalRecord: ApprovalRecord) => void;
  onApprove: (approvalRecord: ApprovalRecord) => void;
  usersSubmitting: string[];
};

export const UserItem = ({
  user,
  entries = [],
  approvalRecords = [],
  startDate,
  endDate,
  onReject,
  usersSubmitting = [],
  totalHours,
  onApprove,
  onCreate
}: UserItemProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const customersMap = useCustomersMap();
  const projectsMap = useUserProjectsMap({ userId: user.id });
  const {
    state: { hasFilters, filters }
  } = useFilterDialog();
  const { data: allUserGroups = [] } = useUserGroups();
  const userProjects = useMemo(() => Array.from(projectsMap.values()), [
    projectsMap
  ]);

  // const daysDates = eachDayOfInterval({
  //   start: startDate,
  //   end: endDate
  // });
  // const hours = useMemo(() => {
  //   return entries?.reduce((acc, entry) => acc + entry.hours, 0);
  // }, [entries]);
  //
  // const getEntryByDate = (date: Date) => {
  //   return entries.find(entry =>
  //     isEqual(new Date(entry.year, 0, entry.dayOfYear), date)
  //   );
  // };

  const entriesByProjectMap = useMemo(() => {
    const map = new Map<string, Entry[]>();
    entries.forEach(entry => {
      if (!map.has(entry.project)) {
        map.set(entry.project, []);
      }
      map.get(entry.project)?.push(entry);
    });
    return map;
  }, [entries]);

  const projectsWithoutApprovalRecord = useMemo(() => {
    return Array.from(projectsMap.values()).filter(project => {
      return !approvalRecords.some(
        approvalRecord => approvalRecord.project === project.id
      );
    });
  }, [projectsMap, approvalRecords]);

  const approvalRecordsByProjectMap = useConvertToMap<ApprovalRecord>(
    "project",
    approvalRecords
  );

  const leastStatusApprovalRecord = useMemo(() => {
    const unsubmitted = projectsWithoutApprovalRecord.length > 0;
    if (unsubmitted) {
      return undefined;
    }

    const submitted = approvalRecords.find(
      approvalRecord => approvalRecord.status === "submitted"
    );

    if (submitted) {
      return submitted;
    }

    return approvalRecords.find(
      approvalRecord => approvalRecord.status === "approved"
    );
  }, [approvalRecords, projectsWithoutApprovalRecord]);

  const anyApproved = useMemo(
    () =>
      approvalRecords.some(
        approvalRecord => approvalRecord.status === "approved"
      ),
    [approvalRecords]
  );
  const anySubmitted = useMemo(
    () =>
      approvalRecords.some(
        approvalRecord => approvalRecord.status === "submitted"
      ),
    [approvalRecords]
  );
  const anyRejected = useMemo(
    () =>
      approvalRecords.some(
        approvalRecord => approvalRecord.status === "rejected"
      ),
    [approvalRecords]
  );

  const handleOnApprove = (approvalRecord: ApprovalRecord) => {
    onApprove(approvalRecord);
  };

  const handleOnReject = (approvalRecord: ApprovalRecord) => {
    onReject(approvalRecord);
  };

  const handleOnCreate = (project: Project) => {
    onCreate([project], user.id);
  };

  const createAll = () => {
    onCreate(Array.from(projectsMap.values()), user.id);
  };

  const rejectAll = () => {
    approvalRecords.forEach(approvalRecord => {
      onReject(approvalRecord);
    });
  };

  const approveAll = () => {
    approvalRecords.forEach(approvalRecord => {
      onApprove(approvalRecord);
    });
  };

  const filteredProjects = useMemo(() => {
    let temp = userProjects;
    if (hasFilters) {
      if (filters.customers.length > 0) {
        temp = temp.filter(p => filters.customers.indexOf(p.customer) > -1);
      }
      if (filters.projects.length > 0) {
        temp = temp.filter(p => filters.projects.indexOf(p.id as string) > -1);
      }
    }
    return temp;
  }, [userProjects, filters, hasFilters, allUserGroups]);

  const sortedProjects = useMemo(() => {
    const sorting = filteredProjects.slice();
    sorting.sort((a, b) => {
      const customerA = customersMap.get(a.customer)?.name || "";
      const customerB = customersMap.get(b.customer)?.name || "";

      const customerComparison = customerA.localeCompare(customerB);
      if (customerComparison !== 0) {
        return customerComparison;
      }

      return a.name.localeCompare(b.name);
    });

    return sorting;
  }, [filteredProjects, customersMap]);

  const disableAllButtonAction =
    filteredProjects.length !== userProjects.length;

  return (
    <>
      <TableRow>
        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <IconButton
            aria-label={t("common.expandRow")}
            size="medium"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <UserAvatar user={user} />
        </TableCell>
        <TableCell>
          <UserLink
            name={user.displayName || undefined}
            email={user.email}
            hideEmail
          />
        </TableCell>
        <TableCell>{"-"}</TableCell>
        <TableCell>
          <Status
            status={
              anySubmitted && leastStatusApprovalRecord === undefined
                ? "partially-submitted"
                : leastStatusApprovalRecord?.status
            }
          />
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>

        <TableCell align="right">
          <DurationFormat value={totalHours} component="b" />
        </TableCell>

        {/*<TableCell align="right">*/}
        {/*  <Button*/}
        {/*    style={{ visbility: "hidden" }}*/}
        {/*    disabled*/}
        {/*    onClick={() => {}}*/}
        {/*  ></Button>*/}
        {/*</TableCell>*/}
        <TableCell align="right">
          {!leastStatusApprovalRecord && (
            <Button
              variant="contained"
              color="primary"
              onClick={createAll}
              disabled={!!usersSubmitting.length || disableAllButtonAction}
            >
              {t("common.create")}
            </Button>
          )}
          {leastStatusApprovalRecord?.status === "submitted" && (
            <Button
              variant="contained"
              color="primary"
              onClick={approveAll}
              disabled={!!usersSubmitting.length || disableAllButtonAction}
            >
              {t("common.approveAll")}
            </Button>
          )}
        </TableCell>
        <TableCell>
          {leastStatusApprovalRecord?.status === "submitted" && (
            <Button
              variant="contained"
              color="alert"
              onClick={rejectAll}
              disabled={!!usersSubmitting.length || disableAllButtonAction}
            >
              {t("common.rejectAll")}
            </Button>
          )}
        </TableCell>
      </TableRow>
      {open &&
        sortedProjects?.map(project => (
          <ProjectRow
            isLoading={usersSubmitting.includes(user.id)}
            onReject={handleOnReject}
            onCreate={handleOnCreate}
            onApprove={handleOnApprove}
            project={project}
            key={project.id as string}
            approvalRecord={approvalRecordsByProjectMap.get(
              project.id as string
            )}
            entries={entriesByProjectMap.get(project.id as string)}
            user={user}
          ></ProjectRow>
        ))}
    </>
  );
};
