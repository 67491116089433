import { SET_LOADING, SET_STATS_WEEKLY_HOURS } from "./constants";

const initialState = {
  billable: [],
  nonBillable: [],
  weekNumbers: [],
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload.isLoading
      };
    case SET_STATS_WEEKLY_HOURS:
      return {
        ...state,
        billable: payload.billable,
        nonBillable: payload.nonBillable,
        weekNumbers: payload.weekNumbers
      };
    default:
      return state;
  }
};
