import {
  DESELECT_ALL,
  DESELECT_USER,
  SELECT_ALL,
  SELECT_USER
} from "./constants";
import { SelectionAction, SelectionState } from "./types";
import { createReducer } from "typesafe-actions";

const initialState: SelectionState = {
  selected: []
};

const reducer = createReducer<SelectionState, SelectionAction>(initialState)
  .handleType(SELECT_USER, (state, action) => ({
    ...state,
    selected: [...state.selected, action.payload.user]
  }))
  .handleType(DESELECT_USER, (state, action) => ({
    ...state,
    selected: state.selected.filter(u => u.id === action.payload.user.id)
  }))
  .handleType(SELECT_ALL, (state, action) => ({
    ...state,
    selected: [...action.payload.users]
  }))
  .handleType(DESELECT_ALL, state => ({ ...state, selected: [] }));

export default reducer;
