import React, { forwardRef, ForwardRefExoticComponent } from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import UserAvatar from "../../../../components/molecules/UserAvatar";
import UserLink from "../../../../components/molecules/UserLink";
import Project from "../../../../models/Project";
import User from "../../../../models/User";

interface Props {
  onChangeRate: (userId: string, rate: string) => void;
  project: Project;
  user: User;
}

const UserRateItem: ForwardRefExoticComponent<Props> = forwardRef(
  (props, ref) => {
    const { onChangeRate, project, user } = props;
    const labelId = `user-list-item-label-${user.id}`;

    return (
      <TableRow ref={ref}>
        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <UserAvatar user={user} />
        </TableCell>

        <TableCell id={labelId}>
          <UserLink name={user.displayName} email={user.email} hideEmail />
        </TableCell>

        <TableCell align="right">
          <TextField
            disabled={!project?.billable}
            defaultValue={
              !project?.billable
                ? "-"
                : project?.userBillableRate
                ? project?.userBillableRate[user.id]
                : ""
            }
            placeholder={!project?.billable ? "-" : project?.billableRate}
            fullWidth
            variant="filled"
            type="number"
            style={{ maxWidth: 100 }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeRate(user.id, e.target.value)
            }
          />
        </TableCell>
      </TableRow>
    );
  }
);

export default UserRateItem;
