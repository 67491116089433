import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Backdrop } from "@material-ui/core";
import { useTutorial } from "../../../contexts/TutorialContext";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";

const getCloneProps = el => {
  if (!el) return { style: { display: "none" } };
  const rect = el.getBoundingClientRect();
  return {
    style: {
      pointerEvents: "all",
      zIndex: 2,
      position: "absolute",
      top: rect.y,
      left: rect.x,
      height: rect.height,
      width: rect.width
    }
  };
};

const TutorialStep = ({ index, children, immediateNext = true }) => {
  const id = `tut-step-${index}`;
  const { isActive, isHighlighted, next, complete } = useTutorial(index);

  const childrenRef = useRef();
  const cloneRef = useRef();
  const rippleRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (!rippleRef?.current) return;
      rippleRef.current.start();
    }, 500);
  }, [rippleRef, isHighlighted]);

  const handleClick = e => {
    if (!isActive) return;
    if (immediateNext) {
      next();
    } else {
      complete();
    }
    return childrenRef?.current && childrenRef?.current.click();
  };

  return (
    <>
      {React.cloneElement(children, { ref: childrenRef })}
      {isHighlighted && (
        <Backdrop
          open={isActive}
          invisible={!isHighlighted}
          style={{ zIndex: 1 }}
        >
          {React.cloneElement(children, {
            id,
            ref: cloneRef,
            ...getCloneProps(childrenRef?.current),
            onClick: handleClick,
            children: (
              <>
                {children.props.children}
                <TouchRipple ref={rippleRef} center ripplePulsate />
              </>
            )
          })}
        </Backdrop>
      )}
    </>
  );
};

TutorialStep.propTypes = {
  immediateNext: PropTypes.bool,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

export default TutorialStep;
