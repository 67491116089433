import useProjects from "./useProjects";
import Project from "../models/Project";

const useCustomerProjects = (customerId: string) => {
  const query = useProjects();
  const data = query?.data?.filter(
    (project: Project) => project.customer === customerId
  );
  return { ...query, data };
};

export default useCustomerProjects;
