import styled from "@react-pdf/styled-components";
import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "@superprofit/core-react-components/themes/light/colors";

export const Line = styled.View`
  height: 1px;
  background-color: ${colors.primary};
  opacity: 0.5;
`;

export const Container = styled.View`
  margin-bottom: auto;
  width: 100%;
  flex-grow: 2;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 32px;
`;

export const PageNumber = styled.Text`
  margin-top: 4px;
  color: ${colors.secondary};
  font-weight: 300;
  font-size: 8px;
  text-align: right;
`;
