import { SET_LOADING, SET_PROGNOSIS } from "./constants";

const initialState = {
  prognosis: 0,
  actualTD: 0,
  adjustedPrognosis: 0,
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PROGNOSIS:
      return {
        ...state,
        prognosis: payload.prognosis,
        actualTD: payload.actualTD,
        adjustedPrognosis: payload.adjustedPrognosis
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload.isLoading
      };
    default:
      return state;
  }
};
