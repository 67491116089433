import React from "react";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@superprofit/core-react-components/atoms/Table";
import Card from "@superprofit/core-react-components/atoms/Card";
import TableLoader from "../../../../components/molecules/TableLoader";
import { getHoursByProject } from "../../../../utils/ProjectStats";
import ProjectItem from "./ProjectItem";
import { useTranslation } from "react-i18next";

export default ({
  user,
  projects,
  loading,
  month,
  year,
  stats,
  onChange,
  ...rest
}) => {
  const hoursByProject = getHoursByProject(stats);
  const { t } = useTranslation();
  return (
    <Card {...rest}>
      <TableContainer>
        <Table aria-label={t("common.byProject")}>
          <TableHead>
            <TableRow>
              <TableCell style={{ maxWidth: 80, width: 80 }} />
              <TableCell>{t("common.name")}</TableCell>

              <TableCell align="right">{t("common.actual")}</TableCell>

              <TableCell align="right">{t("common.planned")}</TableCell>
            </TableRow>
          </TableHead>

          {!loading && (
            <TableBody>
              {projects.map(project => (
                <ProjectItem
                  key={project.id}
                  hours={hoursByProject[project.id]}
                  month={month}
                  year={year}
                  project={project}
                  user={user}
                  onChange={onChange}
                />
              ))}
            </TableBody>
          )}

          {loading && (
            <TableLoader cols={["circle", "text", "textRight", "textRight"]} />
          )}
        </Table>
      </TableContainer>
    </Card>
  );
};
