import React, { useEffect, useRef, useState } from "react";
import FormDialog from "@superprofit/core-react-components/molecules/FormDialog";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import {
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  FormControlLabel
} from "@superprofit/core-react-components/atoms";
import { useDispatch, useSelector } from "react-redux";
import { PDFDownloadLink } from "@react-pdf/renderer";

import {
  init,
  update,
  download,
  hide
} from "../../../redux/modules/ui/pdf/dialog/actions";
import {
  Autocomplete,
  Typography
} from "@superprofit/core-react-components/atoms";
import { KeyboardDatePicker } from "@superprofit/core-react-components/atoms/pickers";
import InputLabel from "@superprofit/core-react-components/atoms/InputLabel";
import AutocompleteProjects from "../../../pages/protected/invoices/AutocompleteProjects";
import { TEMPLATES } from "../PDFRenderer";
import ByProject from "./templates/ByProject";
import ByUserAndProject from "./templates/ByUserAndProject";
import AutocompleteUsers from "@superprofit/core-react-components/molecules/AutocompleteUsers";
import { useTranslation } from "react-i18next";

export default ({ ...rest }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const downloadLinkParentRef = useRef();
  useEffect(() => {
    dispatch(init());
  }, [dispatch]);
  const customers = useSelector(state => state.api.customers.list);
  const projects = useSelector(state => state.api.projects.list);
  const users = useSelector(state => state.api.users.list);
  const open = useSelector(state => state.ui.pdf.dialog.open);
  const templateId = useSelector(state => state.ui.pdf.dialog.templateId);
  const loading = useSelector(state => state.ui.pdf.dialog.loading);
  const fromDate = useSelector(state => state.ui.pdf.dialog.fromDate);
  const toDate = useSelector(state => state.ui.pdf.dialog.toDate);
  const selectedUsers = useSelector(state => state.ui.pdf.dialog.users);
  const selectedProjects = useSelector(state => state.ui.pdf.dialog.projects);
  const selectedCustomer = useSelector(state => state.ui.pdf.dialog.customer);
  const entries = useSelector(state => state.ui.pdf.dialog.entries);
  const currency = useSelector(state => {
    const curr = state.api.companySettings.settings?.currency?.value;
    return curr && curr !== "none" ? curr : undefined;
  });
  const projectsOptions = projects?.sort(
    (a, b) => -b.customer[0]?.localeCompare(a.customer[0])
  );
  // const usersOptions = users?.sort((a, b) => {
  //   if (b.displayName && a.displayName)
  //     return -b.displayName?.localeCompare(a.displayName);
  //   return -b.id?.localeCompare(a.id);
  // });
  const handleOnClose = () => dispatch(hide());
  const handleOnDownload = () => {
    dispatch(
      download({
        linkContainer: downloadLinkParentRef.current
      })
    );
  };
  const handleOnChangeProject = (e, value) =>
    dispatch(update({ projects: value }));
  const handleOnChangeUsers = (e, value) => dispatch(update({ users: value }));
  const handleOnChangeFromDate = value => dispatch(update({ fromDate: value }));
  const handleOnChangeToDate = value => dispatch(update({ toDate: value }));
  const handleOnChangeCustomer = (e, value) => {
    dispatch(update({ customer: value }));
  };
  const handleOnTemplateChange = e => {
    dispatch(update({ templateId: e.target.value }));
  };

  const getPdfDocument = () => {
    if (templateId === TEMPLATES.BY_USER_AND_PROJECT)
      return (
        <ByUserAndProject
          currency={currency}
          projects={projects}
          users={users}
          entries={entries}
        />
      );
    if (templateId === TEMPLATES.BY_PROJECT)
      return (
        <ByProject
          currency={currency}
          projects={projects}
          users={users}
          entries={entries}
        />
      );
  };

  const customerOptions = customers.sort(
    (a, b) => -b.name?.localeCompare(a.name)
  );

  const canCreatePDF =
    (selectedCustomer || selectedProjects.length > 0) &&
    selectedUsers.length > 0 &&
    !!fromDate &&
    !!toDate;

  return (
    <FormDialog
      {...rest}
      saveActionTitle={t("common.create")}
      onSave={handleOnDownload}
      open={open}
      onClose={handleOnClose}
      title="Create PDF"
      inProgress={loading}
      disabled={!canCreatePDF}
    >
      <Autocomplete
        getOptionSelected={(option, val) => {
          return option.id === val.id;
        }}
        options={customerOptions}
        renderOption={option => option.name}
        getOptionLabel={option => option.name}
        // inputValue={(selectedCustomer || {}).name || ""}
        value={selectedCustomer || null}
        onChange={handleOnChangeCustomer}
        // style={{ width: 300 }}
        disabled={selectedProjects?.length > 0 || loading}
        renderInput={params => (
          <>
            <InputLabel variant="standard" shrink={false}>
              Choose a customer or multiple projects
            </InputLabel>
            <TextField
              {...params}
              variant="filled"
              placeholder={
                selectedProjects?.length > 0
                  ? "Remove the projects to choose a customer"
                  : "Choose a customer"
              }
            />
          </>
        )}
        // renderInput={(params) => (
        //   <TextField {...params} variant="filled" placeholder="Choose exactly one customer"/>
        // )}
      />
      <Autocomplete
        multiple
        getOptionSelected={(option, val) => {
          return option.id === val.id;
        }}
        options={projectsOptions}
        groupBy={option =>
          customers.find(c => c.id === option.customer)?.name || "Other"
        }
        disabled={selectedCustomer?.id || loading}
        getOptionLabel={option => option.name}
        onChange={handleOnChangeProject}
        // style={{ width: 450 }}
        value={selectedProjects}
        // onBlur={handleOnBlurProject}
        renderInput={params => (
          <TextField
            {...params}
            variant="filled"
            placeholder={
              selectedCustomer
                ? "Remove the customer to choose projects"
                : "Choose one or more projects"
            }
          />
        )}
      />

      <AutocompleteUsers
        disabled={loading}
        // onClose={() => {
        //   setIsClosable(true);
        // }}
        // defaultValue={project?.team?.map(u => usersById[u])}
        users={users}
        onChange={handleOnChangeUsers}
        // onOpen={e => setIsClosable(false)}
        variant="filled"
        fullWidth
        label={t("common.users")}
        placeholder="Select at least one"
        value={selectedUsers}
      />
      {/*<Autocomplete*/}
      {/*  multiple*/}
      {/*  getOptionSelected={(option, val) => {*/}
      {/*    return option.id === val.id;*/}
      {/*  }}*/}
      {/*  options={usersOptions}*/}
      {/*  getOptionLabel={(option) => option.displayName || option.id}*/}
      {/*  onChange={handleOnChangeUsers}*/}
      {/*  value={selectedUsers}*/}
      {/*  // style={{ width: 450 }}*/}
      {/*  // onBlur={handleOnBlurUsers}*/}
      {/*  renderInput={(params) => (*/}
      {/*    <>*/}
      {/*      <InputLabel variant="standard" shrink={false}>*/}
      {/*        Users*/}
      {/*      </InputLabel>*/}
      {/*      <TextField {...params} placeholder="Select at least one" variant="filled" />*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*/>*/}

      <InputLabel variant="standard" shrink={false} id="invoice-label-due-date">
        From
      </InputLabel>
      <KeyboardDatePicker
        disabled={loading}
        margin="normal"
        inputVariant="filled"
        format="DD/MM/YYYY"
        value={fromDate.format()}
        onChange={handleOnChangeFromDate}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
      />
      <InputLabel variant="standard" shrink={false} id="invoice-label-due-date">
        To
      </InputLabel>
      <KeyboardDatePicker
        disabled={loading}
        margin="normal"
        inputVariant="filled"
        format="DD/MM/YYYY"
        value={toDate.format()}
        onChange={handleOnChangeToDate}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
      />
      <FormControl component="fieldset" disabled={loading}>
        <FormLabel component="legend">Structure pdf by</FormLabel>
        <RadioGroup
          aria-label="template structure"
          name="template"
          value={templateId}
          onChange={handleOnTemplateChange}
        >
          <FormControlLabel
            value={TEMPLATES.BY_USER_AND_PROJECT}
            control={<Radio />}
            label="User and project"
          />
          <FormControlLabel
            value={TEMPLATES.BY_PROJECT}
            control={<Radio />}
            label="Project"
          />
          {/*<FormControlLabel value={TEMPLATES.BY_USER} control={<Radio />} label="By user" />*/}
        </RadioGroup>
      </FormControl>
      <div ref={downloadLinkParentRef} style={{ display: "none" }}>
        {projects?.length > 0 && users?.length > 0 && entries?.length > 0 && (
          <PDFDownloadLink
            document={getPdfDocument()}
            fileName={`${fromDate.format("D.MMMM")}-${toDate.format(
              "D.MMMM"
            )}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? "NOT_READY" : "READY"
            }
          </PDFDownloadLink>
        )}
      </div>
    </FormDialog>
  );
};
