export const GET = "api/timesheetEntries/GET";
export const GET_SUCCESS = "api/timesheetEntries/GET_SUCCESS";
export const GET_FAILURE = "api/timesheetEntries/GET_FAILED";

export const SAVE = "api/timesheetEntries/SAVE";
export const SAVE_SUCCESS = "api/timesheetEntries/SAVE_SUCCESS";
export const SAVE_FAILURE = "api/timesheetEntries/SAVE_FAILED";

export const BATCH_SAVE = "api/timesheetEntries/BATCH_SAVE";
export const BATCH_SAVE_SUCCESS = "api/timesheetEntries/BATCH_SAVE_SUCCESS";
export const BATCH_SAVE_FAILURE = "api/timesheetEntries/BATCH_SAVE_FAILURE";

export const WATCH_GET = "api/timesheetEntries/WATCH_GET";
export const WATCH_SAVE = "api/timesheetEntries/WATCH_SAVE";
export const WATCH_BATCH_SAVE = "api/timesheetEntries/WATCH_BATCH_SAVE";
