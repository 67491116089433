// import { useEffect } from "react";
import { db } from "../firebase";
// import { Condition, Query } from "@superprofit/core-firestore-models";
import { useQuery /*useQueryClient*/ } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import XstatsUserProjectHoursByWeek from "../models/XstatsUserProjectHoursByWeek";

const useUserStats = (
  {
    user,
    year,
    month
  }: {
    user?: string;
    year: number;
    month?: number;
  },
  queryOpts?: { staleTime?: number; cacheTime?: number }
) => {
  const workspace = useWorkspace();
  // const queryClient = useQueryClient();
  const { staleTime, cacheTime } = queryOpts || {};
  const get = async () => {
    let snapshotRef = db
      .collection("workspaces")
      .doc(workspace)
      .collection("xstats_user_project_hours_by_week")
      .where("year", "==", year);

    if (user) snapshotRef = snapshotRef.where("user", "==", user);
    if (month) snapshotRef = snapshotRef.where("month", "==", month);
    const snapshot = await snapshotRef
      .withConverter(XstatsUserProjectHoursByWeek.converter)
      .get();
    const all: XstatsUserProjectHoursByWeek[] = [];

    snapshot.forEach(doc => {
      all.push(doc.data());
    });
    return all;
  };

  const key = [
    workspace,
    year,
    user,
    month,
    "xstats_user_project_hours_by_week"
  ];
  const query = useQuery(key, get, {
    enabled: !!year,
    staleTime: staleTime || 1000 * 5, //5 seconds stale time. May need to adjust
    cacheTime
  });

  // useEffect(() => {
  //   let unsub: () => void | undefined;
  //   const doIt = async () => {
  //     if (!user || !year) return;
  //     const query = new Query();
  //     query.addCondition(new Condition("user", "==", user));
  //     query.addCondition(new Condition("year", "==", year));
  //     unsub = await db
  //       .collection("workspaces")
  //       .doc(workspace)
  //       .collection("xstats_user_project_hours_by_week")
  //       .where("year", "==", year)
  //       .where("user", "==", user)
  //       .onSnapshot(snap => {
  //         const all: any[] = [];
  //         snap.forEach(doc => {
  //           all.push({ ...doc.data(), id: doc.id });
  //         });
  //         queryClient.setQueryData(key, all);
  //       });
  //   };
  //   doIt().catch(console.error);
  //
  //   return () => unsub && unsub();
  // });

  return query;
};

export default useUserStats;
