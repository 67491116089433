import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  POST,
  POST_FAILURE,
  POST_SUCCESS,
} from "./constants";

const initialState = {
  loading: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET:
      return {
        ...initialState,
        email: payload.email,
        loading: true,
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        role: payload.role,
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case POST:
      return {
        ...state,
        email: payload.email,
        loading: true,
      };
    case POST_SUCCESS:
      return {
        ...state,
        loading: false,
        role: payload.role,
      };
    case POST_FAILURE:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };

    default:
      return state;
  }
};
