import React from "react";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import CardContent from "@superprofit/core-react-components/atoms/CardContent";
import { Skeleton } from "@material-ui/lab";
import styled from "styled-components";
import Card from "@superprofit/core-react-components/atoms/Card";

const DashboardCard = styled(Card)`
  position: relative;
  pointer-events: all;
`;

export default props => {
  return (
    <DashboardCard {...props}>
      <CardHeader
        title={<Skeleton component="div" variant="text" width={200} />}
        subheader={<Skeleton component="div" variant="text" width={200} />}
      />
      <CardContent>
        <Skeleton component="div" variant="rect" height={300} width="100%" />
      </CardContent>
    </DashboardCard>
  );
};
