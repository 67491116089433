import { SAVE_SUCCESS, SAVING, SAVE_FAILED } from "./constants";

export const saveSuccess = settings => ({
  type: SAVE_SUCCESS,
  payload: { settings }
});

export const save = settings => ({
  type: SAVING,
  payload: { settings }
});

export const saveFailed = error => ({
  type: SAVE_FAILED,
  payload: { error }
});
