import React from "react";
import {
  Container,
  Email,
  Tags,
  Period,
  UserName
} from "./userInfo/userInfo.styles";

export default ({ email, tags, displayName, period }) => (
  <Container>
    <UserName>{displayName}</UserName>
    {/*<Email>{email}</Email>*/}
    {tags && <Tags>{[...tags]}</Tags>}
    <Period>{period}</Period>
  </Container>
);
