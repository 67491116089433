import styled from "styled-components";

export const StyledPageHeader = styled.div`
  color: ${props => props.theme.palette.text.primary};
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(4)}px;
  
  h2 {
    color: ${props => props.theme.palette.text.tertiary};
    margin-bottom: ${props => props.theme.spacing(2)}px;
  }
`;
