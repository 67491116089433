import * as yup from "yup";
import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import User from "../../../../models/User";
import useFixEmployeeNumbersDialog from "./useFixEmployeeNumbersDialog";
import useTimetUserMutation from "../../../../hooks/useTimetUserMutation";
import useUsers from "../../../../hooks/useUsers";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ConfirmationDialog from "../../../../components/molecules/ConfirmationDialog";
import FormDialog from "../../../../components/molecules/FormDialog";
import Grid from "../../../../components/atoms/Grid";
import TextField from "../../../../components/atoms/TextField";
import useTimetUserUpdateMutation from "../../../../hooks/useTimetUserUpdateMutation";
import useEmployeeNumbers from "../../../../hooks/useEmployeeNumbers";
import useGlobalSnackbar from "../../../../hooks/useGlobalSnackbar";
import { promiseDelay } from "../../../../helpers";
import useUsersWithoutEmployeeNumber from "./useUsersWithoutEmployeeNumber";

interface IFormInput {
  [key: string]: number | undefined;
}

export default ({ ...rest }) => {
  const { t } = useTranslation();
  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);
  const { update: updateSnackbar } = useGlobalSnackbar();
  const mutation = useTimetUserMutation();
  const { open, close } = useFixEmployeeNumbersDialog();
  const { data: users = [], isInitialLoading, isSuccess, refetch } = useUsers();
  const { mutateAsync: updateUserAsync } = useTimetUserUpdateMutation();
  const { employeeNumbersMap } = useEmployeeNumbers();
  const encodeEmailForRegister = (email: string) => email.replaceAll(".", "-");
  const decodeEmailForRegister = (email: string) => email.replaceAll("-", ".");
  const schema = useMemo(() => {
    let shape: any = {};
    for (let user of users) {
      shape[encodeEmailForRegister(user.email)] = yup
        .number()
        .notOneOf(Array.from(employeeNumbersMap.keys()))
        .typeError(t("common.invalidNumber"));
    }
    return yup.object().shape(shape);
  }, [users, t, employeeNumbersMap]);

  const shouldResetForm = !isInitialLoading && isSuccess;
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    shouldUnregister: true
  });

  useEffect(() => {
    if (shouldResetForm) {
      reset({});
    }
  }, [shouldResetForm, reset]);

  const handleConfirmClose = () => {
    close();
  };

  const handleCancelClose = () => {
    setShowUnsavedConfirmation(false);
  };

  const handleOnClose = () => {
    if (mutation.isLoading) return;
    else {
      handleConfirmClose();
    }
  };
  const usersWithoutEmployeeNumber = useUsersWithoutEmployeeNumber();
  const onSubmit: SubmitHandler<IFormInput> = async data => {
    const promises: Promise<void>[] = [];
    const entries = Object.entries(data);

    for (let [key, value] of entries) {
      const email = decodeEmailForRegister(key);

      promises.push(
        updateUserAsync({ id: email, metadata: { employeeNumber: value } })
      );
    }

    try {
      await Promise.all(promises);
      handleConfirmClose();
      updateSnackbar({
        open: true,
        message: `${t("common.saved")}!   🎉`,
        alert: { severity: "success" }
      });
      await promiseDelay(2000);
      updateSnackbar({
        open: false
      });
      await refetch();
    } catch (error) {
      console.log(error);
      updateSnackbar({
        open: true,
        message: `${t("common.error")}! ${e} 🚨 `,
        alert: { severity: "error" }
      });
    }
  };

  return (
    <Fragment>
      <ConfirmationDialog
        key="unsaved"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showUnsavedConfirmation}
      />

      <FormDialog
        {...rest}
        keepMounted={false}
        PaperProps={{ component: "form", onSubmit: handleSubmit(onSubmit) }}
        SubmitButtonProps={{ type: "submit" }}
        key="form"
        saveActionTitle={t("common.save")}
        open={open}
        onClose={handleOnClose}
        title={t("pages.companySettings.nettlonn.employeeNumbersDialog.title")}
        inProgress={mutation.isLoading}
      >
        <Grid container spacing={2}>
          {usersWithoutEmployeeNumber.map((user: User) => (
            <Grid item xs={12} key={user.email}>
              <TextField
                {...register(encodeEmailForRegister(user.email))}
                fullWidth
                variant="filled"
                label={user.email}
                error={!!errors[encodeEmailForRegister(user.email)]}
                helperText={errors[encodeEmailForRegister(user.email)]?.message}
              />
            </Grid>
          ))}
        </Grid>
      </FormDialog>
    </Fragment>
  );
};
