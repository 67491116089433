import React, { forwardRef } from "react";
import Link from "../atoms/Link";
import { getPathWithParamsRaw } from "../../router/routes";
import UserDisplay from "./UserDisplay";

interface IUserLink {
  name?: string | null;
  email: string;
  hideEmail?: boolean;
}

export default forwardRef<HTMLAnchorElement, IUserLink>(
  ({ name, email, hideEmail = false, ...rest }, ref) => (
    <Link
      withWorkspacePrefix
      to={getPathWithParamsRaw("userOverview", { id: email })}
      color="inherit"
      {...rest}
      ref={ref}
    >
      <UserDisplay name={name} email={email} hideEmail={hideEmail} />
    </Link>
  )
);
