import React, { forwardRef } from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import UserAvatar from "../../../../components/molecules/UserAvatar";
import UserLink from "../../../../components/molecules/UserLink";

export default forwardRef(({ onChangeRate, project, user }, ref) => {
  const labelId = `user-list-item-label-${user.id}`;

  return (
    <TableRow ref={ref}>
      <TableCell style={{ maxWidth: 80, width: 80 }}>
        <UserAvatar user={user} />
      </TableCell>

      <TableCell id={labelId}>
        <UserLink name={user.displayName} email={user.email} hideEmail />
      </TableCell>

      <TableCell align="right">
        <TextField
          disabled={!project?.salaryEnabled}
          defaultValue={
            !project?.salaryEnabled
              ? "-"
              : project?.userSalaryRate
              ? project?.userSalaryRate[user.id]
              : ""
          }
          placeholder={!project?.salaryEnabled ? "-" : project?.salaryRate}
          fullWidth
          variant="filled"
          type="number"
          style={{ maxWidth: 100 }}
          onBlur={e => onChangeRate(user.id, e.target.value)}
        />
      </TableCell>
    </TableRow>
  );
});
