import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";

const UserContext = React.createContext({
  user: {},
  userAccess: {},
  settings: {}
});

export const useIsAdmin = () => {
  const { userAccess } = useContext(UserContext);
  return userAccess?.isAdmin || false;
};

export const useIsActive = () => {
  const { userAccess } = useContext(UserContext);
  return userAccess?.isActive || false;
};

export const useUser = () => {
  const { user } = useContext(UserContext);
  return user;
};

export const useUserSettings = () => {
  const { settings } = useContext(UserContext);
  return settings;
};

export default props => {
  const { user = {} } = useSelector(state => state.api.auth);
  const { list = [] } = useSelector(state => state.api.userAccess);
  const { settings } = useSelector(state => state.api.userSettings);

  const userAccess = useMemo(() => list.find(ua => ua.id === user.email), [
    user,
    list
  ]);

  return (
    <UserContext.Provider value={{ settings, user, userAccess }} {...props} />
  );
};
