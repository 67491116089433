import { SET_PROGNOSIS, SET_LOADING } from "./constants";

export const setPrognosis = (prognosis, adjustedPrognosis, actualTD) => ({
  type: SET_PROGNOSIS,
  payload: { prognosis, adjustedPrognosis, actualTD }
});

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});
