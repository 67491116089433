import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../index";
import { set } from "../../../../redux/modules/ui/timesheet/filter/actions";
import {
  OnlyShowProjectsWith,
  State as FilterState
} from "../../../../redux/modules/ui/timesheet/filter/types";
import { useMemo } from "react";
import Project, { IProject } from "../../../../models/Project";
import useActiveProjects from "../../../../hooks/useActiveProjects";
import usePeriod from "./usePeriod";
import { useDebouncedCallback } from "use-debounce";
import useCustomers from "../../../../hooks/useCustomers";
import Customer from "../../../../models/Customer";
import { useOverrideTimesheetUser } from "./useOverrideTimesheetUser";

export interface IFilteredProject extends IProject {
  expandedCustomer?: any;
}

const useFilterProjects = () => {
  const filter = useSelector((state: RootState) => state.ui.timesheet.filter);
  const dispatch = useDispatch();
  const overrideUserEmail = useOverrideTimesheetUser();
  const update = (updates: Partial<FilterState>) => {
    dispatch(set(updates));
  };
  const { data: customers } = useCustomers();
  const toggleModal = () => {
    dispatch(set({ showModal: !filter.showModal }));
  };
  const [debouncedUpdate] = useDebouncedCallback(update, 100);
  const { search, onlyShowProjectsWith } = filter;

  const { update: setPeriod, ...period } = usePeriod();
  const filteredPeriod = {
    year: onlyShowProjectsWith === null ? undefined : period.year,
    month:
      onlyShowProjectsWith === OnlyShowProjectsWith.hoursThisMonth
        ? period.month
        : undefined,
    week:
      onlyShowProjectsWith === OnlyShowProjectsWith.hoursThisWeek
        ? period.week
        : undefined
  };
  const activeProjectsQuery = useActiveProjects({
    period: filteredPeriod,
    id: overrideUserEmail ?? undefined
  });

  const filtered = activeProjectsQuery.data || [];
  const searched = useMemo(() => {
    const f = filtered.map((p: IFilteredProject) => {
      if (p.customer)
        p.expandedCustomer = customers?.find(
          (c: Customer) => c.id === p.customer
        );
      return p;
    });
    if (search === "") return f;
    return f.filter(
      (p: IProject) => p.name.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  }, [filtered, search, customers]);
  return {
    ...activeProjectsQuery,
    data: searched,
    update: debouncedUpdate,
    toggleModal,
    isLoading: activeProjectsQuery.isLoading,
    ...filter
  };
};

export default useFilterProjects;
