import {
  HIDE_LEAD_DIALOG,
  SHOW_LEAD_DIALOG,
  UPDATE_LEAD_DIALOG_DATA
} from "./constants";

import { SAVE_SUCCESS, SAVE } from "../../../api/leads/constants";

const initialState = {
  open: false,
  updates: {},
  lead: {},
  saving: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LEAD_DIALOG:
      return {
        ...initialState,
        open: true,
        lead: action.payload.lead
      };

    case SAVE:
      return {
        ...state,
        saving: true
      };

    case SAVE_SUCCESS: // Hide dialog when saved
    case HIDE_LEAD_DIALOG:
      return {
        ...state,
        open: false,
        saving: false
      };

    case UPDATE_LEAD_DIALOG_DATA:
      return {
        ...state,
        updates: {
          ...state.updates,
          ...action.payload.updates
        }
      };

    default:
      return state;
  }
};
