import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
  REMOVE,
  REMOVE_FAILURE,
  REMOVE_SUCCESS,
  WATCH_GET,
  WATCH_SAVE,
  WATCH_REMOVE,
} from "./constants";
import { Comment } from "./types";
import {ITimesheetComment} from "../../../../../models/TimesheetComment";

export const get = () => ({
  type: GET,
  payload: {},
});

export const getSuccess = (comments: Comment[]) => ({
  type: GET_SUCCESS,
  payload: { comments },
});

export const getFailure = (error: string) => ({
  type: GET_FAILURE,
  payload: { error },
});

export const watchGetTimesheetComments = (year: number, week: number) => ({
  type: WATCH_GET,
  payload: { year, week },
});

export const save = (comment: Comment, isNew?: boolean) => ({
  type: SAVE,
  payload: { comment, isNew },
});

export const saveSuccess = (comment: Comment, isNew?: boolean) => ({
  type: SAVE_SUCCESS,
  payload: { comment, isNew },
});

export const saveFailure = (comment: Comment, error: string) => ({
  type: SAVE_FAILURE,
  payload: { error, comment },
});

export const watchSaveTimesheetComment = (comment: Comment /* updates */) => ({
  type: WATCH_SAVE,
  payload: { comment /* updates */ },
});

export const remove = (comment: Comment) => ({
  type: REMOVE,
  payload: { comment },
});

export const removeSuccess = (comment: Comment) => ({
  type: REMOVE_SUCCESS,
  payload: {comment },
});

export const removeFailure = (comment: Comment, error: string) => ({
  type: REMOVE_FAILURE,
  payload: { error, comment },
});

export const watchRemoveTimesheetComment = (comment: ITimesheetComment /* updates */) => ({
  type: WATCH_REMOVE,
  payload: { comment /* updates */ },
});
