import React from "react";
import {
  Checkbox,
  Chip,
  Autocomplete,
  TextField
} from "@superprofit/core-react-components/atoms";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ProjectAvatar from "./ProjectAvatar";
import {
  AutocompleteProps,
  AutocompleteRenderOptionState
} from "@material-ui/lab";
import Project from "../../models/Project";
import {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams
} from "@material-ui/lab/Autocomplete/Autocomplete";
import { AutocompleteCloseReason } from "@material-ui/lab/useAutocomplete/useAutocomplete";
import { useTranslation } from "react-i18next";
import { Controller, ControllerProps } from "react-hook-form";
import useProjectsMap from "../../hooks/useProjectsMap";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Option = Project;
type MultiSelectProjects = Partial<AutocompleteProps<any, any, any, any>> & {
  projects: Option[];
  inputRef?: React.Ref<any>;
  label?: string;
  variant?: "standard" | "filled" | "outlined";
};

const renderTags = (
  value: Option[],
  getCustomizedTagProps: AutocompleteGetTagProps
) => {
  return value.map((project, index) => (
    <Chip
      color="primary"
      label={project.name}
      size="medium"
      avatar={<ProjectAvatar project={project} />}
      {...getCustomizedTagProps({ index })}
    />
  ));
};

const MultiSelectProjects = ({
  variant = "filled",
  fullWidth = true,
  projects,
  onClose,
  ...rest
}: MultiSelectProjects): JSX.Element => {
  const { t } = useTranslation();
  const label =
    rest.label || t("components.molecules.multiSelectProjects.label");
  const placeholder =
    rest.placeholder ||
    t("components.molecules.multiSelectProjects.placeholder");

  return (
    <Autocomplete
      multiple
      renderTags={renderTags}
      options={projects}
      disableCloseOnSelect
      onClose={(
        event: React.ChangeEvent<{}>,
        reason: AutocompleteCloseReason
      ) =>
        setTimeout(() => {
          // hack to prevent backdrop close parent modal when closing popper
          onClose && onClose(event, reason);
        }, 500)
      }
      getOptionLabel={(option: Option) => option.name}
      renderOption={(
        option: Option,
        { selected }: AutocompleteRenderOptionState
      ) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            color="primary"
            checked={selected}
          />
          {option.name}
        </React.Fragment>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          ref={rest.inputRef}
          variant={variant}
          fullWidth={fullWidth}
          label={label}
          placeholder={placeholder}
        />
      )}
      {...rest}
    />
  );
};

interface MultiSelectProjectsFormControllerProps<T>
  extends MultiSelectProjects {
  ControllerProps: Omit<ControllerProps<T>, "render">;
}

function withFormController<FieldValues>() {
  return (props: MultiSelectProjectsFormControllerProps<FieldValues>) => {
    const { ControllerProps, projects, ...other } = props;
    const { name = "projects", control } = ControllerProps;
    const projectsMap = useProjectsMap(projects);
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value, ref, onChange, ...rest } = field;
          return (
            <MultiSelectProjects
              fullWidth
              value={value?.map((id: string) => projectsMap.get(id)) || []}
              inputRef={ref}
              onChange={(
                e: React.ChangeEvent<HTMLElement>,
                selected: Project[]
              ) => {
                onChange(
                  selected.map(({ id }) => id).filter(Boolean) as string[]
                );
              }}
              projects={projects}
              {...other}
              {...rest}
            />
          );
        }}
      />
    );
  };
}

export { withFormController };

export default MultiSelectProjects;
