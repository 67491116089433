import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { StyledProgressBar } from "./progressBar/ProgressBar.styles";

interface IProgressBar {
  width?: number;
  color: "primary" | "secondary" | "tertiary" | string;

  alternateColor?: "primary" | "secondary" | "tertiary";
  progress: number;
  size?: "small" | "medium" | "large";
  align?: "left" | "right";
}

const ProgressBar = forwardRef<HTMLDivElement, IProgressBar>((props, ref) => {
  const { size = "medium", align = "right", width = 100, ...rest } = props;
  return (
    <StyledProgressBar
      width={width}
      align={align}
      size={size}
      {...rest}
      ref={ref}
    />
  );
});

export default ProgressBar;
