import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "@material-ui/core";
import {
  Grid,
  Typography,
  Page
} from "@superprofit/core-react-components/atoms";
import { useAuth } from "../../hooks/useAuth";

export default () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { signOut } = useAuth();
  const userClaims = useSelector(
    ({
      api: {
        auth: { idTokenResult }
      }
    }) => idTokenResult?.claims
  );
  const handleLogout = async () => {
    await signOut();
  };
  const ws = new URLSearchParams(location.search).get("workspace");

  return (
    <Grid
      style={{ padding: "2rem" }}
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h1" gutterBottom>
          {t("No access to workspace")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          {t("You tried accessing")} <b>{ws}</b>{" "}
          {t("but we could not find your email")} <b>{userClaims?.email}</b>
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          {t("Contact your workspace administrator if you should have access.")}
        </Typography>
      </Grid>
      <Grid item style={{ padding: "2rem" }}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12} gutterBottom>
            <Link gutterBottom variant="caption" href={window.location.origin}>
              timet.io
            </Link>
          </Grid>
          <Grid item>
            <Typography variant="caption">{t("Logged in as ")}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">{userClaims?.email}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom variant="caption">
              {t("Not you?")}{" "}
            </Typography>
            <Link gutterBottom variant="caption" onClick={handleLogout}>
              Sign out
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
