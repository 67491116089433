import { TOGGLE_VIEW } from "./constants";

const initialState = {
  open: false,
  invoice: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_VIEW:
      return {
        ...state,
        open: !state.open,
        invoice: state.open ? {} : action?.payload?.invoice
      };
    default:
      return state;
  }
};
