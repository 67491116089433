import Grid from "@material-ui/core/Grid";
import Typography from "../../../atoms/Typography";
import PerSeatProduct from "../PerSeatSubscription";
import {
  Button,
  Card,
  CircularProgress,
  NumberFormatIntl,
  TextField,
} from "../../../atoms";
import CardContent from "../../../atoms/CardContent";
import React, { useContext, useRef, useState } from "react";
import { actionTypes } from "./reducer";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ButtonContainer } from "./updateSubscription/updateSubscription.styles";
import handleCreateSubscription from "./createSubscription/helpers/handleCreateSubscription";
import Context from "./context";
import { options as cardElementOpts, iframeStyles } from "./cardElement";
import Divider from "../../../atoms/Divider";
import Isemail from "isemail";
import CouponCard from "./CouponCard";

export default ({ state, dispatch }) => {
  const stripe = useStripe();
  const elements = useElements();
  const nameRef = useRef();
  const emailRef = useRef();
  const [sendingInvoice, setSendingInvoice] = useState(false);
  const [sendingCard, setSendingCard] = useState(false);
  const { reset, initialize } = useContext(Context);
  if (
    !state.showCreateSubscription ||
    state.isLoadingProducts ||
    state.isLoadingSubscriptions
  )
    return null;

  const {
    products,
    subscriptionInformation,
    createdSubscriptionProductId,
    createdSubscriptionSeats,
    upcomingInvoice,
    isLoadingUpcomingInvoice,
    isCreatingSubscription,
    baseUrl: apiBase,
    customerId,
    firebaseIdToken,
    createdSubscriptionStatus,
    billingInformationInputErrors,
    isUpdatingSubscription,
    coupon,
  } = state;

  const selectedProduct = products.find(
    (p) => p?.id === createdSubscriptionProductId
  );

  const handleProductChange = (id) => (seats) => {
    dispatch({
      type: actionTypes.setCreatedSubscriptionProductId,
      payload: { id },
    });
    dispatch({
      type: actionTypes.setCreatedSubscriptionSeats,
      payload: { seats },
    });
  };
  const price = selectedProduct?.prices[0];
  const intlOptions = {
    style: "currency",
    currency: price?.currency.toUpperCase(),
  };

  let currentTier = 0;
  let diff = 0;
  const tiers = [];
  for (let i = 1; i <= createdSubscriptionSeats; i++) {
    const t = price?.tiers[currentTier];
    if (i === createdSubscriptionSeats) {
      tiers.push({ ...t, amountOfUsers: i - diff });
    } else if (i === t.up_to) {
      tiers.push({ ...t, amountOfUsers: t.up_to - diff });
      diff = t.up_to;
      currentTier++;
    }
  }

  const validateInputs = () => {
    const email = emailRef?.current?.value;
    const name = nameRef?.current?.value;
    let ret;
    if (name?.length <= 0) {
      ret = { name: true };
    }
    if (!Isemail.validate(email)) {
      ret = { ...(ret || {}), email: true };
    }
    return ret;
  };

  const handleClick = (e, sendInvoice = false) => {
    e.preventDefault();
    if (createdSubscriptionStatus?.status === "success") {
      reset();
      initialize();
    } else {
      const billingInformationInputErrors = validateInputs();
      if (billingInformationInputErrors) {
        dispatch({
          type: actionTypes.setBillingInformationInputErrors,
          payload: { billingInformationInputErrors },
        });
      } else {
        dispatch({
          type: actionTypes.setBillingInformationInputErrors,
          payload: {},
        });
        const handleCreate = handleCreateSubscription({
          stripe,
          dispatch,
          priceId: selectedProduct?.prices[0].id,
          quantity: createdSubscriptionSeats,
          elements,
          customerId,
          apiBase,
          firebaseIdToken,
          contactName: nameRef?.current?.value,
          contactEmail: emailRef?.current?.value,
          coupon: coupon?.id,
          sendInvoice,
        });
        handleCreate(e).catch((e) => console.error(e));
      }
    }
  };

  const generatePrice = (t) => (
    <Grid item xs={12}>
      <Typography variant="caption" color="textSecondary">
        {" "}
        {t.amountOfUsers}
        {"x "}
        {selectedProduct.name}
        {" (at  "}
        <NumberFormatIntl
          locales="en"
          number={t.unit_amount / 100}
          options={intlOptions}
        />{" "}
        {"/ month): "}{" "}
        <NumberFormatIntl
          locales="en"
          number={(t.unit_amount * t.amountOfUsers) / 100}
          options={intlOptions}
        />
      </Typography>
    </Grid>
  );

  const getTotalMarkup = () => {
    if (createdSubscriptionStatus?.status === "success") return null;
    if (!coupon?.valid) {
      return (
        <Grid item xs={12}>
          <Typography
            gutterBottom
            color="textPrimary"
            variant="h3"
            component="div"
          >
            Total due now:{" "}
            <NumberFormatIntl
              locales="en"
              number={totalAmountDue / 100}
              options={intlOptions}
            />
          </Typography>
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <Typography
          gutterBottom
          color="textPrimary"
          variant="h3"
          component="div"
        >
          Total due now:{" "}
          <s>
            <NumberFormatIntl
              locales="en"
              number={totalAmountDue / 100}
              options={intlOptions}
            />
          </s>
          {"  "}
          <NumberFormatIntl
            locales="en"
            number={
              coupon.amount_off
                ? (totalAmountDue - coupon.amount_off) / 100
                : (totalAmountDue / 100) * ((100 - coupon.percent_off) / 100)
            }
            options={intlOptions}
          />
        </Typography>
      </Grid>
    );
  };

  const totalAmountDue = tiers.reduce(
    (acc, t) => acc + t.amountOfUsers * t.unit_amount,
    0
  );
  let buttonColor = "primary";
  let buttonText = "Purchase with card";
  let buttonTextEmail = "Send me an invoice";
  console.log(createdSubscriptionStatus);
  if (createdSubscriptionStatus?.status === "success") {
    buttonColor = "success";
    buttonText = "Done";
    buttonTextEmail = "Done";
  }
  if (createdSubscriptionStatus?.status === "error") {
    buttonColor = "error";
    buttonText = "Retry";
    buttonTextEmail = "Retry";
  }
  const isDisabled =
    isCreatingSubscription ||
    !createdSubscriptionProductId ||
    !createdSubscriptionSeats;

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">Purchase subscription</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              {products.map((p) => (
                <PerSeatProduct
                  selected={selectedProduct?.id === p.id}
                  product={p}
                  onChange={handleProductChange(p.id)}
                  onSelect={handleProductChange(p.id)}
                />
              ))}{" "}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CouponCard state={state} dispatch={dispatch} />
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        {/*<Grid item xs={12}>*/}
                        {/*  <Typography variant="h1" component="div">*/}
                        {/*    {createdSubscriptionStatus?.status !== "success"*/}
                        {/*      ? "Billing information"*/}
                        {/*      : "Successfully purchased subscription"}*/}
                        {/*  </Typography>*/}
                        {/*</Grid>*/}

                        {selectedProduct ? (
                          <>
                            <Grid item xs={12}>
                              <Grid container>
                                {createdSubscriptionStatus?.status ===
                                  "success" && (
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      Purchase details will arrive in your inbox
                                      shortly.
                                    </Typography>
                                  </Grid>
                                )}
                                {createdSubscriptionStatus?.status !==
                                  "success" &&
                                  tiers.map((t) => generatePrice(t))}
                                {getTotalMarkup()}

                                <Grid item xs={12}>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    You will be billed every{" "}
                                    {price?.recurring?.interval_count === 1
                                      ? ""
                                      : price?.recurring?.interval_count}{" "}
                                    {price?.recurring?.interval}
                                    {"."}
                                  </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    You can cancel your subscription at any
                                    time.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <br />
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <br />
                            {createdSubscriptionStatus?.status !==
                              "success" && (
                              <>
                                <Grid item xs={12}>
                                  <Grid
                                    id="billingInformation"
                                    component="form"
                                    container
                                    spacing={2}
                                  >
                                    <Grid item xs={12}>
                                      <Typography color="textSecondary">
                                        Billing information
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        required
                                        inputProps={{ ref: nameRef }}
                                        error={
                                          billingInformationInputErrors?.name
                                        }
                                        variant="filled"
                                        label="Name"
                                        helperText="Name of the person to contact regarding billing."
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        inputProps={{ ref: emailRef }}
                                        required
                                        error={
                                          billingInformationInputErrors?.email
                                        }
                                        variant="filled"
                                        id="standard-required"
                                        label="Email"
                                        helperText="Email to where receipts and other billing information will be sent."
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {!sendingInvoice && (
                                  <>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                      >
                                        Card details
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <CardElement
                                        options={cardElementOpts}
                                        // onChange={handleCardDetailsChange}
                                      />
                                    </Grid>
                                  </>
                                )}
                              </>
                            )}

                            {!sendingInvoice && (
                              <>
                                <Grid item xs={12}>
                                  <ButtonContainer>
                                    <Button
                                      type="button"
                                      disabled={isDisabled}
                                      variant="contained"
                                      onClick={(e) => {
                                        setSendingCard(true);
                                        handleClick(e);
                                      }}
                                      color={buttonColor}
                                      form="billingInformation"
                                    >
                                      {buttonText}
                                    </Button>
                                    {isCreatingSubscription && (
                                      <CircularProgress size={24} />
                                    )}
                                  </ButtonContainer>
                                </Grid>
                                <Grid item xs={12}>
                                  <Divider />
                                </Grid>
                              </>
                            )}

                            {!sendingCard && (
                              <>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    If you do not have your credit card at hand
                                    or you want to pay invoices manually, you
                                    can choose to do so by clicking the{" "}
                                    <b>
                                      <i>Send me an invoice</i>
                                    </b>{" "}
                                    button. You will receive an invoice in your
                                    billing email's inbox with a due date 30
                                    days every month starting now.
                                  </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                  <ButtonContainer>
                                    <Button
                                      type="button"
                                      disabled={isDisabled}
                                      variant="contained"
                                      onClick={(e) => {
                                        setSendingInvoice(true);
                                        handleClick(e, true);
                                      }}
                                      color={buttonColor}
                                      form="billingInformation"
                                    >
                                      {buttonTextEmail}
                                    </Button>
                                    {isCreatingSubscription && (
                                      <CircularProgress size={24} />
                                    )}
                                  </ButtonContainer>
                                </Grid>
                              </>
                            )}
                          </>
                        ) : (
                          <Grid item xs={12}>
                            <Typography>Please select a product</Typography>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
