import styled from "styled-components";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Grid, Card, Button } from "@superprofit/core-react-components/atoms";

export const ButtonContainer = styled.div`
  position: relative;
  > div {
    &:first-child {
      position: absolute;
      left: 50%;
      right: 50%;
      margin-top: 0.5rem;
      margin-left: -0.5rem;
    }
  }
`;

export const ErrorOutline = styled(ErrorOutlineIcon)`
  color: ${(props) => props.theme.palette.error.main};
`;
export const CheckCircle = styled(CheckCircleIcon)`
  color: ${(props) => props.theme.palette.success.main};
`;

export const ContainerGrid = styled(Grid)`
  padding: 2rem;
  max-width: 35rem;
  margin: auto;
`;
// export const WorkspaceButton = styled(Button)`
//   padding: 1rem;
//   justify-content: space-between;
// `;
//
// export const AnotherWorkspaceCard = styled(Card)`
//   background-color: ${(props) => props.theme.palette.grey.main};
//   padding: 1rem;
// `;

export const ImgGrid = styled(Grid)`
  background-color: ${(props) => props.theme.palette.primary.main};
  margin-bottom: 2rem;

  img {
    max-width: 10rem;
  }
`;
// export const LinkWrapper = styled.div`
//   ${({ disabled }) => disabled && "pointer-events: none; opacity: 0.5;"}
// `;
//
