import styled from "styled-components";
import React, {useContext} from 'react';
import { Button, CircularProgress } from "../../../atoms";
import {actionTypes} from './reducer';
import cancelSubscription from './cancelSubscription';
import AppContext from './context';

export const ButtonContainer = styled.div`
  position: relative;
  width: fit-content;

  > div {
    &:last-child {
      position: absolute;
      left: 50%;
      top: 0;
      height: 24px;
      margin-top: 8px;
      margin-left: -12px;
    }
  }
`;


export default ({ dispatch, state }) => {
  const { baseUrl, isCancellingSubscription, subscriptionInformation } = state;
  const subscription = subscriptionInformation?.subscription;
  const { reset, initialize} = useContext(AppContext);

  const handleCancel = async () => {
    dispatch({ type: actionTypes.toggleCancellingSubscription });
    try {
      const deletedSub = await cancelSubscription({
        apiBase: baseUrl,
        subscriptionId: subscription.id,
        cancelAtPeriodEnd: !subscription.cancel_at_period_end
      });
      reset();
      initialize()
      // dispatch({
      //   type: actionTypes.setCancelledSubscriptionStatus,
      //   payload: {
      //     cancelledSubscriptionStatus: {
      //       status: "success",
      //       subscription: deletedSub,
      //     },
      //   },
      // });
    } catch (e) {
      console.error(e);
      dispatch({
        type: actionTypes.setCancelledSubscriptionStatus,
        payload: {
          cancelledSubscriptionStatus: {
            status: "error",
            message: e.toString(),
          },
        },
      });
    }
    // dispatch({ type: actionTypes.toggleCancellingSubscription });
  };
  return (
    <ButtonContainer>
      <Button
        disabled={isCancellingSubscription}
        onClick={handleCancel}
        color={subscription.cancel_at_period_end ? 'primary' : 'alert'}
        variant={subscription.cancel_at_period_end ? 'contained' : 'primary'}
      >
        {subscription.cancel_at_period_end ? 'Reactivate subscription': 'Cancel subscription'}
      </Button>
      {isCancellingSubscription && <CircularProgress size={24} />}
    </ButtonContainer>
  );
};
