export type AsyncTask = () => Promise<void>;

class AsyncQueue {
  private queue: AsyncTask[];
  private isProcessing: boolean;

  constructor() {
    this.queue = [];
    this.isProcessing = false;
  }

  // Function to add a task to the queue
  public enqueue(task: AsyncTask): void {
    this.queue.push(task);
    this.processQueue().catch(e => {
      this.isProcessing = false;
      this.queue = [];
      console.error(e);
    });
  }

  public get taskCount(): number {
    return this.queue.length;
  }

  // Function to start processing the queue
  private async processQueue(): Promise<void> {
    if (this.isProcessing) return;
    this.isProcessing = true;

    while (this.queue.length > 0) {
      const task = this.queue.shift();
      if (task) {
        await task();
      }
    }

    this.isProcessing = false;
  }
}

export default AsyncQueue;
