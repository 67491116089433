import React from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import UserAvatar from "../../../../components/molecules/UserAvatar";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import DurationFormat from "../../../../components/atoms/DurationFormat";

export default ({ year, month, user, project, hours, onChange }) => {
  return (
    <TableRow>
      <TableCell style={{ maxWidth: 80, width: 80 }}>
        <UserAvatar user={user} />
      </TableCell>

      <TableCell>
        <b>{user.displayName}</b>
      </TableCell>

      <TableCell align="right">
        <b>
          <DurationFormat value={hours} />
        </b>
      </TableCell>

      <TableCell align="right">
        <TextField
          defaultValue={project.getPlannedHoursByMonthByUser(
            year,
            month,
            user.id
          )}
          placeholder={project.getPlannedHoursByMonthByUser(
            year,
            month,
            user.id
          )}
          fullWidth
          variant="filled"
          type="number"
          style={{ maxWidth: 100 }}
          onBlur={e => onChange(year, month, user.id, e.target.value)}
        />
      </TableCell>
    </TableRow>
  );
};
