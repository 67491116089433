import styled, { css } from "styled-components";
import { InputLabel } from "@material-ui/core";
import theme from "styled-theming";

const variants = theme.variants("mode", "variant", {
  standard: {
    light: css`
      transform: none;
      position: initial;
      text-transform: uppercase;
      margin-bottom: ${props => props.theme.spacing(1)}px;
      text-align: left;
      font-size: ${props => props.theme.typography.h2.fontSize};
      font-weight: ${props => props.theme.typography.h2.fontWeight};
      letter-spacing: ${props => props.theme.typography.h2.letterSpacing}px;
    `,
    dark: css``
  }
});

export const StyledInputLabel = styled(InputLabel)`
  ${variants};
`;
