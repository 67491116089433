import project from "./project/reducer";
import userProject from "./userProject/reducer";
import {reducer as usersInWorkspace} from "./usersInWorkspace";
import invoicesCreated from "./invoicesCreated/reducer";
import { combineReducers } from "redux";

export default combineReducers({
  invoicesCreated,
  usersInWorkspace,
  project,
  userProject
});
