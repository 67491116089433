import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@superprofit/core-react-components/atoms";
import ProjectTimesheet, {
  WINDOW_MAX_CARD_BREAKPOINT
} from "./ProjectTimesheet";
import ProjectTimesheetV2 from "./ProjectTimesheetV2";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useTheme from "@material-ui/core/styles/useTheme";
import { darken, Divider, Link } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { watchUpdateUserProfile } from "../../../redux/modules/api/userProfile/actions";
import { useWindowWidth } from "@react-hook/window-size";
import useUserProfile from "../../../hooks/useUserProfile";
import usePeriod from "./hooks/usePeriod";
import useFilterProjects from "./hooks/useFilterProjects";
import useHiddenProjectTimesheets from "./hooks/useHiddenProjectTimesheets";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useOverrideTimesheetUser } from "./hooks/useOverrideTimesheetUser";

const descendingComparator = (a, b) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export default () => {
  const dispatch = useDispatch();
  const { data: projects = [] } = useFilterProjects();
  const overriddenUserEmail = useOverrideTimesheetUser();
  const { data: userProfile } = useUserProfile({ userId: overriddenUserEmail });
  const { update: setPeriod, ...period } = usePeriod();
  const { t } = useTranslation();

  const [showHiddenProjects, setShowHiddenProjects] = useState(false);
  const { hidden } = useHiddenProjectTimesheets({
    userId: overriddenUserEmail
  });
  const windowWidth = useWindowWidth();
  const [sortOrder, setSortOrder] = useState(
    (userProfile && userProfile.projectSortOrder) || {}
  );

  console.log(hidden, projects);

  useEffect(() => {
    if (userProfile && userProfile.projectSortOrder) {
      setSortOrder(userProfile.projectSortOrder);
    }
  }, [userProfile]);

  const sortedProjects = useMemo(() => {
    return [...projects]
      .filter(p => !hidden.has(p.id))
      .sort((a, b) => {
        if (sortOrder[a.id] && !sortOrder[b.id]) return 1;
        if (sortOrder[b.id] && !sortOrder[a.id]) return -1;
        if (sortOrder[b.id] && !sortOrder[a.id])
          descendingComparator(a.name, b.name);
        return descendingComparator(sortOrder[a.id], sortOrder[b.id]);
      });
  }, [sortOrder, projects, hidden]);

  const hiddenProjects = useMemo(() => {
    return projects
      .filter(p => hidden.has(p.id))
      .map((project, idx) => (
        <Grid
          key={project.id}
          item
          xs={12}
          sm={windowWidth >= WINDOW_MAX_CARD_BREAKPOINT ? "auto" : undefined}
          component="div"
        >
          <ProjectTimesheetV2 period={period} project={project} />
        </Grid>
      ));
  }, [hidden]);

  const theme = useTheme();

  const grid = 8;

  const getListStyle = isDraggingOver => ({
    marginTop: 20,
    background: isDraggingOver
      ? darken(theme.palette.background.light, 0.05)
      : "none"
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    borderRadius: 8,
    // change background colour if dragging
    background: isDragging ? theme.palette.primary.main : "none",
    // styles we need to apply on draggables
    ...draggableStyle
  });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sortedProjects,
      result.source.index,
      result.destination.index
    );

    const sortOrder = items.reduce(
      (prev, next, idx) => ({
        ...prev,
        [next.id]: idx + 1
      }),
      userProfile.projectSortOrder
    );
    setSortOrder(sortOrder);
    dispatch(
      watchUpdateUserProfile(userProfile, { projectSortOrder: sortOrder })
    );
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Grid
              container
              spacing={3}
              component="div"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {sortedProjects.map((project, idx) => (
                <Draggable
                  key={project.id}
                  draggableId={project.id}
                  index={idx}
                >
                  {(provided, snapshot) => (
                    <Grid
                      key={project.id}
                      item
                      xs={12}
                      sm={
                        windowWidth >= WINDOW_MAX_CARD_BREAKPOINT
                          ? "auto"
                          : undefined
                      }
                      component="div"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <ProjectTimesheetV2
                        period={period}
                        project={project}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <Grid container spacing={3} component="div" style={{ opacity: 0.8 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Link
            component="button"
            onClick={() => setShowHiddenProjects(!showHiddenProjects)}
          >
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              {t("pages.timesheet.templates.showHiddenProjects", {
                count: hiddenProjects.length
              })}
              {showHiddenProjects ? <ExpandLess /> : <ExpandMore />}
            </span>
          </Link>
        </Grid>
        {showHiddenProjects && hiddenProjects}
      </Grid>
    </>
  );
};
