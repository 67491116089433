import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { GET_SUCCESS, SAVE_SUCCESS } from "../../../api/userSettings/constants";
import { WATCH_REFRESH_GOALS } from "./constants";
import { setGoals, watchRefreshGoals } from "./actions";
import { SETTING_TYPES } from "../../../../../models/UserSetting";

export const getSettings = ({
  api: {
    userSettings: { settings }
  }
}) => settings;

export const dispatchRefresh = function*(args) {
  try {
    yield put(watchRefreshGoals());
  } catch (e) {
    console.warn(e);
  }
};

export const handleRefresh = function*(args) {
  try {
    const settings = yield select(getSettings);
    const result = Object.keys(settings)
      .map(key => settings[key])
      .filter(setting => setting.type === SETTING_TYPES.PERFORMANCE_GOAL)
      .sort((a, b) => {
        if (a.year === b.year) {
          return a.month > b.month ? 1 : -1;
        } else {
          return a.year - b.year;
        }
      });
    yield put(setGoals(result));
  } catch (e) {
    console.warn(e);
  }
};

export const handleRefreshSaga = function*() {
  yield takeLatest(WATCH_REFRESH_GOALS, handleRefresh);
};

export const handleSaveSuccessSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, dispatchRefresh);
};

export const handleGetSuccessSaga = function*() {
  yield takeLatest(GET_SUCCESS, dispatchRefresh);
};

export default function*() {
  yield all([
    handleRefreshSaga(),
    handleSaveSuccessSaga(),
    handleGetSuccessSaga()
  ]);
}
