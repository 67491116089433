import React, { Fragment, useState } from "react";
import FormDialog from "../../../components/molecules/FormDialog";
import CheckIcon from "@material-ui/icons/Check";
import ConfirmationDialog from "../../../components/molecules/ConfirmationDialog";
import { FormHelperText } from "@superprofit/core-react-components/atoms";
import LinkIcon from "@material-ui/icons/Link";
import { useAllowCreateUser } from "../../../helpers";
import { useTranslation } from "react-i18next";
import useWorkspace from "../../../hooks/useWorkspace";
import { SubmitHandler, useForm } from "react-hook-form";
import TextField from "../../../components/atoms/TextField";
import Typography from "../../../components/atoms/Typography";
import Grid from "../../../components/atoms/Grid";
import Tooltip from "../../../components/atoms/Tooltip";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../../components/atoms/Button";
import useTimetUserMutation from "../../../hooks/useTimetUserMutation";
import useUserCreateDialog from "./hooks/useUserCreateDialog";

interface IFormInput {
  email: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .matches(
        //valid email address separated by semicolon
        /^([a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})(;[ \t]*[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/
      )
      .required()
  })
  .required();
export default ({ ...rest }) => {
  const { t } = useTranslation();

  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  const allowCreateUser = useAllowCreateUser();
  const workspace = useWorkspace();
  const mutation = useTimetUserMutation();

  const {
    handleSubmit,
    formState: { errors },
    register
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit"
  });

  const { open, close } = useUserCreateDialog();

  const handleConfirmClose = () => {
    close();
  };

  const handleCancelClose = () => {
    setShowUnsavedConfirmation(false);
  };

  const handleOnClose = () => {
    if (mutation.isLoading) return;
    else {
      handleConfirmClose();
    }
  };

  const copyLink = () => {
    navigator.clipboard
      .writeText(window.location.origin + "/app/" + workspace)
      .then(() => setCopiedLink(true))
      .catch(e => console.error("Could not copy", e));
    setTimeout(() => {
      setCopiedLink(false);
    }, 2000);
  };
  const onSubmit: SubmitHandler<IFormInput> = async ({ email }) => {
    const emails = email.trim().split(";");
    for (let i = 0; i < emails.length; i++) {
      const email = emails[i].trim();
      await mutation.mutateAsync({ email });
    }
    close();
  };
  return (
    <Fragment>
      <ConfirmationDialog
        key="unsaved"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showUnsavedConfirmation}
      />

      <FormDialog
        {...rest}
        PaperProps={{ component: "form", onSubmit: handleSubmit(onSubmit) }}
        SubmitButtonProps={{ type: "submit" }}
        key="form"
        disabled={!allowCreateUser}
        saveActionTitle={t("common.invite")}
        open={open}
        onClose={handleOnClose}
        title={t("pages.users.addMultipleUsers")}
        inProgress={mutation.isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              {...register("email", { required: true })}
              disabled={!allowCreateUser}
              fullWidth
              variant="filled"
              label={t("common.email")}
              placeholder="john.doe@timet.io"
              error={!!errors.email}
              helperText={
                errors.email
                  ? t("common.invalidEmail")
                  : t("pages.users.emailHelperText")
              }
            />
          </Grid>
          {!allowCreateUser && (
            <Grid item xs={12}>
              <Typography>{t("pages.users.subscriptionTooltip")}</Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormHelperText>
              <span>{t("pages.users.copyLinkHelperText")}</span>
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Tooltip
              title={t("common.copied")}
              leaveDelay={1000}
              open={copiedLink}
            >
              <Button color="primary" variant="contained" onClick={copyLink}>
                <span style={{ paddingRight: "0.5rem" }}>
                  {t("common.copyLink")}
                </span>

                {!copiedLink ? <LinkIcon /> : <CheckIcon />}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </FormDialog>
    </Fragment>
  );
};
