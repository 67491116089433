import { useMemo } from "react";
import Project from "../models/Project";
import useUserGroups from "./useUserGroups";
import UserGroup from "../models/UserGroup";
import useUser from "./useUser";
import useUnarchivedProjects from "./useUnarchivedProjects";

const useUserProjects = (
  projects?: Project[] | null,
  overrideEmail?: string
) => {
  const { data: allProjects } = useUnarchivedProjects();
  const { email: loggedInUserEmail } = useUser();
  const email = overrideEmail || loggedInUserEmail;
  const { data: userGroups } = useUserGroups({ ids: [email] });
  return useMemo(
    () =>
      (projects || allProjects || []).filter((p: Project) => {
        // id can be null if its a newly instantiated project..
        const inUserGroup = p.userGroups.some(ugId =>
          userGroups?.find((ug: UserGroup) => ug.id === ugId)
        );
        if (p.id && (p.team.indexOf(email) > -1 || inUserGroup)) {
          return true;
        }
        return false;
      }),
    [projects, allProjects, userGroups]
  );
};

export default useUserProjects;
