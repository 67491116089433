import {
  HIDE_CUSTOMER_DIALOG,
  SHOW_CUSTOMER_DIALOG,
  UPDATE_CUSTOMER_DIALOG_DATA
} from "./constants";

export const showCustomerDialog = customer => ({
  type: SHOW_CUSTOMER_DIALOG,
  payload: { customer }
});

export const hideCustomerDialog = () => ({
  type: HIDE_CUSTOMER_DIALOG,
  payload: {}
});

export const updateCustomerDialogData = updates => ({
  type: UPDATE_CUSTOMER_DIALOG_DATA,
  payload: { updates }
});
