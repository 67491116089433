import { combineReducers } from "redux";

import comments from "./comments/reducer";
import dialog from "./dialog/reducer";
import snackbar from "./snackbar/reducer";

export default combineReducers({
  dialog,
  comments,
  snackbar
});
