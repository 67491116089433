import Entry from "../../../../models/Entry";
import { sync as syncFn } from "./AsyncEworkDialog";
import Project from "../../../../models/Project";
import { promiseDelay, useFeatures } from "../../../../helpers";
import useGlobalSnackbar from "../../../../hooks/useGlobalSnackbar";
import { useTranslation } from "react-i18next";

export function useEwork() {
  const { t } = useTranslation();
  const { syncHoursEwork } = useFeatures();
  const { update: updateSnackbar } = useGlobalSnackbar();

  const sync = async (props: { entries: Entry[]; projects: Project[] }) => {
    try {
      const result = await syncFn({ props });
      if (result.reason === "success") {
        updateSnackbar({
          open: true,
          message: `${t("common.saved")}!   🎉`,
          alert: { severity: "success" }
        });
      }
    } catch (error) {
      console.log(error);
      updateSnackbar({
        open: true,
        message: `${t("common.error")}! ${e} 🚨 `,
        alert: { severity: "error" }
      });
    } finally {
      await promiseDelay(2000);
      updateSnackbar({
        open: false
      });
    }
  };
  return {
    sync,
    isEnabled: syncHoursEwork
  };
}
