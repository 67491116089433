
export default (constants) => ({
  getEntry: () => ({
    type: constants.GET_ENTRY,
    payload: {},
  }),
  getEntrySuccess: (entry, pagination) => ({
    type: constants.GET_ENTRY_SUCCESS,
    payload: { entry, pagination },
  }),
  get: () => ({
    type: constants.GET,
    payload: {},
  }),
  getSuccess: (list, pagination) => ({
    type: constants.GET_SUCCESS,
    payload: { list, pagination },
  }),
  listenAllSuccess: (list, pagination) => ({
    type: constants.LISTEN_ALL_SUCCESS,
    payload: { list, pagination },
  }),
  listenSuccess: (entry, pagination) => ({
    type: constants.LISTEN_SUCCESS,
    payload: { entry, pagination },
  }),
  getFailure: (error) => ({
    type: constants.GET_FAILURE,
    payload: { error },
  }),
  getEntryFailure: (error) => ({
    type: constants.GET_ENTRY_FAILURE,
    payload: { error },
  }),
  create: () => ({
    type: constants.CREATE,
    payload: {},
  }),
  createSuccess: (list, pagination) => ({
    type: constants.CREATE_SUCCESS,
    payload: { list, pagination },
  }),
  createFailure: (error) => ({
    type: constants.CREATE_FAILURE,
    payload: { error },
  }),
  update: () => ({
    type: constants.UPDATE,
    payload: {},
  }),
  updateSuccess: (list, pagination) => ({
    type: constants.UPDATE_SUCCESS,
    payload: { list, pagination },
  }),
  updateFailure: (error) => ({
    type: constants.UPDATE_FAILURE,
    payload: { error },
  }),
  delete: () => ({
    type: constants.DELETE,
    payload: {},
  }),
  deleteSuccess: (list, pagination) => ({
    type: constants.DELETE_SUCCESS,
    payload: { list, pagination },
  }),
  deleteFailure: (error) => ({
    type: constants.DELETE_FAILURE,
    payload: { error },
  }),

  exist: () => ({
    type: constants.EXISTS,
  }),

  existSuccess: (docId, exist) => ({
    type: constants.EXISTS_SUCCESS,
    payload: { docId, exist },
  }),

  existFailure: (error) => ({
    type: constants.EXISTS_FAILURE,
    payload: { error },
  }),
  watchExist: (docId) => ({
    type: constants.WATCH_EXISTS,
    payload: { docId },
  }),
  watchGet: () => ({
    type: constants.WATCH_GET,
    payload: {},
  }),
  watchGetEntry: (id) => ({
    type: constants.WATCH_GET_ENTRY,
    payload: {id},
  }),
  watchDelete: ({ id }) => ({
    type: constants.WATCH_DELETE,
    payload: { id },
  }),
  watchUpdate: (original, updates) => ({
    type: constants.WATCH_UPDATE,
    payload: { original, updates },
  }),
  watchCreate: (data) => ({
    type: constants.WATCH_CREATE,
    payload: { data },
  }),
  watchListen: () => ({
    type: constants.WATCH_LISTEN,
  }),
  watchListenAll: () => ({
    type: constants.WATCH_LISTEN_ALL,
  }),
  watchStopListen: () => ({
    type: constants.WATCH_STOP_LISTEN,
  }),
  watchStopListenAll: () => ({
    type: constants.WATCH_STOP_LISTEN_ALL,
  }),
});
