import { Comment } from "@material-ui/icons";
import React from "react";
import {StyledIconButton, StyledInputAdornment} from "./commentAdornment/CommentAdornment.styles";
import {ICommentAdornment} from "./commentAdornment/types";

const CommentAdornment = ({hidden, onClick} : ICommentAdornment) => {
  return (
    <StyledInputAdornment>
      <StyledIconButton onClick={onClick} aria-label="toggle comment" size="small"  visibility={hidden ? "hidden" : "visible"}>
        <Comment />
      </StyledIconButton>
    </StyledInputAdornment>
  );
};

export default CommentAdornment;
