import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@superprofit/core-react-components/atoms/Button";
import { show as showPDFDialog } from "../../../redux/modules/ui/pdf/dialog/actions";
import Card from "@superprofit/core-react-components/atoms/Card";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import UsersTable from "./userTable/UserTable";
import { Menu, MenuItem } from "@material-ui/core";
import FeatureNotImplementedDialog from "@superprofit/core-react-components/organisms/FeatureNotImplementedDialog";
import { selectAllUsers } from "../../../redux/modules/ui/users/selection/actions";
import { showAccessControlDialog } from "../../../redux/modules/ui/users/accessControlDialog/actions";
import { showDeleteUsersDialog } from "../../../redux/modules/ui/users/deleteUsersDialog/actions";
import IconMore from "@material-ui/icons/MoreVert";
import { showAssignProjectsDialog } from "../../../redux/modules/ui/users/assignProjectsDialog/actions";
import { useWorkspaceHistory } from "../../../contexts/WorkspaceContext";
import { useTranslation } from "react-i18next";
import useUserCreateDialog from "./hooks/useUserCreateDialog";
import useUserEditDialog from "./hooks/useUserEditDialog";

const UsersCard = ({
  users,
  settings,
  loading,
  selection: selected,
  ...rest
}) => {
  const dispatch = useDispatch();
  const history = useWorkspaceHistory();
  const { t } = useTranslation();
  const { show: showUserCreateDialog } = useUserCreateDialog();
  const { show: showUserEditDialog } = useUserEditDialog();

  const [anchorEl, setAnchorEl] = useState();

  const [notYetDialog, setNotYetDialog] = useState(false);

  const handleOnAdd = () => {
    showUserCreateDialog();
  };

  const handleOnEdit = (e, data) => {
    showUserEditDialog(data.id);
  };

  const handleOnArchive = (e, data) => {
    setNotYetDialog(true);
  };

  const handleOnDelete = (e, data) => {
    dispatch(showDeleteUsersDialog([data]));
  };

  const handleOnDeleteSelection = () => {
    dispatch(showDeleteUsersDialog(selected));
  };

  const handleOnView = (e, data) => {
    setNotYetDialog(true);
    history.push(`/users/${data.id}`);
  };

  const handleOnSelectionChange = selection => {
    dispatch(selectAllUsers(selection));
  };
  const handleOnClearSelection = () => {
    dispatch(selectAllUsers([]));
  };
  const handleOnShowPDFDialog = () => {
    dispatch(showPDFDialog({ users: selected }));
  };
  const handleOnBatchAssignRoles = () => {
    dispatch(showAccessControlDialog(selected));
  };
  const handleOnBatchAssignProjects = () => {
    dispatch(showAssignProjectsDialog(selected));
  };
  const handleOnOverrideUserTimesheet = (e, data) => {
    history.push(`/timesheet?overrideUser=${data.id}`);
  };

  const handleOnAssignRoles = (e, user) => {
    dispatch(showAccessControlDialog([user]));
  };
  const handleOnAssignProjects = (e, user) => {
    dispatch(showAssignProjectsDialog([user]));
  };

  return (
    <div>
      <Card {...rest}>
        <CardHeader title={t("common.users")} />

        <Divider />

        <UsersTable
          onAdd={handleOnAdd}
          onEdit={handleOnEdit}
          onView={handleOnView}
          onDelete={handleOnDelete}
          onSelectionChange={handleOnSelectionChange}
          onAssignRoles={handleOnAssignRoles}
          onAssignProjects={handleOnAssignProjects}
          onOverrideUserTimesheet={handleOnOverrideUserTimesheet}
          users={users}
          loading={loading}
          selection={selected}
          settings={settings}
        />
      </Card>

      <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={selected.length > 0}
          autoHideDuration={null}
          onClose={noop => noop}
          message={`${selected.length} users selected`}
          action={
            <React.Fragment>
              <Button
                color="inherit"
                size="small"
                onClick={handleOnClearSelection}
              >
                {t("common.clear")}
              </Button>
              <Button
                color="alert"
                size="small"
                onClick={handleOnDeleteSelection}
              >
                {t("common.delete")}
              </Button>
              <Button
                color="inherit"
                size="small"
                onClick={e => setAnchorEl(e.currentTarget)}
              >
                <IconMore />
              </Button>
            </React.Fragment>
          }
        />
      </AppBar>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            handleOnBatchAssignRoles();
            setAnchorEl(null);
          }}
          disabled={selected.find(
            u => !u?.userAccess || u?.userAccess?.isPendingInvite
          )}
        >
          {t("pages.users.assignRoles")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOnBatchAssignProjects();
            setAnchorEl(null);
          }}
        >
          {t("pages.users.assignProjects")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOnShowPDFDialog();
            setAnchorEl(null);
          }}
        >
          {t("common.createReport")}
        </MenuItem>
      </Menu>

      <FeatureNotImplementedDialog
        open={notYetDialog}
        onClose={e => setNotYetDialog(false)}
      />
    </div>
  );
};

export default UsersCard;
