import Holidays from "date-holidays";
import { moment } from "@superprofit/time-util";

export const defaultWorkingWeekDays = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: false,
  7: false
};

export const defaultHolidaysCalendar = "NO";

export const getWorkingDaysPeriod = (
  start,
  end,
  holidaysCalendar,
  workingWeekDays
) => {
  const duration = end.diff(start, "days") + 1;

  const hd = new Holidays(holidaysCalendar);

  let workingDays = 0;
  for (let i = 0; i < duration; i++) {
    const nextDate = start.clone().add(i, "days");
    const isoWeekday = nextDate.isoWeekday();
    if (workingWeekDays[isoWeekday] && !hd.isHoliday(nextDate.toDate())) {
      workingDays++;
    }
  }

  return workingDays;
};

export const getWorkingDays = (
  year,
  month,
  holidaysCalendar,
  workingWeekDays
) => {
  const startOfMonth = moment()
    .year(year)
    .month(month - 1)
    .startOf("month");
  const endOfMonth = moment()
    .year(year)
    .month(month - 1)
    .endOf("month");

  return getWorkingDaysPeriod(
    startOfMonth,
    endOfMonth,
    holidaysCalendar,
    workingWeekDays
  );
};

// returns the working days for a given month up to a max date
export const getWorkingDaysTD = (
  year,
  month,
  maxDate,
  holidaysCalendar,
  workingWeekDays
) => {
  const start = moment()
    .year(year)
    .month(month - 1)
    .startOf("month");

  const monthEnd = moment()
    .year(year)
    .month(month - 1)
    .endOf("month");
  const end = monthEnd.isBefore(maxDate) ? monthEnd : maxDate;

  return getWorkingDaysPeriod(start, end, holidaysCalendar, workingWeekDays);
};
