import moment from "moment";

export const getHoursByUser = (stats = []) => {
  const hoursByUserId = {};
  stats.forEach(s => {
    hoursByUserId[s.user] = (hoursByUserId[s.user] || 0) + s.total;
  });

  return hoursByUserId;
};

export const getMoneyByUser = (stats = [], projectsById = {}) => {
  const moneyByUserId = {};

  stats.forEach(s => {
    if (projectsById[s.project].billable && projectsById[s.project]) {
      moneyByUserId[s.user] =
        (moneyByUserId[s.user] || 0) +
        s.total * projectsById[s.project].getBillableRate(s.user);
    }
  });

  return moneyByUserId;
};

export const getSalaryByUser = (stats = [], projectsById = {}) => {
  const moneyByUserId = {};

  stats.forEach(s => {
    if (projectsById[s.project].salaryEnabled && projectsById[s.project]) {
      moneyByUserId[s.user] =
        (moneyByUserId[s.user] || 0) +
        s.total * projectsById[s.project].getSalaryRate(s.user);
    }
  });

  return moneyByUserId;
};

export const getRelativeHoursPercentageByUser = (stats = []) => {
  const hoursByUserId = getHoursByUser(stats);
  const max = Math.max.apply(this, Object.values(hoursByUserId));
  return Object.keys(hoursByUserId).reduce(
    (prev, next) => ({
      ...prev,
      [next]: (hoursByUserId[next] / max) * 100
    }),
    {}
  );
};

export const getRelativeMoneyByUser = (stats = []) => {
  const moneyByUserId = getMoneyByUser(stats);
  const max = Math.max.apply(this, Object.values(moneyByUserId));
  return Object.keys(moneyByUserId).reduce(
    (prev, next) => ({
      ...prev,
      [next]: (moneyByUserId[next] / max) * 100
    }),
    {}
  );
};

export const getProjectHoursByUser = (stats = []) => {
  const statsByUserProjectId = stats.reduce((prev, next) => {
    if (next.total === 0) return prev;
    const projectHours =
      ((prev[next.user] && (prev[next.user][next.project] || 0)) || 0) +
      next.total;
    const userRecord = {
      ...(prev[next.user] || {}),
      [next.project]: projectHours
    };
    return {
      ...prev,
      [next.user]: userRecord
    };
  }, {});
  return statsByUserProjectId;
};

export const getProjectMoneyByUser = (stats = [], projectsById) => {
  const statsByUserProjectId = stats.reduce((prev, next) => {
    const project = projectsById[next.project];

    if (next.total === 0 || !project?.billable) return prev;
    const projectMoney =
      ((prev[next.user] && (prev[next.user][next.project] || 0)) || 0) +
      next.total * project.getBillableRate(next.user);

    const userRecord = {
      ...(prev[next.user] || {}),
      [next.project]: projectMoney
    };

    return {
      ...prev,
      [next.user]: userRecord
    };
  }, {});
  return statsByUserProjectId;
};

export const getProjectSalaryByUser = (stats = [], projectsById) => {
  const statsByUserProjectId = stats.reduce((prev, next) => {
    const project = projectsById[next.project];
    const rate = project.getSalaryRate(next.user);
    if (next.total === 0 || rate === 0) return prev;
    const projectSalary =
      ((prev[next.user] && (prev[next.user][next.project] || 0)) || 0) +
      next.total * rate;

    const userRecord = {
      ...(prev[next.user] || {}),
      [next.project]: projectSalary
    };

    return {
      ...prev,
      [next.user]: userRecord
    };
  }, {});
  return statsByUserProjectId;
};

export const getProjectHoursPerWeekByUser = (stats = []) => {
  const statsByUserProjectWeek = stats.reduce((prev, next) => {
    if (next.total === 0) return prev;
    const existing =
      (prev[next.user] &&
        prev[next.user][next.project] &&
        prev[next.user][next.project][next.week]) ||
      0;
    const projectHours = existing + next.total;
    let userRecord = { ...(prev[next.user] || {}) };
    userRecord[next.project] = { ...(userRecord[next.project] || {}) };
    userRecord[next.project][next.week] = projectHours;

    return {
      ...prev,
      [next.user]: userRecord
    };
  }, {});
  return statsByUserProjectWeek;
};

export const getHoursPerWeekByUser = (stats = []) => {
  const statsByWeek = stats.reduce((prev, next) => {
    if (next.total === 0) return prev;
    const weekHours =
      ((prev[next.user] && (prev[next.user][next.week] || 0)) || 0) +
      next.total;
    const userRecord = { ...(prev[next.user] || {}), [next.week]: weekHours };
    return {
      ...prev,
      [next.user]: userRecord
    };
  }, {});
  return statsByWeek;
};

export const getUserProjectEntries = (entries = [], projectsById) => {
  return entries.reduce((prev, next) => {
    const project = projectsById[next.project];
    if (next.hours === 0) return prev;
    const date = `${next.year}-${next.dayOfYear}`;
    const res = { ...prev };
    res[next.user] = res[next.user] || {};
    res[next.user][next.project] = res[next.user][next.project] || [];
    res[next.user][next.project].push({
      date,
      year: next.year,
      dayOfYear: next.dayOfYear,
      hours: next.hours,
      money: next.hours * project.getBillableRate(next.user),
      comments: next.comments || []
    });

    return res;
  }, {});
};

export const getTotalPlannedByUser = (year, month, userId, projects) => {
  return projects.reduce(
    (prev, next) =>
      prev + next.getPlannedHoursByMonthByUser(year, month, userId),
    0
  );
};

const hasMetCapacityForGivenWeek = (year, month, week, capacity, value) => {};
