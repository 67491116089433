import { STAGE_UPDATES, SET_SAVING } from "./constants";

export const setSaving = isSaving => ({
  type: SET_SAVING,
  payload: { isSaving }
});

export const stageUpdates = (project, updates) => ({
  type: STAGE_UPDATES,
  payload: { project, updates }
});
