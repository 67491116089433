import { moment } from "@superprofit/time-util";

export const getLabel = stat => {
  return `${stat.year}-${stat.week}`;
};

export const getLabels = (stats, offsetLeft = 0, offsetRight = 1) => {
  const minWeek = stats.reduce((prev, next) => {
    if (
      (!prev ||
        moment()
          .year(next.year)
          .week(next.week)
          .startOf("week")
          .isBefore(prev)) &&
      next.year &&
      next.week
    ) {
      return moment()
        .year(next.year)
        .week(next.week)
        .startOf("week");
    }
    return prev;
  }, null);

  const maxWeek = stats.reduce((prev, next) => {
    if (
      !prev ||
      moment()
        .year(next.year)
        .week(next.week)
        .startOf("week")
        .isAfter(prev)
    ) {
      return moment()
        .year(next.year)
        .week(next.week)
        .startOf("week");
    }
    return prev;
  }, null);

  if (!minWeek) return [];

  const diff = Math.abs(maxWeek.diff(minWeek, "weeks"));

  const labels = new Array(diff + offsetLeft + offsetRight)
    .fill("")
    .map((n, idx) => {
      const next = minWeek
        .clone()
        .subtract(offsetLeft, "week")
        .add(idx, "weeks");
      return `${next.year()}-${next.week()}`;
    });

  return labels;
};
