import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Grid from "../../../../components/atoms/Grid";
import TextField from "../../../../components/atoms/TextField";
import FormControl from "../../../../components/atoms/FormControl";
import React, { useMemo } from "react";
import ThemeProvider from "../../../../ThemeProvider";
import ReactDOM from "react-dom";
import Dialog from "../../../../components/atoms/Dialog";
import DialogContent from "../../../../components/atoms/DialogContent";
import DialogActions from "../../../../components/atoms/DialogActions";
import Button from "../../../../components/atoms/Button";
import DialogTitle from "../../../../components/atoms/DialogTitle";
import Select from "../../../../components/atoms/Select";
import MenuItem from "../../../../components/atoms/MenuItem";
import LinearProgress from "../../../../components/atoms/LinearProgress";
import FormHelperText from "../../../../components/atoms/FormHelperText";
import {
  getAuthToken,
  getIdentities,
  setAuthTokenInLocalStorage
} from "./helpers";
import Entry from "../../../../models/Entry";
import { projectId } from "../../../../config/firebase";
import Period from "../../../../redux/modules/ui/timesheet/period";
import Checkbox from "../../../../components/atoms/Checkbox";
import FormControlLabel from "../../../../components/atoms/FormControlLabel";
import Alert from "../../../../components/atoms/Alert";
import Project from "../../../../models/Project";
import projects from "../../Projects";
import Typography from "../../../../components/atoms/Typography";

interface IFormInput {
  username: string;
  password: string;
  terms: boolean;
}

interface AsyncEworkDialogProps {
  open: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  onError: () => void;
  containerElement: HTMLElement;
  entries: Entry[];
  projects: Project[];
  month: number;
}

export function AsyncEworkDialog(props: AsyncEworkDialogProps) {
  const { t } = useTranslation();
  const {
    open = true,
    onCancel,
    onError,
    onSuccess,
    entries,
    projects = []
  } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [localOpen, setLocalOpen] = React.useState(open);

  const schema = useMemo(() => {
    let shape: any = {
      username: yup.string().required(),
      password: yup.string().required(),
      disclaimer: yup.boolean().oneOf([true])
    };

    return yup.object().shape(shape);
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    watch,
    register
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit"
  });

  const handleConfirmClose = () => {};

  const handleCancelClose = () => {
    handleOnClose();
  };

  const handleOnClose = () => {
    setLocalOpen(false);
    onCancel();
  };
  const onSubmit: SubmitHandler<IFormInput> = async data => {
    try {
      setIsLoading(true);
      const { username, password } = data;
      const url = `https://europe-west1-${projectId}.cloudfunctions.net/integrations-ework-synchours`;
      const result = await fetch(url, {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username,
          password,
          entries: entries.map(entry => ({
            year: entry.year,
            day: entry.dayOfYear,
            hours: entry.hours,
            month: entry.month
          }))
        })
      });
      if (!result.ok) {
        throw new Error("Failed to sync hours");
      }

      setLocalOpen(false);
      setIsLoading(false);
      onSuccess();
    } catch (error) {
      setIsLoading(false);
      onError();
    }
  };
  const termsAccepted = watch("terms");

  const totalHours = useMemo(() => {
    return entries.reduce((acc, entry) => acc + entry.hours, 0);
  }, [entries]);
  const projectName = useMemo(() => {
    return projects.find(project => project.id === entries[0].project)?.name;
  }, [projects, entries]);
  return (
    <ThemeProvider mode="light">
      <Dialog
        container={props.containerElement}
        keepMounted={false}
        PaperProps={{ component: "form", onSubmit: handleSubmit(onSubmit) }}
        key="form"
        open={localOpen}
        onClose={handleOnClose}
      >
        {isLoading && <LinearProgress />}
        <DialogTitle>{t("integrations.ework.syncHours")}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" fullWidth variant="outlined">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  {...register(`username`)}
                  fullWidth
                  variant="filled"
                  label={t("common.username")}
                  autoComplete={"username"}
                  error={!!errors.username}
                  helperText={errors.username?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register("password")}
                  fullWidth
                  variant="filled"
                  type={"password"}
                  label={t("common.password")}
                  autoComplete={"current-password"}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Alert variant="filled" severity="info">
                  {t("integrations.ework.aboutToSyncHoursInfoMessage", {
                    hours: totalHours,
                    projectName
                  })}
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox {...register("terms")} />}
                  label={
                    <Typography variant="caption">
                      {" "}
                      {t("integrations.ework.terms")}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelClose}
            color="primary"
            variant="outlined"
            disabled={isLoading}
          >
            {t("common.cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isLoading || !termsAccepted}
          >
            {t("common.sync")}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

type ResolvePayload = {
  reason: "success" | "cancel";
  data: any;
};

type RejectPayload = {
  reason: "error";
  data: any;
};

type Resolve = (value: ResolvePayload) => void;
type Reject = (value: Error) => void;
type Options = {
  props: Partial<AsyncEworkDialogProps>;
};
export async function sync(options: Options) {
  const props = options.props || {};
  return new Promise((resolve: Resolve, reject: Reject) => {
    const containerElement = document.createElement("div");
    document.body.appendChild(containerElement);
    const unmount = () => {
      setTimeout(() => {
        document.body.removeChild(containerElement);
      }, 1000);
    };
    const {
      onSuccess,
      onCancel,
      onError,
      containerElement: ce,
      open,
      entries = [],
      projects = [],
      ...rest
    } = props;
    ReactDOM.render(
      <AsyncEworkDialog
        open
        containerElement={containerElement}
        onSuccess={() => {
          unmount();
          resolve({ reason: "success", data: null });
        }}
        onCancel={() => {
          unmount();
          resolve({ reason: "cancel", data: null });
        }}
        onError={() => {
          unmount();
          reject(new Error("Failed to sync hours"));
        }}
        entries={entries}
        projects={projects}
        {...rest}
      />,
      containerElement
    );
  });
}
