import { takeLatest, put, take, all } from "redux-saga/effects";

// constants
import { WATCH_GET } from "./constants";
import { GET_CUSTOMER_SUCCESS } from "../customers/constants";
import { GET_SUCCESS as GET_PROJECTS_SUCCESS } from "../projects/constants";
import { GET_SUCCESS as GET_STATS_SUCCESS } from "../customerStats/constants";
import { GET_SUCCESS as GET_USERS_SUCCESS } from "@superprofit/core-redux-saga-modules/Users/constants";

// actions
import { get, getFailure, getSuccess } from "./actions";
import { watchGetProjects } from "../projects/actions";
import { watchGetCustomer } from "../customers/actions";
import { watchGetCustomerStats } from "../customerStats/actions";
import { watchGetUsers } from "@superprofit/core-redux-saga-modules/Users/actions";

export const getCustomerOverview = function* getCustomerOverview(args) {
  const { id, year, month } = args.payload;

  try {
    yield put(get());

    yield put(watchGetCustomer(id));
    const {
      payload: { customer }
    } = yield take(GET_CUSTOMER_SUCCESS);

    yield put(watchGetProjects());
    const {
      payload: { list: projects }
    } = yield take(GET_PROJECTS_SUCCESS);

    const customerProjects = projects.filter(p => p.customer === id);
    const customerProjectIds = customerProjects.map(p => p.id);

    yield put(watchGetCustomerStats(id, year, month, customerProjectIds));

    yield put(watchGetUsers());

    const [
      {
        payload: { monthBefore, allTime, byWeek }
      },
      {
        payload: { list: users }
      }
    ] = yield all([take(GET_STATS_SUCCESS), take(GET_USERS_SUCCESS)]);

    yield put(
      getSuccess({
        customer,
        projects: customerProjects,
        stats: {
          allTime,
          byWeek,
          monthBefore
        },
        users,
        year,
        month
      })
    );
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const getCustomerOverviewSaga = function* getCustomerOverviewSaga() {
  yield takeLatest(WATCH_GET, getCustomerOverview);
};

export default function*() {
  yield all([getCustomerOverviewSaga()]);
}
