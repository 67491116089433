import React, { forwardRef } from "react";
import { StyledTooltip } from "./tooltip/tooltip.styles";
import withStyles from "@material-ui/core/styles/withStyles";

const Tooltip = withStyles({
  tooltip: {
    fontSize: ".8rem",
    padding: ".5rem"
  }
})(StyledTooltip);

export default forwardRef((props, ref) => <Tooltip {...props} ref={ref} />);
