import {
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  all,
} from "redux-saga/effects";
import {
  WATCH_GET,
  WATCH_SAVE,
  WATCH_DELETE,
  WATCH_PATCH,
  WATCH_GET_PROJECT,
  WATCH_PATCH_MULTIPLE,
  WATCH_DUPLICATE,
} from "./constants";
import {
  get,
  getFailure,
  getSuccess,
  getProject as getProjectAction,
  getProjectSuccess,
  getProjectFailure,
  saveProject as saveProjectAction,
  saveSuccess,
  saveFailure,
  deleteProject as deleteProjectAction,
  deleteSuccess,
  deleteFailure,
  patch,
  patchFailure,
  patchSuccess,
  patchMultiple,
  patchMultipleSuccess,
  patchMultipleFailure,
  watchGetProject,
  watchSaveProject,
  watchDeleteProject,
  watchPatchProject,
  watchPatchMultipleProjects,
  duplicateProject as duplicateProjectAction,
  watchDuplicateProject,
  duplicateSuccess,
  duplicateFailure,
} from "./actions";
import Project, { IProject } from "../../../../models/Project";
import { RootState } from "../../../../index";

export const getActiveWorkspace = ({ api: { auth } }: RootState) =>
  auth.activeWorkspaceId;
export const getUserEmail = ({
  api: {
    auth: { user },
  },
}: RootState) => user.email;

export const getProjects = function* () {
  try {
    yield put(get());
    const workspace: string = yield select(getActiveWorkspace);
    const projects: Project[] = yield call(Project.list, workspace);
    yield put(getSuccess(projects));
  } catch (e) {
    console.warn(e);
    if (e instanceof Error) {
      yield put(getFailure(e.toString()));
    }
  }
};

export const getProject = function* getProject(
  arg: ReturnType<typeof watchGetProject>
) {
  const {
    payload: { id },
  } = arg;
  try {
    yield put(getProjectAction(id));

    const workspace: string = yield select(getActiveWorkspace);

    const project: Project = yield call(Project.get, workspace, id);

    yield put(getProjectSuccess(id, project));
  } catch (e) {
    console.warn(e);
    if (e instanceof Error) {
      yield put(getProjectFailure(id, e.toString()));
    }
  }
};

export const saveProject = function* (
  args: ReturnType<typeof watchSaveProject>
) {
  const { payload } = args;
  const { project, updates } = payload;

  const isNew = !project || !project.id;

  try {
    const workspace: string = yield select(getActiveWorkspace);
    const user: string = yield select(getUserEmail);

    yield put(saveProjectAction(project, updates));

    let savedProject: Project;

    if (isNew) {
      savedProject = yield call(
        Project.create,
        workspace,
        user,
        updates as IProject
      );
    } else {
      savedProject = yield call(
        Project.update,
        workspace,
        user,
        project,
        updates
      );
    }

    yield put(saveSuccess(savedProject));
  } catch (e) {
    if (e instanceof Error) {
      yield put(saveFailure(e.toString(), project, updates));
    }
    console.warn(e);
  }
};

export const deleteProject = function* (
  args: ReturnType<typeof watchDeleteProject>
) {
  const { payload } = args;
  const { id } = payload;
  try {
    const workspace: string = yield select(getActiveWorkspace);
    yield put(deleteProjectAction(id));
    yield call(Project.delete, workspace, id);
    yield put(deleteSuccess(id));
  } catch (e) {
    console.warn(e);
    if (e instanceof Error) {
      yield put(deleteFailure(id, e.toString()));
    }
  }
};

export const patchProject = function* patchProject(
  arg: ReturnType<typeof watchPatchProject>
) {
  try {
    const {
      payload: { id, updates },
    } = arg;

    yield put(patch(id));

    const workspace: string = yield select(getActiveWorkspace);
    const user: string = yield select(getUserEmail);

    yield call(Project.patch, workspace, user, id, updates);

    yield put(patchSuccess(id, updates));
  } catch (e) {
    console.warn(e);
    if (e instanceof Error) {
      yield put(patchFailure(e.toString()));
    }
  }
};

export const patchMultipleProjects = function* patchMultipleProjects(
  arg: ReturnType<typeof watchPatchMultipleProjects>
) {
  try {
    const {
      payload: { ids, updates, updatesById },
    } = arg;
    yield put(patchMultiple(ids));

    const workspace: string = yield select(getActiveWorkspace);
    const user: string = yield select(getUserEmail);

    yield call(
      Project.patchMultiple,
      workspace,
      user,
      ids,
      updates,
      updatesById
    );

    yield put(patchMultipleSuccess(ids, updates || {}, updatesById || {}));
  } catch (e) {
    console.warn(e);
    if (e instanceof Error) {
      yield put(patchMultipleFailure(e.toString()));
    }
  }
};

export const duplicateProject = function* (
  args: ReturnType<typeof watchDuplicateProject>
) {
  const { payload } = args;
  const { project } = payload;

  try {
    const workspace: string = yield select(getActiveWorkspace);
    const user: string = yield select(getUserEmail);

    yield put(duplicateProjectAction(project));

    const {
      billableRate,
      billable,
      userBillableRate,
      billableCurrency,
      budget,
      color,
      customer,
      externalReference,
      managerEmail,
      managerName,
      name,
      planned,
      tags,
      team,
      twntyFourSevenId,
      userGroups,
    } = project.data();
    const savedProject: Project = yield call(
      Project.create,
      workspace,
      user,
        {
          billableRate,
          billable,
          userBillableRate,
          billableCurrency,
          budget,
          color,
          customer,
          externalReference,
          managerEmail,
          managerName,
          name: `Duplicate of ${name}`,
          planned,
          tags,
          team,
          twntyFourSevenId,
          userGroups,
        } as IProject
    );

    yield put(duplicateSuccess(savedProject));
  } catch (e) {
    if (e instanceof Error) {
      yield put(duplicateFailure(e.toString(), project));
    }
    console.warn(e);
  }
};

export const getProjectsSaga = function* () {
  yield takeLatest(WATCH_GET, getProjects);
};

export const saveProjectSaga = function* () {
  yield takeEvery(WATCH_SAVE, saveProject);
};
export const deleteProjectSaga = function* () {
  yield takeEvery(WATCH_DELETE, deleteProject);
};

export const patchProjectSaga = function* patchProjectSaga() {
  yield takeLatest(WATCH_PATCH, patchProject);
};
export const duplicateProjectSaga = function* duplicateProjectSaga() {
  yield takeLatest(WATCH_DUPLICATE, duplicateProject);
};

export const patchMultipleProjectsSaga = function* patchMultipleProjectsSaga() {
  yield takeLatest(WATCH_PATCH_MULTIPLE, patchMultipleProjects);
};

export const getProjectSaga = function* getProjectSaga() {
  yield takeLatest(WATCH_GET_PROJECT, getProject);
};

export default function* () {
  yield all([
    getProjectsSaga(),
    getProjectSaga(),
    saveProjectSaga(),
    duplicateProjectSaga(),
    deleteProjectSaga(),
    patchProjectSaga(),
    patchMultipleProjectsSaga(),
  ]);
}
