import timesheet from "./timesheet/sagas";
import leads from "./leads/sagas";
import projects from "./projects/sagas";
import projectOverview from "./projectOverview/sagas";
import users from "./users/sagas";
import customers from "./customers/sagas";
import customerOverview2 from "./customerOverview2/sagas";
import dashboard from "./dashboard/sagas";
import userOverview from "./userOverview/sagas";
import invoices from "./invoices/sagas";
import companySettings from "./companySettings/sagas";
import userSettings from "./userSettings/sagas";
import userGroups from "./userGroups/sagas";
import integrations from "./integrations/sagas";
import pdf from "./pdf/sagas";
import workspaceOverview from "./workspaceOverview/sagas";

// Reports
import report from "./report/sagas";

export default [
  ...timesheet,
  ...leads,
  ...projects,
  ...projectOverview,
  ...users,
  ...customers,
  ...customerOverview2,
  ...dashboard,
  ...userOverview,
  ...userGroups,
  ...invoices,
  ...companySettings,
  ...userSettings,
  ...integrations,
  ...pdf,
  ...report,
  ...workspaceOverview
];
