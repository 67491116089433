// TODO: Hours by project
// PROJECTS: List of projects with users (avatar) activity current month
// USERS: List of users and totalt hours billed vs total current month
// Billable hours current month
// Average billable rate
// Total billed current month
// Hours by customer
// ...
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { Button } from "@superprofit/core-react-components/atoms";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import Snackbar from "@superprofit/core-react-components/atoms/Snackbar";
import Page, {
  PageActions,
  PageHeader,
  PageTitle
} from "../../components/atoms/Page";
import LinkButton from "../../components/atoms/LinkButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Skeleton from "@material-ui/lab/Skeleton";
import UserHeader from "../../components/molecules/UserHeader";
import { Paper, Tab, Tabs } from "@material-ui/core";
import Overview from "./userOverview/Overview";
import Plan from "./userOverview/Plan";
import { useIsAdmin } from "../../contexts/UserContext";
import { logEvent, ANALYTICS_EVENT } from "../../firebase";
import useTimetUser from "../../hooks/useTimetUser";
import { useTranslation } from "react-i18next";
import useUserEditDialog from "./users/hooks/useUserEditDialog";
import UserEditDialog from "./users/UserEditDialog";
import { getPathWithKey } from "../../router/routes";
import { RootState } from "../..";

interface MatchParams {
  id: string;
}
interface UserOverviewProps extends RouteComponentProps<MatchParams> {}

export default ({
  match: {
    params: { id }
  }
}: UserOverviewProps) => {
  const { t } = useTranslation();

  const { show } = useUserEditDialog();

  const isAdmin = useIsAdmin();
  const workspaceName = useSelector(
    (state: RootState) => state.api.auth?.activeWorkspaceId
  );

  const [tab, setTab] = useState("overview");

  const { user: authUser } = useSelector(
    (state: RootState) => state.api.currentUser
  );

  const { data: user, isLoading: loading } = useTimetUser({
    id
  });

  const snackbar = useSelector(
    (state: RootState) => state.ui.userSettings.snackbar
  );

  useEffect(() => {
    if (workspaceName) {
      logEvent(ANALYTICS_EVENT.USER_OVERVIEW_VISITED, {
        isAdmin,
        workspaceName
      });
    }
  }, [isAdmin, workspaceName]);
  const handleOnChangeTab = (_: React.ChangeEvent<{}>, val: any) => {
    setTab(val);
  };

  const handleOnEdit = () => {
    if (user?.id) show(user.id);
  };

  return (
    <Page padding={false}>
      <PageHeader color="primary">
        <Grid
          container
          spacing={3}
          component="div"
          style={{ marginBottom: 4 }}
          justify="space-between"
        >
          <Grid item xs={6} component="div">
            {authUser && id !== authUser.email && (
              <LinkButton
                color="inherit"
                startIcon={<ArrowBackIcon color="inherit" />}
                to={getPathWithKey("users")}
              >
                {t("common.users")}
              </LinkButton>
            )}
          </Grid>
          <Grid item xs={6} component="div">
            <PageActions>
              {isAdmin && (
                <Button
                  disabled={!user || loading}
                  color="inherit"
                  onClick={handleOnEdit}
                >
                  {t("common.edit")}
                </Button>
              )}
            </PageActions>
          </Grid>
        </Grid>

        <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
          <Grid container item xs={12} component="div" justify="center"></Grid>
          <Grid container item xs={12} component="div" justify="center">
            <PageTitle>
              {!user || loading ? (
                <Skeleton
                  component="div"
                  height={25}
                  width={200}
                  style={{ backgroundColor: "rgba(255,255,255,.5)" }}
                />
              ) : (
                <UserHeader user={user} />
              )}
            </PageTitle>
          </Grid>
        </Grid>
      </PageHeader>
      <Paper square>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleOnChangeTab}
          aria-label="disabled tabs example"
        >
          <Tab label={t("common.overview")} value="overview" />
          <Tab label={t("common.plan")} value="plan" />
        </Tabs>
      </Paper>

      {tab === "overview" && <Overview />}
      {tab === "plan" && <Plan />}

      {/* @ts-ignore  no type for AppBar yet.. */}
      <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={snackbar.visible}
          message={snackbar.text}
        />
      </AppBar>

      <UserEditDialog />
    </Page>
  );
};
