import React, { useState } from "react";
import { ProjectItem, ProjectItemContainer } from "./TimesheetV2.styles";
import Project from "../../../models/Project";
import Typography from "../../../components/atoms/Typography";
import IconButton from "../../../components/atoms/IconButton";
import useCustomersMap from "../../../hooks/useCustomersMap";
import MoreVert from "@material-ui/icons/MoreVert";
import Menu from "../../../components/atoms/Menu";
import MenuItem from "../../../components/atoms/MenuItem";
import Button from "../../../components/atoms/Button";
import { useTranslation } from "react-i18next";

interface IProps {
  project: Project;
  onHide: (project: Project) => void;
  isHidden: boolean;
}
const Item: React.FC<IProps> = ({ project, ...props }) => {
  const customersMap = useCustomersMap();
  const customer = customersMap.get(project.customer);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOnHide = () => {
    if (props.onHide) {
      props.onHide(project);
    }
    handleClose();
  };
  return (
    <>
      <ProjectItem isHidden={props.isHidden}>
        <ProjectItemContainer>
          <Typography>{project.name}</Typography>
          <Typography variant="caption">{customer?.name}</Typography>
        </ProjectItemContainer>
        <IconButton aria-haspopup="true" onClick={handleMoreClick}>
          <MoreVert />
        </IconButton>
      </ProjectItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <Button onClick={handleOnHide}>
            {t(props.isHidden ? "common.show" : "common.hide")}
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Item;
