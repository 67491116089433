import { SET_LOADING, SET_USERS_TABLE } from "./constants";

const initialState = {
  selected: [],
  list: [],
  searchTerm: "",
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload.isLoading
      };

    case SET_USERS_TABLE:
      return {
        ...state,
        list: payload.list
      };

    default:
      return state;
  }
};
