import useWorkspace from "./useWorkspace";
import { useQuery } from "@tanstack/react-query";
import UserAccess from "../models/UserAccess";

export const getKey = (workspace: string) => [
  workspace,
  UserAccess.collectionName,
  "list"
];

const useUsersAccess = () => {
  const workspace = useWorkspace();
  const get = () => UserAccess.list(workspace);
  const key = getKey(workspace);
  const query = useQuery(key, get, {
    staleTime: 1000 * 60 * 5 //five minutes stale time. rarely changes
  });
  return query;
};

export default useUsersAccess;
