import {
  darken,
  lighten
} from "@superprofit/core-react-components/themes/shared/colorUtils";

export const ChartOptions = (
  OFFSET,
  getTooltipTitle,
  getTooltipLabel,
  options = {}
) => ({
  legend: {
    display: false,
    labels: {
      boxWidth: 5,
      usePointStyle: true
    },
    position: "bottom",
    align: "left"
  },
  tooltips: {
    callbacks: {
      title: function(tooltipItems, data) {
        if (getTooltipTitle) return getTooltipTitle(tooltipItems, data, OFFSET);
        return "Week: " + tooltipItems[0].xLabel;
      },
      label: function(tooltipItem, data) {
        if (getTooltipLabel) return getTooltipLabel(tooltipItem, data, OFFSET);
        return ` ${(tooltipItem.yLabel &&
          -OFFSET + parseFloat(tooltipItem.yLabel)) ||
          "0.0"}`;
      }
    }
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    xAxes: [
      {
        display: false,
        gridLines: {
          display: true,
          borderDash: [2, 5],
          borderDashOffset: 2,
          drawBorder: false,
          zeroLineWidth: 0
          // display: false,
          // drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        display: false,
        ticks: {
          beginAtZero: true
        },
        gridLines: {
          display: false,
          borderDash: [2, 5],
          borderDashOffset: 2,
          drawBorder: false,
          zeroLineWidth: 0
        }
      }
    ]
  }
});

export const createGradientFill = (elementId, color, range, yMax) => {
  const el = document.getElementById(elementId);
  if (!el) return color;
  const ctx = el.getContext("2d");
  const { height } = el.getBoundingClientRect();
  const relative =
    range === -Infinity || yMax === -Infinity || !range || !yMax
      ? 0
      : yMax / range;
  const gradientFill1 = ctx.createLinearGradient(0, 0, 0, height);
  gradientFill1.addColorStop(0, lighten(color, 0.8));
  gradientFill1.addColorStop(1 - Math.max(relative, 0.3), lighten(color, 0.8));
  gradientFill1.addColorStop(1, lighten(color, 0.99));
  return gradientFill1;
};

export const createGradientStroke = (elementId, color1) => {
  const el = document.getElementById(elementId);
  if (!el) return color1;
  const ctx = el.getContext("2d");
  const { width } = el.getBoundingClientRect();
  const gradientStroke = ctx.createLinearGradient(0, 0, width, 0);
  gradientStroke.addColorStop(0, lighten(color1, 0.2));
  gradientStroke.addColorStop(1, darken(color1, 0.2));
  return gradientStroke;
};

export const createDataset = (label, data, color, options) => ({
  label: label,
  backgroundColor: color,
  borderColor: color,
  pointBorderColor: "#fff",
  pointBackgroundColor: color,
  pointBorderWidth: 0,
  pointRadius: 0,
  pointHoverBackgroundColor: color,
  pointHoverBorderColor: "rgba(255,255,255,1)",
  pointHoverBorderWidth: 0,
  pointHoverRadius: 8,
  pointHitRadius: 10,
  data: data,
  ...options
});

export const pointsOptions = showPoints => {
  if (showPoints) {
    return {
      pointBorderWidth: 1,
      pointRadius: 4
    };
  }
  return {
    pointBorderWidth: 0,
    pointRadius: 0
  };
};
