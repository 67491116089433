import { useMutation, useQueryClient } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import Project, { IProject } from "../models/Project";
import useUser from "./useUser";
import useProject from "./useProject";
import TimesheetComment from "../models/TimesheetComment";
import useProjects, { getKey as getProjectsKey } from "./useProjects";
import { getKey as getProjectKey } from "./useProject";
import { useTranslation } from "react-i18next";

const useProjectDuplicateMutation = () => {
  const { t } = useTranslation();
  const workspace = useWorkspace();
  const queryClient = useQueryClient();
  const { data: projects = [] } = useProjects();

  const user = useUser();
  const duplicate = (projectOrId: string | Project) => {
    let project: Project;
    if (typeof projectOrId === "string") {
      project = projects.find((p: Project) => p.id === projectOrId);
    } else {
      project = projectOrId;
    }
    if (!project) throw new Error("No project to duplicate");
    const clone = project.clone();
    clone.setData({
      id: null,
      name: `${clone.name} (${t("common.copy_noun")})`
    });
    return Project.create(workspace, user.email, clone.data());
  };

  return useMutation(duplicate, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        getProjectsKey(workspace),
        (projects: Project[] = []) => {
          const idx = projects.findIndex(p => p.id === data.id);
          if (idx > -1) return projects.map(p => (p.id === data.id ? data : p));
          return [data].concat(projects);
        }
      );
      queryClient.setQueryData(getProjectKey(workspace, data.id), () => data);
    }
  });
};

export default useProjectDuplicateMutation;
