import React, { Fragment, useState } from "react";
import FormDialog from "@superprofit/core-react-components/molecules/FormDialog";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Autocomplete,
  Avatar,
  Divider,
  Grid
} from "@superprofit/core-react-components/atoms";
import ConfirmationDialog from "../../../components/molecules/ConfirmationDialog";
import InputLabel from "@superprofit/core-react-components/atoms/InputLabel";
import {
  hideAssignProjectsDialog,
  updateAssignProjectsDialogData,
  watchSaveAssignProjects
} from "../../../redux/modules/ui/users/assignProjectsDialog/actions";
import {
  List,
  ListSubheader,
  Form,
  ListContainer,
  ListItemSecondaryAction,
  ListItemText,
  AvatarGroup
} from "./assignProjectsDialog/AssignProjectsDialog.styles";
import { ListItem } from "@superprofit/core-react-components/atoms/List";
import IconButton from "@superprofit/core-react-components/atoms/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import Tooltip from "@superprofit/core-react-components/atoms/Tooltip";
import { useTranslation } from "react-i18next";

export default ({ ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);

  const { open, saving: isSaving, users, updates, hasUnsaved } = useSelector(
    state => state.ui.users.assignProjectsDialog
  );
  const customers = useSelector(state => state.api.customers.list);
  const projects = useSelector(state => state.api.projects.list);

  const handleConfirmClose = () => {
    dispatch(hideAssignProjectsDialog());
  };

  const handleCancelClose = () => {
    setShowUnsavedConfirmation(false);
  };

  const handleOnClose = e => {
    if (isSaving) return;
    if (hasUnsaved) {
      setShowUnsavedConfirmation(true);
    } else {
      handleConfirmClose();
    }
  };

  const handleOnSave = () => {
    dispatch(watchSaveAssignProjects());
  };

  const handleAutocompleteChange = (e, project) => {
    if (!project) return;
    const exists =
      updates.assignedProjects.findIndex(p => p.id === project.id) > -1;
    if (!exists) {
      dispatch(
        updateAssignProjectsDialogData({
          ...updates,
          assignedProjects: [...updates.assignedProjects, project]
        })
      );
    }
  };

  const handleRemoveProject = project => () => {
    const existing = updates.removedProjects.find(p => p.id === project.id);
    const added = updates.assignedProjects.find(p => p.id === project.id);
    if (added) {
      dispatch(
        updateAssignProjectsDialogData({
          ...updates,
          assignedProjects: updates.assignedProjects.filter(
            p => p.id !== project.id
          )
        })
      );
    } else {
      const removedProjects = existing
        ? updates.removedProjects.filter(p => p.id !== project.id)
        : updates.removedProjects.concat([project]);
      dispatch(
        updateAssignProjectsDialogData({
          ...updates,
          removedProjects
        })
      );
    }
  };
  const unAssignedProjects = [];
  let assigned = {};

  projects.forEach(p => {
    const hasProject =
      p.team.filter(email => users.findIndex(u => u.email === email) > -1)
        .length === users.length;
    const added = updates.assignedProjects.find(ap => ap.id === p.id);
    const removed = updates.removedProjects.find(ap => ap.id === p.id);
    let proj = hasProject && p;
    if (added) proj = { ...added, __adding: true };
    else if (removed) proj = { ...removed, __removing: true };
    if (proj) {
      const cust = customers.find(c => proj.customer === c.id);
      if (cust) {
        assigned[cust.name] = assigned[cust.name]
          ? assigned[cust.name].concat([proj])
          : [proj];
      } else {
        assigned.Other = assigned.Other
          ? assigned.Other.concat([proj])
          : [proj];
      }
    } else {
      unAssignedProjects.push(p);
    }
  });

  unAssignedProjects.sort(
    (a, b) =>
      -(
        customers.find(c => c.id === b.customer)?.name || "Other"
      ).localeCompare(customers.find(c => c.id === a.customer)?.name || "Other")
  );

  return (
    <Fragment>
      <ConfirmationDialog
        key="unsaved"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showUnsavedConfirmation}
      />

      <FormDialog
        {...rest}
        key="form"
        saveActionTitle="Save"
        onSave={handleOnSave}
        open={open}
        onClose={handleOnClose}
        title="Assign projects"
        inProgress={isSaving}
        disabled={
          updates.removedProjects.length === 0 &&
          updates.assignedProjects.length === 0
        }
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <InputLabel variant="standard" shrink={false}>
                    User{users.length > 1 ? "s" : ""}
                  </InputLabel>{" "}
                </Grid>
                <Grid item xs={12}>
                  {users.length > 1 ? (
                    <Tooltip
                      title={users.reduce(
                        (acc, curr) => `${acc}${curr?.email || ""},`,
                        ""
                      )}
                      aria-label="More"
                    >
                      <AvatarGroup max={4}>
                        {users.map(u => (
                          <Avatar alt={u?.displayName} src={u?.picture}>
                            {(u?.displayName || u?.email || "")[0]}
                          </Avatar>
                        ))}
                      </AvatarGroup>
                    </Tooltip>
                  ) : (
                    <Typography>{users[0]?.email}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={unAssignedProjects}
                getOptionLabel={option => option.name}
                onChange={handleAutocompleteChange}
                groupBy={option =>
                  customers.find(c => c.id === option.customer)?.name || "Other"
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    helperText="Select a project from the list to assign it."
                    placeholder="Select project..."
                    label={t("common.projects")}
                    variant="filled"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid item>
              <InputLabel
                style={{ marginTop: "2rem" }}
                variant="standard"
                shrink={false}
              >
                Assigned projects
              </InputLabel>
            </Grid>
            <Grid item>
              <InputLabel
                style={{ marginTop: "2rem" }}
                variant="standard"
                shrink={false}
              >
                <b style={{ color: "green" }}>
                  {updates.assignedProjects.length > 0
                    ? `${updates.assignedProjects.length} ADD`
                    : ""}{" "}
                </b>
                {updates.assignedProjects.length > 0 &&
                updates.removedProjects.length > 0
                  ? " / "
                  : ""}
                <b style={{ color: "red" }}>
                  {updates.removedProjects.length > 0
                    ? `${updates.removedProjects.length} REMOVE`
                    : ""}
                </b>
              </InputLabel>
            </Grid>
          </Grid>

          <Divider />
          <ListContainer item xs={12}>
            <List subheader={<li />}>
              {Object.keys(assigned).map(name => (
                <li key={`section-${name}`}>
                  <ul>
                    <ListSubheader>{name}</ListSubheader>
                    {assigned[name].map(item => (
                      <ListItem key={`item-${name}-${item.name}`}>
                        <ListItemText
                          adding={item.__adding}
                          removing={item.__removing}
                          primary={item.name}
                        />

                        <ListItemSecondaryAction
                          adding={item.__adding}
                          removing={item.__removing}
                        >
                          <IconButton
                            onClick={handleRemoveProject(item)}
                            edge="end"
                            aria-label="delete"
                          >
                            {item.__removing ? (
                              <RestoreFromTrashIcon />
                            ) : (
                              <DeleteIcon />
                            )}
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </ul>
                </li>
              ))}
            </List>
          </ListContainer>
        </Form>
      </FormDialog>
    </Fragment>
  );
};
