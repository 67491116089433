import { QueryBuilder } from "./QueryBuilder";
import Timestamp from "./Timestamp";

export default class Workspace {
  static converter = {
    toFirestore(user) {
      return user.data();
    },
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      return new Workspace({ ...data, id: snapshot.id });
    },
  };

  static collectionName = "workspaces";

  static createId(workspace) {
    return workspace;
  }

  constructor({
    id,
    name,
    billingInformation,
    displayName = "",
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
  }) {
    this.id = id;
    this.name = name;
    this.billingInformation = billingInformation;
    this.displayName = displayName;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.updatedBy = updatedBy;
    this.updatedAt = updatedAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      name: this.name,
      billingInformation: this.billingInformation || null,
      displayName: this.displayName,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
    };
  }

  static exists = async (db, workspace) => {
    const snapshot = await db
      .collection(Workspace.collectionName)
      .doc(workspace)
      .collection("workspace_public_metadata")
      .doc('data')
      .get();
    const data = snapshot.data();
    return data && data.name === workspace
  };

  static get = async (db, workspace) => {
    const snapshot = await db
      .collection(Workspace.collectionName)
      .doc(workspace)
      .withConverter(Workspace.converter)
      .get();

    return snapshot.data();
  };

  static listen = (db, workspace, callback) => {
    return db
      .collection("workspaces")
      .doc(workspace)
      .withConverter(Workspace.converter)
      .onSnapshot((doc) => {
        callback(doc.data());
      });
  };

  static list = async (db, workspace, query) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(Workspace.collectionName),
      query
    )
      .withConverter(Workspace.converter)
      .get();

    return snapshot.docs.map((doc) => doc.data());
  };

  static update = async (db, wpName, byEmployee, workspace, updates) => {
    const updatedWorkspace = workspace.clone();

    updatedWorkspace.setData({
      ...updates,
      updatedAt: Timestamp.now(),
      updatedBy: byEmployee
    });

    await db
      .collection(Workspace.collectionName)
      .doc(wpName)
      .withConverter(Workspace.converter)
      .set(updatedWorkspace, { merge: true });

    return updatedWorkspace;
  };

  static create = async (db, __notInUseHere__, user, workspace) => {
    console.log('Not in use.')
    // const name = workspace.name.trim().toLowerCase(); // no need to differ on casing.
    // const newWorkspace = new Workspace({
    //   id: Workspace.createId(name),
    //   name,
    //   displayName: workspace.displayName || "",
    //   updatedAt: Timestamp.now(),
    //   createdAt: Timestamp.now(),
    //   updatedBy: user,
    //   createdBy: user,
    // });
    //
    // await db
    //   .collection(Workspace.collectionName)
    //   .doc(Workspace.createId(workspace.name))
    //   .withConverter(Workspace.converter)
    //   .set(newWorkspace, { merge: true });
    //
    // return newWorkspace;
  };
}
