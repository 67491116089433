import { ModuleFactory } from "@superprofit/core-redux-saga-modules/Helpers";
import { StripeAccountDetails } from "@superprofit/core-firestore-models";
import { db } from "../../../../firebase";

let jestWorkaroundModule;

// TODO: Remove this when we have a better solution for testing
// TODO: Remove this whole module.Is it even needed?
if (process.env.NODE_ENV === "test") {
  jestWorkaroundModule = { collectionName: "stripeAccountDetails" };
} else {
  jestWorkaroundModule = StripeAccountDetails;
}

const m = new ModuleFactory(jestWorkaroundModule, db);

export const reducer = m.reducer;
export const sagas = m.sagas;
export const actions = m.actions;
export const constants = m.constants;
