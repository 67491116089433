import { QueryBuilder, Timestamp } from ".";
import Isemail from "isemail";
import { db } from "@superprofit/timet-react-client/src/firebase";

export default class User {
  static validateEmail(email) {
    const isemail = Isemail.validate(email);
    if (!isemail) {
      throw new Error(
        `[core-firestore-models Users] ${email} is not a valid email`
      );
    }
  }

  static converter = {
    toFirestore(user) {
      return user.data();
    },
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      return new User({ ...data, id: snapshot.id });
    },
  };

  static collectionName = "users";

  static createId(db, workspace) {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(User.collectionName)
      .doc().id;
  }

  constructor({
    id,
    displayName,
    email,
    emailVerified,
    picture,
    tags,
    userId,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
  }) {
    this.id = id;
    this.displayName = displayName || null;
    this.email = email;
    this.emailVerified = emailVerified;
    this.picture = picture || null;
    this.tags = tags;
    this.userId = userId || null;

    this.createdBy = createdBy || null;
    this.createdAt = createdAt || null;
    this.updatedBy = updatedBy || null;
    this.updatedAt = updatedAt || null;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      displayName: this.displayName || "",
      email: this.email,
      emailVerified: !!this.emailVerified,
      picture: this.picture || "",
      tags: this.tags || [],
      userId: this.userId || "",
      createdBy: this.createdBy || null,
      createdAt: this.createdAt || null,
      updatedBy: this.updatedBy || null,
      updatedAt: this.updatedAt || null,
    };
  }

  static get = async (workspace, user) => {
    const doc = await db
      .collection("workspaces")
      .doc(workspace)
      .collection("users")
      .doc(user)
      .get();
    return { ...doc.data(), id: doc.id };
  };

  static getAll = (workspace, usersIds) => {
    return Promise.all(usersIds.map((user) => User.get(workspace, user)));
  };

  static list = async (db, workspace, query) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(User.collectionName),
      query
    )
      .withConverter(User.converter)
      .get();

    return snapshot.docs.map((doc) => doc.data());
  };

  static create = async (db, workspace, user, data) => {
    if (data.email) User.validateEmail(data.email);
    const newUser = new User({
      ...data,
      id: data.email,
      updatedAt: Timestamp.now(),
      createdAt: Timestamp.now(),
      updatedBy: user,
      createdBy: user,
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(User.collectionName)
      .doc(newUser.id)
      .withConverter(User.converter)
      .set(newUser, { merge: true });

    return newUser;
  };

  static update = async (db, workspace, byUser, user, updates) => {
    if (updates.email) User.validateEmail(updates.email);
    const updatedUser = user.clone();
    updatedUser.setData({
      ...updates,
      updatedAt: Timestamp.now(),
      updatedBy: byUser,
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(User.collectionName)
      .doc(updatedUser.id)
      .withConverter(User.converter)
      .set(updatedUser, { merge: true });

    return updatedUser;
  };
}
