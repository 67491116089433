import snackbar from "./snackbar/sagas";
import table from "./table/sagas";
import assignProjectsDialog from "./assignProjectsDialog/sagas";
import accessControlDialog from "./accessControlDialog/sagas";

export default [
  snackbar(),
  table(),
  accessControlDialog(),
  assignProjectsDialog()
];
