import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import {
  GET_SUCCESS,
  SAVE_SUCCESS,
  BATCH_SAVE_SUCCESS
} from "../../../api/timesheet/entries/constants";

import { setEntriesByProject, watchRefreshEntries } from "./actions";
import { WATCH_REFRESH_ENTRIES } from "./constants";

export const getEntries = ({
  api: {
    timesheet: { entries }
  }
}) => entries.list;

const getEntriesByProject = entries =>
  entries.reduce(
    (prev, next) => ({
      ...prev,
      [next.project]: (prev[next.project] || []).concat(next)
    }),
    {}
  );

export const handleEntriesRefresh = function*(args) {
  try {
    const entries = yield select(getEntries);
    const entriesByProject = getEntriesByProject(entries);
    // console.log(entriesByProject);
    yield put(setEntriesByProject(entriesByProject));
  } catch (e) {
    console.warn(e);
  }
};

export const handleRefreshEntries = function*() {
  yield put(watchRefreshEntries());
};

export const handleEntriesGetSuccessSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleRefreshEntries);
};

export const handleEntriesSaveSuccessSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleRefreshEntries);
};

export const handleEntriesSaveBatchSuccessSaga = function*() {
  yield takeLatest(BATCH_SAVE_SUCCESS, handleRefreshEntries);
};

export const handleEntriesRefreshSaga = function*() {
  yield takeLatest(WATCH_REFRESH_ENTRIES, handleEntriesRefresh);
};

export default function*() {
  yield all([
    handleEntriesRefreshSaga(),
    handleEntriesGetSuccessSaga(),
    handleEntriesSaveSuccessSaga(),
    handleEntriesSaveBatchSuccessSaga()
  ]);
}
