import Grid from "@material-ui/core/Grid";
import Typography from "../../../atoms/Typography";
import PerSeatProduct from "../PerSeatSubscription";
import {
  Button,
  Card,
  CircularProgress,
  NumberFormatIntl,
  Skeleton,
} from "../../../atoms";
import CardContent from "../../../atoms/CardContent";
import React, { useContext, useEffect, useState } from "react";
import retrieveUpcomingInvoice from "./updateSubscription/retrieveUpcomingInvoice";
import { actionTypes } from "./reducer";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import updateSubscription from "./updateSubscription/helpers/updateSubscription";
import SeatsRow from "./updateSubscription/SeatsRow";
import ChargedRow from "./updateSubscription/ChargedRow";
import DueRow from "./updateSubscription/DueRow";
import ButtonRow from "./updateSubscription/ButtonRow";
import Context from "./context";
import CouponCard from "./CouponCard";

// Hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export default ({ state, dispatch }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { reset, initialize } = useContext(Context);

  const {
    products,
    subscriptionInformation,
    upcomingInvoice,
    isLoadingUpcomingInvoice,
    baseUrl: apiBase,
    firebaseIdToken,
    updatedSubscriptionStatus,
    isUpdatingSubscription,
    coupon
  } = state;

  const subscription = subscriptionInformation?.subscription;
  const {
    updatedSubscriptionProductId = subscription?.plan?.product?.id,
    updatedSubscriptionSeats = subscription?.quantity,
  } = state;

  const debouncedSeats = useDebounce(updatedSubscriptionSeats, 500);

  const selectedProduct = products.find(
    (p) => p?.id === updatedSubscriptionProductId
  );

  const handleClick = async () => {
    const handleSubscribe = async () => {
      const priceId = updatedProduct.prices[0].id;
      dispatch({ type: actionTypes.toggleUpdatingSubscription });
      try {
        const { response, json } = await updateSubscription({
          apiBase,
          priceId,
          subscriptionId: subscription.id,
          customerId: subscription.customer.id,
          quantity: updatedSubscriptionSeats,
          cancelAtPeriodEnd: false,
          coupon: coupon?.id
        });

        if (response.status === 402 && json.object === "payment_intent") {
          try {
            await stripe.confirmCardPayment(json.client_secret, {
              payment_method: json.payment_method,
            });
            dispatch({
              type: actionTypes.setUpdatedSubscriptionStatus,
              payload: {
                updatedSubscriptionStatus: {
                  status: "success",
                  message: "Success!",
                },
              },
            });
          } catch (e) {
            dispatch({
              type: actionTypes.setUpdatedSubscriptionStatus,
              payload: {
                updatedSubscriptionStatus: {
                  status: "error",
                  message: e.toString(),
                },
              },
            });
          }
        } else if (response.ok) {
          dispatch({
            type: actionTypes.setUpdatedSubscriptionStatus,
            payload: {
              updatedSubscriptionStatus: {
                status: "success",
                message: "Success!",
              },
            },
          });
        } else {
          dispatch({
            type: actionTypes.setUpdatedSubscriptionStatus,
            payload: {
              updatedSubscriptionStatus: {
                status: "error",
                message: response.statusText,
              },
            },
          });
        }
      } catch (e) {
        dispatch({
          type: actionTypes.setUpdatedSubscriptionStatus,
          payload: {
            updatedSubscriptionStatus: {
              status: "error",
              message: e.toString(),
            },
          },
        });
      }
      dispatch({ type: actionTypes.toggleUpdatingSubscription });
    };

    if (updatedSubscriptionStatus?.status === "success") {
      reset();
      initialize();
    } else {
      handleSubscribe().then((m) => m);
    }
  };

  const handleProductChange = (id) => (seats) => {
    dispatch({
      type: actionTypes.setUpdatedSubscriptionProductId,
      payload: { id },
    });
    dispatch({
      type: actionTypes.setUpdatedSubscriptionSeats,
      payload: { seats },
    });
  };

  useEffect(() => {
    if (debouncedSeats && subscription) {
      let isCancelled = false;
      const d = (args) => !isCancelled && dispatch(args);
      const get = async () => {
        const upcomingInvoice = await retrieveUpcomingInvoice({
          apiBase: state.baseUrl,
          subscriptionId: subscription.id,
          customerId: subscription.customer.id,
          priceId: subscription.plan.id, // add state.newPriceId if we start using more subscriptions.
          quantity: state.updatedSubscriptionSeats,
          token: state.firebaseIdToken,
          coupon: state.coupon?.valid ? state.coupon.id : undefined,
        });
        if (upcomingInvoice) {
          d({
            type: actionTypes.setUpcominginvoice,
            payload: { upcomingInvoice },
          });
        }

        d({ type: actionTypes.toggleLoadingUpcomingInvoice });
      };
      get();
      return () => (isCancelled = true);
    }
  }, [debouncedSeats, state.coupon]);


  useEffect(() => {
    if (!isLoadingUpcomingInvoice) {
      dispatch({ type: actionTypes.toggleLoadingUpcomingInvoice });
    }
  }, [updatedSubscriptionSeats, state.coupon]);

  const updatedProduct = state.products?.find(
    (p) => p.id === updatedSubscriptionProductId
  );

  if (
    !state.showUpdateSubscription ||
    state.isLoadingProducts ||
    state.isLoadingSubscriptions
  )
    return null;
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">Update subscription</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              {products.map((p) => (
                <PerSeatProduct
                  owned={
                    subscription?.plan.product?.id === p.id && subscription
                  }
                  selected={selectedProduct?.id === p.id}
                  product={p}
                  onChange={handleProductChange(p.id)}
                  onSelect={handleProductChange(p.id)}
                />
              ))}
            </Grid>

            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CouponCard state={state} dispatch={dispatch} />
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        <SeatsRow state={state} />
                        <ChargedRow state={state} />
                        <DueRow state={state} />
                        <ButtonRow state={state} onClick={handleClick} />
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
