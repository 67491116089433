import styled, { css } from "styled-components";
import theme from "styled-theming";
import { Checkbox } from "@material-ui/core";

const colors = theme.variants("mode", "extcolor", {
  alert: {
    light: css`
      &.Mui-checked {
        color: ${props => props.theme.palette.error.main};
      }
    `,
    dark: css``
  }
});

export const StyledCheckbox = styled(Checkbox)`
  ${colors};
`;
