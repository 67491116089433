import { projectId } from "../../../../config/firebase";

export type Identity = {
  id: string;
  client: {
    id: number;
    name: string;
  };
};

export async function getIdentities(
  username: string,
  password: string
): Promise<Identity[]> {
  const url = `https://europe-west1-${projectId}.cloudfunctions.net/integrations-twentyFourSO-soap-identities`;
  const result = await fetch(url, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      username,
      password
    })
  });
  if (!result.ok) {
    throw new Error("Failed to authenticate");
  }
  return result.json();
}

export async function getAuthToken(
  username: string,
  password: string,
  identity: string,
  workspace: string
) {
  const url = `https://europe-west1-${projectId}.cloudfunctions.net/integrations-twentyFourSO-soap-authenticate`;
  // const url = `http://127.0.0.1:5001/dev-timet-eu/europe-west1/integrations-twentyFourSO-soap-authenticate`;
  const result = await fetch(url, {
    method: "POST",
    cache: "no-cache",
    headers: {
      Accept: "text/plain",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      username,
      password,
      identity,
      workspace
    })
  });
  if (!result.ok) {
    throw new Error("Failed to authenticate");
  }
  return result.text();
}

export function setAuthTokenInLocalStorage(token: string, workspace: string) {
  localStorage.setItem(`x-247-authorization-${workspace}`, token);
}
export function getAuthTokenFromLocalStorage(workspace: string) {
  return localStorage.getItem(`x-247-authorization-${workspace}`);
}

export function removeAuthTokenFromLocalStorage(workspace: string) {
  localStorage.removeItem(`x-247-authorization-${workspace}`);
}

export async function hasSession(workspace: string) {
  const token = getAuthTokenFromLocalStorage(workspace);
  if (!token) {
    return false;
  }
  const url = `https://europe-west1-${projectId}.cloudfunctions.net/integrations-twentyFourSO-soap-has-session`;
  const result = await fetch(url, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-247-authorization": token
    }
  });
  if (!result.ok) {
    removeAuthTokenFromLocalStorage(workspace);
  }

  return result.ok;
}
