import React from "react";
import { Document } from "@react-pdf/renderer";
import {Page, Timet, WorkspaceName} from '../pdfRenderer.styles';
import PageHeader from "../PageHeader";
import PageFooter from "../PageFooter";
import HoursTable from "../HoursTable";
import { moment } from "@superprofit/time-util";
import {removeEntriesWithZeroHours, sortEntriesInProjectByDate} from '../helpers';

/* messy solution */
export default ({ currency, projects, users, entries = [], workspaceName = '' }) => {
  const sorted = removeEntriesWithZeroHours(entries).reduce((acc, entry) => {
    let project = acc[entry.project] || [];
    if (project) project = [...project, entry];
    else project = [entry];
    return {
      ...acc,
      [entry.project]: sortEntriesInProjectByDate(project),
    };
  }, {});

  const pages = [];
  Object.keys(sorted).forEach((key) => {
    const list = sorted[key];
    const proj = projects.find((o) => o.id === key);
    const getTotalHours = () => list.reduce((acc, e) => e.hours + acc, 0);
    const getTotalCost = () =>
      list.reduce((acc, e) => {
        const billableRate = proj.userBillableRate[e.user]
          ? proj.userBillableRate[e.user]
          : proj.billableRate;
        return acc + billableRate * e.hours;
      }, 0);

    const MAX_ENTRIES_OTHER_PAGES = 28;
    const MAX_ENTRIES_FIRST_PAGE = 25;
    let i = 0;
    while (i < list.length) {
      pages[pages.length] = [];
      pages[pages.length - 1].push(<WorkspaceName>{workspaceName}</WorkspaceName>);
      if (i === 0) {
        const first = list[0];
        const last = list[list.length - 1];
        const fromFormated = moment({ year: first.year}
        ).add(first.dayOfYear - 1, 'day', ).format("DD.MMMM YYYY");

        const toFormated = moment({ year: last.year}
        ).add(last.dayOfYear - 1, 'day', ).format("DD.MMMM YYYY");
        pages[pages.length - 1].push(
          <PageHeader gutterBottom subtitle={`${fromFormated} - ${toFormated}`} title={proj.name} />
        );
        pages[pages.length - 1].push(
          <HoursTable
            currency={currency}
            showHeader={i === 0}
            users={users}
            showUser
            entries={list.slice(i, i + MAX_ENTRIES_FIRST_PAGE)}
            project={proj}
            showTotal={i + MAX_ENTRIES_FIRST_PAGE >= list.length}
            getTotalCost={getTotalCost}
            getTotalHours={getTotalHours}
          />
        );
        i += MAX_ENTRIES_FIRST_PAGE;
      } else if (i + MAX_ENTRIES_OTHER_PAGES >= list.length) {
        // last page
        pages[pages.length - 1].push(
          <HoursTable
            currency={currency}
            showHeader={i === 0}
            users={users}
            showUser
            entries={list.slice(i, i + MAX_ENTRIES_OTHER_PAGES)}
            project={proj}
            getTotalCost={getTotalCost}
            getTotalHours={getTotalHours}
          />
        );
        i += MAX_ENTRIES_OTHER_PAGES;
      } else {
        pages[pages.length - 1].push(
          <HoursTable
            currency={currency}
            showHeader={i === 0}
            users={users}
            showUser
            entries={list.slice(i, i + MAX_ENTRIES_OTHER_PAGES)}
            project={proj}
            showTotal={false}
          />
        );
        i += MAX_ENTRIES_OTHER_PAGES;
      }
    }
  });

  const mapped = pages.map((page, idx) => (
    <Page key={`random_${Math.random()}`} size="A4">
      {page}
      <PageFooter>{idx + 1}</PageFooter>
    </Page>
  ));

  return <Document>{mapped}</Document>;
};
