import styled from "styled-components";
import MuiCard from "../../../../../components/atoms/Card";
import MuiCardContent from "../../../../../components/atoms/CardContent";
import CardHeader from "../../../../../components/atoms/CardHeader";
import IconButton from "../../../../../components/atoms/IconButton";
export const StyledCard = styled(MuiCard)<{ isHidden?: boolean }>`
  ${({ isHidden }) => (isHidden ? "background-color: transparent" : null)};
`;

export const StyledCardContent = styled(MuiCardContent)`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(4, minmax(10px, 1fr)); /* Four columns */

  @media screen and (min-width: 600px) {
    & {
      grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
    }
  }
`;

export const StyledIconButton = styled(IconButton)<{ expanded: boolean }>`
  && {
    margin-left: auto;

    > span > svg {
      transition: all 0.3s ease-in-out;
      transform: rotate(${({ expanded }) => (expanded ? "180deg" : "0deg")});
    }
  }
`;

export const StyledHeader = styled(CardHeader)`
  //.MuiCardHeader-action {
  //  align-self: center;
  //}
`;
