export const WATCH_AUTHENTICATE =
  "api/integrations/twentyfourseven/auth/WATCH_AUTHENTICATE";
export const WATCH_CHECK_HAS_SESSION =
  "api/integrations/twentyfourseven/auth/WATCH_CHECK_HAS_SESSION";

export const AUTHENTICATE =
  "api/integrations/twentyfourseven/auth/AUTHENTICATE";
export const AUTHENTICATE_SUCCESS =
  "api/integrations/twentyfourseven/auth/AUTHENTICATE_SUCCESS";
export const AUTHENTICATE_FAILURE =
  "api/integrations/twentyfourseven/auth/AUTHENTICATE_FAILURE";

export const CHECK_HAS_SESSION =
  "api/integrations/twentyfourseven/auth/CHECK_HAS_SESSION";
export const CHECK_HAS_SESSION_SUCCESS =
  "api/integrations/twentyfourseven/auth/CHECK_HAS_SESSION_SUCCESS";
export const CHECK_HAS_SESSION_FAILURE =
  "api/integrations/twentyfourseven/auth/CHECK_HAS_SESSION_FAILURE";
