import { darken, lighten } from "../shared/colorUtils";

export const colors = {
  primary: "#3f51b5", //"#1976d2", //"#1495FE", //"#1976d2", //'#667BFF', //'#1976d2', //'#7376F0',
  secondary: "#2CCBA8",
  tertiary: "#EA4C88", //"#1976d2", //"#B871FF", // "#FA008A"
  alert: "#e53935", //"#F05A5D",
  grey: "#F3F5F9",
  success: "#00912E",
  warning: "#FF7400",
  font: darken("#546e7a", 0.15), //'#4B4D4F',
  fontLight: "#546e7a", //lighten('#4B4D4F', 0.3),
  fontDark: darken("#546e7a", 0.3), //darken('#4B4D4F', 0.3),
  fontExtraLight: lighten("#546e7a", 0.2),
  background: "#F1F3F7",
  highlight: "#FFFFFF",
  white: "#FFFFFF"
};
