import React, { useMemo } from "react";
import Hint from "../../../../../components/atoms/Hint";
import Important from "../../../../../components/atoms/Important";
import Grid from "@material-ui/core/Grid";
import { StyledLogged } from "./Logged.styles";
import Skeleton from "@material-ui/lab/Skeleton";
import DurationFormat from "../../../../../components/atoms/DurationFormat";
import NumberFormat from "react-number-format";
import {
  getHoursByProject,
  getMoneyByProject
} from "../../../../../utils/ProjectStats";
import TrendIndicator from "../../../../../components/atoms/TrendIndicator";
import { LoggedItem } from "./Logged.styles";
import ConnectedCurrencyFormat from "../../../../../components/organisms/ConnectedCurrencyFormat";
import { useTranslation } from "react-i18next";

export default ({
  loading,
  project,
  stats,
  statsBefore,
  year,
  month,
  ...rest
}) => {
  const { t } = useTranslation();

  const { money, hours } = useMemo(() => {
    if (loading || !project) return {};
    const monthHours = getHoursByProject(stats);
    const monthMoney = getMoneyByProject(stats, { [project.id]: project });

    return {
      money: monthMoney[project.id],
      hours: monthHours[project.id]
    };
  }, [project, stats, loading]);

  const { moneyPrev, hoursPrev } = useMemo(() => {
    if (loading || !project) return {};

    const monthHours = getHoursByProject(statsBefore);
    const monthMoney = getMoneyByProject(statsBefore, {
      [project.id]: project
    });

    return {
      moneyPrev: monthMoney[project.id],
      hoursPrev: monthHours[project.id]
    };
  }, [project, statsBefore, loading]);

  const [hoursTrend, moneyTrend] = useMemo(() => {
    if (loading) return [null, null];
    const hoursTrend = Math.round(
      (((hours || 0) - (hoursPrev || 0)) / (hoursPrev || 0)) * 100
    );
    const moneyTrend = Math.round(
      (((money || 0) - (moneyPrev || 0)) / (moneyPrev || 0)) * 100
    );

    return [hoursTrend, moneyTrend];
  }, [moneyPrev, hoursPrev, money, hours, loading]);

  const plannedHours = useMemo(() => {
    if (!project || loading || !project.getProjectPlannedHoursByMonth) return 0;
    return project.getProjectPlannedHoursByMonth(year, month);
  }, [loading, project]);

  return (
    <StyledLogged>
      <Grid
        container
        spacing={6}
        component="div"
        alignItems="center"
        justify="flex-start"
      >
        <Grid item component="div">
          <Hint>{t("common.logged")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <LoggedItem>
                <DurationFormat value={hours} />
                <TrendIndicator changePercentage={hoursTrend} />
              </LoggedItem>
            </Important>
          )}
        </Grid>

        <Grid item component="div">
          <Hint>{t("common.billable")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <LoggedItem>
                <ConnectedCurrencyFormat number={money || 0} />
                <TrendIndicator changePercentage={moneyTrend} />
              </LoggedItem>
            </Important>
          )}
        </Grid>

        <Grid item component="div">
          <Hint>{t("common.planned")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <LoggedItem>
                <DurationFormat value={plannedHours} />
              </LoggedItem>
            </Important>
          )}
        </Grid>
      </Grid>
    </StyledLogged>
  );
};
