import styled from "styled-components";
import { darken } from "@superprofit/core-react-components/themes/shared/colorUtils";

export const Distribution = styled.div`
  min-width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  height: 1.5rem;
  background: ${props => props.theme.palette.background.light};
`;

export const DistributionSection = styled.div<{ percentage: number }>`
  background: ${props => props.color};
  width: ${props => props.percentage}%;
  height: 1.5rem;
  transform: scale(1);
  transition: all cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    z-index: 2;
    background: ${props => darken(props.color, 0.2)};
    transform: scaleY(1.1);
  }
  &:first-child {
    border-top-left-radius: ${props => props.theme.shape.borderRadius}px;
    border-bottom-left-radius: ${props => props.theme.shape.borderRadius}px;
  }

  &:last-child {
    border-top-right-radius: ${props => props.theme.shape.borderRadius}px;
    border-bottom-right-radius: ${props => props.theme.shape.borderRadius}px;
  }
`;
