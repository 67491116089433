import { action } from "typesafe-actions";


import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
  DELETE,
  DELETE_FAILURE,
  DELETE_SUCCESS,
  DUPLICATE,
  DUPLICATE_FAILURE,
  DUPLICATE_SUCCESS,
  WATCH_DUPLICATE,
  WATCH_GET,
  WATCH_SAVE,
  WATCH_DELETE,
  PATCH,
  PATCH_FAILURE,
  PATCH_SUCCESS,
  WATCH_PATCH,
  WATCH_GET_PROJECT,
  GET_PROJECT,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILURE,
  PATCH_MULTIPLE,
  PATCH_MULTIPLE_SUCCESS,
  PATCH_MULTIPLE_FAILURE,
  WATCH_PATCH_MULTIPLE
} from "./constants";
import {Project, Updates, UpdatesById} from './types'


export const get = () => action(GET);
export const getSuccess = (list: Project[]) =>
    action(GET_SUCCESS, { list });
export const getFailure = (error: string) => action(GET_FAILURE, { error });
// export const watchGetUserGroups = (users: string[] | [] = []) => action(WATCH_GET, { users});
// export const get = () => ({
//   type: GET,
//   payload: {}
// });
//
// export const getSuccess = (list: Project[]) => ({
//   type: GET_SUCCESS,
//   payload: { list, pagination }
// });
//
// export const getFailure = error => ({
//   type: GET_FAILURE,
//   payload: { error }
// });


export const getProject = (id: string) => action(GET_PROJECT, { id});
export const getProjectSuccess = (id: string, project: Project) =>
    action(GET_PROJECT_SUCCESS, { id, project });
export const getProjectFailure = (id: string, error: string) => action(GET_PROJECT_FAILURE, { error });

// export const getProject = id => ({
//   type: GET_PROJECT,
//   payload: { id }
// });
//
// export const getProjectSuccess = (id, project) => ({
//   type: GET_PROJECT_SUCCESS,
//   payload: { id, project }
// });
//
// export const getProjectFailure = (id, error) => ({
//   type: GET_PROJECT_FAILURE,
//   payload: { id, error }
// });





export const saveProject = (project: Project, updates: Updates) => action(SAVE, { project, updates });
export const saveSuccess = (project: Project) =>
    action(SAVE_SUCCESS, { project });
export const saveFailure = (error: string, project: Project, updates: Updates) => action(SAVE_FAILURE, { project, updates, error });

// export const saveProject = (project, updates) => ({
//   type: SAVE,
//   payload: { project, updates }
// });
//
// export const saveSuccess = project => ({
//   type: SAVE_SUCCESS,
//   payload: { project }
// });
//
// export const saveFailure = (error, project, updates) => ({
//   type: SAVE_FAILURE,
//   payload: { project, updates, error }
// });






export const patch = (id: string) => action(PATCH, { id });
export const patchSuccess = (id: string, updates: Updates) =>
    action(PATCH_SUCCESS, { id, updates });
export const patchFailure = (error: string ) => action(PATCH_FAILURE, { error });

// export const patch = id => ({
//   type: PATCH,
//   payload: { id }
// });
//
// export const patchSuccess = (id, updates) => ({
//   type: PATCH_SUCCESS,
//   payload: { id, updates }
// });
//
// export const patchFailure = error => ({
//   type: PATCH_FAILURE,
//   payload: { error }
// });

export const patchMultiple = (ids: string[]) => action(PATCH_MULTIPLE, { ids })
export const patchMultipleSuccess = (ids: string[], updates: Updates, updatesById: UpdatesById) => action(PATCH_MULTIPLE_SUCCESS, { ids, updates, updatesById })
export const patchMultipleFailure = (error: string) => action(PATCH_MULTIPLE_FAILURE, { error})
// export const patchMultiple = ids => ({
//   type: PATCH_MULTIPLE,
//   payload: { ids }
// });

// export const patchMultipleSuccess = (ids, updates = {}, updatesById = {}) => ({
//   type: PATCH_MULTIPLE_SUCCESS,
//   payload: { ids, updates, updatesById }
// });

// export const patchMultipleFailure = error => ({
//   type: PATCH_MULTIPLE_FAILURE,
//   payload: { error }
// });

export const deleteProject = (id: string) => action(DELETE, {id});
export const deleteSuccess = (id: string) => action(DELETE_SUCCESS, {id})
export const deleteFailure = (id: string, error: string) => action(DELETE_FAILURE, {id, error})

// export const deleteProject = id => ({
//   type: DELETE,
//   payload: { id }
// });
//
// export const deleteSuccess = id => ({
//   type: DELETE_SUCCESS,
//   payload: { id }
// });
//
// export const deleteFailure = (id, error) => ({
//   type: DELETE_FAILURE,
//   payload: { id, error }
// });

export const duplicateProject = (project: Project) => action(DUPLICATE, {project});
export const duplicateSuccess = (project: Project) => action(DUPLICATE_SUCCESS, {project})
export const duplicateFailure = (error: string, project: Project) => action(DUPLICATE_FAILURE, {project, error})
export const watchDuplicateProject = (project: Project) => action(WATCH_DUPLICATE, { project });

export const watchGetProjects = () => action(WATCH_GET);
// export const watchGetProjects = () => ({
//   type: WATCH_GET,
//   payload: {}
// });

export const watchGetProject = (id: string) => action(WATCH_GET_PROJECT, { id});
// export const watchGetProject = id => ({
//   type: WATCH_GET_PROJECT,
//   payload: { id }
// });

export const watchDeleteProject = (id: string) => action(WATCH_DELETE, {id});

// export const watchDeleteProject = id => ({
//   type: WATCH_DELETE,
//   payload: { id }
// });

export const watchSaveProject = (project: Project, updates: Updates) => action(WATCH_SAVE, {project, updates})

// export const watchSaveProject = (project, updates) => ({
//   type: WATCH_SAVE,
//   payload: { project, updates }
// });

export const watchPatchProject = (id: string, updates: Updates) => action(WATCH_PATCH, {id, updates})

// export const watchPatchProject = (id, updates) => ({
//   type: WATCH_PATCH,
//   payload: { id, updates }
// });

export const watchPatchMultipleProjects = (ids: string[], updates: Updates, updatesById?: UpdatesById) => action(WATCH_PATCH_MULTIPLE, {ids, updates, updatesById})

// export const watchPatchMultipleProjects = (
//     ids,
//     updates = {},
//     updatesById = {}
// ) => ({
//   type: WATCH_PATCH_MULTIPLE,
//   payload: { ids, updates, updatesById }
// });
