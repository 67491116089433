import { takeLatest, call, put, select, all } from "redux-saga/effects";
import { WATCH_GET, WATCH_SAVE, WATCH_SAVE_COMMENT } from "./constants";
import {
  get,
  getFailure,
  getSuccess,
  save,
  saveFailure,
  saveSuccess,
  saveComment,
  saveCommentSuccess,
  saveCommentFailure
} from "./actions";
import Lead from "../../../../models/Lead";
import LeadComment from "../../../../models/LeadComment";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;
export const getUserEmail = ({
  api: {
    auth: { user }
  }
}) => user.email;

export const getLeads = function* getLeads() {
  try {
    yield put(get());
    const workspace = yield select(getActiveWorkspace);
    const leads = yield call(Lead.list, workspace);

    yield put(getSuccess(leads));
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const saveLead = function* saveLead(arg) {
  const {
    payload: { lead, updates }
  } = arg;

  try {
    yield put(save(lead, updates));
    const workspace = yield select(getActiveWorkspace);
    const user = yield select(getUserEmail);

    const isNew = !lead || !lead.id;

    let savedLead;
    if (isNew) {
      savedLead = yield call(Lead.create, workspace, user, updates);
    } else {
      savedLead = yield call(Lead.update, workspace, user, lead, updates);
    }
    yield put(saveSuccess(savedLead, isNew));
  } catch (e) {
    console.warn(e);
    yield put(saveFailure(e));
  }
};

export const saveLeadComment = function* saveLeadComment(arg) {
  const {
    payload: { comment, lead }
  } = arg;
  try {
    yield put(saveComment(comment, lead));
    const workspace = yield select(getActiveWorkspace);
    const user = yield select(getUserEmail);
    const [savedComment, savedLead] = yield call(
      LeadComment.create,
      workspace,
      user,
      lead,
      comment
    );
    yield put(saveCommentSuccess(savedComment, savedLead));
  } catch (e) {
    console.warn(e);
    yield put(saveCommentFailure(lead, e));
  }
};

export const getLeadsSaga = function* getLeadsSaga() {
  yield takeLatest(WATCH_GET, getLeads);
};

export const saveLeadSaga = function* saveLeadSaga() {
  yield takeLatest(WATCH_SAVE, saveLead);
};

export const saveCommentSaga = function* addCommentSaga() {
  yield takeLatest(WATCH_SAVE_COMMENT, saveLeadComment);
};

export default function*() {
  yield all([saveLeadSaga(), getLeadsSaga(), saveCommentSaga()]);
}
