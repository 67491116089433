import React, { useMemo } from "react";
import Page from "@superprofit/core-react-components/atoms/Page";
import Grid from "@material-ui/core/Grid";
import UserHoursMonthTable from "./users/UserTable";
import PeriodSelect from "../../../components/molecules/PeriodSelect";
import { Button } from "@superprofit/core-react-components/atoms";
import FilterIcon from "@material-ui/icons/FilterList";
import Badge from "@superprofit/core-react-components/atoms/Badge";
import FilterDialog from "./shared/FilterDialog";
import { CreatePDFDialog } from "../../../components/organisms/PDFRenderer";
import useFilters from "./shared/useFilters";
import usePeriod from "./shared/usePeriod";
import useProjects from "../../../hooks/useProjects";
import useUsers from "../../../hooks/useUsers";
import { useTranslation } from "react-i18next";
import { endOfYear, startOfYear } from "date-fns";
import useAllProjectsEntries from "../../../hooks/useAllProjectsEntries";
import useStatsFromEntries, {
  StatsType
} from "../../../hooks/useStatsFromEntries";

export default () => {
  const period = usePeriod();
  const { data: projects, isLoading: projectsLoading } = useProjects();
  const { data: users, isLoading: usersLoading } = useUsers();
  // const { data: stats = [], isLoading: statsLoading } = useUserStatsByWeek(
  //   period
  // );
  const { hasFilters, filters, toggleModal } = useFilters();
  const { t } = useTranslation();

  const currentYearDate = new Date(period.year, 1, 1);
  const startOfYearDate = startOfYear(currentYearDate);
  const endOfYearDate = endOfYear(currentYearDate);
  const {
    data: allProjectsEntries,
    isLoading: entriesLoading
  } = useAllProjectsEntries({
    fromDate: startOfYearDate,
    toDate: endOfYearDate
  });
  const byWeekAllYear = useStatsFromEntries(allProjectsEntries, {
    fromDate: startOfYearDate,
    toDate: endOfYearDate,
    type: StatsType.XStatsUserProjectHoursByWeek
  });

  const monthStats = useMemo(() => {
    return byWeekAllYear.filter(
      s => s.month === period.month && s.year === period.year
    );
  }, [byWeekAllYear, period]);

  const isLoading = projectsLoading || usersLoading || entriesLoading;

  const handleOnFilter = () => {
    toggleModal();
  };

  const handleOnChangePeriod = (pl: { year?: number; month?: number }) => {
    period.update(pl);
  };

  return (
    <Page
      context={t("common.management")}
      title={t("pages.reports.userHours.title")}
    >
      <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={6} component="div">
          <PeriodSelect
            period={period}
            showNavigation
            onChange={handleOnChangePeriod}
            showWeek={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} component="div" justify="flex-end">
          <Grid container spacing={2} justify="flex-end">
            <Grid item></Grid>
            <Grid item>
              <Badge color="error" variant="dot" invisible={!hasFilters}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleOnFilter}
                  disabled={isLoading}
                  startIcon={<FilterIcon />}
                >
                  {t("common.filter")}
                </Button>
              </Badge>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 20 }} component="div">
        <Grid item xs={12} component="div">
          <UserHoursMonthTable
            filters={filters}
            stats={monthStats}
            users={users}
            projects={projects}
            loading={isLoading}
            period={period}
            columns={{
              expand: true,
              avatar: true,
              hours: true,
              billable: true,
              weekDistribution: true,
              projectDistribution: true
            }}
          />
        </Grid>
      </Grid>

      <FilterDialog />
      <CreatePDFDialog />
    </Page>
  );
};
