import React, { useEffect, useMemo } from "react";
import { PageContent } from "../../../components/atoms/Page";
import Grid from "@material-ui/core/Grid";
import MonthTable from "./plan/UserTable";
import { useDispatch, useSelector } from "react-redux";
import PeriodSelect from "../../../components/molecules/PeriodSelect";
import { setPeriod } from "../../../redux/modules/ui/projectOverview/period/actions";
import { stageUpdates } from "../../../redux/modules/ui/projectOverview/plan/actions";
import { useDebounce } from "use-debounce";
import { watchSaveProject } from "../../../redux/modules/api/projects/actions";
import { useParams } from "react-router-dom";
import useProject from "../../../hooks/useProject";

export default () => {
  const dispatch = useDispatch();

  const {
    loading: statsLoading,
    data: {
      users,
      stats: { byWeek }
    }
  } = useSelector(state => state.ui.projectOverview.basis);

  // const { project, loading: projectLoading } = useSelector(
  //   state => state.ui.projectOverview.project
  // );
  const { id: projectId } = useParams();
  const { data: project, isLoading: projectLoading } = useProject(projectId);

  const {
    stagedPlan,
    loading: planLoading,
    saving: planSaving,
    updates: planUpdates
  } = useSelector(state => state.ui.projectOverview.plan);

  const [debouncedStagedUpdates] = useDebounce(planUpdates, 1500);

  const period = useSelector(state => state.ui.projectOverview.period);

  const filteredUsers = useMemo(() => {
    if (!project || !project.team) return [];
    return users.filter(u => project && project.team.indexOf(u.id) > -1);
  }, [project, users]);

  const monthStats = useMemo(() => {
    return byWeek.filter(
      s => s.month === period.month && s.year === period.year
    );
  }, [byWeek, period]);

  useEffect(() => {
    if (Object.keys(debouncedStagedUpdates).length > 0) {
      dispatch(watchSaveProject(project, { planned: stagedPlan }));
    }
  }, [dispatch, debouncedStagedUpdates]);

  const handleOnChangePeriod = ({ year, month }) => {
    dispatch(setPeriod(year, month));
  };

  const handleOnChangeUserPlan = (year, month, userId, value) => {
    dispatch(
      stageUpdates({
        [`${year}.${month}.${userId}`]: Number.parseFloat(value)
      })
    );
  };

  const isLoading = projectLoading || statsLoading || planLoading;

  return (
    <PageContent>
      <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
        <Grid item xs={12} component="div" justify="flex-start">
          <PeriodSelect
            period={period}
            onChange={handleOnChangePeriod}
            showWeek={false}
          />
        </Grid>
        <Grid item xs={12} component="div" justify="flex-start">
          <MonthTable
            onChange={handleOnChangeUserPlan}
            year={period.year}
            month={period.month}
            project={project}
            users={filteredUsers}
            stats={monthStats}
            loading={isLoading}
          />
        </Grid>
      </Grid>
    </PageContent>
  );
};
