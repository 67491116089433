import React, { useEffect } from "react";
import { AppContainer } from "../app/app.styles";
import { Redirect, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { routes, RouteWithSubRoutes } from "../router";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "../components/organisms/AppBar";
import Sidebar from "../components/organisms/Sidebar";
import {
  Typography,
  CircularProgress,
  Backdrop,
  Grid
} from "@superprofit/core-react-components/atoms";
import { getPathWithKeyRaw } from "../router/routes";
import { watchGetCompanySettings } from "../redux/modules/api/companySettings/actions";
import { actions as applicationAccess } from "../redux/modules/api/applicationAccess";
import { usersInWorkspace } from "../redux/modules/api/xstats/actions";
import AppLanding from "./protected/AppLanding";
import userAccessActions from "@superprofit/core-redux-saga-modules/UserAccess/actions";
import { watchGetUserSettings } from "../redux/modules/api/userSettings/actions";
import { useTranslation } from "react-i18next";
import { useAuth } from "../hooks/useAuth";
import { withSidebar } from "../components/hocs/withSidebar";
import { withAppBar } from "../components/hocs/withAppBar";
import { FilterDialog } from "@superprofit/timet-react-client/src/components/organisms/FilterDialog";

const Container = withSidebar(
  withAppBar(props => <AppContainer {...props} />)(AppBar)
)(Sidebar);

export default ({ routerHelpers: { getPublicPathWithKey } }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const user = useSelector(({ api: { auth } }) => auth.user);
  const claims = useSelector(({ api: { auth } }) => auth.idTokenResult?.claims);
  const workspace = useSelector(({ api: { auth } }) => auth.activeWorkspaceId);
  const workspaceJoining = useSelector(
    ({ api: { auth } }) => auth.workspaceJoining
  );
  const { t } = useTranslation();
  const { getIdToken } = useAuth();

  useEffect(() => {
    if (user && !workspaceJoining && workspace) {
      // Should have a saga init app function
      // dispatch(watchGetIdToken(true));
      getIdToken().catch(console.error);
      dispatch(userAccessActions.watchGet());
      dispatch(userAccessActions.watchListenAll());
      dispatch(watchGetCompanySettings());
      dispatch(watchGetUserSettings());
      dispatch(usersInWorkspace.watchGetEntry());
      dispatch(usersInWorkspace.watchListen());
      dispatch(applicationAccess.watchGetEntry());
      dispatch(applicationAccess.watchListen());
    }
  }, [user, workspaceJoining, workspace]);

  useEffect(() => {
    if (!user) {
      const next = encodeURIComponent(location.pathname + location.search);
      window.location.href = `${getPublicPathWithKey("login")}?next=${next}`;
    }
  }, [user, getPublicPathWithKey]);

  if (match.isExact) {
    return <AppLanding />;
  }

  if (
    !(claims?.workspaces && claims?.workspaces[workspace]) ||
    workspaceJoining
  ) {
    return (
      <Backdrop open>
        <Grid container justify="center" alignItems="center" direction="column">
          {workspaceJoining && (
            <Grid xs item>
              <Typography gutterBottom>
                {t("pages.protected.templates.joiningWorkspace", {
                  workspace,
                  user: user?.email
                })}
              </Typography>
            </Grid>
          )}
          <Grid xs item>
            <CircularProgress color="inherit" />
          </Grid>
        </Grid>
      </Backdrop>
    );
  }

  return (
    <Container title="Timet">
      <Switch>
        {location.pathname === `${match.url}/${workspace}` &&
          claims?.workspaces[workspace].isAdmin && (
            <Redirect
              to={`${match.url}/${workspace}${getPathWithKeyRaw("dashboard")}`}
            />
          )}
        {location.pathname === `${match.url}/${workspace}` &&
          !claims?.workspaces[workspace].isAdmin && (
            <Redirect
              to={`${match.url}/${workspace}${getPathWithKeyRaw("timesheet")}`}
            />
          )}
        {routes.map(route => (
          <RouteWithSubRoutes
            key={`route_${route.id}`}
            {...route}
            path={`${match.url}/:workspaceId${route.path}`}
          />
        ))}
      </Switch>
      <FilterDialog />
      {/*<BrowserWarning/>*/}
    </Container>
  );
};
