import React, { forwardRef, Fragment, useMemo, useState } from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import DurationFormat from "../../../../components/atoms/DurationFormat";
import NumberFormat from "react-number-format";
import IconButton from "@superprofit/core-react-components/atoms/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CustomerAvatar from "../../../../components/molecules/CustomerAvatar";
import CustomerLink from "../../../../components/molecules/CustomerLink";
import CustomerProjectItem from "./CustomerProjectItem";
import ConnectedCurrencyFormat from "../../../../components/organisms/ConnectedCurrencyFormat";

export default forwardRef(
  ({ data, weekNumbers, projectsById, ...rest }, ref) => {
    const [open, setOpen] = useState(false);

    const {
      customer,
      hours,
      projectHours,
      projectMoney,
      money,
      relativeHours,
      byWeek,
      byUserWeek
    } = data;

    // const weekDistribution = useMemo(
    //   () =>
    //     weekNumbers.map(w => ({
    //       tooltip: `${t("common.week")} ${w}`,
    //       value: byWeek[w] || 0,
    //       color: "secondary"
    //     })),
    //   [weekNumbers, byWeek]
    // );

    const projectList = useMemo(() => {
      const max = Math.max.apply(this, Object.values(projectHours));

      const res = Object.keys(projectHours).map(p => ({
        project: projectsById[p],
        percentage: ((projectHours[p] || 0) / hours) * 100,
        hours: projectHours[p] || 0,
        money: projectMoney[p] || 0
        // byWeek: byUserWeek[p] || {}
      }));
      res.sort((a, b) => {
        if ((b.hours || 0) < (a.hours || 0)) return -1;
        if ((b.hours || 0) > (a.hours || 0)) return 1;
        return 0;
      });
      return res;
    }, [projectHours, projectsById]);

    return (
      <Fragment>
        <TableRow {...rest} ref={ref} key="main">
          <TableCell style={{ maxWidth: 80, width: 80 }}>
            {projectList && projectList.length ? (
              <IconButton
                aria-label="expand row"
                size="medium"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : null}
          </TableCell>

          <TableCell style={{ maxWidth: 80, width: 80 }}>
            <CustomerAvatar customer={customer} />
          </TableCell>

          <TableCell>
            <CustomerLink name={customer.name} id={customer.id} />
          </TableCell>

          {/*<TableCell align="right">*/}
          {/*  <WeekDistribution weeks={weekDistribution} />*/}
          {/*</TableCell>*/}

          <TableCell align="right">
            <DurationFormat value={hours} component="b" />
          </TableCell>

          <TableCell align="right">
            <span style={{ width: 100, minWidth: 100 }} />
          </TableCell>

          <TableCell align="right">
            <b>
              <ConnectedCurrencyFormat number={money} />
            </b>
          </TableCell>
        </TableRow>
        {open &&
          projectList.map(data => (
            <CustomerProjectItem
              key={`${data.project.id}-${customer.id}`}
              customer={customer}
              project={data.project}
              hours={data.hours}
              money={data.money}
              percentage={data.percentage}
              money={data.money}
              weekNumbers={weekNumbers}
            />
          ))}
      </Fragment>
    );
  }
);
