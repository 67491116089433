import {
  // getHoursByProject,
  // getMoneyByProject,
  getUserMoneyByProject,
  getProjectUserEntries,
  getUserHoursByProject
} from "../../ProjectStats";
import {
  ReportSection,
  ReportSubtitle,
  ReportTable,
  ReportTableCell,
  ReportTableFooter,
  ReportTableHeader,
  ReportTableRow,
  ReportTitle,
  Report
} from "../Report";
import { colors } from "@superprofit/core-react-components/themes/light/colors";
import { setDayOfYear, setYear } from "date-fns";
import { format } from "../../../date-fns-wrappers";
import i18next from "i18next";
const translate = i18next.t;

const build = (users, projects, entries) => {
  const projectsById = projects.reduce(
    (prev, next) => ({ ...prev, [next.id]: next }),
    {}
  );

  const filteredEntries = entries;

  const userMoneyByProject = getUserMoneyByProject(entries, projectsById);
  const userHoursByProject = getUserHoursByProject(entries);
  // const moneyByProject = getMoneyByProject(entries, projectsById);
  // const hoursByProject = getHoursByProject(entries);
  const entriesByProjectUser = getProjectUserEntries(
    filteredEntries,
    projectsById
  );

  const getPrettyDate = (year, dayOfYear) => {
    const date = setYear(new Date(), year);
    const dateWithDayOfYear = setDayOfYear(date, dayOfYear);
    return format(dateWithDayOfYear, "PP");
  };

  const report = new Report();
  projects.forEach(project => {
    const reportSection = new ReportSection([
      new ReportTitle(`${translate("common.project")}: ${project.name}`)
    ]);

    let hasEntries = false;

    users.forEach(user => {
      // user rows
      const projectUserEntries =
        entriesByProjectUser[project.id] &&
        entriesByProjectUser[project.id][user.id];

      if (projectUserEntries && projectUserEntries.length > 0) {
        hasEntries = true;
        // Sort entries
        projectUserEntries.sort((a, b) => {
          if (a.year > b.year) {
            return -1;
          } else if (a.dayOfYear > b.dayOfYear) {
            return -1;
          }
          return 1;
        });
        const f = number => {
          return new Intl.NumberFormat(i18next.language, {
            minimumFractionDigits: 2
          }).format(number);
        };

        const tableRows = [];
        // Create rows for entries
        projectUserEntries.forEach(entry => {
          tableRows.push(
            new ReportTableRow(
              [
                new ReportTableCell(getPrettyDate(entry.year, entry.dayOfYear)),
                new ReportTableCell(entry.hours || entry.total, {
                  textAlign: "right"
                }),
                new ReportTableCell(
                  project.billable ? f(project.getBillableRate(user.id)) : "-",
                  {
                    textAlign: "right"
                  }
                ),
                new ReportTableCell(
                  project.billable ? f(entry.money || 0) : "-",
                  {
                    textAlign: "right"
                  }
                )
              ],
              {
                borderBottomColor:
                  entry?.comments.length <= 0 ? colors.background : undefined,
                borderBottomWidth: entry?.comments.length <= 0 ? 1 : undefined,
                borderBottomStyle:
                  entry?.comments.length <= 0 ? "solid" : undefined,
                padding: 3
              }
            )
          );

          if (entry?.comments.length > 0) {
            entry.comments.forEach((c, idx) => {
              tableRows.push(
                new ReportTableRow(
                  [
                    new ReportTableCell("Comment: " + c.comment, {
                      paddingLeft: 8,
                      fontStyle: "italic",
                      fontSize: 8,
                      opacity: 0.8
                    })
                  ],
                  {
                    borderBottomColor:
                      idx === entry?.comments.length - 1
                        ? colors.background
                        : undefined,
                    borderBottomWidth:
                      idx === entry?.comments.length - 1 ? 1 : undefined,
                    borderBottomStyle:
                      idx === entry?.comments.length - 1 ? "solid" : undefined,
                    paddingBottom:
                      idx === entry?.comments.length - 1 ? 3 : undefined
                  }
                )
              );
            });
          }
        });

        const tableHeader = new ReportTableHeader([
          new ReportTableCell(translate("common.date")),
          new ReportTableCell(translate("common.hours"), {
            textAlign: "right"
          }),
          new ReportTableCell(translate("common.rate"), { textAlign: "right" }),
          new ReportTableCell(translate("common.billable"), {
            textAlign: "right"
          })
        ]);
        const tableFooter = new ReportTableFooter([
          new ReportTableCell(translate("common.total")),
          new ReportTableCell(
            userHoursByProject[project.id]
              ? userHoursByProject[project.id][user.id]
              : "",
            { textAlign: "right" }
          ),
          new ReportTableCell(
            project.billable ? f(project.getBillableRate(user.id)) : "-",
            {
              textAlign: "right"
            }
          ),
          new ReportTableCell(
            userMoneyByProject[project.id]
              ? f(userMoneyByProject[project.id][user.id])
              : "-",
            { textAlign: "right" }
          )
        ]);

        reportSection.push([
          new ReportSubtitle(user.displayName || user.email, {}),
          new ReportTable(tableHeader, tableRows, tableFooter)
        ]);
      }
    });
    if (hasEntries) {
      report.push(reportSection);
    }
  });

  return report;
};

export const createTableExport = (users, projects, entries) => {
  return build(users, projects, entries);
};
