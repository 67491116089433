import {
    STAGE_ARCHIVING,
} from "./constants";
import { PageAction, PageState } from "./types";
import { createReducer } from "typesafe-actions";


const initialState: PageState = {
  stagedArchiving: [],
};


const reducer = createReducer<PageState, PageAction>(initialState)
  .handleType(STAGE_ARCHIVING, (state, { payload }) => ({ ...state, stagedArchiving: payload.userGroups }))

export default reducer;
