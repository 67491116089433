import { takeLatest, call, put, select, all } from "redux-saga/effects";
import { WATCH_GET } from "./constants";
import { get, getFailure, getSuccess } from "./actions";
import { db } from "../../../../firebase";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;

export const doGet = async (workspace, year, month, projects) => {
  const allTimeSnapshot = await db
    .collection("workspaces")
    .doc(workspace)
    .collection("xstats_project_hours_by_week")
    .where("project", "in", projects)
    .where("year", "==", year)
    .get();

  const allTime = [];
  allTimeSnapshot.forEach(doc => {
    allTime.push({ ...doc.data(), id: doc.id });
  });

  const monthBeforeSnapshot = await db
    .collection("workspaces")
    .doc(workspace)
    .collection("xstats_user_project_hours_by_month")
    .where("year", "==", month === 1 ? year - 1 : year)
    .where("month", "==", month === 1 ? 12 : month - 1)
    .where("project", "in", projects)
    .get();

  const monthBefore = [];
  monthBeforeSnapshot.forEach(doc => {
    monthBefore.push({ ...doc.data(), id: doc.id });
  });

  const snapshot = await db
    .collection("workspaces")
    .doc(workspace)
    .collection("xstats_user_project_hours_by_week")
    .where("year", "==", year)
    .where("month", "==", month)
    .where("project", "in", projects)
    .get();

  const byWeek = [];
  snapshot.forEach(doc => {
    byWeek.push({ ...doc.data(), id: doc.id });
  });

  return {
    monthBefore,
    byWeek,
    allTime
  };
};

export const getCustomerStats = function* getCustomerStats(args) {
  const { id, year, month, projectIds } = args.payload;
  try {
    yield put(get());
    const workspace = yield select(getActiveWorkspace);

    let allProjects = projectIds;
    const projectChunks = [];

    while (allProjects.length) {
      const nextChunk = allProjects.slice(0, 10);
      projectChunks.push(nextChunk);
      allProjects = allProjects.slice(10);
    }

    const statsAll = yield all(
      projectChunks.map(chunk => call(doGet, workspace, year, month, chunk))
    );

    const stats = statsAll.reduce(
      (prev, next) => ({
        ...prev,
        allTime: [...(prev.allTime || []), ...next.allTime],
        monthBefore: [...(prev.monthBefore || []), ...next.monthBefore],
        byWeek: [...(prev.byWeek || []), ...next.byWeek]
      }),
      {}
    );

    yield put(getSuccess(stats));
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const getCustomerStatsSaga = function* getCustomerStatsSaga() {
  yield takeLatest(WATCH_GET, getCustomerStats);
};

export default function*() {
  yield all([getCustomerStatsSaga()]);
}
