import {
  HIDE,
  SHOW,
  UPDATE,
  SET_UPDATES,
  UPDATE_TIMESHEET_ENTRIES,
  UPDATE_QUERY
} from "./constants";
import { SAVE, SAVE_SUCCESS } from "../../../api/invoices/constants";

const initialState = {
  open: false,
  updates: {},
  invoice: {},
  saving: false,
  hasUnsaved: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW:
      return {
        ...state,
        open: true,
        invoice: action.payload.invoice
      };

    case SAVE:
      return {
        ...state,
        saving: true
      };

    case SAVE_SUCCESS: // Hide dialog when saved, use sagas?
    case HIDE:
      return initialState;
    case UPDATE_TIMESHEET_ENTRIES:
    case SET_UPDATES:
      return {
        ...state,
        updates: action.payload.updates,
        hasUnsaved: action.payload.unsaved
      };

    case UPDATE_QUERY:
      return {
        ...state,
        query: action.payload?.query,
        hasUnsaved: true
      };

    default:
      return state;
  }
};
