import { QueryBuilder, Timestamp } from "@superprofit/core-firestore-models";
import { db, firebase } from "../firebase";
import { moment } from "@superprofit/time-util";
type QuerySnapshot = firebase.firestore.QuerySnapshot;
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;
type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
export interface ITimesheetComment {
  project: string;
  year: number;
  user: string;
  week: number;
  month: number;
  day: number;
  comment: string;
  id?: string;
  createdBy: string;
  createdAt: typeof Timestamp;
  updatedBy: string;
  updatedAt: string;
}
export default class TimesheetComment implements ITimesheetComment {
  project: string;
  year: number;
  user: string;
  week: number;
  day: number;
  month: number;
  comment: string;
  id?: string;
  createdBy: string;
  createdAt: typeof Timestamp;
  updatedBy: string;
  updatedAt: string;

  static collectionName = "timesheet_comments";

  static converter = {
    toFirestore(timesheetComment: TimesheetComment) {
      return timesheetComment.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as ITimesheetComment;
      return new TimesheetComment({ ...data, id: snapshot.id });
    }
  };

  static createId(workspace: string) {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(TimesheetComment.collectionName)
      .doc().id;
  }

  constructor({
    project,
    year,
    user,
    day,
    week,
    month,
    comment,
    id,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt
  }: ITimesheetComment) {
    if (id) {
      this.id = id;
    }

    this.user = user;
    this.project = project;
    this.year = year;
    this.week = week;
    this.day = day;
    this.month = month;
    this.comment = comment;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.updatedBy = updatedBy;
    this.updatedAt = updatedAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<ITimesheetComment>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      user: this.user,
      project: this.project,
      year: this.year,
      day: this.day,
      month: this.month,
      week: this.week,
      comment: this.comment,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt
    };
  }

  static list = async (workspace: string, query: any) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(TimesheetComment.collectionName),
      query
    )
      .withConverter(TimesheetComment.converter)
      .get();

    const result = snapshot.docs.map((doc: QueryDocumentSnapshot) =>
      doc.data()
    );
    result.sort((a: TimesheetComment, b: TimesheetComment) => {
      const key =
        a.createdAt.seconds === b.createdAt.seconds ? "nanoseconds" : "seconds";
      if (a.createdAt[key] < b.createdAt[key]) {
        return -1;
      }
      if (a.createdAt[key] > b.createdAt[key]) {
        return 1;
      }
      return 0;
    });
    return result;
  };

  static listenToList = async (
    workspace: string,
    query: any,
    callback: (data: TimesheetComment[]) => void
  ) => {
    return await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(TimesheetComment.collectionName),
      query
    )
      .withConverter(TimesheetComment.converter)
      .onSnapshot((snap: QuerySnapshot) => {
        const all: any[] = [];
        snap.forEach(doc => {
          all.push({ ...doc.data(), id: doc.id });
        });
        callback(all);
      });
  };

  static getAllByProjects = async (
    workspace: string,
    projectIds: string[],
    year: number,
    month: number
  ) => {
    const inOpLimit = 10; // firestore in-operator limitation

    let result = [];
    let head;
    let tail = projectIds.slice();
    while (tail && tail.length) {
      head = tail.slice(0, inOpLimit);
      tail = tail.slice(inOpLimit);
      let ref = db
        .collection("workspaces")
        .doc(workspace)
        .collection(TimesheetComment.collectionName)
        .where("project", "in", head)
        .where("year", "==", year)
        .where("month", "==", month)
        .withConverter(TimesheetComment.converter);

      const res = await ref.get();
      result.push(...res.docs.map(d => d.data()));
    }
    return result;
  };

  static getAllByProjectsAndUsers = async (
    workspace: string,
    projectIds: string[],
    userIds: string[],
    year: number,
    month: number
  ) => {
    const inOpLimit = 10; // firestore in-operator limitation
    let result = [];
    const promises = [];
    for (let uid of userIds) {
      let head;
      let tail = projectIds.slice();
      while (tail && tail.length) {
        head = tail.slice(0, inOpLimit);
        tail = tail.slice(inOpLimit);
        let ref = db
          .collection("workspaces")
          .doc(workspace)
          .collection(TimesheetComment.collectionName)
          .where("project", "in", head)
          .where("year", "==", year)
          .where("month", "==", month)
          .where("user", "==", uid)
          .withConverter(TimesheetComment.converter);

        // const res = await ref.get();
        // result.push(...res.docs.map(d => d.data()));
        promises.push(ref.get());
      }
    }
    result = await Promise.all(promises);
    result = result.flatMap(p =>
      p.docs.map((d: QueryDocumentSnapshot) => d.data() as TimesheetComment)
    );
    return result;
  };

  static getAllByUsers = async (
    workspace: string,
    userIds: string[],
    year: number,
    month: number
  ) => {
    const inOpLimit = 10; // firestore in-operator limitation

    let result = [];
    let head;
    let tail = userIds.slice();
    while (tail && tail.length) {
      head = tail.slice(0, inOpLimit);
      tail = tail.slice(inOpLimit);
      let ref = db
        .collection("workspaces")
        .doc(workspace)
        .collection(TimesheetComment.collectionName)
        .where("user", "in", head)
        .where("year", "==", year)
        .where("month", "==", month)
        .withConverter(TimesheetComment.converter);

      const res = await ref.get();
      result.push(...res.docs.map(d => d.data()));
    }
    return result;
  };

  static create = async (
    workspace: string,
    user: string,
    data: Omit<
      ITimesheetComment,
      | "week"
      | "month"
      | "id"
      | "updatedAt"
      | "updatedBy"
      | "createdAt"
      | "createdBy"
    >
  ) => {
    const date = moment()
      .year(data.year)
      .dayOfYear(data.day);

    const comment = new TimesheetComment({
      ...data,
      month: date.month() + 1, // we want 1-12 not 0 - 11
      week: date.week(),
      id: TimesheetComment.createId(workspace),
      updatedAt: Timestamp.now(),
      createdAt: Timestamp.now(),
      updatedBy: user,
      createdBy: user
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(TimesheetComment.collectionName)
      .doc(comment.id)
      .withConverter(TimesheetComment.converter)
      .set(comment, { merge: true });

    return comment;
  };

  static delete = async (workspace: string, comment: ITimesheetComment) => {
    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(TimesheetComment.collectionName)
      .doc(comment.id)
      .delete();

    return comment;
  };

  /*  static update = async (workspace: string, user: string, comment: typeof TimesheetComment, updates: Partial<ITimesheetComment>) => {
    const updatedComment = comment.clone();

    updatedComment.setData({
      ...updates,
      updatedAt: Timestamp.now(),
      updatedBy: user
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(TimesheetComment.collectionName)
      .doc(updatedComment.id)
      .withConverter(TimesheetComment.converter)
      .set(updatedComment, { merge: true });

    return updatedComment;
  };*/
}
