export const GET = "api/userGroups/GET";
export const GET_SUCCESS = "api/userGroups/GET_SUCCESS";
export const GET_FAILURE = "api/userGroups/GET_FAILED";

export const SAVE = "api/userGroups/SAVE";
export const SAVE_SUCCESS = "api/userGroups/SAVE_SUCCESS";
export const SAVE_FAILURE = "api/userGroups/SAVE_FAILED";

export const BATCH = "api/userGroups/BATCH";
export const BATCH_SUCCESS = "api/userGroups/BATCH_SUCCESS";
export const BATCH_FAILURE = "api/userGroups/BATCH_FAILED";

export const REMOVE = "api/userGroups/REMOVE";
export const REMOVE_SUCCESS = "api/userGroups/REMOVE_SUCCESS";
export const REMOVE_FAILURE = "api/userGroups/REMOVE_FAILED";

export const WATCH_GET = "api/userGroups/WATCH_GET";
export const WATCH_SAVE = "api/userGroups/WATCH_SAVE";
export const WATCH_REMOVE = "api/userGroups/WATCH_REMOVE";
export const WATCH_BATCH = "api/userGroups/WATCH_BATCH";
