import TextField from "./TextField";
import NumberFormatCustom from "./NumberFormatCustom";
import React, { forwardRef } from "react";

export default forwardRef((props, ref) => {
  return (
    <TextField
      ref={ref}
      {...props}
      InputProps={{
        ...(props.InputProps || {}),
        inputComponent: NumberFormatCustom
      }}
    />
  );
});
