import React, { useMemo, useState } from "react";
import { TableCell, TableRow } from "../../../components/atoms/Table";
import IconButton from "../../../components/atoms/IconButton";
import Button from "../../../components/atoms/Button";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ProjectAvatar from "../../../components/molecules/ProjectAvatar";
import Project from "@superprofit/timet-react-client/src/models/Project";
import DurationFormat from "../../../components/atoms/DurationFormat";
import { useTranslation } from "react-i18next";
import Entry from "@superprofit/timet-react-client/src/models/Entry";
import { ApprovalRecord } from "@superprofit/timet-react-client/src/models/ApprovalRecords";
import { Status } from "@superprofit/timet-react-client/src/pages/protected/userApproval/Status";
import { UserRow } from "./projectItem/UserRow";
import useUsersMap from "@superprofit/timet-react-client/src/hooks/useUsersMap";
import useConvertToMap from "@superprofit/timet-react-client/src/hooks/useConvertToMap";
import useCustomersMap from "@superprofit/timet-react-client/src/hooks/useCustomersMap";
import useUniqueUsersInProjects from "@superprofit/timet-react-client/src/hooks/useUniqueUsersInProjects";
import ProjectLink from "@superprofit/timet-react-client/src/components/molecules/ProjectLink";
import CustomerLink from "@superprofit/timet-react-client/src/components/molecules/CustomerLink";
type ProjectItemProps = {
  project: Project;
  entries?: Entry[];
  totalHours: number;
  approvalRecords?: ApprovalRecord[];
  startDate: Date;
  endDate: Date;
  onCreate: (project: Project, user: string) => void;
  onReject: (approvalRecord: ApprovalRecord) => void;
  onApprove: (approvalRecord: ApprovalRecord) => void;
  usersSubmitting: string[];
};

export const ProjectItem = ({
  project,
  entries = [],
  approvalRecords = [],
  startDate,
  endDate,
  onReject,
  usersSubmitting = [],
  totalHours,
  onApprove,
  onCreate
}: ProjectItemProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const allUsersMap = useUsersMap();
  const customersMap = useCustomersMap();
  const usersInProject = useUniqueUsersInProjects([project]);
  const usersMap = useMemo(() => {
    return new Map(
      Array.from(allUsersMap).filter(([id]) => usersInProject.has(id))
    );
  }, [allUsersMap, usersInProject]);

  // const daysDates = eachDayOfInterval({
  //   start: startDate,
  //   end: endDate
  // });
  // const hours = useMemo(() => {
  //   return entries?.reduce((acc, entry) => acc + entry.hours, 0);
  // }, [entries]);
  //
  // const getEntryByDate = (date: Date) => {
  //   return entries.find(entry =>
  //     isEqual(new Date(entry.year, 0, entry.dayOfYear), date)
  //   );
  // };

  const entriesByUserMap = useMemo(() => {
    const map = new Map<string, Entry[]>();
    entries.forEach(entry => {
      if (!map.has(entry.user)) {
        map.set(entry.user, []);
      }
      map.get(entry.user)?.push(entry);
    });
    return map;
  }, [entries]);

  const usersWithoutApprovalRecord = useMemo(() => {
    return Array.from(usersMap.values()).filter(user => {
      return !approvalRecords.some(
        approvalRecord => approvalRecord.user === user.id
      );
    });
  }, [usersMap, approvalRecords]);

  const approvalRecordsByUserMap = useConvertToMap<ApprovalRecord>(
    "user",
    approvalRecords
  );

  const leastStatusApprovalRecord = useMemo(() => {
    const unsubmitted = usersWithoutApprovalRecord.length > 0;
    if (unsubmitted) {
      return undefined;
    }

    const submitted = approvalRecords.find(
      approvalRecord => approvalRecord.status === "submitted"
    );

    if (submitted) {
      return submitted;
    }

    return approvalRecords.find(
      approvalRecord => approvalRecord.status === "approved"
    );
  }, [approvalRecords, usersWithoutApprovalRecord]);

  const handleOnApprove = (approvalRecord: ApprovalRecord) => {
    onApprove(approvalRecord);
  };

  const handleOnReject = (approvalRecord: ApprovalRecord) => {
    onReject(approvalRecord);
  };

  const handleOnCreate = (user: string) => {
    onCreate(project, user);
  };

  const createAll = () => {
    usersWithoutApprovalRecord.forEach(user => {
      onCreate(project, user.id);
    });
  };

  const anyApproved = useMemo(
    () =>
      approvalRecords.some(
        approvalRecord => approvalRecord.status === "approved"
      ),
    [approvalRecords]
  );
  const anySubmitted = useMemo(
    () =>
      approvalRecords.some(
        approvalRecord => approvalRecord.status === "submitted"
      ),
    [approvalRecords]
  );
  const anyRejected = useMemo(
    () =>
      approvalRecords.some(
        approvalRecord => approvalRecord.status === "rejected"
      ),
    [approvalRecords]
  );

  const rejectAll = () => {
    approvalRecords.forEach(approvalRecord => {
      onReject(approvalRecord);
    });
  };

  const approveAll = () => {
    approvalRecords.forEach(approvalRecord => {
      onApprove(approvalRecord);
    });
  };

  const customer = customersMap.get(project.customer);

  return (
    <>
      <TableRow>
        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <IconButton
            aria-label={t("common.expandRow")}
            size="medium"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <ProjectAvatar project={project} />
        </TableCell>
        <TableCell>
          <ProjectLink name={project.name} id={project.id || ""} />
        </TableCell>
        <TableCell>
          {customer ? (
            <CustomerLink id={customer.id} name={customer.name} />
          ) : (
            "-"
          )}
        </TableCell>

        <TableCell>
          <Status
            status={
              anySubmitted && leastStatusApprovalRecord === undefined
                ? "partially-submitted"
                : leastStatusApprovalRecord?.status
            }
          />
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>

        <TableCell align="right">
          <DurationFormat value={totalHours} component="b" />
        </TableCell>

        {/*<TableCell align="right">*/}
        {/*  <Button*/}
        {/*    style={{ visbility: "hidden" }}*/}
        {/*    disabled*/}
        {/*    onClick={() => {}}*/}
        {/*  ></Button>*/}
        {/*</TableCell>*/}
        <TableCell align="right">
          {/*{!leastStatusApprovalRecord && (*/}
          {/*  <Button*/}
          {/*    variant="contained"*/}
          {/*    color="primary"*/}
          {/*    onClick={createAll}*/}
          {/*    disabled={usersSubmitting.length}*/}
          {/*  >*/}
          {/*    {t("common.create")}*/}
          {/*  </Button>*/}
          {/*)}*/}
          {leastStatusApprovalRecord?.status === "submitted" && (
            <Button
              variant="contained"
              color="primary"
              onClick={approveAll}
              disabled={usersSubmitting.length}
            >
              {t("common.approveAll")}
            </Button>
          )}
        </TableCell>
        <TableCell>
          {leastStatusApprovalRecord?.status === "submitted" && (
            <Button
              variant="contained"
              color="alert"
              onClick={rejectAll}
              disabled={usersSubmitting.length}
            >
              {t("common.rejectAll")}
            </Button>
          )}
        </TableCell>
      </TableRow>
      {open &&
        Array.from(usersMap.values())?.map(user => (
          <UserRow
            isLoading={usersSubmitting.includes(user.id)}
            onReject={handleOnReject}
            onCreate={handleOnCreate}
            onApprove={handleOnApprove}
            project={project}
            key={user.id}
            approvalRecord={approvalRecordsByUserMap.get(user.id)}
            entries={entriesByUserMap.get(user.id)}
            user={user}
          ></UserRow>
        ))}
    </>
  );
};
