import { GET, GET_FAILURE, GET_SUCCESS, WATCH_GET } from "./constants";

export const get = () => ({
  type: GET,
  payload: {}
});

export const getSuccess = (stats, customers, projects, users) => ({
  type: GET_SUCCESS,
  payload: { stats, customers, projects, users }
});

export const getFailure = error => ({
  type: GET_FAILURE,
  payload: { error }
});

export const watchGetHoursByUsersReport = (year, month) => ({
  type: WATCH_GET,
  payload: { year, month }
});
