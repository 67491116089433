import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import {
  ApprovalRecord,
  GetApprovalRecordsOptions
} from "../models/ApprovalRecords";

export const getKey = (
  workspace: string,
  options: Omit<GetApprovalRecordsOptions, "workspaceId">
) => [workspace, ApprovalRecord.COLLECTION, options];

export const useApprovalRecords = (
  options: Omit<GetApprovalRecordsOptions, "workspaceId">
) => {
  const workspace = useWorkspace();
  const get = () =>
    ApprovalRecord.getApprovalRecords({ workspaceId: workspace, ...options });
  return useQuery(getKey(workspace, options), get);
};
