import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;

  > * {
    &:last-child {
      flex: 1;
      max-width: 100%;
      overflow: hidden;
    }
  }
`;
