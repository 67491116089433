import React, { useMemo, useState } from "react";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@superprofit/core-react-components/atoms/Table";
import Card from "@superprofit/core-react-components/atoms/Card";
import CustomerItem from "./CustomerItem";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import {
  getProjectHoursByCustomer,
  getMoneyByCustomer,
  getHoursByCustomer,
  getRelativeHoursPercentageByCustomer,
  getProjectMoneyByCustomer
} from "../../../../utils/CustomerStats";
import { weeks } from "@superprofit/time-util";
import TableLoader from "../../../../components/molecules/TableLoader";
import Entry from "../../../../models/Entry";
import { filterStats } from "../../../../utils/StatsFilter";
import { createTableExport } from "../../../../utils/report/data/CustomerTableExport";
import { saveAsPDF, saveAsXLSX } from "../../../../utils/report";
import TimetUser from "../../../../models/TimetUser";
import ActionMenu from "../shared/ActionMenu";
import { useSelector } from "react-redux";
import TimesheetComment from "../../../../models/TimesheetComment";
import { useTranslation } from "react-i18next";

const getProp = (data, orderBy) => {
  switch (orderBy) {
    case "name":
      return data.project.name;
    default:
      return data[orderBy] || 0;
  }
};

const descendingComparator = (a, b, orderBy) => {
  if (getProp(b, orderBy) < getProp(a, orderBy)) return -1;
  if (getProp(b, orderBy) > getProp(a, orderBy)) return 1;
  return 0;
};

const getComparator = (orderDir, orderBy) => {
  return orderDir === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export default ({
  stats,
  projects,
  customers,
  period,
  loading,
  filters,
  onAdd,
  ...rest
}) => {
  const workspace = useSelector(state => state.api.auth.activeWorkspaceId);
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState("hours");
  const [orderDir, setOrderDir] = useState("desc");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { year, month } = period;

  const weekNumbers = useMemo(() => {
    return weeks(year, month);
  }, [year, month]);

  const projectsById = useMemo(() => {
    return projects.reduce(
      (prev, next) => ({
        ...prev,
        [next.id]: next
      }),
      {}
    );
  }, [projects]);

  const filteredStats = useMemo(() => {
    return filterStats(
      stats,
      projects,
      filters.type,
      filters.projects,
      filters.users,
      filters.customers
    );
  }, [filters, stats]);

  const filteredCustomers = useMemo(() => {
    if (filters.customers.length === 0) return customers;
    const shownCustomersById = filters.customers.reduce(
      (prev, next) => ({
        ...prev,
        [next]: true
      }),
      {}
    );

    return customers.filter(c => !!shownCustomersById[c.id]);
  }, [filters, customers]);

  const customersList = useMemo(() => {
    const projectHoursByCustomer = getProjectHoursByCustomer(
      stats,
      projectsById
    );
    const moneyByCustomer = getMoneyByCustomer(stats, projectsById);
    const hoursByCustomer = getHoursByCustomer(stats, projectsById);
    const relativeHours = getRelativeHoursPercentageByCustomer(
      stats,
      projectsById
    );
    const projectMoneyByCustomer = getProjectMoneyByCustomer(
      stats,
      projectsById
    );

    return filteredCustomers.map(customer => ({
      customer,
      hours: hoursByCustomer[customer.id] || 0,
      money: moneyByCustomer[customer.id] || 0,
      relativeHours: relativeHours[customer.id] || 0,
      projectHours: projectHoursByCustomer[customer.id] || {},
      projectMoney: projectMoneyByCustomer[customer.id] || {}
    }));
  }, [stats, filteredCustomers, projectsById]);

  const sortedCustomersList = useMemo(() => {
    const sorted = customersList.slice();
    sorted.sort(getComparator(orderDir, orderBy));
    return sorted;
  }, [customersList, orderBy, orderDir]);

  const pageList = useMemo(() => {
    return rowsPerPage > 0
      ? sortedCustomersList.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : sortedCustomersList;
  }, [sortedCustomersList, page, rowsPerPage]);

  const count = customersList.length;

  const handleOnSort = (event, property) => {
    const isAsc = orderBy === property && orderDir === "asc";
    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExport = async type => {
    const customersById = filteredCustomers.reduce(
      (prev, next) => ({
        ...prev,
        [next.id]: next
      }),
      {}
    );

    const filteredProjects = projects.filter(p => !!customersById[p.customer]);
    const projectsIds = filteredProjects.map(p => p.id);

    const rawEntries = await Entry.getAllByProjects(
      workspace,
      projectsIds,
      year,
      month
    );
    rawEntries.forEach(e => (e.total = e.hours || 0));

    const users = await TimetUser.list(null, workspace);
    const rawComments = await TimesheetComment.getAllByProjects(
      workspace,
      projectsIds,
      year,
      month
    );
    let mergedEntries = rawEntries.map(e => {
      const com = rawComments.filter(
        c =>
          c.year === e.year &&
          c.day === e.dayOfYear &&
          c.user === e.user &&
          c.project === e.project
      );
      return { ...e, comments: com };
    });
    const filteredEntries = filters
      ? filterStats(
          mergedEntries,
          projects,
          filters.type,
          filters.projects,
          filters.users,
          filters.customers
        )
      : mergedEntries;

    const report = createTableExport(
      filteredCustomers,
      projects,
      users,
      filteredEntries
    );

    if (type === "PDF") {
      await saveAsPDF(report, `timet-report-by-customer-${month}-${year}`);
    } else if (type === "XLSX") {
      await saveAsXLSX(report, `timet-report-by-customer-${month}-${year}`);
    } else if (type === "PDFPY") {
      await saveAsPDF(
        createTableExport(
          filteredCustomers,
          projects,
          users,
          filteredEntries,
          true
        ),
        `timet-report-by-customer-${month}-${year}`
      );
    } else if (type === "XLSXPY") {
      await saveAsXLSX(
        createTableExport(
          filteredCustomers,
          projects,
          users,
          filteredEntries,
          true
        ),
        `timet-report-by-customer-${month}-${year}`
      );
    }
  };

  return (
    <Card {...rest}>
      <CardHeader
        title=""
        action={
          <ActionMenu
            onAdd={onAdd}
            onExportXLSX={() => handleExport("XLSX")}
            onExportPDF={() => handleExport("PDF")}
            onExportPDFProjectsOnly={() => handleExport("PDFPY")}
            onExportXLSXProjectsOnly={() => handleExport("XLSXPY")}
          />
        }
      />

      <Divider />

      <TableContainer>
        <Table aria-label={t("common.hoursByCustomer")}>
          <TableHead>
            <TableRow>
              <TableCell style={{ maxWidth: 80, width: 80 }} />
              <TableCell style={{ maxWidth: 80, width: 80 }} />
              <TableCell sortDirection={orderBy === "name" ? orderDir : false}>
                <TableSortLabel
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? orderDir : "asc"}
                  onClick={e => handleOnSort(e, "name")}
                >
                  {t("common.name")}
                </TableSortLabel>
              </TableCell>

              <TableCell
                align="right"
                sortDirection={orderBy === "hours" ? orderDir : false}
              >
                <TableSortLabel
                  active={orderBy === "hours"}
                  direction={orderBy === "hours" ? orderDir : "asc"}
                  onClick={e => handleOnSort(e, "hours")}
                >
                  {t("common.logged")}
                </TableSortLabel>
              </TableCell>

              <TableCell align="right" style={{ minWidth: 100 }} />
              <TableCell align="right">{t("common.billable")}</TableCell>
            </TableRow>
          </TableHead>

          {!loading && (
            <TableBody>
              {pageList.map(data => (
                <CustomerItem
                  key={data.customer.id}
                  data={data}
                  weekNumbers={weekNumbers}
                  projectsById={projectsById}
                />
              ))}
            </TableBody>
          )}

          {!loading && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelRowsPerPage={t("common.rowsPerPage")}
                  rowsPerPageOptions={[
                    10,
                    25,
                    50,
                    { label: t("common.all"), value: -1 }
                  ]}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": t("common.rowsPerPage") },
                    native: true
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}

          {loading && (
            <TableLoader
              cols={[
                "rect",
                "square",
                "text",
                "textRight",
                "textRight",
                "textRight"
              ]}
            />
          )}
        </Table>
      </TableContainer>
    </Card>
  );
};
