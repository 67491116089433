export const GET = "@superprofit/core-redux-saga-modules/Users/GET";
export const GET_SUCCESS = "@superprofit/core-redux-saga-modules/Users/GET_SUCCESS";
export const GET_FAILURE = "@superprofit/core-redux-saga-modules/Users/GET_FAILED";

export const SAVE = "@superprofit/core-redux-saga-modules/Users/SAVE";
export const SAVE_SUCCESS = "@superprofit/core-redux-saga-modules/Users/SAVE_SUCCESS";
export const SAVE_FAILURE = "@superprofit/core-redux-saga-modules/Users/SAVE_FAILED";

export const DELETE = "@superprofit/core-redux-saga-modules/Users/DELETE";
export const DELETE_SUCCESS = "@superprofit/core-redux-saga-modules/Users/DELETE_SUCCESS";
export const DELETE_FAILURE = "@superprofit/core-redux-saga-modules/Users/DELETE_FAILED";

export const WATCH_GET = "@superprofit/core-redux-saga-modules/Users/WATCH_GET";
export const WATCH_SAVE = "@superprofit/core-redux-saga-modules/Users/WATCH_SAVE";
export const WATCH_DELETE = "@superprofit/core-redux-saga-modules/Users/WATCH_DELETE";
