import React, { forwardRef, Fragment, useState } from "react";
import Table, {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import Checkbox from "@superprofit/core-react-components/atoms/Checkbox";
import UserRow from "./UserRow";
import ActionMenu from "../../../../components/molecules/ActionMenu";
import TableLoader from "../../../../components/molecules/TableLoader";
import Tooltip from "@superprofit/core-react-components/atoms/Tooltip";
import { useTranslation } from "react-i18next";

const descendingComparator = (a, b, orderBy) => {
  if (orderBy === "status") {
    if (!a.userAccess?.isActive && !b.userAccess?.isActive) {
      return -b.displayName?.localeCompare(a.displayName);
    }
    if (!b.userAccess?.isActive && a.userAccess?.isActive) return 1;
    if (b.userAccess?.isActive && !a.userAccess?.isActive) return -1;
    if (b.emailVerified && !a.emailVerified) return -1;
    if (!b.emailVerified && a.emailVerified) return 1;
    return 0;
  } else {
    if ((b[orderBy] || "").toLowerCase() < (a[orderBy] || "").toLowerCase())
      return -1;
    if ((b[orderBy] || "").toLowerCase() > (a[orderBy] || "").toLowerCase())
      return 1;
    return 0;
  }
};

const getComparator = (orderDir, orderBy) => {
  return orderDir === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export default forwardRef(
  (
    {
      onAssignRoles,
      onAssignProjects,
      onSelectionChange,
      onOverrideUserTimesheet,
      onEdit,
      onArchive,
      onDelete,
      onView,
      selection = [],
      users,
      settings,
      loading
    },
    ref
  ) => {
    const [actionMenu, setActionMenu] = useState({
      user: null,
      anchor: null
    });
    const { t } = useTranslation();

    const [orderBy, setOrderBy] = useState("name");
    const [orderDir, setOrderDir] = useState("asc");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const selectedIds = React.useMemo(
      () =>
        (selection || []).reduce(
          (prev, next) => ({
            ...prev,
            [next.id]: true
          }),
          {}
        ),
      [selection]
    );

    const usersList = React.useMemo(() => {
      let usersListSorted = users.slice();

      usersListSorted.sort(getComparator(orderDir, orderBy));
      usersListSorted =
        rowsPerPage > 0
          ? usersListSorted.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : usersListSorted;

      return usersListSorted;
    }, [orderBy, orderDir, page, rowsPerPage, users]);

    const handleOnSort = (event, property) => {
      const isAsc = orderBy === property && orderDir === "asc";
      setOrderDir(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleOnMore = (e, user) => {
      setActionMenu({ anchor: e.currentTarget, user });
    };

    const handleOnCloseMore = e => {
      setActionMenu({});
    };

    const handleOnSelectAll = () => {
      if (selection && selection.length === users.length) {
        onSelectionChange([]);
      } else {
        onSelectionChange(users);
      }
    };

    const handleOnSelect = user => {
      if (selectedIds[user.id]) {
        onSelectionChange(selection.filter(u => u.id !== user.id));
      } else {
        onSelectionChange(selection.filter(u => u.id !== user.id).concat(user));
      }
    };

    return (
      <Fragment>
        <ActionMenu
          onEdit={onEdit}
          onArchive={onArchive}
          onDelete={onDelete}
          onView={onView}
          onAssignRoles={onAssignRoles}
          onOverrideUserTimesheet={onOverrideUserTimesheet}
          onAssignProjects={onAssignProjects}
          data={actionMenu.user}
          open={!!actionMenu.anchor}
          anchorEl={actionMenu.anchor}
          onClose={handleOnCloseMore}
        />

        <TableContainer ref={ref}>
          <Table aria-label={t("common.users")}>
            <TableHead>
              <TableRow>
                <TableCell style={{ maxWidth: 80, width: 80 }}>
                  <Checkbox
                    defaultChecked={false}
                    checked={
                      (selection.length && selection.length === users.length) ||
                      false
                    }
                    indeterminate={
                      (selection.length && selection.length < users.length) ||
                      false
                    }
                    inputProps={{ "aria-label": t("common.selectAllUsers") }}
                    onChange={handleOnSelectAll}
                  />
                </TableCell>

                <TableCell style={{ maxWidth: 80, width: 80 }} />

                <TableCell
                  sortDirection={orderBy === "displayName" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "displayName"}
                    direction={orderBy === "displayName" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "displayName")}
                  >
                    {t("common.name")}
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sortDirection={orderBy === "status" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "status"}
                    direction={orderBy === "status" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "status")}
                  >
                    {t("common.status")}
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  align="right"
                  sortDirection={orderBy === "capacity" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "capacity"}
                    direction={orderBy === "capacity" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "capacity")}
                  >
                    <Tooltip title={t("pages.users.capacityPerNormalWeek")}>
                      <span>{t("common.capacity")}</span>
                    </Tooltip>
                  </TableSortLabel>
                </TableCell>

                <TableCell>{t("common.tags")}</TableCell>

                <TableCell style={{ maxWidth: 80, width: 80 }}></TableCell>
              </TableRow>
            </TableHead>

            {!loading && (
              <Fragment>
                <TableBody>
                  {usersList.map(u => (
                    <UserRow
                      key={u.id}
                      user={u}
                      capacity={
                        u.capacity !== null && u.capacity !== undefined
                          ? u.capacity
                          : settings?.defaultCapacity?.value
                      }
                      selected={!!selectedIds[u.id]}
                      onMore={handleOnMore}
                      onSelect={() => handleOnSelect(u)}
                    />
                  ))}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TablePagination
                      labelRowsPerPage={t("common.rowsPerPage")}
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        { label: t("common.all"), value: -1 }
                      ]}
                      count={users.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": t("common.rowsPerPage") },
                        native: true
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Fragment>
            )}

            {loading && (
              <TableLoader
                cols={[
                  "rect",
                  "circle",
                  "text",
                  "text",
                  "text",
                  "text",
                  "rect"
                ]}
              />
            )}
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
);
