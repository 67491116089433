import styled from "@react-pdf/styled-components";
import { colors } from "@superprofit/core-react-components/themes/light/colors";

export const Container = styled.View`
  flex-direction: column;
  font-size: 10px;
  flex-grow: 1;
`;

export const Row = styled.View`
  flex-direction: row;
  padding: 6px;
  border: 0 solid ${colors.background};
  border-top-width: 1px;
`;

export const HeaderRow = styled(Row)`
  background-color: ${colors.primary};
  border-top-width: 0;
  color: white;
`;

export const Date = styled.Text`
  flex-grow: 2;
  flex-basis: 120px;
`;

export const User = styled.Text`
  flex-grow: 2;
  flex-basis: 120px;
`;

const Number = styled.Text`
  flex-basis: 60px;
  text-align: right;
`;
export const Amount = styled.Text`
  flex-basis: 30px;
  text-align: right;
`;
export const Cost = Number;
export const Price = Number;

export const TotalRow = styled(Row)`
  border: 1px solid black;
  border-left-width: 0;
  border-right-width: 0;
  width: 100%;
  font-weight: bold;
  //justify-content: flex-end;
`;
