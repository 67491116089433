import { SET_LOADING, SET_STATS_WEEKLY_HOURS } from "./constants";

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const setWeeklyHours = (billable, nonBillable, weekNumbers) => ({
  type: SET_STATS_WEEKLY_HOURS,
  payload: { billable, nonBillable, weekNumbers }
});
