import React, { Component } from "react";
import ReactDOM from "react-dom";
import Dialog from "@superprofit/core-react-components/atoms/Dialog";
import DialogTitle from "@superprofit/core-react-components/atoms/DialogTitle";
import DialogContent from "@superprofit/core-react-components/atoms/DialogContent";
import DialogContentText from "@superprofit/core-react-components/atoms/DialogContentText";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import DialogActions from "@superprofit/core-react-components/atoms/DialogActions";
import Button from "@superprofit/core-react-components/atoms/Button";
import Provider from "../../../../ThemeProvider";
import { MODES } from "../../../../redux/modules/ui/theme/constants";
import * as TwentyFourSeven from "@superprofit/24-seven-integration-client";
import CircularProgress from "@superprofit/core-react-components/atoms/CircularProgress";

let resolve;

class AsyncAuthDialog extends Component {
  static create() {
    const containerElement = document.createElement("div");
    document.body.appendChild(containerElement);
    return ReactDOM.render(<AsyncAuthDialog />, containerElement);
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      error: false
    };
    this.userRef = React.createRef();
    this.pwRef = React.createRef();

    this.handleAuthenticate = this.handleAuthenticate.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.show = this.show.bind(this);
  }

  handleCancel() {
    if (this.state.loading) return;
    this.setState({ open: false });
    resolve(false);
  }

  handleOnChange() {
    if (!this.state.error) return;
    this.setState({ error: false });
  }

  async handleAuthenticate() {
    this.setState({ loading: true });
    const username = this.userRef.current.value;
    const pw = this.pwRef.current.value;

    const result = await TwentyFourSeven.authenticate(username, pw);

    if (result) {
      this.setState({ open: false, loading: false });
      resolve(true);
    } else {
      this.setState({ error: true, loading: false });
    }
  }

  show() {
    this.setState({ open: true });
    return new Promise(res => {
      resolve = res;
    });
  }

  render() {
    return (
      <Provider mode={MODES.LIGHT}>
        <Dialog
          open={this.state.open}
          onClose={this.handleCancel}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Sign in</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Sign in to 24sevenoffice to continue export.
            </DialogContentText>

            <br />
            <br />

            <TextField
              error={this.state.error}
              disabled={this.state.loading}
              inputRef={this.userRef}
              autoFocus
              id="name"
              label="Username"
              type="username"
              variant="filled"
              fullWidth
              onChange={this.handleOnChange}
            />

            <br />
            <br />

            <TextField
              error={this.state.error}
              disabled={this.state.loading}
              inputRef={this.pwRef}
              autoFocus
              id="pw"
              variant="filled"
              label="Password"
              type="password"
              fullWidth
              onChange={this.handleOnChange}
            />

            <br />
            <br />
          </DialogContent>
          <DialogActions>
            {this.state.loading && (
              <CircularProgress color="primary" size={24} />
            )}
            <Button
              disabled={this.state.loading}
              onClick={this.handleCancel}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.loading}
              onClick={this.handleAuthenticate}
              variant="contained"
              color="primary"
            >
              Sign in
            </Button>
          </DialogActions>
        </Dialog>
      </Provider>
    );
  }
}

export default AsyncAuthDialog.create();
