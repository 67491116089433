import { SET_LOADING, SET_STATS_NON_BILLABLE_MONTH } from "./constants";

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const setNonBillableMonth = (hours, change, goal) => ({
  type: SET_STATS_NON_BILLABLE_MONTH,
  payload: { hours, change, goal }
});
