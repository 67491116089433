import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import TimesheetComment, {
  ITimesheetComment
} from "../models/TimesheetComment";
import useUser from "./useUser";

type Params = {
  id: string;
  year?: number;
  week: number;
  projectId: string;
};
const useTimesheetCommentMutation = (params: Params) => {
  const { id, year, week, projectId } = params;
  const workspace = useWorkspace();
  const { email } = useUser();
  const queryClient = useQueryClient();

  const key = [
    workspace,
    id,
    TimesheetComment.collectionName,
    year,
    week,
    projectId
  ];

  const mutate = async ({
    comment,
    remove = false
  }: {
    comment:
      | TimesheetComment
      | Pick<
          ITimesheetComment,
          "user" | "project" | "year" | "day" | "comment"
        >;
    remove?: boolean;
  }) => {
    if (comment instanceof TimesheetComment) {
      if (remove) {
        await TimesheetComment.delete(workspace, comment);
        queryClient.setQueryData(key, old =>
          (old as TimesheetComment[])?.filter(c => c.id !== comment.id)
        );
      }
      throw Error("Not implemented on comment");
    } else {
      const createdComment = await TimesheetComment.create(
        workspace,
        email,
        comment
      );
      if (createdComment) {
        queryClient.setQueryData(key, old =>
          (old as TimesheetComment[])?.concat([createdComment])
        );
      }
    }
  };

  return useMutation(mutate);
};

export default useTimesheetCommentMutation;
