import React from "react";
import { Route } from "react-router-dom";
import { getPathWithKey as getProtectedPathWithKey } from "./routes";
import { getPathWithKey as getPublicPathWithKey } from "./publicRoutes";

const routerHelpers = {
  getProtectedPathWithKey,
  getPublicPathWithKey
};
export default route => (
  <Route
    path={route.path}
    render={props => (
      // pass the sub-routes down to keep nesting
      <route.component
        {...props}
        routerHelpers={routerHelpers}
        routes={route.routes}
      />
    )}
  />
);
