import React, {
  forwardRef,
  ForwardRefExoticComponent,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";

import ConfirmationDialog from "../../../../components/molecules/ConfirmationDialog";
import useCustomers from "../../../../hooks/useCustomers";
import useUsers from "../../../../hooks/useUsers";
import useUserGroups from "../../../../hooks/useUserGroups";
import useUsersMap from "../../../../hooks/useUsersMap";
import useUserGroupsMap from "../../../../hooks/useUserGroupsMap";
import useDialog from "../hooks/useDialog";
import { useTranslation } from "react-i18next";
import useProjectSalaryRateSettings from "../../../../hooks/useProjectSalarySettings";
import Grid from "../../../../components/atoms/Grid";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import FormDialog from "../../../../components/molecules/FormDialog";
import TextField from "../../../../components/atoms/TextField";
import { withFormController as withFormControllerTagInput } from "../../../../components/atoms/TagInput";
import { withFormController as withFormControllerUsers } from "../../../../components/molecules/AutocompleteUsers";
import { withFormController as withFormControllerUserGroups } from "../../../../components/molecules/AutocompleteUserGroups";
import FormControl from "../../../../components/atoms/FormControl";
import FormControlLabel from "../../../../components/atoms/FormControlLabel";
import FormLabel from "../../../../components/atoms/FormLabel";
import useProject from "../../../../hooks/useProject";
import { withFormController as withFormControllerCheckbox } from "../../../../components/atoms/Checkbox";
import Radio from "../../../../components/atoms/Radio";
import { withFormController as withFormControllerRadioGroup } from "../../../../components/atoms/RadioGroup";
import { withFormController as withFormControllerColorPicker } from "../../../../components/atoms/ColorPicker";
import useProjectCreateMutation from "../../../../hooks/useProjectCreateMutation";
import useProjectUpdateMutation from "../../../../hooks/useProjectUpdateMutation";
import { withFormController as withFormControllerCustomer } from "../../../../components/molecules/AutocompleteCustomer";
import useCustomersMap from "../../../../hooks/useCustomersMap";
import Typography from "../../../../components/atoms/Typography";
import useFeatures from "../../../../hooks/useFeatures";
import { IFormInput } from "./types";
import { defaultValues, isSalaryEnabledFromSalaryType } from "./helpers";
import { useSchema } from "./useSchema";
import salaryTable from "../../reports/salary/SalaryTable";
import { IProject } from "../../../../models/project/types";
import Project from "../../../../models/Project";

const TagInputFromController = withFormControllerTagInput<IFormInput>();
const AutoCompleteUsersFormController = withFormControllerUsers<IFormInput>();
const AutocompleteUserGroupsFormController = withFormControllerUserGroups<
  IFormInput
>();
const AutocompleteCustomerFormController = withFormControllerCustomer<
  IFormInput
>();
const CheckboxFromController = withFormControllerCheckbox<IFormInput>();
const RadioGroupFromController = withFormControllerRadioGroup<IFormInput>();
const ColorPickerFormController = withFormControllerColorPicker<IFormInput>();

interface IFormProps {
  id: string;
  project?: Project;
  // create: () => void;
  create: ReturnType<typeof useProjectCreateMutation>["mutate"];
  update: ReturnType<typeof useProjectUpdateMutation>["mutate"];
}

export interface IFormRef {
  isDirty: () => boolean;
}

const Form = forwardRef<IFormRef, IFormProps>(
  ({ id: formId, project, create, update }, ref) => {
    const { t } = useTranslation();
    const { data: customers } = useCustomers();
    const customersMap = useCustomersMap(customers);
    const { data: users } = useUsers();
    const { data: userGroups } = useUserGroups();
    const usersMap = useUsersMap(users);
    const userGroupsMap = useUserGroupsMap(userGroups);
    const { projectSalaryRateSettings } = useProjectSalaryRateSettings();
    const features = useFeatures();
    const { close } = useDialog();
    const schema = useSchema();

    const {
      control,
      handleSubmit,
      formState: { errors, isDirty, dirtyFields },
      register,
      watch
    } = useForm<IFormInput>({
      resolver: yupResolver(schema),
      defaultValues: {
        ...defaultValues,
        nettlonnDimensionOne: project?.metadata?.nettlonn?.dimensionOne,
        nettlonnSalaryArt: project?.metadata?.nettlonn?.salaryArt,
        name: project?.name,
        tags: project?.tags || [],
        managerEmail: project?.managerEmail || "",
        managerName: project?.managerName || "",
        twntyFourSevenId: project?.twntyFourSevenId || "",
        externalReference: project?.externalReference || "",
        billable: project?.billable,
        billableRate: project?.billableRate,
        salaryEnabled: project?.salaryEnabled,
        salaryRate: project?.salaryRate,
        customer: customersMap.get(project?.customer),
        team: project?.team?.map(id => usersMap.get(id)).filter(Boolean) || [],
        userGroups: project?.userGroups?.map(u => userGroupsMap.get(u)),
        salaryType: project?.salaryType
      }
    });

    useImperativeHandle(ref, () => ({
      isDirty: () => isDirty
    }));

    const globalProvisionOfProjectBillableRate =
      projectSalaryRateSettings?.value?.globalProvisionOfProjectBillableRate;
    const watchedSalaryType = watch("salaryType");
    const watchedBillable = watch("billable");
    const watchedBillableRate = watch("billableRate");
    const onSubmit = (data: IFormInput) => {
      const userGroups = (data.userGroups || [])
        .map(u => u.id)
        .filter((id): id is string => Boolean(id));
      const team = data.team.map(u => u.id);

      const salaryEnabled = data.salaryType !== "none";
      const customer = data.customer?.id;
      const nettlonn =
        features?.nettlonn && salaryEnabled
          ? {
              salaryArt: data.nettlonnSalaryArt,
              dimensionOne: data.nettlonnDimensionOne
            }
          : undefined;

      const payload: Partial<IProject> = {};

      for (const [key, value] of Object.entries(dirtyFields)) {
        if (value) {
          payload[key as keyof Partial<IProject>] =
            data[key as keyof IFormInput];
        }
      }

      if (dirtyFields.salaryType) {
        payload.salaryEnabled = salaryEnabled;
        payload.salaryProvisionPercentage =
          data.salaryType === "provision"
            ? globalProvisionOfProjectBillableRate
            : null;
      }
      if (dirtyFields.team) {
        payload.team = team;
      }
      if (dirtyFields.userGroups) {
        payload.userGroups = userGroups;
      }
      if (dirtyFields.customer) {
        payload.customer = customer;
      }

      if (nettlonn) {
        payload.metadata = {
          nettlonn
        };
      }

      if (project?.id) {
        update(payload, {
          onSuccess: () => {
            close();
          }
        });
      } else {
        create(payload, {
          onSuccess: () => {
            close();
          }
        });
      }
    };

    return (
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              {...register("name")}
              fullWidth
              variant="filled"
              label={t("pages.projects.projectName")}
            />
          </Grid>
          <Grid item xs={12}>
            <ColorPickerFormController control={control} name="color" />
          </Grid>
          <Grid item xs={12}>
            <TagInputFromController
              ControllerProps={{
                control,
                name: "tags"
              }}
              fullWidth
              variant="filled"
              label={t("common.tags")}
              placeholder={t("common.tagsPlaceholder")}
            />
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteUsersFormController
              ControllerProps={{
                control,
                name: "team"
              }}
              users={users}
              label={t("common.teamMembers")}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteUserGroupsFormController
              ControllerProps={{
                control,
                name: "userGroups"
              }}
              groups={userGroups}
              variant="filled"
              label={t("common.userGroups")}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteCustomerFormController
              ControllerProps={{ control, name: "customer" }}
              customers={customers}
              label={t("common.customer")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("managerName")}
              fullWidth
              variant="filled"
              label={t("common.managerName")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("managerEmail")}
              fullWidth
              variant="filled"
              label={t("common.managerEmail")}
              error={!!errors.managerEmail}
              helperText={!!errors.managerEmail && errors.managerEmail.message}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <CheckboxFromController
                  ControllerProps={{ control, name: "billable" }}
                  color="primary"
                />
              }
              label={t("common.billable")}
            />
          </Grid>
          {watchedBillable && (
            <Grid item xs={12}>
              <TextField
                {...register("billableRate")}
                fullWidth
                inputProps={{ min: 0, step: "0.01" }}
                variant="filled"
                label={`${t("common.default")} ${t("common.billableRate")}`}
                type="number"
                style={{ maxWidth: 200 }}
                disabled={!watchedBillable}
                error={!!errors.billableRate}
                helperText={errors.billableRate?.message}
              />
            </Grid>
          )}
          {features?.projectSalaryRate && (
            <>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {t("pages.projects.dialog.projectSalaryVariant")}
                  </FormLabel>
                  <RadioGroupFromController
                    aria-label="salary type"
                    control={control}
                    name="salaryType"
                  >
                    <FormControlLabel
                      value="none"
                      control={<Radio color="primary" />}
                      label={t("common.none")}
                    />
                    <FormControlLabel
                      value="fixed"
                      control={<Radio color="primary" />}
                      label={t("pages.projects.dialog.projectSalaryManual")}
                    />
                    {globalProvisionOfProjectBillableRate &&
                      watchedBillable && (
                        <FormControlLabel
                          value="provision"
                          control={<Radio color="primary" />}
                          label={t(
                            "pages.projects.dialog.globalProvisionOfProjectBillableRate"
                          )}
                        />
                      )}
                  </RadioGroupFromController>
                </FormControl>
              </Grid>
              {watchedSalaryType === "fixed" && (
                <Grid item xs={12}>
                  <TextField
                    {...register("salaryRate")}
                    fullWidth
                    inputProps={{ step: 0.01, min: 0 }}
                    variant="filled"
                    label={`${t("common.default")} ${t("common.salaryRate")}`}
                    type="number"
                    style={{ maxWidth: 200 }}
                    error={!!errors.salaryRate}
                    helperText={
                      errors.salaryRate?.message ||
                      t("common.fractionsWillBeRounded")
                    }
                  />
                </Grid>
              )}
              {watchedSalaryType === "provision" && (
                <Grid item xs={12}>
                  <TextField
                    value={
                      (globalProvisionOfProjectBillableRate / 100) *
                      (!Number.isNaN(parseFloat(watchedBillableRate))
                        ? parseFloat(watchedBillableRate)
                        : 0)
                    }
                    disabled
                    fullWidth
                    variant="filled"
                    label={`${t("common.salaryRate")}`}
                    type="number"
                    style={{ maxWidth: 200 }}
                    helperText={`${globalProvisionOfProjectBillableRate}% ${t(
                      "common.of"
                    )} ${watchedBillableRate || 0}`}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth variant="outlined">
              <FormLabel component="legend">
                <Typography variant="h3">{t("common.integrations")}</Typography>
              </FormLabel>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    {...register("externalReference")}
                    // defaultValue={project?.externalReference}
                    fullWidth
                    variant="filled"
                    label={t("common.referenceNo")}
                    placeholder="--"
                  />
                </Grid>
                <Grid item xs={12}>
                  {features?.twentyFourSevenIntegration && (
                    <TextField
                      {...register("twntyFourSevenId")}
                      // defaultValue={project?.twntyFourSevenId}
                      fullWidth
                      variant="filled"
                      label="24sevenoffice-id"
                      placeholder="--"
                    />
                  )}
                </Grid>
                {features?.nettlonn && (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <FormControl
                        component="fieldset"
                        fullWidth
                        variant="outlined"
                      >
                        <FormLabel component="legend">
                          <Typography variant="h4">Nettlønn</Typography>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              {...register("nettlonnSalaryArt")}
                              fullWidth
                              type="number"
                              inputProps={{ min: 0 }}
                              variant="filled"
                              label={t("common.salaryArt")}
                              error={!!errors.nettlonnSalaryArt}
                              helperText={errors.nettlonnSalaryArt?.message}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              {...register("nettlonnDimensionOne")}
                              fullWidth
                              type="number"
                              inputProps={{ min: 0 }}
                              variant="filled"
                              label={t("common.dimensionOne")}
                              error={!!errors?.nettlonnDimensionOne}
                              helperText={errors?.nettlonnDimensionOne?.message}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    );
  }
);

export default Form;
