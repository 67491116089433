import React, { useRef, useState } from "react";
import {
  ClickToSignIn,
  FeatureCard,
  FeaturesSection,
  LandingSection,
  PlansContainer,
  StyledLandingPage,
  TitleContainer
} from "./landing/Landing.styles";
import { Typography } from "@material-ui/core";
import { push } from "connected-react-router";
import Plans from "../protected/subscription/Plans";
import Button from "@superprofit/core-react-components/atoms/Button";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import Toolbar from "@superprofit/core-react-components/atoms/Toolbar";
import Grid from "@material-ui/core/Grid";
import Login from "./Login";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useTransform } from "framer-motion";
import { useViewportScroll, useUpdateRef } from "../../helpers";
import Link from "@material-ui/core/Link";
import { Dialog } from "@superprofit/core-react-components/atoms";
import useTheme from "@material-ui/core/styles/useTheme";
import Features from "./Features";

export default props => {
  const dispatch = useDispatch();

  const [showLogin, setShowLogin] = useState(false);

  const user = useSelector(({ api: { auth } }) => auth.user);
  const [scrollNode, scrollRef] = useUpdateRef();
  const signUpSectionRef = useRef();
  const signInSectionRef = useRef();
  const plansSectionRef = useRef();
  const { scrollYProgress } = useViewportScroll(scrollNode);
  const fade = useTransform(scrollYProgress, [0, 0.2], [1, 0.05]);

  const scrollToSection = ({ current }) => e => {
    e.preventDefault();
    const rect = current.getBoundingClientRect();
    scrollNode.scrollTo({
      left: rect.x,
      top: rect.top + scrollNode.scrollTop,
      behaviour: "smooth"
    });
  };

  const handleShowLogin = () => {
    if (!!user) {
      dispatch(push('/app'));

    } else {
      setShowLogin(true);

    }
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  const handleOnSignup = () => {
    const path = props.routerHelpers.getPublicPathWithKey("signup");
    dispatch(push(path));
  };

  return (
    <StyledLandingPage ref={scrollRef}>
      <AppBar elevation={0}>
        <Toolbar>
          <Grid container justify="flex-end" component="div">
            <Button color="primary" onClick={scrollToSection(signUpSectionRef)}>
              About
            </Button>
            <Button color="primary" onClick={scrollToSection(plansSectionRef)}>
              Features
            </Button>
            <Button color="primary" onClick={scrollToSection(plansSectionRef)}>
              Pricing
            </Button>
            <Button color="primary" onClick={handleShowLogin}>
              {!!user ? "Get started" : "Sign in"}
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <LandingSection color="gradient" ref={signUpSectionRef}>
        <motion.div style={{ opacity: fade }}>
          <Grid container justify="center" alignItems="center" component="div">
            <Grid item xs={6}>
              <TitleContainer>
                <Typography component="h1" variant="h1">
                  Timesheets made simple.
                </Typography>
                <Typography component="h2" variant="h2">
                  Manage projects, teams, billable and non-billable working
                  hours. Create reports and invoice customers in a snap.
                </Typography>
                <div>
                  <Button
                    role="link"
                    color="secondary"
                    size="large"
                    variant="contained"
                    onClick={handleOnSignup}
                  >
                    Sign up
                  </Button>
                  <ClickToSignIn>
                    Already have an account? Click
                    <Link color="secondary" href="#" onClick={handleShowLogin}>
                      here
                    </Link>
                    to sign in.
                  </ClickToSignIn>
                </div>
              </TitleContainer>
            </Grid>
            <Grid item xs={6}>
              <div>
                <img src="/timet_main.png" style={{ marginTop: 100 }} />
              </div>
            </Grid>
          </Grid>
        </motion.div>
      </LandingSection>

      <Features />

      <LandingSection color="gradient">
        <TitleContainer>
          <br />
          <br />
          <Typography component="h1" variant="h1">
            Subscription plans
          </Typography>
        </TitleContainer>
        <PlansContainer ref={plansSectionRef}>
          <Plans />
        </PlansContainer>
      </LandingSection>

      <Login
        open={showLogin}
        onClose={handleCloseLogin}
        {...props}
        ref={signInSectionRef}
        scrollYProgress={scrollYProgress}
      />
    </StyledLandingPage>
  );
};
