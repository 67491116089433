import styled from "styled-components";
import { CardHeader } from "@material-ui/core";

export const StyledCardHeader = styled(CardHeader)`
  .MuiCardHeader-content {
    .MuiCardHeader-title {
      font-weight: ${props => props.theme.typography.h3.fontWeight};
      font-size: ${props => props.theme.typography.h3.fontSize};
    }

    .MuiCardHeader-subheader {
      text-transform: uppercase;
      font-weight: ${props => props.theme.typography.h2.fontWeight};
      font-size: ${props => props.theme.typography.h2.fontSize};
      color: ${props => props.theme.palette.text.hint};
    }
  }

  .MuiCardHeader-action {
    margin-top: 0;
    margin-right: 0;
  }
`;
