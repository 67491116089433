import { SELECT_WORKSPACE } from "./constants";

export const initialState = {
  selectedWorkspace: ""
};

export default (state = initialState, action) => {
  const { payload = {} } = action;
  const { selectedWorkspace } = payload;
  switch (action.type) {
    case SELECT_WORKSPACE:
      return { ...state, selectedWorkspace };
    default:
      return state;
  }
};
