import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@superprofit/core-react-components/atoms/Dialog";
import DialogTitle from "@superprofit/core-react-components/atoms/DialogTitle";
import DialogContent from "@superprofit/core-react-components/atoms/DialogContent";
import DialogContentText from "@superprofit/core-react-components/atoms/DialogContentText";

import {
  hideExportDialog,
  watchExportInvoice
} from "../../../../redux/modules/ui/integrations/twentyfourseven/invoices/actions";
import CircularProgress from "@superprofit/core-react-components/atoms/CircularProgress";

export default () => {
  const dispatch = useDispatch();

  const open = useSelector(
    state => state.ui.integrations.twentyFourSeven.invoices.dialog.open
  );
  const isLoading = useSelector(
    state => state.ui.integrations.twentyFourSeven.invoices.dialog.loading
  );
  const invoice = useSelector(
    state => state.ui.integrations.twentyFourSeven.invoices.dialog.invoice
  );

  const handleOnClose = () => {
    if (isLoading) return;
    dispatch(hideExportDialog());
  };

  const handleOnExport = () => {
    if (isLoading) return;
    dispatch(watchExportInvoice({ invoice }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Syncing invoice</DialogTitle>
      <DialogContent style={{ minWidth: 320 }}>
        <DialogContentText>Please wait while syncing invoice</DialogContentText>
        <CircularProgress color="primary" size={48} />
      </DialogContent>

      {/*<DialogActions>*/}
      {/*  {isLoading && <CircularProgress color="primary" size={24} />}*/}
      {/*  <Button disabled={isLoading} onClick={handleOnClose} color="primary">*/}
      {/*    Cancel*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    disabled={isLoading}*/}
      {/*    onClick={handleOnExport}*/}
      {/*    variant="contained"*/}
      {/*    color="primary"*/}
      {/*  >*/}
      {/*    Export*/}
      {/*  </Button>*/}
      {/*</DialogActions>*/}
    </Dialog>
  );
};
