import { moment } from "@superprofit/time-util";

export const getHoursByCustomer = (stats = [], projectsById = {}) => {
  const hoursByCustomerId = {};
  stats.forEach(s => {
    const customerId =
      projectsById[s.project] && projectsById[s.project].customer;
    if (!customerId) return;
    hoursByCustomerId[customerId] =
      (hoursByCustomerId[customerId] || 0) + s.total;
  });

  return hoursByCustomerId;
};

export const getMoneyByCustomer = (stats = [], projectsById = {}) => {
  const moneyByCustomerId = {};

  stats.forEach(s => {
    const customerId =
      projectsById[s.project] && projectsById[s.project].customer;
    if (!customerId) return;

    if (projectsById[s.project] && projectsById[s.project].billable) {
      moneyByCustomerId[customerId] =
        (moneyByCustomerId[customerId] || 0) +
        s.total * projectsById[s.project].getBillableRate(s.user);
    }
  });

  return moneyByCustomerId;
};

export const getRelativeHoursPercentageByCustomer = (
  stats = [],
  projectsById = {}
) => {
  const hoursByCustomerId = getHoursByCustomer(stats, projectsById);
  const max = Math.max.apply(this, Object.values(hoursByCustomerId));
  return Object.keys(hoursByCustomerId).reduce(
    (prev, next) => ({
      ...prev,
      [next]: (hoursByCustomerId[next] / max) * 100
    }),
    {}
  );
};

export const getRelativeMoneyByCustomer = (stats = [], projectsById = {}) => {
  const moneyByCustomerId = getMoneyByCustomer(stats, projectsById);
  const max = Math.max.apply(this, Object.values(moneyByCustomerId));
  return Object.keys(moneyByCustomerId).reduce(
    (prev, next) => ({
      ...prev,
      [next]: (moneyByCustomerId[next] / max) * 100
    }),
    {}
  );
};

export const getProjectHoursByCustomer = (stats = [], projectsById = {}) => {
  const statsByProjectUserId = stats.reduce((prev, next) => {
    const customerId =
      projectsById[next.project] && projectsById[next.project].customer;
    if (!customerId) return prev;
    if (next.total === 0) return prev;

    const projectHours =
      ((prev[customerId] && (prev[customerId][next.project] || 0)) || 0) +
      next.total;

    const customerRecord = {
      ...(prev[customerId] || {}),
      [next.project]: projectHours
    };
    return {
      ...prev,
      [customerId]: customerRecord
    };
  }, {});
  return statsByProjectUserId;
};

export const getProjectMoneyByCustomer = (stats = [], projectsById = {}) => {
  const statsByProjectUserId = stats.reduce((prev, next) => {
    const customerId =
      projectsById[next.project] && projectsById[next.project].customer;
    const project = projectsById[next.project];

    if (!customerId || !project) return prev;
    if (next.total === 0) return prev;

    const projectMoney =
      ((prev[customerId] && (prev[customerId][next.project] || 0)) || 0) +
      next.total * project.getBillableRate(next.user);

    const customerRecord = {
      ...(prev[customerId] || {}),
      [next.project]: projectMoney
    };
    return {
      ...prev,
      [customerId]: customerRecord
    };
  }, {});
  return statsByProjectUserId;
};

export const getCustomerProjectEntries = (entries = [], projectsById) => {
  return entries.reduce((prev, next) => {
    const project = projectsById[next.project];
    if (next.hours === 0) return prev;
    const date = `${next.year}-${next.dayOfYear}`;
    const res = { ...prev };
    res[project.customer] = res[project.customer] || {};
    res[project.customer][next.project] =
      res[project.customer][next.project] || [];
    res[project.customer][next.project].push({
      user: next.user,
      date,
      year: next.year,
      dayOfYear: next.dayOfYear,
      hours: next.hours,
      money: next.hours * project.getBillableRate(next.user),
      comments: next.comments || []
    });

    return res;
  }, {});
};
// export const getHoursPerWeekByProject = (stats = []) => {
//   const statsByWeek = stats.reduce((prev, next) => {
//     if (next.total === 0) return prev;
//     const weekHours =
//       ((prev[next.project] && (prev[next.project][next.week] || 0)) || 0) +
//       next.total;
//     const projectRecord = {
//       ...(prev[next.project] || {}),
//       [next.week]: weekHours
//     };
//     return {
//       ...prev,
//       [next.project]: projectRecord
//     };
//   }, {});
//   return statsByWeek;
// };
//
// export const getUserHoursPerWeekByProject = (stats = []) => {
//   const statsByUserProjectWeek = stats.reduce((prev, next) => {
//     if (next.total === 0) return prev;
//     const existing =
//       (prev[next.project] &&
//         prev[next.project][next.user] &&
//         prev[next.project][next.user][next.week]) ||
//       0;
//     const userHours = existing + next.total;
//     let projectRecord = { ...(prev[next.project] || {}) };
//     projectRecord[next.user] = { ...(projectRecord[next.user] || {}) };
//     projectRecord[next.user][next.week] = userHours;
//
//     return {
//       ...prev,
//       [next.project]: projectRecord
//     };
//   }, {});
//   return statsByUserProjectWeek;
// };

// export const getWeekOfMonthDates = (year, month, week) => {
//   const startOfMonth = moment()
//     .year(year)
//     .month(month - 1)
//     .startOf("month");
//   const endOfMonth = moment()
//     .year(year)
//     .month(month - 1)
//     .endOf("month");
//   const start = moment()
//     .year(year)
//     .month(month - 1)
//     .week(week)
//     .startOf("week");
//   const end = moment()
//     .year(year)
//     .month(month - 1)
//     .week(week)
//     .endOf("week");
//
//   let startD = start.date();
//   let endD = end.date();
//
//   if (start.isBefore(startOfMonth)) {
//     startD = startOfMonth.date();
//   }
//
//   if (end.isAfter(endOfMonth)) {
//     endD = endOfMonth.date();
//   }
//
//   return `${startD}-${endD}`;
// };
