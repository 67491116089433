import { useMemo } from "react";
import useUnarchivedProjects from "./useUnarchivedProjects";
import useUserStats from "./useUserStats";
import useUser from "./useUser";
import Project from "../models/Project";
import useUserGroups from "./useUserGroups";

type Params = {
  period: { year: number; month?: number; week?: number };
  id?: string;
};

const useActiveProjects = ({ period, id: overrideId }: Params) => {
  const query = useUnarchivedProjects();
  const { email } = useUser();
  const id = overrideId || email;
  const { data: stats, isLoading: statsLoading } = useUserStats({
    year: period.year,
    user: id
  });
  // const filtered = useMemo(() => {
  //   const d = activeProjectsQuery.data || [];
  //   if (onlyShowProjectsWith === OnlyShowProjectsWith.hoursThisWeek) {
  //     return d.filter((p: IProject) =>
  //       currentYearByWeek.some(
  //         s => s.project === p.id && s.week === period.week
  //       )
  //     );
  //   }

  const filteredStatsByProjectMap = useMemo(() => {
    const m = new Map();
    (stats || []).forEach(s => {
      if (
        period.year &&
        (typeof period.month === "undefined"
          ? true
          : s.month === period.month) &&
        (typeof period.week === "undefined" ? true : s.week === period.week) &&
        s.total > 0
      ) {
        m.set(s.project, s);
      }
    });
    return m;
  }, [stats, period]);

  const { isLoading: userGroupsLoading, data: userGroups } = useUserGroups({
    ids: [id]
  });

  const data = useMemo(() => {
    if (query.data) {
      return query.data.filter((p: Project) => {
        const isParticipant =
          (p.team || []).some(u => u === id) ||
          userGroups
            ?.filter((g: any) => p.userGroups.some(id => id === g.id))
            .some((u: any) => {
              return (
                u &&
                u.users &&
                u.users.length > 0 &&
                u.users.some((usr: string) => usr === "all" || id === usr)
              );
            });

        return (
          (filteredStatsByProjectMap.has(p.id) || !period.year) && isParticipant
        );
      });
    }
    return [];
  }, [query.data, filteredStatsByProjectMap, period, userGroups]);
  return {
    ...query,
    data,
    isLoading:
      query.isLoading || (!!period.year && statsLoading) || userGroupsLoading
  };
};

export default useActiveProjects;
