import React from "react";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
  StyledQontoCheckIcon,
  StyledQontoCircle,
  StyledQontoConnector,
  StyledQontoStepIcon,
  StyledStepper
} from "./stepper/Stepper.styles";
import Tooltip from "./Tooltip";
import StepButton from "@material-ui/core/StepButton";

function QontoStepIcon(props) {
  const { active, completed } = props;

  return (
    <StyledQontoStepIcon active={active}>
      {completed ? <StyledQontoCheckIcon /> : <StyledQontoCircle />}
    </StyledQontoStepIcon>
  );
}

export default function({
  steps,
  activeStep,
  completedSteps,
  onClick,
  ...rest
}) {
  const handleOnClick = (e, value) => {
    if (onClick) onClick(e, value);
  };

  return (
    <StyledStepper
      {...rest}
      alternativeLabel
      activeStep={activeStep}
      connector={<StyledQontoConnector />}
    >
      {steps.map(({ label, tooltip }, idx) => (
        <Step key={label} completed={completedSteps[idx]}>
          <Tooltip placement="top" title={tooltip} arrow>
            <StepButton onClick={e => handleOnClick(e, idx)}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </StepButton>
          </Tooltip>
        </Step>
      ))}
    </StyledStepper>
  );
}
