import { ActionType } from "typesafe-actions";
import * as actions from "./actions";

export enum OnlyShowProjectsWith {
  hoursThisWeek = "hoursThisWeek",
  hoursThisMonth = "hoursThisMonth",
  hoursThisYear = "hoursThisYear"
}

export interface State {
  search: string;
  onlyShowProjectsWith: null | OnlyShowProjectsWith;
  showModal: boolean;
  showHiddenProjects: boolean;
}

export type FilterAction = ActionType<typeof actions>;
