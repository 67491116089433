import React, { forwardRef, useMemo } from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import DurationFormat from "../../../../components/atoms/DurationFormat";
import NumberFormat from "react-number-format";
import ProgressBar from "../../../../components/atoms/ProgressBar";
import { useTheme } from "@material-ui/styles";
import WeekDistribution from "../../../../components/atoms/WeekDistribution";
import UserAvatar from "../../../../components/molecules/UserAvatar";
import UserLink from "../../../../components/molecules/UserLink";
import ConnectedCurrencyFormat from "../../../../components/organisms/ConnectedCurrencyFormat";
import { useTranslation } from "react-i18next";

// { user, hours, byWeek, percentage, project, weekNumbers, columns, ...rest }
interface IProjectUserItemProps {
  user: any;
  hours: any;
  byWeek: any;
  percentage: any;
  project: any;
  weekNumbers: any;
  columns: any;
}
const ProjectUserItem = forwardRef(
  (
    {
      user,
      hours,
      byWeek,
      percentage,
      project,
      weekNumbers,
      columns,
      ...rest
    }: IProjectUserItemProps,
    ref
  ) => {
    const theme = useTheme<{ palette: any }>();
    const { t } = useTranslation();

    const weekDistribution = useMemo(
      () =>
        weekNumbers.map((w: number) => ({
          tooltip: `${t("common.week")} ${w}`,
          value: byWeek[w] || 0,
          color: "secondary"
        })),
      [weekNumbers, byWeek]
    );

    return (
      <TableRow
        {...rest}
        ref={ref}
        style={{ background: theme?.palette?.background?.light }}
      >
        {columns.expand && <TableCell style={{ maxWidth: 80, width: 80 }} />}

        {columns.avatar && (
          <TableCell style={{ maxWidth: 80, width: 80 }}>
            <UserAvatar user={user} />
          </TableCell>
        )}

        <TableCell>
          <UserLink name={user.displayName} email={user.email} hideEmail />
        </TableCell>

        {columns.weekDistribution && (
          <TableCell align="right">
            <WeekDistribution weeks={weekDistribution} />
          </TableCell>
        )}

        {columns.hours && (
          <TableCell align="right">
            <DurationFormat value={hours} component="b" />
          </TableCell>
        )}

        {columns.relativeShare && (
          <TableCell>
            <ProgressBar align="left" color="primary" progress={percentage} />
          </TableCell>
        )}

        {columns.billable && (
          <TableCell align="right">
            {project.billable && (
              <b>
                <ConnectedCurrencyFormat
                  number={project.getBillableRate(user.email) * hours}
                />
              </b>
            )}
          </TableCell>
        )}
      </TableRow>
    );
  }
);

export default ProjectUserItem;
