import { combineReducers } from "redux";

import basis from "./basis/reducer";
import period from "./period/reducer";
import filters from "./filters/reducer";
import filtersDialog from "./filtersDialog/reducer";
import users from "./users/reducer";
import projects from "./projects/reducer";

export default combineReducers({
  basis,
  period,
  filters,
  filtersDialog,
  users,
  projects
});
