export const GET = "api/invoices/GET";
export const GET_SUCCESS = "api/invoices/GET_SUCCESS";
export const GET_FAILURE = "api/invoices/GET_FAILED";

export const DELETE = "api/invoices/DELETE";
export const DELETE_SUCCESS = "api/invoices/DELETE_SUCCESS";
export const DELETE_FAILURE = "api/invoices/DELETE_FAILED";

export const DELETE_MULTIPLE = "api/invoices/DELETE_MULTIPLE";
export const DELETE_MULTIPLE_SUCCESS = "api/invoices/DELETE_MULTIPLE_SUCCESS";
export const DELETE_MULTIPLE_FAILURE = "api/invoices/DELETE_MULTIPLE_FAILURE";

export const SAVE = "api/invoices/SAVE";
export const SAVE_SUCCESS = "api/invoices/SAVE_SUCCESS";
export const SAVE_FAILURE = "api/invoices/SAVE_FAILURE";

export const PATCH = "api/invoices/PATCH";
export const PATCH_SUCCESS = "api/invoices/PATCH_SUCCESS";
export const PATCH_FAILURE = "api/invoices/PATCH_FAILURE";

export const BATCH_UPDATE = "api/invoices/BATCH_UPDATE";
export const BATCH_UPDATE_SUCCESS = "api/invoices/BATCH_UPDATE_SUCCESS";
export const BATCH_UPDATE_FAILURE = "api/invoices/BATCH_UPDATE_FAILURE";

export const WATCH_DELETE = "api/invoices/WATCH_DELETE";
export const WATCH_DELETE_MULTIPLE = "api/invoices/WATCH_DELETE_MULTIPLE";
export const WATCH_GET = "api/invoices/WATCH_GET";
export const WATCH_SAVE = "api/invoices/WATCH_SAVE";
export const WATCH_UPDATE_ALL = "api/invoices/WATCH_UPDATE_ALL";
export const WATCH_PATCH = "api/invoices/WATCH_PATCH";
