import React, { Component } from "react";
import ReactDOM from "react-dom";
import Dialog from "@superprofit/core-react-components/atoms/Dialog";
import DialogTitle from "@superprofit/core-react-components/atoms/DialogTitle";
import DialogContent from "@superprofit/core-react-components/atoms/DialogContent";
import DialogContentText from "@superprofit/core-react-components/atoms/DialogContentText";
import DialogActions from "@superprofit/core-react-components/atoms/DialogActions";
import Button from "@superprofit/core-react-components/atoms/Button";
import Provider from "../../../../ThemeProvider";
import { MODES } from "../../../../redux/modules/ui/theme/constants";

let resolve;

class ConfirmInvoiceSyncDialog extends Component {
  static create() {
    const containerElement = document.createElement("div");
    document.body.appendChild(containerElement);
    return ReactDOM.render(<ConfirmInvoiceSyncDialog />, containerElement);
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      error: false
    };

    this.handleOk = this.handleOk.bind(this);
    this.show = this.show.bind(this);
  }

  handleOk() {
    if (this.state.loading) return;
    this.setState({ open: false });
    resolve(true);
  }

  show() {
    this.setState({ open: true });
    return new Promise(res => {
      resolve = res;
    });
  }

  render() {
    return (
      <Provider mode={MODES.LIGHT}>
        <Dialog
          open={this.state.open}
          onClose={this.handleOk}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Success</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Invoice has been synced to 24sevenoffice
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleOk} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Provider>
    );
  }
}

export default ConfirmInvoiceSyncDialog.create();
