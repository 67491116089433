import React from "react";
import { AppContainer } from "../app/app.styles";
import { Switch } from "react-router-dom";
import { publicRoutes as routes, RouteWithSubRoutes } from "../router";

export default () => (
  <AppContainer>
    <Switch>
      {routes.map(route => (
        <RouteWithSubRoutes key={`route_${route.id}`} {...route} />
      ))}
    </Switch>
  </AppContainer>
);
