import {
  getCustomerProjectEntries,
  getProjectHoursByCustomer,
  getProjectMoneyByCustomer
} from '../../CustomerStats';
import moment from 'moment';
import {
  Report,
  ReportSection,
  ReportSubtitle,
  ReportTable,
  ReportTableCell,
  ReportTableFooter,
  ReportTableHeader,
  ReportTableRow,
  ReportTitle
} from '../Report';
import {colors} from '@superprofit/core-react-components/themes/light/colors';
import {format} from '@superprofit/core-react-components/atoms/NumberFormatIntl';

const build = (customers, projects, users, entries, projectsOnly = false) => {
  const sortedCustomers = customers.slice();
  sortedCustomers.sort((a, b) => {
    if (a.name > b.name) {
      return -1;
    } else if (a.name > b.name) {
      return -1;
    }
    return 1;
  });

  const usersById = users.reduce(
    (prev, next) => ({...prev, [next.id]: next}),
    {}
  );

  const customersById = customers.reduce(
    (prev, next) => ({...prev, [next.id]: next}),
    {}
  );

  const customerProjects = projects.filter(p => !!customersById[p.customer]);

  const projectsById = customerProjects.reduce(
    (prev, next) => ({...prev, [next.id]: next}),
    {}
  );
  const filteredEntries = entries.filter(e => {
    return !!projectsById[e.project];
  });

  const projectMoneyByCustomer = getProjectMoneyByCustomer(
    filteredEntries,
    projectsById
  );
  const projectHoursByCustomer = getProjectHoursByCustomer(filteredEntries);
  const entriesByCustomerProject = getCustomerProjectEntries(
    filteredEntries,
    projectsById
  );

  const report = new Report();
  sortedCustomers.forEach(customer => {
    const reportSection = new ReportSection([
      new ReportTitle(`Customer: ${customer.name}`)
    ]);

    let hasEntries = false;


    const tableRows = [];
    const f = (number) => {
      let options = { minimumFractionDigits: 2};
      // if (currency) {
      //   options = { style: "currency", currency };
      // }
      return format({ number, options });
    };

    if (projectsOnly) {
      const tableHeader = new ReportTableHeader([
        new ReportTableCell('Project'),
        new ReportTableCell('Hours', {textAlign: 'right'}),
        new ReportTableCell('Billable', {textAlign: 'right'})
      ]);

      let totalSum = 0;
      let totalHours = 0;
      projects.forEach(project => {
        const customerProjectEntries =
          entriesByCustomerProject[customer.id] &&
          entriesByCustomerProject[customer.id][project.id];
        let projectSum = 0;
        let projectHours = 0;

        if (customerProjectEntries && customerProjectEntries.length > 0) {
          hasEntries = true;
          // Sort entries
          customerProjectEntries.sort((b, a) => {
            if (a.year > b.year) {
              return -1;
            } else if (a.dayOfYear > b.dayOfYear) {
              return -1;
            } else if (
              a.year === b.year &&
              a.dayOfYear === b.dayOfYear &&
              usersById[a.user]?.displayName > usersById[b.user]?.displayName
            ) {
              return -1;
            }
            return 1;
          });
          customerProjectEntries.forEach(entry => {
            projectHours += entry.hours || entry.total;
            projectSum += entry.money;
          });

          tableRows.push(
            new ReportTableRow([
              new ReportTableCell(project.name),
              new ReportTableCell(projectHours,
                {textAlign: 'right'}
              ),
              new ReportTableCell(f(projectSum), {textAlign: 'right'})
            ], {
              borderBottomColor: colors.background,
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              padding: 3
            })
          );
          totalHours = projectHours;
          totalSum += projectSum;

        }
      });
      const tableFooter = new ReportTableFooter([
        new ReportTableCell('Total'),
        new ReportTableCell('*', {textAlign: 'right'}),
        new ReportTableCell(f(totalSum), {textAlign: 'right'})
      ]);
      reportSection.push([
        new ReportSubtitle(''),
        new ReportTable(tableHeader, tableRows, tableFooter)
      ]);
    } else {
      projects.forEach(project => {
        const customerProjectEntries =
          entriesByCustomerProject[customer.id] &&
          entriesByCustomerProject[customer.id][project.id];

        let sum = 0;

        if (customerProjectEntries && customerProjectEntries.length > 0) {
          hasEntries = true;

          // Sort entries
          customerProjectEntries.sort((b, a) => {
            if (a.year > b.year) {
              return -1;
            } else if (a.dayOfYear > b.dayOfYear) {
              return -1;
            } else if (
              a.year === b.year &&
              a.dayOfYear === b.dayOfYear &&
              usersById[a.user]?.displayName > usersById[b.user]?.displayName
            ) {
              return -1;
            }
            return 1;
          });

          const tableRows = [];

          const f = (number) => {
            let options = { minimumFractionDigits: 2};
            // if (currency) {
            //   options = { style: "currency", currency };
            // }
            return format({ number, options });
          };
          customerProjectEntries.forEach(entry => {
            // Create rows for entries
            tableRows.push(
              new ReportTableRow([
                new ReportTableCell(
                  moment()
                    .year(entry.year)
                    .dayOfYear(entry.dayOfYear)
                    .format('LL'),
                ),
                new ReportTableCell(
                  usersById[entry.user]?.displayName ||
                  usersById[entry.user]?.email,

                ),
                new ReportTableCell(entry.hours || entry.total, {
                  textAlign: 'right'
                }),
                new ReportTableCell(f(project.getBillableRate(entry.user)), {
                  textAlign: 'right'
                }),
                new ReportTableCell(f(entry.money || 0), {textAlign: 'right'})
              ], {
                borderBottomColor: entry?.comments.length <= 0 ? colors.background : undefined,
                borderBottomWidth: entry?.comments.length <= 0 ? 1 : undefined,
                borderBottomStyle: entry?.comments.length <= 0 ? 'solid' : undefined,
                padding: 3
              })
            );

            if (entry?.comments.length > 0) {
              entry.comments.forEach((c,idx) => {
                tableRows.push(
                  new ReportTableRow([
                    new ReportTableCell(
                      "Comment: " + c.comment, {
                        paddingLeft: 8,
                        fontStyle: 'italic',
                        fontSize: 8,
                        opacity: 0.8,
                      }
                    )
                  ], {
                    borderBottomColor: idx === entry?.comments.length -1 ? colors.background : undefined,
                    borderBottomWidth: idx === entry?.comments.length -1 ? 1 : undefined,
                    borderBottomStyle: idx === entry?.comments.length -1 ? 'solid' : undefined,
                    paddingBottom: idx === entry?.comments.length -1 ? 3 : undefined
                  })
                );
              })
            }
            sum += entry.money;
          });
          const tableHeader = new ReportTableHeader([
            new ReportTableCell('Date'),
            new ReportTableCell('Employee', ),
            new ReportTableCell('Hours', {textAlign: 'right'}),
            new ReportTableCell('Rate', {textAlign: 'right'}),
            new ReportTableCell('Billable', {textAlign: 'right'})
          ]);
          const tableFooter = new ReportTableFooter([
            new ReportTableCell('Total'),
            new ReportTableCell('', {textAlign: 'right'}),
            new ReportTableCell(
              projectHoursByCustomer[customer.id]
                ? projectHoursByCustomer[customer.id][project.id]
                : '',
              {textAlign: 'right'}
            ),
            new ReportTableCell('*', {textAlign: 'right'}),
            new ReportTableCell(f(sum), {textAlign: 'right'})
          ]);

          reportSection.push([
            new ReportSubtitle(project.name),
            new ReportTable(tableHeader, tableRows, tableFooter)
          ]);
        }
      });
    }
    if (hasEntries) {
      report.push(reportSection);
    }
  });

  return report;
};

export const createTableExport = (customers, projects, users, entries, projectsOnly) => {
  return build(customers, projects, users, entries, projectsOnly);
};
