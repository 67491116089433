import { SET_LOADING, SET_STATS_NON_BILLABLE_MONTH } from "./constants";

const initialState = {
  hours: 0,
  change: 0,
  goal: null,
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload.isLoading
      };
    case SET_STATS_NON_BILLABLE_MONTH:
      return {
        ...state,
        hours: payload.hours,
        change: payload.change,
        goal: payload.goal
      };
    default:
      return state;
  }
};
