import React, { forwardRef, Fragment, useState } from "react";
import Table, {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";

import Checkbox from "@superprofit/core-react-components/atoms/Checkbox";
import InvoiceRow from "./InvoiceRow";
import ActionMenu from "../../../../components/molecules/ActionMenu";
import TableLoader from "../../../../components/molecules/TableLoader";
import { useFeatures } from "../../../../helpers";
import { useTranslation } from "react-i18next";

const getProp = (invoice, orderBy) => {
  switch (orderBy) {
    case "amount":
      return invoice.basis.totalSum;
    case "customer":
      return invoice.customer.name.toLowerCase();
    case "period":
      return `${invoice.year}-${invoice.month}`;
    default:
      return invoice[orderBy];
  }
};

const descendingComparator = (a, b, orderBy) => {
  if (getProp(b, orderBy) < getProp(a, orderBy)) return -1;
  if (getProp(b, orderBy) > getProp(a, orderBy)) return 1;
  return 0;
};

const getComparator = (orderDir, orderBy) => {
  return orderDir === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export default forwardRef(
  (
    {
      onChangePaid,
      onSelectionChange,
      onEdit,
      onArchive,
      onDelete,
      onView,
      onExport,
      selection = [],
      invoices,
      loading
    },
    ref
  ) => {
    const [actionMenu, setActionMenu] = useState({
      invoice: null,
      anchor: null
    });
    const { twentyFourSevenIntegration } = useFeatures();
    const { t } = useTranslation();

    const [orderBy, setOrderBy] = useState("invoiceNumber");
    const [orderDir, setOrderDir] = useState("desc");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const selectedIds = React.useMemo(
      () =>
        (selection || []).reduce(
          (prev, next) => ({
            ...prev,
            [next.id]: true
          }),
          {}
        ),
      [selection]
    );

    const invoiceList = React.useMemo(() => {
      let invoiceListListSorted = invoices.slice();

      invoiceListListSorted.sort(getComparator(orderDir, orderBy));
      invoiceListListSorted =
        rowsPerPage > 0
          ? invoiceListListSorted.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : invoiceListListSorted;

      return invoiceListListSorted;
    }, [orderBy, orderDir, page, rowsPerPage, invoices]);

    const handleOnSort = (event, property) => {
      const isAsc = orderBy === property && orderDir === "asc";
      setOrderDir(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleOnMore = (e, invoice) => {
      setActionMenu({ anchor: e.currentTarget, invoice });
    };

    const handleOnCloseMore = e => {
      setActionMenu({});
    };

    const handleOnSelectAll = () => {
      if (selection && selection.length === invoices.length) {
        onSelectionChange([]);
      } else {
        onSelectionChange(invoices);
      }
    };

    const handleOnSelect = invoice => {
      if (selectedIds[invoice.id]) {
        onSelectionChange(selection.filter(i => i.id !== invoice.id));
      } else {
        onSelectionChange(
          selection.filter(i => i.id !== invoice.id).concat(invoice)
        );
      }
    };

    return (
      <Fragment>
        <ActionMenu
          onEdit={onEdit}
          onArchive={onArchive}
          onDelete={onDelete}
          onView={onView}
          onChangePaid={onChangePaid}
          onExport247={twentyFourSevenIntegration && onExport}
          data={actionMenu.invoice}
          open={!!actionMenu.anchor}
          anchorEl={actionMenu.anchor}
          onClose={handleOnCloseMore}
        />

        <TableContainer ref={ref}>
          <Table aria-label="Invoices">
            <TableHead>
              <TableRow>
                <TableCell style={{ maxWidth: 80, width: 80 }}>
                  <Checkbox
                    defaultChecked={false}
                    checked={
                      (selection.length &&
                        selection.length === invoices.length) ||
                      false
                    }
                    indeterminate={
                      (selection.length &&
                        selection.length < invoices.length) ||
                      false
                    }
                    inputProps={{ "aria-label": "select all invoices" }}
                    onChange={handleOnSelectAll}
                  />
                </TableCell>

                <TableCell
                  sortDirection={orderBy === "invoiceNumber" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "invoiceNumber"}
                    direction={orderBy === "invoiceNumber" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "invoiceNumber")}
                  >
                    {t("common.number")}.
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sortDirection={orderBy === "customer" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "customer"}
                    direction={orderBy === "customer" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "customer")}
                  >
                    {t("common.customer")}
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sortDirection={orderBy === "period" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "period"}
                    direction={orderBy === "period" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "period")}
                  >
                    Period
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  align="right"
                  sortDirection={orderBy === "amount" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "amount"}
                    direction={orderBy === "amount" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "amount")}
                  >
                    {t("common.amount")}
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sortDirection={orderBy === "paid" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "paid"}
                    direction={orderBy === "paid" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "paid")}
                  >
                    {t("common.status")}
                  </TableSortLabel>
                </TableCell>

                <TableCell style={{ maxWidth: 80, width: 80 }}></TableCell>
              </TableRow>
            </TableHead>

            {!loading && (
              <Fragment>
                <TableBody>
                  {invoiceList.map(i => (
                    <InvoiceRow
                      key={i.id}
                      invoice={i}
                      selected={!!selectedIds[i.id]}
                      onChangePaid={onChangePaid}
                      onView={onView}
                      onMore={handleOnMore}
                      onSelect={() => handleOnSelect(i)}
                    />
                  ))}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TablePagination
                      labelRowsPerPage={t("common.rowsPerPage")}
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        { label: t("common.all"), value: -1 }
                      ]}
                      count={invoices.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Fragment>
            )}

            {loading && (
              <TableLoader
                cols={["rect", "text", "text", "text", "text", "text", "rect"]}
              />
            )}
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
);
