import React, { forwardRef } from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import Checkbox from "@superprofit/core-react-components/atoms/Checkbox";
import Tooltip from "@superprofit/core-react-components/atoms/Tooltip";
import IconButton from "@superprofit/core-react-components/atoms/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MonthYearDisplay from "../../../../components/atoms/MonthYearDisplay";
import ConnectedCurrencyFormat from "../../../../components/organisms/ConnectedCurrencyFormat";
import Label from "@superprofit/core-react-components/atoms/Label";
import { FlexColumn } from "../invoiceCard/InvoiceCard.styles";
import { useTranslation } from "react-i18next";

export default forwardRef(
  (
    {
      invoice,
      onMore,
      onSelect,
      onChangePaid,
      onView,
      selected = false,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <TableRow
        key={invoice.id}
        {...rest}
        ref={ref}
        onClick={e => onView(e, invoice)}
      >
        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <Checkbox
            checked={selected}
            value={invoice.id}
            onChange={onSelect}
            onClick={e => e.stopPropagation()}
          />
        </TableCell>

        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <b>{invoice.invoiceNumber}</b>
        </TableCell>

        <TableCell>
          <b>{invoice.customer.name}</b>
        </TableCell>

        <TableCell>
          <b>
            <MonthYearDisplay year={invoice.year} month={invoice.month} />
          </b>
        </TableCell>

        <TableCell align="right">
          <b>
            <ConnectedCurrencyFormat number={invoice.basis.totalSum} />
          </b>
        </TableCell>

        <TableCell>
          {invoice.paid ? (
            <Label label={t("common.paid")} color="success" />
          ) : (
            <Label label={t("common.unpaid")} color="warning" />
          )}
        </TableCell>

        <TableCell align="right">
          <Tooltip title={t("common.more")} aria-label="More">
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={e => {
                onMore(e, invoice);
                e.stopPropagation();
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }
);
