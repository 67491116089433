import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../index";
import * as actions from "../../../../redux/modules/ui/users/snackbar/actions";

const useSnackbar = () => {
  const dispatch = useDispatch();

  const snackbar = useSelector((state: RootState) => state.ui.users.snackbar);

  const update = (updates: any) => {
    dispatch(actions.updateSnackbar(updates));
  };
  return {
    update,
    state: snackbar
  };
};

export default useSnackbar;
