import React from "react";
import {
  AvatarContainer,
  CommentContainer,
  StyledComment
} from "./comment/Comment.styles";
import Typography from "@material-ui/core/Typography";

export default ({ avatar, message, children, ...rest }) => (
  <StyledComment {...rest}>
    <AvatarContainer>{avatar}</AvatarContainer>
    <CommentContainer>
      <Typography
        component="p"
        variant="body2"
        color="textSecondary"
        gutterBottom
      >
        {children}
      </Typography>
    </CommentContainer>
  </StyledComment>
);
