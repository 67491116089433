import styled from "styled-components";
import { CircularProgress } from "../../../../atoms";

export const ButtonContainer = styled.div`
  position: relative;
  width: fit-content;

  > div {
    &:last-child {
      position: absolute;
      left: 50%;
      top: 0;
      height: 24px;
      margin-top: 8px;
      margin-left: -12px;
    }
  }
`;
