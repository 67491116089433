import styled from "@react-pdf/styled-components";
import { Font } from "@react-pdf/renderer";
import robotoRegular from "./font/Roboto-Regular.ttf";
import robotoBold from "./font/Roboto-Bold.ttf";
import robotoLight from "./font/Roboto-Light.ttf";
import robotoItalic from "./font/Roboto-Italic.ttf";
import robotoThinItalic from "./font/Roboto-ThinItalic.ttf";
import { colors } from "@superprofit/core-react-components/themes/light/colors";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: robotoRegular
    },
    { src: robotoBold, fontWeight: 700 },
    { src: robotoLight, fontWeight: 300 },
    { src: robotoItalic, fontStyle: "italic", fontWeight: 400 },
    { src: robotoThinItalic, fontStyle: "italic", fontWeight: 100 }
  ]
});

export const Page = styled.Page`
  flex-direction: column;
  justify-content: space-between;
  font-family: Roboto;
  font-size: 16px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
`;
export const WorkspaceName = styled.Text`
  align-self: flex-end;
  padding-bottom: 10px;
  color: ${colors.secondary};
  font-weight: bold;
  font-size: 12px;
  opacity: 0.5;
`;

export const Timet = styled.Text`
  align-self: flex-end;
  color: ${colors.secondary};
  font-weight: bold;
  font-size: 8px;
  opacity: 0.5;
`;
