import React, { forwardRef, useMemo } from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import DurationFormat from "../../../../components/atoms/DurationFormat";
import NumberFormat from "react-number-format";
import ProgressBar from "../../../../components/atoms/ProgressBar";
import ProjectLink from "../../../../components/molecules/ProjectLink";
import ProjectAvatar from "../../../../components/molecules/ProjectAvatar";
import { useTheme } from "@material-ui/styles";
import WeekDistribution from "../../../../components/atoms/WeekDistribution";
import { ISalaryProjectItem } from "../shared/types";
import { useTranslation } from "react-i18next";
import CustomerLink from "../../../../components/molecules/CustomerLink";
import useCustomersMap from "../../../../hooks/useCustomersMap";

export default forwardRef<HTMLDivElement, ISalaryProjectItem>(
  (
    {
      user,
      hours,
      money,
      byWeek,
      percentage,
      project,
      weekNumbers,
      columns,
      salary,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme<{ palette: any }>();
    const { t } = useTranslation();
    const customersMap = useCustomersMap();

    const weekDistribution = useMemo(
      () =>
        weekNumbers.map(w => ({
          tooltip: `${t("common.week")} ${w}`,
          value: byWeek[w] || 0,
          color: "secondary"
        })),
      [weekNumbers, byWeek]
    ) as { tooltip: string; value: number; color: "secondary" }[];

    return (
      <TableRow
        {...rest}
        ref={ref}
        style={{ background: theme.palette.background.light }}
      >
        {columns.expand && <TableCell style={{ maxWidth: 80, width: 80 }} />}

        {columns.avatar && (
          <TableCell style={{ maxWidth: 80, width: 80 }}>
            <ProjectAvatar project={project} />
          </TableCell>
        )}

        <TableCell>
          <ProjectLink name={project.name} id={project.id || ""} />
        </TableCell>

        {columns.customer && (
          <TableCell>
            <CustomerLink
              name={
                customersMap.get(project.customer)?.name || project.customer
              }
              id={project.customer.id || ""}
            />
          </TableCell>
        )}

        {columns.weekDistribution && (
          <TableCell align="right">
            <WeekDistribution weeks={weekDistribution} />
          </TableCell>
        )}

        {columns.hours && (
          <TableCell align="right">
            <DurationFormat value={hours} component="b" />
          </TableCell>
        )}

        {columns.projectDistribution && (
          <TableCell>
            <ProgressBar
              align="left"
              color={project.getColor()}
              progress={percentage}
            />
          </TableCell>
        )}

        {columns.salary && (
          <TableCell align="right">
            {project.salaryEnabled && (
              <b>
                <NumberFormat
                  displayType="text"
                  thousandSeparator
                  isNumericString
                  decimalScale={2}
                  value={salary || 0}
                />
              </b>
            )}
          </TableCell>
        )}
      </TableRow>
    );
  }
);
