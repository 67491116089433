import { SET_BASIS, SET_LOADING } from "./constants";

const initialState = {
  stats: [],
  users: [],
  projects: [],
  customers: [],
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BASIS:
      return {
        ...state,
        stats: payload.stats,
        customers: payload.customers,
        projects: payload.projects,
        users: payload.users
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload.isLoading
      };
    default:
      return state;
  }
};
