import styled, { css } from "styled-components";
import { Card, Divider, Grid } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "@material-ui/core";
import Typography from "../../../components/atoms/Typography";

// export const EventContainer = styled.div`
//   height: 2rem;
//   width: 100%;
//   background: black;
// `;
// export const EventWrapperContainer = styled.div`
//   height: 100%;
//   width: 100%;
// `;
// export const EventContainerWrapperContainer = styled.div`
//   width: 100%;
//   width: 100%;
//   background: #3f51b5;
// `;

export const TimesheetV2Container = styled(Card)`
  display: flex;
  padding: 1rem 0;
  width: 100%;
  //height: 100%;
  margin-top: ${props => props.theme.spacing(1)}px;
  margin-bottom: ${props => props.theme.spacing(1)}px;

  overflow: hidden;
`;

export const TimesheetV2HeaderContainer = styled.div`
  display: flex;
`;

export const TimesheetV2Wrapper = styled(CardContent)`
  display: flex;
  width: 100%;
  //height: 100%;
`;

export const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EntriesContainer = styled.div``;
export const EntriesWrapper = styled.div`
  padding-bottom: ${props => props.theme.spacing(1)}px;
  display: flex;
  width: 100%;
  height: 4rem;
  > * {
    margin-left: ${props => props.theme.spacing(1)}px;
  }
`;

export const ProjectContainer = styled.div`
  padding-bottom: ${props => props.theme.spacing(1)}px;
  display: flex;
  height: 4rem;
`;

export const DayContainer = styled.div``;

export const EventContainer = styled.div``;

export const GridContainer = styled.form`
  display: grid;
  grid-template-columns: max-content repeat(7, 1fr); /* First column is max-content, rest are 1fr */
  //grid-gap: 0.5rem; /* Adjust the gap as needed */
  align-items: center; /* Center items vertically */
  align-content: baseline; /* Align items in the center of the gap */
  width: 100%;
  overflow: auto;
`;

export const ProjectItem = styled.div<{ isHidden?: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;
  position: sticky;
  background: white;
  left: 0;
  z-index: 90;
  justify-content: space-between;

  ${({ isHidden }) => isHidden && "font-style: italic;"};
  border-right: 1px solid ${props => props.theme.palette.divider};

  //&:first-child {
  //  top: 0;
  //}
`;
// box-shadow: 0px 9px 0px white, -4px 7px 4px 4px ${props =>
//     props.theme.palette.divider};
export const ProjectItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Day = styled.div`
  background: white;
  margin: 0.5rem;
  min-width: 5rem; /* Ensure that the columns shrink to fit content */
  /* Add styling for the other columns if needed */
  //&:nth-child(-n + 7) {
  //  position: sticky;
  //  top: 0;
  //}

  > * {
    &:first-child {
      width: 100%;
    }
  }
`;

export const ProjectsHeaderContainer = styled(Grid).attrs(() => ({
  container: true,
  // spacing: 2,
  alignItems: "center"
}))`
  position: sticky;
  background: white;
  left: 0;
  top: 0;
  padding-top: 0.125rem;
  z-index: 100;
  //grid-row: span 2;
  height: 4rem;
  border-right: 0;
  > * {
    :not(:first-child) {
      margin-left: ${props => props.theme.spacing(1)}px;
    }
  }
`;

export const ProjectsHeader = styled(ProjectItem)`
  //top: 0;
  //padding-top: 0.125rem;
  //z-index: 100;
  ////grid-row: span 2;
  //height: 4rem;
  //min-width: 6rem;
  border-right: 0;
  //border-bottom: 1px solid ${props => props.theme.palette.divider};

  //box-shadow: 0px 12px 11px -18px rgba(0, 0, 0, 0.5);
  // box-shadow: 0px 9px 0px white, -4px 7px 4px 4px props =>
  //   props.theme.palette.divider};
  //box-shadow: 0px 10px 0px 0px white;
`;
export const DayHeader = styled(Day)<{ isToday: boolean; theme: any }>`
  z-index: 95;
  height: 4rem;
  position: sticky;
  top: 0;
  margin: 0;
  border-bottom: 1px solid ${props => props.theme.palette.divider};
  ${props =>
    props.isToday &&
    css`
      color: ${props.theme.palette.primary.main};
    `}
`;

export const DayHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ShowHiddenProjectsButton = styled(Link).attrs({
  component: "button"
})`
  position: sticky;
  z-index: 100;
  left: 0;
  //grid-column: 1 / 9;
  padding-top: 1rem;
  box-shadow: 0px 9px 0px white,
    -4px 7px 4px 4px ${props => props.theme.palette.divider};
  span {
    width: 100%;
    display: inline-flex;
    align-items: center;
  }
`;

export const HiddenProjectsMessage = styled(Typography)`
  position: sticky;
  z-index: 100;
  left: 0;
  padding-top: 1rem;
`;

export const ProjectsHeaderDivider = styled(Divider)`
  height: 2rem;
`;

export const GridContainerCard = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(3rem, 1fr)
  ); /* Each input will have a minimum width of 150px */
  grid-gap: 10px; /* Adjust this value as needed */
`;
