import React, { forwardRef, ForwardRefExoticComponent } from "react";
import { StyledInputLabel } from "./inputLabel/InputLabel.styles";
import { InputLabelProps } from "@material-ui/core";

type IInputLabelProps = Omit<InputLabelProps, "variant"> & {
  variant?: "standard"; //possibly related: variant TS bug https://github.com/mui/material-ui/issues/15697#issuecomment-493419773
} & {
  shrink?: boolean;
};
const InputLabel: ForwardRefExoticComponent<IInputLabelProps> = forwardRef(
  (props, ref) => <StyledInputLabel shrink={false} {...props} ref={ref} />
);

export default InputLabel;
