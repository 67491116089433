import React, { forwardRef } from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import Checkbox from "@superprofit/core-react-components/atoms/Checkbox";
import Tooltip from "@superprofit/core-react-components/atoms/Tooltip";
import IconButton from "@superprofit/core-react-components/atoms/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tags from "../../../../components/molecules/Tags";
import UserDisplay from "../../../../components/molecules/UserDisplay";
import CustomerAvatar from "../../../../components/molecules/CustomerAvatar";
import CustomerLink from "../../../../components/molecules/CustomerLink";
import { useWorkspaceHistory } from "../../../../contexts/WorkspaceContext";

export default forwardRef(
  ({ customer, onMore, onSelect, selected = false, ...rest }, ref) => {
    const history = useWorkspaceHistory();

    return (
      <TableRow
        key={customer.id}
        {...rest}
        ref={ref}
        onClick={e => history.push(`/customers/${customer.id}`)}
      >
        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <Checkbox
            checked={selected}
            value={customer.id}
            onChange={onSelect}
            defaultValue={undefined}
            onClick={e => e.stopPropagation()}
          />
        </TableCell>

        <TableCell style={{ maxWidth: 80, width: 80 }}>
          <CustomerAvatar customer={customer} />
        </TableCell>

        <TableCell>
          <CustomerLink id={customer.id} name={customer.name} />
        </TableCell>

        <TableCell>
          <UserDisplay name={customer.contactName} email={customer.email} />
        </TableCell>

        <TableCell>
          <Tags tags={customer.tags} />
        </TableCell>

        <TableCell align="right">
          <Tooltip title="More" aria-label="More">
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={e => {
                e.stopPropagation();
                onMore(e, customer);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }
);
