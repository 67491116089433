import { SET_LOADING, SET_BURNDOWN } from "./constants";

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const setBurndown = (dataset, labels, values) => ({
  type: SET_BURNDOWN,
  payload: {
    dataset,
    labels,
    values
  }
});
