import { call, put, takeLatest, all } from "redux-saga/effects";
import { WATCH_AUTHENTICATE, WATCH_CHECK_HAS_SESSION } from "./constants";
import * as TwentyFourSeven from "@superprofit/24-seven-integration-client";

import {
  authenticate,
  authenticateFailure,
  authenticateSuccess,
  checkHasSession,
  checkHasSessionFailure,
  checkHasSessionSuccess,
} from "./actions";
import { addGlobalAlert } from "../../../../ui/app/actions";

// const LOCAL = 'http://localhost:5001/timetrack-222415/us-central1'
/*
const handleAuth = function* handleAuth(args) {
  const { username, pw } = args.payload;
  try {
    yield put(authenticate());
    const ok = yield call(TwentyFourSeven.authenticate, username, pw);
    if (ok) {
      yield put(authenticateSuccess());

    } else {
      yield put(
        addGlobalAlert({
          severity: "error",
          title: "Authentication failure",
          message: 'There was an error when authenticating towards 24/7.',
        })
      );
      yield put(authenticateFailure(new Error('Authentication failure')));

    }
  } catch (error) {
    yield put(
      addGlobalAlert({
        severity: "error",
        title: "Authentication failure",
        message: error.toString(),
      })
    );
    yield put(authenticateFailure(error));
  }
};
*/
const handleCheckSession = function* handleCheckSession(args) {
  try {
    yield put(checkHasSession());
    const hasActiveSession = yield call(TwentyFourSeven.hasActiveSession);
    if (hasActiveSession) {
      yield put(checkHasSessionSuccess());
    } else {
      throw new Error("No active session");
    }
  } catch (error) {
    yield put(checkHasSessionFailure(error));
  }
};

const authSaga = function* authSaga() {
  // yield takeLatest(WATCH_AUTHENTICATE, handleAuth);
};

const checkSessionSaga = function* authSaga() {
  yield takeLatest(WATCH_CHECK_HAS_SESSION, handleCheckSession);
};

export default function* () {
  yield all([authSaga(), checkSessionSaga()]);
}
