import React, { useEffect } from "react";
import * as constants from "../../../../redux/modules/ui/userOverview/projectsTable/constants";
import useProjectsMap from "../../../../hooks/useProjectsMap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../index";
import { State } from "../../../../redux/modules/ui/userOverview/projectsTable/types";
import useProjects from "../../../../hooks/useProjects";
import useUserProfile from "../../../../hooks/useUserProfile";
import useUserProfileMutation from "../../../../hooks/useUserProfileMutation";
import useUserProjects from "../../../../hooks/useUserProjects";

function useFilterDialog() {
  const dispatch = useDispatch();
  const state = useSelector(
    (state: RootState) => state.ui.userOverview.projectsTable
  );
  const {
    data: userProfile,
    isLoading: isLoadingUserProfile
  } = useUserProfile();
  const userProfileMutation = useUserProfileMutation();

  const projects = useUserProjects();
  const projectsMap = useProjectsMap(projects);

  useEffect(() => {
    if (userProfile && userProfile.projectsTableFilters) {
      dispatch({
        type: constants.UPDATE,
        payload: {
          projects: userProfile.projectsTableFilters?.projects
        }
      });
    }
  }, [isLoadingUserProfile, projects]);

  const open = () => {
    dispatch({ type: constants.OPEN });
  };
  const close = () => {
    if (state.isClosable) {
      dispatch({ type: constants.CLOSE });
    }
  };

  const setIsClosable = (isClosable: boolean) => {
    dispatch({ type: constants.SET_IS_CLOSEABLE, payload: isClosable });
  };

  const update = (filters: Partial<State["filters"]>) => {
    dispatch({
      type: constants.UPDATE,
      payload: { ...state.filters, ...filters }
    });

    if (filters.projects) {
      const projectsTableFilters = {
        ...userProfile?.projectsTableFilters,
        projects: filters.projects
      };

      userProfileMutation.mutate({
        projectsTableFilters
      });
    }
  };

  // const confirmClose = () => {
  //   close();
  // };
  //
  // const handleCancelClose = () => {
  //   setShowUnsavedConfirmation(false);
  // };
  //
  // const handleOnClose = e => {
  //   close();
  //   if (!isClosable) return;
  //   if (hasUnsaved) {
  //     setShowUnsavedConfirmation(true);
  //   } else {
  //     handleConfirmClose();
  //   }
  // };

  return {
    open,
    close,
    update,
    projectsMap,
    setIsClosable,
    ...state
  };
}

export default useFilterDialog;
