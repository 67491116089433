import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@superprofit/core-react-components/atoms/Table";
import UserRateItem from "./UserRateItem";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import TableLoader from "../../../../components/molecules/TableLoader";
import { useParams } from "react-router-dom";
import useProject from "../../../../hooks/useProject";
import useProjectMutation from "../../../../hooks/useProjectMutation";
import { useTranslation } from "react-i18next";

const getProp = (data, orderBy) => {
  switch (orderBy) {
    case "displayName":
      return data.displayName;
    default:
      return data[orderBy] || 0;
  }
};

const descendingComparator = (a, b, orderBy) => {
  if (getProp(b, orderBy) < getProp(a, orderBy)) return -1;
  if (getProp(b, orderBy) > getProp(a, orderBy)) return 1;
  return 0;
};

const getComparator = (orderDir, orderBy) => {
  return orderDir === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export default ({ users }) => {
  const { id: projectId } = useParams();
  const { data: project, isLoading: loading } = useProject(projectId);
  const mutation = useProjectMutation(projectId);
  const { t } = useTranslation();

  const [orderBy, setOrderBy] = useState("hours");
  const [orderDir, setOrderDir] = useState("desc");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const sortedUserList = useMemo(() => {
    const sorted = users.slice();
    sorted.sort(getComparator(orderDir, orderBy));
    return sorted;
  }, [users, orderBy, orderDir]);

  const pageList = useMemo(() => {
    return rowsPerPage > 0
      ? sortedUserList.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : sortedUserList;
  }, [sortedUserList, page, rowsPerPage]);

  const count = users.length;

  const handleOnChangeRate = (userId, value) => {
    const parsedValue = +Number.parseFloat(value).toFixed(2);

    if (project.userSalaryRate[userId] !== parsedValue)
      mutation.mutate({
        userSalaryRate: {
          ...project.userSalaryRate,
          [userId]: parsedValue
        }
      });
  };

  const handleOnSort = (event, property) => {
    const isAsc = orderBy === property && orderDir === "asc";
    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table stickyHeader aria-label="Salary rates by team member">
        <TableHead>
          <TableRow>
            <TableCell style={{ maxWidth: 80, width: 80 }} />

            <TableCell
              sortDirection={orderBy === "displayName" ? orderDir : false}
            >
              <TableSortLabel
                active={orderBy === "displayName"}
                direction={orderBy === "displayName" ? orderDir : "asc"}
                onClick={e => handleOnSort(e, "displayName")}
              >
                {t("common.name")}
              </TableSortLabel>
            </TableCell>

            <TableCell align="right" style={{ minWidth: 150 }}>
              {t("common.salaryRate")}
            </TableCell>
          </TableRow>
        </TableHead>

        {!loading && project?.salaryEnabled && (
          <TableBody>
            {pageList.map(user => (
              <UserRateItem
                key={user.id}
                user={user}
                project={project}
                onChangeRate={handleOnChangeRate}
              />
            ))}
          </TableBody>
        )}

        {!loading && (
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage={t("common.rowsPerPage")}
                rowsPerPageOptions={[
                  10,
                  25,
                  50,
                  { label: t("common.all"), value: -1 }
                ]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}

        {loading && (
          <TableLoader cols={["circle", "text", "text", "textRight", "text"]} />
        )}
      </Table>
    </TableContainer>
  );
};
