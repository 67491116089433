import { useMutation, useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import Entry from "../models/Entry";
import TimesheetEntry from "../models/Entry";
import useISOWeekBookendsAsDate from "../pages/protected/timesheet/hooks/useISOWeekBookendsAsDate";

export const getKey = (
  workspace: string,
  id: string,
  year: number,
  week: number
) => [workspace, id, Entry.collectionName, year, week];

const useUserTimesheet = ({
  id,
  year,
  week
}: {
  id: string;
  year: number; // isoWeekYear
  week: number; // isoWeek
}) => {
  const workspace = useWorkspace();

  const [startOfWeekDate, endOfWeekDate] = useISOWeekBookendsAsDate({
    year,
    week
  });
  const get = () =>
    Entry.getUserEntriesRange(workspace, id, startOfWeekDate, endOfWeekDate);

  const query = useQuery(getKey(workspace, id, year, week), get, {
    enabled: !!id && !!year && !!week
  });
  return query;
};

export default useUserTimesheet;
