import { SET_LOADING, SET_PROJECT } from "./constants";

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const setProject = project => ({
  type: SET_PROJECT,
  payload: {
    project
  }
});
