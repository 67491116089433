import { GET, GET_FAILURE, GET_SUCCESS } from "./constants";

const initialState = {
  stats: {
    raw: [],
    monthBefore: []
  },
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        stats: {
          ...state.stats,
          ...payload
        }
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
