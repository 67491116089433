import { HIDE_AUTH_DIALOG, SHOW_AUTH_DIALOG } from "./constants";

export const showAuthDialog = () => ({
  type: SHOW_AUTH_DIALOG,
  payload: {}
});

export const hideAuthDialog = () => ({
  type: HIDE_AUTH_DIALOG,
  payload: {}
});
