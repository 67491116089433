import React, { forwardRef } from "react";
import { StyledLabel } from "./label/Label.styles";

const Label = forwardRef(({ label, ...rest }, ref) => (
  <StyledLabel aria-label={label} {...rest} ref={ref}>
    {label}
  </StyledLabel>
));

Label.defaultProps = {
  variant: "filled",
  color: "success"
};

export default Label;
