import React, { forwardRef, ForwardRefExoticComponent } from "react";
import { FormControl, FormControlProps } from "@material-ui/core";

interface IProps extends FormControlProps {
  component?: string; //idk if used
}

const Component: ForwardRefExoticComponent<IProps> = forwardRef(
  (props, ref) => <FormControl {...props} ref={ref} />
);

export default Component;
