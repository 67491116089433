import { db } from "../firebase";

export default class XstatsInvoicesCreated {
  static collectionName = "xstats_invoices_created";
  static docName = "xstats_invoices_created";

  static converter = {
    toFirestore(customer) {
      return customer.data();
    },
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      return new XstatsInvoicesCreated({ ...data, id: snapshot.id });
    }
  };

  constructor({ createdAt, updatedAt, numberOfInvoices }) {
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.numberOfInvoices = numberOfInvoices;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  data() {
    return {
      createdAt: this.createdAt,
      numberOfInvoices: this.numberOfInvoices,
      updatedAt: this.updatedAt
    };
  }

  static get = async workspace => {
    const snapshot = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(XstatsInvoicesCreated.collectionName)
      .doc(XstatsInvoicesCreated.docName)
      .withConverter(XstatsInvoicesCreated.converter)
      .get();
    return snapshot.data();
  };
}
