export const supportedCountries = [
  {
    abbr: "US",
    name: "United States"
  },
  {
    abbr: "CA",
    name: "Canada"
  },
  {
    abbr: "MX",
    name: "Mexico"
  },
  {
    abbr: "NO",
    name: "Norway"
  },
  {
    abbr: "SE",
    name: "Sweden"
  },
  {
    abbr: "UK",
    name: "United Kingdom"
  },
  {
    abbr: "DK",
    name: "Denmark"
  },
  {
    abbr: "DE",
    name: "Germany"
  },
  {
    abbr: "FR",
    name: "France"
  },
  {
    abbr: "ES",
    name: "Spain"
  }
];
