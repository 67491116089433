import React, { useMemo } from "react";
import Hint from "../../../../../components/atoms/Hint";
import Important from "../../../../../components/atoms/Important";
import Grid from "@material-ui/core/Grid";
import { LoggedItem, StyledLogged } from "./Logged.styles";
import Skeleton from "@material-ui/lab/Skeleton";
import DurationFormat from "../../../../../components/atoms/DurationFormat";
import NumberFormat from "react-number-format";
import {
  getHoursByCustomer,
  getMoneyByCustomer
} from "../../../../../utils/CustomerStats";

import useTheme from "@material-ui/core/styles/useTheme";
import TrendIndicator from "../../../../../components/atoms/TrendIndicator";
import { useTranslation } from "react-i18next";

export default ({
  loading,
  projects,
  customer,
  stats,
  statsBefore,
  ...rest
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const projectsById = projects.reduce(
    (prev, next) => ({
      ...prev,
      [next.id]: next
    }),
    {}
  );

  const { money, hours } = useMemo(() => {
    if (loading || !customer || !stats) return {};

    const monthHours = getHoursByCustomer(stats, projectsById);
    const monthMoney = getMoneyByCustomer(stats, projectsById);

    return {
      money: monthMoney[customer.id],
      hours: monthHours[customer.id]
    };
  }, [customer, projectsById, stats, loading]);

  const { moneyPrev, hoursPrev } = useMemo(() => {
    if (loading || !customer || !statsBefore) return {};

    const monthHours = getHoursByCustomer(statsBefore, projectsById);

    const monthMoney = getMoneyByCustomer(statsBefore, projectsById);

    return {
      moneyPrev: monthMoney[customer.id],
      hoursPrev: monthHours[customer.id]
    };
  }, [customer, projectsById, statsBefore, loading]);

  const [hoursTrend, moneyTrend] = useMemo(() => {
    if (loading) return [null, null];
    const hoursTrend = Math.round(
      (((hours || 0) - (hoursPrev || 0)) / (hoursPrev || 0)) * 100
    );
    const moneyTrend = Math.round(
      (((money || 0) - (moneyPrev || 0)) / (moneyPrev || 0)) * 100
    );

    return [hoursTrend, moneyTrend];
  }, [moneyPrev, hoursPrev, money, hours, loading]);

  return (
    <StyledLogged>
      <Grid
        container
        spacing={3}
        component="div"
        alignItems="center"
        justify="space-around"
      >
        <Grid item xs={12} sm={8} lg={6} component="div">
          <Hint>{t("common.logged")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              <LoggedItem>
                <DurationFormat value={hours} />
                <TrendIndicator changePercentage={hoursTrend} />
              </LoggedItem>
              <em>|</em>
              <LoggedItem>
                <NumberFormat
                  displayType="text"
                  thousandSeparator
                  isNumericString
                  decimalScale={2}
                  value={money || 0}
                />
                <TrendIndicator changePercentage={moneyTrend} />
              </LoggedItem>
            </Important>
          )}
        </Grid>
      </Grid>
    </StyledLogged>
  );
};
