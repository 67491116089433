import {
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  GET,
  GET_FAILURE,
  GET_SUCCESS
} from "./constants";

const initialState = {
  data: null,
  loading: true,
  saving: false,
  saveError: null,
  fetchError: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.userProfile
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false,
        fetchError: payload.error
      };
    case UPDATE:
      return {
        ...state,
        saving: true
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        saving: false,
        data: payload.userProfile
      };
    case UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        saveError: payload.error
      };

    default:
      return state;
  }
};
