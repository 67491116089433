import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FormDialog from "@superprofit/core-react-components/molecules/FormDialog";
import {
  hideGoalDialog,
  updateGoalDialogData
} from "../../../../redux/modules/ui/companySettings/goalDialog/actions";
import {
  FormControl,
  InputLabel,
  Select,
  TextField
} from "@superprofit/core-react-components/atoms";
import MenuItem from "@superprofit/core-react-components/atoms/MenuItem";
import { years } from "@superprofit/time-util";
import { monthNames } from "../../../../helpers";
import moment from "moment";
import { watchSaveCompanySettings } from "../../../../redux/modules/api/companySettings/actions";
import CompanySettingGoal from "../../../../models/CompanySettingGoal";

const yearOptions = years(moment().year() + 5, 10);
const monthOptions = monthNames();

export default ({ ...rest }) => {
  const dispatch = useDispatch();

  const { open, saving, goal, updates } = useSelector(
    state => state.ui.companySettings.goalDialog
  );

  const isSaving = useSelector(
    state => state.ui.companySettings.goalDialog.saving
  );

  const handleOnClose = e => {
    if (isSaving) return;
    dispatch(hideGoalDialog());
  };

  const handleOnChange = (name, value) => {
    dispatch(updateGoalDialogData({ [name]: parseFloat(value) }));
  };

  const handleOnSave = () => {
    dispatch(
      watchSaveCompanySettings([
        new CompanySettingGoal({
          ...goal,
          ...updates
        })
      ])
    );
  };

  return (
    <FormDialog
      {...rest}
      saveActionTitle="Save"
      onSave={handleOnSave}
      open={open}
      onClose={handleOnClose}
      title={goal.id ? "Update goal" : "Add goal"}
      inProgress={saving}
    >
      <FormControl component="fieldset" margin="normal">
        <InputLabel variant="standard" shrink={false} id="year-label">
          Year
        </InputLabel>

        <Select
          variant="filled"
          labelId="year-label"
          id="year-input"
          defaultValue={goal.year}
          onBlur={e => handleOnChange("year", e.target.value)}
          required
        >
          {yearOptions.map(y => (
            <MenuItem key={y} value={y}>
              {y}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl component="fieldset" margin="normal">
        <InputLabel variant="standard" shrink={false} id="month-label">
          Month
        </InputLabel>

        <Select
          variant="filled"
          labelId="month-label"
          id="month-input"
          defaultValue={goal.month}
          onBlur={e => handleOnChange("month", e.target.value)}
        >
          <MenuItem key={"all-months"} value={"*"}>
            Every month
          </MenuItem>

          {monthOptions.map((m, idx) => (
            <MenuItem key={m} value={idx + 1}>
              {m}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />

      <TextField
        required
        label="Billable hours"
        helperText="Billable hours goal"
        type="number"
        variant="filled"
        defaultValue={goal.billable}
        onBlur={e => handleOnChange("billable", e.target.value)}
      />

      <TextField
        required
        label="Average rate"
        helperText="Average rate for billable hours"
        type="number"
        variant="filled"
        defaultValue={goal.avgBillableRate}
        onBlur={e => handleOnChange("avgBillableRate", e.target.value)}
      />

      <TextField
        label="Non-Billable hours"
        helperText="Non billable hours target"
        required
        type="number"
        variant="filled"
        defaultValue={goal.nonBillable}
        onBlur={e => handleOnChange("nonBillable", e.target.value)}
      />
    </FormDialog>
  );
};
