import { darken, lighten } from "../shared/colorUtils";

export const colors = {
  primary: "#7376F0",
  secondary: "#2CCBA8",
  alert: "#F05A5D",
  grey: "#F3F5F9",
  font: "#4B4D4F",
  fontLight: lighten("#4B4D4F", 0.3),
  fontDark: darken("#4B4D4F", 0.3),
  background: "#F1F3F7",
  highlight: "#FFFFFF"
} as const;
