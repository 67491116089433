import {
  HIDE_DELETE_USERS_DIALOG,
  RESET_DELETE_USERS_DIALOG,
  SHOW_DELETE_USERS_DIALOG
} from "./constants";
import { DeleteUsersDialogState, DeleteUsersDialogAction } from "./types";
import { createReducer } from "typesafe-actions";

const initialState: DeleteUsersDialogState = {
  open: false,
  users: []
};

const reducer = createReducer<DeleteUsersDialogState, DeleteUsersDialogAction>(
  initialState
)
  .handleType(HIDE_DELETE_USERS_DIALOG, state => ({ ...state, open: false }))
  .handleType(SHOW_DELETE_USERS_DIALOG, (state, action) => ({
    ...initialState,
    open: true,
    users: action.payload.users
  }))
  .handleType(RESET_DELETE_USERS_DIALOG, () => initialState);

export default reducer;
