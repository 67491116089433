import styled from "styled-components";

export const CommentForm = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  > * {
    margin-right: ${props => props.theme.spacing(0.5)}px;
    margin-left: ${props => props.theme.spacing(0.5)}px;

    &.comment-input {
      flex: 1;
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
