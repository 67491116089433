import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { colors } from "./colors";
import shadows from "../shared/shadows";
import typography from "../shared/typography";
import { hexToRgba, lighten } from "../shared/colorUtils";

export default createMuiTheme({
  shadows,
  typography,
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
    tertiary: {
      main: colors.tertiary
    },
    error: {
      light: lighten(colors.alert, 0.8),
      main: colors.alert
    },
    success: {
      light: lighten(colors.success, 0.8),
      main: colors.success
    },
    warning: {
      light: lighten(colors.warning, 0.8),
      main: colors.warning
    },
    grey: {
      main: colors.grey
    } as any, // Workaround for ts error. Dont know if this is even used. Grey does not support PaletteColorOptions
    background: {
      default: colors.background,
      paper: colors.white,
      light: lighten(colors.background, 0.5)
    },
    text: {
      primary: colors.fontDark,
      secondary: colors.fontLight,
      tertiary: colors.fontLight,
      disabled: colors.fontLight,
      hint: colors.fontExtraLight,
      white: colors.white
    },
    action: {
      active: hexToRgba(colors.font, 1),
      selected: hexToRgba(colors.primary, 0.08)
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  }
});
