import {
  DESELECT_ALL,
  DESELECT_CUSTOMER,
  SELECT_ALL,
  SELECT_CUSTOMER
} from "./constants";

export const selectCustomer = customer => ({
  type: SELECT_CUSTOMER,
  payload: { customer }
});

export const deselectCustomer = customer => ({
  type: DESELECT_CUSTOMER,
  payload: { customer }
});

export const selectAllCustomers = customers => ({
  type: SELECT_ALL,
  payload: { customers }
});

export const deselectAllCustomers = () => ({
  type: DESELECT_ALL,
  payload: {}
});
