import {
  Button,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@superprofit/core-react-components/atoms";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../index";
import {stageArchiving, watchArchive} from "../../../redux/modules/ui/userGroups/page/actions";
// import { watchArchiveUsers } from "../../../redux/modules/ui/users/table/actions";
// import {hideArchiveUsersDialog, resetArchiveUsersDialog} from '../../../redux/modules/ui/users/deleteUsersDialog/actions';

export default () => {
  const dispatch = useDispatch();
  const { stagedArchiving } = useSelector(
    (state: RootState) => state.ui.userGroups.page || {}
  );
  const handleArchive = () => {
    dispatch(watchArchive());
  };

  const handleCancel = () => {dispatch(stageArchiving([]))};
  const handleReset = () => {dispatch(stageArchiving([]))};

  return (
    <Dialog
      onExited={handleReset}
      open={stagedArchiving.length > 0}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Archive user groups?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Archiving {stagedArchiving.length} user group(s)
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" autoFocus>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleArchive}
          color="alert"
          autoFocus
        >
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};
