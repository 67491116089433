import { moment } from "@superprofit/time-util";


export const sortEntriesInProjectByDate = (e) => {
  const copy = e.slice();
  return copy.sort((a, b) => {
    // if (a is less than b by some ordering criterion) {
    if (a.year < b.year || (a.year === b.year && a.dayOfYear < b.dayOfYear)) {
      return -1;
    }
    // if (a is greater than b by the ordering criterion) {
    if (a.year > b.year || (a.year === b.year && a.dayOfYear > b.dayOfYear)) {
      return 1;
    }
    // a must be equal to b
    return 0;
  });
};

export const sortEntries = entries =>
  entries.sort((a, b) => {
    if (a.year < b.year) return -1;
    if (a.year > b.year) return 1;
    if (a.dayOfYear < b.dayOfYear) return -1;
    if (a.dayOfYear > b.dayOfYear) return 1;
    return 0;
  });

export const removeEntriesWithZeroHours = entries => entries.filter(e => e.hours > 0)

export const splitEntriesByLength = entries => {
  const MAX_ENTRIES_ON_PAGE = 25;
  if (entries.length > MAX_ENTRIES_ON_PAGE) {
    //max entries on a page
    const arr = [];
    for (let i = 0; i < entries.length; i += MAX_ENTRIES_ON_PAGE) {
      arr.push(entries.slice(i, i + MAX_ENTRIES_ON_PAGE));
    }
    return arr;
  }
  return [entries];
};

export const splitEntriesByMonth = sortedByDate => {
  const months = [];
  const toOrdinal = ({ year, dayOfYear }) =>
    (year * 1000 + dayOfYear).toString();
  const monthsNotEqual = (curr, prev) =>
    curr &&
    prev &&
    moment(toOrdinal(curr)).month() !== moment(toOrdinal(prev)).month();
  for (let i = 0; i < sortedByDate.length; i++) {
    const current = sortedByDate[i];
    const prev = sortedByDate[i - 1];
    if (months.length === 0) months.push([current]);
    else if (monthsNotEqual(current, prev)) months.push([current]);
    else months[months.length - 1].push(current);
  }
  return months.reduce(
    (acc, curr) => [...acc, ...splitEntriesByLength(curr)],
    []
  );
};
