import React, { useState } from "react";
import {
  Grid,
  Card,
  Link,
  Button,
  Typography,
  TextField,
  Divider
} from "@superprofit/core-react-components/atoms";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  ContainerGrid,
  WorkspaceButton,
  AnotherWorkspaceCard,
  ImgGrid,
  LinkWrapper
} from "./appLanding/AppLanding.styles";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { watchSignOut } from "@superprofit/core-redux-saga-modules/Auth/actions";
import { push } from "connected-react-router";
import IconButton from "@superprofit/core-react-components/atoms/IconButton";
import TranslateIcon from "@material-ui/icons/Translate";
import { useAuth } from "../../hooks/useAuth";

export default () => {
  const { t, i18n } = useTranslation();
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const { signOut } = useAuth();
  const handleTextChange = e => {
    setText(e.currentTarget.value);
  };

  const userClaims = useSelector(
    ({
      api: {
        auth: { idTokenResult }
      }
    }) => idTokenResult?.claims
  );
  const workspaces = Object.keys(userClaims?.workspaces || {}).sort((a, b) => {
    const comp = a.localeCompare(b);
    if (comp < 0) {
      return -1;
    }
    if (comp > 0) {
      return 1;
    }
    // a must be equal to b
    return 0;
  });
  if (workspaces.length === 1) {
    window.location.href = `/app/${workspaces[0]}`;
    return null;
  }

  const handleJoin = () => {
    window.location.href = `/app/${text}`;
  };

  const handleCreateWorkspace = () => {
    dispatch(push("/signup"));
  };

  const handleLanguageChange = () => {
    const currentLanguage = i18n.language;
    if (currentLanguage === "en") {
      i18n.changeLanguage("nb-NO").catch(console.error);
    } else {
      i18n.changeLanguage("en").catch(console.error);
    }
  };

  return (
    <ContainerGrid container spacing={2} direction="column" justify="center">
      <Grid item container xs={12} justify="flex-end">
        <Grid item>
          <IconButton color="primary" onClick={handleLanguageChange}>
            <TranslateIcon />
          </IconButton>
        </Grid>
      </Grid>
      <ImgGrid item>
        <img src="/timet_logo_3.png" />
      </ImgGrid>
      <Grid item xs={12}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12}>
            {/*<Typography variant="h1">Welcome back!</Typography>*/}
            <Typography variant="h1">
              {t("pages.appLanding.welcome")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/*<Typography>Choose a workspace below to start working.</Typography>*/}
            <Typography>{t("pages.appLanding.choose")}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Card elevation={4}>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ padding: "1rem" }}>
                <Trans
                  i18nKey="pages.appLanding.templates.workspacesFor"
                  values={{ email: userClaims?.email }}
                >
                  Workspaces for <b>{{ email: userClaims?.email }}</b>
                </Trans>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            {workspaces.map(w => (
              <>
                <Grid key={w} item xs={12}>
                  <WorkspaceButton
                    fullWidth
                    component={Link}
                    href={`${window.location.protocol}//${window.location.host}/app/${w}`}
                  >
                    {w} <ArrowForwardIcon />
                  </WorkspaceButton>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            ))}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <AnotherWorkspaceCard variant="outlined">
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={12} sm={6}>
              {/*<Typography>Need more workspaces?</Typography>*/}
              <Typography>
                {workspaces?.length > 0
                  ? t("pages.appLanding.needMore")
                  : t("pages.appLanding.createFirstWorkspace")}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleCreateWorkspace}
              >
                {workspaces.length > 0
                  ? t("pages.appLanding.createAnother")
                  : t("common.create")}
                {/*Create another workspace*/}
              </Button>
            </Grid>
          </Grid>
        </AnotherWorkspaceCard>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" direction="column" spacing={2}>
          <Grid item>
            <Typography>
              {t("pages.appLanding.cantFind")}
              {/*Can't find workspace? Try joining one by typing in the name below.*/}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "15rem" }}
              value={text}
              onEnter={handleJoin}
              placeholder={t("pages.appLanding.exactPlaceholder")}
              // placeholder="Exact workspace name"
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item>
            <LinkWrapper disabled={!text}>
              <Link href={`/app/${text}`}>{t("common.join")}</Link>
            </LinkWrapper>
          </Grid>
          <br />
          <br />
          <br />
          <Grid item>
            <Link component="button" onClick={signOut}>
              {t("common.logout")}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </ContainerGrid>
  );
};
