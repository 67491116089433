import basis from "./basis/sagas";
import project from "./project/sagas";
import customer from "./customer/sagas";
import snackbar from "./snackbar/sagas";
import burndown from "./burndown/sagas";
import plan from "./plan/sagas";
import invoice from "./invoice/sagas";
import addUserDialog from "./addUserDialog/sagas";

export default [
  basis(),
  project(),
  customer(),
  snackbar(),
  burndown(),
  plan(),
  invoice(),
  addUserDialog()
];
