import React, { forwardRef, Fragment, useMemo, useState } from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import DurationFormat from "../../../../components/atoms/DurationFormat";
import NumberFormat from "react-number-format";
import IconButton from "../../../../components/atoms/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import WeekDistribution from "../../../../components/atoms/WeekDistribution";
import ProjectUserItem from "./ProjectUserItem";
import ProjectAvatar from "../../../../components/molecules/ProjectAvatar";
import ProjectLink from "../../../../components/molecules/ProjectLink";
import ProgressBar from "../../../../components/atoms/ProgressBar";
import Label from "@superprofit/core-react-components/atoms/Label";
import { useDispatch, useSelector } from "react-redux";
import CheckableAvatar from "@superprofit/core-react-components/molecules/CheckableAvatar";
import { selectProject } from "../../../../redux/modules/ui/report/projects/actions";
import ConnectedCurrencyFormat from "../../../../components/organisms/ConnectedCurrencyFormat";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../index";
import Project from "../../../../models/Project";
import SyncIcon from "@material-ui/icons/ImportExport";
import Tooltip from "../../../../components/atoms/Tooltip";
import Entry from "../../../../models/Entry";

interface IProjectItemProps {
  data: any;
  weekNumbers: any;
  usersById: any;
  columns: any;
  onSyncProject?: (project: Project) => void;
}

const ProjectItem = forwardRef((props: IProjectItemProps, ref) => {
  const { data, weekNumbers, usersById, columns, ...rest } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    project,
    hours,
    relativeHours,
    byUserWeek,
    byWeek,
    money,
    userHours
  } = data;

  const isSelected = useSelector(
    (state: RootState) =>
      state.ui.report.projects.selected.findIndex(
        (u: Project) => u.id === project.id
      ) > -1
  );

  const handleChecked = () => {
    dispatch(selectProject(project));
  };
  const weekDistribution = useMemo(
    () =>
      weekNumbers.map((w: number) => ({
        tooltip: `${t("common.week")} ${w}`,
        value: byWeek[w] || 0,
        color: "secondary"
      })),
    [weekNumbers, byWeek]
  );

  const usersList = useMemo(() => {
    const max = Math.max.apply(Math, Object.values(userHours));

    const res = Object.keys(userHours).map(p => ({
      user: usersById[p],
      percentage: ((userHours[p] || 0) / hours) * 100,
      hours: userHours[p],
      byWeek: byUserWeek[p] || {},
      relativePercentage: ((userHours[p] || 0) / max) * 100
    }));
    res.sort((a, b) => {
      if ((b.hours || 0) < (a.hours || 0)) return -1;
      if ((b.hours || 0) > (a.hours || 0)) return 1;
      return 0;
    });
    return res;
  }, [userHours]);

  const handleOnSyncProject = () => {
    if (props.onSyncProject) {
      props.onSyncProject(project);
    }
  };

  return (
    <Fragment>
      <TableRow {...rest} ref={ref} key="main">
        {columns.expand && (
          <TableCell style={{ maxWidth: 80, width: 80 }}>
            {usersList && usersList.length ? (
              <IconButton
                aria-label={t("common.expandRow")}
                size="medium"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : null}
          </TableCell>
        )}

        {columns.avatar && (
          <TableCell style={{ maxWidth: 80, width: 80 }}>
            <CheckableAvatar checked={isSelected} onChange={handleChecked}>
              <ProjectAvatar project={project} />
            </CheckableAvatar>
          </TableCell>
        )}

        <TableCell>
          <ProjectLink name={project.name} id={project.id} />
        </TableCell>

        {columns.label && (
          <TableCell>
            <div>
              {project.billable ? (
                <Label
                  variant="filled"
                  color="success"
                  label={t("common.billable")}
                />
              ) : (
                <Label
                  variant="filled"
                  color="warning"
                  label={t("common.nonBillable")}
                />
              )}
            </div>
          </TableCell>
        )}

        {columns.weekDistribution && (
          <TableCell align="right">
            <WeekDistribution weeks={weekDistribution} />
          </TableCell>
        )}

        {columns.hours && (
          <TableCell align="right">
            <DurationFormat value={hours} component="b" />
          </TableCell>
        )}

        {columns.relativeShare && (
          <TableCell align="right">
            <span style={{ width: 100, minWidth: 100 }} />
          </TableCell>
        )}

        {columns.relative && (
          <TableCell align="right">
            <ProgressBar
              progress={relativeHours}
              color="primary"
              align="left"
            />
          </TableCell>
        )}

        {columns.billable && (
          <TableCell align="right">
            <b>
              <ConnectedCurrencyFormat number={money} />
            </b>
          </TableCell>
        )}
        {columns.sync && (
          <TableCell align="right">
            <Tooltip title={t("integrations.ework.syncHours")} arrow>
              <IconButton onClick={handleOnSyncProject}>
                <SyncIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
      {columns.expand &&
        open &&
        usersList.map(data => (
          <ProjectUserItem
            key={`${data.user.email}-${project.id}`}
            user={data.user}
            project={project}
            hours={data.hours}
            percentage={data.relativePercentage}
            byWeek={data.byWeek}
            weekNumbers={weekNumbers}
            columns={columns}
          />
        ))}
    </Fragment>
  );
});

export default ProjectItem;
