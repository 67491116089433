// import {
//   GET,
//   GET_FAILURE,
//   GET_SUCCESS,
//   SAVE,
//   SAVE_FAILURE,
//   SAVE_SUCCESS,
//   DELETE,
//   DELETE_FAILURE,
//   DELETE_SUCCESS,
//   EXIST,
//   EXIST_FAILURE,
//   EXIST_SUCCESS,
//   WATCH_GET,
//   WATCH_SAVE,
//   WATCH_DELETE,
//   WATCH_EXIST
// } from "./constants";
//
// export const get = () => ({
//   type: GET,
//   payload: {}
// });
//
// export const getSuccess = (list, pagination) => ({
//   type: GET_SUCCESS,
//   payload: { list, pagination }
// });
//
// export const getFailure = error => ({
//   type: GET_FAILURE,
//   payload: { error }
// });
//
// export const save = () => ({
//   type: SAVE,
//   payload: {}
// });
//
// export const saveSuccess = (list, pagination) => ({
//   type: SAVE_SUCCESS,
//   payload: { list, pagination }
// });
//
// export const saveFailure = error => ({
//   type: SAVE_FAILURE,
//   payload: { error }
// });
//
// export const exist = () => ({
//   type: EXIST,
//   payload: {}
// });
//
// export const existSuccess = (workspace, exist) => ({
//   type: EXIST_SUCCESS,
//   payload: { workspace, exist }
// });
//
// export const existFailure = error => ({
//   type: EXIST_FAILURE,
//   payload: { error }
// });
//
// export const deleteWorkspace = () => ({
//   type: DELETE,
//   payload: {}
// });
//
// export const deleteSuccess = (list, pagination) => ({
//   type: DELETE_SUCCESS,
//   payload: { list, pagination }
// });
//
// export const deleteFailure = error => ({
//   type: DELETE_FAILURE,
//   payload: { error }
// });
//
// export const watchWorkspaceExist = workspace => ({
//   type: WATCH_EXIST,
//   payload: { workspace }
// });
// export const watchGetWorkspaces = () => ({
//   type: WATCH_GET,
//   payload: {}
// });
//
// export const watchDeleteWorkspace = ({ id }) => ({
//   type: WATCH_DELETE,
//   payload: { id }
// });
//
// export const watchSaveWorkspace = ({ name, displayName }) => ({
//   type: WATCH_SAVE,
//   payload: { name, displayName }
// });

import constants from './constants';
import createActions from '../Helpers/moduleFactory/actions';

export default createActions(constants);
