import { all, put, take, takeLatest, select } from "redux-saga/effects";
import {
  GET_SUCCESS as GET_CUSTOMERS_SUCCESS,
  SAVE_SUCCESS
} from "../../../api/customers/constants";
import { GET_SUCCESS as GET_PROJECTS_SUCCESS } from "../../../api/projects/constants";
import {
  WATCH_GET_CUSTOMER_TABLE,
  WATCH_REFRESH_CUSTOMERS_TABLE
} from "./constants";
import { watchGetProjects } from "../../../api/projects/actions";
import { watchGetCustomers } from "../../../api/customers/actions";
import { setData, setLoading, watchRefreshCustomersTable } from "./actions";

export const getCustomersState = state => {
  return {
    projects: state.api.projects.list,
    customers: state.api.customers.list
  };
};

export const handleRefreshTable = function*(args) {
  try {
    const { projects, customers } = yield select(getCustomersState);

    const projectsPerCustomer = projects.reduce((prev, next) => {
      if (next.customer) {
        return {
          ...prev,
          [next.customer]: (prev[next.customer] || []).concat(next)
        };
      }
      return prev;
    }, {});

    const enrichedCustomers = customers.map(customer =>
      customer
        .clone()
        .setData({ projects: projectsPerCustomer[customer.id] || [] })
    );

    yield put(setData(enrichedCustomers));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const fetchCustomerTable = function*(args) {
  try {
    yield put(setLoading(true));
    yield put(watchGetCustomers());
    yield put(watchGetProjects());

    const [customers, projects] = yield all([
      take(GET_CUSTOMERS_SUCCESS),
      take(GET_PROJECTS_SUCCESS)
    ]);

    yield put(watchRefreshCustomersTable());
  } catch (e) {
    console.warn(e);
  }
};

export const handleDispatchRefresh = function*(args) {
  try {
    yield put(watchRefreshCustomersTable());
  } catch (e) {
    console.warn(e);
  }
};

export const fetchCustomerTableSaga = function*() {
  yield takeLatest(WATCH_GET_CUSTOMER_TABLE, fetchCustomerTable);
};

export const handleDispatchRefreshSaga = function*() {
  yield takeLatest([SAVE_SUCCESS], handleDispatchRefresh);
};

//
export const handleRefreshSaga = function*() {
  yield takeLatest(WATCH_REFRESH_CUSTOMERS_TABLE, handleRefreshTable);
};

export default function*() {
  yield all([
    fetchCustomerTableSaga(),
    handleDispatchRefreshSaga(),
    handleRefreshSaga()
  ]);
}
