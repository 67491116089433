import React from "react";
import {
  Plan,
  PlanActions,
  PlanCard,
  PlanContent,
  PlanCost,
  PlanDetails,
  PlanIcon,
  PlanName,
  PlanUsers
} from "./Subscription.styles";
import { Box, useTheme } from "@material-ui/core";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import Button from "@superprofit/core-react-components/atoms/Button";

export default ({ plan, active }) => {
  const theme = useTheme();
  return (
    <PlanCard active={active}>
      <Plan plan={plan}>
        <PlanIcon plan={plan} />
        <PlanName>
          <Box {...theme.typography.h2}>{plan.toUpperCase()}</Box>
        </PlanName>
      </Plan>
      <PlanContent>
        <PlanCost>$ 29.00</PlanCost>
        <PlanUsers>
          <Box {...theme.typography.h2}>
            {"25 users / per month".toUpperCase()}
          </Box>
        </PlanUsers>

        <Divider />

        <PlanDetails>
          <Box {...theme.typography.body1}>{`
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            Nulla orci diam, tristique sit amet nisi sit amet, efficitur 
            pellentesque arcu. `}</Box>
        </PlanDetails>

        <Divider />

        <PlanActions>
          <Button disabled={active} color="primary" variant="contained">
            {active ? "Active" : "Select"}
          </Button>
        </PlanActions>
      </PlanContent>
    </PlanCard>
  );
};
