import Isemail from "isemail";
import { QueryBuilder, Timestamp } from "@superprofit/core-firestore-models";
import { db, firebase } from "../firebase";
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;
type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

export interface IUser {
  id: string;
  displayName: string | null;
  email: string;
  emailVerified: boolean;
  picture: string | null;
  tags: string[];
  userId: string | null;
  capacity: null | number;
  planned: null | number;
  projectSortOrder: null | string[];
  metadata: { [key: string]: any };
  createdBy: string | null;
  createdAt: typeof Timestamp;
  updatedBy: string | null;
  updatedAt: typeof Timestamp;
}

export type IUserCreatePayload = Omit<
  IUser,
  "id" | "updatedBy" | "updatedAt" | "createdBy" | "createdAt"
>;

export default class User implements IUser {
  id: string;
  displayName: string | null;
  email: string;
  emailVerified: boolean;
  picture: string | null;
  tags: string[];
  userId: string | null;
  capacity: null | number;
  planned: null | number;
  projectSortOrder: null | { [key: string]: number };
  metadata: { employeeNumber?: number; [key: string]: any };
  createdBy: string | null;
  createdAt: typeof Timestamp;
  updatedBy: string | null;
  updatedAt: typeof Timestamp;

  static validateEmail(email: string) {
    const isemail = Isemail.validate(email);
    if (!isemail) {
      throw new Error(`[Users] ${email} is not a valid email`);
    }
    return isemail;
  }

  static converter = {
    toFirestore(user: User) {
      return user.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IUser;
      return new User({ ...data, id: snapshot.id });
    }
  };

  static collectionName = "users";

  static createId(workspace: string) {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(User.collectionName)
      .doc().id;
  }
  constructor({
    id,
    displayName,
    email,
    emailVerified,
    picture,
    tags,
    userId,
    planned,
    projectSortOrder,
    metadata,
    capacity,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt
  }: IUser) {
    this.id = id;
    this.displayName = displayName || null;
    this.email = email;
    this.emailVerified = emailVerified;
    this.picture = picture || null;
    this.tags = tags || [];
    this.userId = userId || null;

    this.createdBy = createdBy || null;
    this.createdAt = createdAt || null;
    this.updatedBy = updatedBy || null;
    this.updatedAt = updatedAt || null;
    this.capacity = capacity === null || capacity === undefined ? 40 : capacity;
    this.planned = planned || null;
    this.projectSortOrder = projectSortOrder || null;
    this.metadata = metadata || {};
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IUser>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      displayName: this.displayName || "",
      email: this.email,
      emailVerified: !!this.emailVerified,
      picture: this.picture || "",
      tags: this.tags || [],
      userId: this.userId || "",
      createdBy: this.createdBy || null,
      createdAt: this.createdAt || null,
      updatedBy: this.updatedBy || null,
      updatedAt: this.updatedAt || null,
      capacity: this.capacity || null,
      planned: this.planned || null,
      metadata: this.metadata || {},
      projectSortOrder: this.projectSortOrder || {}
    };
  }

  getCapacity() {
    return this.capacity;
  }

  getOnboarding(type: string) {
    return (this?.metadata?.onboarding || {})[type];
  }

  async updateOnboarding(workspace: string, type: string, data: any) {
    const metadata = {
      ...this?.metadata,
      onboarding: {
        ...this?.metadata?.onboarding,
        [type]: data
      }
    };

    return User.update(workspace, this.id, this, { metadata });
  }

  static get = async (workspace: string, user: string) => {
    const doc = await db
      .collection("workspaces")
      .doc(workspace)
      .collection("users")
      .doc(user)
      .withConverter(User.converter)
      .get();
    return doc.data();
  };

  static getAll = (workspace: string, usersIds: string[]) => {
    return Promise.all(usersIds.map(user => User.get(workspace, user)));
  };

  static list = async (workspace: string, query?: any) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(User.collectionName),
      query
    )
      .withConverter(User.converter)
      .get();

    return snapshot.docs.map((doc: QueryDocumentSnapshot) => doc.data());
  };

  static create = async (
    workspace: string,
    user: string,
    data: IUserCreatePayload
  ) => {
    if (data.email && User.validateEmail(data.email)) {
      const newUser = new User({
        ...data,
        id: data.email,
        updatedAt: Timestamp.now(),
        createdAt: Timestamp.now(),
        updatedBy: user,
        createdBy: user
      });

      await db
        .collection("workspaces")
        .doc(workspace)
        .collection(User.collectionName)
        .doc(newUser.id)
        .withConverter(User.converter)
        .set(newUser, { merge: true });

      return newUser;
    }
  };

  static update = async (
    workspace: string,
    byUser: string,
    user: User,
    updates: Partial<IUser>
  ) => {
    if (updates.email) User.validateEmail(updates.email);
    const updatedUser = user.clone();
    updatedUser.setData({
      ...updates,
      metadata: {
        ...user.metadata,
        ...(updates.metadata || {})
      },
      updatedAt: Timestamp.now(),
      updatedBy: byUser
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(User.collectionName)
      .doc(updatedUser.id)
      .withConverter(User.converter)
      .set(updatedUser, { merge: true });

    return updatedUser;
  };

  static patch = async (
    workspace: string,
    user: string,
    id: string,
    updates: Partial<IUser>
  ) => {
    const patchData = {
      updatedAt: Timestamp.now(),
      updatedBy: user,
      ...updates
    };

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(User.collectionName)
      .doc(id)
      .set(patchData, { merge: true });

    return { patchData };
  };
}
