import React, { forwardRef, ForwardRefExoticComponent } from "react";
import { StyledChip } from "./chip/Chip.styles";
import { ChipProps } from "@material-ui/core";

const Chip: ForwardRefExoticComponent<ChipProps> = forwardRef((props, ref) => (
  <StyledChip {...props} ref={ref} />
));

Chip.defaultProps = {
  color: "default"
};

export default Chip;
