import React, { useMemo } from "react";
import { PageContent } from "../../../components/atoms/Page";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import UserRateTable from "./rates/UserRateTable";
import UserSalaryRateTable from "./salaryRates/UserRateTable";
import BudgetSettingsCard from "./budget/BudgetSettingsCard";
import Card from "@superprofit/core-react-components/atoms/Card";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import { useFeatures } from "../../../helpers";
import { useParams } from "react-router-dom";
import useProject from "../../../hooks/useProject";
import { useTranslation } from "react-i18next";
import User from "../../../models/User";
import UserGroup from "../../../models/UserGroup";
import { RootState } from "../../../index";
import Typography from "../../../components/atoms/Typography";

export default () => {
  const {
    loading: usersLoading,
    data: { users, userGroups = [] }
  } = useSelector((state: RootState) => state.ui.projectOverview.basis);
  const { t } = useTranslation();
  const { id: projectId } = useParams<{ id: string }>();
  const { data: project, isLoading: projectLoading } = useProject(projectId);

  const { projectSalaryRate } = useFeatures();

  const filteredUsers = useMemo(() => {
    if (usersLoading || projectLoading || !project || !users) return [];
    return users.filter((u: User) => {
      const projectUserGroups = (project?.userGroups || []).map(id => {
        const gr = userGroups.find((ug: UserGroup) => ug.id === id);
        if (gr?.users?.includes("all")) {
          return { ...gr, users: users.map((u: User) => u.email) };
        }
        return gr;
      });
      return (
        project.team.indexOf(u.email) > -1 ||
        projectUserGroups.some(g => g?.users && g.users.indexOf(u.email) > -1)
      );
    });
  }, [users, userGroups, project]);
  const isLoading = usersLoading || projectLoading;

  return (
    <PageContent>
      <Grid container component="div" style={{ marginBottom: 4 }} spacing={3}>
        <Grid container item xs={12} component="div" justify="flex-start">
          <BudgetSettingsCard />
        </Grid>

        <Grid container item xs={12} component="div" justify="flex-start">
          <Grid item xs={12} component="div">
            <Card>
              <CardHeader title={t("common.billing")} />
              <Divider />
              <UserRateTable users={filteredUsers} />
            </Card>
          </Grid>
        </Grid>
        {projectSalaryRate && (
          <Grid container item xs={12} component="div" justify="flex-start">
            <Grid item xs={12} component="div">
              <Card>
                <CardHeader
                  subheaderTypographyProps={{
                    style: { textTransform: "none" }
                  }}
                  title={t("common.salary")}
                  subheader={t("pages.projects.settings.salaryTableSubheader")}
                />

                <Divider />
                {project?.salaryEnabled ? (
                  <UserSalaryRateTable users={filteredUsers} />
                ) : (
                  <Typography variant="h3" style={{ padding: 16 }}>
                    {t("pages.projects.settings.salaryDisabledHelperText")}
                  </Typography>
                )}
              </Card>
            </Grid>
          </Grid>
        )}
      </Grid>
    </PageContent>
  );
};
