import { useMemo, useState } from "react";
import {
  getHoursByProject,
  getHoursPerWeekByProject,
  getRelativeHoursPercentageByProject,
  getSalaryByProject,
  getUserHoursByProject,
  getUserHoursPerWeekByProject
} from "../../../../utils/ProjectStats";
import useFilteredProjects from "./useFilteredProjects";
import useFilteredStats from "./useFilteredStats";
import Project from "../../../../models/Project";
import useUsersMap from "../../../../hooks/useUsersMap";
import useProjectsMap from "../../../../hooks/useProjectsMap";
import {
  getHoursByUser,
  getHoursPerWeekByUser,
  getMoneyByUser,
  getProjectHoursByUser,
  getProjectHoursPerWeekByUser,
  getProjectMoneyByUser,
  getRelativeHoursPercentageByUser
} from "../../../../utils/UserStats";
import {
  Filters,
  IUserTable,
  OrderBy,
  OrderDir,
  TransformedProject,
  TransformedUser
} from "./types";
import XstatsUserProjectHoursByWeek from "../../../../models/XstatsUserProjectHoursByWeek";
import useFilteredUsers from "./useFilteredUsers";
import User from "../../../../models/User";
import useUsersList from "./useUsersList";

const getProp = (data: TransformedUser, orderBy: OrderBy) => {
  switch (orderBy) {
    case "displayName":
      return data.user.displayName || 0;
    default:
      return data[orderBy] || 0;
  }
};

const descendingComparator = (
  a: TransformedUser,
  b: TransformedUser,
  orderBy: OrderBy
) => {
  if (getProp(b, orderBy) < getProp(a, orderBy)) return -1;
  if (getProp(b, orderBy) > getProp(a, orderBy)) return 1;
  return 0;
};

const getComparator = (orderDir: OrderDir, orderBy: OrderBy) => {
  return orderDir === "desc"
    ? (a: TransformedUser, b: TransformedUser) =>
        descendingComparator(a, b, orderBy)
    : (a: TransformedUser, b: TransformedUser) =>
        -descendingComparator(a, b, orderBy);
};

const useSortedUsersList = ({
  usersList
}: {
  usersList: TransformedUser[];
}) => {
  const [orderBy, setOrderBy] = useState<OrderBy>("hours");
  const [orderDir, setOrderDir] = useState<OrderDir>("desc");
  const sortedUsersList = useMemo(() => {
    const sorted = usersList.slice();
    sorted.sort(getComparator(orderDir, orderBy));
    return sorted;
  }, [usersList, orderBy, orderDir]);
  return { sortedUsersList, orderBy, orderDir, setOrderDir, setOrderBy };
};

export default useSortedUsersList;
