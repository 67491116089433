export default ({
  customerId,
  subscriptionId,
  priceId,
  apiBase,
  quantity,
  token,
  coupon,
}) => {
  return fetch(`${apiBase}/retrieveUpcomingInvoice`, {
    method: "post",
    headers: {
      "Content-type": "application/json",
      authorization: token,
    },
    body: JSON.stringify({
      customerId,
      subscriptionId,
      priceId,
      quantity,
      coupon,
    }),
  }).then((response) => {
    return response.json();
  });
};
