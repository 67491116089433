import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./withAppBar/withAppBar.styles";
import { Theme, withTheme } from "@material-ui/core";

export function withAppBar(Component: React.FC<any>) {
  return function(AppBar: React.FC<any>) {
    function WrapperComponent({
      theme,
      title,
      ...rest
    }: {
      theme: Theme;
      title: string;
      [key: string]: any;
    }) {
      const BREAKPOINT = theme.breakpoints.values.md;
      const [stickySidebar, setStickySidebar] = useState(
        window.innerWidth > BREAKPOINT
      );

      const handleResize = useCallback(() => {
        if (stickySidebar && window.innerWidth < BREAKPOINT) {
          setStickySidebar(false);
        } else if (!stickySidebar && window.innerWidth > BREAKPOINT) {
          setStickySidebar(true);
        }
      }, [BREAKPOINT, stickySidebar]);

      useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, [handleResize]);

      return (
        <Container>
          <AppBar
            stickySidebar={stickySidebar}
            position="sticky"
            title={title}
          />
          <div>
            <Component {...rest} />
          </div>
        </Container>
      );
    }
    return withTheme(WrapperComponent) as React.FC<any>;
  };
}

// export default (Component: ) => AppBar =>
//   withTheme(({ theme, title, ...rest }) => {
//     const BREAKPOINT = theme.breakpoints.values.md;
//     const [stickySidebar, setStickySidebar] = useState(
//       window.innerWidth > BREAKPOINT
//     );
//
//     const handleResize = useCallback(() => {
//       if (stickySidebar && window.innerWidth < BREAKPOINT) {
//         setStickySidebar(false);
//       } else if (!stickySidebar && window.innerWidth > BREAKPOINT) {
//         setStickySidebar(true);
//       }
//     }, [BREAKPOINT, stickySidebar]);
//
//     useEffect(() => {
//       window.addEventListener("resize", handleResize);
//       return () => {
//         window.removeEventListener("resize", handleResize);
//       };
//     }, [handleResize]);
//
//     return (
//       <Container>
//         <AppBar
//           stickySidebar={stickySidebar}
//           position="sticky"
//           title={title}
//         />
//         <div>
//           <Component {...rest} />
//         </div>
//       </Container>
//     );
//   });
