import {
  HIDE_ASSIGN_PROJECTS_DIALOG,
  SHOW_ASSIGN_PROJECTS_DIALOG,
  SET_SAVING,
  UPDATE_ASSIGN_PROJECTS_DIALOG_DATA,
  WATCH_SAVE_ASSIGNED_PROJECTS
} from "./constants";

import { action } from "typesafe-actions";
import User from "../../../../../models/User";

export const showAssignProjectsDialog = (users: User[]) =>
  action(SHOW_ASSIGN_PROJECTS_DIALOG, { users });

export const hideAssignProjectsDialog = () =>
  action(HIDE_ASSIGN_PROJECTS_DIALOG);

export const updateAssignProjectsDialogData = (updates: any) =>
  action(UPDATE_ASSIGN_PROJECTS_DIALOG_DATA, { updates });

export const watchSaveAssignProjects = () =>
  action(WATCH_SAVE_ASSIGNED_PROJECTS);

export const setSaving = (saving: boolean) => action(SET_SAVING, { saving });
