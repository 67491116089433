import { HIDE, SHOW, UPDATE } from "./constants";
import { moment } from "@superprofit/time-util";
import {TEMPLATES} from '../../../../../components/organisms/PDFRenderer';

const initialState = {
  fromDate: moment().startOf('month'),
  toDate: moment().endOf('month'),
  users: [],
  projects: [],
  entries: [],
  templateId: TEMPLATES.BY_USER_AND_PROJECT,
  open: false,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW:
      return {
        ...state,
        users: action.payload?.users,
        projects: action.payload?.projects,
        open: true
      };
    case HIDE:
      return initialState;

    case UPDATE:
      return { ...state, ...action.payload?.updates };

    default:
      return state;
  }
};
