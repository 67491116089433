import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideAuthDialog } from "../../../../redux/modules/ui/integrations/twentyfourseven/auth/actions";
import Dialog from "@superprofit/core-react-components/atoms/Dialog";
import DialogTitle from "@superprofit/core-react-components/atoms/DialogTitle";
import DialogContent from "@superprofit/core-react-components/atoms/DialogContent";
import DialogContentText from "@superprofit/core-react-components/atoms/DialogContentText";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import DialogActions from "@superprofit/core-react-components/atoms/DialogActions";
import Button from "@superprofit/core-react-components/atoms/Button";
import { watchAuthenticate } from "../../../../redux/modules/api/integrations/twentyfourseven/auth/actions";

export default () => {
  const dispatch = useDispatch();

  const usernameRef = useRef(null);
  const pwRef = useRef(null);
  const open = useSelector(
    state => state.ui.integrations.twentyFourSeven.auth.dialog.open
  );

  const handleOnClose = () => {
    dispatch(hideAuthDialog());
  };

  const handleOnAuthenticate = () => {
    dispatch(watchAuthenticate(usernameRef.current.value, pwRef.current.value));
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Sign in</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Sign in to 24sevenoffice to continue export.
        </DialogContentText>

        <br />
        <br />

        <TextField
          inputRef={usernameRef}
          autoFocus
          id="name"
          label="Username"
          type="username"
          variant="filled"
          fullWidth
        />

        <br />
        <br />

        <TextField
          inputRef={pwRef}
          autoFocus
          id="pw"
          variant="filled"
          label="Password"
          type="password"
          fullWidth
        />

        <br />
        <br />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleOnAuthenticate}
          variant="contained"
          color="primary"
        >
          Sign in
        </Button>
      </DialogActions>
    </Dialog>
  );
};
