import React, { useEffect } from "react";
import { Page, Button } from "@superprofit/core-react-components/atoms";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import Plans from "./subscription/Plans";
import Stripe from "@superprofit/core-react-components/organisms/Stripe";
import workspaceActions from "@superprofit/core-redux-saga-modules/Workspaces/actions";
import { actions as stripeAccountDetails } from "../../redux/modules/api/stripeAccountDetails";
import { publicKey, cloudFunctionsBaseUrl } from "../../config/stripe";
import { logEvent, ANALYTICS_EVENT } from "../../firebase";
import PerSeatProduct from "./subscription/PerSeatProduct";
import { useTranslation } from "react-i18next";

export default () => {
  const dispatch = useDispatch();
  const accountDetails = useSelector(
    state => state.api.stripeAccountDetails.entry
  );
  const idToken = useSelector(state => state.api.auth.idTokenResult.token);
  const workspaceName = useSelector(state => state.api.auth?.activeWorkspaceId);
  const [product, setProduct] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [loadingSession, setLoadingSession] = React.useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    // dispatch(workspaceActions.watchListen());
  }, [dispatch]);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      // dispatch({ type: actionTypes.toggleLoadingProducts });
      const productsResponse = await fetch(
        `${cloudFunctionsBaseUrl}/getProducts?expand=data.prices`
      );
      if (!productsResponse.ok) {
        console.error("Err loading products");
      } else {
        const json = await productsResponse.json();
        const p = json.find(pr => pr.name === "Timet Unlimited" && pr.active);
        const priceResponse = await fetch(
          `${cloudFunctionsBaseUrl}/getPrices?expand=data.tiers&product=${p.id}`
        );
        if (!priceResponse.ok) {
          setLoading(false);
          console.error("Err loading prices");
          return;
        }
        p.prices = await priceResponse.json();
        setProduct(p);
        setLoading(false);
      }
    };
    getData().catch(e => console.error(e));

    dispatch(stripeAccountDetails.watchGetEntry());
  }, [dispatch]);
  useEffect(() => {
    if (workspaceName) {
      logEvent(ANALYTICS_EVENT.PURCHASE_VISITED, { workspaceName });
    }
  }, [workspaceName]);

  const onProductChange = async (seats, priceId) => {
    try {
      setLoadingSession(true);
      const res = await fetch(
        `${cloudFunctionsBaseUrl}/createSubscriptionCheckoutSession`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            authorization: idToken
          },
          body: JSON.stringify({
            customerId: accountDetails?.customerId,
            quantity: seats,
            priceId,
            workspaceId: workspaceName,
            successUrl: `${window.location.origin}/app/${workspaceName}/subscription/success`,
            cancelUrl: `${window.location.origin}/app/${workspaceName}/purchase`
            // returnUrl: `${window.location.origin}/app/${workspaceName}`
          })
        }
      );

      if (res.ok) {
        const json = await res.json();
        if (json?.url) {
          window.location.href = json.url;
        }
      } else {
        setLoadingSession(false);
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Page context={t("common.management")} title="Purchase access">
      <Grid container>
        <Grid item>
          <PerSeatProduct
            loadingSession={loadingSession}
            loading={loading}
            product={product}
            onPurchase={onProductChange}
          />
        </Grid>
      </Grid>
      {/*<Stripe*/}
      {/*  customerId={accountDetails?.customerId}*/}
      {/*  filterProductsFunc={filter}*/}
      {/*  baseUrl={cloudFunctionsBaseUrl}*/}
      {/*  stripeKey={publicKey}*/}
      {/*  firebaseIdToken={idToken}*/}
      {/*/>*/}
    </Page>
  );
};
