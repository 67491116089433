import { useMutation, useQueryClient } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import Project, { IProject } from "../models/Project";
import useUser from "./useUser";
import { getKey as getProjectsKey } from "./useProjects";
import { getKey as getProjectKey } from "./useProject";

const useProjectCreateMutation = () => {
  const workspace = useWorkspace();
  const queryClient = useQueryClient();

  const user = useUser();
  const create = (data: Partial<IProject>) => {
    const payload = {
      name: data.name || "",
      billable: data.billable || false,
      billableRate: data.billableRate || null,
      billableCurrency: data.billableCurrency || "nok",
      salaryEnabled: data.salaryEnabled || false,
      salaryRate: data.salaryRate || null,
      salaryCurrency: data.salaryCurrency || "usd",
      budget: data.budget || null,
      userSalaryRate: data.userSalaryRate || null,
      userBillableRate: data.userBillableRate || null,
      globalDefaultProvisionOfProjectBillableRateEnabled:
        data.globalDefaultProvisionOfProjectBillableRateEnabled || false,
      team: data.team || [],
      userGroups: data.userGroups || [],
      tags: data.tags || [],
      color: data.color || "#4422ff",
      customer: data.customer || "",
      managerName: data.managerName || null,
      managerEmail: data.managerEmail || null,
      twntyFourSevenId: data.twntyFourSevenId || null,
      externalReference: data.externalReference || null,
      planned: data.planned || null,
      archived: data.archived || false
    };
    return Project.create(workspace, user.email, payload);
  };

  return useMutation(create, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        getProjectsKey(workspace),
        (projects: Project[] = []) => {
          const idx = projects.findIndex(p => p.id === data.id);
          if (idx > -1) return projects.map(p => (p.id === data.id ? data : p));
          return [data].concat(projects);
        }
      );
      queryClient.setQueryData(getProjectKey(workspace, data.id), () => data);
    }
  });
};

export default useProjectCreateMutation;
