import {
  HIDE_FILTER_DIALOG,
  SHOW_FILTER_DIALOG,
  UPDATE_FILTER_DIALOG_DATA
} from "./constants";

const initialState = {
  open: false,
  filters: {
    projects: [],
    users: [],
    customers: [],
    type: "All"
  },
  saving: false,
  hasUnsaved: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_FILTER_DIALOG:
      return {
        ...state,
        filters: {
          ...initialState.filters,
          ...action.payload.filters
        },
        open: true
      };

    case HIDE_FILTER_DIALOG:
      return {
        ...state,
        open: false,
        hasUnsaved: false
      };

    case UPDATE_FILTER_DIALOG_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload.updates
        },
        hasUnsaved: true
      };

    default:
      return state;
  }
};
