import {
  BATCH_SAVE,
  BATCH_SAVE_FAILURE,
  BATCH_SAVE_SUCCESS,
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS
} from "./constants";

const initialState = {
  list: [],
  loading: false,
  saving: false,
  error: null,
  savingIdMap: {},
  savedIdMap: {},
  errorIdMap: {}
};

const concatEntries = (stateEntries, updatedEntries) => {
  const map = {};
  stateEntries.forEach(e => (map[e.id] = e));
  updatedEntries.forEach(e => (map[e.id] = e));
  return Object.values(map);
};

export default (state = initialState, { type, payload = {} }) => {
  const { entry, error, entries } = payload;

  switch (type) {
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        list: entries
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: error
      };

    case SAVE:
      return {
        ...state,
        savingIdMap: { ...state.savingIdMap, [entry.id]: true }
      };

    case SAVE_SUCCESS: {
      return {
        ...state,
        list: concatEntries(state.list, [entry]),
        savingIdMap: { ...state.savingIdMap, [entry.id]: false },
        savedIdMap: { ...state.savedIdMap, [entry.id]: true },
        errorIdMap: { ...state.errorIdMap, [entry.id]: undefined }
      };
    }

    case SAVE_FAILURE:
      return {
        ...state,
        savingIdMap: { ...state.savingIdMap, [entry.id]: false },
        savedIdMap: { ...state.savedIdMap, [entry.id]: false },
        errorIdMap: { ...state.errorIdMap, [entry.id]: error }
      };

    case BATCH_SAVE:
      return {
        ...state,
        savingIdMap: entries.reduce(
          (prev, next) => ({ ...prev, [next.id]: true }),
          state.savingIdMap
        )
      };

    case BATCH_SAVE_SUCCESS: {
      return {
        ...state,
        list: concatEntries(state.list, entries),
        savedIdMap: entries.reduce(
          (prev, next) => ({ ...prev, [next.id]: true }),
          state.savedIdMap
        ),
        savingIdMap: entries.reduce(
          (prev, next) => ({ ...prev, [next.id]: false }),
          state.savingIdMap
        ),
        errorIdMap: entries.reduce(
          (prev, next) => ({ ...prev, [next.id]: false }),
          state.errorIdMap
        )
      };
    }

    case BATCH_SAVE_FAILURE:
      return {
        ...state,
        errorIdMap: entries.reduce(
          (prev, next) => ({ ...prev, [next.id]: true }),
          state.errorIdMap
        ),
        savingIdMap: entries.reduce(
          (prev, next) => ({ ...prev, [next.id]: false }),
          state.savingIdMap
        ),
        savedIdMap: entries.reduce(
          (prev, next) => ({ ...prev, [next.id]: false }),
          state.savedIdMap
        )
      };

    default:
      return state;
  }
};
