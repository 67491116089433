import { HIDE, SHOW, UPDATE, INIT, DOWNLOAD } from "./constants";

export const show = ({ users = [], projects = [] }) => ({
  type: SHOW,
  payload: { users, projects }
});

export const download = ({ linkContainer }) => ({
  type: DOWNLOAD,
  payload: { linkContainer }
});

export const hide = () => ({
  type: HIDE,
  payload: {}
});

export const init = () => ({
  type: INIT,
  payload: {}
});

export const update = updates => ({
  type: UPDATE,
  payload: { updates }
});
