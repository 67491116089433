import {
  SET_LOADING,
  WATCH_REFRESH_PROJECTS_TABLE,
  UPDATE_PROJECTS_SEARCH,
  SET_PROJECTS_TABLE,
  WATCH_GET_PROJECTS
} from "./constants";

export const setProjectsTable = list => ({
  type: SET_PROJECTS_TABLE,
  payload: { list }
});

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const updateProjectsSearch = searchTerm => ({
  type: UPDATE_PROJECTS_SEARCH,
  payload: { searchTerm }
});

export const watchGetProjects = () => ({
  type: WATCH_GET_PROJECTS
});

export const watchRefreshProjectsTable = () => ({
  type: WATCH_REFRESH_PROJECTS_TABLE
});
