import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@superprofit/core-react-components/atoms/Table";
import UserRateItem from "./UserRateItem";
import { watchSaveProject } from "../../../../redux/modules/api/projects/actions";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import TableLoader from "../../../../components/molecules/TableLoader";
import { useTranslation } from "react-i18next";
import User from "../../../../models/User";
import Project from "../../../../models/Project";
import { useParams } from "react-router-dom";
import useProject from "../../../../hooks/useProject";
import useProjectMutation from "../../../../hooks/useProjectMutation";
import useGlobalSnackbar from "../../../../hooks/useGlobalSnackbar";
import { promiseDelay } from "../../../../helpers";

type Direction = "asc" | "desc";

type Property = "displayName";
const getProp = (data: User, orderBy: Property) => {
  switch (orderBy) {
    case "displayName":
      return data.displayName || "";
    default:
      return data[orderBy] || 0;
  }
};

const descendingComparator = (a: User, b: User, orderBy: Property) => {
  if (getProp(b, orderBy) < getProp(a, orderBy)) return -1;
  if (getProp(b, orderBy) > getProp(a, orderBy)) return 1;
  return 0;
};

const getComparator = (orderDir: Direction, orderBy: Property) => {
  return orderDir === "desc"
    ? (a: User, b: User) => descendingComparator(a, b, orderBy)
    : (a: User, b: User) => -descendingComparator(a, b, orderBy);
};

interface UserRateTableProps {
  users: User[];
}

const UserRateTable: React.FC<UserRateTableProps> = ({ users }) => {
  const { id: projectId } = useParams<{ id: string }>();
  const { data: project, isLoading: loading } = useProject(projectId);
  const mutation = useProjectMutation(projectId);
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState<Property>("displayName");
  const [orderDir, setOrderDir] = useState<Direction>("desc");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const { update: updateSnackbar } = useGlobalSnackbar();

  const sortedUserList = useMemo(() => {
    const sorted = users.slice();
    sorted.sort(getComparator(orderDir, orderBy));
    return sorted;
  }, [users, orderBy, orderDir]);

  const pageList = useMemo(() => {
    return rowsPerPage > 0
      ? sortedUserList.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : sortedUserList;
  }, [sortedUserList, page, rowsPerPage]);

  const count = users.length;

  const handleOnChangeRate = (userId: string, value: string) => {
    const parsedValue = +Number.parseFloat(value).toFixed(2);

    if (Number.isNaN(parsedValue)) return;

    if (project?.userBillableRate?.[userId] !== parsedValue) {
      updateSnackbar({
        open: true,
        message: `${t("common.saving")}...   😎`,
        alert: { severity: "info" }
      });
      console.log("User billable rates", project?.userBillableRate);
      console.log("ParsedVlaue", parsedValue);
      mutation.mutate(
        {
          userBillableRate: {
            ...project?.userBillableRate,
            [userId]: parsedValue
          }
        },
        {
          onSuccess: async () => {
            await promiseDelay(1000);
            updateSnackbar({
              open: true,
              message: `${t("pages.projects.settings.projectUpdated")}!   🎉`,
              alert: { severity: "success" }
            });
            await promiseDelay(2000);
            updateSnackbar({
              open: false
            });
          },
          onError: async (error: any) => {
            console.error(error);
            updateSnackbar({
              open: true,
              message: `${t("common.error")}! 😱 ${error}`,
              alert: { severity: "error" }
            });
            await promiseDelay(3000);
            updateSnackbar({
              open: false
            });
          },
          onSettled: () => {}
        }
      );
    }
  };

  const handleOnSort = (event: any, property: Property) => {
    const isAsc = orderBy === property && orderDir === "asc";
    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table stickyHeader aria-label="Rates by team member">
        <TableHead>
          <TableRow>
            <TableCell style={{ maxWidth: 80, width: 80 }} />

            <TableCell
              sortDirection={orderBy === "displayName" ? orderDir : false}
            >
              <TableSortLabel
                active={orderBy === "displayName"}
                direction={orderBy === "displayName" ? orderDir : "asc"}
                onClick={e => handleOnSort(e, "displayName")}
              >
                {t("common.name")}
              </TableSortLabel>
            </TableCell>

            <TableCell align="right" style={{ minWidth: 150 }}>
              {t("common.billableRate")}
            </TableCell>
          </TableRow>
        </TableHead>

        {!loading && (
          <TableBody>
            {project &&
              pageList.map(user => (
                <UserRateItem
                  key={user.id}
                  user={user}
                  project={project}
                  onChangeRate={handleOnChangeRate}
                />
              ))}
          </TableBody>
        )}

        {!loading && (
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage={t("common.rowsPerPage")}
                rowsPerPageOptions={[
                  10,
                  25,
                  50,
                  { label: t("common.all"), value: -1 }
                ]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}

        {loading && (
          <TableLoader cols={["circle", "text", "text", "textRight", "text"]} />
        )}
      </Table>
    </TableContainer>
  );
};

export default UserRateTable;
