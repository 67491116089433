import React, { useEffect, useMemo } from "react";
import Page from "../../components/atoms/Page";
import Grid from "../../components/atoms/Grid";
import Button from "../../components/atoms/Button";
import Card from "../../components/atoms/Card";
import CardHeader from "../../components/atoms/CardHeader";
import Table, {
  TableContainer,
  TableHead,
  TableBody
} from "../../components/atoms/Table";
import { useDispatch, useSelector } from "react-redux";
import PeriodSelect from "../../components/molecules/PeriodSelect";
import { setPeriod } from "../../redux/modules/ui/userOverview/period/actions";
import { useTranslation } from "react-i18next";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { RootState } from "@superprofit/timet-react-client/src";
import Divider from "../../components/atoms/Divider";
import { TableCell } from "../../components/atoms/Table";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import useUnarchivedProjects from "@superprofit/timet-react-client/src/hooks/useUnarchivedProjects";
import useUserProjects from "@superprofit/timet-react-client/src/hooks/useUserProjects";
import { useEntriesWithDateRange } from "@superprofit/timet-react-client/src/hooks/useEntries";
import useUser from "@superprofit/timet-react-client/src/hooks/useUser";
import { useApprovalRecords } from "@superprofit/timet-react-client/src/hooks/useApprovalRecords";
import { ProjectItem } from "@superprofit/timet-react-client/src/pages/protected/userApproval/ProjectItem";

import Project from "@superprofit/timet-react-client/src/models/Project";
import { useApprovalRecordsCreateMutation } from "@superprofit/timet-react-client/src/hooks/useApprovalRecordsCreateMutation";
import useWorkspace from "@superprofit/timet-react-client/src/hooks/useWorkspace";
import useGlobalSnackbar from "@superprofit/timet-react-client/src/hooks/useGlobalSnackbar";
import { promiseDelay } from "@superprofit/timet-react-client/src/helpers";
import { CardActions } from "@material-ui/core";
import TableLoader from "../../components/molecules/TableLoader";

type OrderBy = "name" | "hours" | "status";

export const UserApproval = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = React.useState<string>("hours");
  const [orderDir, setOrderDir] = React.useState<"asc" | "desc">("desc");

  const [projectsSubmitting, setProjectsSubmitting] = React.useState<string[]>(
    []
  );
  const { i18n } = useTranslation();

  const period = useSelector(
    (state: RootState) => state.ui.userOverview.period
  );
  const { mutateAsync: create, isLoading } = useApprovalRecordsCreateMutation();
  const workspace = useWorkspace();
  const { update: updateSnackbar } = useGlobalSnackbar();
  const currentMonthStart = startOfMonth(
    new Date(period.year, period.month - 1)
  );
  const { email } = useUser();
  const currentMonthEnd = endOfMonth(currentMonthStart);
  const { data: approvalRecordsUnfiltered = [] } = useApprovalRecords({
    fromDate: currentMonthStart,
    toDate: currentMonthEnd,
    users: [email]
  });

  const approvalRecords = useMemo(
    () => approvalRecordsUnfiltered.filter(a => a.status !== "rejected"),
    [approvalRecordsUnfiltered]
  );

  const {
    data: allProjects,
    isLoading: projectsLoading
  } = useUnarchivedProjects();

  const userProjects = useUserProjects(allProjects, email);

  const { data: entries = [] } = useEntriesWithDateRange({
    fromDate: currentMonthStart,
    toDate: currentMonthEnd,
    user: email
  });

  const getApprovalRecordForProject = (projectId: string) => {
    const r = approvalRecords.filter(r => r.project === projectId);
    if (r.length === 0) return null;
    if (r.length > 1) {
      console.warn("Multiple approval records for project", projectId);
    }
    return r[0];
  };

  const submit = async (projects: Project[]) => {
    updateSnackbar({
      open: true,
      message: `${t("common.submitting")}...`,
      alert: { severity: "info" }
    });
    const result = await create(
      {
        workspaceId: workspace,
        userId: email,
        fromDate: format(currentMonthStart, "yyyy-MM-dd"),
        toDate: format(currentMonthEnd, "yyyy-MM-dd"),
        projects: projects.map(p => p.id as string),
        language: i18n.language
      },
      {
        onSuccess: async () => {
          updateSnackbar({
            open: true,
            message: `${t("common.submitted")}!   🎉`,
            alert: { severity: "success" }
          });
          await promiseDelay(2000);
          updateSnackbar({
            open: false
          });
        },
        onError: async e => {
          updateSnackbar({
            open: true,
            message: `${t("common.error")}! ${e} 🚨 `,
            alert: { severity: "error" }
          });
          await promiseDelay(2000);
          updateSnackbar({
            open: false
          });
        }
      }
    );
  };

  const handleOnSubmitOne = async (project: Project) => {
    setProjectsSubmitting([...projectsSubmitting, project.id as string]);
    await submit([project]).catch(console.error);
    setProjectsSubmitting(projectsSubmitting.filter(p => p !== project.id));
  };
  const submitAll = async () => {
    setProjectsSubmitting(userProjects.map(p => p.id as string));
    await submit(userProjects).catch(console.error);
    setProjectsSubmitting([]);
  };

  const handleOnChangePeriod = ({
    year,
    month
  }: {
    year: number;
    month: number;
  }) => {
    dispatch(setPeriod(year, month));
  };

  const handleOnSort = (e: React.MouseEvent, field: OrderBy) => {
    if (orderBy === field) {
      setOrderDir(orderDir === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrderDir("asc");
    }
  };

  const totalHoursByProjectMap = useMemo(() => {
    const map = new Map<string, number>();
    entries.forEach(e => {
      const current = map.get(e.project) || 0;
      map.set(e.project, current + e.hours);
    });
    return map;
  }, [entries]);

  const sortedProjects = useMemo(() => {
    const sorting = userProjects.slice();
    sorting.sort((a, b) => {
      if (orderBy === "name") {
        return a.name.localeCompare(b.name) * (orderDir === "asc" ? 1 : -1);
      }
      if (orderBy === "hours") {
        const aHours = totalHoursByProjectMap.get(a.id) || 0;
        const bHours = totalHoursByProjectMap.get(b.id) || 0;
        return (aHours - bHours) * (orderDir === "asc" ? 1 : -1);
      }
      return 0;
    });
    return sorting;
  }, [userProjects, orderBy, orderDir, totalHoursByProjectMap]);

  const loading = projectsLoading;

  return (
    <Page context={email} title={t("pages.userApprovals.title")}>
      <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={6} component="div">
          <PeriodSelect
            period={period}
            showNavigation
            onChange={handleOnChangePeriod}
            showWeek={false}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 20 }} component="div">
        <Grid item xs={12} component="div">
          <Card>
            <CardHeader
              title={t("pages.userApprovals.tableHeader")}
              action={
                <CardActions>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitAll}
                    disabled={
                      projectsSubmitting.length > 0 ||
                      loading ||
                      approvalRecords.length > 0
                    }
                  >
                    {t("common.submitAll")}
                  </Button>
                </CardActions>
              }
            />

            <Divider />

            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell style={{ maxWidth: 80, width: 80 }} />
                  <TableCell style={{ maxWidth: 80, width: 80 }} />
                  <TableCell
                    sortDirection={orderBy === "name" ? orderDir : false}
                  >
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={orderBy === "name" ? orderDir : "asc"}
                      onClick={e => handleOnSort(e, "name")}
                    >
                      {t("common.name")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>{t("common.status")}</TableSortLabel>
                  </TableCell>
                  <TableCell>{t("common.date")}</TableCell>
                  <TableCell
                    align="right"
                    sortDirection={orderBy === "hours" ? orderDir : false}
                  >
                    <TableSortLabel
                      active={orderBy === "hours"}
                      direction={orderBy === "hours" ? orderDir : "asc"}
                      onClick={e => handleOnSort(e, "hours")}
                    >
                      {t("common.hours")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right" />
                </TableHead>
                <TableBody>
                  {sortedProjects.map((project: Project) => (
                    <ProjectItem
                      isSubmitting={projectsSubmitting.includes(project.id)}
                      project={project}
                      entries={entries.filter(e => e.project === project.id)}
                      approvalRecord={getApprovalRecordForProject(project.id)}
                      startDate={currentMonthStart}
                      endDate={currentMonthEnd}
                      onSubmit={handleOnSubmitOne}
                    />
                  ))}
                </TableBody>
                {loading && (
                  <TableLoader
                    cols={[
                      "rect",
                      "square",
                      "text",
                      "text",
                      "text",
                      "textRight",
                      "textRight"
                    ]}
                  />
                )}
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};
