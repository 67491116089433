import {
  endOfISOWeek,
  getDayOfYear,
  setISOWeek,
  setISOWeekYear,
  startOfISOWeek as startOfWeekFn
} from "date-fns";

const useISOWeekBookendsAsDate = ({
  year,
  week
}: {
  year: number;
  week: number;
}) => {
  // Bookends:
  // verbINFORMAL
  // 3rd person present: bookends
  // be positioned at the end or on either side of (something).
  // "the narrative is bookended by a pair of incisive essays"
  const startOfWeekDate = (function() {
    let dt = new Date();
    dt = setISOWeekYear(dt, year);
    dt = setISOWeek(dt, week);
    return startOfWeekFn(dt);
  })();
  const endOfWeekDate = endOfISOWeek(startOfWeekDate);
  return [startOfWeekDate, endOfWeekDate];
};

export default useISOWeekBookendsAsDate;
