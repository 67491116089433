import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const createXLSXBlob = async report => {
  const csvData = report.renderAsXLSX(); // tableData.map(r => r.data.map(c => c.data));
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  // https://stackoverflow.com/questions/24395693/how-to-set-cell-width-when-export-xlsx-files-with-js-xlsx
  function fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({
      wch: Math.max(
        ...arrayOfArray.map(a2 => (a2[i] ? a2[i].toString().length : 0))
      )
    }));
  }
  const ws = XLSX.utils.aoa_to_sheet(csvData);
  ws["!cols"] = fitToColumn(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  return new Blob([excelBuffer], { type: fileType });
};

export const saveAsXLSX = async (report, filename) => {
  const blob = await createXLSXBlob(report);
  const fileExtension = ".xlsx";
  FileSaver.saveAs(blob, filename + fileExtension);
};
export const saveAsCsv = async (report, filename) => {
  const blob = await createXLSXBlob(report);
  const fileExtension = ".csv";
  FileSaver.saveAs(blob, filename + fileExtension);
};
