import React, { forwardRef, ForwardRefExoticComponent } from "react";
import Autocomplete from "./Autocomplete";
import Chip from "./Chip";
import TextField from "./TextField";
import { AutocompleteProps } from "@material-ui/lab";
import {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams
} from "@material-ui/lab/Autocomplete/Autocomplete";
import { useTranslation } from "react-i18next";
import useProjectsMap from "../../hooks/useProjectsMap";
import { Controller, ControllerProps } from "react-hook-form";
import Project from "../../models/Project";
import MultiSelectProjects from "../molecules/MultiSelectProjects";

type Option = { title: string };
type TagInputProps = Partial<AutocompleteProps<any, any, any, any>> & {
  label?: string;
  fullWidth?: boolean;
  variant?: "filled" | "outlined";
  placeholder?: string;
  helperText?: string;
  tags?: Option[];
};

const TagInput: ForwardRefExoticComponent<TagInputProps> = forwardRef(
  (
    { fullWidth = true, renderInput, options, variant = "filled", ...rest },
    ref
  ) => {
    // label={t("common.tags")}
    // placeholder="List of tags"
    const { t } = useTranslation();
    const label = rest.label || t("common.tags");
    const placeholder =
      rest.placeholder || t("components.molecules.tagInput.placeholder");
    const helperText =
      rest.helperText || t("components.molecules.tagInput.helperText");
    const tags = rest.tags || [];
    return (
      <Autocomplete
        ref={ref}
        options={tags}
        multiple
        freeSolo
        renderTags={(value: Option[], getTagProps: AutocompleteGetTagProps) =>
          value.map((option, index) => (
            <Chip label={option} color="primary" {...getTagProps({ index })} />
          ))
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            label={label}
            placeholder={placeholder}
            variant={variant}
            helperText={helperText}
            {...params}
            fullWidth={fullWidth}
          />
        )}
        {...rest}
      />
    );
  }
);

interface TagInputFormControllerProps<T> extends TagInputProps {
  ControllerProps: Omit<ControllerProps<T>, "render">;
}

export function withFormController<FieldValues>() {
  return (props: TagInputFormControllerProps<FieldValues>) => {
    const { ControllerProps, tags = [], ...other } = props;
    const { name = "tags", control } = ControllerProps;
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value = [], ref, onChange, ...rest } = field;
          return (
            <TagInput
              ref={ref}
              value={value}
              onChange={(
                e: React.ChangeEvent<HTMLElement>,
                selected: string[]
              ) => {
                onChange(selected.map(id => id).filter(Boolean) as string[]);
              }}
              tags={tags}
              {...other}
              {...rest}
            />
          );
        }}
      />
    );
  };
}

export default TagInput;
