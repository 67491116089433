const cancelSubscription = ({ apiBase, subscriptionId, cancelAtPeriodEnd = false}) => {
  return fetch(`${apiBase}/cancelSubscription`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      subscriptionId,
      cancelAtPeriodEnd
    }),
  })
    .then(response => {
      return response.json();
    });
};

export default cancelSubscription;