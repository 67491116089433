import styled, { css } from "styled-components";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import theme from "styled-theming";

const TableHeadBg = theme("mode", {
  light: css`
    background: ${props => props.theme.palette.background.light};
  `,
  dark: css``
});

// alternative for delete
const RowSelection = theme("mode", {
  light: css`
    background-color: ${props => props.theme.palette.action.selected};
  `,
  dark: css``
});

export const StyledTableHead = styled(TableHead)`
  ${TableHeadBg};
`;

export const StyledTableRow = styled(TableRow)`
  &.Mui-selected,
  &.Mui-selected:hover {
    ${RowSelection};
  }

  &:hover {
    cursor: ${props => props.onClick ? 'pointer' : 'arrow'};
  }
`;
