export default (Model, prefix = 'GENERATED_', name = '') => ({
  GET: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/GET`,
  GET_SUCCESS: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/GET_SUCCESS`,
  GET_FAILURE: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/GET_FAILED`,

  GET_ENTRY: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/GET_ENTRY`,
  GET_ENTRY_SUCCESS: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/GET_ENTRY_SUCCESS`,
  GET_ENTRY_FAILURE: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/GET_ENTRY_FAILED`,

  CREATE: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/CREATE`,
  CREATE_SUCCESS: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/CREATE_SUCCESS`,
  CREATE_FAILURE: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/CREATE_FAILED`,

  DELETE: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/DELETE`,
  DELETE_SUCCESS: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/DELETE_SUCCESS`,
  DELETE_FAILURE: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/DELETE_FAILED`,

  UPDATE: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/UPDATE`,
  UPDATE_SUCCESS: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/UPDATE_SUCCESS`,
  UPDATE_FAILURE: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/UPDATE_FAILED`,

  EXISTS: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/EXISTS`,
  EXISTS_SUCCESS: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/EXISTS_SUCCESS`,
  EXISTS_FAILURE: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/EXISTS_FAILED`,


  LISTEN_SUCCESS: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/LISTEN_SUCCESS`,
  LISTEN_ALL_SUCCESS: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/LISTEN_ALL_SUCCESS`,

  WATCH_GET: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/WATCH_GET`,

  WATCH_GET_ENTRY: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/WATCH_GET_ENTRY`,

  WATCH_EXISTS: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/WATCH_EXISTS`,

  WATCH_CREATE: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/WATCH_CREATE`,
  WATCH_DELETE: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/WATCH_DELETE`,
  WATCH_UPDATE: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/WATCH_UPDATE`,

  WATCH_LISTEN: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/WATCH_LISTEN`,
  WATCH_STOP_LISTEN: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/WATCH_STOP_LISTEN`,

  WATCH_LISTEN_ALL: `@superprofit/core-redux-saga-modules/${prefix}${name || Model.collectionName}/WATCH_LISTEN_ALL`,
  WATCH_STOP_LISTEN_ALL: `@superprofit/core-redux-saga-modules/$prefix${name || Model.collectionName}/WATCH_STOP_LISTEN_ALL`,
})
