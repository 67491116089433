const initialState = {
  isLoadingProducts: true,
  isLoadingSubscriptions: true,
  hasSubscription: false,
  showUpdateSubscription: false,
  showUpdatePaymentMethod: false,
  showCreateSubscription: false,
  products: [],
  subscriptions: [],
};

export const actionTypes = {
  setUpcominginvoice: "setUpcominginvoice",
  toggleHasSubscription: "toggleHasSubscription",
  toggleShowUpdateSubscription: "toggleShowUpdateSubscription",
  toggleShowUpdatePaymentMethod: "toggleShowUpdatePaymentMethod",
  toggleShowCreateSubscription: "toggleShowCreateSubscription",
  toggleLoadingProducts: "toggleLoadingProducts",
  toggleUpdatingSubscription: "toggleUpdatingSubscription",
  toggleUpdatingPaymentMethod: "toggleUpdatingPaymentMethod",
  toggleCancellingSubscription: "toggleCancellingSubscription",
  toggleCreatingSubscription: "toggleCreatingSubscription",
  toggleLoadingUpcomingInvoice: "toggleLoadingUpcomingInvoice",
  toggleLoadingSubscriptions: "toggleLoadingSubscriptions",
  setCreatedSubscriptionStatus: "setCreatedSubscriptionStatus",
  setUpdatedPaymentMethodStatus: "setUpdatedPaymentMethodStatus",
  setUpdatedSubscriptionStatus: "setUpdatedSubscriptionStatus",
  setBillingInformationInputErrors: "setBillingInformationInputErrors",
  setCancelledSubscriptionStatus: "setCancelledSubscriptionStatus",
  setSubscriptionInformation: "setSubscriptionInformation",
  setProducts: "setProducts",
  setSubscriptions: "setSubscriptions",
  setFetchProductsError: "setFetchProductsError",
  setCreatedSubscriptionSeats: "setCreatedSubscriptionSeats",
  setCreatedSubscriptionProductId: "setCreatedSubscriptionProductId",
  setUpdatedSubscriptionSeats: "setUpdatedSubscriptionSeats",
  setUpdatedSubscriptionProductId: "setUpdatedSubscriptionProductId",
  setSelectedProduct: "setSelectedProduct",
  toggleLoadingCoupon: "toggleLoadingCoupon",
  setCoupon: "setCoupon",
  init: "init",
};

export function init(s) {
  return { ...initialState, ...s };
}

export default function reducer(state, action) {
  switch (action.type) {
    case actionTypes.toggleHasSubscription:
      return { ...state, ...state, hasSubscription: !state.hasSubscription };
    case actionTypes.toggleShowUpdateSubscription:
      return {
        ...state,
        showUpdatePaymentMethod: false,
        showUpdateSubscription: !state.showUpdateSubscription,
      };
    case actionTypes.toggleShowUpdatePaymentMethod:
      return {
        ...state,
        showUpdateSubscription: false,
        showUpdatePaymentMethod: !state.showUpdatePaymentMethod,
      };
    case actionTypes.toggleShowCreateSubscription:
      return {
        ...state,
        showCreateSubscription: !state.showCreateSubscription,
      };
    case actionTypes.toggleLoadingProducts:
      return { ...state, isLoadingProducts: !state.isLoadingProducts };
    case actionTypes.toggleLoadingUpcomingInvoice:
      return {
        ...state,
        isLoadingUpcomingInvoice: !state.isLoadingUpcomingInvoice,
      };
    case actionTypes.toggleUpdatingSubscription:
      return {
        ...state,
        isUpdatingSubscription: !state.isUpdatingSubscription,
      };
    case actionTypes.setUpdatedSubscriptionStatus:
      return {
        ...state,
        updatedSubscriptionStatus: action.payload.updatedSubscriptionStatus,
      };
    case actionTypes.setCancelledSubscriptionStatus:
      return {
        ...state,
        cancelledSubscriptionStatus: action.payload.cancelledSubscriptionStatus,
      };
    case actionTypes.toggleCreatingSubscription:
      return {
        ...state,
        isCreatingSubscription: !state.isCreatingSubscription,
      };
    case actionTypes.toggleCancellingSubscription:
      return {
        ...state,
        isCancellingSubscription: !state.isCancellingSubscription,
      };
    case actionTypes.setCreatedSubscriptionStatus:
      return {
        ...state,
        createdSubscriptionStatus: action.payload.createdSubscriptionStatus,
      };
    case actionTypes.setUpdatedPaymentMethodStatus:
      return {
        ...state,
        updatedPaymentMethodStatus: action.payload.updatedPaymentMethodStatus,
      };
    case actionTypes.toggleLoadingSubscriptions:
      return {
        ...state,
        isLoadingSubscriptions: !state.isLoadingSubscriptions,
      };
    case actionTypes.toggleUpdatingPaymentMethod:
      return {
        ...state,
        isUpdatingPaymentMethod: !state.isUpdatingPaymentMethod,
      };
    case actionTypes.setProducts:
      return { ...state, products: action.payload.products };
    case actionTypes.setSubscriptions:
      return { ...state, subscriptions: action.payload.subscriptions };
    case actionTypes.setSubscriptionInformation:
      return {
        ...state,
        subscriptionInformation: action.payload.subscriptionInformation,
      };
    case actionTypes.setUpcominginvoice:
      return { ...state, upcomingInvoice: action.payload.upcomingInvoice };
    case actionTypes.setFetchProductsError:
      return {
        ...state,
        fetchProductsError: action.payload.fetchProductsError,
      };
    case actionTypes.init:
      return init(action.payload);
    case actionTypes.setCreatedSubscriptionProductId:
      return { ...state, createdSubscriptionProductId: action.payload.id };
    case actionTypes.setCreatedSubscriptionSeats:
      return { ...state, createdSubscriptionSeats: action.payload.seats };
    case actionTypes.setUpdatedSubscriptionProductId:
      return { ...state, updatedSubscriptionProductId: action.payload.id };
    case actionTypes.setUpdatedSubscriptionSeats:
      return { ...state, updatedSubscriptionSeats: action.payload.seats };
    case actionTypes.setCoupon:
      return { ...state, coupon: action.payload.coupon };
    case actionTypes.toggleLoadingCoupon:
      return { ...state, isLoadingCoupon: !state.isLoadingCoupon };
    case actionTypes.setBillingInformationInputErrors:
      return {
        ...state,
        billingInformationInputErrors:
          action.payload.billingInformationInputErrors,
      };
    // case actionTypes.setSelectedProduct: return {...state, selectedProduct: action.payload.selectedProduct }
    default:
      throw new Error();
  }
}
