import styled, { css } from "styled-components";
import { darken, lighten } from "@material-ui/core";

export const Weeks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-right: ${props => props.theme.spacing(1)}px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const splitStartPrimary = css`
  background: linear-gradient(
    to right bottom,
    ${props => lighten(props.theme.palette.secondary.main, 0.1)} 50%,
    ${props => darken(props.theme.palette.secondary.main, 0.1)} 50%
  );
`;

const splitEndPrimary = css`
  background: linear-gradient(
    to right bottom,
    ${props => darken(props.theme.palette.secondary.main, 0.1)} 50%,
    ${props => lighten(props.theme.palette.secondary.main, 0.1)} 50%
  );
`;

const splitStartDefault = css`
  background: linear-gradient(
    to right bottom,
    ${props => darken(props.theme.palette.background.light, 0.02)} 50%,
    ${props => darken(props.theme.palette.background.light, 0.05)} 50%
  );
`;

const splitEndDefault = css`
  background: linear-gradient(
    to right bottom,
    ${props => darken(props.theme.palette.background.light, 0.05)} 50%,
    ${props => darken(props.theme.palette.background.light, 0.02)} 50%
  );
`;

const colors = {
  primary: css<{ splitStart?: string; splitEnd?: string }>`
    color: white;
    background: ${props => darken(props.theme.palette.secondary.main, 0.1)};
    ${props => props.splitStart && splitStartPrimary};
    ${props => props.splitEnd && splitEndPrimary};
  `,
  secondary: css<{ splitStart?: string; splitEnd?: string }>`
    background: ${props => darken(props.theme.palette.background.light, 0.025)};
    ${props => props.splitStart && splitStartDefault};
    ${props => props.splitEnd && splitEndDefault};
  `
};

export const Week = styled.div<{
  splitStart?: string;
  splitEnd?: string;
  color?: "primary" | "secondary";
}>`
  border-radius: ${props => props.theme.shape.borderRadius}px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding-top: ${props => props.theme.spacing(1)}px;
  padding-bottom: ${props => props.theme.spacing(1)}px;
  padding-left: ${props => props.theme.spacing(0.5)}px;
  padding-right: ${props => props.theme.spacing(0.5)}px;
  word-break: unset;
  white-space: nowrap;

  ${props => props.color && colors[props.color]};
`;
