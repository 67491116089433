import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
  BATCH_UPDATE,
  BATCH_UPDATE_FAILURE,
  BATCH_UPDATE_SUCCESS,
  DELETE,
  DELETE_FAILURE,
  DELETE_SUCCESS,
  PATCH,
  PATCH_FAILURE,
  PATCH_SUCCESS,
  DELETE_MULTIPLE,
  DELETE_MULTIPLE_SUCCESS,
  DELETE_MULTIPLE_FAILURE
} from "./constants";

const initialState = {
  list: [],
  loading: false,
  pagination: {},
  saving: false,
  saveError: null
};

const updateList = (list, invoice) => {
  const newList = list.slice();
  const idx = newList.findIndex(c => c.id === invoice.id);
  newList[idx] = invoice;
  return newList;
};

const patchInvoice = (list, id, updates) => {
  const newList = list.slice();
  const idx = newList.findIndex(c => c.id === id);
  if (idx > -1) {
    newList[idx] = newList[idx].clone();
    newList[idx].setData(updates);
  }
  return newList;
};

const patchUpdated = (list, updatedList) => {
  return updatedList.reduce(
    (prev, next) => patchInvoice(prev, next.id, next.data()),
    list
  );
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVE:
      return {
        ...state,
        saving: true
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        saving: false,
        list: payload.isNew
          ? [payload.invoice].concat(state.list)
          : updateList(state.list, payload.invoice)
      };
    case SAVE_FAILURE:
      return {
        ...state,
        saving: false,
        saveError: payload.error
      };
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.list
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false,
        list: payload.list
      };
    case BATCH_UPDATE:
      return {
        ...state,
        loading: true
      };
    case BATCH_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: patchUpdated(state.list, payload.list)
      };
    case BATCH_UPDATE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case DELETE:
      return {
        ...state,
        loading: true
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter(i => i.id !== payload?.id)
      };
    case DELETE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case DELETE_MULTIPLE:
      return {
        ...state,
        loading: true
      };
    case DELETE_MULTIPLE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter(i => !payload.ids.find(id => i.id === id))
      };
    case DELETE_MULTIPLE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case PATCH:
      return {
        ...state,
        saving: true
      };

    case PATCH_SUCCESS:
      return {
        ...state,
        saving: false,
        list: patchInvoice(state.list, payload.id, payload.updates)
      };

    case PATCH_FAILURE:
      return {
        ...state,
        saving: false,
        saveError: payload.error
      };

    default:
      return state;
  }
};
