import {
  ReportTable,
  ReportTableCell,
  ReportTableHeader,
  ReportTableRow,
  Report
} from "../Report";
import Customer from "../../../models/Customer";
import Entry from "../../../models/Entry";
import Project from "../../../models/Project";
import { setDayOfYear, setYear } from "date-fns";

const build = (
  ogUsers: any,
  ogProjects: any,
  entries: any,
  customers: any = [],
  features: any = {},
  isAdmin: boolean = false
) => {
  const report = new Report();
  const projects = ogProjects.slice();
  const getPrettyDate = (year: number, dayOfYear: number) => {
    const date = setYear(new Date(), year);
    const dateWithDayOfYear = setDayOfYear(date, dayOfYear);
    return dateWithDayOfYear.toISOString().split("T")[0];
  };
  const tableHeader = new ReportTableHeader([
    new ReportTableCell("Date"),
    new ReportTableCell("User"),
    new ReportTableCell("Email"),
    new ReportTableCell("Project"),
    new ReportTableCell("Customer"),
    new ReportTableCell("Hours"),
    ...(features?.userProjectIncome || isAdmin
      ? [new ReportTableCell("Project rate")]
      : []),
    ...(projects.some((p: Project) => p.salaryEnabled)
      ? [new ReportTableCell("Salary rate")]
      : [])
  ]);

  const tableRows: any[] = [];

  projects.sort((a: any, b: any) => a?.name?.localeCompare(b?.name));

  const users = ogUsers.slice();
  users.sort((a: any, b: any) => a?.fullName?.localeCompare(b?.fullName));

  users.forEach((user: any) => {
    let sumTotalMoney = 0;
    let sumTotalHours = 0;
    let hasEntries = false;
    entries
      .slice()
      .filter((entry: Entry) => entry.user === user.id)
      .sort((a: Entry, b: Entry) => {
        // First, sort by user
        const userComparison = a.user.localeCompare(b.user);
        if (userComparison !== 0) {
          return userComparison;
        }

        // If users are equal, sort by project name
        const projectComparison = a.project.localeCompare(b.project);
        if (projectComparison !== 0) {
          return projectComparison;
        }

        // If project names are equal, sort by year in ascending order
        const yearComparison = a.year - b.year;
        if (yearComparison !== 0) {
          return yearComparison;
        }

        // If years are equal, sort by dayOfYear in ascending order
        return a.dayOfYear - b.dayOfYear;
      })
      .forEach((entry: Entry) => {
        const project = projects.find((p: any) => p.id === entry.project);
        const hours = entry.hours;

        if (project && hours) {
          hasEntries = true;
          sumTotalHours += hours;
          // sumTotalMoney = total ? sumTotalMoney + total : sumTotalMoney;
          tableRows.push(
            new ReportTableRow([
              new ReportTableCell(getPrettyDate(entry.year, entry.dayOfYear)),
              new ReportTableCell(user?.displayName),
              new ReportTableCell(user?.email),
              new ReportTableCell(project?.name),
              new ReportTableCell(
                customers?.find((c: any) => c.id === project?.customer)?.name ||
                  project?.customer
              ),
              new ReportTableCell(hours, {
                textAlign: "right"
              }),
              ...(features?.userProjectIncome || isAdmin
                ? [
                    new ReportTableCell(
                      features?.userProjectIncome || isAdmin
                        ? project.getBillableRate(user.id)
                        : "",
                      {
                        textAlign: "right"
                      }
                    )
                  ]
                : []),
              ...(project.salaryEnabled
                ? [
                    new ReportTableCell(
                      project.salaryEnabled
                        ? project.getSalaryRate(user.id)
                        : "",
                      {
                        textAlign: "right"
                      }
                    )
                  ]
                : [])
            ])
          );
        }
      });
  });
  report.push(new ReportTable(tableHeader, tableRows));
  return report;
};

export const createTableExport = (
  users: any,
  projects: any,
  entries: any,
  customers: Customer[] = [],
  features: any = {},
  isAdmin: boolean = false
) => {
  return build(users, projects, entries, customers, features, isAdmin);
};
