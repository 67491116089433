import Login from "../pages/public/Login";
import Landing from "../pages/public/Landing";
import Signup from "../pages/public/Signup";
import NoAccess from "../pages/public/NoAccess";
import TermsAndConditions from '../pages/public/TermsAndConditions';
import PrivacyPolicy from '../pages/public/PrivacyPolicy';

const routes = [
  {
    id: "login",
    path: "/login",
    exact: true,
    component: Login
  },  {
    id: "terms",
    path: "/terms-and-conditions",
    exact: true,
    component: TermsAndConditions
  },{
    id: "privacyPolicy",
    path: "/privacy-policy",
    exact: true,
    component: PrivacyPolicy
  },
  {
    id: "signup",
    path: "/signup",
    exact: true,
    component: Signup
  },
  {
    id: "landing",
    path: "/",
    exact: true,
    component: Landing
  },
  {
    id: "workspace-no-access",
    path: "/workspace-no-access",
    exact: true,
    component: NoAccess
  }
];

export const getPathWithKey = key => routes.find(o => o.id === key).path;

export default routes;
