import { moment } from "@superprofit/time-util";
import React from "react";

export default ({ year, month }) => {
  const period = moment()
    .year(year)
    .month(month - 1)
    .format("MMMM YYYY");

  const formattedPeriod = `${period[0].toUpperCase()}${period.slice(1)}`;

  return <span>{formattedPeriod}</span>;
};
