import { useMemo } from "react";
import User from "../../../../models/User";
import useUsers from "../../../../hooks/useUsers";

const useUsersWithoutEmployeeNumber = () => {
  const { data: users = [] } = useUsers();
  return useMemo(
    () => users.filter((user: User) => !user.metadata?.employeeNumber),
    [users]
  );
};

export default useUsersWithoutEmployeeNumber;
