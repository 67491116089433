import { useMutation, useQueryClient } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import Project, { IProject } from "../models/Project";
import useUser from "./useUser";
import useProject from "./useProject";
import TimesheetComment from "../models/TimesheetComment";
import { getKey as getProjectsKey } from "./useProjects";
import { getKey as getProjectKey } from "./useProject";

const useProjectMutation = (projectId?: string) => {
  const workspace = useWorkspace();
  const { data: project } = useProject(projectId || null);
  const queryClient = useQueryClient();

  const user = useUser();
  const save = (
    payload:
      | Partial<IProject>
      | { updates: Partial<IProject>; genericProject?: Project }
  ) => {
    let updates = payload as Partial<IProject>;
    let genericProject = null;

    if ("updates" in payload) {
      updates = payload.updates;
      genericProject = payload.genericProject;
    }

    if (!project && !genericProject) {
      const payload = {
        name: updates.name || "",
        billable: updates.billable || false,
        billableRate: updates.billableRate || null,
        billableCurrency: updates.billableCurrency || "nok",
        salaryEnabled: updates.salaryEnabled || false,
        salaryRate: updates.salaryRate || null,
        salaryCurrency: updates.salaryCurrency || "usd",
        budget: updates.budget || null,
        userSalaryRate: updates.userSalaryRate || null,
        userBillableRate: updates.userBillableRate || null,
        team: updates.team || [],
        userGroups: updates.userGroups || [],
        tags: updates.tags || [],
        color: updates.color || "#4422ff",
        customer: updates.customer || "",
        managerName: updates.managerName || null,
        managerEmail: updates.managerEmail || null,
        twntyFourSevenId: updates.twntyFourSevenId || null,
        externalReference: updates.externalReference || null,
        planned: updates.planned || null,
        archived: updates.archived || false
      };
      return Project.create(workspace, user.email, payload);
    }
    const projectToUpdate = project || genericProject;

    if (projectToUpdate) {
      return Project.update(workspace, user.email, projectToUpdate, updates);
    } else throw new Error("No project to update");
  };

  return useMutation(save, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        getProjectsKey(workspace),
        (projects: Project[] = []) => {
          const idx = projects.findIndex(p => p.id === data.id);
          if (idx > -1) return projects.map(p => (p.id === data.id ? data : p));
          return [data].concat(projects);
        }
      );
      queryClient.setQueryData(getProjectKey(workspace, data.id), () => data);
    }
  });
};

export default useProjectMutation;
