import { combineReducers } from "redux";
import ui from "./modules/ui/reducer";
import api from "./modules/api/reducer";
import { connectRouter } from "connected-react-router";

export default history =>
  combineReducers({
    api,
    router: connectRouter(history),
    ui
  });
