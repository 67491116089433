import weeklyHours from "./weeklyHours/sagas";
import hoursByProject from "./hoursByProject/sagas";
import hoursByUser from "./hoursByUser/sagas";
import billableMonth from "./billableMonth/sagas";
import nonBillableMonth from "./nonBillableMonth/sagas";
import prognosis from "./prognosis/sagas";
import settingsDialog from "./settingsDialog/sagas";
import activeGoal from "./activeGoal/sagas";

export default [
  weeklyHours(),
  hoursByProject(),
  hoursByUser(),
  billableMonth(),
  nonBillableMonth(),
  prognosis(),
  settingsDialog(),
  activeGoal()
];
