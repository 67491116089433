import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Protected from "./pages/Protected";
import Public from "./pages/Public";
import { Switch } from "react-router-dom";
import { publicRoutes, RouteWithSubRoutes } from "./router";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./redux/store";
import ThemeProvider from "./ThemeProvider";
import { DeployVersion } from "./app/app.styles";
import moment from "moment";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Snackbar from "./components/atoms/Snackbar";
import useGlobalSnackbar from "./hooks/useGlobalSnackbar";
import { Alert } from "@material-ui/lab";
import * as Yup from "yup";
import i18n from "i18next";
import { useAuth } from "./hooks/useAuth";
import { firebase } from "./firebase";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default to 20 seconds
      staleTime: 1000 * 20
    }
  }
});

// Set Yup to use i18n for error messages
Yup.setLocale({
  mixed: {
    required: () => i18n.t("validation.yup.mixed.required"),
    notType: () => i18n.t("validation.yup.mixed.notType"),
    oneOf: () => i18n.t("validation.yup.mixed.oneOf"),
    notOneOf: () => i18n.t("validation.yup.mixed.notOneOf"),
    default: () => i18n.t("validation.yup.mixed.default")
  },
  string: {
    length: () => i18n.t("validation.yup.string.length"),
    min: ({ min }) => i18n.t("validation.yup.string.min", { min }),
    max: ({ max }) => i18n.t("validation.yup.string.max", { max }),
    email: () => i18n.t("validation.yup.string.email"),
    url: () => i18n.t("validation.yup.string.url"),
    trim: () => i18n.t("validation.yup.string.trim"),
    lowercase: () => i18n.t("validation.yup.string.lowercase"),
    uppercase: () => i18n.t("validation.yup.string.uppercase"),
    matches: ({ regex }) => i18n.t("validation.yup.string.matches", { regex })
  },
  number: {
    min: ({ min }) => i18n.t("validation.yup.number.min", { min }),
    max: ({ max }) => i18n.t("validation.yup.number.max", { max }),
    lessThan: () => i18n.t("validation.yup.number.lessThan"),
    moreThan: () => i18n.t("validation.yup.number.moreThan"),
    positive: () => i18n.t("validation.yup.number.positive"),
    negative: () => i18n.t("validation.yup.number.negative"),
    integer: () => i18n.t("validation.yup.number.integer")
  },
  date: {
    min: ({ min }) => i18n.t("validation.yup.date.min", { min }),
    max: ({ max }) => i18n.t("validation.yup.date.max", { max })
  },
  array: {
    min: ({ min }) => i18n.t("validation.yup.array.min", { min }),
    max: ({ max }) => i18n.t("validation.yup.array.max", { max })
  }
});

const App = () => {
  const mode = useSelector(store => store.ui.theme.mode);
  const { user, activeWorkspaceId } = useSelector(({ api: { auth } }) => auth);
  const ac = useSelector(
    ({ api: { applicationAccess } }) => applicationAccess?.entry
  );
  const { snackbar } = useGlobalSnackbar();
  useAuth();
  // const dispatch = useDispatch();
  //
  // useEffect(() => {
  //   const unregisterAuthObserver = firebase
  //     .auth()
  //     .onAuthStateChanged(user => dispatch(watchAuthStateChanged(user)));
  //   const unregisterIdTokenObserver = firebase
  //     .auth()
  //     .onIdTokenChanged(token => dispatch(watchOnIdTokenChanged(token)));
  //   return () => {
  //     unregisterAuthObserver();
  //     unregisterIdTokenObserver();
  //   };
  // }, [firebase]);
  // useEffect(() => {
  //   const unregisterAuthObserver = firebase
  //     .auth()
  //     .onAuthStateChanged(handleAuthStateChanged);
  //   const unregisterIdTokenObserver = firebase
  //     .auth()
  //     .onIdTokenChanged(handleIdTokenChanged);
  //   return () => {
  //     unregisterAuthObserver();
  //     unregisterIdTokenObserver();
  //   };
  // }, [firebase]);

  useEffect(() => {
    window.intercomSettings = {
      app_id: "bky4cnef",
      name: user?.displayName, // Full name
      email: user?.email, // Email address
      created_at: user?.createdAt || null, // Signup date as a Unix timestamp
      custom_launcher_selector: "#my_custom_link",
      max_users: ac?.maxUsers || null,
      workspace: activeWorkspaceId || null,
      subscriptionEnd: ac?.accessUntilTimestamp
        ? moment.unix(ac.accessUntilTimestamp).format("DD/MM/YYYY")
        : null
    };

    const init = function() {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function() {
          i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function() {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/bky4cnef";
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    };
    init();
  }, [user, ac?.maxUsers, activeWorkspaceId, ac?.accessUntilTimestamp]);

  return (
    <ThemeProvider mode={mode}>
      <QueryClientProvider client={queryClient}>
        <ConnectedRouter history={history}>
          <Switch>
            <RouteWithSubRoutes path="/app" component={Protected} />
            <RouteWithSubRoutes
              exact
              path={[...publicRoutes.map(r => r.path)]}
              component={Public}
            />
          </Switch>
        </ConnectedRouter>
        <div id="modal-root"></div>
        {/*<GlobalAlerts />*/}
        <Snackbar
          autoHideduration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          {...snackbar.options}
          message={
            !!snackbar.options?.alert ? false : snackbar.options?.message
          }
        >
          {snackbar.options?.alert && (
            <Alert variant="filled" {...snackbar.options.alert}>
              {snackbar.options?.alert?.children || snackbar.options?.message}
            </Alert>
          )}
        </Snackbar>
        <DeployVersion>
          {process.env.REACT_APP_GIT_SHA} - {process.env.REACT_APP_BUILD_TIME}
        </DeployVersion>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
