import React, { useEffect, useMemo } from "react";
import Page from "@superprofit/core-react-components/atoms/Page";
import Grid from "@material-ui/core/Grid";
import PeriodSelect from "../../../components/molecules/PeriodSelect";
import { Button } from "@superprofit/core-react-components/atoms";
import FilterIcon from "@material-ui/icons/FilterList";
import Badge from "@superprofit/core-react-components/atoms/Badge";
import FilterDialog from "./shared/FilterDialog";
import SalaryTable from "./salary/SalaryTable";
import { filterArchivedProjects } from "../../../utils/ProjectStats";
import useProjects from "../../../hooks/useProjects";
import useCustomers from "../../../hooks/useCustomers";
import useUsers from "../../../hooks/useUsers";
import usePeriod from "./shared/usePeriod";
import useFilters from "./shared/useFilters";
import useUserStatsByWeek from "../../../hooks/useUserStatsByWeek";
import { useTranslation } from "react-i18next";

export default () => {
  const period = usePeriod();
  const { t } = useTranslation();
  const { data: projects, isLoading: projectsLoading } = useProjects();
  const { data: customers = [], isLoading: customersLoading } = useCustomers();
  const { data: users, isLoading: usersLoading } = useUsers();
  const { data: stats = [], isLoading: statsLoading } = useUserStatsByWeek(
    period
  );
  const { hasFilters, filters, toggleModal } = useFilters();
  const isLoading =
    projectsLoading || customersLoading || usersLoading || statsLoading;

  const activeProjects = useMemo(() => {
    if (!projects) return [];
    return filterArchivedProjects(projects, stats);
  }, [projects, stats]);

  const handleOnFilter = () => {
    toggleModal();
  };

  const handleOnChangePeriod = (pl: { year?: number; month?: number }) => {
    period.update(pl);
  };

  return (
    <Page context={t("common.management")} title={t("common.report")}>
      <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={6} component="div">
          <PeriodSelect
            period={period}
            showNavigation
            onChange={handleOnChangePeriod}
            showWeek={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} component="div" justify="flex-end">
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Badge color="error" variant="dot" invisible={!hasFilters}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleOnFilter}
                  disabled={isLoading}
                  startIcon={<FilterIcon />}
                >
                  {t("common.filter")}
                </Button>
              </Badge>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 20 }} component="div">
        <Grid item xs={12} component="div">
          <SalaryTable
            title={t("common.salaryByUser") || ""}
            filters={filters}
            stats={stats}
            users={users}
            projects={activeProjects}
            loading={isLoading}
            period={period}
            columns={{
              expand: true,
              avatar: true,
              hours: true,
              salary: true,
              customer: true
              // weekDistribution: true,
              // projectDistribution: true
            }}
          />
        </Grid>
      </Grid>

      <FilterDialog />
    </Page>
  );
};
