import React, { forwardRef } from "react";
import Link from "../atoms/Link";
import { getPathWithParamsRaw } from "../../router/routes";
import { ForwardRefExoticComponent } from "react";

interface CustomerLinkProps {
  id: string;
  name: string;
}

const CustomerLink: ForwardRefExoticComponent<CustomerLinkProps> = forwardRef(
  ({ id, name, ...rest }, ref) => (
    <Link
      withWorkspacePrefix
      to={getPathWithParamsRaw("customerOverview", { id })}
      color="inherit"
      {...rest}
      ref={ref}
    >
      <b>{name}</b>
    </Link>
  )
);

export default CustomerLink;
