import { useWorkspaceHistory } from "../../../contexts/WorkspaceContext";
import React, { useState } from "react";
import {
  Card,
  Snackbar,
  CardHeader,
  Divider,
  Menu,
  MenuItem,
  AppBar,
  Button
} from "@superprofit/core-react-components/atoms";
import FeatureNotImplementedDialog from "@superprofit/core-react-components/organisms/FeatureNotImplementedDialog";

import IconMore from "@material-ui/icons/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { IUserGroupsCard } from "./userGroupsCard/types";
import GroupsTable from "./userGroupsCard/GroupsTable";
import { RootState } from "../../../index";
import { stageArchiving } from "../../../redux/modules/ui/userGroups/page/actions";
import { useTranslation } from "react-i18next";

//import {showUserDialog} from "../../../redux/modules/ui/users/dialog/actions";
// import {showDeleteUsersDialog} from "../../../redux/modules/ui/users/deleteUsersDialog/actions";
// import {selectAllUsers} from "../../../redux/modules/ui/users/selection/actions";
// import {show as showPDFDialog} from "../../../redux/modules/ui/pdf/dialog/actions";
// import {showAccessControlDialog} from "../../../redux/modules/ui/users/accessControlDialog/actions";
// import {showAssignProjectsDialog} from "../../../redux/modules/ui/users/assignProjectsDialog/actions";

const UserGroupsCard = () => {
  const dispatch = useDispatch();
  const history = useWorkspaceHistory();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();

  const [notYetDialog, setNotYetDialog] = useState<boolean>(false);

  const { selection } = useSelector(
    (state: RootState) => state.ui.userGroups.table
  );

  const handleOnAdd = (/*e, data*/) => {
    // dispatch(showUserDialog({}));
  };

  const handleOnEdit = (/*e, data*/) => {
    // const user = users.find(u => u.id === data.id);
    // dispatch(showUserDialog(user));
  };

  const handleOnArchive = (/*e, data*/) => {
    // setNotYetDialog(true);
  };

  const handleOnDelete = (/*e, data*/) => {
    // dispatch(showDeleteUsersDialog([data]));
  };

  const handleOnArchiveSelection = () => {
    dispatch(stageArchiving(selection));
  };

  const handleOnView = (/*e, data*/) => {
    // setNotYetDialog(true);
    // history.push(`/users/${data.id}`);
  };

  const handleOnSelectionChange = (/*selection*/) => {
    // dispatch(selectAllUsers(selection));
  };
  const handleOnClearSelection = () => {
    // dispatch(selectAllUsers([]));
  };
  const handleOnShowPDFDialog = () => {
    // dispatch(showPDFDialog({ users: selected }));
  };
  const handleOnBatchAssignRoles = () => {
    // dispatch(showAccessControlDialog(selected));
  };
  const handleOnBatchAssignProjects = () => {
    // dispatch(showAssignProjectsDialog(selected));
  };

  const handleOnAssignRoles = (/*e, user*/) => {
    // dispatch(showAccessControlDialog([user]));
  };
  const handleOnAssignProjects = (/*e, user*/) => {
    // dispatch(showAssignProjectsDialog([user]));
  };

  return (
    <div>
      <Card>
        <CardHeader title={t("common.userGroups")} />

        <Divider />

        <GroupsTable />
      </Card>

      <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={selection.length > 0}
          autoHideDuration={null}
          onClose={(noop: any) => noop}
          message={`${selection.length} user groups selected`}
          action={
            <React.Fragment>
              <Button
                color="inherit"
                size="small"
                onClick={handleOnClearSelection}
              >
                {t("common.clear")}
              </Button>
              <Button
                color="alert"
                size="small"
                onClick={handleOnArchiveSelection}
              >
                {t("common.archive")}
              </Button>
            </React.Fragment>
          }
        />
      </AppBar>

      <FeatureNotImplementedDialog
        open={notYetDialog}
        onClose={() => setNotYetDialog(false)}
      />
    </div>
  );
};

export default UserGroupsCard;
