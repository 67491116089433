import {combineReducers} from "redux";
import dialog from "../userGroups/dialog/reducer";
import table from "../userGroups/table/reducer";
import page from "../userGroups/page/reducer";


const reducer =  combineReducers({
  dialog,
  table,
  page
});

export default reducer;
