import {
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  WATCH_GET,
  WATCH_SAVE,
  SAVE_COMMENT_SUCCESS,
  SAVE_COMMENT,
  SAVE_COMMENT_FAILURE,
  WATCH_SAVE_COMMENT
} from "./constants";

export const get = () => ({
  type: GET,
  payload: {}
});

export const getSuccess = (list, pagination) => ({
  type: GET_SUCCESS,
  payload: { list, pagination }
});

export const getFailure = error => ({
  type: GET_FAILURE,
  payload: { error }
});

export const save = (lead, updates) => ({
  type: SAVE,
  payload: { lead, updates }
});

export const saveSuccess = (lead, isNew) => ({
  type: SAVE_SUCCESS,
  payload: { lead, isNew }
});

export const saveFailure = error => ({
  type: SAVE_FAILURE,
  payload: { error }
});

export const saveComment = (comment, lead) => ({
  type: SAVE_COMMENT,
  payload: { comment, lead }
});

export const saveCommentSuccess = (comment, lead) => ({
  type: SAVE_COMMENT_SUCCESS,
  payload: { comment, lead }
});

export const saveCommentFailure = (lead, error) => ({
  type: SAVE_COMMENT_FAILURE,
  payload: { lead, error }
});

export const watchGetLeads = () => ({
  type: WATCH_GET,
  payload: {}
});

export const watchSaveLead = (lead, updates) => ({
  type: WATCH_SAVE,
  payload: { lead, updates }
});

export const watchSaveLeadComment = (comment, lead) => ({
  type: WATCH_SAVE_COMMENT,
  payload: { comment, lead }
});
