import { SET_LOADING, SET_BASIS } from "./constants";

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const setBasis = basis => ({
  type: SET_BASIS,
  payload: { basis }
});
