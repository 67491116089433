import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  SAVE_FAILURE,
} from "./constants";
import {
  defaultHolidaysCalendar,
  defaultWorkingWeekDays,
} from "../../../../utils/WorkingDays";
import CompanySettingGeneric from "../../../../models/CompanySettingGeneric";

const initialState = {
  loading: false,
  saving: false,
  settings: {
    defaultCapacity: new CompanySettingGeneric({
      id: "defaultCapacity",
      value: 40,
    }),
    workdays: new CompanySettingGeneric({
      id: "workdays",
      value: defaultWorkingWeekDays,
    }),
    holidaysCalendar: new CompanySettingGeneric({
      id: "holidaysCalendar",
      value: defaultHolidaysCalendar,
    }),
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET:
      return {
        ...state,
        loading: true,
      };
    case GET_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...payload.settings,
        },
        loading: false,
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SAVE:
      return {
        ...state,
        saving: true,
      };
    case SAVE_SUCCESS: {
      let s = { ...state, saving: false };
      payload.savedSettings.forEach((setting) => {
        s = {
          ...s,
          settings: {
            ...s.settings,
            [setting.id]: { ...(s.settings[setting.id] || {}), ...setting },
          },
        };
      });
      return s;
    }
    case SAVE_FAILURE:
      return {
        ...state,
        saving: false,
      };

    default:
      return state;
  }
};
