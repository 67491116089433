import {
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
  DELETE,
  DELETE_FAILURE,
  DELETE_SUCCESS,
  BATCH_UPDATE,
  BATCH_UPDATE_FAILURE,
  BATCH_UPDATE_SUCCESS,
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  WATCH_GET,
  WATCH_SAVE,
  WATCH_DELETE,
  WATCH_UPDATE_ALL,
  PATCH,
  PATCH_FAILURE,
  PATCH_SUCCESS,
  WATCH_PATCH,
  WATCH_DELETE_MULTIPLE,
  DELETE_MULTIPLE,
  DELETE_MULTIPLE_SUCCESS,
  DELETE_MULTIPLE_FAILURE
} from "./constants";

export const get = () => ({
  type: GET,
  payload: {}
});

export const getSuccess = (list, pagination) => ({
  type: GET_SUCCESS,
  payload: { list, pagination }
});

export const getFailure = error => ({
  type: GET_FAILURE,
  payload: { error }
});

export const batchUpdate = () => ({
  type: BATCH_UPDATE
});

export const batchUpdateSuccess = list => ({
  type: BATCH_UPDATE_SUCCESS,
  payload: { list }
});

export const batchUpdateFailure = error => ({
  type: BATCH_UPDATE_FAILURE,
  payload: { error }
});

export const save = (invoice, updates) => ({
  type: SAVE,
  payload: { invoice, updates }
});

export const saveSuccess = (invoice, isNew) => ({
  type: SAVE_SUCCESS,
  payload: { invoice, isNew }
});

export const saveFailure = error => ({
  type: SAVE_FAILURE,
  payload: { error }
});

export const patch = id => ({
  type: PATCH,
  payload: { id }
});

export const patchSuccess = (id, updates) => ({
  type: PATCH_SUCCESS,
  payload: { id, updates }
});

export const patchFailure = error => ({
  type: PATCH_FAILURE,
  payload: { error }
});

export const deleteInvoice = id => ({
  type: DELETE,
  payload: { id }
});

export const deleteSuccess = id => ({
  type: DELETE_SUCCESS,
  payload: { id }
});

export const deleteFailure = error => ({
  type: DELETE_FAILURE,
  payload: { error }
});

export const deleteMultiple = ids => ({
  type: DELETE_MULTIPLE,
  payload: { ids }
});

export const deleteMultipleSuccess = ids => ({
  type: DELETE_MULTIPLE_SUCCESS,
  payload: { ids }
});

export const deleteMultipleFailure = error => ({
  type: DELETE_MULTIPLE_FAILURE,
  payload: { error }
});

export const watchGetInvoices = () => ({
  type: WATCH_GET,
  payload: {}
});

export const watchDeleteInvoice = id => ({
  type: WATCH_DELETE,
  payload: { id }
});

export const watchSaveInvoice = (invoice, updates, stats) => ({
  type: WATCH_SAVE,
  payload: { invoice, updates, stats }
});

export const watchUpdateInvoices = (invoices, updates) => ({
  type: WATCH_UPDATE_ALL,
  payload: { invoices, updates }
});

export const watchPatchInvoice = (id, updates) => ({
  type: WATCH_PATCH,
  payload: { id, updates }
});

export const watchDeleteMultipleInvoices = ids => ({
  type: WATCH_DELETE_MULTIPLE,
  payload: { ids }
});
