import { combineReducers } from "redux";
import period from "./period/reducer";
import customer from "./customer/reducer";
import snackbar from "./snackbar/reducer";
import invoice from "./invoice/reducer";
import basis from "./basis/reducer";

export default combineReducers({
  invoice,
  period,
  customer,
  snackbar,
  basis
});
