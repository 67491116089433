import {
  SET_LOADING,
  UPDATE_PROJECTS_SEARCH,
  SET_PROJECTS_TABLE
} from "./constants";

const initialState = {
  selected: [],
  list: [],
  searchTerm: "",
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload.isLoading
      };
    case UPDATE_PROJECTS_SEARCH:
      return {
        ...state,
        searchTerm: payload.searchTerm
      };

    case SET_PROJECTS_TABLE:
      return {
        ...state,
        list: payload.list
      };

    default:
      return state;
  }
};
