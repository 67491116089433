import React, { Fragment } from "react";
import { CloseOutlined } from "@material-ui/icons";
import {
  FormDialogContent,
  FormDialogTitle,
  StyledFormDialog
} from "./formDialog/FormDialog.styles";
import Toolbar from "../atoms/Toolbar";
import IconButton from "../atoms/IconButton";
import Button from "../atoms/Button";
import DialogActions from "../atoms/DialogActions";
import Divider from "../atoms/Divider";
import CircularProgress from "../atoms/CircularProgress";
import { DrawerProps } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";

export { FormDialogGroup } from "./formDialog/FormDialog.styles";

interface FormDialogProps extends DrawerProps {
  title: string;
  saveActionTitle: string;
  children: React.ReactNode;
  onSave?: () => void;
  onClose: () => void;
  inProgress: boolean;
  disabled?: boolean;
  SubmitButtonProps?: ButtonProps;
}

const FormDialog: React.FC<FormDialogProps> = props => {
  const {
    title,
    saveActionTitle,
    children,
    onSave,
    onClose,
    inProgress,
    disabled,
    SubmitButtonProps = {},
    ...rest
  } = props;
  return (
    <StyledFormDialog {...rest} anchor="right" onClose={onClose}>
      <Toolbar>
        <FormDialogTitle variant="h6">{title}</FormDialogTitle>

        {inProgress && <CircularProgress color="inherit" size={30} />}

        {!inProgress && (
          <IconButton
            onClick={onClose}
            edge="start"
            color="inherit"
            aria-label="close"
          >
            <CloseOutlined />
          </IconButton>
        )}
      </Toolbar>

      <FormDialogContent>{children}</FormDialogContent>

      <Fragment>
        <Divider />
        <DialogActions>
          {inProgress && <CircularProgress size={30} />}

          <Button
            disabled={inProgress || disabled}
            autoFocus
            size="large"
            onClick={onSave ? onSave : undefined}
            color="primary"
            {...SubmitButtonProps}
          >
            {saveActionTitle}
          </Button>
        </DialogActions>
      </Fragment>
    </StyledFormDialog>
  );
};

export default FormDialog;
