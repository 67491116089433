import React, { useCallback, useMemo } from "react";
import useUserProfile from "../../../../hooks/useUserProfile";
import useUserProfileMutation from "../../../../hooks/useUserProfileMutation";
import Project from "../../../../models/Project";

export function useProjectSortOrder() {
  const { data: profile } = useUserProfile();
  const profileMutation = useUserProfileMutation();

  const projectSortOrder = useMemo(() => {
    return profile?.projectSortOrder || {};
  }, [profile]);

  const reorder = (
    sortedProjects: Project[],
    source: number,
    destination: number
  ) => {
    if (!profile) return;

    const result = [...sortedProjects];
    const [removed] = result.splice(source, 1);
    result.splice(destination, 0, removed);
    const newOrder = result.reduce(
      (prev, next, idx) => ({
        ...prev,
        [next.id as string]: idx + 1
      }),
      projectSortOrder
    );
    profileMutation.mutate({
      ...profile,
      projectSortOrder: newOrder
    });
  };

  const getSortedProjects = useCallback(
    (projects: Project[]) => {
      if (!profile) return projects;
      return projects.sort((a, b) => {
        const aId = a.id || "";
        const bId = b.id || "";
        if (projectSortOrder[aId] && !projectSortOrder[bId]) return 1;
        if (projectSortOrder[bId] && !projectSortOrder[aId]) return -1;
        return projectSortOrder[aId] - projectSortOrder[bId];
      });
    },
    [projectSortOrder, profile]
  );

  return { reorder, order: projectSortOrder, getSortedProjects };
}
