import useProjectsEntries, {
  UseProjectsEntriesOptions
} from "./useProjectsEntries";
import useProjects from "./useProjects";
export type UseAllProjectEntriesOptions = Omit<
  UseProjectsEntriesOptions,
  "ids"
>;

const useAllProjectsEntries = (options: UseAllProjectEntriesOptions) => {
  const { data: projects } = useProjects();
  const ids = projects?.map((project: any) => project.id);
  return useProjectsEntries({ ...options, ids });
};

export default useAllProjectsEntries;
