import React, { useMemo } from "react";
import {
  StyledCard,
  StyledCardContent,
  StyledHeader,
  StyledIconButton
} from "./Card.styles";
import EntryType from "../../../../../models/Entry";
import { Entry } from "./entry/Entry";
import { getDayOfYear, setDayOfYear, setISOWeekYear } from "date-fns";
import { EntryPayload } from "../../../../../hooks/useUserTimesheetMutation";
import Project from "../../../../../models/Project";
import ProjectAvatar from "../../../../../components/molecules/ProjectAvatar";
import useCustomersMap from "../../../../../hooks/useCustomersMap";
import { format } from "../../../../../components/atoms/NumberFormatIntl";
import useCompanySettingsCurrency from "../../../../../hooks/useCompanySettingsCurrency";
import Divider from "../../../../../components/atoms/Divider";
import CommentIcon from "@material-ui/icons/CommentOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardActions from "../../../../../components/atoms/CardActions";
import { More } from "./more/More";
import useHiddenProjectTimesheets from "../../../timesheet/hooks/useHiddenProjectTimesheets";
import IconButton from "../../../../../components/atoms/IconButton";
import { useFeatures } from "../../../../../helpers";

export type CardProps = {
  userEmail: string;
  project: Project;
  hidden?: boolean;
  datesBetween: Date[];
  entries: EntryType[];
  onSaveEntry?: (entry: EntryPayload) => void;
  onMoveProjectUp?: (project: Project) => void;
  onMoveProjectDown?: (project: Project) => void;
};
export const Card = (props: CardProps) => {
  const customersMap = useCustomersMap();
  const { timesheetEntryComments: commentsEnabled = false } = useFeatures();
  const currency = useCompanySettingsCurrency();
  const { datesBetween, entries, onSaveEntry, project, userEmail } = props;
  const { toggleHidden, hidden } = useHiddenProjectTimesheets({
    userId: userEmail
  });
  const [expanded, setExpanded] = React.useState(false);

  const handleOnBlur = (hours: number, date: Date, entry?: EntryType) => {
    if (onSaveEntry && project.id) {
      const year = date.getFullYear();
      const dayOfYear = getDayOfYear(date);
      onSaveEntry({
        year,
        dayOfYear,
        user: userEmail,
        project: project.id,
        hours,
        entry
      });
    }
  };

  const entriesByISOStringMap = useMemo(() => {
    const m = new Map<string, EntryType>();
    for (let entry of entries) {
      let dt = new Date();
      dt = setISOWeekYear(dt, entry.year);
      dt = setDayOfYear(dt, entry.dayOfYear);
      m.set(dt.toISOString(), entry);
    }
    return m;
  }, [entries]);

  const sum = useMemo(() => {
    if (project.billable && project.billableRate && currency) {
      return format({
        locales: "en",
        number:
          project.billableRate *
          entries.reduce((acc, entry) => acc + entry.hours, 0),
        options: {
          style: "currency",
          currency: currency?.toUpperCase()
        }
      });
    }
  }, [currency, project.billable, project.billableRate, entries]);

  const customerName = customersMap.get(project.customer)?.name ?? "";

  const subheader = `${customerName} ${customerName && sum && "-"} ${sum}`;

  const handleOnHideProject = () => {
    toggleHidden(project.id);
  };
  const handleOnShowProject = () => {
    toggleHidden(project.id);
  };

  const handleOnMoveProjectUp = props.onMoveProjectUp?.bind(null, project);
  const handleOnMoveProjectDown = props.onMoveProjectDown?.bind(null, project);

  return (
    <StyledCard
      isHidden={props.hidden}
      variant={props.hidden ? "outlined" : "elevation"}
    >
      <StyledHeader
        avatar={<ProjectAvatar project={project} />}
        title={project.name}
        subheader={subheader}
        action={
          <More
            isHidden={hidden.has(`${project.id}`)}
            onHideProject={handleOnHideProject}
            onMoveDown={handleOnMoveProjectDown}
            onMoveUp={handleOnMoveProjectUp}
            onShowProject={handleOnShowProject}
          />
        }
      />
      <Divider />
      <StyledCardContent>
        {datesBetween.map((date, index) => (
          <Entry
            date={date}
            onBlur={handleOnBlur}
            entry={entriesByISOStringMap.get(date.toISOString())}
          />
        ))}
      </StyledCardContent>
      <Divider />
      {commentsEnabled && (
        <CardActions>
          <IconButton>
            <CommentIcon />
          </IconButton>
          <StyledIconButton
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <ExpandMoreIcon />
          </StyledIconButton>
        </CardActions>
      )}
    </StyledCard>
  );
};
