import {
  SHOW_ADD_USER_DIALOG,
  HIDE_ADD_USER_DIALOG,
  UPDATE_ADD_USER_DIALOG_DATA,
  SET_SAVING
} from "./constants";

export const setSaving = isSaving => ({
  type: SET_SAVING,
  payload: { isSaving }
});

export const showAddUserDialog = () => ({
  type: SHOW_ADD_USER_DIALOG,
  payload: {}
});

export const hideAddUserDialog = () => ({
  type: HIDE_ADD_USER_DIALOG,
  payload: {}
});

export const updateAddUserDialogData = updates => ({
  type: UPDATE_ADD_USER_DIALOG_DATA,
  payload: { updates }
});
