import React, { forwardRef } from "react";
import { StyledButton } from "./button/button.styles";
import CircularProgress from "./CircularProgress";

export default forwardRef((props, ref) => {
  if (props.loading) {
    const { children, progressProps, ...rest } = props;
    return (
      <StyledButton {...rest} ref={ref}>
        <CircularProgress className="absolute" size={30} {...progressProps} />
        {children}
      </StyledButton>
    );
  }
  return <StyledButton {...props} ref={ref} />;
});
