import styled from 'styled-components';

export const Outer = styled.div`
  overflow: auto;
`;

export const Inner = styled.div`
  max-width: 50rem;
  padding: 2rem;
  margin: auto;
  > h1 {
    padding-bottom: 1rem;
  }
  > h2 {
    padding-bottom: 0.5rem;
  }
  
  > p {
    padding-bottom: 1rem;
  }
`;
