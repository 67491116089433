import React from "react";
import EntryModel from "../../../../../../models/Entry";
import { format } from "../../../../../../date-fns-wrappers";
import { StyledEntry } from "./Entry.styles";
import * as yup from "yup";
import { promiseDelay } from "../../../../../../helpers";
import useGlobalSnackbar from "../../../../../../hooks/useGlobalSnackbar";
import { useTranslation } from "react-i18next";

const schema = yup
  .number()
  .required()
  .min(0)
  .max(24);
interface IProps {
  onBlur: (hours: number, date: Date, entry?: EntryModel) => void;
  entry?: EntryModel;
  date: Date;
}
export function Entry(props: IProps) {
  const { onBlur, date, entry } = props;
  const [value, setValue] = React.useState<string>(
    entry?.hours.toString() || ""
  );
  const { t } = useTranslation();
  const { update: updateSnackbar } = useGlobalSnackbar();
  const showErrorSnackbar = async (message: string) => {
    updateSnackbar({
      open: true,
      message: `${t("common.error")}! ${message} 🚨 `,
      alert: { severity: "error" }
    });
    await promiseDelay(2000);
    updateSnackbar({
      open: false
    });
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const regex = /^\d+([.,]\d*)?$/;
      if (!regex.test(e.target.value)) {
        return;
      }
      setValue(e.target.value);
    } catch (error) {
      await showErrorSnackbar(error.message);
    }
  };
  const handleOnBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur && value) {
      try {
        const hours = EntryModel.parseHours(value);
        if (entry?.hours === hours) {
          return;
        }
        await schema.validate(hours);
        onBlur(hours, date, props.entry);
      } catch (error) {
        await showErrorSnackbar(error.message);
      }
    }
  };
  const title = format(date, "EEE. d").toUpperCase();
  return (
    <StyledEntry
      label={title}
      inputProps={{
        max: 24,
        min: 0,
        step: 0.5
      }}
      onChange={handleChange}
      value={value}
      // defaultValue={props.entry?.hours}
      onBlur={handleOnBlur}
      variant={"filled" as any}
    />
  );
}
