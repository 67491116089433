import React, { useEffect, useMemo } from "react";
import Page from "@superprofit/core-react-components/atoms/Page";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import PeriodSelect from "../../../components/molecules/PeriodSelect";
import { watchGetHoursByUsersReport } from "../../../redux/modules/api/report/actions";
import { setPeriod } from "../../../redux/modules/ui/report/period/actions";
import { Button } from "@superprofit/core-react-components/atoms";
import FilterIcon from "@material-ui/icons/FilterList";
import Badge from "@superprofit/core-react-components/atoms/Badge";
import FilterDialog from "./shared/FilterDialog";
import { showFilterDialog } from "../../../redux/modules/ui/report/filtersDialog/actions";
import ProjectsTable from "./projects/ProjectsTable";
import { resetFilters } from "../../../redux/modules/ui/report/filters/actions";
import { filterArchivedProjects } from "../../../utils/ProjectStats";
import { useTranslation } from "react-i18next";
import useAllProjectsEntries from "../../../hooks/useAllProjectsEntries";
import { endOfYear, startOfYear } from "date-fns";
import useStatsFromEntries, {
  StatsType
} from "../../../hooks/useStatsFromEntries";
import useProjects from "../../../hooks/useProjects";
import useCustomers from "../../../hooks/useCustomers";
import useUsers from "../../../hooks/useUsers";

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { period } = useSelector(state => state.ui.report);

  const { data: projects = [], isLoading: projectsLoading } = useProjects();
  const { data: customers = [], isLoading: customersLoading } = useCustomers();
  const { data: users = [], isLoading: usersLoading } = useUsers();

  const currentYearDate = new Date(period.year, 1, 1);
  const startOfYearDate = startOfYear(currentYearDate);
  const endOfYearDate = endOfYear(currentYearDate);
  const {
    data: allProjectsEntries,
    isLoading: entriesLoading
  } = useAllProjectsEntries({
    fromDate: startOfYearDate,
    toDate: endOfYearDate
  });
  const byWeekAllYear = useStatsFromEntries(allProjectsEntries, {
    fromDate: startOfYearDate,
    toDate: endOfYearDate,
    type: StatsType.XStatsUserProjectHoursByWeek
  });
  const monthStats = useMemo(() => {
    return byWeekAllYear.filter(
      s => s.month === period.month && s.year === period.year
    );
  }, [byWeekAllYear, period]);
  const { hasFilters, filters } = useSelector(state => state.ui.report.filters);

  const activeProjects = useMemo(() => {
    if (!projects) return [];
    return filterArchivedProjects(projects, byWeekAllYear);
  }, [projects, byWeekAllYear]);

  useEffect(() => {
    return () => dispatch(resetFilters());
  }, []);

  const handleOnFilter = () => {
    dispatch(showFilterDialog(filters));
  };

  const handleOnChangePeriod = ({ year, month }) => {
    dispatch(setPeriod(year, month));
  };

  const loading =
    projectsLoading || customersLoading || usersLoading || entriesLoading;

  return (
    <Page context={t("common.management")} title={t("common.report")}>
      <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={6} component="div">
          <PeriodSelect
            period={period}
            showNavigation
            onChange={handleOnChangePeriod}
            showWeek={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} component="div" justify="flex-end">
          <Grid container spacing={2} justify="flex-end">
            <Grid item></Grid>
            <Grid item>
              <Badge color="error" variant="dot" invisible={!hasFilters}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleOnFilter}
                  disabled={loading}
                  startIcon={<FilterIcon />}
                >
                  {t("common.filter")}
                </Button>
              </Badge>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 20 }} component="div">
        <Grid item xs={12} component="div">
          <ProjectsTable
            title={t("common.hoursByUser")}
            filters={filters}
            stats={monthStats}
            users={users}
            customers={customers}
            projects={activeProjects}
            loading={loading}
            period={period}
            columns={{
              expand: true,
              avatar: true,
              hours: true,
              billable: true,
              weekDistribution: true,
              relativeShare: true
            }}
          />
        </Grid>
      </Grid>

      <FilterDialog />
      {/*<CreatePDFDialog />*/}
    </Page>
  );
};
