import React, { useState } from "react";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import Button from "@superprofit/core-react-components/atoms/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Input,
  NumberFormatIntl, CircularProgress,
} from '@superprofit/core-react-components/atoms';
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

export default ({ product, onPurchase, selected, loading, owned, loadingSession }) => {
  // const theme = useTheme();
  // const { state, dispatch } = useContext(ReducerContext);
  // const owned =
  //   state?.accountDetails?.subscriptions.filter((s) => s?.plan.id === price?.id)
  //     .length > 0;
  // // const active = state?.billingInformation?.stripePriceId === price?.id;
  const [seats, setSeats] = useState(owned ? owned.quantity : 1);
  const handleInputChange = (e) => {
    try {
      const nr = parseInt(e.currentTarget.value, 10);
      setSeats(nr);
    } catch (e) {
      console.error("[per-seat-subscription] Error parsing number to int", e);
    }
  };
  const increment = () => {
    const s = seats + 1 > 999 ? 999 : seats + 1;
    setSeats(s);
    // onChange(s);
  };
  const decrement = () => {
    const s = seats - 1 < 1 ? 1 : seats - 1;
    setSeats(s);
    // onChange(s);
  };

  const price = !loading && product.prices[0];

  const billed =
    price?.recurring?.interval === "month"
      ? "monthly"
      : "unsupported billing interval";

  const intlOptions = {
    style: "currency",
    currency: price?.currency?.toUpperCase(),
  };

  const getButtonTitle = () => {
    // if (owned && seats === owned.quantity) {
    //   return 'Owned'
    // }
    // if (selected) return 'Selected';
    return 'Checkout';
  }
  const tiers = (price?.tiers || []).filter(t => t.unit_amount > 0);
  const freeTiers = (price?.tiers || []).filter(t => t.unit_amount === 0);

  return (
    <Card raised={selected || owned} style={{maxWidth: '20rem'}}>
      <CardContent>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            {loading ? (
              <Skeleton variant="text" width={200} height={20} />
            ) : (
              <Typography variant="h1" component="h3" gutterBottom>
                {product.name}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              {freeTiers?.map((t, index, arr) => t.up_to && (
                <Grid item xs={12}>
                  <Typography
                    align="right"
                    color="textSecondary"
                    variant="caption"
                  >
                    {t.up_to === 1 ? `One FREE user, then` : `First ${t.up_to} users free, then`}
                  </Typography>
                </Grid>
              ))}
              {loading
                ? [0, 0, 0].map(() => (
                  <Grid item xs={12}>
                    <Skeleton variant="text" width={150} />
                  </Grid>
                ))
                : tiers[0] && (
                <Grid item xs={12}>
                  <Typography variant="h3" color="primary">
                    <NumberFormatIntl
                      locales="en"
                      number={tiers[0].unit_amount / 100}
                      options={intlOptions}
                    />{" "}
                    per user {billed}
                  </Typography>
                </Grid>
              )}
              {tiers?.map((t, index, arr) => (
                <Grid item xs={12}>
                  <Typography
                    align="right"
                    color="textSecondary"
                    variant="caption"
                  >
                    <NumberFormatIntl
                      locales="en"
                      number={t.unit_amount / 100}
                      options={intlOptions}
                    />{" "}
                    per user{" "}
                    {arr[index - 1] && arr[index - 1].up_to
                      ? ` above ${arr[index - 1].up_to}`
                      : `up top ${t.up_to}`}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {loading && (
            <Grid item xs={12}>
              <Skeleton variant="text" width={150} />
            </Grid>
          )}
          {!loading && product?.metadata?.shortDescription && (
            <Grid item xs={12}>
              <Typography>{product.metadata.shortDescription}</Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {loading &&
              [0, 0].map(() => (
                <Grid item xs={12}>
                  <Skeleton variant="text" width={150} />
                </Grid>
              ))}
              {!loading && product?.metadata?.salesBulletOne && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary">
                    {product.metadata.salesBulletOne}
                  </Typography>
                </Grid>
              )}
              {!loading && product?.metadata?.salesBulletTwo && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary">
                    {product.metadata.salesBulletTwo}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {loading ? (
                <Grid item>
                  <Skeleton variant="text" width={150} height={32} />
                </Grid>
              ) : (
                <>
                  <Grid item>
                    <Grid container justify="center">
                      <Grid item>
                        <IconButton onClick={decrement}>
                          <RemoveIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Input
                          style={{ width: "3rem", textAlign: "center"}}
                          onChange={handleInputChange}
                          value={seats}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton onClick={increment}>
                          <AddIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {loading ? (
                <Grid item>
                  <Skeleton variant="text" width={100} height={32} />
                </Grid>
              ) : (
                <Grid item>
                  {loadingSession ?               <CircularProgress size={23} /> :   <Button disabled={selected || owned?.quantity === seats} onClick={() => onPurchase(seats, price?.id)}>
                    {getButtonTitle()}
                  </Button>}

                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
