import React from "react";
import { ApprovalRecord } from "@superprofit/timet-react-client/src/models/ApprovalRecords";
import Label from "../../../components/atoms/Label";
import { useTranslation } from "react-i18next";

type StatusProps = {
  approvalRecord?: ApprovalRecord;
  status?:
    | "approved"
    | "submitted"
    | "rejected"
    | "unsubmitted"
    | "partially-submitted";
};
export const Status = ({ approvalRecord, status }: StatusProps) => {
  const { t } = useTranslation();

  if (approvalRecord?.status === "approved" || status === "approved")
    return (
      <Label variant="filled" color="success" label={t("common.approved")} />
    );

  if (approvalRecord?.status === "submitted" || status === "submitted")
    return (
      <Label variant="filled" color="warning" label={t("common.submitted")} />
    );

  if (status === "partially-submitted")
    return (
      <Label
        variant="filled"
        color="info"
        label={t("common.partiallySubmitted")}
      />
    );

  return (
    <Label variant="filled" color="error" label={t("common.notSubmitted")} />
  );
};
