import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Timet } from "@superprofit/timet-types";
import { ApprovalRecord } from "../models/ApprovalRecords";

export const useApprovalRecordsCreateMutation = () => {
  const queryClient = useQueryClient();

  const create = (payload: Timet.Api.ApprovalRecords.CreatePayload) => {
    return ApprovalRecord.create(payload);
  };

  return useMutation(create, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(ApprovalRecord.COLLECTION)
      });
    }
  });
};
