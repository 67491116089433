import {
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  all,
} from "redux-saga/effects";
import {WATCH_BATCH, WATCH_GET, WATCH_REMOVE, WATCH_SAVE} from "./constants";
import {
  get,
  getFailure,
  getSuccess,
  saveSuccess,
  saveFailure,
  save,
  watchGetUserGroups,
  watchSaveUserGroup,
  remove,
  watchRemoveUserGroup,
  removeSuccess,
  removeFailure,
  watchBatchUserGroups,
  batch,
  batchSuccess,
  batchFailure,
} from "./actions";
import UserGroup from "../../../../models/UserGroup";
import { RootState } from "../../../../index";

export const getActiveWorkspace = ({ api: { auth } }: RootState) =>
  auth.activeWorkspaceId;
export const getUserEmail = ({
  api: {
    auth: { user },
  },
}: RootState) => user.email;

export const getUserGroups = function* (
  args: ReturnType<typeof watchGetUserGroups>
) {
  try {
    yield put(get());
    const workspace: string = yield select(getActiveWorkspace);

    const userGroups: UserGroup[] = yield call(
      UserGroup.list,
      workspace,
      args.payload.users
    );

    yield put(getSuccess(userGroups));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(getFailure(e.toString()));
    }
  }
};
export const removeUserGroup = function* (
  args: ReturnType<typeof watchRemoveUserGroup>
) {
  const { payload } = args;
  const { userGroup } = payload;
  try {
    yield put(remove(userGroup));
    const workspace: string = yield select(getActiveWorkspace);
    yield call(UserGroup.delete, workspace, userGroup);

    yield put(removeSuccess(userGroup));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(removeFailure(userGroup, e.toString()));
    }
  }
};

export const saveUserGroup = function* (
  args: ReturnType<typeof watchSaveUserGroup>
) {
  const { payload } = args;
  const { userGroup } = payload;

  try {
    const workspace: string = yield select(getActiveWorkspace);
    const user: string = yield select(getUserEmail);

    yield put(save(userGroup /*isNew*/));

    let savedUserGroup: UserGroup;
    // New userGroup
    if (!userGroup.id) {
      savedUserGroup = yield call(UserGroup.create, workspace, user, userGroup);
    } else {
      savedUserGroup = yield call(UserGroup.update, workspace, user, userGroup);
    }

    yield put(saveSuccess(savedUserGroup));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(saveFailure(userGroup, e.toString()));
    }
  }
};
export const batchUserGroups = function* (
  args: ReturnType<typeof watchBatchUserGroups>
): any {
  const { payload } = args;
  const { userGroups } = payload;

  try {
    const workspace: string = yield select(getActiveWorkspace);
    const user: string = yield select(getUserEmail);

    yield put(batch(userGroups /*isNew*/));

    const savedUserGroups = yield call(
      UserGroup.batchUpdate,
      workspace,
      user,
      userGroups
    );

    yield put(batchSuccess(savedUserGroups));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(batchFailure(userGroups, e.toString()));
    }
  }
};

export const getUserGroupsSaga = function* () {
  yield takeLatest(WATCH_GET, getUserGroups);
};
export const saveUserGroupSaga = function* () {
  yield takeEvery(WATCH_SAVE, saveUserGroup);
};
export const removeUserGroupSaga = function* () {
  yield takeEvery(WATCH_REMOVE, removeUserGroup);
};
export const batchUserGroupsSaga = function* () {
  yield takeEvery(WATCH_BATCH, batchUserGroups);
};

export default function* () {
  yield all([getUserGroupsSaga(), saveUserGroupSaga(), removeUserGroupSaga(), batchUserGroupsSaga()]);
}
