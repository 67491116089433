import React from "react";
import InvoiceCard from "./InvoiceCard";
import FormDialog from "@superprofit/core-react-components/molecules/FormDialog";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyObject } from "../../../helpers";
import { Dialog } from "@superprofit/core-react-components/atoms";
import { toggleView } from "../../../redux/modules/ui/invoices/view/actions";

const ViewInvoiceDialog = () => {
  const dispatch = useDispatch();
  const invoice = useSelector(
    ({
      ui: {
        invoices: { view }
      }
    }) => view.invoice
  );
  const open = useSelector(
    ({
      ui: {
        invoices: { view }
      }
    }) => view.open
  );

  const handleClose = () => {
    dispatch(toggleView());
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <InvoiceCard
        onClose={handleClose}
        aria-labelledby="Invoice"
        open={open}
        invoice={invoice}
      />
    </Dialog>
  );
};

export default ViewInvoiceDialog;
