import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import Entry from "../models/Entry";
import { endOfMonth, startOfMonth } from "date-fns";

export const getKey = (
  workspace: string,
  id: string,
  year: number,
  month: number
) => [workspace, id, Entry.collectionName, year, month];

const useUserTimesheetMonth = ({
  id,
  year,
  month
}: {
  id: string;
  year: number; // isoWeekYear
  month: number; // month
}) => {
  const workspace = useWorkspace();

  const startOfMonthDate = startOfMonth(new Date(year, month - 1, 1));
  const endOfMonthDate = endOfMonth(new Date(year, month - 1, 1));
  const get = () =>
    Entry.getUserEntriesRange(workspace, id, startOfMonthDate, endOfMonthDate);

  const query = useQuery(getKey(workspace, id, year, month), get, {
    enabled: !!id && !!year && !!month
  });
  return query;
};

export default useUserTimesheetMonth;
