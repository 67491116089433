import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import Project from "../models/Project";

export const getKey = (workspace: string, projectId: string | null) => [
  workspace,
  Project.collectionName,
  projectId
];
const useProject = (projectId: string | null) => {
  const workspace = useWorkspace();

  const get = () => {
    if (!projectId) return;
    return Project.get(workspace, projectId);
  };
  const query = useQuery(getKey(workspace, projectId), get, {
    enabled: !!projectId,
    staleTime: 1000 * 60 * 5 //five minutes stale time. rarely changes
  });
  return query;
};

export default useProject;
