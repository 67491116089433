import { useMemo } from "react";
import Project from "../models/Project";
import useUserGroups from "./useUserGroups";
import UserGroup from "../models/UserGroup";
import useUsersMap from "@superprofit/timet-react-client/src/hooks/useUsersMap";

const useUniqueUsersInProjects = (projects: Project[] = []) => {
  const { data: userGroups } = useUserGroups();
  const allUsersMap = useUsersMap();
  const data = useMemo(() => {
    const users = new Set<string>();
    for (let p of projects) {
      if (p.team) {
        for (let u of p.team) {
          users.add(u);
        }
      }
      if (p.userGroups && userGroups?.length > 0) {
        for (let ug of p.userGroups) {
          const g = userGroups.find((usrGrp: UserGroup) => usrGrp.id === ug);
          if (g) {
            if (g.users?.includes("all")) {
              return new Set(Array.from(allUsersMap.keys()));
            } else {
              for (let u of g.users) {
                users.add(u);
              }
            }
          }
        }
      }
    }
    return users;
  }, [userGroups, projects, allUsersMap]);
  return data;
};

export default useUniqueUsersInProjects;
