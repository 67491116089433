import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { GET, GET_SUCCESS } from "../../../api/customerOverview/constants";
import { setLoading, setCustomer } from "./actions";
import { SAVE_SUCCESS } from "../../../api/customers/constants";

export const getCurrentCustomer = ({
  ui: {
    customerOverview: {
      customer: { customer }
    }
  }
}) => customer;

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const handleGetSuccess = function*(args) {
  try {
    const {
      payload: { customer }
    } = args;
    yield put(setCustomer(customer));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleSaveSuccess = function*(args) {
  try {
    const {
      payload: { customer }
    } = args;

    const currentCustomer = yield select(getCurrentCustomer);

    if (!currentCustomer.id || currentCustomer?.id !== customer?.id) return; // prevent unnecessary recalculations

    yield put(setCustomer(customer));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleGetSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export const handleGetSuccessSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleGetSuccess);
};

export const handleSaveSuccessSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleSaveSuccess);
};

export default function*() {
  yield all([handleGetSuccessSaga(), handleGetSaga(), handleSaveSuccessSaga()]);
}
