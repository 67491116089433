import {
  HIDE_BUDGET_DIALOG,
  SHOW_BUDGET_DIALOG,
  UPDATE_BUDGET_DIALOG_DATA
} from "./constants";

export const showBudgetDialog = () => ({
  type: SHOW_BUDGET_DIALOG,
  payload: {}
});

export const hideBudgetDialog = () => ({
  type: HIDE_BUDGET_DIALOG,
  payload: {}
});

export const updateBudgetDialogData = updates => ({
  type: UPDATE_BUDGET_DIALOG_DATA,
  payload: { updates }
});
