import React, {useEffect} from 'react';
import {Page, Button} from '@superprofit/core-react-components/atoms';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux';
import Plans from './subscription/Plans';
import Stripe from '@superprofit/core-react-components/organisms/Stripe';
import workspaceActions from '@superprofit/core-redux-saga-modules/Workspaces/actions';
import {actions as stripeAccountDetails} from '../../redux/modules/api/stripeAccountDetails';
import {publicKey, cloudFunctionsBaseUrl} from '../../config/stripe';
import {logEvent, ANALYTICS_EVENT} from '../../firebase';
import {actionTypes} from '@superprofit/core-react-components/src/organisms/stripe/newstuff/reducer';
import PerSeatProduct from './subscription/PerSeatProduct';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

export default ({routes = []}) => {
  const dispatch = useDispatch();
  const accountDetails = useSelector(
    (state) => state.api.stripeAccountDetails.entry
  );
  const idToken = useSelector((state) => state.api.auth.idTokenResult.token);
  const workspaceName = useSelector(state => state.api.auth?.activeWorkspaceId);
  const [product, setProduct] = React.useState(null);


  useEffect(() => {
    // dispatch(workspaceActions.watchListen());
  }, [dispatch]);
  const match = useRouteMatch();

  useEffect(() => {
    const getData = async () => {
      // dispatch({ type: actionTypes.toggleLoadingProducts });
      const productsResponse = await fetch(
        `${cloudFunctionsBaseUrl}/getProducts?expand=data.prices`
      );
      if (!productsResponse.ok) {
        console.error('Err loading products');
      } else {
        const json = await productsResponse.json();
        const p = json.find(pr => pr.name === "Timet Unlimited" && pr.active)
        const priceResponse = await fetch(
          `${cloudFunctionsBaseUrl}/getPrices?expand=data.tiers&product=${p.id}`
        );
        if (!priceResponse.ok) {
         console.error('Err loading prices')
          return;
        }
        p.prices = await priceResponse.json();
        setProduct(p);
      }
    };
    getData().catch(e => console.error(e));

    dispatch(stripeAccountDetails.watchGetEntry());
  }, [dispatch]);
  useEffect(() => {
    if (workspaceName) {
      logEvent(ANALYTICS_EVENT.SUBSCRIPTION_VISITED, {workspaceName});
    }
  }, [workspaceName]);

  const getPortal = async () => {
    try {
      const res = await fetch(`${cloudFunctionsBaseUrl}/getCustomerPortalLink`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          authorization: idToken,
        },
        body: JSON.stringify({
          customerId: accountDetails?.customerId,
          returnUrl: `${window.location.origin}/app/${workspaceName}`
        })
      });
      if (res.ok) {
        const json = await res.json();
        if (json?.url) {
          window.location.href = json.url;
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const filter = (p) => {
    return p?.metadata?.identifier === 'timet';
  };

  const onProductChange = async (seats, priceId) => {
    try {
      const res = await fetch(`${cloudFunctionsBaseUrl}/createSubscriptionCheckoutSession`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          authorization: idToken,
        },
        body: JSON.stringify({
          customerId: accountDetails?.customerId,
          quantity: seats,
          priceId,
          workspaceId: workspaceName
          // returnUrl: `${window.location.origin}/app/${workspaceName}`
        })
      });

      if (res.ok) {
        const json = await res.json();
        if (json?.url) {
          window.location.href = json.url;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Switch>
      {routes.map(r => <Route
                              path={`${match.url}${r.path}`}

                              component={r.component} />)}
    </Switch>
  );
};
