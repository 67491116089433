import React, { forwardRef, Fragment, useMemo, useState } from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import DistributionBar from "../../../../components/atoms/DistributionBar";
import DurationFormat from "../../../../components/atoms/DurationFormat";
import UserAvatar from "../../../../components/molecules/UserAvatar";
import UserLink from "../../../../components/molecules/UserLink";
import NumberFormat from "react-number-format";
import IconButton from "@superprofit/core-react-components/atoms/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import WeekDistribution from "../../../../components/atoms/WeekDistribution";
import UserProjectMonthItem from "./SalaryProjectItem";
import ProgressBar from "../../../../components/atoms/ProgressBar";
import CheckableAvatar from "@superprofit/core-react-components/molecules/CheckableAvatar";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../redux/modules/ui/report/users/actions";
import ConnectedCurrencyFormat from "../../../../components/organisms/ConnectedCurrencyFormat";
import { RootState } from "../../../../index";
import { ISalaryItem } from "../shared/types";
import User from "../../../../models/User";
import { useTranslation } from "react-i18next";

export default forwardRef<HTMLDivElement, ISalaryItem>(
  ({ data, projectsById, weekNumbers, columns, ...rest }, ref) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
      user,
      hours,
      relativeHours,
      byProjectWeek,
      byWeek,
      salary,
      projectHours,
      projectMoney,
      projectSalary
    } = data;

    const isSelected = useSelector(
      (state: RootState) =>
        state.ui.report.users.selected.findIndex(
          (u: User) => u.id === user.id
        ) > -1
    );
    const handleChecked = () => {
      dispatch(selectUser(user));
    };

    const weekDistribution = useMemo(
      () =>
        weekNumbers.map(w => ({
          tooltip: `${t("common.week")} ${w}`,
          value: byWeek[w] || 0,
          color: "secondary"
        })),
      [weekNumbers, byWeek]
    ) as { tooltip: string; value: number; color: "secondary" }[];

    const distributionDataset = useMemo(
      () =>
        Object.keys(projectHours)
          .map(p => ({
            value: projectHours[p],
            color: projectsById[p].color,
            tooltip: (
              <DurationFormat
                value={projectHours[p]}
                prefix={`${projectsById[p].name}: `}
              />
            )
          }))
          .filter(v => !!v.value),
      [projectHours]
    );

    const projectList = useMemo(() => {
      const res = Object.keys(projectHours).map(p => ({
        project: projectsById[p],
        percentage: ((projectHours[p] || 0) / hours) * 100,
        hours: projectHours[p],
        byWeek: byProjectWeek[p] || {},
        money: projectMoney[p],
        salary: projectSalary[p] || 0
      }));

      res.sort((a, b) => {
        if ((b.hours || 0) < (a.hours || 0)) return -1;
        if ((b.hours || 0) > (a.hours || 0)) return 1;
        return 0;
      });
      return res;
    }, [projectHours]);

    return (
      <Fragment>
        <TableRow {...rest} ref={ref} key="main">
          {columns.expand && (
            <TableCell style={{ maxWidth: 80, width: 80 }}>
              {projectList && projectList.length ? (
                <IconButton
                  aria-label="expand row"
                  size="medium"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              ) : null}
            </TableCell>
          )}

          {columns.avatar && (
            <TableCell style={{ maxWidth: 80, width: 80 }}>
              <CheckableAvatar checked={isSelected} onChange={handleChecked}>
                <UserAvatar
                  user={{
                    email: user?.email,
                    displayName: user.displayName || undefined,
                    picture: user.picture || undefined
                  }}
                />
              </CheckableAvatar>
            </TableCell>
          )}

          <TableCell>
            <UserLink
              name={user.displayName || undefined}
              email={user.email}
              hideEmail
            />
          </TableCell>
          {columns.customer && (
            <TableCell>
              <br />
            </TableCell>
          )}

          {columns.weekDistribution && (
            <TableCell align="right">
              <WeekDistribution weeks={weekDistribution} />
            </TableCell>
          )}

          {columns.hours && (
            <TableCell align="right">
              <DurationFormat value={hours} component="b" />
            </TableCell>
          )}

          {columns.relative && (
            <TableCell align="right">
              <ProgressBar
                progress={relativeHours}
                color="primary"
                align="left"
              />
            </TableCell>
          )}

          {columns.projectDistribution && (
            <TableCell>
              <DistributionBar dataset={distributionDataset} />
            </TableCell>
          )}

          {columns.salary && (
            <TableCell align="right">
              <b>
                <ConnectedCurrencyFormat number={salary} />
              </b>
            </TableCell>
          )}
        </TableRow>

        {columns.expand &&
          open &&
          projectList.map(data => (
            <UserProjectMonthItem
              key={`${user.email}-${data.project.id}`}
              user={user}
              project={data.project}
              hours={data.hours}
              money={data.money}
              salary={data.salary}
              percentage={data.percentage}
              byWeek={data.byWeek}
              weekNumbers={weekNumbers}
              columns={columns}
            />
          ))}
      </Fragment>
    );
  }
);
