import {
  HIDE_FILTER_DIALOG,
  SHOW_FILTER_DIALOG,
  UPDATE_FILTER_DIALOG_DATA,
  RESET_FILTER_DIALOG
} from "./constants";

export const showFilterDialog = filters => ({
  type: SHOW_FILTER_DIALOG,
  payload: { filters }
});

export const hideFilterDialog = () => ({
  type: HIDE_FILTER_DIALOG,
  payload: {}
});

export const updateFilterDialogData = updates => ({
  type: UPDATE_FILTER_DIALOG_DATA,
  payload: { updates }
});

export const resetFilterDialog = () => ({
  type: RESET_FILTER_DIALOG
});
