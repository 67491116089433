import { all, put, takeLatest } from "@redux-saga/core/effects";
import { GET, GET_SUCCESS } from "../../../api/projectOverview/constants";
import { setLoading, setCustomer } from "./actions";

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const handleGetSuccess = function*(args) {
  try {
    const {
      payload: { customer }
    } = args;
    yield put(setCustomer(customer));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleGetSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export const handleGetSuccessSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleGetSuccess);
};

export default function*() {
  yield all([handleGetSuccessSaga(), handleGetSaga()]);
}
