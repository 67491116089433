import { takeLatest, call, put, select, all } from "redux-saga/effects";
import { WATCH_GET } from "./constants";
import { get, getFailure, getSuccess } from "./actions";
import { db } from "../../../../firebase";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;

export const doGet = async (workspace, year, month) => {
  const snapshot = await db
    .collection("workspaces")
    .doc(workspace)
    .collection("xstats_user_project_hours_by_week")
    .where("year", "==", year)
    .get();

  const all = [];
  snapshot.forEach(doc => {
    all.push({ ...doc.data(), id: doc.id });
  });

  const monthBeforeSnapshot = await db
    .collection("workspaces")
    .doc(workspace)
    .collection("xstats_user_project_hours_by_week")
    .where("year", "==", month === 1 ? year - 1 : year)
    .where("month", "==", month === 1 ? 12 : month - 1)
    .get();

  const monthBefore = [];
  monthBeforeSnapshot.forEach(doc => {
    monthBefore.push({ ...doc.data(), id: doc.id });
  });

  return {
    raw: all,
    monthBefore
  };
};

export const getCompanyStats = function* getCompanyStats(args) {
  const { year, month } = args.payload;
  try {
    yield put(get());
    const workspace = yield select(getActiveWorkspace);
    const stats = yield call(doGet, workspace, year, month);
    yield put(getSuccess(stats));
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const getCompanyStatsSaga = function* getCompanyStatsSaga() {
  yield takeLatest(WATCH_GET, getCompanyStats);
};

export default function*() {
  yield all([getCompanyStatsSaga()]);
}
