import { all, put, takeLatest } from "@redux-saga/core/effects";
import {
  PATCH_MULTIPLE,
  PATCH_MULTIPLE_SUCCESS
} from "../../../api/projects/constants";
import { setSaving } from "./actions";

export const handleSaveSuccess = function*(args) {
  try {
    yield put(setSaving(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleSave = function*(args) {
  yield put(setSaving(true));
};

export const handleSaveSuccessSaga = function*() {
  yield takeLatest(PATCH_MULTIPLE_SUCCESS, handleSaveSuccess);
};

export const handleSaveSaga = function*() {
  yield takeLatest(PATCH_MULTIPLE, handleSave);
};

export default function*() {
  yield all([handleSaveSuccessSaga(), handleSaveSaga()]);
}
