import React, { useMemo } from "react";
import { PageContent } from "../../../components/atoms/Page";
import Grid from "@material-ui/core/Grid";
import PeriodSelect, {
  Period
} from "../../../components/molecules/PeriodSelect";
import UserStats from "./overview/UserStats";
import { setPeriod } from "../../../redux/modules/ui/userOverview/period/actions";
import { useDispatch, useSelector } from "react-redux";
import ProjectsTable from "../reports/projects/ProjectsTable";
import useTimetUser from "../../../hooks/useTimetUser";
import useUserGroups from "../../../hooks/useUserGroups";
import { useParams } from "react-router-dom";
import useFilterDialog from "./overview/useFilterDialog";
import FilterDialog from "./overview/FilterDialog";
import useUserProjects from "../../../hooks/useUserProjects";
import useUnarchivedProjects from "../../../hooks/useUnarchivedProjects";
import useUserTimesheetMonth from "../../../hooks/useUserTimesheetMonth";
import { useEwork } from "../integrations/ework/useEwork";
import { RootState } from "../../..";
import Project from "../../../models/Project";

export interface UserSummary {
  user: string;
  project: string;
  week: number;
  year: number;
  month: number;
  total: number;
}

export default () => {
  const dispatch = useDispatch();
  const period = useSelector(
    (state: RootState) => state.ui.userOverview.period
  );
  const { id: email } = useParams();
  const {
    data: allProjects,
    isLoading: projectsLoading
  } = useUnarchivedProjects();
  const userProjects = useUserProjects(allProjects, email);
  const { data: userGroups } = useUserGroups({ ids: [email] });
  const { open, filters } = useFilterDialog();
  const { data: user, isLoading: timetUserLoading } = useTimetUser({
    id: email
  });

  const { sync, isEnabled: eworkSyncIsEnabled } = useEwork();

  const {
    isLoading: timesheetEntriesLoading,
    data: entries = []
  } = useUserTimesheetMonth({
    id: email,
    ...period
  });

  const nonUserProjectsWithEntries = useMemo(() => {
    const projectIdSet = new Set();

    for (const entry of entries) {
      if (!userProjects.find((p: Project) => p.id === entry.project)) {
        projectIdSet.add(entry.project);
      }
    }
    return (allProjects || []).filter((p: Project) => projectIdSet.has(p.id));
  }, [allProjects, userProjects, entries]);

  const projects = [...userProjects, ...nonUserProjectsWithEntries];

  const isLoading =
    projectsLoading || timetUserLoading || timesheetEntriesLoading;

  const handleOnChangePeriod = ({ year, month }: Period) => {
    dispatch(setPeriod(year, month));
  };

  const handleOnEworkSync = (project: Project) => {
    sync({
      entries: entries.filter(e => e.project === project.id),
      projects: [project]
    });
  };

  const monthStats = useMemo(() => {
    const weekTotals = entries
      .filter(s => s.month === period.month && s.year === period.year)
      .reduce((acc, item) => {
        const weekKey =
          user +
          "-" +
          item.project +
          "-" +
          item.isoWeekYear +
          "-" +
          item.isoWeek;
        if (!acc[weekKey]) {
          acc[weekKey] = {
            user: item.user,
            project: item.project,
            week: item.isoWeek,
            year: item.isoWeekYear,
            month: item.month,
            total: 0
          };
        }

        // Accumulate hours
        acc[weekKey].total += item.hours;

        return acc;
      }, {} as { [weekKey: string]: UserSummary });
    return Object.values(weekTotals);

    //Race condition. 1.december 2023 many people had missing hours in their overview section.
    // return currentYearByWeek.filter(
    //   s => s.month === period.month && s.year === period.year
    // );
  }, [entries, period]);

  return (
    <PageContent>
      <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
        <Grid item xs={12} component="div" justify="flex-start">
          <PeriodSelect
            period={period}
            showNavigation
            onChange={handleOnChangePeriod}
            showWeek={false}
          />
        </Grid>

        <Grid item xs={12} component="div">
          <UserStats
            user={user}
            projects={projects}
            month={period.month}
            year={period.year}
            loading={isLoading}
            currentMonthStats={monthStats}
          />
        </Grid>

        <Grid item xs={12} component="div">
          <ProjectsTable
            stats={monthStats}
            users={user ? [user] : []}
            userGroups={userGroups}
            filters={filters}
            projects={projects}
            // projects={filters.projects.length > 0 ? filters.projects : projects}
            onFilterOpen={open}
            loading={isLoading}
            period={period}
            onSyncProject={handleOnEworkSync}
            columns={{
              sync: eworkSyncIsEnabled,
              label: true,
              avatar: true,
              hours: true,
              weekDistribution: true,
              relative: true
            }}
            show={{
              weekDistributionSum: true
            }}
          />
          <FilterDialog />
        </Grid>

        {/*<Grid item xs={12} component="div">*/}
        {/*  <ActivityCard*/}
        {/*    projects={projects}*/}
        {/*    loading={isLoading}*/}
        {/*    user={user}*/}
        {/*    year={period.year}*/}
        {/*    stats={currentYearByWeek}*/}
        {/*  />*/}
        {/*</Grid>*/}
      </Grid>
    </PageContent>
  );
};
