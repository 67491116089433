import { useMemo } from "react";
import Entry from "../../../models/Entry";

export const getKey = (
  projectId: string | null,
  year: number,
  dayOfYear: number
) => `${projectId}-${year}-${dayOfYear}`;
const useEntriesMap = (entries: Entry[]) => {
  return useMemo(() => {
    const m = new Map<string, Entry>();
    (entries || []).forEach((u: Entry) => {
      if (u.id) m.set(getKey(u.project, u.year, u.dayOfYear), u);
    });
    return m;
  }, [entries]);
};

export default useEntriesMap;
