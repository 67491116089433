import React, {
  forwardRef,
  InputHTMLAttributes,
  useRef,
  useState
} from "react";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@superprofit/core-react-components/atoms";
import { StyledEntry } from "./Timesheet.styles";
import CommentAdornment from "./CommentAdornment";
import { getDayOfYear, getYear } from "date-fns";
import Entry from "../../../models/Entry";

export type OnBlurParams = {
  entry?: Entry;
  date: Date;
  hours: number;
  comment?: string;
  dayOfYear: number;
  year: number;
};
interface IProps {
  abnormalDay?: boolean;
  onCommentSave?: (comment: string) => void;
  title: string;
  entry?: Entry;
  date: Date;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>, params: OnBlurParams) => void;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

export default forwardRef(
  (
    {
      abnormalDay = false,
      title,
      entry,
      onBlur,
      onChange,
      onCommentSave,
      date,
      ...rest
    }: IProps,
    ref
  ) => {
    const commentRef = useRef<HTMLInputElement>();
    const [showAdornment, setShowAdornment] = useState(false);
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const {
        target: { value }
      } = e;
      setShowAdornment(false);
      let parsedVal = parseFloat(value);
      parsedVal = Number.isNaN(parsedVal) ? 0 : parsedVal;
      if ((!entry || !entry.hours) && value === "") return;
      if (entry?.hours) {
        const parsedHours =
          typeof entry.hours === "string"
            ? parseFloat(entry.hours)
            : entry.hours;
        if (parsedVal === parsedHours) return;
      }
      if (!onBlur) return;
      const dayOfYear = getDayOfYear(date);
      const year = getYear(date);
      onBlur(e, {
        entry,
        date,
        dayOfYear,
        year,
        hours: parsedVal
      });
    };
    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setShowAdornment(true);
    };

    const handleOnComment = () => {
      setCommentDialogOpen(true);
    };

    const handleCloseComment = () => {
      setCommentDialogOpen(false);
    };
    const handleOnSaveComment = () => {
      if (commentRef?.current?.value && onCommentSave) {
        onCommentSave(commentRef.current.value);
      }
      setCommentDialogOpen(false);
    };

    return (
      <StyledEntry abnormalDay={abnormalDay}>
        <TextField
          fullWidth
          onChange={onChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          inputRef={ref}
          label={title.toUpperCase()}
          variant="filled"
          inputProps={
            rest?.inputProps
              ? rest.inputProps
              : {
                  max: 24,
                  min: 0,
                  step: 0.5
                }
          }
          type="number"
          defaultValue={
            entry && entry.hours && entry.hours !== 0 ? entry.hours : undefined
          }
          align="right"
          alignlabel="center"
          InputProps={
            onCommentSave && {
              endAdornment: (
                <CommentAdornment
                  onClick={handleOnComment}
                  hidden={!showAdornment}
                />
              )
            }
          }
        />
        {onCommentSave && (
          <Dialog
            open={commentDialogOpen}
            onClose={handleCloseComment}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Add comment</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Write something short and meaningful.
              </DialogContentText>
              <TextField
                autoFocus
                inputRef={commentRef}
                margin="dense"
                id="name"
                label="Comment"
                type="text"
                variant="filled"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseComment} color="primary">
                Cancel
              </Button>
              <Button onClick={handleOnSaveComment} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </StyledEntry>
    );
  }
);
