import {
  all,
  put,
  takeLatest,
  select,
  call,
  delay,
} from "@redux-saga/core/effects";
import { DOWNLOAD, INIT } from "./constants";
import { update } from "./actions";
import { watchGetCustomers } from "../../../api/customers/actions";
import { watchGetProjects } from "../../../api/projects/actions";
import { watchGetUsers } from "@superprofit/core-redux-saga-modules/Users/actions";
import Entry from "../../../../../models/Entry";
import { useSelector } from "react-redux";

export const handleInit = function* () {
  const customers = yield select((state) => state.api.customers.list);
  const projects = yield select((state) => state.api.projects.list);
  const users = yield select((state) => state.api.users.list);
  const workspace = yield select(({ api: { auth } }) => auth.activeWorkspaceId);
  if (customers.length === 0) yield put(watchGetCustomers());
  if (projects.length === 0) yield put(watchGetProjects());
  if (users.length === 0) yield put(watchGetUsers());
  yield put(update({ workspace }));
};

export const handleDownload = function* (args) {
  const { payload = {} } = args;
  const { linkContainer } = payload;
  yield put(update({ loading: true }));
  let {
    users,
    customer,
    projects,
    fromDate,
    toDate,
    loading,
    error,
  } = yield select((state) => state.ui.pdf.dialog);

  const allProjects = yield select((state) => state.api.projects.list);
  const workspace = yield select((state) => state.api.auth.activeWorkspaceId);
  const projectsToFetch = customer
    ? allProjects.filter((p) => p.customer === customer.id).map((p) => p.id)
    : projects.map((p) => p.id);

  if ( projectsToFetch.length <= 0) {
    throw new Error('Trying to download pdf but there were no projects to fetch from. ProjectsToFetch length was zero')
  }
  // useEffect(async () => {
  //   const get = async () => {
  //     // const res = await Entry.getAll('skynet3', ['SxF7AgnACLEhXPHjCk3n', 'srEUcKpDtO49j7LQAbTZ'], [], 2020, 2020, 0, 200);
  //     // console.log(res);
  //   };
  //   await get();
  // }, []);

  const entries = yield call(
    Entry.getAll,
    workspace,
    projectsToFetch,
    users.map((u) => u.id),
    fromDate.year(),
    toDate.year(),
    fromDate.dayOfYear(),
    toDate.dayOfYear()
  );
  yield put(update({ entries }));

  //Hackity hack
  let tries = 0;
  while (tries < 10) {
    tries++;
    if (linkContainer?.childNodes[0]?.innerText === "READY") {
      linkContainer.childNodes[0].click();
      tries = 99;
    }
    yield delay(50);
  }
  yield delay(1000);
  yield put(update({ entries: [], loading: false }));
};

export const handleInitSaga = function* () {
  yield takeLatest(INIT, handleInit);
};
export const handleDownloadSaga = function* () {
  yield takeLatest(DOWNLOAD, handleDownload);
};

export default function* () {
  yield all([handleInitSaga(), handleDownloadSaga()]);
}
