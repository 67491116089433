export default {
  CREATE_WORKSPACE_VISITED: "create_workspace_visited",
  DASHBOARD_VISITED: "dashboard_visited",
  DISPATCH_CREATE_WORKSPACE: "dispatch_create_workspace",
  GENERIC_CSV_EXPORTED: "generic_csv_exported",
  NETTLONN_EXPORTED: "nettlonn_exported",
  PROJECT_HOURS_EXPORTED: "project_hours_exported",
  PURCHASE_VISITED: "purchase_visited",
  SALARY_EXPORTED: "salary_exported",
  SUBSCRIPTION_VISITED: "subscription_visited",
  TIMESHEET_COMMENT_DELETE: "ts_comment_delete",
  TIMESHEET_COMMENT_SAVE: "ts_comment_save",
  TIMESHEET_ENTRY_SAVE: "ts_entry_save",
  TIMESHEET_ENTRY_SAVE_ERROR: "ts_entry_save_error",
  TIMESHEET_ENTRY_SAVE_SUCCESS: "ts_entry_save_success",
  TIMESHEET_ENTRY_TABLE_VIEW_SAVE: "ts_entry_table_view_save",
  TIMESHEET_ENTRY_TABLE_VIEW_SAVE_ERROR: "ts_entry_table_view_save_error",
  TIMESHEET_ENTRY_TABLE_VIEW_SAVE_SUCCESS: "ts_entry_table_view_save_success",
  USERS_VISITED: "users_visited",
  USER_HOURS_EXPORTED: "user_hours_exported",
  USER_OVERVIEW_VISITED: "user_overview_visited"
};
