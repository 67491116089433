import CompanySetting from "../models/CompanySetting";
import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";

export const getKey = (workspace: string) => [
  workspace,
  CompanySetting.collectionName
];
const useCompanySettings = () => {
  const workspace = useWorkspace();
  const key = getKey(workspace);
  return useQuery(key, () => CompanySetting.map(workspace));
};

export default useCompanySettings;
