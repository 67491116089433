import { useCallback, useEffect } from "react";
import { Workspace } from "@superprofit/core-firestore-models";
import { firebase, db } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveWorkspaceId,
  setIdTokenResult,
  setWorkspace,
  signedIn,
  signedOut,
  workspaceJoined,
  workspaceJoining,
  workspaceNoAccess
} from "../redux/modules/api/auth/actions";
import { RootState } from "../index";
import { isStaging, promiseDelay } from "../helpers";

import { projectId } from "../config/firebase";
import { push } from "connected-react-router";
import { getPathWithKey } from "../router/publicRoutes";

let MAX_ATTEMPTS = 5;
let attempts = 0;

async function joinWorkspace(workspace: string, token: string) {
  const url = `https://europe-west1-${projectId}.cloudfunctions.net/workspaces-join`;
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
    authorization: token
  };

  if (isStaging()) {
    headers["x-firestore-database-id"] = "staging";
  }
  const result = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify({
      workspace
    })
  });
  return result;
}
function getWorkspaceIdFromPath(): string {
  const path = window.location.pathname;
  const [empty, app, workspaceId] = path.split("/");
  if (app === "app" && workspaceId) {
    return workspaceId;
  }
  return "";
}

async function getWorkspace(
  workspace: string
): Promise<{ id: string; name: string; displayName: string }> {
  return Workspace.get(db, workspace);
}

let unregisterAuthObserver: (() => void) | null = null;
let unregisterIdTokenObserver: (() => void) | null = null;

export function useAuth() {
  const dispatch = useDispatch();

  const authState = useSelector(({ api: { auth } }: RootState) => auth);
  const workspace = getWorkspaceIdFromPath();

  const getIdToken = useCallback(
    async () => {
      await firebase.auth().currentUser?.getIdToken(true);
    }, // force update token
    [firebase]
  );
  const signOut = useCallback(async () => {
    await firebase.auth().signOut();
    window.location.href = getPathWithKey("login");
  }, [firebase]);

  useEffect(() => {
    function handleAuthStateChanged(user: firebase.User | null) {
      if (!!user) {
        dispatch(signedIn(user));
      } else {
        dispatch(signedOut());
      }
    }

    async function handleIdTokenChanged(user: firebase.User | null) {
      try {
        if (!user) {
          return;
        }

        if (attempts >= MAX_ATTEMPTS) {
          console.error(
            "[useAuth]: Max attempts reached. Redirecting to workspace no access."
          );
          window.location.href = `${getPathWithKey(
            "workspace-no-access"
          )}?workspace=${workspace}`;
          return;
        }
        const idTokenResult = await user.getIdTokenResult(true);
        dispatch(setIdTokenResult(idTokenResult));
        const isCurrentlyJoining = authState.workspaceJoining;
        if (
          workspace &&
          idTokenResult.claims?.workspaces?.[workspace]?.isActive
        ) {
          const data = await getWorkspace(workspace);
          attempts = 0;
          dispatch(setActiveWorkspaceId(workspace));
          dispatch(setWorkspace(data));
        } else if (workspace && !isCurrentlyJoining && idTokenResult.token) {
          console.warn(
            "[useAuth]: Not currently joining workspace. Trying to join workspace."
          );
          attempts += 1;
          dispatch(workspaceJoining(workspace));
          const result = await joinWorkspace(workspace, idTokenResult.token);
          if (result.ok) {
            await promiseDelay(3000);
            await getIdToken();
            dispatch(workspaceJoined(workspace));
          } else if (result.status === 401) {
            dispatch(
              push(
                `${getPathWithKey(
                  "workspace-no-access"
                )}?workspace=${workspace}`
              )
            );
          } else {
            throw new Error("Failed to join workspace");
          }
        }
      } catch (e) {
        console.error("[useAuth]: onIdTokenChanged", e);
        dispatch(
          push(
            `${getPathWithKey("workspace-no-access")}?workspace=${workspace}`
          )
        );
      }
    }
    if (!unregisterAuthObserver) {
      unregisterAuthObserver = firebase
        .auth()
        .onAuthStateChanged(handleAuthStateChanged);
    }
    if (!unregisterIdTokenObserver) {
      unregisterIdTokenObserver = firebase
        .auth()
        .onIdTokenChanged(handleIdTokenChanged);
    }
    return () => {
      if (unregisterAuthObserver) {
        unregisterAuthObserver();
        unregisterAuthObserver = null;
      }
      if (unregisterIdTokenObserver) {
        unregisterIdTokenObserver();
        unregisterIdTokenObserver = null;
      }
    };
  }, [firebase, dispatch, workspace]);

  return {
    signOut,
    getIdToken
  };
}
