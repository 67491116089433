import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent
} from "react";
import { StyledAutocomplete } from "./autocomplete/Autocomplete.styles";
import { AutocompleteProps } from "@material-ui/lab";
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Value
} from "@material-ui/lab/useAutocomplete/useAutocomplete";

// export * from "@material-ui/lab/Autocomplete";

// function Autocomplete<ForwardRefExoticComponent<T, Multiple extends boolean | undefined = boolean, DisableClearable extends boolean | undefined = boolean, FreeSolo extends boolean | undefined = boolean>(
//     props: AutocompleteCustomProps<T, Multiple, DisableClearable, FreeSolo>
//     ref: ForwardedRef<HTMLDivElement>): JSX.Element {
//     return <StyledAutocomplete {...props} ref={ref} />
// }

function Autocomplete<
  T,
  Multiple extends boolean | undefined = boolean,
  DisableClearable extends boolean | undefined = boolean,
  FreeSolo extends boolean | undefined = boolean
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
  // @ts-ignore
  return <StyledAutocomplete {...props} ref={ref} />;
}

const ForwardedAutocomplete = forwardRef(
  Autocomplete
) as ForwardRefExoticComponent<AutocompleteProps<any, any, any, any>>;
export default ForwardedAutocomplete;

// interface ForwardedAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
//   extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {}
//
// function ForwardedAutocomplete<T, Multiple, DisableClearable, FreeSolo>(
//   props: ForwardedAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
//   ref: ForwardedRef<HTMLDivElement>
// ) {
//   const { onChange, ...rest } = props;
//   const onChg = onChange as (
//     event: React.ChangeEvent<{}>,
//     value: any,
//     reason: AutocompleteChangeReason,
//     details?: AutocompleteChangeDetails<T>
//   ) => void;
//   return <StyledAutocomplete onChange={onChg} {...rest} ref={ref} />;
// }
//
// const Autocomplete = forwardRef(ForwardedAutocomplete) as <
//   T,
//   Multiple,
//   DisableClearable,
//   FreeSolo
// >(
//   props: ForwardedAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
//     ref?: ForwardedRef<HTMLDivElement>;
//   }
// ) => ReturnType<typeof ForwardedAutocomplete>;
