import {
  DESELECT_ALL,
  DESELECT_CUSTOMER,
  SELECT_ALL,
  SELECT_CUSTOMER
} from "./constants";

const initialState = {
  selected: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECT_CUSTOMER:
      return {
        ...state,
        selected: [...state.selected, payload.customer]
      };
    case DESELECT_CUSTOMER:
      return {
        ...state,
        selected: state.selected.filter(p => p.id === payload.customer.id)
      };
    case SELECT_ALL:
      return {
        ...state,
        selected: [...payload.customers]
      };
    case DESELECT_ALL:
      return {
        ...state,
        selected: []
      };

    default:
      return state;
  }
};
