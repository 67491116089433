import { NumberFormatIntl, Skeleton } from "../../../../atoms";
import Typography from "../../../../atoms/Typography";
import Grid from "@material-ui/core/Grid";
import React from "react";

export default ({ state }) => {
  const {
    subscriptionInformation,
    isLoadingUpcomingInvoice,
    upcomingInvoice,
    updatedSubscriptionStatus,
  } = state;

  const subscription = subscriptionInformation?.subscription;
  const {
    updatedSubscriptionProductId = subscription?.plan?.product?.id,
    updatedSubscriptionSeats = subscription?.quantity,
  } = state;

  const updatedProduct = state.products?.find(
    (p) => p.id === updatedSubscriptionProductId
  );
  if (isLoadingUpcomingInvoice)
    return (
      <Grid item xs={12}>
        <Skeleton width={200} />
      </Grid>
    );



  if (
    !updatedSubscriptionProductId ||
    !updatedSubscriptionSeats ||
    (updatedSubscriptionProductId === subscription?.plan?.product?.id &&
      updatedSubscriptionSeats === subscription.quantity)
  ) {
    return (
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          The adjusted cost will be displayed here.
        </Typography>
      </Grid>
    );
  }

  if (updatedSubscriptionStatus?.status === "success")
    return (
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          You added {updatedSubscriptionSeats}{" "}
          additional users to your {updatedProduct.name} plan.
        </Typography>
      </Grid>
    );



  return (
    <Grid item xs={12}>
      <Typography variant="body2" color="textSecondary">
        <>
          You will be charged{" "}
          <b>
            <NumberFormatIntl
              locales="en"
              number={upcomingInvoice?.immediate_total / 100}
              options={{
                style: "currency",
                currency: subscription?.latest_invoice?.currency?.toUpperCase(),
              }}
            />
          </b>
        </>{" "}
        today
      </Typography>
    </Grid>
  );
};
