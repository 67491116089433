import {createStore, applyMiddleware, compose} from "redux";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import rootSaga from "./rootSaga";
import createRootReducer from "./rootReducer";

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware, routerMiddleware(history))
  // other store enhancers if any
);

export default function configureStore() {
  const store = createStore(createRootReducer(history), enhancer);
  sagaMiddleware.run(rootSaga);
  return store;
}
