import { db, firebase } from "../firebase";
import {
  QueryBuilder,
  Timestamp,
  User
} from "@superprofit/core-firestore-models";
import Entry from "./Entry";
import Customer from "./Customer";
import Project from "./Project";

type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
export type ProjectData = Partial<Project> & {
  id: string;
  name: string;
  billableRate: number;
  twntyFourSevenId: string | null;
};

export type ProjectBasis = Project & {
  entriesByUser: {
    [key: string]: number;
  };
  rawEntries: Entry[];
  entries: Array<{
    billableRate: number;
    hours: number;
    id: string;
    displayName: string;
  }>;
};

export interface BasisData {
  customer: {
    id: string;
    name: string;
    email: string;
    twntyFourSevenId: string | null;
    externalReference: string | null;
    billingAddress: string | null;
    billingAddressCity: string | null;
    billingAddressCountry: string | null;
    billingAddressState: string | null;
    billingAddressZip: string | null;
  };
  dueDate: Timestamp;
  issueDate: Timestamp | null;
  month: number;
  year: number;
  totalHours: number;
  totalSum: number;
  projects: ProjectBasis[];
}
export interface InvoiceData {
  id: string;
  invoiceNumber: number;
  twntyFourSevenId: string | null;
  month: number;
  year: number;
  customer: {
    id: string;
    name: string;
    email: string;
    twntyFourSevenId: string | null;
    externalReference: string | null;
    billingAddress: string | null;
    billingAddressCity: string | null;
    billingAddressCountry: string | null;
    billingAddressState: string | null;
    billingAddressZip: string | null;
  };
  projects: ProjectData[];
  dueDate: Timestamp;
  issueDate: Timestamp | null;
  paid: boolean;
  description: string;
  basis: BasisData;
  createdBy: string;
  updatedBy: string;
  createdAt: Timestamp | firebase.firestore.FieldValue;
  updatedAt: Timestamp | firebase.firestore.FieldValue;
}

export type InvoiceDataCreatePayload = Omit<
  InvoiceData,
  | "paid"
  | "invoiceNumber"
  | "id"
  | "updatedAt"
  | "createdAt"
  | "updatedBy"
  | "createdBy"
>;

const parseNumber = val => {
  const value = Number(val);
  if (Number.isNaN(value)) {
    return undefined;
  }
  return value;
};

export default class Invoice implements InvoiceData {
  id: string;
  invoiceNumber: number;
  twntyFourSevenId: string | null;
  month: number;
  year: number;
  customer: {
    id: string;
    name: string;
    email: string;
    twntyFourSevenId: string | null;
    externalReference: string | null;
    billingAddress: string | null;
    billingAddressCity: string | null;
    billingAddressCountry: string | null;
    billingAddressState: string | null;
    billingAddressZip: string | null;
  };
  projects: ProjectData[];
  dueDate: Timestamp;
  issueDate: Timestamp | null;
  paid: boolean;
  description: string;
  basis: BasisData;
  createdBy: string;
  updatedBy: string;
  createdAt: Timestamp | firebase.firestore.FieldValue;
  updatedAt: Timestamp | firebase.firestore.FieldValue;

  static collectionName = "invoices";

  static converter = {
    toFirestore(invoice: Invoice) {
      const tmp = invoice.data();

      const data = {
        ...tmp,
        customer: {
          name: tmp.customer.name,
          id: tmp.customer.id,
          twntyFourSevenId: tmp.customer.twntyFourSevenId
        },
        projects: tmp.projects.map(p => ({
          id: p.id,
          name: p.name,
          billableRate: p.billableRate,
          twntyFourSevenId: p.twntyFourSevenId
        }))
      };

      return data;
    },
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      return new Invoice({
        ...data,
        id: snapshot.id
      });
    }
  };

  static createId(workspace) {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(Invoice.collectionName)
      .doc().id;
  }

  static async getInvoiceBasis(workspace, year, month, project) {
    const res = await Entry.getAllByProjects(workspace, [project], year, month);
    return res.map(e => e.data());
  }

  static async createData(
    workspace: string,
    year: number,
    month: number,
    customer: Customer,
    projects: Project[],
    dueDate: Timestamp,
    issueDate: Timestamp
  ) {
    const basis = await Invoice.createDataFromXstats(
      workspace,
      year,
      month,
      customer,
      projects,
      dueDate,
      issueDate
    );

    basis.projects = basis.projects.filter(p => p?.entries?.length > 0);

    const invoice: InvoiceDataCreatePayload = {
      customer,
      month,
      year,
      issueDate,
      dueDate,
      projects: projects
        .filter(p => basis.projects.findIndex(bp => bp.id === p.id) > -1)
        .map(p => ({
          id: p.id,
          name: p.name,
          billableRate: p.billableRate,
          twntyFourSevenId: p.twntyFourSevenId
        })),
      basis
    };

    return invoice;

    //  Logic from old saga. Not revised.

    // const invoice = {
    //   customer,
    //   month: period.month,
    //   year: period.year,
    //   issueDate: Timestamp.fromMoment(
    //       moment(`${period.year} ${period.month}`, "YYYY MM").endOf("month")
    //   ),
    //   dueDate: Timestamp.fromMoment(
    //       moment(`${period.year} ${period.month}`, "YYYY MM")
    //           .add(1, "m")
    //           .endOf("month")
    //   ),
    //   projects,
    // };

    //  let basis = yield call(
    //       Invoice2.createDataFromXstats,
    //       workspace,
    //       invoice.year,
    //       invoice.month,
    //       invoice.customer,
    //       projects,
    //       invoice.dueDate,
    //       invoice.issueDate
    //   );
    //   basis.projects = basis.projects.filter(p => p?.entries?.length > 0);
    //   invoice.projects = projects.filter(p => basis.projects.findIndex(bp => bp.id === p.id) > -1 );
    //   invoice.basis = basis;
    // yield put(watchSaveInvoice({}, invoice));
  }

  static async createDataFromXstats(
    workspace,
    year,
    month,
    customer,
    projects,
    dueDate,
    issueDate
  ) {
    const projectSet = projects.reduce(
      (prev, next) => ({
        ...prev,
        [next.id]: {
          id: next.id,
          name: next.name,
          twntyFourSevenId: next.twntyFourSevenId,
          externalReference: next.externalReference,
          billableRate: next.billableRate,
          userBillableRate: next.userBillableRate,
          entries: []
        }
      }),
      {}
    );

    let distinctUsers = {};

    /*
     * {
     * month, project, week, total, user, year
     * */

    const basis = await Promise.all(
      projects.map(async p => {
        const rawEntries = await Invoice.getInvoiceBasis(
          workspace,
          year,
          month,
          p.id
        );

        const allByProjectAndWeek = new Map();

        for (const entry of rawEntries) {
          const key = `${entry.user}.${entry.week}`;
          const existing = allByProjectAndWeek.get(key);
          if (existing) {
            allByProjectAndWeek.set(key, {
              ...existing,
              total: existing.total + entry.hours
            });
          } else {
            allByProjectAndWeek.set(key, {
              user: entry.user,
              week: entry.week,
              total: entry.hours,
              month: entry.month,
              year: entry.year,
              project: entry.project
            });
          }
        }

        const result = Array.from(allByProjectAndWeek.values());

        const hoursByUser = result.reduce((prev, next) => {
          if (next.total === 0) return prev; // Skip adding entries with 0 hours
          return {
            ...prev,
            [next.user]: prev[next.user]
              ? prev[next.user] + next.total
              : next.total
          };
        }, {});

        return {
          ...projectSet[p.id],
          entriesByUser: hoursByUser,
          rawEntries
        };
      })
    );
    basis.forEach(p => {
      Object.keys(p.entriesByUser).forEach(userId => {
        distinctUsers[userId] = true;
      });
    });

    const userSet = (
      await User.getAll(workspace, Object.keys(distinctUsers))
    ).reduce(
      (prev, next) => ({
        ...prev,
        [next.id]: {
          id: next.id,
          displayName: next.displayName
        }
      }),
      {}
    );

    const projectsResult = basis.map(p => ({
      ...p,
      entries: Object.keys(p.entriesByUser).map(userId => ({
        ...userSet[userId],
        hours: p.entriesByUser[userId],
        billableRate:
          parseNumber(p.userBillableRate?.[userId]) || p.billableRate
      }))
    }));

    const totalHours = projectsResult.reduce(
      (pprev, pnext) =>
        pprev + pnext.entries.reduce((prev, next) => prev + next.hours, 0),
      0
    );

    const totalSum = projectsResult.reduce(
      (pprev, pnext) =>
        pprev +
        pnext.entries.reduce(
          (prev, next) => prev + next.hours * next.billableRate,
          0
        ),
      0
    );

    const invoiceData: BasisData = {
      month: month,
      year: year,
      customer: {
        id: customer.id,
        name: customer.name,
        email: customer.email,
        twntyFourSevenId: customer.twntyFourSevenId,
        externalReference: customer.externalReference,
        billingAddress: customer.billingAddress,
        billingAddressCity: customer.billingAddressCity,
        billingAddressCountry: customer.billingAddressCountry,
        billingAddressState: customer.billingAddressState,
        billingAddressZip: customer.billingAddressZip
      },
      twntyFourSevenId: null,
      projects: projectsResult,
      totalHours,
      totalSum,
      dueDate,
      issueDate
    };

    return invoiceData;
  }

  constructor({
    id,
    invoiceNumber,
    twntyFourSevenId,
    month,
    year,
    customer,
    projects,
    dueDate,
    issueDate,
    paid,
    description,
    basis,
    createdBy,
    updatedBy,
    createdAt,
    updatedAt
  }: InvoiceData) {
    this.id = id;
    this.description = description || "";
    this.invoiceNumber = invoiceNumber;
    this.customer = customer;
    this.projects = projects;
    this.dueDate = dueDate;
    this.issueDate = issueDate || null;
    this.paid = paid;
    this.year = year;
    this.month = month;
    this.basis = basis;
    this.twntyFourSevenId = twntyFourSevenId || null;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates) {
    Object.assign(this, updates);
    return this;
  }

  isValid() {
    return true; // validation goes here
  }

  data(): InvoiceData {
    return {
      id: this.id,
      year: this.year,
      month: this.month,
      basis: this.basis,
      paid: this.paid,
      invoiceNumber: this.invoiceNumber,
      dueDate: this.dueDate,
      issueDate: this.issueDate,
      customer: this.customer,
      projects: this.projects,
      description: this.description,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      updatedBy: this.updatedBy,
      twntyFourSevenId: this.twntyFourSevenId || null
    };
  }

  static list = async (workspace, query) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(Invoice.collectionName),
      query
    )
      .withConverter(Invoice.converter)
      .get();

    return snapshot.docs.map(
      (doc: QueryDocumentSnapshot) => doc.data() as Invoice
    );
  };

  static create = async (
    workspace: string,
    user: string,
    data: InvoiceDataCreatePayload
  ) => {
    const invoicesCreated = await db
      .collection("workspaces")
      .doc(workspace)
      .collection("xstats_invoices_created")
      .doc("xstats_invoices_created")
      .get();

    const invoiceNumber = invoicesCreated.data()?.numberOfInvoices
      ? invoicesCreated.data()?.numberOfInvoices + 1
      : 1;

    const invoice = new Invoice({
      ...data,
      paid: false,
      invoiceNumber,
      id: Invoice.createId(workspace),
      updatedAt: Timestamp.now(),
      createdAt: Timestamp.now(),
      updatedBy: user,
      createdBy: user
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Invoice.collectionName)
      .doc(invoice.id)
      .withConverter(Invoice.converter)
      .set(invoice, { merge: true });

    return invoice;
  };

  static patch = async (
    workspace: string,
    user: string,
    id: string,
    updates: Partial<InvoiceData>
  ) => {
    const patchData = {
      updatedAt: Timestamp.now(),
      updatedBy: user,
      ...updates
    };

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Invoice.collectionName)
      .doc(id)
      .set(patchData, { merge: true });

    return { patchData };
  };

  static update = async (
    workspace: string,
    user: string,
    invoice: Invoice,
    updates: Partial<InvoiceData>
  ) => {
    const updatedInvoice = invoice.clone();
    updatedInvoice.setData({
      ...updates,
      updatedAt: Timestamp.now(),
      updatedBy: user
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Invoice.collectionName)
      .doc(updatedInvoice.id)
      .withConverter(Invoice.converter)
      .set(updatedInvoice, { merge: true });

    return updatedInvoice;
  };

  static batchUpdate = async (
    workspace: string,
    invoices: Invoice[],
    updates: Partial<InvoiceData>[]
  ) => {
    const batch = db.batch();
    const updated = invoices.map(inv => {
      if (!inv.isValid()) throw new Error("Not a valid inv");

      const _updates = updates.find(
        (u: Partial<InvoiceData>) => u.id === inv.id
      );

      if (!_updates) throw new Error(`No corresponding updates for ${inv.id}`);
      const clone = inv.clone();
      clone.setData({
        ..._updates
      });
      const docRef = db
        .collection("workspaces")
        .doc(workspace)
        .collection(Invoice.collectionName)
        .doc(inv.id)
        .withConverter(Invoice.converter);
      batch.set(docRef, clone, { merge: true });
      return new Invoice({ id: clone.id, ...clone.data() }); // Prevent unwanted fields
    });

    await batch.commit();

    return updated;
  };

  static delete = async (workspace: string, id: string) => {
    return await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Invoice.collectionName)
      .doc(id)
      .delete();
  };

  static deleteMultiple = async (workspace, ids) => {
    return Promise.all(ids.map(id => Invoice.delete(workspace, id)));
  };
}
