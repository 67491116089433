import React, { forwardRef, Fragment, useState } from "react";
import Table, {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";

import Checkbox from "@superprofit/core-react-components/atoms/Checkbox";
import CustomerRow from "./CustomerRow";
import ActionMenu from "../../../../components/molecules/ActionMenu";
import TableLoader from "../../../../components/molecules/TableLoader";
import { useTranslation } from "react-i18next";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = (orderDir, orderBy) => {
  return orderDir === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export default forwardRef(
  (
    {
      onSelectionChange,
      onEdit,
      onArchive,
      onDelete,
      onView,
      selection = [],
      customers,
      loading
    },
    ref
  ) => {
    const [actionMenu, setActionMenu] = useState({
      customer: null,
      anchor: null
    });
    const { t } = useTranslation();

    const [orderBy, setOrderBy] = useState("name");
    const [orderDir, setOrderDir] = useState("asc");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const selectedIds = React.useMemo(
      () =>
        (selection || []).reduce(
          (prev, next) => ({
            ...prev,
            [next.id]: true
          }),
          {}
        ),
      [selection]
    );

    const customerList = React.useMemo(() => {
      let customerListSorted = customers
        .slice()
        .filter(c => c.archived !== true);

      customerListSorted.sort(getComparator(orderDir, orderBy));
      customerListSorted =
        rowsPerPage > 0
          ? customerListSorted.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : customerListSorted;

      return customerListSorted;
    }, [orderBy, orderDir, page, rowsPerPage, customers]);

    const handleOnSort = (event, property) => {
      const isAsc = orderBy === property && orderDir === "asc";
      setOrderDir(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleOnMore = (e, customer) => {
      setActionMenu({ anchor: e.currentTarget, customer });
    };

    const handleOnCloseMore = e => {
      setActionMenu({});
    };

    const handleOnSelectAll = () => {
      if (selection && selection.length === customers.length) {
        onSelectionChange([]);
      } else {
        onSelectionChange(customers);
      }
    };

    const handleOnSelect = customer => {
      if (selectedIds[customer.id]) {
        onSelectionChange(selection.filter(c => c.id !== customer.id));
      } else {
        onSelectionChange(
          selection.filter(c => c.id !== customer.id).concat(customer)
        );
      }
    };

    return (
      <Fragment>
        <ActionMenu
          onEdit={onEdit}
          onArchive={onArchive}
          onDelete={onDelete}
          onView={onView}
          data={actionMenu.customer}
          open={!!actionMenu.anchor}
          anchorEl={actionMenu.anchor}
          onClose={handleOnCloseMore}
        />

        <TableContainer ref={ref}>
          <Table aria-label="Customers">
            <TableHead>
              <TableRow>
                <TableCell style={{ maxWidth: 80, width: 80 }}>
                  <Checkbox
                    defaultChecked={false}
                    checked={
                      (selection.length &&
                        selection.length === customers.length) ||
                      false
                    }
                    indeterminate={
                      (selection.length &&
                        selection.length < customers.length) ||
                      false
                    }
                    inputProps={{ "aria-label": "select all customers" }}
                    onChange={handleOnSelectAll}
                  />
                </TableCell>

                <TableCell style={{ maxWidth: 80, width: 80 }} />

                <TableCell
                  sortDirection={orderBy === "name" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "name")}
                  >
                    {t("common.name")}
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sortDirection={orderBy === "contactName" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "contactName"}
                    direction={orderBy === "contactName" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "contactName")}
                  >
                    {t("common.contact")}
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sortDirection={orderBy === "tags" ? orderDir : false}
                >
                  <TableSortLabel
                    active={orderBy === "tags"}
                    direction={orderBy === "tags" ? orderDir : "asc"}
                    onClick={e => handleOnSort(e, "tags")}
                  >
                    {t("common.tags")}
                  </TableSortLabel>
                </TableCell>

                <TableCell style={{ maxWidth: 80, width: 80 }}></TableCell>
              </TableRow>
            </TableHead>

            {!loading && (
              <Fragment>
                <TableBody>
                  {customerList.map(c => (
                    <CustomerRow
                      key={c.id}
                      customer={c}
                      selected={!!selectedIds[c.id]}
                      onMore={handleOnMore}
                      onSelect={() => handleOnSelect(c)}
                    />
                  ))}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TablePagination
                      labelRowsPerPage={t("common.rowsPerPage")}
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        { label: t("common.all"), value: -1 }
                      ]}
                      count={customers.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Fragment>
            )}

            {loading && (
              <TableLoader
                cols={["rect", "circle", "text", "text", "text", "rect"]}
              />
            )}
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
);
