import React, { forwardRef } from "react";
import { Autocomplete, Chip, TextField } from "./index";

export default forwardRef(({ fullWidth, ...rest }, ref) => (
  <Autocomplete
    ref={ref}
    multiple
    options={[].map(option => option.title)}
    freeSolo
    renderTags={(value, getTagProps) =>
      value.map((option, index) => (
        <Chip label={option} color="primary" {...getTagProps({ index })} />
      ))
    }
    renderInput={params => (
      <TextField
        {...params}
        label="Tags"
        fullWidth={fullWidth}
        placeholder="List of tags"
        variant="filled"
      />
    )}
    {...rest}
  />
));
