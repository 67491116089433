const modifyChannel = (channelBase, modifier, bg = 255) =>
  Math.round(bg + (channelBase - bg) * modifier);

const toRgb = channels => `rgb(${channels.join(",")})`;

const modifyColor = (channels, modifier, bg) =>
  channels.map(channel => modifyChannel(channel, modifier, bg));

export const componentToHex = c => {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

export const rgbToHex = ([r, g, b]) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
};

export const hexToRgba = (hex, alpha) => {
  const channels = hexToRgb(hex).concat(alpha);
  return `rgba(${channels.join(",")})`;
};

export const darken = (color, amount) =>
  rgbToHex(modifyColor(hexToRgb(color), 1 - amount, 0));

export const lighten = (color, amount) =>
  rgbToHex(modifyColor(hexToRgb(color), 1 - amount, 255));
