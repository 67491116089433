import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toggleSidebar from "../../redux/modules/ui/app/actions";
import { getPathWithKey, getPathWithParams } from "../../router/routes";
import Drawer from "@superprofit/core-react-components/atoms/Drawer";
import {
  NestedList,
  SidebarContent,
  SidebarListItem,
  SidebarListItemText,
  StickySidebar,
  StyledListItemIcon,
  StyledSubHeader
} from "./sidebar/Sidebar.styles";
import List from "@superprofit/core-react-components/atoms/List";
import Collapse from "@superprofit/core-react-components/atoms/Collapse";
import CircularProgress from "@superprofit/core-react-components/atoms/CircularProgress";
import {
  ExpandLess,
  ExpandMore,
  PeopleAltOutlined,
  AssignmentOutlined,
  WorkOutlineOutlined,
  SettingsOutlined,
  Group,
  AccountBoxOutlined,
  DashboardOutlined,
  ExitToAppOutlined,
  AccountBalanceWalletOutlined,
  AssessmentOutlined,
  ReceiptOutlined,
  Publish
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { useFeatures, useUserAcccess } from "../../helpers";
import { cloudFunctionsBaseUrl } from "../../config/stripe";
import { actions as stripeAccountDetails } from "../../redux/modules/api/stripeAccountDetails";
import { useAuth } from "../../hooks/useAuth";

export default ({ sticky, ...rest }) => {
  const dispatch = useDispatch();
  const { projectSalaryRate, approvalRecords } = useFeatures();

  const [accountOpen, setAccountOpen] = useState(false);
  const [reportsOpen, setReportsOpen] = useState(false);
  const [approvalsOpen, setApprovalsOpen] = useState(false);
  const [loadingPortal, setLoadingPortal] = useState(false);

  const open = useSelector(state => state.ui.app.shared.sidebar.open);
  const user = useSelector(state => state.api.auth.user);
  const workspace = useSelector(state => state.api.auth.workspace);
  const accountDetails = useSelector(
    state => state.api.stripeAccountDetails.entry
  );
  const idToken = useSelector(state => state.api.auth.idTokenResult.token);
  const workspaceName = useSelector(state => state.api.auth?.activeWorkspaceId);
  useEffect(() => {
    dispatch(stripeAccountDetails.watchGetEntry());
  }, [dispatch]);
  const { isAdmin } = useUserAcccess();
  const { signOut } = useAuth();

  const handleOnClose = () => dispatch(toggleSidebar(false));
  const handleLogout = async () => {
    await signOut();
    handleOnClose();
  };

  const { t } = useTranslation();

  const handleMySubscription = () => {
    const getPortal = async () => {
      setLoadingPortal(true);
      try {
        const res = await fetch(
          `${cloudFunctionsBaseUrl}/getCustomerPortalLink`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              authorization: idToken
            },
            body: JSON.stringify({
              customerId: accountDetails?.customerId,
              returnUrl: `${window.location.origin}/app/${workspaceName}`
            })
          }
        );
        if (res.ok) {
          const json = await res.json();
          if (json?.url) {
            window.location.href = json.url;
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingPortal(false);
      }
    };
    getPortal().catch(e => console.error(e));
  };

  const v2Users = [
    "sondre.t.johannessen@gmail.com",
    "sondre.johannessen@sonat.no"
  ];
  const v2Enabled = v2Users.includes(user.email);

  const timesheetPath = v2Enabled
    ? getPathWithKey("timesheetV2Card")
    : getPathWithKey("timesheet");

  const sidebarContent = (
    <SidebarContent>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <StyledSubHeader>{workspace && workspace.label}</StyledSubHeader>
        {isAdmin && (
          <>
            <SidebarListItem
              path={getPathWithKey("dashboard")}
              onClick={handleOnClose}
            >
              <DashboardOutlined fontSize="small" />
              <SidebarListItemText primary={t("common.dashboard")} />
            </SidebarListItem>

            {approvalRecords && (
              <>
                <SidebarListItem
                  button
                  onClick={() => {
                    setApprovalsOpen(!approvalsOpen);
                  }}
                >
                  <Publish fontSize="small" />
                  <SidebarListItemText primary={t("common.approvals")} />
                  {approvalsOpen ? <ExpandLess /> : <ExpandMore />}
                </SidebarListItem>
                <Collapse in={approvalsOpen} timeout="auto" unmountOnExit>
                  <NestedList component="div" disablePadding>
                    <SidebarListItem
                      path={getPathWithKey("approvals")}
                      onClick={handleOnClose}
                    >
                      <SidebarListItemText primary={t("common.projects")} />
                    </SidebarListItem>
                    <SidebarListItem
                      path={getPathWithKey("approvalsByUser")}
                      onClick={handleOnClose}
                    >
                      <SidebarListItemText primary={t("common.users")} />
                    </SidebarListItem>
                  </NestedList>
                </Collapse>
              </>
            )}

            <SidebarListItem
              button
              onClick={() => {
                setReportsOpen(!reportsOpen);
              }}
            >
              <AssessmentOutlined fontSize="small" />
              <SidebarListItemText primary={t("common.reports")} />
              {reportsOpen ? <ExpandLess /> : <ExpandMore />}
            </SidebarListItem>

            <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
              <NestedList component="div" disablePadding>
                <SidebarListItem
                  key="reports-by-users"
                  path={getPathWithKey("reports-by-users")}
                >
                  <SidebarListItemText primary={t("common.userHours")} />
                </SidebarListItem>

                <SidebarListItem
                  key="reports-by-projects"
                  path={getPathWithKey("reports-by-projects")}
                >
                  <SidebarListItemText primary={t("common.projectHours")} />
                </SidebarListItem>

                <SidebarListItem
                  key="reports-by-customers"
                  path={getPathWithKey("reports-by-customers")}
                >
                  <SidebarListItemText primary={t("common.customerHours")} />
                </SidebarListItem>

                {projectSalaryRate && (
                  <SidebarListItem
                    key="reports-for-salary"
                    path={getPathWithKey("reports-for-salary")}
                  >
                    <SidebarListItemText primary={t("common.salary")} />
                  </SidebarListItem>
                )}
              </NestedList>
            </Collapse>

            <SidebarListItem
              path={getPathWithKey("users")}
              onClick={handleOnClose}
            >
              <PeopleAltOutlined fontSize="small" />
              <SidebarListItemText primary={t("common.users")} />
            </SidebarListItem>

            <SidebarListItem
              path={getPathWithKey("groups")}
              onClick={handleOnClose}
            >
              <Group fontSize="small" />
              <SidebarListItemText primary={t("common.groups")} />
            </SidebarListItem>

            <SidebarListItem
              path={getPathWithKey("projects")}
              onClick={handleOnClose}
            >
              <AssignmentOutlined fontSize="small" />
              <SidebarListItemText primary={t("common.projects")} />
            </SidebarListItem>

            <SidebarListItem
              path={getPathWithKey("customers")}
              onClick={handleOnClose}
            >
              <AccountBoxOutlined fontSize="small" />
              <SidebarListItemText primary={t("common.customers")} />
            </SidebarListItem>

            {/*<SidebarListItem path={getPathWithKey("leads")} onClick={handleOnClose}>*/}
            {/*  <Badge badgeContent="!" color="error">*/}
            {/*    <WorkOutlineOutlined fontSize="small" />*/}
            {/*  </Badge>*/}

            {/*  <SidebarListItemText primary="Leads" />*/}
            {/*</SidebarListItem>*/}

            <SidebarListItem
              path={getPathWithKey("invoices")}
              onClick={handleOnClose}
            >
              <ReceiptOutlined fontSize="small" />
              <SidebarListItemText primary={t("common.invoices")} />
            </SidebarListItem>

            <SidebarListItem
              button
              onClick={() => {
                setAccountOpen(!accountOpen);
              }}
            >
              <AccountBalanceWalletOutlined fontSize="small" />
              <SidebarListItemText primary={t("common.account")} />
              {accountOpen ? <ExpandLess /> : <ExpandMore />}
            </SidebarListItem>

            <Collapse in={accountOpen} timeout="auto" unmountOnExit>
              <NestedList component="div" disablePadding>
                <SidebarListItem path={getPathWithKey("purchase")}>
                  <SidebarListItemText primary={t("common.purchaseAccess")} />
                </SidebarListItem>
                <SidebarListItem
                  disabled={!accountDetails?.customerId}
                  onClick={handleMySubscription}
                >
                  <SidebarListItemText primary={t("common.mySubscription")} />
                  {loadingPortal && <CircularProgress size={23} />}
                </SidebarListItem>

                <SidebarListItem
                  path={getPathWithKey("company-settings")}
                  onClick={handleOnClose}
                >
                  <SidebarListItemText primary={t("common.settings")} />
                </SidebarListItem>
              </NestedList>
            </Collapse>
          </>
        )}

        <StyledSubHeader>{user && user.email}</StyledSubHeader>

        <SidebarListItem
          path={getPathWithParams("userOverview", { id: user.email })}
          onClick={handleOnClose}
        >
          <StyledListItemIcon>
            <DashboardOutlined fontSize="small" />
          </StyledListItemIcon>
          <SidebarListItemText primary={t("common.overview")} />
        </SidebarListItem>

        <SidebarListItem path={timesheetPath} onClick={handleOnClose}>
          <StyledListItemIcon>
            <WorkOutlineOutlined fontSize="small" />
          </StyledListItemIcon>
          <SidebarListItemText primary={t("common.timesheet")} />
        </SidebarListItem>

        {approvalRecords && (
          <SidebarListItem
            path={getPathWithParams("userApprovals", { id: user.email })}
            onClick={handleOnClose}
          >
            <StyledListItemIcon>
              <Publish fontSize="small" />
            </StyledListItemIcon>
            <SidebarListItemText primary={t("common.approvals")} />
          </SidebarListItem>
        )}

        <SidebarListItem
          path={getPathWithKey("profile-settings")}
          onClick={handleOnClose}
        >
          <StyledListItemIcon>
            <SettingsOutlined fontSize="small" />
          </StyledListItemIcon>
          <SidebarListItemText primary={t("common.settings")} />
        </SidebarListItem>

        <SidebarListItem onClick={handleLogout}>
          <StyledListItemIcon>
            <ExitToAppOutlined fontSize="small" />
          </StyledListItemIcon>
          <SidebarListItemText primary={t("common.logout")} />
        </SidebarListItem>
      </List>
    </SidebarContent>
  );

  if (sticky) {
    return <StickySidebar>{sidebarContent}</StickySidebar>;
  } else {
    return (
      <Drawer {...rest} open={open} onClose={handleOnClose}>
        {sidebarContent}
      </Drawer>
    );
  }
};
