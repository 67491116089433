import React, { Fragment, useRef, useState } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
// import { showProjectDialog } from "../../../redux/modules/ui/projects/dialog/actions";
import { setSelectedProjects } from "../../../redux/modules/ui/projects/selection/actions";
import {
  AppBar,
  Button,
  Snackbar,
  Card,
  CardHeader,
  Divider
} from "@superprofit/core-react-components/atoms";
import { show as showPDFDialog } from "../../../redux/modules/ui/pdf/dialog/actions";
import ProjectsTable from "./projectTable/ProjectsTable";
import { getPathWithParams } from "../../../router/routes";
import FeatureNotImplementedDialog from "@superprofit/core-react-components/organisms/FeatureNotImplementedDialog";
import {
  watchDuplicateProject,
  watchPatchMultipleProjects
} from "../../../redux/modules/api/projects/actions";
import ConfirmationDialog from "../../../components/molecules/ConfirmationDialog";
import { ConfirmDialog, IProjectsCard, Project } from "./projectsCard/types";
import useDialog from "./hooks/useDialog";
import { useTranslation } from "react-i18next";
import useProjectDuplicateMutation from "../../../hooks/useProjectDuplicateMutation";

const ProjectsCard = ({
  projects,
  loading,
  selection: selected,
  ...rest
}: IProjectsCard) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { show } = useDialog();
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialog>(null);
  const [notYetDialog, setNotYetDialog] = useState(false);
  const { mutateAsync: duplicateProject } = useProjectDuplicateMutation();

  const handleOnEdit = (e: React.MouseEvent, data: Project) => {
    const project = projects.find(p => p.id === data.id);
    if (project) show(project?.id);
  };

  const handleOnCloseConfirm = () => {
    setConfirmDialog(null);
  };

  const handleOnArchive = (e: React.MouseEvent, data: Project) => {
    if (typeof data.id === "string") {
      setConfirmDialog({
        title: `Archive ${data.name}?`,
        text: "Are you sure you want to archive selected project(s)",
        callback: () =>
          dispatch(
            watchPatchMultipleProjects([data.id] as string[], {
              archived: true
            })
          )
      });
    }
  };

  const handleOnArchiveSelected = () => {
    setConfirmDialog({
      title: `Archive ${selected.length} projects?`,
      text: "Are you sure you want to archive selected project(s)",
      callback: () =>
        dispatch(
          watchPatchMultipleProjects(
            selected.filter(s => s.id).map(s => s.id) as string[],
            { archived: true }
          )
        )
    });
  };

  const handleOnView = (e: React.MouseEvent, data: Project) =>
    dispatch(push(getPathWithParams("projectOverview", { id: data.id })));

  const handleOnSelectionChange = (selection: Project[]) => {
    dispatch(setSelectedProjects(selection));
  };

  const handleOnClearSelection = () => {
    dispatch(setSelectedProjects([]));
  };

  const handleOnShowPDFDialog = () => {
    dispatch(showPDFDialog({ projects: selected }));
  };
  const handleOnDuplicate = async (e: React.MouseEvent, data: Project) => {
    await duplicateProject(data);
  };

  return (
    <div>
      <ConfirmationDialog
        key="confirm"
        title={confirmDialog?.title}
        text={confirmDialog?.text}
        onConfirm={confirmDialog?.callback ? confirmDialog.callback : () => {}}
        onClose={handleOnCloseConfirm}
        open={Boolean(confirmDialog?.callback)}
      />
      <Card {...rest}>
        <CardHeader title={t("common.projects")} />

        <Divider />

        <ProjectsTable
          onArchive={handleOnArchive}
          onEdit={handleOnEdit}
          onView={handleOnView}
          onDelete={handleOnArchive}
          onDuplicate={handleOnDuplicate}
          onSelectionChange={handleOnSelectionChange}
          projects={projects}
          loading={loading}
          selection={selected}
        />
      </Card>

      <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={selected.length > 0}
          autoHideDuration={null}
          onClose={() => {}}
          message={`${selected.length} projects selected`}
          action={
            <React.Fragment>
              <Button
                color="inherit"
                size="small"
                onClick={handleOnShowPDFDialog}
              >
                PDF
              </Button>
              <Button
                color="inherit"
                size="small"
                onClick={handleOnClearSelection}
              >
                CLEAR
              </Button>
              <Button
                color="alert"
                size="small"
                onClick={handleOnArchiveSelected}
              >
                ARCHIVE
              </Button>
            </React.Fragment>
          }
        />
      </AppBar>

      <FeatureNotImplementedDialog
        open={notYetDialog}
        onClose={() => setNotYetDialog(false)}
      />
    </div>
  );
};

export default ProjectsCard;
