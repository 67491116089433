import * as yup from "yup";
import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import User from "../../../../models/User";
import useFixEmployeeNumbersDialog from "./useFixEmployeeNumbersDialog";
import useTimetUserMutation from "../../../../hooks/useTimetUserMutation";
import useUsers from "../../../../hooks/useUsers";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ConfirmationDialog from "../../../../components/molecules/ConfirmationDialog";
import FormDialog from "../../../../components/molecules/FormDialog";
import Grid from "../../../../components/atoms/Grid";
import TextField from "../../../../components/atoms/TextField";
import useTimetUserUpdateMutation from "../../../../hooks/useTimetUserUpdateMutation";
import useEmployeeNumbers from "../../../../hooks/useEmployeeNumbers";
import useGlobalSnackbar from "../../../../hooks/useGlobalSnackbar";
import { promiseDelay } from "../../../../helpers";
import useFixProjectsDialog from "./useFixProjectsDialog";
import useUnarchivedProjects from "../../../../hooks/useUnarchivedProjects";
import Project from "../../../../models/Project";
import useProjectsMissingNettlonnConfig from "./useProjectsMissingNettlonnConfig";
import FormLabel from "../../../../components/atoms/FormLabel";
import FormControl from "../../../../components/atoms/FormControl";
import Typography from "../../../../components/atoms/Typography";
import useProjectUpdateMutation from "../../../../hooks/useProjectUpdateMutation";

interface IFormInput {
  [key: string]: { salaryArt: number; dimensionOne: number };
}

export default ({ ...rest }) => {
  const { t } = useTranslation();
  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);
  const { update: updateSnackbar } = useGlobalSnackbar();
  const {
    mutateAsync: updateProject,
    isLoading: isMutationLoading
  } = useProjectUpdateMutation();
  const { open, close } = useFixProjectsDialog();
  const {
    data: projects = [],
    isInitialLoading,
    isSuccess,
    refetch
  } = useUnarchivedProjects();
  const { mutateAsync: updateUserAsync } = useTimetUserUpdateMutation();
  const projectsMissingNettlonnConfig = useProjectsMissingNettlonnConfig();

  const schema = useMemo(() => {
    let shape: any = {};
    const nettlonnSchema = yup.object().shape({
      salaryArt: yup
        .number()
        .required()
        .typeError(t("common.invalidNumber")),
      dimensionOne: yup
        .number()
        .required()
        .typeError(t("common.invalidNumber"))
    });
    for (let project of projectsMissingNettlonnConfig) {
      shape[project.id] = nettlonnSchema;
    }
    return yup.object().shape(shape);
  }, [projectsMissingNettlonnConfig, t]);

  const shouldResetForm = !isInitialLoading && isSuccess;
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    shouldUnregister: true
  });

  useEffect(() => {
    if (shouldResetForm) {
      reset();
    }
  }, [shouldResetForm, reset]);

  const handleConfirmClose = () => {
    close();
  };

  const handleCancelClose = () => {
    setShowUnsavedConfirmation(false);
  };

  const handleOnClose = () => {
    if (isMutationLoading) return;
    else {
      handleConfirmClose();
    }
  };
  const onSubmit: SubmitHandler<IFormInput> = async data => {
    const promises: Promise<void>[] = [];
    const entries = Object.entries(data);

    for (let [id, value] of entries) {
      promises.push(
        updateProject({ id, metadata: { nettlonn: { ...value } } })
      );
    }

    try {
      await Promise.all(promises);
      handleConfirmClose();
      updateSnackbar({
        open: true,
        message: `${t("common.saved")}!   🎉`,
        alert: { severity: "success" }
      });
      await promiseDelay(2000);
      updateSnackbar({
        open: false
      });
      await refetch();
    } catch (error) {
      console.log(error);
      updateSnackbar({
        open: true,
        message: `${t("common.error")}! ${e} 🚨 `,
        alert: { severity: "error" }
      });
    }
  };
  return (
    <Fragment>
      <ConfirmationDialog
        key="unsaved"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showUnsavedConfirmation}
      />

      <FormDialog
        {...rest}
        keepMounted={false}
        PaperProps={{ component: "form", onSubmit: handleSubmit(onSubmit) }}
        SubmitButtonProps={{ type: "submit" }}
        key="form"
        saveActionTitle={t("common.save")}
        open={open}
        onClose={handleOnClose}
        title={t("pages.companySettings.nettlonn.projectConfigDialog.title")}
        inProgress={isMutationLoading}
      >
        <Grid container spacing={4}>
          {projectsMissingNettlonnConfig.map(
            (project: Project, index: number) => (
              <Grid item xs={12} key={project.id}>
                <FormControl component="fieldset" fullWidth variant="outlined">
                  <FormLabel component="legend">
                    <Typography variant="h3">{project.name}</Typography>
                  </FormLabel>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        {...register(`${project.id}.salaryArt`)}
                        fullWidth
                        type="number"
                        inputProps={{ min: 0 }}
                        variant="filled"
                        label={t("common.salaryArt")}
                        error={!!errors[project.id]}
                        helperText={errors[project.id]?.salaryArt?.message}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        {...register(`${project.id}.dimensionOne`)}
                        fullWidth
                        variant="filled"
                        type="number"
                        inputProps={{ min: 0 }}
                        label={t("common.dimensionOne")}
                        error={!!errors[project.id]}
                        helperText={errors[project.id]?.dimensionOne?.message}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            )
          )}
        </Grid>
      </FormDialog>
    </Fragment>
  );
};
