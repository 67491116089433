import {
  SET_DATA,
  SET_LOADING,
  SET_SELECTED,
  TOGGLE_ALL_PAID,
  WATCH_GET_INVOICES_TABLE,
  WATCH_REFRESH_INVOICES_TABLE
} from "./constants";

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const setData = data => ({
  type: SET_DATA,
  payload: { data }
});

export const setSelected = selected => ({
  type: SET_SELECTED,
  payload: { selected }
});

export const toggleAllPaid = () => ({
  type: TOGGLE_ALL_PAID
});

export const watchGetInvoicesTable = () => ({
  type: WATCH_GET_INVOICES_TABLE
});

export const watchRefreshInvoicesTable = () => ({
  type: WATCH_REFRESH_INVOICES_TABLE,
  payload: {}
});
