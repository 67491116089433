import React from "react";
import Card from "@superprofit/core-react-components/atoms/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Logged from "./customerStats/Logged";
import ProjectDistribution from "./customerStats/ProjectDistribution";

export default ({
  month,
  year,
  stats,
  loading,
  projects,
  customer,
  statsBefore,
  ...rest
}) => {
  return (
    <Card {...rest}>
      <CardContent>
        <Grid container spacing={3} component="div" alignItems="center">
          <Grid item xs={12} md={6} lg={7} component="div">
            <Logged
              loading={loading}
              projects={projects}
              customer={customer}
              stats={stats}
              statsBefore={statsBefore}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} component="div">
            <ProjectDistribution
              loading={loading}
              projects={projects}
              customer={customer}
              stats={stats}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
