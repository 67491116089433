import { Button, CircularProgress, Skeleton } from "../../../../atoms";
import { ButtonContainer } from "./updateSubscription.styles";
import Grid from "@material-ui/core/Grid";
import React from "react";

export default ({ state, onClick, disabled }) => {
  const {
    subscriptionInformation,
    isLoadingUpcomingInvoice,
    updatedSubscriptionStatus,
    isUpdatingSubscription,
  } = state;
  const subscription = subscriptionInformation?.subscription;
  const {
    updatedSubscriptionProductId = subscription?.plan?.product?.id,
    updatedSubscriptionSeats = subscription?.quantity,
  } = state;

  if (isLoadingUpcomingInvoice)
    return (
      <Grid item xs={12}>
        <Skeleton width={100} height={32} />{" "}
      </Grid>
    );
  let buttonColor = "primary";
  let buttonText = "Confirm change"
  if (updatedSubscriptionStatus?.status === 'success') {buttonColor = 'success'; buttonText = 'Done'}
  if (updatedSubscriptionStatus?.status === 'error') {buttonColor = 'error'; buttonText = 'Retry'}
  const isDisabled =
    disabled || isUpdatingSubscription ||
    !updatedSubscriptionProductId ||
    !updatedSubscriptionSeats ||
    (updatedSubscriptionProductId === subscription?.plan?.product?.id &&
    updatedSubscriptionSeats === subscription.quantity);

  return (
    <Grid item xs={12}>
      <ButtonContainer>
        <Button
          disabled={isDisabled}
          variant="contained"
          onClick={onClick}
          color={buttonColor}
        >
          {buttonText}
        </Button>
        {isUpdatingSubscription && <CircularProgress size={24} />}
      </ButtonContainer>
    </Grid>
  );
};
