import useCompanySettings from "./useCompanySettings";
import { CompanySettingGeneric } from "../models/CompanySetting";

const useNettlonnSettings = () => {
  const { data: settings, ...rest } = useCompanySettings();

  let nettlonnSettings: CompanySettingGeneric | undefined;

  if (settings?.nettlonnSettings instanceof CompanySettingGeneric) {
    nettlonnSettings = settings.nettlonnSettings;
  }
  return {
    data: nettlonnSettings,
    nettlonnSettings,
    ...rest
  };
};

export default useNettlonnSettings;
