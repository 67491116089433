import {
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
    Button,
} from "@superprofit/core-react-components/atoms";
import React, { useState } from "react";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {ICommentEntry} from "./types";
import { moment } from "@superprofit/time-util";

export default ({comment, onDelete}: ICommentEntry) => {
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const handleCloseDialog = () => setShowDeletePrompt(false);
  const handleDeleteComment = () => {
    if (onDelete && comment) {
      onDelete(comment)
    }
    setShowDeletePrompt(false);
  }
  const created = moment()
      .year(comment.year)
      .dayOfYear(comment.day)
      .format("LL");
   return (
    <Grid container spacing={2} justifyContent="space-between" >
      <Grid item>
        <Typography variant="caption"><b>{created}</b></Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="caption">
          {comment.comment}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton size="small" onClick={() => setShowDeletePrompt(true)}>
          <DeleteOutlineIcon />
        </IconButton>
      </Grid>
      <Dialog
        open={showDeletePrompt}
        onClose={handleCloseDialog}
        aria-labelledby="delete-comment-dialog-title"
        aria-describedby="delete-comment-dialog-description"
      >
        <DialogTitle id="delete-comment-dialog-title">
          Delete comment
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-comment-dialog-description">
            Do you want to delete this comment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleDeleteComment} variant="contained" color="alert">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
