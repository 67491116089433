import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { GET, GET_SUCCESS } from "../../../api/companyDashboard/constants";
import { setBasis, setLoading } from "./actions";

const filterSortProjects = (projects, stats) => {
  return (projects || [])
    .reduce((prev, next) => {
      const isArchived = next.archived;
      const hasHours = stats.some(s => s.project === next.id);
      if (!isArchived || hasHours) {
        return [...prev, next];
      }
      return prev;
    }, [])
    .sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const getData = ({
  api: {
    companyDashboard: { data: basis }
  }
}) => ({
  basis
});

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const handleUpdate = function*(args) {
  const { basis } = yield select(getData);
  const {
    stats: { raw },
    projects
  } = basis;
  const filteredProjects = filterSortProjects(projects, raw);

  try {
    yield put(setBasis({ ...basis, projects: filteredProjects }));
    yield put(setLoading(false));
  } catch (e) {
    console.log(e);
    console.warn(e);
  }
};

export const handleStatisticsLoadingSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export const handleUpdateSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleUpdate);
};

export default function*() {
  yield all([handleUpdateSaga(), handleStatisticsLoadingSaga()]);
}
