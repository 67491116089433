import {
  GET,
  GET_FAILURE,
  GET_CUSTOMER,
  GET_CUSTOMER_FAILURE,
  GET_CUSTOMER_SUCCESS,
  GET_SUCCESS,
  PATCH,
  PATCH_FAILURE,
  PATCH_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS
} from "./constants";

const initialState = {
  list: [],
  lastUpdated: Date.now(),
  loading: false,
  pagination: {},
  saving: false,
  saveError: null
};

const addIfNotExists = (list, project) => {
  const newList = list.slice();
  const idx = newList.findIndex(c => c.id === project.id);
  if (idx > -1) {
    newList[idx] = project;
  } else {
    newList.push(project);
  }
  return newList;
};

const updateList = (list, customer) => {
  const newList = list.slice();
  const idx = newList.findIndex(c => c.id === customer.id);
  newList[idx] = customer;
  return newList;
};

const patchCustomer = (list, id, updates) => {
  const newList = list.slice();
  const idx = newList.findIndex(c => c.id === id);
  if (idx > -1) {
    newList[idx] = newList[idx].clone();
    newList[idx].setData(updates);
  }
  return newList;
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.list
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false,
        list: payload.list
      };

    case GET_CUSTOMER:
      return {
        ...state,
        loadingIdMap: {
          [payload.id]: true
        }
      };

    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loadingIdMap: {
          [payload.id]: true
        },
        list: addIfNotExists(state.list, payload.customer),
        lastUpdated: Date.now()
      };

    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        loadingIdMap: {
          [payload.id]: true
        }
      };

    case SAVE:
      return {
        ...state,
        saving: true
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        saving: false,
        list: payload.isNew
          ? [payload.customer].concat(state.list)
          : updateList(state.list, payload.customer)
      };
    case SAVE_FAILURE:
      return {
        ...state,
        saving: false,
        saveError: payload.error
      };

    case PATCH:
      return {
        ...state,
        saving: true
      };

    case PATCH_SUCCESS:
      return {
        ...state,
        saving: false,
        list: patchCustomer(state.list, payload.id, payload.updates)
      };

    case PATCH_FAILURE:
      return {
        ...state,
        saving: false,
        saveError: payload.error
      };

    default:
      return state;
  }
};
