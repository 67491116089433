import React from "react";
import Card from "@superprofit/core-react-components/atoms/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";

import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FolderIcon from "@material-ui/icons/Folder";
import useReminders from "./reminders/useReminders";
import { List } from "./reminders/Reminders.styles";

export default (props: { period: { month: number; year: number } }) => {
  const { period } = props;

  const { data } = useReminders(period);
  return (
    <Card>
      <CardContent>
        <Grid item xs={12}>
          <Typography variant="h6">Reminders</Typography>
          <Divider />
          <List dense>
            {data?.map(d => (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={d.user} secondary={d.total} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      </CardContent>
    </Card>
  );
};
