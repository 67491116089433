import Lead from "./Lead";
import { moment } from "@superprofit/time-util";
import hash from "object-hash";
import { db, firebase } from "../firebase";

export default class LeadComment {
  constructor({ id, user, comment, createdAt, updatedAt }) {
    this.id = id;
    this.user = user;
    this.comment = comment;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  isValid = () => {
    return this.comment && this.comment.length > 0;
  };

  data() {
    return {
      id: this.id,
      user: this.user,
      comment: this.comment,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt
    };
  }

  static create = async (workspace, user, lead, comment) => {
    const saveComment = new LeadComment(comment);
    saveComment.createdAt = moment()
      .utc()
      .format();
    saveComment.updatedAt = moment()
      .utc()
      .format();
    saveComment.id = hash(saveComment.data());
    saveComment.user = user;
    const comments = [saveComment.data()].concat(lead.comments);

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Lead.collectionName)
      .doc(lead.id)
      .update({
        comments: firebase.firestore.FieldValue.arrayUnion(saveComment.data())
      });

    const updatedLead = lead.clone();
    updatedLead.setData({ comments });

    return [saveComment, updatedLead];
  };
}
