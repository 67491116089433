import { put, takeLatest, all, call, race, take, select } from "redux-saga/effects";
import { saveInvoice } from "@superprofit/24-seven-integration-client";
import { WATCH_EXPORT_INVOICE } from "./constants";
import { watchCheckHasSession } from "../../../../api/integrations/twentyfourseven/auth/actions";
import { watchExportInvoice } from "../../../../api/integrations/twentyfourseven/invoices/actions";
import {
  CHECK_HAS_SESSION_FAILURE,
  CHECK_HAS_SESSION_SUCCESS
} from "../../../../api/integrations/twentyfourseven/auth/constants";
import AsyncAuthDialog from "../../../../../../pages/protected/integrations/twentyFourSeven/AsyncAuthDialog";
import {
  hideExportDialog,
  setExportLoading,
  showExportDialog
} from "./actions";
import ConfirmInvoiceSyncDialog from "../../../../../../pages/protected/integrations/twentyFourSeven/ConfirmInvoiceSyncDialog";
import { watchPatchCustomer } from "../../../../api/customers/actions";
import { watchPatchInvoice } from "../../../../api/invoices/actions";
import { watchPatchProject } from "../../../../api/projects/actions";
import Customer from "../../../../../../models/Customer";
import Project from "../../../../../../models/Project";
import {Timestamp} from '@superprofit/core-firestore-models';
import {EXPORT_FAILURE, EXPORT_SUCCESS} from '../../../../api/integrations/twentyfourseven/invoices/constants';

const LOCAL_API = 'http://localhost:5001/timetrack-222415/us-central1';

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;
export const getUserEmail = ({
  api: {
    auth: { user }
  }
}) => user.email;

export function* handleExport(args) {
  const { invoice } = args.payload;

  try {
    yield put(setExportLoading(true));
    yield put(showExportDialog());
    yield put(watchCheckHasSession());

    const { hasSession } = yield race({
      hasSession: take(CHECK_HAS_SESSION_SUCCESS),
      notHasSession: take(CHECK_HAS_SESSION_FAILURE)
    });

    const authenticated = yield hasSession || call(AsyncAuthDialog.show);

    if (authenticated) {
      const workspace = yield select(getActiveWorkspace);
      const basis = { ...invoice.basis };
      const refreshedCustomer = yield call(Customer.get, workspace, basis.customer.id);
      basis.customer.twntyFourSevenId = refreshedCustomer.twntyFourSevenId;

      const refreshedProjects = yield all(basis.projects.map((p) => call(Project.get, workspace, p.id)));

      basis.projects.forEach(p => {
        const refreshed = refreshedProjects.find(rp => rp.id = p.id);
        if (refreshed) {
          p.twntyFourSevenId = refreshed.twntyFourSevenId;
        }
      });

      // const body = {
      //   id: basis.id,
      //   twntyFourSevenId: basis.twntyFourSevenId,
      //   customer: basis.customer,
      //   projects: basis.projects,
      //   // dueDate:Timestamp.toMomentUtc(basis.dueDate).format(),
      //   issueDate:Timestamp.toMomentUtc(basis.issueDate).format(),
      // };
      // const result = yield call(saveInvoice, body);

      yield put(watchExportInvoice(basis));

      const response = yield take([EXPORT_SUCCESS, EXPORT_FAILURE]);

      if (response.type === EXPORT_SUCCESS) {
        const result =  response.payload.invoice;
        if (result.invoice.customer.twntyFourSevenId !== basis.customer.twntyFourSevenId) {
          yield put(watchPatchCustomer(basis.customer.id, {
            twntyFourSevenId: result.invoice.customer.twntyFourSevenId
          }));
        }

        const projectsToUpdate = [];
        result.invoice.projects.forEach(p => {
          const old = basis.projects.find(pOld => pOld.id === p.id);
          if (old && old.twntyFourSevenId !== p.twntyFourSevenId) {
            projectsToUpdate.push(p);
          }
        });

        yield all(projectsToUpdate.map(p => (
          put(watchPatchProject(p.id, {
            twntyFourSevenId: p.twntyFourSevenId
          }))
        )));

        // Put action that updates record in store as well?
        if (result.invoice.twntyFourSevenId !== invoice.twntyFourSevenId) {
          yield put(watchPatchInvoice(invoice.id, {
            twntyFourSevenId: result.invoice.twntyFourSevenId
          }));
        }

        yield put(setExportLoading(false));

        yield put(hideExportDialog());

        // yield call(ConfirmInvoiceSyncDialog.show);
      }
    } else {
      yield put(setExportLoading(false));
      throw new Error("Authentication failed.");
    }
  } catch (error) {
    yield put(setExportLoading(false));
    console.warn("ERROR", error);
  }
}

const exportSaga = function* authSaga() {
  yield takeLatest(WATCH_EXPORT_INVOICE, handleExport);
};

export default function*() {
  yield all([exportSaga()]);
}
