import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider
} from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./app/app.styles";
import React from "react";
import themes from "./components/themes";

// import themes from "@superprofit/core-react-components/themes";

interface IThemeProvider {
  children: React.ReactNode;
  mode: "light" | "dark";
}
export default ({ children, mode }: IThemeProvider) => {
  const theme = themes[mode];
  return (
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={{ mode, ...theme }}>
            <GlobalStyle />
            {children}
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  );
};
