import styled from "@react-pdf/styled-components";

export const Container = styled.View`
  flex-direction: column;
  padding-right: 40px;
`;

export const UserName = styled.Text`
  font-weight: 300;
`;

export const Period = styled.Text`
  padding-top: 10px;
  font-size: 12px;
  font-weight: 300;
`;

export const Tags = styled.Text`
  padding-top: 2px;
  font-size: 8px;
  font-weight: 100;
  font-style: italic;
`;

export const Email = styled.Text`
  padding-top: 10px;
  font-size: 10px;
  font-weight: 100;
  font-style: italic;
`;
