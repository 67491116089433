import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import UserProfile from "../models/UserProfile";
import useUser from "./useUser";
import Entry from "../models/Entry";
import useUserProfile, { getKey } from "./useUserProfile";

type UserProfileType = any;
const useUserProfileMutation = () => {
  const { email } = useUser();
  const workspace = useWorkspace();
  const queryClient = useQueryClient();
  const key = getKey(workspace, email);

  const { data: userProfile } = useUserProfile();

  const save = (updates: Partial<UserProfile>) => {
    if (!userProfile) {
      throw new Error("No user profile");
    }
    return UserProfile.save(workspace, email, userProfile, updates);
  };

  return useMutation(save, {
    onSuccess: (data: UserProfileType, variables, context) => {},
    onMutate: async updates => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(key);

      // Snapshot the previous value
      const previous = queryClient.getQueryData(key);

      // Optimistically update to the new value
      queryClient.setQueryData(key, (old: any) => ({ ...old, ...updates }));

      // Return a context object with the snapshotted value
      return { previous };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newTodo, context: any) => {
      console.error(err);
      queryClient.setQueryData(key, context.previous);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(key).catch(console.error);
    }
  });
};

export default useUserProfileMutation;
