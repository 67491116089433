import { GET, GET_FAILURE, GET_SUCCESS } from "./constants";

const initialState = {
  loading: false,
  data: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        data: { ...payload },
        loading: false
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
