import {
  HIDE_ASSIGN_PROJECTS_DIALOG,
  SET_SAVING,
  SHOW_ASSIGN_PROJECTS_DIALOG,
  UPDATE_ASSIGN_PROJECTS_DIALOG_DATA
} from "./constants";
import { AssignProjectsDialogAction, AssignProjectsDialogState } from "./types";
import { createReducer } from "typesafe-actions";

const initialState: AssignProjectsDialogState = {
  open: false,
  updates: {
    assignedProjects: [],
    removedProjects: []
  },
  users: [],
  saving: false,
  hasUnsaved: false
};

const reducer = createReducer<
  AssignProjectsDialogState,
  AssignProjectsDialogAction
>(initialState)
  .handleType(HIDE_ASSIGN_PROJECTS_DIALOG, state => ({
    ...state,
    open: false,
    hasUnsaved: false
  }))
  .handleType(SHOW_ASSIGN_PROJECTS_DIALOG, (state, action) => ({
    ...initialState,
    open: true,
    users: action.payload.users
  }))
  .handleType(UPDATE_ASSIGN_PROJECTS_DIALOG_DATA, (state, action) => ({
    ...state,
    updates: { ...state.updates, ...action.payload.updates },
    hasUnsaved: true
  }))
  .handleType(SET_SAVING, (state, action) => ({
    ...state,
    saving: action.payload.saving
  }));

export default reducer;
