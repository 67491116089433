import styled from "styled-components";
import { MenuItem } from "@superprofit/core-react-components/atoms";

export const StyledWeekItem = styled(MenuItem)`
  flex-direction: column;
  align-items: flex-start;

  > * {
    pointer-events: none;
  }

  .week {
    font-size: 0.8rem;
    font-weight: ${props => props.theme.typography.fontWeightMedium};
  }

  .week-current {
    font-weight: ${props => props.theme.typography.fontWeightBold};
  }

  .date-range {
    font-size: ${props => props.theme.typography.h2.fontSize};
    font-weight: ${props => props.theme.typography.h2.fontWeight};
    color: ${props => props.theme.palette.text.hint};
    letter-spacing: ${props => props.theme.typography.h2.letterSpacing};
    text-transform: uppercase;
  }
`;
