import React, { useEffect, useRef } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { authUi, firebase, logEvent, ANALYTICS_EVENT } from "../../firebase";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Divider,
  Grid,
  Link,
  Typography
} from "@superprofit/core-react-components/atoms";
import { getCurrentWorkspace } from "../../helpers";
import InputAdornment from "@superprofit/core-react-components/atoms/InputAdornment";
import CircularProgress from "@superprofit/core-react-components/atoms/CircularProgress";
import { useDebouncedCallback } from "use-debounce";
import workspaceActions from "@superprofit/core-redux-saga-modules/Workspaces/actions";
import { update } from "../../redux/modules/ui/signup/actions";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import {
  ErrorOutline,
  CheckCircle,
  ButtonContainer,
  ContainerGrid,
  ImgGrid
} from "./signup/Signup.styles";
import { watchSignOut } from "@superprofit/core-redux-saga-modules/Auth/actions";
import { CardContent } from "@superprofit/core-react-components/atoms";
import useWorkspaceSignup from "../../hooks/useWorkspaceSignup";

export default React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { isCreating, handleCreate } = useWorkspaceSignup();

  const dispatch = useDispatch();
  const user = useSelector(({ api: { auth } }) => auth.user);

  const idTokenResult = useSelector(({ api: { auth } }) => auth.idTokenResult);

  const workspaceName = useSelector(
    ({ ui: { signup } }) => signup.workspaceName
  );

  const loading = useSelector(({ ui: { signup } }) => signup.loading);

  const workspaceExist = useSelector(
    ({ ui: { signup } }) => signup.workspaceExist
  );
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const next = params.get("next");
  const workspace = getCurrentWorkspace(next);
  const isSignedIn = idTokenResult && !workspace;

  useEffect(() => {
    logEvent(ANALYTICS_EVENT.CREATE_WORKSPACE_VISITED);
  }, []);

  const checkWorkspace = name => {
    dispatch(workspaceActions.watchExist(name));
  };
  const [debouncedCheckWorkspace] = useDebouncedCallback(checkWorkspace, 300);
  useEffect(() => {
    if (workspaceName) debouncedCheckWorkspace(workspaceName);
  }, [workspaceName]);

  const handleOnChangeWorkspace = e => {
    const nm = e.currentTarget.value.replace(/[^a-z0-9\-]/gi, "");
    dispatch(update({ workspaceName: nm.toLowerCase().trim() }));
  };

  const getAdornment = () => {
    let adornment = (
      <CircularProgress
        size="1rem"
        style={{ visibility: !loading && "hidden" }}
      />
    );
    if (!loading && workspaceExist && workspaceName)
      adornment = <ErrorOutline />;
    else if (!loading && workspaceExist === false && workspaceName)
      adornment = <CheckCircle />;
    return <InputAdornment position="end">{adornment}</InputAdornment>;
  };

  return (
    <ContainerGrid>
      <ImgGrid>
        <img src="/timet_logo_3.png" />
      </ImgGrid>
      <Grid item xs={12}>
        <Grid container direction="column" justify="center" alignItems="center">
          {!isSignedIn && (
            <>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {t("pages.signup.pleaseAuthenticate")}
                  {/*Please authenticate before proceeding*/}
                </Typography>
              </Grid>
              <Grid item>
                <StyledFirebaseAuth
                  uiConfig={authUi}
                  firebaseAuth={firebase.auth()}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={4}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h1">
                  {t("pages.signup.createWorkspace")}
                  {/*Create a new Timet workspace*/}
                </Typography>
                <Typography variant="body2">
                  {t("pages.signup.createWorkspaceDescription")}
                  {/*Timet helps you organize and track hours for multiple projects*/}
                  {/*and customers.*/}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={isCreating || !isSignedIn}
                  error={workspaceExist}
                  variant="filled"
                  // label={t("common.name")}
                  helperText={
                    workspaceExist
                      ? t("pages.signup.workspaceExist")
                      : t("pages.signup.onlyLowercase")
                  }
                  // helperText={
                  //   workspaceExist
                  //     ? "Workspace exist. Try a different name"
                  //     : 'Only lowercase letters, numbers and "-" allowed.'
                  // }
                  // placeholder="my-workspace"
                  placeholder={t("pages.signup.placeholder")}
                  value={workspaceName}
                  onChange={handleOnChangeWorkspace}
                  InputProps={{
                    endAdornment: getAdornment()
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <Grid item>
                    <ButtonContainer>
                      {isCreating && <CircularProgress size="1rem" />}
                      <Button
                        variant="contained"
                        onClick={() => handleCreate(workspaceName)}
                        color="primary"
                        disabled={
                          isCreating ||
                          loading ||
                          workspaceExist === null ||
                          workspaceExist ||
                          !workspaceName ||
                          !isSignedIn
                        }
                      >
                        {t("common.create")}
                      </Button>
                    </ButtonContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Typography variant="caption" color="textSecondary">
                      {isCreating &&
                        // "Please wait while we create your workspace"
                        t("pages.signup.pleaseWait")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <br />
      <br />
      <br />
      <Grid item xs={12}>
        {isSignedIn && (
          <Grid container alignItems="center" direction="column" spacing={2}>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {user.email}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                {t("common.notYou")}
              </Typography>
              <Link component="button" onClick={() => dispatch(watchSignOut())}>
                {t("common.signOut")}
                {/*Sign out*/}
              </Link>
            </Grid>
          </Grid>
        )}
      </Grid>
    </ContainerGrid>
  );
});
