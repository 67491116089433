import {
  HIDE_GOAL_DIALOG,
  SET_SAVE_STATE,
  SHOW_GOAL_DIALOG,
  UPDATE_GOAL_DIALOG_DATA
} from "./constants";
import { moment } from "@superprofit/time-util";

const initialState = {
  open: false,
  updates: {},
  goal: { year: moment().year(), month: "*" },
  saving: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_GOAL_DIALOG:
      return {
        ...initialState,
        open: true,
        goal: {
          ...state.goal,
          ...payload.goal
        }
      };

    case SET_SAVE_STATE:
      return {
        ...state,
        saving: payload.isSaving
      };

    case HIDE_GOAL_DIALOG:
      return {
        ...state,
        open: false
      };

    case UPDATE_GOAL_DIALOG_DATA:
      return {
        ...state,
        updates: {
          ...state.updates,
          ...payload.updates
        }
      };

    default:
      return state;
  }
};
