import React, { forwardRef, ForwardRefExoticComponent } from "react";
import {
  TableCell,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tags from "../../../../components/molecules/Tags";
import UserAvatar from "../../../../components/molecules/UserAvatar";
import Label from "@superprofit/core-react-components/atoms/Label";
import DurationFormat from "../../../../components/atoms/DurationFormat";
import UserLink from "../../../../components/molecules/UserLink";
import { useWorkspaceHistory } from "../../../../contexts/WorkspaceContext";
import { useTranslation } from "react-i18next";
import useUsersAccess from "../../../../hooks/useUsersAccess";
import useConvertToMap from "../../../../hooks/useConvertToMap";
import UserAccess from "../../../../models/UserAccess";
import User from "../../../../models/User";
import Checkbox from "../../../../components/atoms/Checkbox";
import Tooltip from "../../../../components/atoms/Tooltip";
import IconButton from "../../../../components/atoms/IconButton";

interface IProps {
  user: User;
  capacity: number;
  onMore: (event: React.MouseEvent<HTMLButtonElement>, user: User) => void;
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected?: boolean;
}

const UserRow: ForwardRefExoticComponent<IProps> = forwardRef((props, ref) => {
  const { user, capacity, onMore, onSelect, selected = false, ...rest } = props;
  let status = <Label label="Not verified" color="warning" />;
  const { t } = useTranslation();
  const { data: usersAccess = [] } = useUsersAccess();
  const usersAccessMap = useConvertToMap<UserAccess>("id", usersAccess);
  const userAccess = usersAccessMap.get(user.id);

  if (!userAccess?.isActive) {
    status = <Label label="Deactivated" color="error" />;
  }
  if (userAccess?.isActive && user.emailVerified) {
    status = <Label label={t("common.active")} color="success" />;
  }

  if (userAccess?.isPendingInvite) {
    status = <Label label={t("common.pending")} color="warning" />;
  }

  const history = useWorkspaceHistory();

  const handleOnClick = () => {
    if (userAccess?.isActive && user.emailVerified)
      history.push(`/users/${user.id}`);
  };

  return (
    <TableRow key={user.id} {...rest} ref={ref} onClick={handleOnClick}>
      <TableCell style={{ maxWidth: 80, width: 80 }}>
        <Checkbox
          checked={selected}
          value={user.id}
          onChange={onSelect}
          onClick={e => e.stopPropagation()}
        />
      </TableCell>

      <TableCell style={{ maxWidth: 80, width: 80 }}>
        <UserAvatar user={user} />
      </TableCell>

      <TableCell>
        <UserLink name={user.displayName} email={user.email} />
      </TableCell>

      <TableCell>{status}</TableCell>

      <TableCell align="right">
        <DurationFormat component="b" value={capacity} />
      </TableCell>

      <TableCell>
        <Tags tags={user.tags} />
      </TableCell>

      <TableCell align="right">
        <Tooltip title="More" aria-label="More">
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={e => {
              onMore(e, user);
              e.stopPropagation();
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
});

export default UserRow;
