import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectWorkspace } from "../../../redux/modules/ui/login/actions";
import FormControl from "@superprofit/core-react-components/atoms/FormControl";
import { Button, Select } from "@superprofit/core-react-components/atoms";
import FormHelperText from "@superprofit/core-react-components/atoms/FormHelperText";
import parseDomain from "parse-domain";
import { push } from "connected-react-router";
import { Link } from "@material-ui/core";
import { watchSignOut } from "@superprofit/core-redux-saga-modules/Auth/actions";
import {
  Divider,
  TextField,
  Typography,
  Grid,
} from "@superprofit/core-react-components/atoms";

export default ({ signInPath = "" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [workspaceName, setWorkspaceName] = useState("");
  const userClaims = useSelector(
    ({
      api: {
        auth: { idTokenResult = {} },
      },
    }) => idTokenResult.claims
  );

  const selectedWorkspace = useSelector(
    ({
      ui: {
        login: { selectedWorkspace: s },
      },
    }) => s
  );
  const handleTextChange = (e) => {
    setWorkspaceName(e.currentTarget.value);
  };
  const handleJoin = () => {
    window.location.href = `/app/${workspaceName}`
  }
  //Since we dont support subdomain ssl we comment out code
  const createUrl = () => {
    // const { domain, tld } = parseDomain(window.location.href, {
    //   customTlds: /localhost|\.local/
    // });
    // if (tld === "localhost") {
    //   return `http://${selectedWorkspace}.localhost:3000${signInPath}`;
    // }
    // return `${window.location.protocol}//${selectedWorkspace}.${domain}.${tld}${signInPath}`;
    return `/app/${selectedWorkspace}`;
  };
  const handleClick = (e) => {
    // window.open(e.currentTarget.getAttribute("data-href"), "");
    dispatch(push(e.currentTarget.getAttribute("data-href")));
  };
  const handleLogout = () => {
    dispatch(watchSignOut());
  };

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Grid container direction="column" justiyContent="center">
          <Grid item>
            <Typography variant="caption">{t("Logged in as ")}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">{userClaims.email}</Typography>
          </Grid>
          <Grid item>
            <span>
              <Typography gutterBottom variant="caption">
                {t("Not you?")}{" "}
              </Typography>
              <Link gutterBottom variant="caption" onClick={handleLogout}>
                Sign out
              </Link>
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={12} md={5}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h2">{t("Select from list")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">
                  {t("Please select desired workspace.")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Select
                  autoWidth
                  native
                  variant="filled"
                  value={selectedWorkspace}
                  onChange={(e) =>
                    dispatch(selectWorkspace(e.currentTarget.value))
                  }
                  inputProps={{
                    name: "age",
                    id: "select-workspace-component",
                  }}
                >
                  <option aria-label="None" value="" disabled>
                    Workspace
                  </option>
                  {Object.keys(userClaims?.workspaces || {}).map((w) => (
                    <option key={`option_${w}`} value={w}>
                      {w}
                    </option>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={handleClick}
                  disabled={!selectedWorkspace}
                  color="primary"
                  variant="contained"
                  data-href={createUrl()}
                >
                  {t("Go")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={12} md={5}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h2">
                  {t("Workspace not in list?")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="caption">
                  {t("Type the workspace you want to go to")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onEnter={handleJoin}
                  placeholder="Workspace name"
                  variant="filled"
                  onChange={handleTextChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Button
                    onClick={handleJoin}
                    disabled={!workspaceName}
                    color="primary"
                    variant="contained"
                  >
                    {t("Go")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
