import React, { forwardRef, Fragment, useEffect, useState } from "react";
import Table, {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@superprofit/core-react-components/atoms/Table";
import { Checkbox, Tooltip } from "@superprofit/core-react-components/atoms";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import GroupRow from "./groupsTable/GroupRow";
import ActionMenu from "../../../../components/molecules/ActionMenu";
import TableLoader from "../../../../components/molecules/TableLoader";
import { IGroupsTable, Group } from "./groupsTable/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../index";
import {
  SortDirection,
  TableState
} from "../../../../redux/modules/ui/userGroups/table/types";
import { UserGroupsState } from "../../../../redux/modules/api/userGroups/types";
import UserGroup, { IUserGroup } from "../../../../models/UserGroup";
import {
  setOrderBy,
  setOrderDir,
  setPage,
  setRowsPerPage,
  setSelection,
  watchGetUserGroups
} from "../../../../redux/modules/ui/userGroups/table/actions";
import { showDialog } from "../../../../redux/modules/ui/userGroups/dialog/actions";
import { watchSaveUserGroup } from "../../../../redux/modules/api/userGroups/actions";
import { stageArchiving } from "../../../../redux/modules/ui/userGroups/page/actions";
import { useTranslation } from "react-i18next";

const descendingComparator = (/*a, b, orderBy*/) => {
  // if (orderBy === "status") {
  //     if (!a.userAccess?.isActive && !b.userAccess?.isActive) {
  //         return -b.displayName?.localeCompare(a.displayName);
  //     }
  //     if (!b.userAccess?.isActive && a.userAccess?.isActive) return 1;
  //     if (b.userAccess?.isActive && !a.userAccess?.isActive) return -1;
  //     if (b.emailVerified && !a.emailVerified) return -1;
  //     if (!b.emailVerified && a.emailVerified) return 1;
  //     return 0;
  // } else {
  //     if ((b[orderBy] || "").toLowerCase() < (a[orderBy] || "").toLowerCase())
  //         return -1;
  //     if ((b[orderBy] || "").toLowerCase() > (a[orderBy] || "").toLowerCase())
  //         return 1;
  //     return 0;
  // }
};

const getComparator = (/*orderDir, orderBy*/) => {
  return undefined;
  // return orderDir === "desc"
  //     ? (a, b) => descendingComparator(a, b, orderBy)
  //     : (a, b) => -descendingComparator(a, b, orderBy);
};

enum Direction {
  Asc = "asc",
  Desc = "desc"
}

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [actionMenu, setActionMenu] = useState<{
    group: Group | null;
    anchor: HTMLElement | null;
  }>({
    group: null,
    anchor: null
  });
  const {
    page,
    orderBy,
    orderDir,
    rowsPerPage,
    userGroups,
    selection
  }: TableState = useSelector((state: RootState) => state.ui.userGroups.table);
  const { loading }: UserGroupsState = useSelector(
    (state: RootState) => state.api.userGroups
  );

  useEffect(() => {
    dispatch(watchGetUserGroups());
  }, []);

  const selectedIds = React.useMemo(
    () =>
      selection.reduce(
        (prev, next) => ({ ...prev, [next.id || "noid"]: true }),
        {} as Record<string, boolean>
      ),
    [selection]
  );

  const handleOnSort = (
    event: React.MouseEvent<HTMLSpanElement>,
    property: keyof IUserGroup
  ) => {
    const isAsc = orderBy === property && orderDir === "asc";
    dispatch(setOrderDir(isAsc ? SortDirection.DESC : SortDirection.ASC));
    dispatch(setOrderBy(property));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event: { target?: { value: string } }) => {
    if (event.target?.value) {
      dispatch(setRowsPerPage(parseInt(event.target?.value, 10)));
      dispatch(setPage(0));
    }
  };

  const handleOnMore = (e: React.MouseEvent<any>, group: Group) => {
    setActionMenu({ anchor: e.currentTarget, group });
  };

  const handleOnCloseMore = () => {
    setActionMenu({ group: null, anchor: null });
  };

  const handleOnSelectAll = () => {
    if (selection && selection.length === userGroups.length) {
      dispatch(setSelection([]));
    } else {
      dispatch(setSelection(userGroups));
    }
  };

  const handleOnSelect = (group: Group) => {
    if (!group.id) return;
    if (selectedIds[group.id]) {
      dispatch(setSelection(selection.filter(u => u.id !== group.id)));
    } else {
      dispatch(
        setSelection(selection.filter(u => u.id !== group.id).concat(group))
      );
    }
  };

  const onEdit = (e: React.MouseEvent, g: UserGroup) => {
    dispatch(showDialog(g));
  };
  const onArchive = (e: React.MouseEvent, g: UserGroup) => {
    dispatch(stageArchiving([g]));
  };
  return (
    <Fragment>
      <ActionMenu
        onExport247={undefined}
        onChangePaid={undefined}
        onEdit={onEdit}
        onArchive={onArchive}
        onDelete={undefined}
        onView={undefined}
        onAssignRoles={undefined}
        onAssignProjects={undefined}
        data={actionMenu.group}
        open={!!actionMenu.anchor}
        anchorEl={actionMenu.anchor}
        onClose={handleOnCloseMore}
      />

      <TableContainer>
        <Table aria-label="UserGroups">
          <TableHead>
            <TableRow>
              <TableCell style={{ maxWidth: 80, width: 80 }}>
                <Checkbox
                  defaultChecked={false}
                  checked={
                    (selection.length &&
                      selection.length === userGroups.length) ||
                    false
                  }
                  indeterminate={
                    (selection.length &&
                      selection.length < userGroups.length) ||
                    false
                  }
                  inputProps={{ "aria-label": "select all groups" }}
                  onChange={handleOnSelectAll}
                />
              </TableCell>

              <TableCell sortDirection={orderBy === "name" ? orderDir : false}>
                <TableSortLabel
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? orderDir : "asc"}
                  onClick={e => handleOnSort(e, "name")}
                >
                  {t("common.name")}
                </TableSortLabel>
              </TableCell>
              <TableCell>{t("common.users")}</TableCell>
              <TableCell style={{ maxWidth: 80, width: 80 }}></TableCell>
            </TableRow>
          </TableHead>

          {!loading && (
            <Fragment>
              <TableBody>
                {userGroups.map(u => (
                  <GroupRow
                    key={u.id}
                    group={u}
                    selected={selectedIds[u.id || "noid"]}
                    onMore={handleOnMore}
                    onSelect={() => handleOnSelect(u)}
                  />
                ))}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage={t("common.rowsPerPage")}
                    rowsPerPageOptions={[
                      10,
                      25,
                      50,
                      { label: t("common.all"), value: -1 }
                    ]}
                    count={userGroups.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Fragment>
          )}

          {loading && <TableLoader cols={["rect", "text", "circle", "rect"]} />}
        </Table>
      </TableContainer>
    </Fragment>
  );
};
