import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "../../components/atoms/Page";
import Overview from "./workspaceOverview/Overview";
import { watchGetCompanyDashboard } from "../../redux/modules/api/companyDashboard/actions";

export default ({
  match: {
    params: { id }
  }
}) => {
  const dispatch = useDispatch();

  const period = useSelector(state => state.ui.workspaceOverview.period);

  useEffect(() => {
    const { year, month } = period;

    dispatch(watchGetCompanyDashboard(year, month));
  }, [period, dispatch]);

  return (
    <Page>
      <Overview />
    </Page>
  );
};
