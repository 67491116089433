import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";

// icons
import { AddOutlined } from "@material-ui/icons";

// actions
import { watchGetLeads } from "../../redux/modules/api/leads/actions";
import { showLeadDialog } from "../../redux/modules/ui/leads/dialog/actions";

// components
import Page from "@superprofit/core-react-components/atoms/Page";
import SearchField from "@superprofit/core-react-components/atoms/SearchField";
import Button from "@superprofit/core-react-components/atoms/Button";
import { PageToolbar } from "@superprofit/core-react-components/molecules/PageToolbar";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import Snackbar from "@superprofit/core-react-components/atoms/Snackbar";
import AddLeadDialog from "./leads/AddLeadDialog";
import LeadList from "./leads/LeadList";
import { AddAction, FloatingAddAction } from "./shared/PageAction.styles";
import { useTranslation } from "react-i18next";

export default () => {
  const dispatch = useDispatch();

  const snackbar = useSelector(state => state.ui.leads.snackbar);

  useEffect(() => {
    dispatch(watchGetLeads());
  }, [dispatch]);
  const { t } = useTranslation();
  const handleOnShowLeadDialog = () => {
    dispatch(showLeadDialog({}));
  };

  return (
    <Page context={t("common.management")} title="Leads">
      <PageToolbar>
        <div>
          <SearchField placeholder={t("common.search")} />
          <Button color="default" size="large" variant="contained">
            Search
          </Button>
        </div>

        <div>
          <FloatingAddAction
            onClick={handleOnShowLeadDialog}
            color="primary"
            elevation={2}
            size="large"
          >
            <AddOutlined />
          </FloatingAddAction>
          <AddAction
            size="large"
            variant="contained"
            color="primary"
            onClick={handleOnShowLeadDialog}
          >
            Add lead
          </AddAction>
        </div>
      </PageToolbar>

      <Grid container spacing={3} style={{ marginTop: 20 }} component="div">
        <Grid item xs={12} component="div">
          <LeadList />
        </Grid>
      </Grid>

      <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={snackbar.visible}
          message={snackbar.text}
        />
      </AppBar>

      <AddLeadDialog />
    </Page>
  );
};
