import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import toggleSidebar from "../../redux/modules/ui/app/actions";
import IconButton from "@superprofit/core-react-components/atoms/IconButton";
import { StyledBox } from "./appBar/AppBar.styles";
import UserIcon from "@material-ui/icons/PersonOutlineOutlined";
import HelpIcon from "@material-ui/icons/HelpOutline";
import UserMenu from "./appBar/UserMenu";
import { useTranslation } from "react-i18next";
import TranslateIcon from "@material-ui/icons/Translate";
import { SwitchEnvsDialog } from "./appBar/SwitchEnvsDialog";
import Divider from "../atoms/Divider";
import Button from "../atoms/Button";
import Menu from "../atoms/Menu";
import Typography from "@material-ui/core/Typography";
import MenuItem from "../atoms/MenuItem";
import useClaims from "../../hooks/useClaims";
import useWorkspaceDisplayName from "../../hooks/useWorkspaceDisplayName";
import useWorkspace from "../../hooks/useWorkspace";
import { StyledAnchor } from "../atoms/link/Link.styles";

export default props => {
  const { title, stickySidebar } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const claims = useClaims();
  const workspaceDisplayName = useWorkspaceDisplayName();
  const workspaceId = useWorkspace();

  const currentLanguage = i18n.language;
  const [quickLogAnchor, setQuickLogAnchor] = useState(null);
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);

  const handleOnShowQuickLog = e => {
    setQuickLogAnchor(e.currentTarget);
  };
  const handleOnCloseQuickLog = () => {
    setQuickLogAnchor(null);
  };
  const handleOnShowUserMenu = e => {
    setUserMenuAnchor(e.currentTarget);
  };
  const handleOnCloseUserMenu = () => {
    setUserMenuAnchor(null);
  };

  const handleLanguageChange = () => {
    if (currentLanguage === "en") {
      i18n.changeLanguage("nb-NO").catch(console.error);
    } else {
      i18n.changeLanguage("en").catch(console.error);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnShowSidebar = () => dispatch(toggleSidebar(true));
  const claimWorkspaces = Object.entries(claims?.workspaces || {})
    .filter(([, value]) => value.isActive)
    .map(([key]) => key);
  return (
    <AppBar>
      <Toolbar style={{ height: 64, minHeight: 64 }}>
        {!stickySidebar && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleOnShowSidebar}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
        )}
        <div style={{ flex: 1 }}>
          <StyledBox display="flex" alignItems="center">
            <Typography component="h6" variant="button" noWrap>
              {title}
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              style={{ margin: "0 16px" }}
            />
            {claimWorkspaces.length <= 1 ? (
              <Typography variant="button" noWrap>
                {(workspaceDisplayName || workspaceId).toUpperCase()}
              </Typography>
            ) : (
              <Button onClick={handleClick}>
                {workspaceDisplayName || workspaceId}
              </Button>
            )}
            {claimWorkspaces.length > 1 && (
              <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                {claimWorkspaces.map(workspace => (
                  <MenuItem key={workspace}>
                    <StyledAnchor href={`/app/${workspace}`}>
                      {workspace}
                    </StyledAnchor>
                  </MenuItem>
                ))}
              </Menu>
            )}
          </StyledBox>
        </div>

        <SwitchEnvsDialog />

        {/*<IconButton*/}
        {/*  aria-label="log hours"*/}
        {/*  color="primary"*/}
        {/*  onClick={handleOnShowQuickLog}*/}
        {/*>*/}
        {/*  <TimerIcon />*/}
        {/*</IconButton>*/}
        <IconButton color="primary" onClick={handleLanguageChange}>
          <TranslateIcon />
        </IconButton>
        <IconButton
          variant="link"
          color="primary"
          href="https://intercom.help/timetio/en/collections/3054474-get-started"
        >
          {/*<Badge badgeContent={4} color="error">*/}
          <HelpIcon />
          {/*</Badge>*/}
        </IconButton>
        <IconButton color="primary" onClick={handleOnShowUserMenu}>
          {/*<Badge badgeContent={4} color="error">*/}
          <UserIcon />
          {/*</Badge>*/}
        </IconButton>

        <UserMenu
          open={!!userMenuAnchor}
          anchorEl={userMenuAnchor}
          onClose={handleOnCloseUserMenu}
          hideBackdrop={false}
        />
      </Toolbar>
    </AppBar>
  );
};
