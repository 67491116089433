import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    &:first-child {
    }

    &:last-child {
      margin-top: 4rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }
`;
