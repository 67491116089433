import {
  HIDE_LEAD_DIALOG,
  SHOW_LEAD_DIALOG,
  UPDATE_LEAD_DIALOG_DATA
} from "./constants";

export const showLeadDialog = lead => ({
  type: SHOW_LEAD_DIALOG,
  payload: { lead }
});

export const hideLeadDialog = () => ({
  type: HIDE_LEAD_DIALOG,
  payload: {}
});

export const updateLeadDialogData = updates => ({
  type: UPDATE_LEAD_DIALOG_DATA,
  payload: { updates }
});
