import { Condition, Query } from "@superprofit/core-firestore-models";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import TimesheetComment from "../models/TimesheetComment";
import { useEffect } from "react";
import { project } from "../redux/modules/api/xstats/actions";

const useTimesheetComment = ({
  id,
  year,
  week,
  projectId
}: {
  id: string;
  year: number;
  week: number;
  projectId?: string;
}) => {
  const workspace = useWorkspace();
  const query = new Query();
  query.addCondition(new Condition("user", "==", id));
  query.addCondition(new Condition("year", "==", year));
  query.addCondition(new Condition("week", "==", week));
  if (projectId) query.addCondition(new Condition("project", "==", projectId));

  const get = () => TimesheetComment.list(workspace, query);
  const key = [
    workspace,
    id,
    TimesheetComment.collectionName,
    year,
    week,
    projectId
  ];

  return useQuery(key, get, {
    enabled: !!id && !!year && !!week
  });
};

export default useTimesheetComment;
