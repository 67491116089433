import { useCompanyFeatures } from "../../../hooks/useFeatures";

import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Card from "../../../components/atoms/Card";
import CardHeader from "../../../components/atoms/CardHeader";
import Divider from "../../../components/atoms/Divider";
import CardContent from "../../../components/atoms/CardContent";
import TextField from "../../../components/atoms/TextField";
import Button from "../../../components/atoms/Button";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useCompanySettingMutation from "../../../hooks/useCompanySettingMutation";
import useCompanySettings from "../../../hooks/useCompanySettings";
import CompanySettingGeneric from "../../../models/CompanySettingGeneric";
import Grid from "../../../components/atoms/Grid";
import Typography from "../../../components/atoms/Typography";
import Project from "../../../models/Project";
import useProjectSalarySettings from "../../../hooks/useProjectSalarySettings";
import useProjectSalaryRateSettings from "../../../hooks/useProjectSalarySettings";
import useGlobalSnackbar from "../../../hooks/useGlobalSnackbar";
import { promiseDelay } from "../../../helpers";
import Chip from "../../../components/atoms/Chip";
import Alert from "../../../components/atoms/Alert";
import useUsers from "../../../hooks/useUsers";
import User from "../../../models/User";
import FixEmployeeNumbersDialog from "./nettlonnConfig/FixEmployeeNumbersDialog";
import useNettlonnSettings from "../../../hooks/useNettlonnSettings";
import useFixEmployeeNumbersDialog from "./nettlonnConfig/useFixEmployeeNumbersDialog";
import useUsersWithoutEmployeeNumber from "./nettlonnConfig/useUsersWithoutEmployeeNumber";
import useProjectsMissingNettlonnConfig from "./nettlonnConfig/useProjectsMissingNettlonnConfig";
import useFixProjectsDialog from "./nettlonnConfig/useFixProjectsDialog";
import FixProjectsDialog from "./nettlonnConfig/FixProjectsDialog";

interface IFormInput {
  csvRecipients: string | null;
}

const NettlonnConfig = () => {
  const { update: updateSnackbar } = useGlobalSnackbar();
  const { t } = useTranslation();
  const { features } = useCompanyFeatures();
  const mutation = useCompanySettingMutation();
  const { show: showFixEmployeeNumbersDialog } = useFixEmployeeNumbersDialog();
  const {
    show: showProjectsMissingNettlonnConfigDialog
  } = useFixProjectsDialog();
  const defaultSetting = useMemo(
    () =>
      new CompanySettingGeneric({
        id: "nettlonnSettings",
        value: {},
        updatedAt: null,
        updatedBy: null
      }),
    []
  );
  const usersWithoutEmployeeNumber = useUsersWithoutEmployeeNumber();
  const projectsMissingNettlonnConfig = useProjectsMissingNettlonnConfig();
  let {
    data: nettlonnSettings = defaultSetting,
    isInitialLoading,
    isSuccess
  } = useNettlonnSettings();
  const schema = useMemo(
    () =>
      yup
        .object({
          csvRecipients: yup
            .string()
            .matches(
              //valid email address separated by semicolon
              /^([a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})(;[ \t]*[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/
            )
            .required()
        })
        .required(),
    [t]
  );
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
    reset
  } = useForm<IFormInput>({
    resolver: yupResolver(schema)
  });

  const shouldResetForm = !isInitialLoading && isSuccess;

  useEffect(() => {
    if (shouldResetForm) {
      reset({
        csvRecipients: nettlonnSettings?.value?.csvRecipients?.join(";")
      });
    }
  }, [shouldResetForm, reset, nettlonnSettings]);
  const onSubmit = async (data: IFormInput) => {
    const cl = nettlonnSettings.clone();
    if (data.csvRecipients === null) {
      cl.setData({ value: {} });
    } else {
      cl.setData({
        value: {
          csvRecipients: data.csvRecipients.split(";")
        }
      });
    }
    await mutation.mutateAsync(cl, {
      onSuccess: async () => {
        updateSnackbar({
          open: true,
          message: `${t("common.saved")}!   🎉`,
          alert: { severity: "success" }
        });
        await promiseDelay(2000);
        updateSnackbar({
          open: false
        });
      },
      onError: async e => {
        updateSnackbar({
          open: true,
          message: `${t("common.error")}! ${e} 🚨 `,
          alert: { severity: "error" }
        });
        await promiseDelay(2000);
        updateSnackbar({
          open: false
        });
      }
    });
  };

  if (!features?.nettlonn) {
    return null;
  }

  const recipientsString = watch("csvRecipients");

  const recipientsChips = (
    recipientsString?.split(";").filter(Boolean) || []
  ).map((email: string, index: number) => (
    <Grid item>
      <Chip key={index} label={email} />
    </Grid>
  ));
  const handleFixEmployeeNumbers = () => {
    showFixEmployeeNumbersDialog();
  };
  const handleFixProjectsMissingNettlonnConfig = () => {
    showProjectsMissingNettlonnConfigDialog();
  };
  return (
    <>
      <Card>
        <CardHeader title={t("pages.companySettings.nettlonn.title")} />
        <Divider />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} justify="flex-end">
              <Grid item xs={12}>
                <TextField
                  {...register("csvRecipients")}
                  variant="filled"
                  label={t("pages.companySettings.nettlonn.csvRecipientsLabel")}
                  error={!!errors.csvRecipients}
                  helperText={t(
                    "pages.companySettings.nettlonn.csvRecipientsHelperText"
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">
                  {t("pages.companySettings.nettlonn.csvRecipientsDesc")}
                </Typography>
              </Grid>
              {recipientsString && (
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    {t("common.recipient", { count: recipientsChips.length })}:{" "}
                  </Typography>
                  <Grid container spacing={1}>
                    {recipientsChips}
                  </Grid>
                </Grid>
              )}
              {usersWithoutEmployeeNumber.length > 0 && (
                <Grid item xs={12}>
                  <Alert
                    severity="warning"
                    action={
                      <Button
                        color="primary"
                        onClick={handleFixEmployeeNumbers}
                      >
                        {t("common.fixIt")}
                      </Button>
                    }
                  >
                    {t("pages.companySettings.nettlonn.missingEmployeeNumber", {
                      count: usersWithoutEmployeeNumber.length
                    })}
                  </Alert>
                </Grid>
              )}
              {projectsMissingNettlonnConfig.length > 0 && (
                <Grid item xs={12}>
                  <Alert
                    severity="warning"
                    action={
                      <Button
                        color="primary"
                        onClick={handleFixProjectsMissingNettlonnConfig}
                      >
                        {t("common.fixIt")}
                      </Button>
                    }
                  >
                    {t("pages.companySettings.nettlonn.projectsMissingConfig", {
                      count: projectsMissingNettlonnConfig.length
                    })}
                  </Alert>
                </Grid>
              )}
              <Grid item>
                <Button
                  disabled={!isDirty || !!errors?.csvRecipients}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  {t("common.save")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <FixEmployeeNumbersDialog />
      <FixProjectsDialog />
    </>
  );
};

export default NettlonnConfig;
