import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel
} from "@superprofit/core-react-components/atoms";
import React from "react";
import {
  Card,
  CardHeader,
  Divider,
  CardContent
} from "@superprofit/core-react-components/atoms";
import UserSettingGeneric from "../../../models/UserSettingGeneric";
import { watchSaveUserSettings } from "../../../redux/modules/api/userSettings/actions";
import CompanySettingGeneric from "../../../models/CompanySettingGeneric";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { settings } = useSelector(
    (state: {
      api: { userSettings: { settings: { features: { value: any } } } };
    }) => state.api.userSettings
  );

  const features = settings?.features?.value || {};
  const handleComments = () => {
    dispatch(
      watchSaveUserSettings([
        new UserSettingGeneric({
          id: "features",
          value: {
            ...features,

            timesheetEntryComments: !features?.timesheetEntryComments
          },
          user: null,
          updatedAt: null,
          updatedBy: null,
          createdAt: null,
          createdBy: null
        })
      ])
    );
  };
  const handleAutoFillWeek = () => {
    dispatch(
      watchSaveUserSettings([
        new UserSettingGeneric({
          id: "features",
          value: {
            ...features,
            autoFillWeek: !features?.autoFillWeek
          },
          user: null,
          updatedAt: null,
          updatedBy: null,
          createdAt: null,
          createdBy: null
        })
      ])
    );
  };

  const handleSyncHoursEwork = () => {
    dispatch(
      watchSaveUserSettings([
        new UserSettingGeneric({
          id: "features",
          value: {
            ...features,
            syncHoursEwork: !features?.syncHoursEwork
          },
          user: null,
          updatedAt: null,
          updatedBy: null,
          createdAt: null,
          createdBy: null
        })
      ])
    );
  };

  return (
    <Card>
      <CardHeader title={t("common.features")} />
      <Divider />
      <CardContent>
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">
            {t("pages.profileSettings.featuresHeader")}
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!features?.timesheetEntryComments}
                  onChange={handleComments}
                />
              }
              label={t("pages.profileSettings.timesheetEntryComments")}
            />
            <FormHelperText>
              {t("pages.profileSettings.timesheetEntryCommentsDesc")}
            </FormHelperText>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!features?.autoFillWeek}
                  onChange={handleAutoFillWeek}
                />
              }
              label={t("pages.profileSettings.autoFillWeek")}
            />
            <FormHelperText>
              {t("pages.profileSettings.autoFillWeekDesc")}
            </FormHelperText>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!features?.syncHoursEwork}
                  onChange={handleSyncHoursEwork}
                />
              }
              label={t("pages.profileSettings.syncHoursEwork")}
            />
            <FormHelperText>
              {t("pages.profileSettings.syncHoursEworkDesc")}
            </FormHelperText>
          </FormGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
};
