import { SET_GOALS, WATCH_REFRESH_GOALS } from "./constants";

export const setGoals = goals => ({
  type: SET_GOALS,
  payload: { goals }
});

export const watchRefreshGoals = () => ({
  type: WATCH_REFRESH_GOALS,
  payload: {}
});
