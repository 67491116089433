import {
  STAGE_ARCHIVING, WATCH_ARCHIVE,
} from "./constants";
import { action } from "typesafe-actions";
import UserGroup, {IUserGroup} from "../../../../../models/UserGroup";

export const stageArchiving = (userGroups: UserGroup[]) => action(STAGE_ARCHIVING, {userGroups});
export const watchArchive = () => action(WATCH_ARCHIVE);



