// @ts-ignore
import { QueryBuilder, Timestamp } from "@superprofit/core-firestore-models";
import { db, firebase } from "../firebase";
import {
  Colors,
  IProject as IProjectT,
  SnapshotOptions,
  DocumentSnapshot,
  QueryDocumentSnapshot
} from "./project/types";
import UserGroup from "@superprofit/timet-react-client/src/models/UserGroup";

const colors: Colors = [
  "#4422ff",
  "#2255cc",
  "#aa3388",
  "#FA5A5D",
  "#00ffff",
  "#ffff00",
  "#ff9900",
  "#88ff88"
];

const getIndex = (initial: string) => {
  return initial.toLowerCase().charCodeAt(0) % colors.length;
};

const getColor = (initial: string) => {
  return colors[getIndex(initial)];
};
//Keep the export
export type IProject = IProjectT;

export default class Project implements IProject {
  id: string | null;
  name: string;
  billable: boolean;
  billableRate: number | null;
  billableCurrency: "nok" | "usd";
  salaryEnabled: boolean;
  salaryRate: number | null;
  salaryCurrency: "nok" | "usd";
  salaryType: "provision" | "fixed" | "none";
  salaryProvisionPercentage: number | null;

  budget: Array<{ type: string; value: number }> | null;
  userSalaryRate: { [key: string]: number | null } | null;
  userBillableRate: { [key: string]: number | null } | null;
  team: string[];
  userGroups: string[];
  tags: string[];
  customer: string;
  color: typeof colors[number];
  metadata: IProject["metadata"];
  managerName: string | null;
  managerEmail: string | null;
  twntyFourSevenId: string | null;
  externalReference: string | null;
  planned: { [key: string]: number } | null;
  archived: boolean;
  createdBy: string;
  updatedBy: string;
  updatedAt: Timestamp | firebase.firestore.FieldValue;
  createdAt: Timestamp | firebase.firestore.FieldValue;
  static collectionName = "projects";
  static userGroupPrefixInTeam = "userGroup:";

  static getBillableRateAsNumber(
    billableRate: null | string | number
  ): null | number {
    // At some point billableRate was set to string in the database.
    // if it is a string, we need to convert it to number.
    // This needs to be fixed so that billableRate is always a number.
    if (billableRate === null) {
      return null;
    }

    if (typeof billableRate === "number") {
      return billableRate;
    }
    const numberValue = parseFloat(billableRate);

    if (Number.isNaN(numberValue)) {
      throw new Error(`The value "${numberValue}" is not a valid number`);
    }
    return numberValue;
  }

  static converter = {
    toFirestore(project: Project) {
      const data = project.data();
      if (data.billableRate !== null) {
        data.billableRate = Project.getBillableRateAsNumber(data.billableRate);
      }

      if (data.userBillableRate) {
        for (const [key, value] of Object.entries(data.userBillableRate)) {
          try {
            data.userBillableRate[key] = Project.getBillableRateAsNumber(value);
          } catch (e) {
            console.error(
              `Error converting userBillableRate[${key}]: ${value} to number. \n Setting it to null.`
            );
            data.userBillableRate[key] = null;
          }
        }
      }

      if (data.userSalaryRate) {
        for (const [key, value] of Object.entries(data.userSalaryRate)) {
          try {
            data.userSalaryRate[key] = Project.getBillableRateAsNumber(value);
          } catch (e) {
            console.error(
              `Error converting userSalaryRate[${key}]: ${value} to number. \n Setting it to null.`
            );
            data.userSalaryRate[key] = null;
          }
        }
      }

      return data;
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IProject;
      return new Project({ ...data, id: snapshot.id });
    }
  };

  static createId(workspace: string): string {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(Project.collectionName)
      .doc().id;
  }

  static prefixUserGroupIds(ids: string[]) {
    return ids.map(i => `${Project.userGroupPrefixInTeam}${i}`);
  }

  static isUserInProject(
    id: string,
    project: Project,
    allUserGroups: UserGroup[]
  ) {
    const usersUserGroupIds = allUserGroups
      .filter(ug => ug.users.indexOf(id) > -1 || ug.users.indexOf("all") > -1)
      .map(ug => ug.id);
    const inUserGroup = project.userGroups.some(
      ugId => usersUserGroupIds.indexOf(ugId) > -1
    );
    return project.team.indexOf(id) > -1 || inUserGroup;
  }

  static isUsersInProject(
    ids: string[],
    project: Project,
    allUserGroups: UserGroup[]
  ) {
    return ids.every(id => Project.isUserInProject(id, project, allUserGroups));
  }

  constructor({
    id = null,
    name,
    billable,
    billableRate,
    billableCurrency,
    salaryEnabled,
    salaryRate,
    salaryCurrency,
    salaryProvisionPercentage,
    budget,
    userBillableRate,
    userSalaryRate,
    team,
    metadata,
    userGroups,
    tags,
    color,
    customer,
    managerName,
    managerEmail,
    salaryType,
    twntyFourSevenId,
    externalReference,
    planned,
    archived,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt
  }: IProject) {
    this.id = id;
    this.name = name;
    this.billable = billable;
    this.billableRate = Project.getBillableRateAsNumber(billableRate);
    this.billableCurrency = billableCurrency || "usd";
    this.salaryEnabled = salaryEnabled;
    this.salaryRate = salaryRate;
    this.salaryCurrency = salaryCurrency || "usd";
    this.salaryType = salaryType || "none";
    this.salaryProvisionPercentage = salaryProvisionPercentage || null;
    this.budget = budget || null;
    this.userBillableRate = userBillableRate || {};
    this.userSalaryRate = userSalaryRate || {};
    this.metadata = metadata || {};
    this.team = team || [];
    this.userGroups = userGroups || [];
    this.tags = tags;
    this.customer = customer || "";
    this.color = color || "#3f51b5";
    this.managerName = managerName;
    this.managerEmail = managerEmail;
    this.twntyFourSevenId = twntyFourSevenId || null;
    this.externalReference = externalReference || null;
    this.planned = planned || {};
    this.archived = archived || false;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.updatedBy = updatedBy;
    this.updatedAt = updatedAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IProject>) {
    Object.assign(this, updates);
    return this;
  }

  static getPlanKey(year: number, month: number, userId: string) {
    return `${year}.${month}.${userId}`;
  }

  getPlannedHoursByMonthByUser(year: number, month: number, userId: string) {
    return this?.planned?.[`${year}.${month}.${userId}`] || 0;
  }

  getPlannedHoursByMonthByUsers(year: number, month: number) {
    return this.team.reduce(
      (prev, next) => ({
        ...prev,
        [next]: this.getPlannedHoursByMonthByUser(year, month, next)
      }),
      {}
    );
  }

  getProjectPlannedHoursByMonth(year: number, month: number) {
    return this.team.reduce((prev, next) => {
      return prev + this.getPlannedHoursByMonthByUser(year, month, next);
    }, 0);
  }

  getPlannedMoneyByMonthByUser(year: number, month: number, userId: string) {
    return (
      this.getPlannedHoursByMonthByUser(year, month, userId) *
      this.getBillableRate(userId)
    );
  }

  getPlannedMoneyByMonthByUsers(year: number, month: number) {
    return this.team.reduce(
      (prev, next) => ({
        ...prev,
        [next]: this.getPlannedMoneyByMonthByUser(year, month, next)
      }),
      {}
    );
  }

  getProjectPlannedMoneyByMonth(year: number, month: number) {
    const byUser = this.getPlannedMoneyByMonthByUsers(year, month);
    return (Object.values(byUser) as Array<number>).reduce(
      (prev, next) => prev + next,
      0
    );
  }

  getColor() {
    return (
      this.color ||
      (this.name && this.name.length && getColor(this.name[0])) ||
      "#3f51b5"
    );
  }

  getBillableRate(userId: string) {
    if (!this.billable) return 0;
    if (!this.billableRate) return 0;
    const nr =
      typeof this.billableRate === "string"
        ? parseInt(this.billableRate, 10)
        : this.billableRate;

    const userBillable = this.userBillableRate && this.userBillableRate[userId];
    const userNr =
      typeof userBillable === "string"
        ? parseInt(userBillable, 10)
        : userBillable;

    return userNr || nr;
  }

  getSalaryRate(userId: string) {
    const userBillable = this.userBillableRate && this.userBillableRate[userId];
    const billableRate = userBillable || this.billableRate;
    const userSalary = this.userSalaryRate && this.userSalaryRate[userId];
    if (this.salaryType === "none" || !this.salaryEnabled) {
      return 0;
    } else if (this.salaryType === "fixed") {
      return userSalary || this.salaryRate;
    } else if (this.salaryType === "provision" && billableRate) {
      return (billableRate * (this.salaryProvisionPercentage || 0)) / 100;
    }
  }

  data(): IProject {
    return {
      id: this.id,
      name: this.name,
      billable: this.billable || false,
      billableRate: this.billableRate || null,
      billableCurrency: this.billableCurrency || null,
      salaryEnabled: this.salaryEnabled || false,
      salaryRate: this.salaryRate || null,
      salaryType: this.salaryType || null,
      salaryCurrency: this.salaryCurrency || null,
      salaryProvisionPercentage: this.salaryProvisionPercentage || null,
      budget: this.budget || null,
      userBillableRate: this.userBillableRate || {},
      userSalaryRate: this.userSalaryRate || {},
      team: this.team || [],
      userGroups: this.userGroups || [],
      tags: this.tags || "",
      managerName: this.managerName || "",
      managerEmail: this.managerEmail || "",
      externalReference: this.externalReference || null,
      metadata: this.metadata || {},
      customer: this.customer || "",
      color:
        this.color ||
        (this.name && this.name.length && getColor(this.name[0])) ||
        "#3f51b5",
      planned: this.planned || {},
      archived: this.archived || false,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
      twntyFourSevenId: this.twntyFourSevenId || null
    };
  }

  static async get(workspace: string, project: string) {
    const doc = await db
      .collection("workspaces")
      .doc(workspace)
      .collection("projects")
      .doc(project)
      .withConverter(Project.converter)
      .get();
    return doc.data();
  }

  static list = async (workspace: string, query?: any) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(Project.collectionName),
      query
    )
      .withConverter(Project.converter)
      .get();

    return snapshot.docs.map(
      (doc: QueryDocumentSnapshot) => doc.data() as Project
    );
  };

  static create = async (
    workspace: string,
    user: string,
    data: Omit<
      IProject,
      "id" | "updatedBy" | "updatedAt" | "createdBy" | "createdAt"
    >
  ) => {
    const project = new Project({
      ...data,
      id: Project.createId(workspace),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedBy: user,
      createdBy: user
    });

    if (!project.id) {
      throw new Error("[project] Missing id when creating!");
    }

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Project.collectionName)
      .doc(project.id)
      .withConverter(Project.converter)
      .set(project, { merge: true });

    return project;
  };

  static patch = async (
    workspace: string,
    user: string,
    id: string,
    updates: Partial<IProject>
  ) => {
    const patchData = {
      updatedAt: Timestamp.now(),
      updatedBy: user,
      ...updates
    };

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Project.collectionName)
      .doc(id)
      .set(patchData, { merge: true });

    return { patchData };
  };

  static update = async (
    workspace: string,
    user: string,
    project: Project,
    updates: Partial<IProject>
  ) => {
    const updatedProject =
      (project.clone && project.clone()) || new Project({ ...project });

    updatedProject.setData({
      ...updates,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedBy: user
    });
    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(Project.collectionName)
      .doc(updatedProject.id)
      .withConverter(Project.converter)
      .set(updatedProject, { merge: true });

    return updatedProject;
  };

  static delete = async (workspace: string, id: string) => {
    await db
      .collection("workspaces")
      .doc(workspace)
      .collection("projects")
      .doc(id)
      .delete();
  };

  static patchMultiple = async (
    workspace: string,
    user: string,
    ids: string[],
    updates: Partial<IProject>,
    updatesById?: { [key: string]: Partial<IProject> }
  ) => {
    return Promise.all(
      ids.map(id => {
        let ups = updates;
        if (updatesById && updatesById[id]) {
          ups = { ...ups, ...updatesById[id] };
        }
        return Project.patch(workspace, user, id, ups);
      })
    );
  };
}
