import { GET, GET_SUCCESS, GET_FAILURE } from "./constants";

export const get = () => ({
  type: GET,
  payload: {}
});

export const getSuccess = (workspace, user, role) => ({
  type: GET_SUCCESS,
  payload: { workspace, user, role }
});

export const getFailure = error => ({
  type: GET_FAILURE,
  payload: { error }
});
