import {
  takeLatest,
  call,
  put,
  select,
  all,
} from "redux-saga/effects";
import {
  get,
  getFailure,
  getSuccess,
  post,
  postFailure,
  postSuccess,
} from "./actions";
import { WATCH_POST, WATCH_GET } from "./constants";

let userRoleUrl;
export const init = (url) => {
  userRoleUrl = url;
};

export const getUserRole = function* (args) {
  try {
    const { payload } = args;
    const { email } = payload;

    yield put(get());
    const workspaceId = yield select(
      ({ api: { auth } }) => auth.activeWorkspaceId
    );
    const token = yield select(({ api: { auth } }) => auth.idTokenResult.token);

    const doGet = () =>
      fetch(`${userRoleUrl}?workspaceId=${workspaceId}&userEmail=${email}`, {
        headers: {
          Authorization: token,
        },
      });
    const response = yield call(doGet);

    if (response.ok) {
      const role = yield call(() => response.text());
      yield put(getSuccess({email, role}));
    } else {
      yield put(getFailure(`Response was not ok - Status text: ${response.statusText}`))
    }
  } catch (e) {
    console.error('[user-role-saga]', e);
    yield put(getFailure(e.toString()));
  }
};

export const postUserRole = function* (args) {
  const { payload } = args;
  const { email, role } = payload;
  try {
    const workspaceId = yield select(
      ({ api: { auth } }) => auth.activeWorkspaceId
    );
    const token = yield select(({ api: { auth } }) => auth.idTokenResult.token);
    yield put(post());
    const doPost = () =>
      fetch(userRoleUrl, {
        method: "POST",
        body: JSON.stringify({
          workspaceId,
          role,
          userEmail: email,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
    const response = yield call(doPost);
    if (response.ok) {
      yield put(postSuccess({email, role}));
    } else {
      yield put(postFailure(`Response was not ok - Status text: ${response.statusText}`))
    }

  } catch (e) {
    console.error("[user-role-saga]", e);
    yield put(postFailure(e.toString()));
  }
};

export const getUserRoleSaga = function* getUsersSaga() {
  yield takeLatest(WATCH_GET, getUserRole);
};

export const postUserRoleSaga = function* () {
  yield takeLatest(WATCH_POST, postUserRole);
};

export default function* () {
  yield all([getUserRoleSaga(), postUserRoleSaga()]);
}
