import { moment } from "@superprofit/time-util";

export const getHoursByProject = (stats = []) => {
  const hoursByProjectId = {};
  stats.forEach(s => {
    hoursByProjectId[s.project] = (hoursByProjectId[s.project] || 0) + s.total;
  });

  return hoursByProjectId;
};

export const getMoneyByProject = (stats = [], projectsById = {}) => {
  const moneyByProjectId = {};

  stats.forEach(s => {
    if (projectsById[s.project] && projectsById[s.project].billable) {
      moneyByProjectId[s.project] =
        (moneyByProjectId[s.project] || 0) +
        s.total * projectsById[s.project].getBillableRate(s.user);
    }
  });

  return moneyByProjectId;
};
export const getSalaryByProject = (stats = [], projectsById = {}) => {
  const moneyByProjectId = {};

  stats.forEach(s => {
    if (projectsById[s.project] && projectsById[s.project].salaryEnabled) {
      moneyByProjectId[s.project] =
        (moneyByProjectId[s.project] || 0) +
        s.total * projectsById[s.project].getSalaryRate(s.user);
    }
  });

  return moneyByProjectId;
};

export const getRelativeHoursPercentageByProject = (stats = []) => {
  const hoursByProjectId = getHoursByProject(stats);
  const max = Math.max.apply(this, Object.values(hoursByProjectId));
  return Object.keys(hoursByProjectId).reduce(
    (prev, next) => ({
      ...prev,
      [next]: (hoursByProjectId[next] / max) * 100
    }),
    {}
  );
};

export const getRelativeMoneyByProject = (stats = []) => {
  const moneyByProjectId = getMoneyByProject(stats);
  const max = Math.max.apply(this, Object.values(moneyByProjectId));
  return Object.keys(moneyByProjectId).reduce(
    (prev, next) => ({
      ...prev,
      [next]: (moneyByProjectId[next] / max) * 100
    }),
    {}
  );
};

export const getUserHoursByProject = (stats = []) => {
  return stats.reduce((prev, next) => {
    if (next.total === 0) return prev;
    const userHours =
      ((prev[next.project] && (prev[next.project][next.user] || 0)) || 0) +
      next.total;
    const projectRecord = {
      ...(prev[next.project] || {}),
      [next.user]: userHours
    };
    return {
      ...prev,
      [next.project]: projectRecord
    };
  }, {});
};

export const getHoursPerWeekByProject = (stats = []) => {
  const statsByWeek = stats.reduce((prev, next) => {
    if (next.total === 0) return prev;
    const weekHours =
      ((prev[next.project] && (prev[next.project][next.week] || 0)) || 0) +
      next.total;
    const projectRecord = {
      ...(prev[next.project] || {}),
      [next.week]: weekHours
    };
    return {
      ...prev,
      [next.project]: projectRecord
    };
  }, {});
  return statsByWeek;
};

export const getUserHoursPerWeekByProject = (stats = []) => {
  const statsByUserProjectWeek = stats.reduce((prev, next) => {
    if (next.total === 0) return prev;
    const existing =
      (prev[next.project] &&
        prev[next.project][next.user] &&
        prev[next.project][next.user][next.week]) ||
      0;
    const userHours = existing + next.total;
    let projectRecord = { ...(prev[next.project] || {}) };
    projectRecord[next.user] = { ...(projectRecord[next.user] || {}) };
    projectRecord[next.user][next.week] = userHours;

    return {
      ...prev,
      [next.project]: projectRecord
    };
  }, {});
  return statsByUserProjectWeek;
};

export const getWeekOfMonthDates = (year, month, week) => {
  const monthStart = moment()
    .year(year)
    .month(month - 1)
    .startOf("month");
  const monthEnd = moment()
    .year(year)
    .month(month - 1)
    .endOf("month");

  const startOfMonth = moment()
    .year(year)
    .month(month - 1)
    .startOf("month");

  while (
    startOfMonth.year() === year &&
    startOfMonth.month() === month - 1 &&
    startOfMonth.week() !== week
  ) {
    startOfMonth.add(1, "week");
  }

  const endOfMonth = moment()
    .year(year)
    .month(month - 1)
    .endOf("month");
  const start = startOfMonth.clone().startOf("week");
  const end = startOfMonth.clone().endOf("week");

  let startD = start.date();
  let endD = end.date();
  if (start.isBefore(monthStart)) {
    startD = startOfMonth.date();
  }

  if (end.isAfter(monthEnd)) {
    endD = endOfMonth.date();
  }
  return `${startD}-${endD}`;
};

export const getProjectUserEntries = (entries = [], projectsById) => {
  return entries.reduce((prev, next) => {
    const project = projectsById[next.project];
    if (next.hours === 0) return prev;
    const date = `${next.year}-${next.dayOfYear}`;
    const res = { ...prev };
    res[next.project] = res[next.project] || {};
    res[next.project][next.user] = res[next.project][next.user] || [];
    res[next.project][next.user].push({
      date,
      year: next.year,
      dayOfYear: next.dayOfYear,
      hours: next.hours,
      money: next.hours * project.getBillableRate(next.user),
      comments: next.comments || []
    });

    return res;
  }, {});
};

export const getUserMoneyByProject = (stats = [], projectsById) => {
  return stats.reduce((prev, next) => {
    const project = projectsById[next.project];

    if (next.total === 0 || !project.billable) return prev;
    const userMoney =
      ((prev[next.project] && (prev[next.project][next.user] || 0)) || 0) +
      next.total * project.getBillableRate(next.user);

    const projectRecord = {
      ...(prev[next.project] || {}),
      [next.user]: userMoney
    };

    return {
      ...prev,
      [next.project]: projectRecord
    };
  }, {});
};

export const getUserSalaryByProject = (stats = [], projectsById) => {
  return stats.reduce((prev, next) => {
    const project = projectsById[next.project];
    if (next.total === 0 || !project.salaryEnabled) return prev;
    const userMoney =
      ((prev[next.project] && (prev[next.project][next.user] || 0)) || 0) +
      next.total * project.getSalaryRate(next.user);

    const projectRecord = {
      ...(prev[next.project] || {}),
      [next.user]: userMoney
    };

    return {
      ...prev,
      [next.project]: projectRecord
    };
  }, {});
};

export const filterArchivedProjects = (projects, stats) => {
  return (projects || [])
    .reduce((prev, next) => {
      const isArchived = next.archived;
      const hasHours = stats.some(s => s.project === next.id);
      if (!isArchived || hasHours) {
        return [...prev, next];
      }
      return prev;
    }, [])
    .sort((a, b) => (a.name < b.name ? -1 : 1));
};
