import { useInvoiceCreateMutation } from "../../../../hooks/useInvoiceCreateMutation";
import Invoice from "../../../../models/Invoice";
import { useState } from "react";
import useGlobalSnackbar from "../../../../hooks/useGlobalSnackbar";
import useWorkspace from "../../../../hooks/useWorkspace";
import { useTranslation } from "react-i18next";
import Customer from "../../../../models/Customer";
import Project from "../../../../models/Project";
import * as TwentyFourSeven from "@superprofit/24-seven-integration-client";
import { authenticate } from "./AsyncAuthenticateDialog";
import { getAuthTokenFromLocalStorage, hasSession } from "./helpers";
import { promiseDelay } from "../../../../helpers";
export type Payload = {
  year: number;
  month: number;
  customer: Customer;
  projects: Project[];
  dueDate: Date;
  issueDate: Date;
};
export function use24SOIntegrationInvoice() {
  const workspace = useWorkspace();
  const { mutateAsync: createInvoice } = useInvoiceCreateMutation();
  const { update: updateSnackbar } = useGlobalSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  /*
  *   let basis = yield call(
    Invoice2.createDataFromXstats,
    workspace,
    invoice.year,
    invoice.month,
    invoice.customer,
    projects,
    invoice.dueDate,
    invoice.issueDate
  );
  basis.projects = basis.projects.filter(p => p?.entries?.length > 0);
  invoice.projects = projects.filter(p => basis.projects.findIndex(bp => bp.id === p.id) > -1 );
  invoice.basis = basis;

  yield put(watchSaveInvoice({}, invoice));
  *
  * */

  const saveInvoice = async (payload: Payload) => {
    try {
      const { year, month, customer, projects, dueDate, issueDate } = payload;
      setIsLoading(true);
      const hasActiveSession = await hasSession(workspace);
      if (!hasActiveSession) {
        const result = await authenticate({ props: { workspace } });
        if (result.reason === "cancel") {
          return;
        }
      }
      const invoiceData = await Invoice.createData(
        workspace,
        year,
        month,
        customer,
        projects,
        dueDate,
        issueDate
      );

      updateSnackbar({
        open: true,
        message: `${t("common.exporting")}... 📈`,
        alert: { severity: "info" }
      });

      const createdInvoice = await createInvoice(invoiceData);
      const token = getAuthTokenFromLocalStorage(workspace);
      const result = await TwentyFourSeven.saveInvoice(
        {
          id: createdInvoice.id,
          twntyFourSevenId: createdInvoice.twntyFourSevenId,
          customer: createdInvoice.customer,
          projects: createdInvoice.basis.projects,
          // dueDate:Timestamp.toMomentUtc(invoice.dueDate).format(),
          issueDate: createdInvoice.issueDate
        },
        { token }
      );
      if (!result.response.ok) {
        throw new Error(result.responseData);
      }
      updateSnackbar({
        open: true,
        message: `${t("common.saved")}!   🎉`,
        alert: { severity: "success" }
      });
      await promiseDelay(1000);
    } catch (error) {
      console.error("Error saving invoice", error);
      updateSnackbar({
        open: true,
        message: `${t("common.error")}! ${error.toString()} 🚨`,
        alert: { severity: "error" }
      });
      await promiseDelay(3000);
    } finally {
      updateSnackbar({ open: false });
      setIsLoading(false);
    }
  };

  return {
    saveInvoice,
    isLoading
  };
}
