import {
  SET_DATA,
  WATCH_GET_CUSTOMER_TABLE,
  SET_LOADING,
  WATCH_REFRESH_CUSTOMERS_TABLE
} from "./constants";

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});

export const setData = data => ({
  type: SET_DATA,
  payload: { data }
});

export const watchGetCustomerTable = () => ({
  type: WATCH_GET_CUSTOMER_TABLE,
  payload: {}
});

export const watchRefreshCustomersTable = () => ({
  type: WATCH_REFRESH_CUSTOMERS_TABLE,
  payload: {}
});
