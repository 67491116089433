export const fontWeights = {
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600
};

export default {
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  ...fontWeights,
  h1: {
    fontSize: "1.25rem",
    fontWeight: fontWeights.fontWeightMedium
  },
  h2: {
    fontSize: ".675rem",
    fontWeight: fontWeights.fontWeightBold,
    letterSpacing: 0.8
  },
  h3: {
    fontSize: "1rem",
    fontWeight: fontWeights.fontWeightBold
  },
  h4: {
    fontSize: ".875rem",
    fontWeight: fontWeights.fontWeightBold
  },
  h5: {
    fontSize: ".75rem",
    fontWeight: fontWeights.fontWeightRegular,
    letterSpacing: 0.9
  }
};
