import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  Ref
} from "react";
import { StyledLink } from "./link/Link.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../index";
import { LinkProps } from "react-router-dom";
import * as H from "history";

interface ILink {
  withWorkspacePrefix?: boolean;
  children: JSX.Element;
  color?: "primary" | "secondary" | "inherit";
  component?: React.ComponentType<any> | undefined;
  to: string | H.LocationDescriptor | ((location: H.Location) => H.Location);
  replace?: boolean | undefined;
  ref?: React.Ref<HTMLAnchorElement> | any; // hack to make types work. dont have time
}

const Link: ForwardRefExoticComponent<ILink> = forwardRef(
  ({ to, withWorkspacePrefix, ...rest }, ref) => {
    const { activeWorkspaceId } = useSelector(
      (state: RootState) => state.api.auth
    );
    const target = withWorkspacePrefix ? `/app/${activeWorkspaceId}${to}` : to;

    return (
      <StyledLink
        {...rest}
        to={target}
        ref={ref as Ref<HTMLAnchorElement> | undefined}
      />
    );
  }
);

// Link.propTypes = {
//   color: PropTypes.oneOf(["primary", "secondary", "inherit"]),
//   to: PropTypes.string.isRequired,
//   withWorkspacePrefix: PropTypes.bool
// };
//
// Link.defaultProps = {
//   withWorkspacePrefix: true
// };

export default Link;
