import { db, firebase } from "../firebase";
import UserSettingGeneric from "./UserSettingGeneric";
import UserSettingGoal from "./UserSettingGoal";
import { Query, QueryBuilder } from "./QueryBuilder";
import Timestamp from "./Timestamp";
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;
type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

export enum SETTING_TYPES {
  GENERIC_KEY_VALUE = "GENERIC_KEY_VALUE",
  PERFORMANCE_GOAL = "PERFORMANCE_GOAL"
}

const SETTING_TYPES_CLASSES = {
  GENERIC_KEY_VALUE: UserSettingGeneric,
  PERFORMANCE_GOAL: UserSettingGoal
};

export default class UserSetting {
  static collectionName = "userSettings";
  static subCollectionName = "settings";

  setData(updates: Partial<keyof typeof SETTING_TYPES_CLASSES>) {
    Object.assign(this, updates);
    return this;
  }

  static converter = {
    toFirestore(setting: UserSettingGeneric | UserSettingGoal) {
      return setting.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as
        | UserSettingGeneric
        | UserSettingGoal;
      if (data.type === SETTING_TYPES.GENERIC_KEY_VALUE) {
        return new UserSettingGeneric({ ...data, id: snapshot.id });
      } else if (data.type === SETTING_TYPES.PERFORMANCE_GOAL) {
        return new UserSettingGoal({ ...data, id: snapshot.id });
      }
      throw new Error("Invalid setting type");
    }
  };

  static map = async (workspace: string, user: string, query?: Query) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(UserSetting.collectionName)
        .doc(user)
        .collection(UserSetting.subCollectionName),
      query
    )
      .withConverter(UserSetting.converter)
      .get();

    const result: { [key: string]: UserSettingGeneric | UserSettingGoal } = {};

    snapshot.docs.forEach((doc: QueryDocumentSnapshot) => {
      result[doc.id] = doc.data() as UserSettingGeneric | UserSettingGoal;
    });

    return result;
  };

  static save = async (
    workspace: string,
    user: string,
    setting: UserSettingGeneric | UserSettingGoal
  ) => {
    const updatedSetting = setting.clone();

    updatedSetting.setData({
      user: user,
      createdAt: Timestamp.now(),
      createdBy: user,
      updatedAt: Timestamp.now(),
      updatedBy: user
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserSetting.collectionName)
      .doc(user)
      .collection(UserSetting.subCollectionName)
      .doc(updatedSetting.id)
      .withConverter(UserSetting.converter)
      .set(updatedSetting, { merge: true });

    return updatedSetting;
  };
}
