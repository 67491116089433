import * as yup from "yup";
import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect, useMemo, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ConfirmationDialog from "../../../../../components/molecules/ConfirmationDialog";
import FormDialog from "../../../../../components/molecules/FormDialog";
import Grid from "../../../../../components/atoms/Grid";

import useGlobalSnackbar from "../../../../../hooks/useGlobalSnackbar";
import { promiseDelay } from "../../../../../helpers";
import useFixProjectsDialog from "./useFixProjectsDialog";
import useUnarchivedProjects from "../../../../../hooks/useUnarchivedProjects";
import Project, { IProject } from "../../../../../models/Project";
import FormLabel from "../../../../../components/atoms/FormLabel";
import FormControl from "../../../../../components/atoms/FormControl";
import Typography from "../../../../../components/atoms/Typography";
import useProjectUpdateMutation from "../../../../../hooks/useProjectUpdateMutation";
import FormControlLabel from "../../../../../components/atoms/FormControlLabel";
import Radio from "../../../../../components/atoms/Radio";
import { withFormController as withFormControllerRadioGroup } from "../../../../../components/atoms/RadioGroup";
import useProjectSalaryRateSettings from "../../../../../hooks/useProjectSalarySettings";
import Divider from "../../../../../components/atoms/Divider";
import Button from "../../../../../components/atoms/Button";

interface IFormInput {
  [key: string]: { salaryType: IProject["salaryType"] };
}

const RadioGroupFromController = withFormControllerRadioGroup<IFormInput>();

export type IFixProjectsForm = {
  handleConfirmClose: () => void;
  id: string;
  projects: Project[];
  updateProject: (updates: Partial<IProject>) => Promise<void>;
};
export default ({
  handleConfirmClose,
  id,
  projects,
  updateProject
}: IFixProjectsForm) => {
  const { t } = useTranslation();
  const { update: updateSnackbar } = useGlobalSnackbar();
  const { projectSalaryRateSettings } = useProjectSalaryRateSettings();

  const globalProvisionOfProjectBillableRate =
    projectSalaryRateSettings?.value?.globalProvisionOfProjectBillableRate;

  const schema = useMemo(() => {
    let shape: any = {};

    return yup.object().shape(shape);
  }, [projects, t]);

  const {
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { dirtyFields }
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    shouldUnregister: true,
    defaultValues: projects.reduce(
      (acc: IFormInput, project: Project) => ({
        ...acc,
        [project.id as string]: { salaryType: project.salaryType }
      }),
      {} as IFormInput
    )
  });
  const values = watch();

  const handleOnSubmit = async (data: IFormInput) => {
    const entries = Object.entries(data).filter(
      ([projectId]) => dirtyFields[projectId]
    );
    const promises: Promise<void>[] = [];

    for (let [id, value] of entries) {
      promises.push(
        updateProject({
          id,
          salaryEnabled: value.salaryType !== "none",
          salaryType: value.salaryType,
          salaryProvisionPercentage:
            value.salaryType === "provision"
              ? globalProvisionOfProjectBillableRate
              : null
        })
      );
    }

    try {
      await Promise.all(promises);
      handleConfirmClose();
      updateSnackbar({
        open: true,
        message: `${t("common.saved")}!   🎉`,
        alert: { severity: "success" }
      });
      await promiseDelay(2000);
      updateSnackbar({
        open: false
      });
    } catch (error) {
      console.log(error);
      updateSnackbar({
        open: true,
        message: `${t("common.error")}! ${error} 🚨 `,
        alert: { severity: "error" }
      });
    }
  };

  //sort projects by name and customer
  const sortedProjects = useMemo(() => {
    return projects.slice().sort((a, b) => {
      if (a.customer === b.customer) {
        return a.name.localeCompare(b.name);
      }
      return a.customer.localeCompare(b.customer);
    });
  }, [projects]);
  const selectAll = () => {
    for (let project of sortedProjects) {
      setValue(`${project.id}.salaryType`, "provision", { shouldDirty: true });
    }
  };

  const selectNone = () => {
    for (let project of sortedProjects) {
      setValue(`${project.id}.salaryType`, "none", { shouldDirty: true });
    }
  };

  const isAllSelected = useMemo(() => {
    return sortedProjects.every(project => {
      if (!project.id) return false;
      return values[project.id].salaryType === "provision";
    });
  }, [sortedProjects, values]);

  return (
    <form id={id} onSubmit={handleSubmit(handleOnSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Button
            variant={isAllSelected ? "outlined" : "contained"}
            color="primary"
            onClick={isAllSelected ? selectNone : selectAll}
          >
            {t(isAllSelected ? "common.selectNone" : "common.selectAll")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {sortedProjects.map((project: Project, index: number) => {
          return (
            <Grid item xs={12} key={project.id}>
              <FormControl component="fieldset" fullWidth variant="outlined">
                <FormLabel component="legend">
                  <Typography variant="h3">{project.name}</Typography>
                </FormLabel>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {t("pages.projects.dialog.projectSalaryVariant")}
                      </FormLabel>
                      <RadioGroupFromController
                        aria-label="salary type"
                        control={control}
                        name={`${project.id}.salaryType`}
                        row
                      >
                        <FormControlLabel
                          value="none"
                          control={<Radio color="primary" />}
                          label={t("common.none")}
                        />
                        {globalProvisionOfProjectBillableRate && (
                          <FormControlLabel
                            value="provision"
                            control={<Radio color="primary" />}
                            label={t(
                              "pages.projects.dialog.globalProvisionOfProjectBillableRate"
                            )}
                          />
                        )}
                      </RadioGroupFromController>
                    </FormControl>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          );
        })}
      </Grid>
    </form>
  );
};
