import createActions from './moduleFactory/actions';
import createConstants from './moduleFactory/constants';
import createRedcuer from './moduleFactory/reducer';
import createSagas from './moduleFactory/sagas';

export default class ModuleFactory {
  constructor(Model, db) {
    this.constants = createConstants(Model);
    this.actions = createActions(this.constants);
    this.sagas = createSagas(Model, this.constants, this.actions, db);
    this.reducer = createRedcuer(this.constants);
  }
}