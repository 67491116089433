import styled from "styled-components";

export const StyledComment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${props => props.theme.spacing(2)}px;
`;

export const AvatarContainer = styled.div`
  margin-right: ${props => props.theme.spacing(1)}px;
`;

export const CommentContainer = styled.div`
  padding: ${props => props.theme.spacing(2)}px;
  color: ${props => props.theme.palette.text.primary};
  background: ${props => props.theme.palette.background.default};
  flex: 1;
  border-radius: ${props => props.theme.shape.borderRadius}px;
`;
