import styled, {css} from 'styled-components';

export const Container = styled.div` 
  position: relative;
  ${({checked}) => checked && css`
> * {
      &:nth-child(2) {
        opacity: 0.1;
      }
    }
`};
  &:hover {
    > * {
      &:nth-child(2) {
        opacity: 0.1;
      }
    }
    
  }
  > * {
    &:first-child {
      top:0;
      position: absolute;
    }   
  }
`;
