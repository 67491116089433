import React, { useMemo } from "react";
import DurationFormat from "../../../../../components/atoms/DurationFormat";
import DistributionBar from "../../../../../components/atoms/DistributionBar";
import Hint from "../../../../../components/atoms/Hint";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  StyledProjectDistribution,
  StyledProjectDistributionStats
} from "./ProjectDistribution.styles";
import Important from "../../../../../components/atoms/Important";
import { getHoursByProject } from "../../../../../utils/ProjectStats";
import useWorkspaceStats from "../hooks/useWorkspaceStats";
import useActiveUsersFromStats from "../../../../../hooks/useActiveUsersFromStats";
import useUniqueUsersInProjects from "../../../../../hooks/useUniqueUsersInProjects";
import { useTranslation } from "react-i18next";

export default () => {
  const {
    statsCurrentMonth: stats,
    isLoading: loading,
    projects,
    projectsMap
  } = useWorkspaceStats();
  const projectsById = Object.fromEntries(projectsMap);
  const { t } = useTranslation();
  const filteredStats = useMemo(() => {
    if (loading || !stats) return [];
    return stats;
  }, [stats, loading]);

  const activeUsersFromStats = useActiveUsersFromStats(stats);
  const uniqueUsersInProjects = useUniqueUsersInProjects(projects);
  const totalUsers = uniqueUsersInProjects.size;
  const activeUsers = activeUsersFromStats.size;

  const projectHours = useMemo(() => {
    if (loading || !filteredStats) return {};
    return getHoursByProject(filteredStats);
  }, [filteredStats, loading]) as { [key: string]: number };

  const distributionDataset = useMemo(() => {
    if (loading || !projectsById) return [];
    return Object.keys(projectHours)
      .map(p => ({
        value: projectHours[p],
        color: projectsById[p].color,
        tooltip: (
          <DurationFormat
            value={projectHours[p]}
            prefix={`${projectsById[p].name}: `}
          />
        )
      }))
      .filter(v => !!v.value);
  }, [loading, projectsById, projectHours]);

  return (
    <StyledProjectDistribution>
      <div>
        <Hint>{t("components.atoms.projectDistribution.header")}</Hint>
        {loading ? (
          <Skeleton height={20} width="100%" />
        ) : (
          <DistributionBar dataset={distributionDataset} />
        )}
      </div>
      <StyledProjectDistributionStats>
        <li>
          <Hint>{t("common.activeProjects")}</Hint>
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              {distributionDataset.length}/{projects.length}
            </Important>
          )}
        </li>
        <li>
          <Hint>{t("common.activeUsers")}</Hint>

          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <Important>
              {activeUsers}/{totalUsers}
            </Important>
          )}
        </li>
      </StyledProjectDistributionStats>
    </StyledProjectDistribution>
  );
};
