import {
  GET_SUCCESS, REMOVE_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
} from "../../../api/timesheet/comments/constants";
import { Reducer } from "redux";
import { CommentsState } from "./types";
import { ITimesheetComment } from "../../../../../models/TimesheetComment";

const initialState = {
  commentsByProject: {},
  commentsSavingByProject: {},
  commentsSavedByProject: {},
};

const reducer: Reducer<CommentsState> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_SUCCESS:
      return {
        ...state,
        commentsByProject: (payload.comments as ITimesheetComment[]).reduce<
          Record<string, ITimesheetComment[]>
        >(
          (prev, next) => ({
            ...prev,
            [next.project]: (prev[next.project] || []).concat(next),
          }),
          {}
        ),
      };

    case SAVE:
      return {
        ...state,
        commentsSavingByProject: {
          ...state.commentsSavingByProject,
          [payload.comment.project]: payload.comment,
        },
      };

    case SAVE_SUCCESS:
      return {
        ...state,
        commentsSavingByProject: {
          ...state.commentsSavingByProject,
          [payload.comment.project]: undefined,
        },
        commentsSavedByProject: {
          ...state.commentsSavedByProject,
          [payload.comment.project]: payload.comment,
        },
        commentsByProject: {
          ...state.commentsByProject,
          [payload.comment.project]: [payload.comment].concat(
            state.commentsByProject[payload.comment.project] || []
          ),
        },
      };

      case REMOVE_SUCCESS:
      return {
        ...state,
        commentsByProject: {
          ...state.commentsByProject,
          [payload.comment.project]: state.commentsByProject[payload.comment.project].filter(c => c.id !== payload.comment.id),
        },
      };

    default:
      return state;
  }
};

export default reducer;
