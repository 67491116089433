import CardContent from "../../../atoms/CardContent";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Typography from "../../../atoms/Typography";
import { InputAdornment, IconButton } from "../../../atoms";
import Close from "@material-ui/icons/Close";
import {
  Button,
  Card,
  Link,
  CircularProgress,
  NumberFormatIntl,
  TextField,
} from "../../../atoms";
import Divider from "../../../atoms/Divider";
import { CardElement } from "@stripe/react-stripe-js";
import { options as cardElementOpts } from "./cardElement";
import React, { useEffect, useRef } from "react";
import { actionTypes } from "./reducer";
import { watchSignOut } from "@superprofit/core-redux-saga-modules/Auth/actions";

export const ButtonContainer = styled.div`
  position: relative;
  width: fit-content;

  > div {
    &:last-child {
      position: absolute;
      left: 50%;
      top: 0;
      height: 24px;
      margin-top: 8px;
      margin-left: -12px;
    }
  }
`;

const getCoupon = async ({ baseUrl, code }) => {
  const response = await fetch(`${baseUrl}/getCoupon?code=${code}`, {
    method: "get",
  });

  if (!response.ok) {
    return false;
  }
  return response.json();
};

const CouponCard = ({ state, dispatch }) => {
  const couponRef = useRef(null);

  const {
    products,
    subscriptionInformation,
    createdSubscriptionProductId,
    createdSubscriptionSeats,
    upcomingInvoice,
    isLoadingUpcomingInvoice,
    isCreatingSubscription,
    baseUrl,
    customerId,
    firebaseIdToken,
    createdSubscriptionStatus,
    billingInformationInputErrors,
    isLoadingCoupon,
    coupon,
  } = state;

  const isDisabled = isLoadingCoupon;
  const buttonColor = coupon?.valid ? "success" : "primary";
  const buttonText = coupon?.valid ? "Applied" : "Apply";
  const handleRemove = () => {
    dispatch({ type: actionTypes.setCoupon, payload: { coupon: undefined } });
  };
  const handleClick = async () => {
    dispatch({ type: actionTypes.toggleLoadingCoupon });

    try {
      const coupon = await getCoupon({
        baseUrl,
        code: couponRef?.current?.value,
      });
      console.log("CO", coupon);
      dispatch({ type: actionTypes.setCoupon, payload: { coupon } });
    } catch (e) {
      console.error(e.toString());
    }

    dispatch({ type: actionTypes.toggleLoadingCoupon });
  };

  useEffect(
    () => () => {
      dispatch({ type: actionTypes.setCoupon, payload: { coupon: {} } });
    },
    [dispatch]
  );

  if (coupon?.valid) {
    return (
      <Card>
        <CardContent>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              {" "}
              You have applied the <b>{coupon.id}</b> promotion code.
            </Typography>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                {" "}
                This code grants{" "}
                {coupon.percent_off ? (
                  `${coupon.percent_off}%`
                ) : (
                  <NumberFormatIntl
                    locales="en"
                    number={coupon.amount_off / 100}
                    options={{
                      style: "currency",
                      currency: coupon.currency?.toUpperCase(),
                    }}
                  />
                )}{" "}
                off.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {coupon.duration === "once" && (
                <Typography variant="body2" color="textSecondary">
                  It is only valid once
                </Typography>
              )}
              {coupon.duration === "repeating" && (
                <Typography variant="body2" color="textSecondary">
                  It will be applied for the next {coupon.duration_in_months}{" "}
                  month(s)
                </Typography>
              )}
              {coupon.duration === "forever" && (
                <Typography variant="body2" color="textSecondary">
                  It will be applied for as long as the subscription runs.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                If you dont wish to use this coupon,{" "}
                <Link component="button" style={{verticalAlign: 'inherit'}} onClick={handleRemove}>
                  remove it.
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <TextField
              inputProps={{
                ref: couponRef,
              }}
              error={coupon && !coupon.valid}
              variant="filled"
              label="Promotion code"
              helperText="You can only use one promo code."
            />
          </Grid>
          <Grid item>
            <ButtonContainer>
              <Button
                disabled={isDisabled}
                variant="contained"
                onClick={handleClick}
                color={buttonColor}
              >
                {buttonText}
              </Button>
              {isLoadingCoupon && <CircularProgress size={24} />}
            </ButtonContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CouponCard;
