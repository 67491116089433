import User from "../models/User";
import useUsers from "./useUsers";
import { useMemo } from "react";

const useEmployeeNumbers = () => {
  const { data: users = [] } = useUsers();
  const employeeNumbersMap = useMemo(() => {
    const m = new Map<number, User>();
    for (const user of users) {
      if (user.metadata?.employeeNumber) {
        m.set(user.metadata.employeeNumber, user);
      }
    }
    return m;
  }, [users]);
  return { employeeNumbersMap };
};

export default useEmployeeNumbers;
