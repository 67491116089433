import React, { Fragment } from "react";
import CardHeader from "@superprofit/core-react-components/atoms/CardHeader";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import Card from "@superprofit/core-react-components/atoms/Card";
import CardContent from "@superprofit/core-react-components/atoms/CardContent";
import { StyledEntries } from "./Timesheet.styles";
import { LeaveComment } from "../leads/leadList/LeadList.styles";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

const TimesheetLoader = props => {
  const days = [...Array(8).keys()];

  return (
    <Card {...props}>
      <CardHeader
        title={<Skeleton variant="text" width={250} height={20} />}
        avatar={<Skeleton variant="circle" width={40} height={40} />}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={0} style={{ marginTop: 20 }} component="div">
          <Grid item xs={12} md={6} component="div">
            <StyledEntries>
              {days.slice(0, 4).map(d => (
                <Skeleton
                  key={d}
                  component="div"
                  variant="rect"
                  width="100%"
                  height={48}
                />
              ))}
            </StyledEntries>
          </Grid>

          <Grid item xs={12} md={6} component="div">
            <StyledEntries>
              {days.slice(4).map(d => (
                <Skeleton
                  key={d}
                  component="div"
                  variant="rect"
                  width="100%"
                  height={48}
                />
              ))}
            </StyledEntries>
          </Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <Grid container spacing={2} component="div" alignItems="flex-end">
          <Grid component="div" item xs={12}>
            <LeaveComment>
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton
                component="div"
                variant="rect"
                width="100%"
                height={48}
              />
            </LeaveComment>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default () => (
  <Grid container spacing={3} style={{ marginTop: 20 }} component="div">
    <Grid key={1} item xs={12} component="div" style={{ maxWidth: 1680 }}>
      <TimesheetLoader />
    </Grid>

    <Grid key={2} item xs={12} component="div" style={{ maxWidth: 1680 }}>
      <TimesheetLoader />
    </Grid>

    <Grid key={3} item xs={12} component="div" style={{ maxWidth: 1680 }}>
      <TimesheetLoader />
    </Grid>
  </Grid>
);
