import styled from "@react-pdf/styled-components";
import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "@superprofit/core-react-components/themes/light/colors";

export const Line = styled.View`
  height: 2px;
  background-color: ${colors.primary};
`;

export const Header = styled.Text`
  margin-top: 10px;
  color: ${colors.primary};
  font-weight: bold;
  font-size: 12px;
`;

export const Container = styled.View`
  ${({gutterBottom}) => gutterBottom && 'margin-bottom: 16px'};

`;

export const Subtitle = styled.Text`
  margin-top: 10px;
  ${({align = 'left'}) => align === 'right' &&   'margin-left: auto'};
  color: ${colors.fontLight};
  font-size: 10px;
`;

export const Timet = styled.Text`
  align-self: flex-end;
  margin-top: 2px;
  color: ${colors.secondary};
  font-weight: bold;
  font-size: 12px;
  opacity: 0.5;
`;
