import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../index";
import { set } from "../../../../redux/modules/ui/timesheet/period/actions";

const usePeriod = () => {
  const period = useSelector((state: RootState) => state.ui.timesheet.period);
  const dispatch = useDispatch();
  const update = (updates: { year?: number; week?: number }) =>
    dispatch(set({ ...period, ...updates }));
  return { ...period, update };
};

export default usePeriod;
