export const mapRatesWithProjects = (stats, projects) => {
  const projectsById = projects.reduce((prev, next) => ({
    ...prev,
    [next.id]: next
  }), {});

  return stats.reduce((prev, next) => {
    const project = projectsById[next.project];
    return [
      ...prev,
      {
        ...next,
        billableRate: project && project.getBillableRate(next.user)
      }
    ];
  }, []);
};
