import { combineReducers } from "redux";

import entries from "./entries/reducer";
import comments from "./comments/reducer";
import snackbar from "./snackbar/reducer";
import projects from "./projects/reducer";
import period from "./period/reducer";
import filter from "./filter/reducer";

export default combineReducers({
  entries,
  comments,
  snackbar,
  projects,
  period,
  filter
});
