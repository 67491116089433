import onSubscriptionComplete from "./onSubscriptionComplete";
import handlePaymentThatRequiresCustomerAction from "./handlePaymentThatRequiresCustomerAction";
import handleRequiresPaymentMethod from "./handleRequiresPaymentMethod";
import {actionTypes} from '../../reducer';


// CHECK OUT THIS GUIDE : https://stripe.com/docs/billing/subscriptions/fixed-price
const createCall = async ({ apiBase, customerId, paymentMethodId, priceId, quantity, firebaseIdToken, coupon, sendInvoice, contactName, contactEmail}) =>{
  const response = await fetch(`${apiBase}/createSubscription`, {
    method: "post",
    headers: {
      "Content-type": "application/json",
      'Authorization': firebaseIdToken
    },
    body: JSON.stringify({
      customerId,
      paymentMethodId,
      priceId,
      quantity,
      coupon,
      sendInvoice,
      contactName,
      contactEmail
    })
  });
  if (!response.ok) {
    return { error: `[create-subscription] Status ${response.status} - ${response.statusText}`}
  }
  return response.json();
};



const createSubscription = async (args) => {
  const { dispatch, paymentMethodId, priceId } = args;
  let response = await createCall(args);
  if (response.error) {
    dispatch({
      type: actionTypes.setCreatedSubscriptionStatus,
      payload: {
        message: typeof response.error === 'string' ? response.error : response.error.toString(),
        status: "error",
      },
    });
  }
  const normalizedResponse = {
    paymentMethodId,
    priceId,
    subscription: response,
  };
  const customerActionResponse = await handlePaymentThatRequiresCustomerAction({
    ...args,
    ...normalizedResponse,
  });
  console.log('[Customer action response]', customerActionResponse);
  if (customerActionResponse) {
    const result = handleRequiresPaymentMethod(customerActionResponse);
    console.log('[result]', result);
    onSubscriptionComplete({...args, result});
  }
};

export default createSubscription
