export const GET = "api/leads/GET";
export const GET_SUCCESS = "api/leads/GET_SUCCESS";
export const GET_FAILURE = "api/leads/GET_FAILED";

export const WATCH_GET = "api/leads/WATCH_GET";

export const SAVE = "api/leads/SAVE";
export const SAVE_SUCCESS = "api/leads/SAVE_SUCCESS";
export const SAVE_FAILURE = "api/leads/SAVE_FAILURE";

export const WATCH_SAVE = "api/leads/WATCH_SAVE";

export const SAVE_COMMENT = "api/leads/SAVE_COMMENT";
export const SAVE_COMMENT_SUCCESS = "api/leads/SAVE_COMMENT_SUCCESS";
export const SAVE_COMMENT_FAILURE = "api/leads/SAVE_COMMENT_FAILURE";

export const WATCH_SAVE_COMMENT = "api/leads/WATCH_SAVE_COMMENT";
