import styled from "styled-components";
import Card from "@superprofit/core-react-components/atoms/Card";

export const BudgetCard = styled(Card)`
  position: relative;
  pointer-events: all;
  width: 100%;
`;

export const StyledBudget = styled.div`
  padding: ${props => props.theme.spacing(2)}px;
`;

export const StyledBudgetStats = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  text-indent: 0;
  margin-top: ${props => props.theme.spacing(4)}px;
  justify-content: space-between;
  
  > * {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style: none;
    // margin-right: ${props => props.theme.spacing(4)}px;
  }
`;
