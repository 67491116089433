import useUserProjects from "./useUserProjects";
import useProjectsMap from "./useProjectsMap";

type Options = {
  userId?: string;
};
const useUserProjectsMap = (opts: Options = {}) => {
  const proj = useUserProjects(null, opts.userId);
  return useProjectsMap(proj);
};

export default useUserProjectsMap;
