import { GET, GET_FAILURE, GET_SUCCESS } from "./constants";

const initialState = {
  list: [],
  loading: false
};

export default (state = initialState, { type, payload = {} }) => {
  const { list, error } = payload;
  switch (type) {
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        list
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
};
