import { useMemo } from "react";
import UserGroup from "../models/UserGroup";
import useUserGroups from "./useUserGroups";

const useUserGroupsMap = (groups?: UserGroup[]) => {
  const { data: allGroups } = useUserGroups();
  return useMemo(() => {
    const m = new Map();
    (groups || allGroups || []).forEach((u: UserGroup) => {
      m.set(u.id, u);
    });
    return m;
  }, [groups, allGroups]);
};

export default useUserGroupsMap;
