export {default as ModuleFactory} from './ModuleFactory';

export const getCurrentWorkspace = pathname => {
  const path = pathname || window.location.pathname;
  const [empty, app, workspaceId] = path.split('/');
  if (app === 'app' && workspaceId) {
    return workspaceId;
  }
  return '';
  // We dont support subdomains under ssl right now.
  //   const { subdomain } = parseDomain(window.location.href, {
  //     customTlds: /localhost|\.local/
  //   });
  //   return subdomain;
};