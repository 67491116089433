import { combineReducers } from "redux";
// import userSettings from "./userSettings/reducer";
import projects from "./projects/reducer";
import customers from "./customers/reducer";
import customerStats from "./customerStats/reducer";
import customerOverview from "./customerOverview/reducer";
import leads from "./leads/reducer";
import companyStats from "./companyStats/reducer";
import projectStats from "./projectStats/reducer";
import projectOverview from "./projectOverview/reducer";
import userStats from "./userStats/reducer";
import companyDashboard from "./companyDashboard/reducer";
import companySettings from "./companySettings/reducer";
import userSettings from "./userSettings/reducer";
import timesheet from "./timesheet/reducer";
import { reducer as stripeAccountDetails } from "./stripeAccountDetails";
import { reducer as applicationAccess } from "./applicationAccess";
import invoices from "./invoices/reducer";
import xstats from "./xstats/reducer";
// import { reducer as auth } from "@superprofit/core-redux-saga-modules/Auth";
import auth from "./auth/reducer";
import { reducer as users } from "@superprofit/core-redux-saga-modules/Users";
import { reducer as userRole } from "@superprofit/core-redux-saga-modules/UserRole";
import { reducer as userAccess } from "@superprofit/core-redux-saga-modules/UserAccess";
import userProfile from "./userProfile/reducer";
import userGroups from "./userGroups/reducer";
import currentUser from "./currentUser/reducer";

// Reports
import report from "./report/reducer";

export default combineReducers({
  auth,
  applicationAccess,
  currentUser,
  userProfile,
  companyDashboard,
  userStats,
  companyStats,
  companySettings,
  customers,
  customerStats,
  customerOverview,
  invoices,
  leads,
  projects,
  projectStats,
  projectOverview,
  timesheet,
  stripeAccountDetails,
  users,
  userRole,
  userGroups,
  userAccess,
  userSettings,
  xstats,
  report
});
