import React, { useEffect, useMemo } from "react";
import Page from "../../components/atoms/Page";
import Grid from "../../components/atoms/Grid";
import Button from "../../components/atoms/Button";
import Card from "../../components/atoms/Card";
import CardHeader from "../../components/atoms/CardHeader";
import Table, {
  TableContainer,
  TableHead,
  TableBody
} from "../../components/atoms/Table";
import { useDispatch, useSelector } from "react-redux";
import PeriodSelect from "../../components/molecules/PeriodSelect";
import { setPeriod } from "../../redux/modules/ui/userOverview/period/actions";
import { useTranslation } from "react-i18next";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { RootState } from "@superprofit/timet-react-client/src";
import Divider from "../../components/atoms/Divider";
import { TableCell } from "../../components/atoms/Table";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import useUnarchivedProjects from "@superprofit/timet-react-client/src/hooks/useUnarchivedProjects";
import useUserProjects, {
  isUserInProject
} from "@superprofit/timet-react-client/src/hooks/useUserProjects";
import { useEntriesWithDateRange } from "@superprofit/timet-react-client/src/hooks/useEntries";
import useUser from "@superprofit/timet-react-client/src/hooks/useUser";
import { useApprovalRecords } from "@superprofit/timet-react-client/src/hooks/useApprovalRecords";
import { ProjectItem } from "./approvals/ProjectItem";

import Project from "@superprofit/timet-react-client/src/models/Project";
import { useApprovalRecordsCreateMutation } from "@superprofit/timet-react-client/src/hooks/useApprovalRecordsCreateMutation";
import useWorkspace from "@superprofit/timet-react-client/src/hooks/useWorkspace";
import useGlobalSnackbar from "@superprofit/timet-react-client/src/hooks/useGlobalSnackbar";
import { promiseDelay } from "@superprofit/timet-react-client/src/helpers";
import { CardActions } from "@material-ui/core";
import TableLoader from "../../components/molecules/TableLoader";
import useAllProjectsEntries from "@superprofit/timet-react-client/src/hooks/useAllProjectsEntries";
import Entry from "@superprofit/timet-react-client/src/models/Entry";
import { ApprovalRecord } from "@superprofit/timet-react-client/src/models/ApprovalRecords";
import { useApprovalRecordsUpdateMutation } from "@superprofit/timet-react-client/src/hooks/useApprovalRecordsUpdateMutation";
import useCustomersMap from "@superprofit/timet-react-client/src/hooks/useCustomersMap";
import Badge from "@superprofit/core-react-components/atoms/Badge";
import FilterIcon from "@material-ui/icons/FilterList";
import { useFilterDialog } from "@superprofit/timet-react-client/src/components/organisms/FilterDialog";
import useUserGroups from "@superprofit/timet-react-client/src/hooks/useUserGroups";

type OrderBy = "name" | "hours" | "status" | "customer";

export const Approvals = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = React.useState<string>("hours");
  const [orderDir, setOrderDir] = React.useState<"asc" | "desc">("desc");

  const [usersSubmitting, setUsersSubmitting] = React.useState<string[]>([]);
  const { i18n } = useTranslation();

  const period = useSelector(
    (state: RootState) => state.ui.userOverview.period
  );
  const { mutateAsync: create, isLoading } = useApprovalRecordsCreateMutation();
  const { mutateAsync: updateAsync } = useApprovalRecordsUpdateMutation();
  const workspace = useWorkspace();
  const { update: updateSnackbar } = useGlobalSnackbar();
  const currentMonthStart = startOfMonth(
    new Date(period.year, period.month - 1)
  );
  const {
    state: { hasFilters, filters },
    setIsOpen
  } = useFilterDialog();
  const { data: allUserGroups = [] } = useUserGroups();
  const { email } = useUser();
  const currentMonthEnd = endOfMonth(currentMonthStart);
  const { data: approvalRecords = [] } = useApprovalRecords({
    fromDate: currentMonthStart,
    toDate: currentMonthEnd,
    statuses: ["approved", "submitted"]
  });

  const handleOnFilter = () => {
    setIsOpen(true);
  };

  const customersMap = useCustomersMap();
  const {
    data: allProjects,
    isLoading: projectsLoading
  } = useUnarchivedProjects();

  const { data: userProjects = [] } = useUnarchivedProjects();

  const {
    data: allProjectsEntries,
    isLoading: entriesLoading
  } = useAllProjectsEntries({
    fromDate: currentMonthStart,
    toDate: currentMonthEnd
  });

  const createOnBehalfOfUser = async (projectId: string, userId: string) => {
    updateSnackbar({
      open: true,
      message: `${t("common.submitting")}...`,
      alert: { severity: "info" }
    });
    const result = await create(
      {
        workspaceId: workspace,
        userId: userId,
        fromDate: format(currentMonthStart, "yyyy-MM-dd"),
        toDate: format(currentMonthEnd, "yyyy-MM-dd"),
        projects: [projectId],
        language: i18n.language
      },
      {
        onSuccess: async () => {
          updateSnackbar({
            open: true,
            message: `${t("common.submitted")}!   🎉`,
            alert: { severity: "success" }
          });
          await promiseDelay(2000);
          updateSnackbar({
            open: false
          });
        },
        onError: async e => {
          updateSnackbar({
            open: true,
            message: `${t("common.error")}! ${e} 🚨 `,
            alert: { severity: "error" }
          });
          await promiseDelay(2000);
          updateSnackbar({
            open: false
          });
        }
      }
    ).catch(console.error);
  };
  const update = async (
    approvalRecord: ApprovalRecord,
    status: ApprovalRecord["status"]
  ) => {
    const message =
      status === "approved" ? t("common.approving") : t("common.rejecting");

    updateSnackbar({
      open: true,
      message: `${message}...`,
      alert: { severity: "info" }
    });
    await updateAsync(
      {
        workspaceId: workspace,
        data: [
          {
            id: approvalRecord.id,
            status
          }
        ],
        language: i18n.language
      },
      {
        onSuccess: async () => {
          updateSnackbar({
            open: true,
            message: `${
              status === "approved"
                ? t("common.approved")
                : t("common.rejected")
            }!   🎉`,
            alert: { severity: "success" }
          });
          await promiseDelay(2000);
          updateSnackbar({
            open: false
          });
        },
        onError: async e => {
          updateSnackbar({
            open: true,
            message: `${t("common.error")}! ${e} 🚨 `,
            alert: { severity: "error" }
          });
          await promiseDelay(2000);
          updateSnackbar({
            open: false
          });
        }
      }
    ).catch(console.error);
  };

  const handleOnApprove = async (approvalRecord: ApprovalRecord) => {
    setUsersSubmitting([...usersSubmitting, approvalRecord.user]);
    await update(approvalRecord, "approved");
    setUsersSubmitting(usersSubmitting.filter(p => p !== approvalRecord.user));
  };
  const handleOnCreate = async (project: Project, user: string) => {
    setUsersSubmitting([...usersSubmitting, user]);
    await createOnBehalfOfUser(project.id as string, user);
    setUsersSubmitting(usersSubmitting.filter(p => p !== user));
  };

  const handleOnReject = async (approvalRecord: ApprovalRecord) => {
    await update(approvalRecord, "rejected");
  };

  const handleOnChangePeriod = ({
    year,
    month
  }: {
    year: number;
    month: number;
  }) => {
    dispatch(setPeriod(year, month));
  };

  const handleOnSort = (e: React.MouseEvent, field: OrderBy) => {
    if (orderBy === field) {
      setOrderDir(orderDir === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrderDir("asc");
    }
  };

  const apprvalRecordsByProject = useMemo(() => {
    const map = new Map<string, ApprovalRecord[]>();
    approvalRecords.forEach(e => {
      const current = map.get(e.project) || [];
      map.set(e.project, [...current, e]);
    });
    return map;
  }, [approvalRecords]);

  const entriesByProjectMap = useMemo(() => {
    const map = new Map<string, Entry[]>();
    allProjectsEntries.forEach(e => {
      const current = map.get(e.project) || [];
      map.set(e.project, [...current, e]);
    });

    return map;
  }, [allProjectsEntries]);

  const totalHoursByProjectMap = useMemo(() => {
    const map = new Map<string, number>();
    entriesByProjectMap.forEach((entries, projectId) => {
      const total = entries.reduce((acc, e) => acc + e.hours, 0);
      map.set(projectId, total);
    });
    return map;
  }, [entriesByProjectMap]);

  const filteredProjects = useMemo(() => {
    let temp = userProjects;
    if (hasFilters) {
      if (filters.customers.length > 0) {
        temp = temp.filter(p => filters.customers.indexOf(p.customer) > -1);
      }
      if (filters.projects.length > 0) {
        temp = temp.filter(p => filters.projects.indexOf(p.id as string) > -1);
      }
      if (filters.users.length > 0) {
        temp = temp.filter(p =>
          Project.isUsersInProject(filters.users, p, allUserGroups)
        );
      }
    }
    return temp;
  }, [userProjects, filters]);

  const sortedProjects = useMemo(() => {
    const sorting = filteredProjects.slice();
    sorting.sort((a, b) => {
      if (orderBy === "name") {
        return a.name.localeCompare(b.name) * (orderDir === "asc" ? 1 : -1);
      }
      if (orderBy === "hours") {
        const aHours = totalHoursByProjectMap.get(a.id) || 0;
        const bHours = totalHoursByProjectMap.get(b.id) || 0;
        return (aHours - bHours) * (orderDir === "asc" ? 1 : -1);
      }

      if (orderBy === "customer") {
        const aCustomer = customersMap.get(a.customer || "")?.name || "";
        const bCustomer = customersMap.get(b.customer || "")?.name || "";
        return (
          aCustomer.localeCompare(bCustomer) * (orderDir === "asc" ? 1 : -1)
        );
      }
      return 0;
    });
    return sorting;
  }, [
    filteredProjects,
    orderBy,
    orderDir,
    totalHoursByProjectMap,
    customersMap
  ]);

  const loading = projectsLoading;

  return (
    <Page context={email} title={t("pages.approvals.title")}>
      <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={6} component="div">
          <PeriodSelect
            period={period}
            showNavigation
            onChange={handleOnChangePeriod}
            showWeek={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} component="div" justify="flex-end">
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Badge color="error" variant="dot" invisible={!hasFilters}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleOnFilter}
                  disabled={isLoading}
                  startIcon={<FilterIcon />}
                >
                  {t("common.filter")}
                </Button>
              </Badge>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 20 }} component="div">
        <Grid item xs={12} component="div">
          <Card>
            <CardHeader
              title={t("pages.approvals.tableHeader")}
              action={
                <CardActions>
                  {/*<Button*/}
                  {/*  color="primary"*/}
                  {/*  variant="contained"*/}
                  {/*  // onClick={submitAll}*/}
                  {/*  disabled={*/}
                  {/*    projectsSubmitting.length > 0 ||*/}
                  {/*    loading ||*/}
                  {/*    approvalRecords.length > 0*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  {t("common.submitAll")}*/}
                  {/*</Button>*/}
                </CardActions>
              }
            />

            <Divider />

            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell style={{ maxWidth: 80, width: 80 }} />
                  <TableCell style={{ maxWidth: 80, width: 80 }} />
                  <TableCell
                    sortDirection={orderBy === "name" ? orderDir : false}
                  >
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={orderBy === "name" ? orderDir : "asc"}
                      onClick={e => handleOnSort(e, "name")}
                    >
                      {t("common.name")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={orderBy === "customer" ? orderDir : false}
                  >
                    <TableSortLabel
                      active={orderBy === "customer"}
                      direction={orderBy === "customer" ? orderDir : "asc"}
                      onClick={e => handleOnSort(e, "customer")}
                    >
                      {t("common.customer")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>{t("common.status")}</TableSortLabel>
                  </TableCell>
                  <TableCell>{t("common.rate")}</TableCell>
                  <TableCell>{t("common.revenue")}</TableCell>
                  <TableCell
                    align="right"
                    sortDirection={orderBy === "hours" ? orderDir : false}
                  >
                    <TableSortLabel
                      active={orderBy === "hours"}
                      direction={orderBy === "hours" ? orderDir : "asc"}
                      onClick={e => handleOnSort(e, "hours")}
                    >
                      {t("common.hours")}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="right" />
                  <TableCell align="right" />
                </TableHead>
                <TableBody>
                  {sortedProjects.map((project: Project) => (
                    <ProjectItem
                      usersSubmitting={usersSubmitting}
                      project={project}
                      entries={
                        entriesByProjectMap.get(project.id as string) || []
                      }
                      approvalRecords={
                        apprvalRecordsByProject.get(project.id as string) || []
                      }
                      totalHours={
                        totalHoursByProjectMap.get(project.id as string) || 0
                      }
                      startDate={currentMonthStart}
                      endDate={currentMonthEnd}
                      onReject={handleOnReject}
                      onApprove={handleOnApprove}
                      onCreate={handleOnCreate}
                    />
                  ))}
                </TableBody>
                {loading && (
                  <TableLoader
                    cols={[
                      "rect",
                      "square",
                      "text",
                      "text",
                      "text",
                      "textRight",
                      "textRight"
                    ]}
                  />
                )}
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};
