import { takeLatest, put, take, all } from "redux-saga/effects";

// constants
import { WATCH_GET } from "./constants";
import { GET_SUCCESS as GET_USERS_SUCCESS } from "@superprofit/core-redux-saga-modules/Users/constants";
import { GET_SUCCESS as GET_CUSTOMERS_SUCCESS } from "../customers/constants";
import { GET_SUCCESS as GET_PROJECTS_SUCCESS } from "../projects/constants";
import { GET_SUCCESS as GET_STATS_SUCCESS } from "../companyStats/constants";
import { GET_SUCCESS as GET_COMPANY_SETTINGS_SUCCESS } from "../companySettings/constants";
// actions
import { get, getFailure, getSuccess } from "./actions";
import { watchGetUsers } from "@superprofit/core-redux-saga-modules/Users/actions";
import { watchGetProjects } from "../projects/actions";
import { watchGetCustomers } from "../customers/actions";
import { watchGetCompanyStats } from "../companyStats/actions";
import { watchGetCompanySettings } from "../companySettings/actions";
import TimetUser from "../../../../models/TimetUser";

export const getDashboard = function* getDashboard(args) {
  const { year, month } = args.payload;
  try {
    yield put(get());
    yield put(watchGetUsers(TimetUser));
    yield put(watchGetCompanySettings());
    yield put(watchGetProjects());
    yield put(watchGetCustomers());
    yield put(watchGetCompanyStats(year, month));

    const [
      {
        payload: { list: users }
      },
      {
        payload: { list: customers }
      },
      {
        payload: { list: projects }
      },
      { payload: stats },
      {
        payload: { settings }
      }
    ] = yield all([
      take(GET_USERS_SUCCESS),
      take(GET_CUSTOMERS_SUCCESS),
      take(GET_PROJECTS_SUCCESS),
      take(GET_STATS_SUCCESS),
      take(GET_COMPANY_SETTINGS_SUCCESS)
    ]);

    const projectsById = projects.reduce(
      (prev, next) => ({
        ...prev,
        [next.id]: next
      }),
      {}
    );

    yield put(
      getSuccess({
        users,
        customers,
        projects,
        projectsById,
        stats,
        settings
      })
    );
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const getCompanyDashboardSaga = function* getCompanyDashboardSaga() {
  yield takeLatest(WATCH_GET, getDashboard);
};

export default function*() {
  yield all([getCompanyDashboardSaga()]);
}
