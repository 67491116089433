import {
  SIGNED_IN,
  SIGNED_OUT,
  SET_ID_TOKEN,
  SET_ACTIVE_WORKSPACE,
  SET_WORKSPACE,
  WATCH_AUTH_ON_ID_TOKEN_CHANGED,
  WATCH_AUTH_STATE_CHANGED,
  WATCH_GET_ID_TOKEN,
  WATCH_AUTH_SIGN_OUT,
  WORKSPACE_NO_ACCESS,
  WORKSPACE_JOINED,
  WORKSPACE_JOINING
} from "./constants";

export const signedIn = user => ({
  type: SIGNED_IN,
  payload: { user }
});

export const setActiveWorkspaceId = activeWorkspaceId => ({
  type: SET_ACTIVE_WORKSPACE,
  payload: { activeWorkspaceId }
});

export const setWorkspace = workspace => ({
  type: SET_WORKSPACE,
  payload: { workspace }
});

export const workspaceJoined = workspace => ({
  type: WORKSPACE_JOINED,
  payload: { workspace }
});

export const workspaceJoining = workspace => ({
  type: WORKSPACE_JOINING,
  payload: { workspace }
});

export const workspaceNoAccess = ({workspace}) => ({
  type: WORKSPACE_NO_ACCESS,
  payload: {
    workspace
  }
});

export const signedOut = () => ({
  type: SIGNED_OUT
});

export const setIdTokenResult = idTokenResult => ({
  type: SET_ID_TOKEN,
  payload: {
    idTokenResult
  }
});

export const watchAuthStateChanged = user => ({
  type: WATCH_AUTH_STATE_CHANGED,
  payload: { user }
});

export const watchGetIdToken = forceRefresh => ({
  type: WATCH_GET_ID_TOKEN,
  payload: { forceRefresh }
});

export const watchOnIdTokenChanged = token => ({
  type: WATCH_AUTH_ON_ID_TOKEN_CHANGED,
  payload: { token }
});

export const watchSignOut = () => ({
  type: WATCH_AUTH_SIGN_OUT
});
