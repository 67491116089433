import { all, delay, put, takeLatest } from "@redux-saga/core/effects";
import { SAVE, SAVE_SUCCESS } from "../../../api/projects/constants";

import { updateSnackbar } from "./actions";

export const handleProjectSaving = function*(args) {
  try {
    yield put(updateSnackbar({ visible: true, text: "Saving project" }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleProjectSaved = function*(args) {
  const { isNew } = args.payload;
  try {
    if (isNew) {
      yield put(updateSnackbar({ visible: true, text: "Project saved." }));
    } else {
      yield put(updateSnackbar({ visible: true, text: "Project updated." }));
    }
    yield delay(2000);
    yield put(updateSnackbar({ visible: false }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleProjectSavingSaga = function*() {
  yield takeLatest(SAVE, handleProjectSaving);
};

export const handleProjectSavedSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleProjectSaved);
};

export default function*() {
  yield all([handleProjectSavingSaga(), handleProjectSavedSaga()]);
}
