import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  REMOVE,
  REMOVE_FAILURE,
  REMOVE_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
} from "./constants";
import { UserGroupsAction, UserGroupsState } from "./types";
import {createReducer} from "typesafe-actions";

const initialState: UserGroupsState = {
  list: [],
  loading: false,
  userGroupSaving: null,
  userGroupDeleting: null
};


const reducer = createReducer<UserGroupsState, UserGroupsAction>(initialState)
    .handleType(GET, (state) => ({...state, loading: true}))
    .handleType(GET_SUCCESS, (state, action) => ({...state, loading: false, list: action.payload.userGroups}))
    .handleType(GET_FAILURE, (state, action) => ({...state, loading: false, error: action.payload.error}))
    .handleType(SAVE, (state) => ({...state, userGroupSaving: true}))
    .handleType(SAVE_SUCCESS, (state, action) => ({...state, userGroupSaving: false, list: [action.payload.userGroup].concat(state.list)}))
    .handleType(SAVE_FAILURE, (state, action) => ({...state, userGroupSaving: false, error: action.payload.error }))
    .handleType(REMOVE, (state) => ({...state, userGroupDeleting: true, }))
    .handleType(REMOVE_SUCCESS, (state, action) => ({...state, userGroupDeleting: false, list: state.list.filter((ug) => ug.id !== action.payload.userGroup.id)}))
    .handleType(REMOVE_FAILURE, (state, action) => ({...state, userGroupDeleting: false, error: action.payload.error}))

export default reducer;
