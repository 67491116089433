import { useSelector } from "react-redux";
import { RootState } from "../index";

const useWorkspaceDisplayName = () => {
  const displayName = useSelector(
    ({ api: { auth } }: RootState) => auth.workspace?.displayName || ""
  );
  return displayName;
};

export default useWorkspaceDisplayName;
