import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../index";
import { Options } from "../redux/modules/ui/app/snackbar/types";
import { useEffect } from "react";
import { SET } from "../redux/modules/ui/app/snackbar/constants";
import {
  set as setSnackbar,
  update as updateSnackbar
} from "../redux/modules/ui/app/snackbar/actions";

const useGlobalSnackbar = () => {
  const snackbar = useSelector(
    ({
      ui: {
        app: { snackbar }
      }
    }: RootState) => snackbar
  );
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (snackbar.options !== null) dispatch(setSnackbar(null));
  // }, [dispatch]);

  const set = (options: Options) => dispatch(setSnackbar(options));
  const update = (options: Options) => dispatch(updateSnackbar(options));
  return { snackbar, set, update };
};

export default useGlobalSnackbar;
