import RG, { RadioGroupProps } from "@material-ui/core/RadioGroup";
import React, { forwardRef, ForwardRefExoticComponent } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";

const RadioGroup: ForwardRefExoticComponent<RadioGroupProps> = forwardRef(
  (props, ref) => <RG {...props} ref={ref} />
);

interface RadioGroupFormControllerProps<T> extends RadioGroupProps {
  control: Control<T>;
}

export function withFormController<FieldValues>() {
  return (props: RadioGroupFormControllerProps<FieldValues>) => {
    const { name, control, defaultValue, ...rest } = props;
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <RadioGroup
              {...field}
              onChange={e => {
                field.onChange(e.target.value);
              }}
              {...rest}
            />
          );
        }}
      />
    );
  };
}
export default RadioGroup;
