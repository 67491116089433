import { ModuleFactory } from "@superprofit/core-redux-saga-modules/Helpers";
import { Workspace } from "@superprofit/core-firestore-models";
import { db } from "../../../../firebase";
import { takeLatest, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { WORKSPACE_NO_ACCESS } from "@superprofit/core-redux-saga-modules/Auth/constants";
import { getPathWithKey } from "../../../../router/publicRoutes";
const m = new ModuleFactory(Workspace, db);

export const reducer = m.reducer;
export const sagas = m.sagas;
export const actions = m.actions;
export const constants = m.constants;

export const handleNoWorkspaceAccessSaga = function*() {
  yield takeLatest(WORKSPACE_NO_ACCESS, function*(args) {
    yield put(push(`${getPathWithKey("workspace-no-access")}?workspace=${args?.payload?.workspace}`));
  });
};
