import React, { useEffect, useMemo } from "react";
import { PageContent } from "../../../components/atoms/Page";
import Grid from "@material-ui/core/Grid";
import MonthTable from "./plan/ProjectTable";
import { useDispatch, useSelector } from "react-redux";
import PeriodSelect from "../../../components/molecules/PeriodSelect";
import { useDebounce } from "use-debounce";
import { setPeriod } from "../../../redux/modules/ui/userOverview/period/actions";
import { watchPatchMultipleProjects } from "../../../redux/modules/api/projects/actions";
import { stageUpdates } from "../../../redux/modules/ui/userOverview/plan/actions";
import AppBar from "@superprofit/core-react-components/atoms/AppBar";
import Snackbar from "@superprofit/core-react-components/atoms/Snackbar";
import { filterArchivedProjects } from "../../../utils/ProjectStats";
import useUser from "../../../hooks/useUser";
import useUserStats from "../../../hooks/useUserStats";
import useTimetUser from "../../../hooks/useTimetUser";
import useProjects from "../../../hooks/useProjects";
import { useParams } from "react-router-dom";

export default () => {
  const dispatch = useDispatch();

  const snackbar = useSelector(state => state.ui.userOverview.snackbar);
  const { id: email } = useParams();

  const { data: user } = useTimetUser({
    id: email
  });
  const period = useSelector(state => state.ui.userOverview.period);

  const { data: byWeek = [], isLoading: statsLoading } = useUserStats({
    year: period.year,
    user: user.email
  });

  const { data: projects } = useProjects();

  const {
    updatesByProjectId,
    stagedPlansByProjectId,
    saving: projectPlansSaving
  } = useSelector(state => state.ui.userOverview.plan);

  const [debouncedStagedUpdates] = useDebounce(updatesByProjectId, 1500);

  // Filter projects that are archived
  const activeProjects = useMemo(() => {
    if (statsLoading || !projects) return [];
    return filterArchivedProjects(
      projects,
      byWeek.filter(s => s.year === period.year && s.month === period.month)
    );
  }, [projects, byWeek, statsLoading]);

  const filteredProjects = useMemo(() => {
    if (!user) return [];
    return activeProjects.filter(project => project.team.indexOf(user.id) > -1);
  }, [activeProjects, user]);

  const monthStats = useMemo(() => {
    return byWeek.filter(
      s => s.month === period.month && s.year === period.year
    );
  }, [byWeek, period]);

  useEffect(() => {
    if (Object.keys(debouncedStagedUpdates).length > 0) {
      dispatch(
        watchPatchMultipleProjects(
          Object.keys(stagedPlansByProjectId),
          {},
          stagedPlansByProjectId
        )
      );
    }
  }, [dispatch, debouncedStagedUpdates]);

  const handleOnChangePeriod = ({ year, month }) => {
    dispatch(setPeriod(year, month));
  };

  const handleOnChangeProjectPlan = (year, month, projectId, value) => {
    const project = projects.find(p => p.id === projectId);
    if (project) {
      dispatch(
        stageUpdates(project, {
          [`${year}.${month}.${user.id}`]: Number.parseFloat(value)
        })
      );
    }
  };
  const isLoading = statsLoading;
  const isSaving = projectPlansSaving;

  return (
    <PageContent>
      <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
        <Grid item xs={12} component="div" justify="flex-start">
          <PeriodSelect
            period={period}
            onChange={handleOnChangePeriod}
            showWeek={false}
          />
        </Grid>
        <Grid item xs={12} component="div" justify="flex-start">
          <MonthTable
            onChange={handleOnChangeProjectPlan}
            year={period.year}
            month={period.month}
            user={user}
            projects={filteredProjects}
            stats={monthStats}
            loading={isLoading}
          />
        </Grid>
      </Grid>
      <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={snackbar.visible}
          message={snackbar.text}
        />
      </AppBar>
    </PageContent>
  );
};
