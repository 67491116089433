import { useMutation, useQueryClient } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import Project, { IProject } from "../models/Project";
import useUser from "./useUser";
import useProject from "./useProject";
import useProjects, { getKey as getProjectsKey } from "./useProjects";
import { getKey as getProjectKey } from "./useProject";
import useProjectsMap from "./useProjectsMap";

const useProjectUpdateMutation = (projectId?: string | null) => {
  const workspace = useWorkspace();
  // const { data: projectFromParam } = useProject(projectId || null);
  const projectsMap = useProjectsMap();
  const queryClient = useQueryClient();
  const user = useUser();
  const update = (updates: Partial<IProject>) => {
    const id = updates?.id || projectId;
    let project = null;
    if (id) project = projectsMap.get(id);
    if (project) {
      return Project.update(workspace, user.email, project, updates);
    } else throw new Error(`No project found with id ${id}`);
  };

  return useMutation(update, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        getProjectsKey(workspace),
        (projects: Project[] = []) => {
          const idx = projects.findIndex(p => p.id === data.id);
          if (idx > -1) return projects.map(p => (p.id === data.id ? data : p));
          return [data].concat(projects);
        }
      );
      queryClient.setQueryData(getProjectKey(workspace, data.id), () => data);
    }
  });
};

export default useProjectUpdateMutation;
