import { useQuery } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import XstatsUserProjectHoursByMonth from "../models/XstatsUserProjectHoursByMonth";

const useUserStatsByMonth = ({
  year,
  month
}: {
  month: number;
  year: number;
}) => {
  const workspace = useWorkspace();
  const get = () =>
    XstatsUserProjectHoursByMonth.listMonth(workspace, month, year);
  const key = [
    workspace,
    year,
    month,
    XstatsUserProjectHoursByMonth.collectionName
  ];
  const query = useQuery(key, get, {
    enabled: !!year && !!month,
    staleTime: 1000 * 20 //20 seconds stale time. May need to adjust
  });
  return query;
};

export default useUserStatsByMonth;
