
import Workspace from "./Workspace";

export default class StripeAccountDetails {
  static converter = {
    toFirestore(accountDetails) {
      return accountDetails.data();
    },
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      return new StripeAccountDetails({ ...data, id: snapshot.id });
    },
  };

  static collectionName = "stripe";
  static documentId = 'data';

  constructor({
    id,
    createdAt,
    customerId,
    createdBy,
    updatedBy,
    updatedAt,
  }) {
    this.id = id;

    this.customerId = customerId;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.updatedBy = updatedBy;
    this.updatedAt = updatedAt;
  }

  data() {
    return {
      customerId: this.customerId,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
    };
  }
/*
  static getDetailsDoc = async (db, workspace) => {
    const detailsSnapshot = await db
      .collection(Workspace.collectionName)
      .doc(workspace)
      .collection(StripeAccountDetails.collectionName)
      .withConverter(StripeAccountDetails.converter)
      .get();

    return detailsSnapshot.docs[0]; // only one document in this collection.
  };

  static getSubs = async (db, workspace, id) => {
    const subscriptionsSnapshot = await db
      .collection(Workspace.collectionName)
      .doc(workspace)
      .collection(StripeAccountDetails.collectionName)
      .doc(id)
      .collection(StripeAccountDetails.subscriptionsCollectionName)
      .get();
    const subs = subscriptionsSnapshot.docs.map((doc) => doc.data());
    return subs;
  };

  static getPaymentMethods = async (db, workspace, id) => {
    const paymentMethodsSnapshot = await db
      .collection(Workspace.collectionName)
      .doc(workspace)
      .collection(StripeAccountDetails.collectionName)
      .doc(id)
      .collection(StripeAccountDetails.paymentMethodsCollectionName)
      .get();
    const pm = paymentMethodsSnapshot.docs.map((doc) => doc.data());
    return pm;
  };*/

  static get = async (db, workspace) => {
    const doc = await db
      .collection(Workspace.collectionName)
      .doc(workspace)
      .collection(StripeAccountDetails.collectionName)
      .doc(StripeAccountDetails.documentId)
      .get();
    return doc.data();
  };
/*
  static listen = async (db, workspace, callback) => {
    const detailsDoc = await StripeAccountDetails.get(db, workspace);
    // if (!detailsDoc) return null;
    const detachAccountDetails = db
      .collection(Workspace.collectionName)
      .doc(workspace)
      .collection(StripeAccountDetails.collectionName)
      .doc(detailsDoc.id)
      .withConverter(StripeAccountDetails.converter)
      .onSnapshot(async (doc) => {
        const details = doc.data();
        details.subscriptions = await StripeAccountDetails.getSubs(
          db,
          workspace,
          doc.id
        );
        details.paymentMethods = await StripeAccountDetails.getPaymentMethods(
          db,
          workspace,
          doc.id
        );
        callback(details);
      });

    const detachSubscriptions = db
      .collection(Workspace.collectionName)
      .doc(workspace)
      .collection(StripeAccountDetails.collectionName)
      .doc(detailsDoc.id)
      .collection(StripeAccountDetails.subscriptionsCollectionName)
      .onSnapshot(async (docs) => {
        const detailsDoc = await StripeAccountDetails.getDetailsDoc(
          db,
          workspace
        );

        const subscriptions = [];
        docs.forEach((doc) => {
          subscriptions.push(doc.data());
        });
        const details = detailsDoc.data();
        details.subscriptions = subscriptions;

        details.paymentMethods = await StripeAccountDetails.getPaymentMethods(
          db,
          workspace,
          detailsDoc.id
        );
        callback(details);
      });

    const detachPaymentMethods = db
      .collection(Workspace.collectionName)
      .doc(workspace)
      .collection(StripeAccountDetails.collectionName)
      .doc(detailsDoc.id)
      .collection(StripeAccountDetails.paymentMethodsCollectionName)
      .onSnapshot(async (docs) => {
        const detailsDoc = await StripeAccountDetails.getDetailsDoc(
          db,
          workspace
        );
        const paymentMethods = [];
        docs.forEach((doc) => {
          paymentMethods.push(doc.data());
        });
        const details = detailsDoc.data();
        details.paymentMethods = paymentMethods;
        details.subscriptions = await StripeAccountDetails.getSubs(
          db,
          workspace,
          detailsDoc.id
        );
        callback(details);
      });
    return () => {
      detachPaymentMethods();
      detachAccountDetails();
      detachSubscriptions();
    };
  };*/
}
