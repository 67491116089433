import { SET_BASIS, SET_LOADING } from "./constants";

export const setBasis = (stats, customers, projects, users) => ({
  type: SET_BASIS,
  payload: { stats, users, customers, projects }
});

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});
