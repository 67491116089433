import { db, firebase } from "../firebase";
import Timestamp from "./Timestamp";
import { Query, QueryBuilder } from "./QueryBuilder";
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

interface IUserAccess {
  /* id is the users email address */
  id: string;
  isActive: boolean | null;
  isPendingInvite: boolean | null;
  isAdmin: boolean | null;
  createdBy: string | null;
  createdAt: ReturnType<typeof Timestamp.now>;
  updatedBy: string | null;
  updatedAt: ReturnType<typeof Timestamp.now>;
}
export default class UserAccess implements IUserAccess {
  id: string;
  isActive: boolean | null;
  isPendingInvite: boolean | null;
  isAdmin: boolean | null;
  createdBy: string | null;
  createdAt: ReturnType<typeof Timestamp.now>;
  updatedBy: string | null;
  updatedAt: ReturnType<typeof Timestamp.now>;

  static converter = {
    toFirestore(user: UserAccess) {
      return user.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IUserAccess;
      return new UserAccess({ ...data, id: snapshot.id });
    }
  };

  static collectionName = "userAccess";

  static createId(workspace: string) {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserAccess.collectionName)
      .doc().id;
  }

  private booleanOrNull(value: boolean | null) {
    if (value === null) {
      return null;
    }
    return !!value;
  }

  constructor({
    id,
    isActive,
    isAdmin,
    isPendingInvite,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt
  }: IUserAccess) {
    this.id = id;
    this.isActive = this.booleanOrNull(isActive);
    this.isPendingInvite = this.booleanOrNull(isPendingInvite);
    this.isAdmin = this.booleanOrNull(isAdmin);
    this.createdBy = createdBy || null;
    this.createdAt = createdAt || null;
    this.updatedBy = updatedBy || null;
    this.updatedAt = updatedAt || null;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IUserAccess>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      id: this.id,
      isActive: this.booleanOrNull(this.isActive),
      isPendingInvite: this.booleanOrNull(this.isPendingInvite),
      isAdmin: this.booleanOrNull(this.isAdmin),
      createdBy: this.createdBy || null,
      createdAt: this.createdAt || null,
      updatedBy: this.updatedBy || null,
      updatedAt: this.updatedAt || null
    };
  }

  static get = async (workspace: string, user: string) => {
    const doc = await db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserAccess.collectionName)
      .doc(user)
      .get();
    return { ...doc.data(), id: doc.id };
  };

  static getAll = (workspace: string, usersIds: string[]) => {
    return Promise.all(usersIds.map(user => UserAccess.get(workspace, user)));
  };

  static nullValuesPayload = () => {
    return {
      isActive: null,
      isPendingInvite: null,
      isAdmin: null,
      createdBy: null,
      createdAt: null,
      updatedBy: null,
      updatedAt: null
    };
  };

  static listenAll = (
    workspace: string,
    callback: (data: UserAccess[]) => void
  ) => {
    return db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserAccess.collectionName)
      .withConverter(UserAccess.converter)
      .onSnapshot(qs => {
        const data: UserAccess[] = [];
        qs.forEach(doc => {
          data.push(doc.data());
        });
        callback(data);
      });
  };

  static list = async (workspace: string, query?: Query) => {
    const snapshot = await QueryBuilder.build(
      db
        .collection("workspaces")
        .doc(workspace)
        .collection(UserAccess.collectionName),
      query
    )
      .withConverter(UserAccess.converter)
      .get();

    return snapshot.docs.map(doc => doc.data());
  };

  static create = async (
    workspace: string,
    userId: string,
    data: Partial<IUserAccess>
  ) => {
    if (!data.id) {
      throw new Error("UserAccess must have an id");
    }

    const newUserAccess = new UserAccess({
      ...UserAccess.nullValuesPayload(),
      ...data,
      id: data.id,
      updatedAt: Timestamp.now(),
      createdAt: Timestamp.now(),
      updatedBy: userId,
      createdBy: userId
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserAccess.collectionName)
      .doc(newUserAccess.id)
      .withConverter(UserAccess.converter)
      .set(newUserAccess, { merge: true });

    return newUserAccess;
  };

  static update = async (
    workspace: string,
    byUser: string,
    original: UserAccess,
    updates: Partial<IUserAccess>
  ) => {
    const updatedUserAccess = original.clone();

    updatedUserAccess.setData({
      ...updates,
      updatedAt: Timestamp.now(),
      updatedBy: byUser
    });

    await db
      .collection("workspaces")
      .doc(workspace)
      .collection(UserAccess.collectionName)
      .doc(updatedUserAccess.id)
      .withConverter(UserAccess.converter)
      .set(updatedUserAccess, { merge: true });

    return updatedUserAccess;
  };
}
