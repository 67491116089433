import { all, put, takeLatest, select } from "@redux-saga/core/effects";

import { GET, GET_SUCCESS } from "../../../api/companyDashboard/constants";

import { setHoursByProject, setLoading } from "./actions";

export const getProjects = ({
  api: {
    projects: { list }
  }
}) => list;

export const getStats = ({
  api: {
    companyStats: {
      stats: { raw }
    }
  }
}) => raw;

export const handleGetSuccess = function*(args) {
  try {
    const projects = yield select(getProjects);
    const stats = yield select(getStats);

    if (!stats || stats.length === 0 || !projects || projects.length === 0)
      return;

    let hoursByProjectWeekly = stats.reduce(
      (prev, next) => ({
        ...prev,
        [next.project]: {
          weeks: {
            ...prev[next.project]?.weeks,
            [next.week]:
              (prev[next.project]?.weeks[next.week] || 0) + next.total
          }
        }
      }),
      {}
    );

    const hoursByProjectMonthly = stats.reduce(
      (prev, next) => ({
        ...prev,
        [next.project]: {
          months: {
            ...prev[next.project]?.months,
            [next.month]:
              (prev[next.project]?.months[next.month] || 0) + next.total
          }
        }
      }),
      {}
    );

    const hoursByProject = projects.map(p => ({
      id: p.id,
      ...p.data(),
      weeklyHours: hoursByProjectWeekly[p.id]?.weeks || {},
      monthlyHours: hoursByProjectMonthly[p.id]?.months || {}
    }));

    yield put(setHoursByProject(hoursByProject));
    yield put(setLoading(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleGet = function*(args) {
  yield put(setLoading(true));
};

export const handleGetSuccessSaga = function*() {
  yield takeLatest(GET_SUCCESS, handleGetSuccess);
};

export const handleGetSaga = function*() {
  yield takeLatest(GET, handleGet);
};

export default function*() {
  yield all([handleGetSuccessSaga(), handleGetSaga()]);
}
