import {
  INIT,
  HIDE,
  SHOW,
  UPDATE,
  UPDATE_TIMESHEET_ENTRIES,
  UPDATE_QUERY,
  SET_UPDATES
} from "./constants";

export const init = () => ({
  type: INIT
});

export const showInvoiceDialog = invoice => ({
  type: SHOW,
  payload: { invoice }
});

export const hideInvoiceDialog = () => ({
  type: HIDE,
  payload: {}
});

export const updateInvoiceDialogData = updates => ({
  type: UPDATE,
  payload: { updates }
});

export const setUpdates = (updates, unsaved = true) => ({
  type: SET_UPDATES,
  payload: { updates, unsaved }
});

export const updateInvoiceDialogQuery = query => ({
  type: UPDATE_QUERY,
  payload: { query }
});

export const updateInvoiceDialogTimesheetEntries = timesheetEntries => ({
  type: UPDATE_TIMESHEET_ENTRIES,
  payload: { updates: { timesheetEntries } }
});
