import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { useIsUserAdmin } from "../../../../hooks/useIsUserAdmin";

export const useOverrideTimesheetUser = () => {
  const location = useLocation();
  const isAdmin = useIsUserAdmin();
  const searchParams = new URLSearchParams(location.search);
  const overrideTimesheetUser = searchParams.get("overrideUser");
  if (!overrideTimesheetUser || !isAdmin) return null;
  try {
    yup
      .string()
      .email()
      .required()
      .validateSync(overrideTimesheetUser);
    return overrideTimesheetUser;
  } catch (e) {
    console.warn("Invalid overrideTimesheetUser", overrideTimesheetUser);
    return null;
  }
};
