import {actionTypes} from '../../reducer';
import retryInvoiceWithNewPaymentMethod from './retryInvoiceWithNewPaymentMethod';
import createSubscription from './createSubscription';

export default args => async (event) => {
  const {dispatch, stripe, elements, state, apiBase, customerId, priceId, quantity, firebaseIdToken, contactName, contactEmail, coupon, sendInvoice = false} = args;
  // console.log(state.accountDetails?.customer?.id, state.accountDetails)

  dispatch({
    type: actionTypes.toggleCreatingSubscription,
  });
  // We don't want to let default form submission happen here,
  // which would refresh the page.
  event?.preventDefault();
  if (!stripe || !elements) {
    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    return;
  }
  // Get a reference to a mounted CardElement. Elements knows how
  // to find your CardElement because there can only ever be one of
  // each type of element.
  const cardElement = elements.getElement("card");
  // If a previous payment was attempted, get the latest invoice
  let paymentMethodId = undefined; // should be set to select stored card
  let error;
  const latestInvoicePaymentIntentStatus = localStorage.getItem('stripe_latestInvoicePaymentIntentStatus');

  if (!paymentMethodId && !sendInvoice) {
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: contactName,
        email: contactEmail
      },
    });
    error = result.error;
    paymentMethodId = result.paymentMethod?.id;
  }

  if (error) {
    console.log("[createPaymentMethod error]", error);
    dispatch({
      type: actionTypes.toggleCreatingSubscription,
    });
    dispatch({
      type: actionTypes.setCreatedSubscriptionStatus,
      payload: {
        status: 'error',
        message: error
      }
    })

  } else {
    if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
      // Update the payment method and retry invoice payment
      const invoiceId = localStorage.getItem('stripe_latestInvoiceId');
      await retryInvoiceWithNewPaymentMethod({
        dispatch,
        stripe,
        apiBase,
        customerId,
        paymentMethodId,
        invoiceId,
        priceId,
        firebaseIdToken,
      });
    } else {
      // Create the subscription
      await createSubscription({
        stripe,
        quantity,
        dispatch,
        apiBase,
        customerId,
        paymentMethodId,
        priceId,
        firebaseIdToken,
        coupon,
        sendInvoice,
        contactName,
        contactEmail
      });
    }
  }
};