import {useQuery} from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import Project from "../models/Project";

export const getKey = (workspace: string) => [
    workspace,
    Project.collectionName
];
const useProjects = () => {
    const workspace = useWorkspace();

    const get = () => Project.list(workspace);
    const query = useQuery(getKey(workspace), get, {
        staleTime: 1000 * 60 * 5 //five minutes stale time. rarely changes
    });
    return query;
};

export default useProjects;
