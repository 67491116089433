import React from "react";
import Table, {
  TableHead,
  TableCell,
  TableRow,
  TableBody
} from "@superprofit/core-react-components/atoms/Table";
import MoreVert from "@material-ui/icons/MoreVert";
import IconButton from "@superprofit/core-react-components/atoms/IconButton";
import { monthNames } from "../../../../helpers";
import Skeleton from "@material-ui/lab/Skeleton";
import { useTranslation } from "react-i18next";

const months = monthNames();

const Loader = ({ rows }) => (
  <TableBody>
    {new Array(rows).fill("").map((row, idx) => (
      <TableRow key={idx}>
        <TableCell component="th" scope="row">
          <div>
            <Skeleton variant="text" width={100} />
          </div>
          <div>
            <Skeleton variant="text" width={50} />
          </div>
        </TableCell>
        <TableCell align="right">
          <Skeleton variant="text" width={50} />
        </TableCell>
        <TableCell align="right">
          <Skeleton variant="text" width={50} />
        </TableCell>
        <TableCell align="right">
          <Skeleton variant="text" width={50} />
        </TableCell>
        <TableCell align="right">
          <Skeleton variant="circle" width={40} height={40} />
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
);

export default ({ goals, inProgress }) => {
  const { t } = useTranslation();

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Period</TableCell>
          <TableCell align="right">{t("common.billable")}</TableCell>
          <TableCell align="right">Avg.rate</TableCell>
          <TableCell align="right">Non. billable</TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
      </TableHead>
      {inProgress ? (
        <Loader rows={5} />
      ) : (
        <TableBody>
          {goals.map(row => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                <div>
                  <b>
                    {Number.isInteger(row.month)
                      ? months[row.month - 1]
                      : row.month}
                  </b>
                </div>
                <div>{row.year}</div>
              </TableCell>
              <TableCell align="right">{row.billable}</TableCell>
              <TableCell align="right">{row.avgBillableRate}</TableCell>
              <TableCell align="right">{row.nonBillable}</TableCell>
              <TableCell align="right">
                <IconButton>
                  <MoreVert />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  );
};
