import React, { forwardRef } from "react";
import { Avatar } from "@superprofit/core-react-components/atoms";
import Project from "../../models/Project";

interface IProjectAvatar {
  project: Project
}

export default forwardRef(({ project, ...rest }: IProjectAvatar, ref) => (
  <Avatar
    initial={project.name && project.name[0]}
    alt={`Avatar ${project.name}`}
    color={project.getColor()}
    variant="rounded"
    {...rest}
    ref={ref}
  />
));
