import {
  HIDE_BUDGET_DIALOG,
  SHOW_BUDGET_DIALOG,
  UPDATE_BUDGET_DIALOG_DATA
} from "./constants";

import { SAVE_SUCCESS, SAVE } from "../../../api/projects/constants";

const initialState = {
  updates: {},
  saving: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE:
      return {
        ...state,
        saving: true
      };

    case SAVE_SUCCESS: // Hide dialog when saved, use sagas?
      return {
        ...state,
        saving: false,
        updates: {}
      };

    case UPDATE_BUDGET_DIALOG_DATA:
      return {
        ...state,
        updates: {
          ...state.updates,
          ...action.payload.updates
        }
      };

    default:
      return state;
  }
};
