import { useMemo } from "react";
import { filterStats } from "../../../../utils/StatsFilter";
import Project from "../../../../models/Project";
import User from "../../../../models/User";

const useFilteredUsers = ({
  filters,
  users
}: {
  filters: any;
  users: User[];
}) => {
  return useMemo(() => {
    if (!filters || filters.users.length === 0) return users;
    const shownUsersById = filters.users.reduce(
      (prev: { [key: string]: boolean }, next: string) => ({
        ...prev,
        [next]: true
      }),
      {}
    );

    return users.filter(u => !!shownUsersById[u.email]);
  }, [filters, users]);
};

export default useFilteredUsers;
