import useUserStatsByMonth from "../../../hooks/useUsersStatsByMonth";
import { useMemo } from "react";

const useReminders = (opts: { year: number; month: number }) => {
  const treshold = 150;
  const userTreshold = treshold;
  const stats = useUserStatsByMonth(opts);
  const data = useMemo(() => {
    if (!stats?.data) return undefined;
    const m = new Map();
    for (let stat of stats.data) {
      if (m.has(stat.user)) {
        const s = m.get(stat.user);
        m.set(stat.user, { ...s, total: s.total + stat.total });
      } else {
        m.set(stat.user, {
          user: stat.user,
          total: stat.total,
          projects: [stat.project]
        });
      }
    }
    return Array.from(m.values()).filter(s => s.total < userTreshold);
  }, [stats?.data]);

  return { ...stats, data };
};

export default useReminders;
