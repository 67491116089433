import { all, put, takeLatest } from "@redux-saga/core/effects";
import { SAVE, SAVE_SUCCESS } from "../../../api/userSettings/constants";

import { hideGoalDialog, setSaveState } from "./actions";

export const handleSaveSuccess = function*(args) {
  const { savedSettings } = args.payload;
  try {
    yield put(hideGoalDialog(savedSettings));
    yield put(setSaveState(false));
  } catch (e) {
    console.warn(e);
  }
};

export const handleSave = function*(args) {
  try {
    yield put(setSaveState(true));
  } catch (e) {
    console.warn(e);
  }
};

export const handleSaveSettingsSuccessSaga = function*() {
  yield takeLatest(SAVE_SUCCESS, handleSaveSuccess);
};

export const handleSaveSettingsSaga = function*() {
  yield takeLatest(SAVE, handleSave);
};

export default function*() {
  yield all([handleSaveSettingsSuccessSaga(), handleSaveSettingsSaga()]);
}
