import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

export const StyledTitle = styled(Typography)`
  flex: 1;
`;

export const QuickLogForm = styled.div`
  padding: ${props => props.theme.spacing(2)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-bottom: ${props => props.theme.spacing(1)}px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const QuickLogActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: ${props => props.theme.spacing(2)}px;
  > * {
    margin-left: ${props => props.theme.spacing(1)}px;
    margin-right: ${props => props.theme.spacing(1)}px;
  }
`;

export const StyledBox = styled(Box)`
  ${props => props.theme.breakpoints.down("xs")} {
    > * {
      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }
    }
  }
`;
