import React from "react";
import { useSelector } from "react-redux";
import LeadListItem from "./leadList/LeadListItem";
import LeadListLoader from "./leadList/LeadListLoader";

export default () => {
  const leads = useSelector(state => state.api.leads.list);
  const isLoading = useSelector(state => state.api.leads.loading);

  if (isLoading) return <LeadListLoader />;

  return (
    <div>
      {leads.map(l => (
        <LeadListItem key={l.id} lead={l} />
      ))}
    </div>
  );
};
