import {
  HIDE_DASHBOARD_SETTINGS_DIALOG,
  SHOW_DASHBOARD_SETTINGS_DIALOG,
  UPDATE_DASHBOARD_SETTINGS_DIALOG_DATA
} from "./constants";

const initialState = {
  open: false,
  updates: {},
  settings: {},
  saving: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DASHBOARD_SETTINGS_DIALOG:
      return {
        ...initialState,
        open: true,
        settings: action.payload.settings
      };

    case HIDE_DASHBOARD_SETTINGS_DIALOG:
      return {
        ...state,
        open: false
      };

    case UPDATE_DASHBOARD_SETTINGS_DIALOG_DATA:
      return {
        ...state,
        updates: {
          ...state.updates,
          ...action.payload.updates
        }
      };

    default:
      return state;
  }
};
