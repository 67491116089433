import React from "react";
import { Document } from "@react-pdf/renderer";
import {Page, Timet, WorkspaceName} from '../pdfRenderer.styles';
import PageHeader from "../PageHeader";
import UserHours from "../UserHours";
import PageFooter from "../PageFooter";
import {removeEntriesWithZeroHours, sortEntries, splitEntriesByMonth} from '../helpers';
import HoursTable from '../HoursTable';

/* messy solution */
export default ({ currency, projects, users, entries = [], workspaceName = '' }) => {
  const sorted = removeEntriesWithZeroHours(entries).reduce((acc, entry) => {
    const project = acc[entry.project] || {};
    if (project[entry.user])
      project[entry.user] = [...project[entry.user], entry];
    else project[entry.user] = [entry];
    return {
      ...acc,
      [entry.project]: project
    };
  }, {});

  const content = [];
  Object.keys(sorted).forEach(p => {
    Object.keys(sorted[p]).forEach(user => {
      const months = splitEntriesByMonth(sortEntries(sorted[p][user]));
      months.forEach((e, idx) => {
        const proj = projects.find(o => o.id === p);
        const getTotalCost = () =>
          e.reduce((acc, ent) => {
            const billableRate = proj.userBillableRate[ent.user]
              ? proj.userBillableRate[ent.user]
              : proj.billableRate;
            return acc + billableRate * ent.hours;
          }, 0);
        content.push(
          <Page key={`${p}_${user}_${idx}`} size="A4">
            <WorkspaceName>{workspaceName}</WorkspaceName>
            <PageHeader>{proj?.name}</PageHeader>
            <UserHours
              currency={currency}
              user={users.find(u => u.id === user)}
              project={projects.find(o => o.id === p)}
              entries={e}
              getTotalCost={getTotalCost}
            />
            <PageFooter>{idx + 1}</PageFooter>
          </Page>
        );
      });
    });
  });

  return <Document>{content}</Document>;
};
