import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteUsers from "@superprofit/core-react-components/molecules/AutocompleteUsers";
import FormDialog from "@superprofit/core-react-components/molecules/FormDialog";
import {
  hideAddUserDialog,
  updateAddUserDialogData
} from "../../../../redux/modules/ui/projectOverview/addUserDialog/actions";
import { watchSaveProject } from "../../../../redux/modules/api/projects/actions";
import ConfirmationDialog from "../../../../components/molecules/ConfirmationDialog";
import { useTranslation } from "react-i18next";

export default function AddUserDialog() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isClosable, setIsClosable] = useState(true);

  const { project } = useSelector(state => state.ui.projectOverview.project);

  const { open, saving: isSaving, updates } = useSelector(
    state => state.ui.projectOverview.addUserDialog
  );

  const users = useSelector(state => state.api.users.list);

  const hasUnsaved = !!Object.keys(updates || {}).length;

  const usersById = users.reduce(
    (prev, next) => ({
      ...prev,
      [next.id]: next
    }),
    {}
  );

  const handleOnSave = () => {
    dispatch(watchSaveProject(project, updates));
  };

  const handleOnChangeTeam = (e, users) => {
    dispatch(updateAddUserDialogData({ team: users.map(u => u.id) }));
  };

  const handleCancelClose = () => {
    setShowUnsavedConfirmation(false);
  };

  const handleConfirmClose = () => {
    if (isSaving) return;
    dispatch(hideAddUserDialog());
  };

  const handleOnClose = e => {
    if (isSaving || !isClosable) return;
    if (hasUnsaved) {
      setShowUnsavedConfirmation(true);
    } else {
      handleConfirmClose();
    }
  };

  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);

  return (
    <Fragment>
      <ConfirmationDialog
        key="add-user-unsavedwarning"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showUnsavedConfirmation}
      />

      <FormDialog
        key="form"
        saveActionTitle="Save"
        onSave={handleOnSave}
        open={open}
        onClose={handleOnClose}
        title={"Add team members"}
        inProgress={isSaving}
      >
        <AutocompleteUsers
          onClose={() => {
            setIsClosable(true);
          }}
          defaultValue={project?.team?.map(u => usersById[u])}
          users={users}
          onChange={handleOnChangeTeam}
          onOpen={e => setIsClosable(false)}
          variant="filled"
          fullWidth
          label={t("common.teamMembers")}
          placeholder=""
        />
      </FormDialog>
    </Fragment>
  );
}
