import {
  HIDE_ADD_USER_DIALOG,
  SHOW_ADD_USER_DIALOG,
  UPDATE_ADD_USER_DIALOG_DATA,
  SET_SAVING
} from "./constants";

import { SAVE_SUCCESS, SAVE } from "../../../api/projects/constants";

const initialState = {
  open: false,
  users: {},
  isSaving: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SAVING:
      return {
        ...state,
        isSaving: action.payload.isSaving
      };

    case SAVE_SUCCESS:
      return {
        ...state,
        open: false
      };

    case SHOW_ADD_USER_DIALOG: // Hide dialog when saved, use sagas?
      return {
        ...state,
        users: {},
        open: true
      };

    case HIDE_ADD_USER_DIALOG: // Hide dialog when saved, use sagas?
      return {
        ...state,
        users: {},
        open: false
      };

    case UPDATE_ADD_USER_DIALOG_DATA:
      return {
        ...state,
        updates: action.payload.updates
      };

    default:
      return state;
  }
};
