import React, { Fragment, useState } from "react";
import FormDialog from "@superprofit/core-react-components/molecules/FormDialog";
import Select from "@superprofit/core-react-components/atoms/Select";
import MenuItem from "@superprofit/core-react-components/atoms/MenuItem";
import InputLabel from "@superprofit/core-react-components/atoms/InputLabel";
import useFilterProjects from "./hooks/useFilterProjects";
import {
  OnlyShowProjectsWith,
  State as FilterState
} from "../../../redux/modules/ui/timesheet/filter/types";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText
} from "@superprofit/core-react-components/atoms";
import { Grid } from "@material-ui/core";

export default () => {
  const {
    data: filteredProjects,
    isLoading: projectsLoading,
    update,
    showModal: open,
    toggleModal,
    onlyShowProjectsWith,
    showHiddenProjects
  } = useFilterProjects();

  const [stagedUpdates, setStagedUpdates] = useState<Partial<FilterState>>({
    onlyShowProjectsWith,
    showHiddenProjects
  });
  const handleOnApply = () => {
    update(stagedUpdates);
    toggleModal();
  };
  const handleOnChangeOnlyShowProjects = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStagedUpdates({
      ...stagedUpdates,
      onlyShowProjectsWith:
        e.target.value === "all"
          ? null
          : (e.target.value as OnlyShowProjectsWith)
    });
  };

  const handleOnChangeShowHiddenProjects = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStagedUpdates({
      ...stagedUpdates,
      showHiddenProjects: e.target.checked
    });
  };

  const value = stagedUpdates.onlyShowProjectsWith ?? "all";
  return (
    <Fragment>
      <FormDialog
        key="form"
        saveActionTitle="Apply"
        onSave={handleOnApply}
        open={open}
        onClose={toggleModal}
        title="Filter"
      >
        <div>
          <InputLabel variant="standard" shrink={false} id="project-type-label">
            Show projects
          </InputLabel>
          <Select
            fullWidth
            variant="filled"
            labelId="only-show-projects-labelid"
            id="only-show-projects"
            value={value}
            name="only-show-projects-with"
            onChange={handleOnChangeOnlyShowProjects}
          >
            <MenuItem value="all">Show all</MenuItem>
            <MenuItem value={OnlyShowProjectsWith.hoursThisWeek}>
              With hours this week
            </MenuItem>
            <MenuItem value={OnlyShowProjectsWith.hoursThisMonth}>
              With hours this month
            </MenuItem>
          </Select>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={showHiddenProjects}
                  onChange={handleOnChangeShowHiddenProjects}
                  name="showHiddenProjects"
                />
              }
              label="Show hidden projects"
            />
            <FormHelperText>
              Shows the projects that are hidden by you
            </FormHelperText>
          </Grid>
        </div>
      </FormDialog>
    </Fragment>
  );
};
