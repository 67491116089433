import styled, { css } from "styled-components";
import { darken } from "@material-ui/core";

const colors = {
  primary: css`
    background: ${props => props.theme.palette.primary.main};
  `,
  secondary: css`
    background: ${props => props.theme.palette.secondary.main};
  `,
  tertiary: css`
    background: ${props => props.theme.palette.tertiary.main};
  `
};

const barAlign = {
  left: css`
    left: 0;
  `,
  right: css`
    right: 0;
  `
};

export const StyledProgressBar = styled.div<{
  color?: "primary" | "secondary" | "tertiary" | string;
  width: number;
  align: keyof typeof barAlign;
  progress?: number;
  size?: string;
}>`
  background: ${props => darken(props.theme.palette.background.light, 0.05)};
  display: block;
  overflow: hidden;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  position: relative;
  min-width: ${props => props.width}px;
  height: 1.5rem;

  &:after {
    ${props =>
      props.color === "primary" ||
      props.color === "secondary" ||
      props.color === "tertiary"
        ? colors[props.color]
        : css`
            background: ${props.color};
          `};
    position: absolute;
    top: 0;
    height: 100%;
    content: "";
    display: block;
    width: ${props => props.progress}%;
    ${props => barAlign[props.align]};
  }
`;
