import {
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  SAVE,
  SAVE_COMMENT_SUCCESS,
  SAVE_FAILURE,
  SAVE_SUCCESS
} from "./constants";

const initialState = {
  list: [],
  loading: false,
  pagination: {},
  saving: false,
  saveError: null
};

const updateList = (list, lead) => {
  const newList = list.slice();
  const idx = newList.findIndex(c => c.id === lead.id);
  newList[idx] = lead;
  return newList;
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVE:
      return {
        ...state,
        saving: true
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        saving: false,
        list: payload.isNew
          ? [payload.lead].concat(state.list)
          : updateList(state.list, payload.lead)
      };
    case SAVE_FAILURE:
      return {
        ...state,
        saving: false,
        saveError: payload.error
      };
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.list
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false,
        list: payload.list
      };

    case SAVE_COMMENT_SUCCESS:
      return {
        ...state,
        list: updateList(state.list, payload.lead)
      };

    default:
      return state;
  }
};
