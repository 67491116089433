import React, { forwardRef, useEffect } from "react";
import { ElevatedTextField } from "./TextField";
import { AsyncContainer, Spinner, StyledSelect } from "./select/Select.styles";

export default forwardRef((props, ref) => {
  if (props.loading) {
    return (
      <AsyncContainer loading={props.loading}>
        <StyledSelect
          disabled={props.loading}
          {...props}
          ref={ref}
        />
        {props.loading && <Spinner size={20} />}
      </AsyncContainer>
    );
  }
  return <StyledSelect {...props} ref={ref} />;
});

export const ElevatedSelect = forwardRef((props, ref) => (
  <StyledSelect {...props} ref={ref} input={<ElevatedTextField />} />
));
