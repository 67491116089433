import React, { useEffect, useMemo } from "react";
import { PageContent } from "../../../components/atoms/Page";
import Grid from "@material-ui/core/Grid";
import ProjectsTable from "../reports/projects/ProjectsTable";
import { useDispatch, useSelector } from "react-redux";
import PeriodSelect from "../../../components/molecules/PeriodSelect";
import { setPeriod } from "../../../redux/modules/ui/workspaceOverview/period/actions";
import ActivityCard from "./overview/ActivityCard";
import WorkspaceStats from "./overview/WorkspaceStats";
import { logEvent, ANALYTICS_EVENT } from "../../../firebase";
import Reminders from "../../../components/organisms/Reminders";
import usePeriod from "./overview/hooks/usePeriod";
import { useFeatures } from "../../../helpers";
import useUserStats from "../../../hooks/useUserStats";
import useProjects from "../../../hooks/useProjects";
import useUsers from "../../../hooks/useUsers";
import useWorkspace from "../../../hooks/useWorkspace";
import useWorkspaceStats from "./overview/hooks/useWorkspaceStats";

export default () => {
  const period = usePeriod();
  const workspaceName = useWorkspace();
  const handleOnChangePeriod = (upd: { year?: number; month?: number }) => {
    period.update(upd);
  };
  const {
    statsCurrentMonth,
    isLoading: loading,
    projects
  } = useWorkspaceStats();
  useEffect(() => {
    if (workspaceName) {
      logEvent(ANALYTICS_EVENT.DASHBOARD_VISITED, { workspaceName });
    }
  }, [workspaceName]);

  const { timesheetReminders } = useFeatures();
  return (
    <PageContent>
      <Grid container spacing={3} component="div" style={{ marginBottom: 4 }}>
        <Grid item xs={12} component="div" justify="flex-start">
          <PeriodSelect
            period={period}
            onChange={handleOnChangePeriod}
            showWeek={false}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={timesheetReminders ? 8 : undefined}
          component="div"
          justify="flex-start"
        >
          <WorkspaceStats />
        </Grid>

        {timesheetReminders && (
          <Grid item xs={12} md={4} component="div" justify="flex-start">
            <Reminders period={period} />
          </Grid>
        )}

        <Grid item xs={12} component="div" justify="flex-start">
          <ActivityCard />
        </Grid>

        <Grid item xs={12} component="div" justify="flex-start">
          <ProjectsTable
            stats={statsCurrentMonth}
            users={[]}
            projects={projects || []}
            loading={loading}
            period={period}
            columns={{
              avatar: true,
              hours: true,
              billable: true,
              weekDistribution: true,
              relativeShare: true,
              label: true
            }}
          />
        </Grid>
      </Grid>
    </PageContent>
  );
};
