import styled from "styled-components";

export const StyledUserDisplay = styled.div`
  .display-name {
    margin-top: ${props => props.theme.spacing(1)}px;
    margin-bottom: ${props => props.theme.spacing(1)}px;
    font-weight: ${props => props.theme.typography.fontWeightBold};
  }

  .email {
    margin-top: ${props => props.theme.spacing(1)}px;
    margin-bottom: ${props => props.theme.spacing(1)}px;
  }
`;
