import React from "react";
import {
  SearchField,
  Page,
  Snackbar,
  Button,
  Grid
} from "@superprofit/core-react-components/atoms";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupsCard from "./userGroups/UserGroupsCard";
import UserGroupDialog from "./userGroups/UserGroupDialog";
import { showDialog } from "../../redux/modules/ui/userGroups/dialog/actions";
import { useDispatch } from "react-redux";
import ArchiveUsersDialog from "./userGroups/ArchiveUsersDialog";
import { useTranslation } from "react-i18next";

export default () => {
  const dispatch = useDispatch();
  const handleOnFilter = () => {};
  const handleOnCreate = () => {
    dispatch(showDialog());
  };
  const snackbar = { visible: false, text: "" };
  const { t } = useTranslation();
  return (
    <Page context={t("common.management")} title={t("common.userGroups")}>
      <Grid container spacing={3} component="div">
        <Grid container item xs={6} component="div">
          <SearchField
            placeholder={t("common.search")}
            onChange={handleOnFilter}
          />
        </Grid>

        <Grid container item xs={6} component="div" justify="flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={handleOnCreate}
            startIcon={<PersonAddIcon />}
          >
            {t("common.create")}
          </Button>
        </Grid>

        <Grid item xs={12} component="div">
          <GroupsCard />
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={snackbar.visible}
        message={snackbar.text}
      />
      <ArchiveUsersDialog />
      <UserGroupDialog />
    </Page>
  );
};
