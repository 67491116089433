import styled, { css } from "styled-components";
import { Avatar } from "@material-ui/core";
import { lighten } from "../../themes/shared/colorUtils";
import { colors as palette } from "../../themes/light/colors";

const colors = [
  "#2094F3",
  lighten(palette.primary, 0.1),
  lighten(palette.primary, 0.2),
  "#3a2ffa",
  lighten(palette.secondary, 0.1),
  lighten(palette.secondary, 0.2),
  "#00a189",
  lighten(palette.tertiary, 0.1),
  lighten(palette.tertiary, 0.2),
  "#F05A5D",
  lighten(palette.alert, 0.1),
  lighten(palette.alert, 0.2)
];

const colorCombos = colors.reduce(
  (prev, next, idx) => ({
    ...prev,
    [idx]: css`
      color: ${props => props.theme.palette.common.white};
      background-color: ${next};
    `
  }),
  {}
) as { [key: number]: string };

const getIndex = (initial: string) => {
  return initial.toLowerCase().charCodeAt(0) % colors.length;
};

export const StyledAvatar = styled(Avatar)<{
  initial?: string;
  color?: string;
}>`
  ${props => (props.initial ? colorCombos[getIndex(props.initial)] : "")};
  ${props =>
    props.color &&
    css<{ color?: string }>`
      background-color: ${props => props.color};
    `};
`;
