import React, { forwardRef } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import { ElevatedTextField } from "./TextField";

const SearchField = forwardRef(({ ...rest }, ref) => {
  return (
    <ElevatedTextField
      {...rest}
      ref={ref}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
});

SearchField.defaultProps = {
  placeholder: "Search"
};

export default SearchField;
