import { SAVE_SUCCESS, SAVE_FAILED, SAVING } from "./constants";

const initialState = {
  saving: false,
  saveError: {}
};

// Use sagas for this instead
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVING:
      return {
        ...state,
        saving: true
      };

    case SAVE_SUCCESS:
      return {
        ...state,
        saving: false
      };

    case SAVE_FAILED:
      return {
        ...state,
        saveError: payload.error
      };

    default:
      return state;
  }
};
