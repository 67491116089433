import {
  BATCH_SAVE_SUCCESS,
  SAVE_SUCCESS
} from "../../../api/timesheet/entries/constants";
import { SET_ENTRIES_BY_PROJECT, STAGE_ENTRIES } from "./constants";

const initialState = {
  stagedEntries: {},
  entriesByProject: {}
};

const removeStaged = (stagedEntries, entriesToRemove) => {
  const updatedStaged = { ...stagedEntries };
  entriesToRemove.forEach(entry => {
    delete updatedStaged[entry.id];
  });
  return updatedStaged;
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ENTRIES_BY_PROJECT:
      return {
        ...state,
        entriesByProject: payload.entries
      };

    case STAGE_ENTRIES:
      return {
        ...state,
        stagedEntries: payload.entries.reduce(
          (prev, next) => ({ ...prev, [next.id]: next }),
          state.stagedEntries
        )
      };
    case BATCH_SAVE_SUCCESS:
      return {
        ...state,
        stagedEntries: removeStaged(state.stagedEntries, payload.entries)
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        stagedEntries: removeStaged(state.stagedEntries, [payload.entry])
      };
    default:
      return state;
  }
};
