import { takeLatest, call, put, select, all, take } from "redux-saga/effects";
import { WATCH_GET } from "./constants";
import { get, getFailure, getSuccess } from "./actions";
import { watchGetUsers } from "@superprofit/core-redux-saga-modules/Users/actions";
import { watchGetProjects } from "../projects/actions";
import { watchGetCustomers } from "../customers/actions";
import { GET_SUCCESS as GET_PROJECTS_SUCCESS } from "../projects/constants";
import { GET_SUCCESS as GET_CUSTOMERS_SUCCESS } from "../customers/constants";
import { GET_SUCCESS as GET_USERS_SUCCESS } from "@superprofit/core-redux-saga-modules/Users/constants";
import { getStats } from "./helpers";

export const getActiveWorkspace = ({ api: { auth } }) => auth.activeWorkspaceId;

export const getHoursByUserReport = function* getHoursByUserReport(args) {
  const { year, month } = args.payload;
  try {
    yield put(get());
    const workspace = yield select(getActiveWorkspace);

    const stats = yield call(getStats, workspace, year, month);

    yield put(watchGetProjects());
    yield put(watchGetUsers());
    yield put(watchGetCustomers());

    const [
      {
        payload: { list: users }
      },
      {
        payload: { list: projects }
      },
      {
        payload: { list: customers }
      }
    ] = yield all([
      take(GET_USERS_SUCCESS),
      take(GET_PROJECTS_SUCCESS),
      take(GET_CUSTOMERS_SUCCESS)
    ]);

    yield put(getSuccess(stats, customers, projects, users));
  } catch (e) {
    console.warn(e);
    yield put(getFailure(e));
  }
};

export const getHoursByUserReportSaga = function* getHoursByUserReportSaga() {
  yield takeLatest(WATCH_GET, getHoursByUserReport);
};

export default function*() {
  yield all([getHoursByUserReportSaga()]);
}
