import { Project } from "../projectsCard/types";

export interface IProjectsTable {
  onSelectionChange: (projects: Project[]) => void;
  onEdit: (e: React.MouseEvent, data: Project) => void;
  onArchive: (e: React.MouseEvent, data: Project) => void;
  onDelete: (e: React.MouseEvent, data: Project) => void;
  onView: (e: React.MouseEvent, data: Project) => void;
  onDuplicate: (e: React.MouseEvent, data: Project) => void;
  selection: Project[];
  projects: Project[];
  loading: boolean;
}

export interface IProjectRow {
  project: Project;
  onMore: (e: React.MouseEvent<HTMLButtonElement>, project: Project) => void;
  onSelect: () => void;
  selected: boolean;
}

export type OrderBy = keyof Pick<
  Project,
  "name" | "managerName" | "tags" | "billable"
>;

export enum OrderDirection {
  ASC = "asc",
  DESC = "desc",
}

export type ActionMenuState = {
  anchor: HTMLButtonElement | null;
  project: Project | null;
};
