import { all, put, take, select, takeLatest } from "redux-saga/effects";
import {
  setSaving,
  hideAssignProjectsDialog,
} from "./actions";
import { WATCH_SAVE_ASSIGNED_PROJECTS } from "./constants";
import { watchPatchProject } from "../../../api/projects/actions";
import { PATCH_FAILURE, PATCH_SUCCESS } from "../../../api/projects/constants";
import { selectAllUsers } from "../selection/actions";
import { addGlobalAlert } from "../../app/actions";

export const handleSaveAssignedProjects = function* () {
  const { updates, users } = yield select(
    (state) => state.ui.users.assignProjectsDialog
  );
  const { removedProjects, assignedProjects } = updates;
  yield put(setSaving(true));
  for (let project of assignedProjects) {
    const teamSet = new Set(project.team);
    users.forEach((u) => {
      teamSet.add(u.email);
    });
    yield put(watchPatchProject(project.id, { team: [...teamSet] }));
    const response = yield take([PATCH_SUCCESS, PATCH_FAILURE]);
    if (response.type === PATCH_FAILURE) {
      yield put(setSaving(false));

      throw new Error("PATCH FAILURE");
    }
  }

  for (let project of removedProjects) {
    const team = project.team.filter(
      (email) => !(users.findIndex((u) => email === u.email) > -1)
    );
    yield put(watchPatchProject(project.id, { team }));
    const response = yield take([PATCH_SUCCESS, PATCH_FAILURE]);
    if (response.type === PATCH_FAILURE) {
      yield put(setSaving(false));

      throw new Error("PATCH FAILURE");
    }
  }
  yield put(setSaving(false));
  yield put(selectAllUsers([]));
  yield put(hideAssignProjectsDialog());
  yield put(
    addGlobalAlert({
      severity: "success",
      title: "Update success",
      message: `${users.length} user(s) updated.`,
      // vertical: "bottom",
      duration: 3000,
      // horizontal: "center",
    })
  );
};

export const handleSaveAssignedProjectsSaga = function* () {
  yield takeLatest(WATCH_SAVE_ASSIGNED_PROJECTS, handleSaveAssignedProjects);
};

export default function* () {
  yield all([handleSaveAssignedProjectsSaga()]);
}
