import React, { useMemo, useState } from "react";
// hooks

// ui
import Grid from "@material-ui/core/Grid";
import {
  HeaderContainer,
  ProjectTimesheetCardHeader,
  ProjectTitleContainer,
  StyledWeekSummary
} from "./Timesheet.styles";
import { NumberFormatIntl } from "@superprofit/core-react-components/atoms";
import Divider from "@superprofit/core-react-components/atoms/Divider";
import Card from "@superprofit/core-react-components/atoms/Card";
import CardContent from "@superprofit/core-react-components/atoms/CardContent";

import Entry from "./Entry";
import IconButton from "@superprofit/core-react-components/atoms/IconButton";
import DragIcon from "@material-ui/icons/DragIndicator";
import EntryModel from "../../../models/Entry";

// other

import ProjectAvatar from "../../../components/molecules/ProjectAvatar";
import Tooltip from "@material-ui/core/Tooltip";
import { useDebouncedCallback } from "use-debounce";
import { FormHelperText } from "@superprofit/core-react-components/atoms";
import { promiseDelay, useFeatures } from "../../../helpers";
import CommentsAccordion from "./projectTimesheet/CommentsAccordion";
import { useWindowWidth } from "@react-hook/window-size";
import useUserTimesheet from "../../../hooks/useUserTimesheet";
import useUser from "../../../hooks/useUser";
import Project from "../../../models/Project";
import useCompanySettingsCurrency from "../../../hooks/useCompanySettingsCurrency";
import useUserTimesheetMutation, {
  EntryPayload
} from "../../../hooks/useUserTimesheetMutation";
import useTimesheetComments from "../../../hooks/useTimesheetComments";
import TimesheetComment from "../../../models/TimesheetComment";
import useTimesheetCommentMutation from "../../../hooks/useTimesheetCommentMutation";
import {
  add,
  getDayOfYear,
  isWeekend,
  setDayOfYear,
  setISOWeek,
  setISOWeekYear
} from "date-fns";
import { format } from "../../../date-fns-wrappers";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import useHiddenProjectTimesheets from "./hooks/useHiddenProjectTimesheets";
import useWorkspace from "../../../hooks/useWorkspace";
import useISOWeekBookendsAsDate from "./hooks/useISOWeekBookendsAsDate";
import { ANALYTICS_EVENT, logEvent } from "../../../firebase";
import useGlobalSnackbar from "../../../hooks/useGlobalSnackbar";
import { useTranslation } from "react-i18next";
import { useOverrideTimesheetUser } from "./hooks/useOverrideTimesheetUser";

const MAX_HOURS_WORKING_DAYS = 5 * 24;
export const WINDOW_MAX_CARD_BREAKPOINT = 1800;

type Period = { year: number; week: number };

interface ProjectTimesheet {
  dragHandleProps: any;
  period: Period;
  project: Project & { expandedCustomer?: any };
}

export default ({
  dragHandleProps,
  period: { year, week },
  project,
  ...rest
}: ProjectTimesheet) => {
  const windowWidth = useWindowWidth();
  const inputRefs: HTMLInputElement[] = [];
  const workspaceName = useWorkspace();
  const { t } = useTranslation();
  const [stagedEntries, setStagedEntries] = useState<Map<string, EntryPayload>>(
    new Map()
  );
  const { update: updateSnackbar } = useGlobalSnackbar();

  // const { autoFillWeek } = useFeatures();
  const currency = useCompanySettingsCurrency();
  const user = useUser();
  const userEmail = user && user.email;
  const { timesheetEntryComments } = useFeatures();
  const overridenUserEmail = useOverrideTimesheetUser();
  const { email } = useUser();
  const timesheetEmail = overridenUserEmail || email;
  const { data: rawEntries } = useUserTimesheet({
    id: timesheetEmail,
    year,
    week
  });
  const mutation = useUserTimesheetMutation();
  const entries = useMemo(
    () => (rawEntries || []).filter(e => e.project === project.id),
    [rawEntries]
  );

  const { data: rawComments } = useTimesheetComments({
    id: timesheetEmail,
    year,
    week,
    projectId: project.id!
  });
  const commentMutation = useTimesheetCommentMutation({
    id: timesheetEmail,
    year,
    week,
    projectId: project.id!
  });

  const { toggleHidden, hidden } = useHiddenProjectTimesheets({
    userId: timesheetEmail
  });

  const comments = useMemo(
    () =>
      (rawComments || []).filter(
        (e: TimesheetComment) => e.project === project.id
      ),
    [rawComments]
  );

  const [startOfWeekDate, endOfWeekDate] = useISOWeekBookendsAsDate({
    year,
    week
  });
  const startOfWeek = getDayOfYear(startOfWeekDate);

  const days = useMemo(() => {
    return new Array(7)
      .fill("")
      .map(
        (d, idx) =>
          `${d} ${format(add(startOfWeekDate, { days: idx }), "EEE do")}`
      );
  }, [startOfWeekDate]);

  const entriesByISOStringMap = useMemo(() => {
    const m = new Map<string, EntryModel>();
    for (let entry of entries) {
      let dt = new Date();
      dt = setISOWeekYear(dt, entry.year);
      dt = setDayOfYear(dt, entry.dayOfYear);
      m.set(dt.toISOString(), entry);
    }
    return m;
  }, [entries]);

  const toggleProjectHidden = () => {
    if (project.id) toggleHidden(project.id);
  };

  const saveBatch = async () => {
    const entriesToSave = Array.from(stagedEntries.values());
    updateSnackbar({
      open: true,
      message: `${t("common.saving")}...   😎`,
      alert: { severity: "info" }
    });
    entriesToSave.forEach((e: EntryPayload) => {
      logEvent(ANALYTICS_EVENT.TIMESHEET_ENTRY_SAVE, {
        workspaceName,
        id: e.entry?.id,
        uid: user?.uid,
        projectId: e.project,
        year: e.year,
        dayOfYear: e.dayOfYear,
        hours: e.hours
      });
    });

    mutation.mutate(entriesToSave, {
      onSuccess: async (/*data, variables, context*/) => {
        entriesToSave.forEach((e: EntryPayload) => {
          logEvent(ANALYTICS_EVENT.TIMESHEET_ENTRY_SAVE_SUCCESS, {
            workspaceName,
            id: e.entry?.id,
            uid: user?.uid
          });
        });
        await new Promise<void>(res => setTimeout(() => res(), 1500));
        updateSnackbar({ open: false });
        updateSnackbar({
          open: true,
          message: `${t("pages.timesheet.hoursSaved")}!   🎉`,
          alert: { severity: "success" }
        });
      },
      onError: async (error: unknown) => {
        entriesToSave.forEach((e: EntryPayload) => {
          logEvent(ANALYTICS_EVENT.TIMESHEET_ENTRY_SAVE_ERROR, {
            workspaceName,
            id: e.entry?.id,
            uid: user?.uid
          });
        });
        updateSnackbar({ open: false });

        updateSnackbar({
          open: true,
          message: `${t("common.error")}: ${error}   🚨🚨 `,
          alert: {
            color: "error",
            severity: "error"
          }
        });
        await new Promise<void>(res => setTimeout(() => res(), 3000));
      },
      onSettled: async () => {
        await promiseDelay(5000);
        updateSnackbar({ open: false });
      }
    });
  };
  const [debouncedSaveBatch] = useDebouncedCallback(saveBatch, 250);

  const handleOnChange = (
    e: React.FormEvent<HTMLInputElement>,
    {
      entry: originalEntry,
      dayOfYear,
      year,
      hours
    }: { dayOfYear: number; year: number; hours: number; entry?: EntryModel }
  ) => {
    if (!project.id) {
      console.error("Project not found");
      return;
    }
    const entry: EntryPayload = {
      user: timesheetEmail,
      project: project.id,
      year,
      dayOfYear,
      hours,
      entry: originalEntry
    };
    const key = `${timesheetEmail}${project.id}${year}${dayOfYear}`;
    const clone = new Map(stagedEntries);
    clone.set(key, entry);
    setStagedEntries(clone);
    debouncedSaveBatch();
  };

  const handleOnCommentSave = (c: string, dayOfYear: number) => {
    if (!project.id) return;

    const comment = {
      comment: c,
      day: dayOfYear,
      year,
      project: project.id,
      user: email
    };
    logEvent(ANALYTICS_EVENT.TIMESHEET_COMMENT_SAVE, {
      workspaceName,
      uid: user?.uid,
      projectId: comment.project
    });
    commentMutation.mutate({ comment });
  };
  const handleOnCommentRemove = (comment: TimesheetComment) => {
    if (!project.id) return;
    logEvent(ANALYTICS_EVENT.TIMESHEET_COMMENT_DELETE, {
      workspaceName,
      uid: user?.uid,
      id: comment.id
    });
    commentMutation.mutate({ comment, remove: true });
  };

  const sumProject = entries.reduce((prev, next) => {
    return prev + (next.hours || 0);
  }, 0);

  const weekendDays = new Array(7).fill(false).map((d, idx) => {
    return isWeekend(add(startOfWeekDate, { days: idx }));
  });

  const getTitle = () => {
    if (project.expandedCustomer) {
      return (
        <ProjectTitleContainer>
          <span>{project.name}</span>
          <FormHelperText>
            <i>{project.expandedCustomer.name}</i>
          </FormHelperText>
        </ProjectTitleContainer>
      );
    }
    return project.name;
  };

  let billableRate;
  if (project.billable) {
    billableRate =
      project.billableRate &&
      (typeof project.billableRate === "string"
        ? parseInt(project.billableRate, 10)
        : project.billableRate);

    if (typeof project?.userBillableRate?.[userEmail] === "number") {
      const userBillable =
        project?.userBillableRate && project?.userBillableRate?.[userEmail];
      billableRate =
        typeof userBillable === "string"
          ? parseInt(userBillable, 10)
          : userBillable;
    }
    if (Number.isNaN(billableRate)) billableRate = undefined;
  }

  const dateRange = `${format(startOfWeekDate, "MMM dd")} - ${format(
    endOfWeekDate,
    "MMM dd"
  )}`;

  const isHidden = project.id !== null && hidden.has(project.id);

  return (
    <Card
      {...rest}
      style={{
        transform: "translate3d(0)",
        width: windowWidth >= WINDOW_MAX_CARD_BREAKPOINT ? "44rem" : undefined
      }}
    >
      <HeaderContainer>
        <ProjectTimesheetCardHeader
          avatar={<ProjectAvatar project={project} />}
          title={getTitle()}
          subheader={dateRange}
        />
        <ProjectTimesheetCardHeader
          subheader={
            project.billable && (
              <NumberFormatIntl
                locales="en"
                number={
                  typeof billableRate === "number"
                    ? sumProject * billableRate
                    : 0
                }
                options={{
                  style: currency && "currency",
                  currency: currency && currency.toUpperCase()
                }}
              />
            )
          }
          action={
            <StyledWeekSummary>
              {!isHidden && (
                <Tooltip title="Drag & drop sort">
                  <span {...dragHandleProps}>
                    <DragIcon fontSize="small" />
                  </span>
                </Tooltip>
              )}
              <Tooltip title={isHidden ? "Show project" : "Hide project"}>
                <IconButton component="span" onClick={toggleProjectHidden}>
                  {isHidden ? (
                    <VisibilityOff fontSize="small" />
                  ) : (
                    <Visibility fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </StyledWeekSummary>
          }
        />
      </HeaderContainer>
      <Divider />
      <CardContent style={{ overflow: "auto" }}>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={12} md component="div" style={{ minWidth: "20rem" }}>
            <Grid container spacing={1}>
              {days.slice(0, 4).map((d: string, idx: number) => {
                const date = add(startOfWeekDate, { days: idx });
                const entry = entriesByISOStringMap.get(date.toISOString());
                return (
                  <Grid item xs style={{ minWidth: "4rem" }}>
                    <Entry
                      date={date}
                      abnormalDay={weekendDays[idx]}
                      ref={(c: HTMLInputElement) => (inputRefs[idx] = c)}
                      key={entry?.id || d}
                      title={d}
                      entry={entry}
                      onBlur={(e, params) => handleOnChange(e, params)}
                      onCommentSave={
                        timesheetEntryComments
                          ? e => handleOnCommentSave(e, startOfWeek + idx)
                          : undefined
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md
            spacing={1}
            component="div"
            style={{ minWidth: "20rem" }}
          >
            <Grid container spacing={1}>
              {days.slice(4).map((d: string, idx: number) => {
                const date = add(startOfWeekDate, { days: idx + 4 });
                const entry = entriesByISOStringMap.get(date.toISOString());
                return (
                  <Grid item xs style={{ minWidth: "4rem" }}>
                    <Entry
                      date={add(startOfWeekDate, { days: idx + 4 })}
                      abnormalDay={weekendDays[4 + idx]}
                      ref={(c: HTMLInputElement) => (inputRefs[idx + 4] = c)}
                      key={entry?.id || d}
                      title={d}
                      entry={entry}
                      onBlur={(e, params) => handleOnChange(e, params)}
                      onCommentSave={
                        timesheetEntryComments
                          ? e => handleOnCommentSave(e, startOfWeek + idx + 4)
                          : undefined
                      }
                    />
                  </Grid>
                );
              })}
              <Grid item xs style={{ minWidth: "4rem" }}>
                <div />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      {timesheetEntryComments && project.id && (
        <CommentsAccordion
          onDelete={handleOnCommentRemove}
          comments={comments}
        />
      )}
    </Card>
  );
};
