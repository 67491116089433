import styled from "styled-components";
import Snackbar from "@material-ui/core/Snackbar";

export const StyledInfoBox = styled(Snackbar)`
  z-index: 2;
  color: ${props => props.theme.palette.common.white};
  background: ${props => props.theme.palette.primary.main};
  max-width: 320px;
  padding: ${props => props.theme.spacing(2)}px;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  box-shadow: ${props => props.theme.shadows[3]};

  .title {
    text-transform: uppercase;
    margin-bottom: ${props => props.theme.spacing(2)}px;
  }
`;

export const StyledInfoBoxActions = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.theme.spacing(4)}px;

  align-items: center;
  justify-content: flex-end;

  > * {
    margin-left: ${props => props.theme.spacing(1)}px;
    margin-right: ${props => props.theme.spacing(1)}px;
  }
`;
