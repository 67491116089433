import { all, delay, put, takeLatest } from "@redux-saga/core/effects";
import { updateSnackbar } from "./actions";
import {
  PATCH_MULTIPLE,
  PATCH_MULTIPLE_SUCCESS
} from "../../../api/projects/constants";

export const handleUserSaving = function*(args) {
  try {
    yield put(updateSnackbar({ visible: true, text: "Saving" }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleUserSaved = function*(args) {
  try {
    yield put(updateSnackbar({ visible: true, text: "User updated" }));
    yield delay(2000);
    yield put(updateSnackbar({ visible: false }));
  } catch (e) {
    console.warn(e);
  }
};

export const handleUserSavingSaga = function*() {
  yield takeLatest(PATCH_MULTIPLE, handleUserSaving);
};

export const handleUserSavedSaga = function*() {
  yield takeLatest(PATCH_MULTIPLE_SUCCESS, handleUserSaved);
};

export default function*() {
  yield all([handleUserSavedSaga(), handleUserSavedSaga()]);
}
