import { useQueries } from "@tanstack/react-query";
import useWorkspace from "./useWorkspace";
import { endOfISOWeek, startOfISOWeek } from "date-fns";
import {
  getKey as getProjectEntriesKey,
  getFactory as getProjectEntriesFactory
} from "./useProjectEntries";
import { UseProjectEntriesOptions } from "./useProjectEntries";
export type UseProjectsEntriesOptions = Omit<UseProjectEntriesOptions, "id"> & {
  ids: string[];
};

const attachFirestoreListener = (workspace: string, id: string) => {
  // TODO: Implement this
  /*
      // refresh react-query cache when firestore changes
      const unsubscribe = .onSnapshot(
          (doc: any) => {
          console.log("Current data: ", doc.data());
          },
          (err: any) => {
          console.log("Encountered error: ", err);
          }
      );
      return unsubscribe;*/
};
const useProjectsEntries = (options: UseProjectsEntriesOptions) => {
  const workspace = useWorkspace();
  let { ids, year, week, fromDate, toDate } = options;

  if (year && week) {
    fromDate = startOfISOWeek(new Date(year, week - 1, 1));
    toDate = endOfISOWeek(fromDate);
  }

  if (fromDate && toDate) {
    const from = fromDate;
    const to = toDate;
    const queries =
      ids
        ?.map(id =>
          id
            ? {
                queryKey: getProjectEntriesKey(workspace, id, from, to),
                queryFn: getProjectEntriesFactory(workspace, from, to, {
                  project: id
                }),
                staleTime: Infinity
              }
            : null
        )
        .filter(Boolean) || [];
    const results = useQueries({
      queries
    });
    const entries = results.map((result: any) => result.data).flat();
    const isLoading = results.some((result: any) => result.isLoading);
    const error = results.some((result: any) => result.error);
    const data = isLoading || error ? [] : entries;
    return { data, isLoading, error };
  }
  throw new Error(
    "useProjectsEntries: Either fromDate and toDate or year and week must be provided."
  );
};

export default useProjectsEntries;
