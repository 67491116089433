import { SET_HOURS_BY_PROJECT, SET_LOADING } from "./constants";

export const setHoursByProject = hoursByProject => ({
  type: SET_HOURS_BY_PROJECT,
  payload: { hoursByProject }
});

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: { isLoading }
});
