import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefExoticComponent,
  ReactNode,
  useEffect
} from "react";
import { ElevatedTextField } from "./TextField";
import { AsyncContainer, Spinner, StyledSelect } from "./select/Select.styles";
import { SelectProps } from "@material-ui/core";
import { Controller, ControllerProps } from "react-hook-form";

type ISelectProps = Omit<SelectProps, "variant"> & {
  variant?: "filled";
} & {
  loading?: boolean;
};
const Select: ForwardRefExoticComponent<ISelectProps> = forwardRef(
  (props, ref) => {
    if (props.loading) {
      return (
        <AsyncContainer loading={props.loading}>
          <StyledSelect disabled={props.loading} {...props} ref={ref} />
          {props.loading && <Spinner size={20} />}
        </AsyncContainer>
      );
    }
    return <StyledSelect {...props} ref={ref} />;
  }
);

export const ElevatedSelect: ForwardRefExoticComponent<ISelectProps> = forwardRef(
  (props, ref) => (
    <StyledSelect {...props} ref={ref} input={<ElevatedTextField />} />
  )
);

// interface TagInputFormControllerProps<T> extends TagInputProps {
//   ControllerProps: Omit<ControllerProps<T>, "render">;
// }
//
// export function withFormController<FieldValues>() {
//   return (props: TagInputFormControllerProps<FieldValues>) => {
//     const { ControllerProps, tags = [], ...other } = props;
//     const { name = "tags", control } = ControllerProps;
//     return (
//       <Controller
//         name={name}
//         control={control}
//         render={({ field }) => {
//           const { value = [], ref, onChange, ...rest } = field;
//           return (
//             <TagInput
//               ref={ref}
//               value={value}
//               onChange={(
//                 e: React.ChangeEvent<HTMLElement>,
//                 selected: string[]
//               ) => {
//                 onChange(selected.map(id => id).filter(Boolean) as string[]);
//               }}
//               tags={tags}
//               {...other}
//               {...rest}
//             />
//           );
//         }}
//       />
//     );
//   };
// }

interface SelectFormControllerProps<T> extends ISelectProps {
  ControllerProps: Omit<ControllerProps<T>, "render">;
}

export function withFormController<FieldValues>() {
  return (props: SelectFormControllerProps<FieldValues>) => {
    const { ControllerProps, ...other } = props;
    const { name = "tags", control } = ControllerProps;
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value = [], ref, onChange, ...rest } = field;
          return (
            <Select
              ref={ref}
              value={value}
              onChange={(
                e: ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>,
                child: ReactNode
              ) => {
                onChange(e.target.value);
              }}
              {...other}
              {...rest}
            />
          );
        }}
      />
    );
  };
}

export default Select;
