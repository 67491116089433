import React, { Fragment, useState } from "react";
import FormDialog, {
  FormDialogGroup
} from "@superprofit/core-react-components/molecules/FormDialog";
import TextField from "@superprofit/core-react-components/atoms/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  hideCustomerDialog,
  updateCustomerDialogData
} from "../../../redux/modules/ui/customers/dialog/actions";
import { watchSaveCustomer } from "../../../redux/modules/api/customers/actions";
import TagInput from "@superprofit/core-react-components/atoms/TagInput";
import ColorPicker from "../../../components/atoms/ColorPicker";
import ConfirmationDialog from "../../../components/molecules/ConfirmationDialog";
import { useFeatures } from "../../../helpers";
import { useTranslation } from "react-i18next";

export default ({ ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { twentyFourSevenIntegration } = useFeatures();

  const [showUnsavedConfirmation, setShowUnsavedConfirmation] = useState(false);

  const { open, saving: isSaving, customer, updates, hasUnsaved } = useSelector(
    state => state.ui.customers.dialog
  );

  const {
    id,
    vatNumber,
    billingAddress,
    billingAddressZip,
    billingAddressState,
    billingAddressCountry,
    billingAddressCity,
    name,
    email,
    color,
    contactName,
    tags,
    externalReference,
    twntyFourSevenId
  } = customer;

  const handleConfirmClose = () => {
    dispatch(hideCustomerDialog());
  };

  const handleCancelClose = () => {
    setShowUnsavedConfirmation(false);
  };

  const handleOnClose = e => {
    if (isSaving) return;
    if (hasUnsaved) {
      setShowUnsavedConfirmation(true);
    } else {
      handleConfirmClose();
    }
  };

  const handleOnBlur = (e, key) => {
    dispatch(updateCustomerDialogData({ [key]: e.target.value }));
  };

  const handleOnChangeTags = (e, tags) => {
    dispatch(updateCustomerDialogData({ tags: tags }));
  };

  const handleOnSave = () => {
    dispatch(watchSaveCustomer(customer, updates));
  };

  const handleOnChangeColor = color => {
    dispatch(updateCustomerDialogData({ color: color }));
  };

  return (
    <Fragment>
      <ConfirmationDialog
        key="unsaved"
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
        open={showUnsavedConfirmation}
      />
      <FormDialog
        {...rest}
        key="form"
        saveActionTitle={id ? "Update" : "Save"}
        onSave={handleOnSave}
        open={open}
        onClose={handleOnClose}
        title={`${id ? t("common.edit") : t("common.add")} ${t(
          "common.customer"
        )}`}
        inProgress={isSaving}
      >
        <TextField
          defaultValue={name}
          fullWidth
          variant="filled"
          label={t("common.name")}
          placeholder={t("common.name")}
          onBlur={e => handleOnBlur(e, "name")}
        />

        <ColorPicker
          defaultValue={color || "#3f51b5"}
          onChange={handleOnChangeColor}
        />

        <TagInput
          defaultValue={tags || []}
          fullWidth
          variant="filled"
          label={t("common.tags")}
          placeholder="List of tags"
          onChange={handleOnChangeTags}
        />

        <TextField
          defaultValue={vatNumber}
          fullWidth
          variant="filled"
          label="Vat / Org.nr."
          placeholder="Vat / Org.nr."
          onBlur={e => handleOnBlur(e, "vatNumber")}
        />

        <TextField
          defaultValue={billingAddress}
          variant="filled"
          fullWidth
          label="Billing address"
          placeholder="Billing address"
          onBlur={e => handleOnBlur(e, "billingAddress")}
        />

        <FormDialogGroup>
          <TextField
            defaultValue={billingAddressZip}
            variant="filled"
            label="Zip code"
            placeholder="90210"
            onBlur={e => handleOnBlur(e, "billingAddressZip")}
          />

          <TextField
            defaultValue={billingAddressCity}
            variant="filled"
            label="City"
            placeholder="Los Angeles"
            onBlur={e => handleOnBlur(e, "billingAddressCity")}
          />
        </FormDialogGroup>

        <FormDialogGroup>
          <TextField
            defaultValue={billingAddressState}
            variant="filled"
            label="State"
            placeholder="California"
            onBlur={e => handleOnBlur(e, "billingAddressState")}
          />

          <TextField
            defaultValue={billingAddressCountry}
            fullWidth
            variant="filled"
            label="Country"
            placeholder="USA"
            onBlur={e => handleOnBlur(e, "billingAddressCountry")}
          />
        </FormDialogGroup>

        <TextField
          defaultValue={contactName}
          fullWidth
          variant="filled"
          label="Contact name"
          placeholder="John Doe"
          onBlur={e => handleOnBlur(e, "contactName")}
        />

        <TextField
          defaultValue={email}
          fullWidth
          variant="filled"
          label="Email"
          placeholder="example@timet.io"
          onBlur={e => handleOnBlur(e, "email")}
        />

        <TextField
          defaultValue={externalReference}
          fullWidth
          variant="filled"
          label="Reference no."
          placeholder="--"
          onBlur={e => handleOnBlur(e, "externalReference")}
        />

        {twentyFourSevenIntegration && (
          <TextField
            defaultValue={twntyFourSevenId}
            fullWidth
            variant="filled"
            label="24sevenoffice-id"
            placeholder="--"
            onBlur={e => handleOnBlur(e, "twntyFourSevenId")}
          />
        )}
      </FormDialog>
    </Fragment>
  );
};
